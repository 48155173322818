import AuthHelper from 'common/AuthHelper';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const isAuth = () => {
  return AuthHelper.isAutenticed();
};

const PrivateRoute: React.FC<any> = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        isAuth() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/Login',
              state: { message: 'Usuário não autorizado' },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
