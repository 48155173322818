import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import { Dispatch } from 'redux';
import { RouteComponentProps } from 'react-router-dom';
import GenericTable from 'components/GenericTable/GenericTable';
import { IconButton, TextField } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import { TABLE_STYLE } from 'common/Constants';
import * as ChainingNetworkActions from 'store/participant/actions';
import { IChainingNetworkItem, IChainingNetworkParams, IStatusItem } from 'store/participant';
import { FormControl, Button, Grid, ButtonGroup } from '@material-ui/core';
import ApprovingModal from './ApprovingModal';

interface IProps extends RouteComponentProps {
  statusList: IStatusItem[];
  pendingApprovals: IChainingNetworkItem[];
  loading: boolean;
  report: string;
  loadStatusRequest: () => void;
  loadPendingChainingNetRequest: (data: IChainingNetworkParams) => void;
  chainingNetReportRequest: (data: IChainingNetworkParams) => void;
  chainingNetReportReset: () => void;
}

const ChainingNetwork: React.FC<IProps> = ({
  statusList,
  pendingApprovals,
  loading,
  loadStatusRequest,
  report,
  loadPendingChainingNetRequest,
  chainingNetReportRequest,
  chainingNetReportReset,
}) => {
  const [status, setStatus] = useState(1);
  const [document, setDocument] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [idSolicitacao, setIdSolicitacao] = useState(0);

  useEffect(() => {
    loadStatusRequest();

    let data = {
      idStatus: status,
      documento: document,
    };

    loadPendingChainingNetRequest(data);
  }, []);

  useEffect(() => {
    if (idSolicitacao !== 0) setOpenModal(true);
  }, [idSolicitacao]);

  useEffect(() => {
    if (!openModal) setIdSolicitacao(0);
  }, [openModal]);

  useEffect(() => {
    if (report !== undefined && report !== '') {
      var buffer = new ArrayBuffer(3);
      var dataView = new DataView(buffer);
      dataView.setUint8(0, 0xfe);
      dataView.setUint8(1, 0xbb);
      dataView.setUint8(2, 0xbf);

      var csvString = report;
      var universalBOM = '\uFEFF';
      var a = window.document.createElement('a');
      a.setAttribute(
        'href',
        'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + csvString),
      );

      let dateString = new Date().toLocaleDateString('pt-BR');
      let dateReplace = dateString.replace('/', '').replace('/', '');
      a.setAttribute('download', `RelatoriodeEncadeamento${dateReplace}_SouzaCruz.csv`);
      window.document.body.appendChild(a);
      a.click();

      chainingNetReportReset();
    }
  }, [report]);

  const handleSubmit = event => {
    event.preventDefault();

    let data = {
      idStatus: status,
      documento: document,
    };

    loadPendingChainingNetRequest(data);
  };

  const handleApproving = id => {
    setIdSolicitacao(id);
  };

  const handleGenerateReport = () => {
    chainingNetReportRequest({
      idStatus: status,
      documento: document,
    });
  };

  return (
    <div>
      <h1>Encadeamento de redes</h1>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <TextField
                name="status"
                id="status"
                size="small"
                select
                required
                value={status}
                label="Selecione o status"
                onChange={(e: React.ChangeEvent<{ value: any }>) =>
                  setStatus(parseInt(e.target.value))
                }
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              >
                <option value="0">Todos</option>

                {statusList.map(status => (
                  <option key={status.id} value={status.id}>
                    {status.nome}
                  </option>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth={true}>
              <TextField
                name="document"
                id="document"
                value={document}
                onChange={(e: React.ChangeEvent<{ value: any }>) => setDocument(e.target.value)}
                variant="outlined"
                size="small"
                label="Código SAP, CPF ou CNPJ"
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <ButtonGroup size="large" color="primary" aria-label="outlined primary button group">
              <Button type="submit" disabled={loading ? true : false}>
                Pesquisar
              </Button>
              <Button
                type="button"
                disabled={loading ? true : false}
                onClick={() => handleGenerateReport()}
              >
                Exportar CSV
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </form>
      <br />
      <GenericTable
        title="Solicitações"
        isLoading={loading}
        data={pendingApprovals}
        options={{
          paging: true,
          search: true,
          pageSize: 10,
          sorting: false,
        }}
        columns={[
          {
            searchable: true,
            title: 'Matriz',
            field: 'matriz',
            cellStyle: { ...TABLE_STYLE, width: '15%' },
          },
          {
            searchable: true,
            title: 'Razão social / Nome',
            field: 'razaoNomeMatriz',
            cellStyle: { ...TABLE_STYLE, width: '12%' },
          },
          {
            searchable: true,
            title: 'Estabelecimento associado',
            field: 'associado',
            cellStyle: { ...TABLE_STYLE, width: '12%' },
          },
          {
            searchable: true,
            title: 'Razão Social / Nome',
            field: 'razaoNomeAssociado',
            cellStyle: { ...TABLE_STYLE, width: '15%' },
          },
          {
            searchable: false,
            title: 'Solicitado em',
            field: 'solicitadoEm',
            cellStyle: { ...TABLE_STYLE, width: '15%' },
          },
          {
            searchable: true,
            title: 'Status',
            field: 'status',
            cellStyle: { ...TABLE_STYLE, width: '10%' },
          },
          {
            searchable: false,
            title: 'Moderação de pedido',
            cellStyle: {
              width: '30%',
              ...TABLE_STYLE,
            },
            render: ({ id }) => (
              <IconButton color="primary" onClick={() => handleApproving(id)}>
                <Edit fontSize="small" />
              </IconButton>
            ),
          },
        ]}
      />
      <ApprovingModal
        title="Moderação de pedido"
        state={openModal}
        setState={setOpenModal}
        size="lg"
        idSolicitacao={idSolicitacao}
        loadChainingParams={{
          idStatus: status,
          documento: document,
        }}
      />
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    statusList: state.participant.statusList,
    pendingApprovals: state.participant.data,
    loading: state.participant.loading,
    error: state.participant.error,
    success: state.participant.success,
    loadingReport: state.participant.loadingReport,
    report: state.participant.report,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadStatusRequest: () => {
      dispatch(ChainingNetworkActions.loadStatusRequest());
    },
    loadPendingChainingNetRequest: (data: IChainingNetworkParams) => {
      dispatch(ChainingNetworkActions.loadPendingChainingNetRequest(data));
    },
    chainingNetReportRequest: (data: IChainingNetworkParams) => {
      dispatch(ChainingNetworkActions.chainingNetReportRequest(data));
    },
    chainingNetReportReset: () => {
      dispatch(ChainingNetworkActions.chainingNetReportReset());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChainingNetwork);
