import { call, put } from 'redux-saga/effects';
import API from '../../common/Api';
import Utils from '../../common/Utils';
import {
  loadSuccess,
  loadFailure,
  loadDistributorsSuccess,
  loadDistributorsFailure,
} from './actions';

export function* loadFdvList(action) {
  try {
    const { data } = yield call(API.post, action.payload.url, action.payload.data);
    yield put(loadSuccess(data));
  } catch (error) {
    yield put(loadFailure());
    Utils.redirect(error);
  }
}

export function* loadDistributors() {
  try {
    const response = yield call(API.get, '/consulta/equipe/distribuidores');
    yield put(loadDistributorsSuccess(response.data));
  } catch (error) {
    yield put(loadDistributorsFailure());
    Utils.redirect(error);
  }
}
