import React, { useEffect } from 'react';
import { Typography, Breadcrumbs } from '@material-ui/core';
import Wrap from 'components/Wrap/Wrap';

const Home: React.FC<any> = ({ history }) => {
  useEffect(() => {
    if (localStorage.getItem('accessToken') === null) {
      history.push('/login');
    }
  });

  return (
    <>
      <Wrap>
        <Breadcrumbs>
          <Typography color="textPrimary">Home</Typography>
        </Breadcrumbs>
      </Wrap>
    </>
  );
};

export default Home;
