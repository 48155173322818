import { Reducer } from 'redux';

export enum IndustryTypes {
  LOAD_REQUEST = '@industries/LOAD_REQUEST',
  LOAD_BY_REGION_REQUEST = '@industries/LOAD_BY_REGION_REQUEST',
  LOAD_SUCCESS = '@industries/LOAD_SUCCESS',
  LOAD_FAILURE = '@industries/LOAD_FAILURE',
  RESET = '@industries/RESET',
}

export interface IIndustry {
  cnpj: string;
  razaoSocial: string;
}

export interface IIndustryState {
  readonly data: IIndustry[];
  readonly loading: boolean;
  readonly error: boolean;
}

const INITIAL_STATE: IIndustryState = {
  data: [],
  loading: false,
  error: false,
};

const reducer: Reducer<IIndustryState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case IndustryTypes.LOAD_BY_REGION_REQUEST:
    case IndustryTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case IndustryTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data,
      };
    case IndustryTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true, data: { ...INITIAL_STATE.data } };
    case IndustryTypes.RESET:
      return { ...state, loading: false, error: false, data: [] };
    default:
      return state;
  }
};

export default reducer;
