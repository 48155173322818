import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import {
  loadUploadFileSuccess,
  loadUploadFileFailure,
  loadFileExemptionDetailsSuccess,
  loadFileExemptionDetailsFailure,
  participantExemptionCsvSuccess,
  participantExemptionCsvFailure,
} from './actions';
import { showToastr } from 'store/toastr/actions';
import { AppMessages } from 'common/AppMessages.enum';

export function* loadParticipantExemptionFile() {
  try {
    const { data } = yield call(API.get, `/participante-pagamento-isencao/all`);
    yield put(loadUploadFileSuccess(data));
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
    yield put(loadUploadFileFailure());
  }
}

export function* loadFileExemptionDetails(action) {
  try {
    const { data } = yield call(
      API.get,
      `/participante-pagamento-isencao/${action.payload.id}/linhas`,
    );
    yield put(loadFileExemptionDetailsSuccess(data));
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
    yield put(loadFileExemptionDetailsFailure());
  }
}

export function* uploadParticipantExemptionCsv(action) {
  try {
    const { data } = yield call(
      API.post,
      `participante-pagamento-isencao/upload`,
      action.payload.file,
    );

    if (!data.erro) {
      yield put(participantExemptionCsvSuccess(data));
      yield put(
        showToastr({
          type: 'success',
          message: AppMessages.CAMPAIGN_REPORT_UPLOAD_SUCCESS,
          open: true,
        }),
      );
    } else {
      yield put(
        showToastr({ type: 'error', message: AppMessages.ERROR_MAILING_PROCCESS, open: true }),
      );
      yield put(participantExemptionCsvFailure());
    }
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
    yield put(participantExemptionCsvFailure());
  }
}
