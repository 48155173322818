import { STRUCTURE_PULICTARGET } from 'common/Constants';
import { Reducer } from 'redux';

export enum PremiacaoTypes {
  LOAD_REQUEST = '@premiacao/LOAD_REQUEST',
  LOAD_SUCCESS = '@premiacao/LOAD_SUCCESS',
  LOAD_FAILURE = '@premiacao/LOAD_FAILURE',
  RESET_PREMIACAO = '@premiacao/RESET_PREMIACAO',
  CREATE_REQUEST = '@premiacao/CREATE_REQUEST',
  CREATE_SUCCESS = '@premiacao/CREATE_SUCCESS',
  CREATE_FAILURE = '@premiacao/CREATE_FAILURE',
}

export interface IPremiacaoData {
  valorPremiacao: number;
  campanhaId: number;
  tipoPeriodoCreditoId: number;
  creditoAutomatico: boolean;
}
export interface IPremiacao extends IPremiacaoData {
  id: number;
}

export interface IPremiacaoState {
  readonly data: IPremiacao;
  readonly loading: boolean;
  readonly error: boolean;
}

export const INITIAL_STATE: IPremiacaoState = {
  data: {
    id: 0,
    valorPremiacao: 0,
    campanhaId: 0,
    tipoPeriodoCreditoId: 0,
    creditoAutomatico: false,
  },
  loading: false,
  error: false,
};

const reducer: Reducer<IPremiacaoState> = (state = INITIAL_STATE, action) => {
  console.log('action create premiacao', action);
  switch (action.type) {
    case PremiacaoTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case PremiacaoTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: { ...action.payload.data },
      };
    case PremiacaoTypes.RESET_PREMIACAO:
      return { ...state, loading: false, error: false, data: { ...INITIAL_STATE.data } };
    case PremiacaoTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true, data: { ...INITIAL_STATE.data } };
    case PremiacaoTypes.CREATE_REQUEST:
      return { ...state, loading: true };
    case PremiacaoTypes.CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: { ...state.data, ...action.payload, id: action.payload.id },
      };
    case PremiacaoTypes.CREATE_FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default reducer;
