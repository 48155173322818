import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import {
  loadRealtimeTotalCadastrosUltimosDiasSuccess,
  loadRealtimeTotalCadastrosUltimosDiasFailure,
} from './actions';

export function* loadRealtimeTotalCadastrosUltimosDiasRequest() {
  try {
    const { data } = yield call(API.get, 'dashboard/realtime/total/cadastros/dias');
    yield put(loadRealtimeTotalCadastrosUltimosDiasSuccess(data.itens, data.atualizacao));
  } catch (error) {
    yield put(loadRealtimeTotalCadastrosUltimosDiasFailure());
  }
}
