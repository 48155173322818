import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import { Dispatch } from 'redux';
import { RouteComponentProps } from 'react-router-dom';
import GenericTable from 'components/GenericTable/GenericTable';
import { IconButton, TextField } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import { TABLE_STYLE } from 'common/Constants';
import * as AutomaticCreditActions from 'store/counting/automaticCredit/actions';
import {
  IAutomaticCreditItem,
  IAutomaticCreditParams,
  IStatusItem,
} from 'store/counting/automaticCredit';
import { FormControl, Button, Grid, ButtonGroup } from '@material-ui/core';
import ApprovingModal from './ApprovingModal';
import Utils from 'common/Utils';
import { GetApp } from '@material-ui/icons';

interface IProps extends RouteComponentProps {
  statusList: IStatusItem[];
  pendingApprovals: IAutomaticCreditItem[];
  loading: boolean;
  report: string;
  loadStatusAutomaticCreditRequest: () => void;
  loadPendingAutomaticCreditRequest: (data: IAutomaticCreditParams) => void;
  automaticCreditReportRequest: (data: IAutomaticCreditParams) => void;
  automaticCreditReportReset: () => void;
}

const AutomaticCreditList: React.FC<IProps> = ({
  statusList,
  pendingApprovals,
  loading,
  loadStatusAutomaticCreditRequest,
  report,
  loadPendingAutomaticCreditRequest,
  automaticCreditReportRequest,
  automaticCreditReportReset,
}) => {
  const [status, setStatus] = useState(1);
  const [document] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [idApuracaoLote, setidApuracaoLote] = useState(0);

  const returnStatus = data => {
    if (data.aprovado && data.observacao != '') {
      return 'Aprovado';
    }
    if (data.aprovado && data.observacao == '') {
      return 'Aguardando';
    }
    if (!data.aprovado && data.descricao != '') {
      return 'Reprovado';
    }
  };

  useEffect(() => {
    loadStatusAutomaticCreditRequest();

    let data = {
      idStatus: status,
      documento: document,
    };

    loadPendingAutomaticCreditRequest(data);
  }, []);

  useEffect(() => {
    if (idApuracaoLote !== 0) setOpenModal(true);
  }, [idApuracaoLote]);

  useEffect(() => {
    if (!openModal) setidApuracaoLote(0);
  }, [openModal]);

  useEffect(() => {
    if (report !== undefined && report !== '') {
      var buffer = new ArrayBuffer(3);
      var dataView = new DataView(buffer);
      dataView.setUint8(0, 0xfe);
      dataView.setUint8(1, 0xbb);
      dataView.setUint8(2, 0xbf);

      var csvString = report;
      var universalBOM = '\uFEFF';
      var a = window.document.createElement('a');
      a.setAttribute(
        'href',
        'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + csvString),
      );

      let dateString = new Date().toLocaleDateString('pt-BR');
      let dateReplace = dateString.replace('/', '').replace('/', '');
      a.setAttribute('download', `RelatoriodeEncadeamento${dateReplace}_SouzaCruz.csv`);
      window.document.body.appendChild(a);
      a.click();

      automaticCreditReportReset();
    }
  }, [report]);

  const handleSubmit = event => {
    event.preventDefault();

    let data = {
      idStatus: status,
      documento: document,
    };

    loadPendingAutomaticCreditRequest(data);
  };

  const handleApproving = id => {
    setidApuracaoLote(id);
  };

  const setReloadList = statusId => {
    setStatus(statusId);
    let data = {
      idStatus: statusId,
      documento: document,
    };

    loadPendingAutomaticCreditRequest(data);
  };

  return (
    <div>
      <h1>Credito Automático</h1>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <TextField
                name="status"
                id="status"
                size="small"
                select
                required
                value={status}
                label="Selecione o status"
                onChange={(e: React.ChangeEvent<{ value: any }>) =>
                  setReloadList(Number(e.target.value))
                }
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              >
                <option value="0">Todos</option>
                {statusList.length > 0 &&
                  statusList
                    .filter(status => {
                      return status.id !== 4;
                    })
                    .map(status => (
                      <option key={status.id} value={status.id}>
                        {status.nome}
                      </option>
                    ))}
              </TextField>
            </FormControl>
          </Grid>
        </Grid>
      </form>
      <br />
      <GenericTable
        title="Solicitações"
        isLoading={loading}
        data={pendingApprovals}
        options={{
          paging: true,
          search: true,
          pageSize: 10,
          sorting: false,
        }}
        columns={[
          {
            searchable: true,
            title: 'Campanha',
            field: 'nomeCampanha',
            cellStyle: { ...TABLE_STYLE, width: '20%' },
          },
          {
            searchable: true,
            title: 'Tipo Apuração',
            field: 'tipoApuracao',
            cellStyle: { ...TABLE_STYLE, width: '15%' },
          },
          {
            searchable: true,
            title: 'Data Processamento',
            render: rowData => {
              return `${rowData.dataInclusao ? Utils.setData(rowData.dataInclusao) : ''}`;
            },
            cellStyle: { ...TABLE_STYLE, width: '15%' },
          },
          {
            searchable: true,
            title: 'Status',
            render: rowData => {
              return `${returnStatus(rowData)}`;
            },
            cellStyle: { ...TABLE_STYLE, width: '20%' },
          },
          {
            searchable: false,
            title: 'Moderação de pedido',
            cellStyle: {
              width: '20%',
              ...TABLE_STYLE,
            },
            render: ({ id }) => (
              <IconButton color="primary" onClick={() => handleApproving(id)}>
                <Edit fontSize="small" />
              </IconButton>
            ),
          },
          {
            searchable: false,
            title: 'Gerar relatório',
            cellStyle: {
              width: '20%',
              ...TABLE_STYLE,
            },
            render: ({ UrlArquivoCredito }) => (
              <IconButton color="primary" onClick={() => window.open(UrlArquivoCredito)}>
                <GetApp fontSize="small" />
              </IconButton>
            ),
          },
        ]}
      />
      <ApprovingModal
        title="Moderação de pedido"
        state={openModal}
        setState={setOpenModal}
        size="lg"
        idApuracaoLote={idApuracaoLote}
        loadChainingParams={{
          idStatus: status,
          documento: document,
        }}
      />
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    statusList: state.automaticCredit.statusList,
    pendingApprovals: state.automaticCredit.data,
    loading: state.automaticCredit.loading,
    error: state.automaticCredit.error,
    success: state.automaticCredit.success,
    loadingReport: state.automaticCredit.loadingReport,
    report: state.automaticCredit.report,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadStatusAutomaticCreditRequest: () => {
      dispatch(AutomaticCreditActions.loadStatusAutomaticCreditRequest());
    },
    loadPendingAutomaticCreditRequest: (data: IAutomaticCreditParams) => {
      dispatch(AutomaticCreditActions.loadPendingAutomaticCreditRequest(data));
    },
    automaticCreditReportRequest: (data: IAutomaticCreditParams) => {
      dispatch(AutomaticCreditActions.automaticCreditReportRequest(data));
    },
    automaticCreditReportReset: () => {
      dispatch(AutomaticCreditActions.automaticCreditReportReset());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AutomaticCreditList);
