import { Reducer } from 'redux';

export enum UploadCampaignReportTypes {
  LOAD_REQUEST = '@uploadCampaignReport/LOAD_REQUEST',
  LOAD_SUCCESS = '@uploadCampaignReport/LOAD_SUCCESS',
  LOAD_FAILURE = '@uploadCampaignReport/LOAD_FAILURE',
  RESET_CAMPAIGN_REPORT = '@campaignEdit/RESET_CAMPAIGN_REPORT',

  CREATE_REQUEST = '@uploadCampaignReport/CREATE_REQUEST',
  CREATE_SUCCESS = '@uploadCampaignReport/CREATE_SUCCESS',
  CREATE_FAILURE = '@uploadCampaignReport/CREATE_FAILURE',
}

export interface IUploadCampaignReportData {
  descricao: string;
  tipoCampanhaRelatorio: number;
  data_Processamento: string;
}

export interface IUploadCampaignReportCreateRequest {
  descricao: string;
  tipoCampanhaRelatorio: number;
  data_Processamento: string;
}

export interface IUploadCampaignReportEdit extends IUploadCampaignReportData {
  id: string | undefined;
}

export interface IUploadCampaignReportEditState {
  readonly data: IUploadCampaignReportEdit;
  readonly loading: boolean;
  readonly error: boolean;
}

export const INITIAL_STATE: IUploadCampaignReportEditState = {
  data: {
    id: '',
    descricao: '',
    tipoCampanhaRelatorio: 0,
    data_Processamento: '',
  },
  loading: false,
  error: false,
};

const reducer: Reducer<IUploadCampaignReportEditState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //Loads
    case UploadCampaignReportTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case UploadCampaignReportTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: { ...action.payload.data },
      };
    case UploadCampaignReportTypes.RESET_CAMPAIGN_REPORT:
      return { ...state, loading: false, error: false, data: INITIAL_STATE.data };
    case UploadCampaignReportTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true, data: { ...INITIAL_STATE.data } };
    //Creates
    case UploadCampaignReportTypes.CREATE_REQUEST:
      return { ...state, loading: true };
    case UploadCampaignReportTypes.CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: { ...state.data, ...action.payload, id: action.payload.id },
      };
    case UploadCampaignReportTypes.CREATE_FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default reducer;
