import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import {
  loadParticipantSeparateInvoiceFile as loadParticipantSeparateInvoiceFileAction,
  loadUploadFileSuccess,
  loadUploadFileFailure,
  participantSeparateInvoiceCsvSuccess,
  participantSeparateInvoiceCsvFailure,
  deleteParticipantSeparateInvoiceCsvSuccess,
  deleteParticipantSeparateInvoiceCsvFailure,
} from './actions';
import { showToastr } from 'store/toastr/actions';
import { AppMessages } from 'common/AppMessages.enum';

export function* loadParticipantSeparateInvoiceFile() {
  try {
    const { data } = yield call(API.get, `/participante-nota-fiscal-separada/lista-arquivo-upload`);
    yield put(loadUploadFileSuccess(data));
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
    yield put(loadUploadFileFailure());
  }
}

export function* uploadParticipantSeparateInvoiceCsv(action) {
  try {
    const { data } = yield call(
      API.post,
      `participante-nota-fiscal-separada/upload`,
      action.payload.file,
    );

    if (data.sucesso) {
      yield put(participantSeparateInvoiceCsvSuccess(data));
      yield put(
        showToastr({
          type: 'success',
          message: AppMessages.CAMPAIGN_REPORT_UPLOAD_SUCCESS,
          open: true,
        }),
      );
    } else {
      yield put(
        showToastr({ type: 'error', message: AppMessages.ERROR_MAILING_PROCCESS, open: true }),
      );
      yield put(participantSeparateInvoiceCsvFailure());
    }
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
    yield put(participantSeparateInvoiceCsvFailure());
  }
}

export function* deleteParticipantSeparateInvoiceCsv(action) {
  try {
    const { data } = yield call(API.delete, action.payload.deleteUrl);
    yield put(deleteParticipantSeparateInvoiceCsvSuccess(data));
    yield put(
      showToastr({
        type: 'success',
        message: AppMessages.UPLOAD_CAMPAIGN_REPORT_DELETE_SUCCESS,
        open: true,
      }),
    );
    const module = { id: Number(data.id) };
    yield put(loadParticipantSeparateInvoiceFileAction(module.id));
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
    yield put(deleteParticipantSeparateInvoiceCsvFailure());
  }
}
