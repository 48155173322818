import { action } from 'typesafe-actions';
import { BannersEditTypes, IBannerEdit } from './';

export const loadBanner = (id: number) => action(BannersEditTypes.LOAD_REQUEST, { id });
export const loadBannerSuccess = data => action(BannersEditTypes.LOAD_SUCCESS, { data });
export const loadBannerFailure = () => action(BannersEditTypes.LOAD_FAILURE);

export const createBanner = (data: IBannerEdit) =>
  action(BannersEditTypes.CREATE_REQUEST, { data });
export const createBannerSuccess = data => action(BannersEditTypes.CREATE_SUCCESS, { data });

export const updateBanner = (data: IBannerEdit) =>
  action(BannersEditTypes.UPDATE_REQUEST, { data });
export const updateBannerSuccess = data => action(BannersEditTypes.UPDATE_SUCCESS, { data });

export const applyBannerFilter = data =>
  action(BannersEditTypes.APPLYFILTER_REQUEST, {
    id: data.id,
    tipoId: data.tipoId,
    filter: data.filter,
  });
export const applyBannerFilterSuccess = data => action(BannersEditTypes.APPLYFILTER_SUCCESS, data);

export const loadBannerFilters = (bannerId: number, moduleType: number) =>
  action(BannersEditTypes.GETFILTER_REQUEST, { bannerId, moduleType });
export const loadBannerFiltersCompleted = () => action(BannersEditTypes.GETFILTER_COMPLETED);

export const loadBannerFiltersPDVSuccess = data =>
  action(BannersEditTypes.GETFILTER_PDV_SUCCESS, data);
export const loadBannerFiltersFDVSuccess = data =>
  action(BannersEditTypes.GETFILTER_FDV_SUCCESS, data);

export const resetBannerEdit = () => action(BannersEditTypes.RESET_BANNER);
export const resetBannerFilters = () => action(BannersEditTypes.GETFILTER_RESET);
export const setBannerFilterCompleted = () => action(BannersEditTypes.GETFILTER_SETCOMPLETED);

export const loadBannerFilterAppliedParticipantCountPDV = (count: number) =>
  action(BannersEditTypes.SETFILTER_COUNT_PDV_REQUEST, { count });
export const loadBannerFilterAppliedParticipantCountFDV = (count: number) =>
  action(BannersEditTypes.SETFILTER_COUNT_FDV_REQUEST, { count });

export const uploadBannerParticipant = (
  uploadUrl: string,
  data: FormData,
  id: number,
  type: number,
) =>
  action(BannersEditTypes.UPLOAD_PARTICIPANT_REQUEST, {
    uploadUrl,
    data,
    id,
    type,
  });
export const uploadBannerParticipantSuccess = data =>
  action(BannersEditTypes.UPLOAD_PARTICIPANT_SUCCESS, { data });
export const uploadBannerParticipantFailure = () =>
  action(BannersEditTypes.UPLOAD_PARTICIPANT_FAILURE);

export const loadParticipantsFileList = (bannerId: number) =>
  action(BannersEditTypes.LOAD_PARTICIPANT_REQUEST, { bannerId });
export const loadParticipantsFileListSuccess = data =>
  action(BannersEditTypes.LOAD_PARTICIPANT_SUCCESS, { data });
export const loadParticipantsFileListFailure = () =>
  action(BannersEditTypes.LOAD_PARTICIPANT_FAILURE);

export const deleteParticipantFile = (bannerParticipantFileId: number) =>
  action(BannersEditTypes.DELETE_PARTICIPANT_REQUEST, { bannerParticipantFileId });
export const deleteParticipantFileSuccess = data =>
  action(BannersEditTypes.DELETE_PARTICIPANT_SUCCESS, { data });
export const deleteParticipantFileFailure = () =>
  action(BannersEditTypes.DELETE_PARTICIPANT_FAILURE);
