import React from 'react';
import Wrap from 'components/Wrap/Wrap';
import PerfilRegister from './PerfilRegister';
import { RouteComponentProps } from 'react-router-dom';
import { Breadcrumbs, Typography, Link, Box } from '@material-ui/core';

interface IParams {
  id?: string | undefined;
}
const PerfilEdit: React.FC<any & RouteComponentProps<IParams>> = ({ history, match }) => {
  return (
    <Box mb="1.5em">
      <Wrap>
        <Breadcrumbs>
          <Link color="inherit" onClick={() => history.push('/')}>
            Home
          </Link>
          <Link color="inherit" onClick={() => history.push('/perfil')}>
            Gestão de Perfis
          </Link>
          <Typography color="textPrimary">{match.params.id ? 'Editar' : 'Cadastrar'}</Typography>
        </Breadcrumbs>
      </Wrap>

      <Box>
        <PerfilRegister match={match} history={history} />
      </Box>
    </Box>
  );
};

export default PerfilEdit;
