import { action } from 'typesafe-actions';
import { TotalErrosCancelamentosTypes, IRealtimeItem } from './';

export const loadRealtimeTotalErrosCancelamentosRequest = () =>
  action(TotalErrosCancelamentosTypes.LOAD_REQUEST);
export const loadRealtimeTotalErrosCancelamentosSuccess = (
  data: IRealtimeItem[],
  atualizacao: string,
) => action(TotalErrosCancelamentosTypes.LOAD_SUCCESS, { data, atualizacao });
export const loadRealtimeTotalErrosCancelamentosFailure = () =>
  action(TotalErrosCancelamentosTypes.LOAD_FAILURE);
