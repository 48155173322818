import { LAST_PAGE, IFilterItemRequest } from './Constants';
import { parseISO, format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import store from 'store';
import { showToastr } from 'store/toastr/actions';
import { IFilterPopupItemRequest } from 'components/Elegibles/ElegiblesFilterItemPopup';

declare var window: Window;

export default class Utils {
  public static validMonthActual(month: number) {
    let current_date = new Date();
    let monthActual = current_date.getMonth() + 1;

    return month === monthActual;
  }

  public static hasInputError(value: string | string[], required: boolean): boolean {
    if (Array.isArray(value)) {
      const result: string[] = [];
      value.forEach(item => {
        if (item === '' || item === null || item === undefined) {
          result.push(item);
        }
      });
      return result.length > 0;
    } else {
      return (value === '' || value === null || value === undefined) && required;
    }
  }

  public static notify(data: any) {
    if (data && data.message) {
      store.dispatch(
        showToastr({
          message: data.message,
          open: true,
          type: data.success ? 'success' : 'error',
        }),
      );
    }
  }

  public static formatDate(date: string) {
    if (!date) {
      return null;
    }

    return format(parseISO(date), 'dd/MM/yyyy', { locale: ptBR });
  }

  public static formatInputDecimal(value: number) {
    return value.toString().replace('.', ',');
  }

  public static formatDecimalToAPI(value: string) {
    return parseFloat(
      value
        .toString()
        .replace('.', '')
        .replace(',', '.'),
    );
  }

  public static returnLimitOrLower(value: number, limit: number) {
    return Utils.formatDecimalToAPI(value.toString()) < limit ? value : limit;
  }

  public static toDateInputValue(date) {
    if (!date) return '';

    const [day, month, year] = date.split('/');
    return `${year}-${month}-${day}`;
  }

  public static removeStringSpace(str: string): string {
    return str.replace(/\s/g, '');
  }

  public static replaceSpaceWithUnderslash(str: string | number): string | number {
    if (typeof str === 'number') {
      return str;
    }

    return str.replace(/\s/g, '_');
  }

  public static replaceUnderslashWithSpace(str: string | number): string | number {
    if (typeof str === 'number') {
      return str;
    }

    return str.replace('_', ' ');
  }

  public static compareDate(startDate: string, endDate: string): boolean {
    return new Date(startDate) >= new Date(endDate);
  }

  public static getToken() {
    return window.localStorage.getItem('accessToken');
  }

  public static setStorageItem(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  public static getStorageItem(key: string): string | null {
    return localStorage.getItem(key);
  }

  public static setLocalDateTime(value: string) {
    let date = new Date(value.includes('Z') ? value : value + 'Z');
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
  }

  public static setData(value: string) {
    const split = value.split('-');
    return split[2].split('T')[0] + '/' + split[1] + '/' + split[0];
  }

  public static setDataHora(value: string) {
    const split = value.split('-');
    return (
      split[2].split('T')[0] +
      '/' +
      split[1] +
      '/' +
      split[0] +
      ' ' +
      split[2].split('T')[1].split('.')[0]
    );
  }

  public static redirect(error: any) {
    switch (error.response.status) {
      case 401:
        this.setStorageItem(LAST_PAGE, window.location.pathname);
        window.location.href = '/login';
        break;
      default:
        break;
    }
  }

  public static toFilterItemObject<T extends any>(
    item: T[],
    idKey: keyof T | null,
    textKey: keyof T | null,
    type: number,
    selected: boolean = false,
  ): IFilterItemRequest[] {
    return item.map(e => {
      return {
        id: idKey === null ? 0 : e[idKey],
        nome: textKey === null ? e : e[textKey],
        tipo: type,
        // tslint:disable-next-line: no-string-literal
        selected: e['selected'] || false,
      } as IFilterItemRequest;
    });
  }

  public static toFilterItemObjectSelecteItems<T extends any>(
    item: T[],
    idKey: keyof T | null,
    textKey: keyof T | null,
    type: number,
    selectedItems: any,
  ): IFilterItemRequest[] {
    return item.map(e => {
      let itemSelected = selectedItems.includes(idKey === null ? 0 : e[idKey]);

      return {
        id: idKey === null ? 0 : e[idKey],
        nome: textKey === null ? e : e[textKey],
        tipo: type,
        // tslint:disable-next-line: no-string-literal
        selected: itemSelected,
      } as IFilterItemRequest;
    });
  }

  public static toFilterItemObjectSelecteItemsPopup<T extends any>(
    item: T[],
    idKey: keyof T | null,
    textKey: keyof T | null,
    title: string,
    selectedItems: any,
  ): IFilterPopupItemRequest[] {
    return item.map(e => {
      let itemSelected = selectedItems.includes(idKey === null ? 0 : e[idKey]);

      return {
        id: idKey === null ? 0 : e[idKey],
        label: textKey === null ? e : e[textKey],
        title: e[title],
        // tslint:disable-next-line: no-string-literal
        selected: itemSelected,
      } as IFilterPopupItemRequest;
    });
  }

  public static generateArrayOfYears() {
    const max = new Date().getFullYear();
    const min = max - 2;
    const years = [] as any;

    for (var i = max; i >= min; i--) {
      years.push(i);
    }
    return years;
  }

  public static generateArrayOfMonths() {
    const months = [] as any;

    for (var _i = 1; _i < 13; _i++) {
      months.push(_i);
    }

    return months;
  }

  public static generateArrayOfStatusPayment() {
    return [
      { id: 2, name: 'Pago' },
      { id: 4, name: 'Abono' },
    ];
  }

  public static toSingleFilterItemObject<T extends any>(
    item: T,
    idKey: keyof T | null,
    textKey: keyof T | null,
    type: number,
    selected: boolean = false,
  ): IFilterItemRequest {
    return {
      id: idKey === null ? item : item[idKey],
      nome: textKey === null ? item : item[textKey],
      selected,
      tipo: type,
    } as IFilterItemRequest;
  }

  public static deepCopy(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }

  public static stringToEditorText(content: string) {
    return EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(content)));
  }

  public static getEditorData(editorState) {
    return draftToHtml(convertToRaw(editorState.getCurrentContent()));
  }

  public static getFormatedFile(file) {
    const blob = file.slice(0, file.size, file.type);
    const newFile = new File([blob], encodeURIComponent(file.name), { type: file.type });
    return newFile;
  }

  public static copyToClipboard(e, id) {
    const target = document.getElementById(id) as any;
    target?.select();
    target?.setSelectionRange(0, 99999);
    document.execCommand('copy');
    store.dispatch(
      showToastr({ message: 'URL Copiada para o clipboard.', open: true, type: 'info' }),
    );
  }

  public static maskCpf(value) {
    if (!value) return value;

    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  }

  public static setCnpjMask(cnpj) {
    return cnpj != null
      ? cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')
      : '';
  }

  public static removeSpecialCharacters(value) {
    return value != null && value !== undefined && value !== ''
      ? value.replace(/[() \.\-\\\/]+/g, '')
      : null;
  }

  public static formatString = (str: string) => {
    // Format string to Uppercase w/o accents
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = 'ãàáäâèéëêìíïîòóöôùúüûñç·/_,:;';
    var to = 'aaaaaeeeeiiiioooouuuunc------';
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, ''); // remove invalid chars
    str = str.toUpperCase();

    return str;
  };

  public static slugfy = (str: string) => {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = 'ãàáäâèéëêìíïîòóöôùúüûñç·/_,:;';
    var to = 'aaaaaeeeeiiiioooouuuunc------';
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

    return str;
  };
}

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch {
    // ignore write errors
  }
};
