import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import {
  loadAllPlanAvaliableFailure,
  loadAllPlanAvaliableSuccess,
  loadFileDetailsSuccess,
  loadFileDetailsFailure,
  loadImportationPlanbyLotSuccess,
  loadImportationPlanbyLotFailure,
} from './actions';

export function* loadAllPlanAvaliable() {
  try {
    const { data } = yield call(API.get, '/planos/all');

    yield put(loadAllPlanAvaliableSuccess(data));
  } catch (error) {
    yield put(loadAllPlanAvaliableFailure());
  }
}

export function* loadFileDetails(action) {
  try {
    const { data } = yield call(API.get, `/planos/${action.payload.id}/linhas`);

    yield put(loadFileDetailsSuccess(data));
  } catch (error) {
    yield put(loadFileDetailsFailure());
  }
}

export function* loadImportationPlanbyLot() {
  try {
    const { data } = yield call(API.get, `/planos/importacoes`);

    yield put(loadImportationPlanbyLotSuccess(data));
  } catch (error) {
    yield put(loadImportationPlanbyLotFailure());
  }
}
