import {
  Box,
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  Paper,
  Select,
  Theme,
} from '@material-ui/core';
import { MODULE_TYPE, STRUCTURE_PULICTARGET } from 'common/Constants';
import CostumizedSelectionPDV from 'components/CostumizedSelectionPDV/CostumizedSelectionPDV';
import Elegibles from 'components/Elegibles/Elegibles';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from 'store';
import { ICampaignEdit, IElegibleTypeOptions } from 'store/campaign/edit';
import * as CampaignEditActions from 'store/campaign/edit/actions';
import { IMailing } from 'store/mailing';
import * as MailingActions from 'store/mailing/actions';
import * as RegionsActions from 'store/regions/actions';

interface StructureProps {
  campaign: ICampaignEdit;
  loadMailing(id: number, moduleType: MODULE_TYPE): void;
  loadTargetPublicOptions(CampaignId: number): void;
  elegibleTypeBlocked: boolean;
  elegibleTypeOptions: IElegibleTypeOptions[];
  mailing: IMailing[];
  loadCampaign: any;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    spacing: {
      marginBottom: theme.spacing(2),
    },
  }),
);

const Structure: React.FC<StructureProps> = ({
  campaign,
  loadMailing,
  loadTargetPublicOptions,
  loadCampaign,
  elegibleTypeOptions,
  mailing,
}) => {
  const classes = useStyle();
  const [targetPublic, setTargetPublic] = useState(0);

  useEffect(() => {
    loadMailing(Number(campaign.id), MODULE_TYPE.CAMPAIGN);
    loadTargetPublicOptions(Number(campaign.id));
  }, []);

  useEffect(() => {
    setTargetPublic(campaign.elegivelFiltroTipo);
  }, [campaign.elegivelFiltroTipo]);

  const handleTargetPublicChange = (event: React.ChangeEvent<{ value: number }>) => {
    let newValue: number = +event.target.value;
    setTargetPublic(newValue);
  };

  return (
    <Paper>
      <Box p="1.5em" className={classes.formControl}>
        <Box fontSize="h5.fontSize" className={classes.spacing}>
          Público alvo da campanha
        </Box>
        <Box width={1} className={classes.spacing}>
          <FormControl fullWidth variant="outlined">
            <Select
              disabled={campaign.elegivelFiltroTipo > 0}
              required
              native
              value={targetPublic}
              onChange={(e: React.ChangeEvent<{ value: any }>) => handleTargetPublicChange(e)}
              inputProps={{
                name: 'public',
                id: 'public',
              }}
            >
              <option value="">Selecione o público alvo da campanha...</option>
              {elegibleTypeOptions.map(type => (
                <option key={type.id} value={type.id}>
                  {type.nome}
                </option>
              ))}
            </Select>
          </FormControl>
        </Box>
        {targetPublic === STRUCTURE_PULICTARGET.PDV && (
          <>
            <Elegibles
              campaignFilters={campaign.filtros}
              campaignId={campaign.id}
              publicTarget={targetPublic}
              mailing={mailing}
            />
          </>
        )}
        {targetPublic === STRUCTURE_PULICTARGET.MAILING && (
          <CostumizedSelectionPDV
            campaignId={Number(campaign.id)}
            publicTarget={targetPublic}
            mailing={mailing}
          />
        )}
      </Box>
    </Paper>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  campaign: state.campaignEdit.data,
  elegibleTypeBlocked: state.campaignEdit.elegibleTypeBlocked,
  elegibleTypeOptions: state.campaignEdit.elegibleTypeOptions,
  loading: state.regions.loading,
  mailing: state.mailing.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...RegionsActions, ...CampaignEditActions, ...MailingActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Structure as any);
