import { Reducer } from 'redux';
import Utils from 'common/Utils';

export enum ConectaPayCountingTypes {
  LOAD_REQUEST = '@conectapayCounting/LOAD_REQUEST',
  LOAD_SUCCESS = '@conectapayCounting/LOAD_SUCCESS',
  LOAD_FAILURE = '@conectapayCounting/LOAD_FAILURE',
  GENERATE_REPORT_REQUEST = '@conectapayCounting/GENERATE_REPORT_REQUEST',
  GENERATE_REPORT_SUCCESS = '@conectapayCounting/GENERATE_REPORT_SUCCESS',
  GENERATE_REPORT_FAILURE = '@conectapayCounting/GENERATE_REPORT_FAILURE',
  GENERATE_REPORT_RESET_PARAMS = '@conectapayCounting/GENERATE_REPORT_RESET_PARAMS',
}

export interface IConectaPayReportParams {
  campanhaId: number;
  mesReferencia: number;
  anoReferencia: number;
}

export interface IConectaPayCountingItem {
  id: number;
  nome: string;
  vigenciaAte: string;
  vigenciaDe: string;
  mesReferencia: number;
  anoReferencia: number;
  aceites: number;
}

export interface IConectaPayCoutingState {
  readonly data: IConectaPayCountingItem[];
  readonly loading: boolean;
  readonly error: boolean;
  readonly loadingReport: boolean;
  readonly report: string;
}

const INITIAL_STATE: IConectaPayCoutingState = {
  data: [],
  loading: false,
  error: false,
  loadingReport: false,
  report: '',
};

const reducer: Reducer<IConectaPayCoutingState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ConectaPayCountingTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case ConectaPayCountingTypes.LOAD_SUCCESS:
      const formated = action.payload.data.map((e: IConectaPayCountingItem) => {
        return {
          ...e,
          vigenciaDe: Utils.setData(e.vigenciaDe),
          vigenciaAte: Utils.setData(e.vigenciaAte),
        };
      });
      return { ...state, loading: false, error: false, data: formated };
    case ConectaPayCountingTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true, data: [] };
    case ConectaPayCountingTypes.GENERATE_REPORT_REQUEST:
      return { ...state, loadingReport: true };
    case ConectaPayCountingTypes.GENERATE_REPORT_SUCCESS:
      return { ...state, report: action.payload.data };
    case ConectaPayCountingTypes.GENERATE_REPORT_FAILURE:
      return { ...state, loadingReport: false };
    case ConectaPayCountingTypes.GENERATE_REPORT_RESET_PARAMS:
      return { ...state, loadingReport: false, report: '' };
    default:
      return state;
  }
};

export default reducer;
