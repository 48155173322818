import React from 'react';
import Wrap from 'components/Wrap/Wrap';
import {
  Breadcrumbs,
  Typography,
  Link,
  Box,
  createStyles,
  makeStyles,
  Theme,
  Button,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faTasks } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    flexboxContainer: {
      display: 'flex',
      justifyContent: 'space-evenly',
      marginTop: '150px',
    },
    flexbox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    icon: {
      width: '4em !important',
      height: '4em',
    },
    button: {
      textTransform: 'unset',
      padding: '35px',
    },
  }),
);

const PlanManagement: React.FC<any> = ({ history }) => {
  const classes = useStyle();

  return (
    <>
      <Wrap>
        <Breadcrumbs>
          <Link color="inherit" onClick={() => history.push('/')}>
            Home
          </Link>
          <Link color="inherit" onClick={() => history.push('/participantes/gestaodeplanos')}>
            Gestão de Planos
          </Link>
        </Breadcrumbs>
      </Wrap>
      <Box className={classes.flexboxContainer}>
        <Button
          className={classes.button}
          onClick={() => history.push('/participantes/gestaodeplanos/alteracaoplanoslote')}
        >
          <Box className={classes.flexbox}>
            <FontAwesomeIcon className={classes.icon} color="#381cad" icon={faUsers as IconProp} />
            <Typography variant="h5" color="textSecondary" component="p">
              Alteração de Planos de Participantes em Lote
            </Typography>
          </Box>
        </Button>

        <Button
          className={classes.button}
          onClick={() => history.push('/participantes/gestaodeplanos/planosexistentes')}
        >
          <Box className={classes.flexbox}>
            <FontAwesomeIcon className={classes.icon} color="#381cad" icon={faTasks as IconProp} />
            <Typography variant="h5" color="textSecondary" component="p">
              Gerenciamento de Planos Existentes
            </Typography>
          </Box>
        </Button>
      </Box>
    </>
  );
};

export default PlanManagement;
