import { Reducer } from 'redux';

export enum SkuTypes {
  LOAD_REQUEST = '@sku/LOAD_REQUEST',
  LOAD_SUCCESS = '@sku/LOAD_SUCCESS',
  LOAD_FAILURE = '@sku/LOAD_FAILURE',
  LOAD_ALL_SKU_REQUEST = '@sku/LOAD_ALL_SKU_REQUEST',
  LOAD_ALL_SKU_SUCCESS = '@sku/LOAD_ALL_SKU_SUCCESS',
  LOAD_ALL_SKU_FAILURE = '@sku/LOAD_ALL_SKU_FAILURE',
  CREATE_REQUEST = '@sku/CREATE_REQUEST',
  CREATE_SUCCESS = '@sku/CREATE_SUCCESS',
  UPDATE_REQUEST = '@sku/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@sku/UPDATE_SUCCESS',
  RESET_SKU = '@sku/RESET_SKU',
}

export interface IEanItem {
  id: number;
  nome: string;
}
export interface ISkuLisItem extends IEanItem {
  label: string;
}

export interface ISkuEdit {
  eans: IEanItem[];
  campanhaId: number;
}

export interface ISkuState {
  readonly data: IEanItem[];
  readonly loading: boolean;
  readonly skuList: ISkuLisItem[];
  readonly loadingSkuList: boolean;
  readonly error: boolean;
}

const INITIAL_STATE: ISkuState = {
  data: [],
  loading: false,
  skuList: [],
  loadingSkuList: true,
  error: false,
};

const reducer: Reducer<ISkuState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SkuTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case SkuTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: { ...action.payload.data },
      };
    case SkuTypes.LOAD_ALL_SKU_REQUEST:
      return { ...state, loading: true };
    case SkuTypes.LOAD_ALL_SKU_SUCCESS:
      return {
        ...state,
        loadingSkuList: false,
        skuList: action.payload.data.data,
      };
    case SkuTypes.RESET_SKU:
      return { ...state, loading: false, error: false, data: INITIAL_STATE.data };
    case SkuTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true, data: { ...state.data } };
    case SkuTypes.CREATE_REQUEST:
      return { ...state, loading: true };
    case SkuTypes.CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SkuTypes.UPDATE_REQUEST:
      return { ...state, loading: true };
    case SkuTypes.UPDATE_SUCCESS:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default reducer;
