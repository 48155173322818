import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import {
  loadUploadConectaPayFile as loadUploadFileAction,
  loadUploadFileSuccess,
  loadUploadFileFailure,
  uploadConectaPayCsvSuccess,
  uploadConectaPayCsvFailure,
  deleteUploadConectaPayCsvSuccess,
  deleteUploadConectaPayCsvFailure,
} from './actions';
import { showToastr } from 'store/toastr/actions';
import { AppMessages } from 'common/AppMessages.enum';

export function* loadUploadConectaPayFile(action) {
  try {
    const { data } = yield call(API.get, `/conectapay/ListaArquivos`);
    yield put(loadUploadFileSuccess(data.arquivos));
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
    yield put(loadUploadFileFailure());
  }
}

export function* uploadConectaPayCsv(action) {
  try {
    const { data } = yield call(API.post, action.payload.uploadUrl, action.payload.data);
    if (data.sucesso) {
      yield put(uploadConectaPayCsvSuccess(data));
      yield put(
        showToastr({
          type: 'success',
          message: AppMessages.CAMPAIGN_REPORT_UPLOAD_SUCCESS,
          open: true,
        }),
      );
    } else {
      yield put(
        showToastr({ type: 'error', message: AppMessages.ERROR_MAILING_PROCCESS, open: true }),
      );
      yield put(uploadConectaPayCsvFailure());
    }
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
    yield put(uploadConectaPayCsvFailure());
  }
}

export function* deleteUploadConectaPayCsv(action) {
  try {
    const { data } = yield call(API.delete, action.payload.deleteUrl);
    yield put(deleteUploadConectaPayCsvSuccess(data));
    yield put(
      showToastr({
        type: 'success',
        message: AppMessages.UPLOAD_CAMPAIGN_REPORT_DELETE_SUCCESS,
        open: true,
      }),
    );
    const module = { id: Number(data.id) };
    yield put(loadUploadFileAction(module.id));
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
    yield put(deleteUploadConectaPayCsvFailure());
  }
}
