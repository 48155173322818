import { Reducer } from 'redux';

export enum ConectaPayReportTypes {
  LOAD_TYPE_CONECTAPAY_REPORT_REQUEST = '@ConectaPayReport/TYPE_CONECTAPAY_REPORT_REQUEST',
  LOAD_TYPE_CONECTAPAY_REPORT_SUCCESS = '@ConectaPayReport/TYPE_CONECTAPAY_REPORT_SUCCESS',
  LOAD_TYPE_CONECTAPAY_REPORT_FAILURE = '@ConectaPayReport/TYPE_CONECTAPAY_REPORT_FAILURE',

  GENERATE_CONECTAPAY_REPORT_REQUEST = '@ConectaPayReport/GENERATE_CONECTAPAY_REPORT_REQUEST',
  GENERATE_CONECTAPAY_REPORT_SUCCESS = '@ConectaPayReport/GENERATE_CONECTAPAY_REPORT_SUCCESS',
  GENERATE_CONECTAPAY_REPORT_FAILURE = '@ConectaPayReport/GENERATE_CONECTAPAY_REPORT_FAILURE',
}

export interface IConectaPayReportParams {
  tipoCampanhaRelatorio: number;
  pagination: {
    limit: number;
    offset: number;
  };
}

export interface ITypeConectaPayReportItem {
  id: number;
  nome: string;
}

export interface IConectaPayReportState {
  readonly typesReports: ITypeConectaPayReportItem[];
  readonly loading: boolean;
  readonly error: boolean;
  readonly success: boolean;
  readonly loadingReport: boolean;
  readonly report: string;
}

const INITIAL_STATE: IConectaPayReportState = {
  typesReports: [],
  loading: false,
  error: false,
  success: false,
  loadingReport: false,
  report: '',
};

const reducer: Reducer<IConectaPayReportState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ConectaPayReportTypes.LOAD_TYPE_CONECTAPAY_REPORT_REQUEST:
      return { ...state, loading: true };
    case ConectaPayReportTypes.LOAD_TYPE_CONECTAPAY_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        typesReports: action.payload.data,
      };
    case ConectaPayReportTypes.LOAD_TYPE_CONECTAPAY_REPORT_FAILURE:
      return { ...state, loading: false, error: true, success: false, typesReports: [] };

    case ConectaPayReportTypes.GENERATE_CONECTAPAY_REPORT_REQUEST:
      return { ...state, loadingReport: true };
    case ConectaPayReportTypes.GENERATE_CONECTAPAY_REPORT_SUCCESS:
      return { ...state, report: action.payload.data, success: true };
    case ConectaPayReportTypes.GENERATE_CONECTAPAY_REPORT_FAILURE:
      return { ...state, loadingReport: false, error: true, success: false };

    default:
      return state;
  }
};

export default reducer;
