import { Reducer } from 'redux';

export enum BannersListTypes {
  LOAD_TYPES_REQUEST = '@bannerList/LOAD_TYPES_REQUEST',
  LOAD_TYPES_SUCCESS = '@bannerList/LOAD_TYPES_SUCCESS',
  LOAD_BANNERS_BY_TYPE_REQUEST = '@bannerList/LOAD_BANNERS_BY_TYPE_REQUEST',
  LOAD_BANNERS_BY_TYPE_SUCCESS = '@bannerList/LOAD_BANNERS_BY_TYPE_SUCCESS',
}

export interface IBannerListItem {
  bannerID: number;
  name: string;
  caption: string;
  label: string;
  link: string;
  anotherPage: boolean;
  urlDownload364: string;
  urlDownload688: string;
}

export interface IBannerListTypes {
  id: number;
  nome: string;
}

export interface IBannerDataItem {
  id: number;
  tipoId: number;
  industriaCnpj: string;
  vigenciaDe: string;
  vigenciaAte: string;
  url: string;
  imagemUri: string;
  imagemMobileUri: string;
  ordem: number;
  janela: boolean;
  clicavel: boolean;
}

export interface IBannersDataList {
  [key: number]: IBannerDataItem[];
}

export interface IBannerListState {
  readonly data: IBannersDataList;
  readonly types: IBannerListTypes[];
  readonly loading: boolean;
  readonly loadingItems: boolean;
  readonly error: boolean;
}

const INITIAL_STATE: IBannerListState = {
  data: {},
  types: [],
  loading: false,
  loadingItems: false,
  error: false,
};

const reducer: Reducer<IBannerListState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BannersListTypes.LOAD_TYPES_REQUEST:
      return { ...state, loading: true };
    case BannersListTypes.LOAD_TYPES_SUCCESS:
      return { ...state, loading: false, error: false, types: action.payload.data };
    case BannersListTypes.LOAD_BANNERS_BY_TYPE_REQUEST:
      return { ...state, loadingItems: true };
    case BannersListTypes.LOAD_BANNERS_BY_TYPE_SUCCESS:
      let items: any = {};

      action.payload.data.forEach((e, i) => {
        if (e) {
          items = {
            ...items,
            [Object.keys(e)[0]]: e[Object.keys(e)[0]],
          };
        }
      });

      return {
        ...state,
        loadingItems: false,
        error: false,
        data: { ...state.data, ...items },
      };
    default:
      return state;
  }
};

export default reducer;
