import { Reducer } from 'redux';
import Utils from 'common/Utils';

export enum UploadChainingNetworkListTypes {
  LOAD_REQUEST = '@uploadChainingNetwork/LOAD_REQUEST',
  LOAD_SUCCESS = '@uploadChainingNetwork/LOAD_SUCCESS',
  LOAD_FAILURE = '@uploadChainingNetwork/LOAD_FAILURE',
}

export interface IUploadChainingNetworkListItem {
  id: number;
  nome_Arquivo_Original: string;
  dt_Processamento_Original: string;
  enriquecido: boolean;
  url_arquivo_alterado: string;
}

export interface IUploadChainingNetworkListState {
  readonly data: IUploadChainingNetworkListItem[];
  readonly loading: boolean;
  readonly error: boolean;
}

const INITIAL_STATE: IUploadChainingNetworkListState = {
  data: [],
  loading: false,
  error: false,
};

const reducer: Reducer<IUploadChainingNetworkListState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UploadChainingNetworkListTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case UploadChainingNetworkListTypes.LOAD_SUCCESS:
      const formated = action.payload.data.map((e: IUploadChainingNetworkListItem) => {
        return {
          ...e,
          dt_Processamento_Original: Utils.setData(e.dt_Processamento_Original),
        };
      });
      return { ...state, loading: false, error: false, data: formated };
    case UploadChainingNetworkListTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true, data: [] };
    default:
      return state;
  }
};

export default reducer;
