import { Reducer } from 'redux';

export enum CampaignReportTypes {
  LOAD_TYPE_CAMPAIGN_REPORT_REQUEST = '@CampaignReport/TYPE_CAMPAIGN_REPORT_REQUEST',
  LOAD_TYPE_CAMPAIGN_REPORT_SUCCESS = '@CampaignReport/TYPE_CAMPAIGN_REPORT_SUCCESS',
  LOAD_TYPE_CAMPAIGN_REPORT_FAILURE = '@CampaignReport/TYPE_CAMPAIGN_REPORT_FAILURE',

  GENERATE_CAMPAIGN_REPORT_REQUEST = '@CampaignReport/GENERATE_CAMPAIGN_REPORT_REQUEST',
  GENERATE_CAMPAIGN_REPORT_SUCCESS = '@CampaignReport/GENERATE_CAMPAIGN_REPORT_SUCCESS',
  GENERATE_CAMPAIGN_REPORT_FAILURE = '@CampaignReport/GENERATE_CAMPAIGN_REPORT_FAILURE',
}

export interface ICampaignReportParams {
  tipoCampanhaRelatorio: number;
  pagination: {
    limit: number;
    offset: number;
  };
}

export interface ITypeCampaignReportItem {
  id: number;
  nome: string;
}

export interface ICampaignReportState {
  readonly typesReports: ITypeCampaignReportItem[];
  readonly loading: boolean;
  readonly error: boolean;
  readonly success: boolean;
  readonly loadingReport: boolean;
  readonly report: string;
}

const INITIAL_STATE: ICampaignReportState = {
  typesReports: [],
  loading: false,
  error: false,
  success: false,
  loadingReport: false,
  report: '',
};

const reducer: Reducer<ICampaignReportState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CampaignReportTypes.LOAD_TYPE_CAMPAIGN_REPORT_REQUEST:
      return { ...state, loading: true };
    case CampaignReportTypes.LOAD_TYPE_CAMPAIGN_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        typesReports: action.payload.data,
      };
    case CampaignReportTypes.LOAD_TYPE_CAMPAIGN_REPORT_FAILURE:
      return { ...state, loading: false, error: true, success: false, typesReports: [] };

    case CampaignReportTypes.GENERATE_CAMPAIGN_REPORT_REQUEST:
      return { ...state, loadingReport: true };
    case CampaignReportTypes.GENERATE_CAMPAIGN_REPORT_SUCCESS:
      return { ...state, report: action.payload.data, success: true };
    case CampaignReportTypes.GENERATE_CAMPAIGN_REPORT_FAILURE:
      return { ...state, loadingReport: false, error: true, success: false };

    default:
      return state;
  }
};

export default reducer;
