import { Badge, createStyles, IconButton, makeStyles, Theme } from '@material-ui/core';
import { CloudDone, CloudDownload } from '@material-ui/icons';
import { TABLE_STYLE } from 'common/Constants';
import Utils from 'common/Utils';
import GenericTable from 'components/GenericTable/GenericTable';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { IParticipantSeparateInvoiceDataFile } from 'store/participantSeparateInvoice/uploadFile';
import * as ParticipantSeparateInvoiceFileActions from 'store/participantSeparateInvoice/uploadFile/actions';

interface UploadConectaPayDataFileProps {
  uploadFile: IParticipantSeparateInvoiceDataFile[];
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      color: theme.palette.info.dark,
    },
    error: {
      color: theme.palette.error.main,
    },
    sucess: {
      color: theme.palette.success.dark,
    },
    ativo: {
      color: '#046dca',
    },
    inativo: {
      color: 'red',
    },
  }),
);

const ParticipantSeparateInvoiceFile: React.FC<UploadConectaPayDataFileProps> = ({
  uploadFile,
}) => {
  const classes = useStyle();

  return !!uploadFile.length ? (
    <GenericTable
      title="Seleções Customizadas"
      isLoading={false}
      data={uploadFile}
      options={{
        paging: false,
        search: false,
        rowStyle: rowData => {
          if (rowData.ativo) {
            return { color: '#046dca' };
          } else {
            return { color: 'red' };
          }
        },
      }}
      columns={[
        {
          title: 'Data envio',
          render: rowData => {
            return `${Utils.setDataHora(rowData.dataInclusao)}`;
          },
          headerStyle: {
            textAlign: 'right',
          },
          cellStyle: { ...TABLE_STYLE, textAlign: 'right' },
        },
        {
          title: 'Nome do arquivo',
          field: 'nomeArquivo',
          cellStyle: { ...TABLE_STYLE },
        },
        {
          title: 'Total participantes',
          field: 'totalParticipantes',
          headerStyle: {
            textAlign: 'center',
          },
          cellStyle: { ...TABLE_STYLE, textAlign: 'center' },
        },
        {
          title: 'Status do processamento',
          render: rowData => {
            return `${
              !rowData.processado
                ? 'Em Processamento'
                : rowData.sucesso
                ? 'Processado com exito'
                : 'Processado com falha'
            }`;
          },
          cellStyle: { ...TABLE_STYLE },
        },
        {
          title: 'Mensagem Status',
          field: 'mensagemStatus',
          cellStyle: { ...TABLE_STYLE },
        },
        {
          title: 'Nome do usuário',
          field: 'usuarioNome',
          cellStyle: { ...TABLE_STYLE },
        },
        {
          title: 'Status',
          headerStyle: {
            textAlign: 'center',
          },
          cellStyle: {
            textAlign: 'center',
            ...TABLE_STYLE,
          },
          render: rowData => {
            return (
              <>
                {rowData.ativo ? (
                  <Badge badgeContent="Ativo" color="primary" children={''} />
                ) : (
                  <Badge badgeContent="Inativo" color="error" children={''} />
                )}
              </>
            );
          },
        },
        {
          title: 'Ações',
          cellStyle: {
            width: '15%',
            color: '#046dca',
            ...TABLE_STYLE,
          },
          render: ({ id, downloadUrl, retornoUrl, ativo }) => (
            <>
              <IconButton
                onClick={() => window.open(downloadUrl)}
                className={ativo ? classes.ativo : classes.inativo}
              >
                <CloudDownload />
              </IconButton>
              {retornoUrl && (
                <IconButton
                  onClick={() => window.open(retornoUrl)}
                  className={ativo ? classes.ativo : classes.inativo}
                >
                  <CloudDone />
                </IconButton>
              )}
            </>
          ),
        },
      ]}
    />
  ) : null;
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(ParticipantSeparateInvoiceFileActions, dispatch);

export default connect(null, mapDispatchToProps)(ParticipantSeparateInvoiceFile);
