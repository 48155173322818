import { action } from 'typesafe-actions';
import { KpiTypes, IKpi, IConfiguredKPI, IKpiFile, IFdvProfile } from './';

export const loadRequest = (id: number) => action(KpiTypes.LOAD_REQUEST, { id });
export const loadSuccess = (data: IKpi) => action(KpiTypes.LOAD_SUCCESS, { data });
export const loadFailure = () => action(KpiTypes.LOAD_FAILURE);
export const loadConfiguredKpi = (id: number) => action(KpiTypes.CONFIGURED_KPI_REQUEST, { id });
export const loadConfiguredKpiSuccess = (data: IConfiguredKPI) =>
  action(KpiTypes.CONFIGURED_KPI_SUCCESS, { data });
export const loadConfiguredKpiFailure = () => action(KpiTypes.CONFIGURED_KPI_FAILURE);

export const genericRequestSuccess = () => action(KpiTypes.GENERIC_SUCCESS);
export const genericRequestFailure = () => action(KpiTypes.GENERIC_FAILURE);

export const saveKpiRequest = (data: any) => action(KpiTypes.SAVE_KPI_REQUEST, { data });

export const deleteKpiRequest = (url: string, options?: object) =>
  action(KpiTypes.DELETE_KPI_REQUEST, { url, options });

export const uploadKpiFileCampaignRegularRequest = (
  file: any,
  id: number,
  mes: number,
  ano: number,
) => action(KpiTypes.UPLOAD_KPI_FILE_CAMPAIGN_REGULAR_REQUEST, { file, id, mes, ano });

export const uploadKpiFileRequest = (
  file: any,
  id: number,
  mes: number,
  ano: number,
  eanReferencia,
) => action(KpiTypes.UPLOAD_KPI_FILE_REQUEST, { file, id, mes, ano, eanReferencia });

export const uploadKpiFileSellinRequest = (
  file: any,
  id: number,
  mes: number,
  ano: number,
  eanReferencia,
  tipoCampanhaId,
) =>
  action(KpiTypes.UPLOAD_KPI_FILE_SELLIN_REQUEST, {
    file,
    id,
    mes,
    ano,
    eanReferencia,
    tipoCampanhaId,
  });

export const uploadKpiFileSellinVeoRequest = (
  file: any,
  id: number,
  mes: number,
  ano: number,
  eanReferencia,
  tipoCampanhaId,
  tipoVeoId,
) =>
  action(KpiTypes.UPLOAD_KPI_FILE_SELLIN_VEO_REQUEST, {
    file,
    id,
    mes,
    ano,
    eanReferencia,
    tipoCampanhaId,
    tipoVeoId,
  });

export const uploadKpiFileVeoRequest = (
  file: any,
  id: number,
  mes: number,
  ano: number,
  tipoMetaId,
) => action(KpiTypes.UPLOAD_KPI_FILE_VEO_REQUEST, { file, id, mes, ano, tipoMetaId });

export const uploadKpiFileSuccess = (data: IKpiFile) =>
  action(KpiTypes.UPLOAD_KPI_FILE_SUCCESS, { data });
export const uploadKpiFileFailure = () => action(KpiTypes.UPLOAD_KPI_FILE_FAILURE);
export const checkUploadKpiFile = (campaignId: number, id: number) =>
  action(KpiTypes.KPI_FILE_PROCESSED_REQUEST, { campaignId, id });
export const checkUploadKpiFileSuccess = (data: any) =>
  action(KpiTypes.KPI_FILE_PROCESSED_SUCCESS, { data });
export const loadFdvProfiles = () => action(KpiTypes.FDV_PROFILES_REQUEST);
export const loadFdvProfilesSuccess = (data: IFdvProfile) =>
  action(KpiTypes.FDV_PROFILES_SUCCESS, { data });
export const resetConfiguredKpi = () => action(KpiTypes.RESET_CONFIGURED_KPI);
export const resetKpiFile = () => action(KpiTypes.RESET_KPI_FILE);
