import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import {
  createPopupSuccess,
  loadPopupSuccess,
  applyPopupFilterSuccess,
  loadPopupFailure,
  loadPopupFiltersPDVSuccess,
  loadPopupFiltersFDVSuccess,
  loadPopupFiltersCompleted,
  loadPopupFilterAppliedParticipantCountPDV,
  loadPopupFilterAppliedParticipantCountFDV,
  uploadPopupParticipantSuccess,
  uploadPopupParticipantFailure,
  loadParticipantsFileListSuccess,
  loadParticipantsFileListFailure,
  deleteParticipantFileSuccess,
  deleteParticipantFileFailure,
  loadFilterClustersSuccess,
  loadFilterClustersFailure,
  loadFilterMethodsPaymentsSuccess,
  loadFilterMethodsPaymentsFailure,
  loadFilterRegionsSuccess,
  loadFilterRegionsFailure,
  loadFilterTerritorySuccess,
  loadFilterTerritoryFailure,
  loadFilterZoneSuccess,
  loadFilterZoneFailure,
} from './actions';
import { showToastr } from 'store/toastr/actions';
import { AppMessages } from 'common/AppMessages.enum';
import { push } from 'connected-react-router';
import store from 'store';
import { loadSegmentsByClusters } from 'store/segments/actions';
import { loadClustersByIndustries } from 'store/clusters/actions';
import { loadIndustriesByRegions } from 'store/industries/actions';
import { STRUCTURE_PULICTARGET } from 'common/Constants';

export function* loadPopup(action) {
  try {
    const { data } = yield call(API.get, `popup/detalhe/${action.payload.id}`);
    yield put(loadPopupSuccess(data.popup));
  } catch (error) {
    console.log(error);
    yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
  }
}

export function* createPopup(action) {
  try {
    const { data } = yield call(API.post, 'popup/create', action.payload.data);

    yield put(createPopupSuccess(data));

    yield put(
      showToastr({ message: AppMessages.SUCCESS_CREATE_POPUP, type: 'success', open: true }),
    );

    yield put(push(`/popups/editar/${data.id}`));
  } catch (error) {
    yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
  }
}

export function* updatePopup(action) {
  try {
    const { data } = yield call(API.patch, 'popup/update', action.payload.data);
    yield put(createPopupSuccess(data));
    yield put(
      showToastr({ message: AppMessages.UPDATE_CREATE_POPUP, type: 'success', open: true }),
    );
  } catch (error) {
    yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
  }
}

export function* applyPopupFilter(action) {
  try {
    const { data } = yield call(
      API.post,
      `/popup/${action.payload.id}/aplicarFiltro`,
      action.payload.filter,
    );
    yield put(applyPopupFilterSuccess(data));
    yield put(
      showToastr({ message: AppMessages.SUCCESS_APPLYFILTER, type: 'success', open: true }),
    );
    const typeId = store.getState().popupEdit.data.codigoTipo;
    yield put(push(`/popups/editar/${typeId}/${action.payload.id}`));
  } catch (error) {
    yield put(loadPopupFailure());
    yield put(showToastr({ message: AppMessages.ERROR_APPLYFILTER, type: 'error', open: true }));
  }
}

export function* loadPopupFilters(action) {
  try {
    const { data } = yield call(
      API.get,
      `/popup/${action.payload.popupId}/filtro/${action.payload.moduleType}`,
    );

    if (action.payload.moduleType === STRUCTURE_PULICTARGET.PDV) {
      yield put(loadPopupFiltersPDVSuccess(data));
    } else {
      yield put(loadPopupFiltersFDVSuccess(data));
    }

    if (data.filtro.regioes.length > 0) {
      const regs = data.filtro.regioes.map(e => e.name);
      yield put(loadIndustriesByRegions(regs));
    }

    if (data.filtro.distribuidores.length > 0) {
      const dists = data.filtro.distribuidores.map(e => e.name);
      yield put(loadClustersByIndustries(dists));
    }

    if (data.filtro.clusters.length > 0) {
      const ownClusters = store.getState().clusters.data;
      const clusters = ownClusters
        .filter(e => data.filtro.clusters.map(x => x.name).includes(e.nome))
        .map(e => e.id);
      yield put(loadSegmentsByClusters(clusters));
    }

    yield put(loadPopupFiltersCompleted());

    if (data.filtro && action.payload.moduleType === STRUCTURE_PULICTARGET.PDV) {
      let contagem;
      contagem = yield call(API.post, `/campanha/contagem/filtro`, [
        ...data.filtro.segmentos.map(({ id, name, selected, tipo }) => {
          return {
            id,
            nome: name,
            selected,
            tipo,
            value: name,
          };
        }),
      ]);
      yield put(loadPopupFilterAppliedParticipantCountPDV(contagem.data.total));
    }

    if (data.filtro && action.payload.moduleType === STRUCTURE_PULICTARGET.SALES_FORCE) {
      let contagem;
      contagem = yield call(API.post, `/campanha/contagem/filtro`, [
        ...data.filtro.perfis.map(({ id, name, selected, tipo }) => {
          return {
            id,
            nome: name,
            selected,
            tipo,
            value: id?.toString(),
          };
        }),
      ]);
      yield put(loadPopupFilterAppliedParticipantCountFDV(contagem.data.total));
    }
  } catch (error) {
    yield put(loadPopupFailure());
    yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
  }
}

export function* uploadPopupParticipant(action) {
  try {
    const { data } = yield call(API.post, action.payload.uploadUrl, action.payload.data);
    if (data.sucesso) {
      yield put(
        showToastr({ type: 'success', message: AppMessages.MAILING_UPLOAD_SUCCESS, open: true }),
      );

      yield put(uploadPopupParticipantSuccess(data));
    } else {
      yield put(
        showToastr({ type: 'error', message: AppMessages.ERROR_MAILING_PROCCESS, open: true }),
      );
      yield put(uploadPopupParticipantFailure());
    }
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
    yield put(uploadPopupParticipantFailure());
  }
}

export function* loadParticipantsFileList(action) {
  try {
    const { data } = yield call(
      API.get,
      `/popup/ListaParticipanteArquivo/${action.payload.popupId}`,
    );

    yield put(loadParticipantsFileListSuccess(data));
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
    yield put(loadParticipantsFileListFailure());
  }
}

export function* deleteParticipantFile(action) {
  try {
    const { data } = yield call(
      API.post,
      `/popup/ListaParticipanteArquivo/Excluir/${action.payload.popupParticipantFileId}`,
    );

    yield put(deleteParticipantFileSuccess(data));
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
    yield put(deleteParticipantFileFailure());
  }
}

export function* loadFilterClusters() {
  try {
    const { data } = yield call(API.get, `/popup/clusters`);

    yield put(loadFilterClustersSuccess(data));
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
    yield put(loadFilterClustersFailure());
  }
}

export function* loadFilterMethodsPayments() {
  try {
    const { data } = yield call(API.get, `/popup/metodos-pagamentos`);

    yield put(loadFilterMethodsPaymentsSuccess(data));
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
    yield put(loadFilterMethodsPaymentsFailure());
  }
}

export function* loadFilterRegions() {
  try {
    const { data } = yield call(API.get, `/popup/regioes`);

    yield put(loadFilterRegionsSuccess(data));
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
    yield put(loadFilterRegionsFailure());
  }
}

export function* loadFilterTerritory() {
  try {
    const { data } = yield call(API.get, `/popup/territorios`);

    yield put(loadFilterTerritorySuccess(data));
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
    yield put(loadFilterTerritoryFailure());
  }
}

export function* loadFilterZone() {
  try {
    const { data } = yield call(API.get, `/popup/zonas`);

    yield put(loadFilterZoneSuccess(data));
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
    yield put(loadFilterZoneFailure());
  }
}
