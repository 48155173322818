import axios from 'axios';
import Api from './Api';
import { ACCESS_TOKEN, LAST_PAGE } from './Constants';
import Utils from './Utils';

export const getAdminToken = async payload => {
  try {
    const response = await Api.post(`${process.env.REACT_APP_API_URL}/auth`, payload);

    if (response.data && response.data.success) {
      const { data } = response.data;

      Utils.setStorageItem(ACCESS_TOKEN, data.accessToken);

      const lastPage = Utils.getStorageItem(LAST_PAGE);

      if (lastPage) {
        window.location.pathname = lastPage;
      }

      const getMenu = await Api.get('/menus');
      if (getMenu.data && getMenu.data.status == 200) {
        // let existisDashBoardMenu = getMenu.data.data.filter(w => w.label === 'Dashboard');

        // if (existisDashBoardMenu != null) Utils.setStorageItem('dashboard', 'true');

        // let menuWithOutDashboard = getMenu.data.data.filter(w => w.label !== 'Dashboard');
        Utils.setStorageItem('menus', JSON.stringify(getMenu.data.data));
      }
      return data;
    }
  } catch (err) {
    console.log(err);
  }
};
