import { action } from 'typesafe-actions';
import { CampaignReportTypes, ITypeCampaignReportItem, ICampaignReportParams } from '.';

export const loadTypesCampaignReportRequest = () =>
  action(CampaignReportTypes.LOAD_TYPE_CAMPAIGN_REPORT_REQUEST);

export const loadTypesCampaignReportSuccess = (data: ITypeCampaignReportItem[]) =>
  action(CampaignReportTypes.LOAD_TYPE_CAMPAIGN_REPORT_SUCCESS, { data });

export const loadTypesCampaignReportFailure = () =>
  action(CampaignReportTypes.LOAD_TYPE_CAMPAIGN_REPORT_FAILURE);

export const generateCampaignReportRequest = (data: ICampaignReportParams) =>
  action(CampaignReportTypes.GENERATE_CAMPAIGN_REPORT_REQUEST, { data });

export const generateCampaignReportSuccess = (data: undefined) =>
  action(CampaignReportTypes.GENERATE_CAMPAIGN_REPORT_SUCCESS, { data });

export const generateCampaignReportFailure = () =>
  action(CampaignReportTypes.GENERATE_CAMPAIGN_REPORT_FAILURE);
