import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import FileUpload from 'components/FileUpload/FileUpload';
import * as ParticipantSeparateInvoiceFileActions from 'store/participantSeparateInvoice/uploadFile/actions';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import { Dispatch, bindActionCreators } from 'redux';
import { MODULE_TYPE } from 'common/Constants';
import ParticipantSeparateInvoiceFile from './uploadFile/ParticipantSeparateInvoiceFile';
import ModalConfirmation, {
  IModalConfirmationState,
} from 'components/ModalConfirmation/ModalConfirmation';
import DownloadClientParticipantSeparateInvoiceExcel from 'components/DownloadClientBase/DownloadClientParticipantSeparateInvoiceExcel';

interface UploadReportProps {
  participantSeparateInvoiceId?: number;
  loadParticipantSeparateInvoiceFile(
    participantSeparateInvoiceId: number,
    moduleType: MODULE_TYPE,
  ): void;
  uploadParticipantSeparateInvoiceCsv(file: FormData): void;
  uploadFile;
  loading: boolean;
}

const ParticipantSeparateInvoice: React.FC<UploadReportProps> = ({
  participantSeparateInvoiceId,
  loadParticipantSeparateInvoiceFile,
  uploadFile,
  loading,
  uploadParticipantSeparateInvoiceCsv,
}) => {
  const [open, setOpen] = useState(false);

  const [modalConfirmation, setModalConfirmation] = useState<IModalConfirmationState>({
    open: false,
    onConfirm: null,
  });

  useEffect(() => {
    loadParticipantSeparateInvoiceFile(Number(participantSeparateInvoiceId), 1);
    // eslint-disable-next-line
  }, []);

  const modalProps = {
    open,
    setOpen,
  };

  const handleUpload = (file: any) => {
    const data = new FormData();
    data.append('file', file);

    setModalConfirmation({
      open: true,
      onConfirm: () => uploadParticipantSeparateInvoiceCsv(data),
    });
    return;
  };

  return (
    <>
      <Box>
        <Box fontSize="h5.fontSize" mt="1em">
          Definir seleção customizada:
        </Box>
        <Box fontSize={14} mb="1em">
          <strong>Importante!</strong> Para realizar a seleção customizada faça o upload de um
          arquivo Excel contendo a base de CodigoSap elegíveis.
        </Box>

        <FileUpload loading={loading} onUpload={handleUpload} acceptType={['xls', 'xlsx']} />

        <Box mt="1em">
          <DownloadClientParticipantSeparateInvoiceExcel />
        </Box>

        <Box mt="1em">
          <ParticipantSeparateInvoiceFile uploadFile={uploadFile} {...modalProps} />
        </Box>
      </Box>
      <ModalConfirmation
        title="Notas Fiscais separadas"
        content={
          'Confirma o envio do arquivo contendo os códigos SAPs dos Participantes que irão receber as Notas Fiscais separadas?'
        }
        state={modalConfirmation}
        setState={setModalConfirmation}
      />
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  uploadFile: state.storeParticipantSeparateInvoiceFile.data,
  loading: state.storeParticipantSeparateInvoiceFile.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(ParticipantSeparateInvoiceFileActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantSeparateInvoice);
