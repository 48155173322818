import { Reducer } from 'redux';

export enum PlanManagementTypes {
  LOAD_ALL_PLAN_REQUEST = '@planManagementTypes/LOAD_ALL_PLAN_REQUEST',
  LOAD_ALL_PLAN_SUCCESS = '@planManagementTypes/LOAD_ALL_PLAN_SUCCESS',
  LOAD_ALL_PLAN_FAILURE = '@planManagementTypes/LOAD_ALL_PLAN_FAILURE',
  LOAD_DETAIL_FILE_REQUEST = '@planManagementTypes/LOAD_DETAIL_FILE_REQUEST',
  LOAD_DETAIL_FILE_SUCCESS = '@planManagementTypes/LOAD_DETAIL_FILE_SUCCESS',
  LOAD_DETAIL_FILE_FAILURE = '@planManagementTypes/LOAD_DETAIL_FILE_FAILURE',
  LOAD_IMPORTATION_BY_LOT_REQUEST = '@planManagementTypes/LOAD_IMPORTATION_BY_LOT_REQUEST',
  LOAD_IMPORTATION_BY_LOT_SUCCESS = '@planManagementTypes/LOAD_IMPORTATION_BY_LOT_SUCCESS',
  LOAD_IMPORTATION_BY_LOT_FAILURE = '@planManagementTypes/LOAD_IMPORTATION_BY_LOT_FAILURE',
}

export interface IPlan {
  id: number;
  valor: number;
  descricao: string;
}

export interface IImportByLotList {
  id: number;
  nomeArquivo: string;
  descricaoStatus: string;
}

export interface IDetailListDataFile {
  numeroLinha: number;
  sap: number;
  descricaoErro: string;
}

export interface IPlanManagementState {
  planAvaliableList: {
    data: IPlan[];
    loading: boolean;
    error: boolean;
  };
  detailFileList: {
    data: IDetailListDataFile[];
    loading: boolean;
    error: boolean;
  };
  importationByLotList: {
    data: IImportByLotList[];
    loading: boolean;
    error: boolean;
  };
}

export const INITIAL_STATE: IPlanManagementState = {
  planAvaliableList: {
    data: [],
    loading: false,
    error: false,
  },
  detailFileList: {
    data: [],
    loading: false,
    error: false,
  },
  importationByLotList: {
    data: [],
    loading: false,
    error: false,
  },
};

const reducer: Reducer<IPlanManagementState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PlanManagementTypes.LOAD_ALL_PLAN_REQUEST:
      return {
        ...state,
        planAvaliableList: {
          ...state.planAvaliableList,
          loading: true,
        },
      };
    case PlanManagementTypes.LOAD_ALL_PLAN_SUCCESS:
      return {
        ...state,
        planAvaliableList: {
          ...state.planAvaliableList,
          data: [...action.payload.data],
          loading: false,
          success: true,
        },
      };
    case PlanManagementTypes.LOAD_ALL_PLAN_FAILURE:
      return {
        ...state,
        planAvaliableList: {
          ...state.planAvaliableList,
          loading: false,
          error: false,
        },
      };
    case PlanManagementTypes.LOAD_DETAIL_FILE_REQUEST:
      return {
        ...state,
        detailFileList: {
          ...state.detailFileList,
          loading: true,
        },
      };
    case PlanManagementTypes.LOAD_DETAIL_FILE_SUCCESS:
      return {
        ...state,
        detailFileList: {
          ...state.detailFileList,
          data: [...action.payload.data],
          loading: false,
          success: true,
        },
      };
    case PlanManagementTypes.LOAD_DETAIL_FILE_FAILURE:
      return {
        ...state,
        detailFileList: {
          ...state.detailFileList,
          loading: false,
          error: false,
        },
      };
    case PlanManagementTypes.LOAD_IMPORTATION_BY_LOT_REQUEST:
      return {
        ...state,
        importationByLotList: {
          ...state.importationByLotList,
          loading: true,
        },
      };
    case PlanManagementTypes.LOAD_IMPORTATION_BY_LOT_SUCCESS:
      return {
        ...state,
        importationByLotList: {
          ...state.importationByLotList,
          data: [...action.payload.data],
          loading: false,
          success: true,
        },
      };
    case PlanManagementTypes.LOAD_IMPORTATION_BY_LOT_FAILURE:
      return {
        ...state,
        importationByLotList: {
          ...state.importationByLotList,
          loading: false,
          error: false,
        },
      };
    default:
      return state;
  }
};

export default reducer;
