import { Reducer } from 'redux';
import Utils from 'common/Utils';

export enum CampaignListTypes {
  LOAD_REQUEST = '@campaignList/LOAD_REQUEST',
  LOAD_SUCCESS = '@campaignList/LOAD_SUCCESS',
  LOAD_FAILURE = '@campaignList/LOAD_FAILURE',
}

export interface ICampaignListItem {
  id: number;
  campanhaBanner: [];
  dataInclusao: string;
  industriaCnpj: string;
  industriaNome: string;
  nome: string;
  temRegulamento: boolean;
  temRegulamentoAceite: boolean;
  vigenciaAte: string;
  vigenciaDe: string;
}

export interface ICampaignListState {
  readonly data: ICampaignListItem[];
  readonly loading: boolean;
  readonly error: boolean;
}

const INITIAL_STATE: ICampaignListState = {
  data: [],
  loading: false,
  error: false,
};

const reducer: Reducer<ICampaignListState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CampaignListTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case CampaignListTypes.LOAD_SUCCESS:
      const formated = action.payload.data.map((e: ICampaignListItem) => {
        return {
          ...e,
          vigenciaDe: Utils.setData(e.vigenciaDe),
          vigenciaAte: Utils.setData(e.vigenciaAte),
        };
      });
      return { ...state, loading: false, error: false, data: formated };
    case CampaignListTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true, data: [] };
    default:
      return state;
  }
};

export default reducer;
