import React, { useEffect, useState } from 'react';
import Wrap from 'components/Wrap/Wrap';
import { Breadcrumbs, Typography, Link, Button, Box, IconButton } from '@material-ui/core';
import { IUserListItem } from 'store/user/list';
import { TABLE_STYLE } from 'common/Constants';
import EditIcon from '@material-ui/icons/Edit';
import { RouteComponentProps } from 'react-router-dom';
import GenericTable from 'components/GenericTable/GenericTable';
import Api from '../../../common/Api';

interface IProps extends RouteComponentProps {
  users: IUserListItem[];
  loading: boolean;
  loadPerfil: () => void;
}

const PerfilList: React.FC<IProps> = ({ history }) => {
  const [perfis, setPerfis] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadPerfil();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadPerfil = async () => {
    const responsePerfis = await Api.get('/perfil');
    setLoading(true);

    if (responsePerfis && responsePerfis.status === 200) {
      setPerfis(responsePerfis.data.perfis);
      setLoading(false);
    }
  };

  const handleOnEdit = (id: number) => history.push(`/perfil/editar/${id}`);
  const handleOnCreate = () => history.push(`/perfil/cadastrar`);

  return (
    <>
      <Wrap>
        <Breadcrumbs>
          <Link color="inherit" onClick={() => history.push('/')}>
            Home
          </Link>
          <Typography color="textPrimary">Gestão de Perfis</Typography>
        </Breadcrumbs>
      </Wrap>

      <Box m="0 0 1.5em 0">
        <Button variant="contained" color="primary" onClick={handleOnCreate}>
          Cadastrar Perfil
        </Button>
      </Box>

      <GenericTable
        title="Perfil"
        isLoading={loading}
        data={perfis}
        options={{
          paging: true,
          search: true,
          pageSize: 10,
          sorting: false,
        }}
        columns={[
          {
            searchable: true,
            title: 'Nome',
            field: 'nome',
            cellStyle: { ...TABLE_STYLE, width: '90%' },
          },
          {
            searchable: false,
            title: 'Ações',
            cellStyle: { ...TABLE_STYLE, width: '10%' },
            render: ({ idPerfil }) => (
              <IconButton color="primary" onClick={() => handleOnEdit(idPerfil)}>
                <EditIcon fontSize="small" />
              </IconButton>
            ),
          },
        ]}
      />
    </>
  );
};

export default PerfilList;
