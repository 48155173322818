import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import {
  loadTrackingBonificationReportFailure,
  loadTrackingBonificationReportSuccess,
  trackingBonificationReportCsvFailure,
  trackingBonificationReportCsvSuccess,
} from './actions';

export function* loadTrackingBonificationReportRequest(action) {
  try {
    const { data } = yield call(API.post, 'bonificacaoCIS/relatorioNegocio', action.payload.data);

    yield put(loadTrackingBonificationReportSuccess(data));
  } catch (error) {
    yield put(loadTrackingBonificationReportFailure());
  }
}

export function* loadTrackingBonificationReportCSVRequest(action) {
  try {
    const { data } = yield call(
      API.post,
      'bonificacaoCIS/relatorioNegocioCSV',
      action.payload.data,
    );
    yield put(trackingBonificationReportCsvSuccess(data));
  } catch (error) {
    yield put(trackingBonificationReportCsvFailure());
  }
}
