import { Reducer } from 'redux';
import Utils from 'common/Utils';
import { IElegibleFilter } from 'store/campaign/edit';

export enum BannersEditTypes {
  LOAD_REQUEST = '@bannersEdit/LOAD_REQUEST',
  LOAD_SUCCESS = '@bannersEdit/LOAD_SUCCESS',
  LOAD_FAILURE = '@bannersEdit/LOAD_FAILURE',
  CREATE_REQUEST = '@bannersEdit/CREATE_REQUEST',
  CREATE_SUCCESS = '@bannersEdit/CREATE_SUCCESS',
  UPDATE_REQUEST = '@bannersEdit/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@bannersEdit/UPDATE_SUCCESS',
  APPLYFILTER_REQUEST = '@bannersEdit/APPLYFILTER_REQUEST',
  APPLYFILTER_SUCCESS = '@bannersEdit/APPLYFILTER_SUCCESS',
  APPLYFILTER_FAILURE = '@bannersEdit/APPLYFILTER_FAILURE',
  GETFILTER_REQUEST = '@bannersEdit/GETFILTER_REQUEST',
  GETFILTER_FAILURE = '@bannersEdit/GETFILTER_FAILURE',
  GETFILTER_COMPLETED = '@bannersEdit/GETFILTER_COMPLETED',
  GETFILTER_RESET = '@bannersEdit/GETFILTER_RESET',
  GETFILTER_SETCOMPLETED = '@bannersEdit/GETFILTER_SETCOMPLETED',
  GETFILTER_PDV_SUCCESS = '@bannersEdit/GETFILTER_PDV_SUCCESS',
  GETFILTER_FDV_SUCCESS = '@bannersEdit/GETFILTER_FDV_SUCCESS',
  LOAD_BANNER_FILTER_APPLIED_REQUEST = '@bannersEdit/LOAD_BANNER_FILTER_APPLIED_REQUEST',
  LOAD_BANNER_FILTER_APPLIED_SUCCESS = '@bannersEdit/LOAD_BANNER_FILTER_APPLIED_SUCCESS',
  LOAD_BANNER_FILTER_APPLIED_FAILURE = '@bannersEdit/LOAD_BANNER_FILTER_APPLIED_FAILURE',
  SETFILTER_COUNT_PDV_REQUEST = '@bannersEdit/SETFILTER_COUNT_PDV_REQUEST',
  SETFILTER_COUNT_FDV_REQUEST = '@bannersEdit/SETFILTER_COUNT_FDV_REQUEST',
  RESET_BANNER = '@bannersEdit/RESET_BANNER',
  UPLOAD_PARTICIPANT_REQUEST = '@bannersEdit/UPLOAD_PARTICIPANT_REQUEST',
  UPLOAD_PARTICIPANT_SUCCESS = '@bannersEdit/UPLOAD_PARTICIPANT_SUCCESS',
  UPLOAD_PARTICIPANT_FAILURE = '@bannersEdit/UPLOAD_PARTICIPANT_FAILURE',
  LOAD_PARTICIPANT_REQUEST = '@bannersEdit/LOAD_PARTICIPANT_REQUEST',
  LOAD_PARTICIPANT_SUCCESS = '@bannersEdit/LOAD_PARTICIPANT_SUCCESS',
  LOAD_PARTICIPANT_FAILURE = '@bannersEdit/LOAD_PARTICIPANT_FAILURE',
  DELETE_PARTICIPANT_REQUEST = '@bannersEdit/DELETE_PARTICIPANT_REQUEST',
  DELETE_PARTICIPANT_SUCCESS = '@bannersEdit/DELETE_PARTICIPANT_SUCCESS',
  DELETE_PARTICIPANT_FAILURE = '@bannersEdit/DELETE_PARTICIPANT_FAILURE',
}

export interface IBanners {
  bannerID: number;
  name: string;
  caption: string;
  label: string;
  link: string;
  anotherPage: boolean;
  urlDownload364: string;
  urlDownload688: string;
}

export interface IBannerEdit {
  id: number;
  tipoId: number;
  vigenciaDe: string;
  vigenciaAte: string;
  url: string;
  imagemUri: string;
  imagemMobileUri: string;
  ordem: number;
  janela: boolean;
  clicavel: boolean;
  exibirInadimplente;
  filtros: any;
}

export interface IBannerEditState {
  readonly data: IBannerEdit;
  readonly elegibleFilterPDV: IElegibleFilter;
  readonly elegibleFilterFDV: IElegibleFilter;
  readonly filterApplied: number;
  readonly loading: boolean;
  readonly error: boolean;
  readonly participantUpload: any;
  readonly participantData: any;
  readonly removingParticipantFile: boolean;
}

const INITIAL_STATE: IBannerEditState = {
  data: {
    id: 0,
    clicavel: false,
    imagemMobileUri: '',
    imagemUri: '',
    janela: false,
    exibirInadimplente: false,
    ordem: 0,
    tipoId: 0,
    url: '',
    vigenciaAte: '',
    vigenciaDe: '',
    filtros: {
      ufs: [],
      clusters: [],
      atividades: [],
    },
  },
  filterApplied: 0,
  elegibleFilterPDV: {
    filtros: {
      regioes: [],
      clusters: [],
      distribuidores: [],
      perfis: [],
      segmentos: [],
      elegivelFiltro: 1,
    },
    filterParticipantCount: 0,
    isCompleted: false,
  },
  elegibleFilterFDV: {
    filtros: {
      regioes: [],
      clusters: [],
      distribuidores: [],
      perfis: [],
      segmentos: [],
      elegivelFiltro: 1,
    },
    filterParticipantCount: 0,
    isCompleted: false,
  },
  loading: false,
  error: false,
  participantUpload: {
    loading_upload: false,
    success: false,
  },
  participantData: [],
  removingParticipantFile: false,
};

const reducer: Reducer<IBannerEditState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BannersEditTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case BannersEditTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...action.payload.data,
          filtros:
            action.payload.data.filtros === undefined || action.payload.data.filtros.length === 0
              ? { ...state.data.filtros }
              : action.payload.data.filtros,
          vigenciaDe: Utils.toDateInputValue(action.payload.data.vigenciaDe),
          vigenciaAte: Utils.toDateInputValue(action.payload.data.vigenciaAte),
        },
      };
    case BannersEditTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true, data: { ...state.data } };
    case BannersEditTypes.CREATE_REQUEST:
      return { ...state, loading: true };
    case BannersEditTypes.CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...action.payload.data,
          filtros:
            action.payload.data.filtros === undefined || action.payload.data.filtros.length === 0
              ? { ...state.data.filtros }
              : action.payload.data.filtros,
        },
      };
    case BannersEditTypes.UPDATE_REQUEST:
      return { ...state, loading: true };
    case BannersEditTypes.UPDATE_SUCCESS:
      return { ...state, loading: false, data: action.payload.data };
    case BannersEditTypes.APPLYFILTER_REQUEST:
      return { ...state, loading: true };
    case BannersEditTypes.APPLYFILTER_SUCCESS:
      return { ...state, loading: false, data: { ...state.data } };
    case BannersEditTypes.APPLYFILTER_FAILURE:
      return { ...state, loading: false };
    case BannersEditTypes.GETFILTER_REQUEST:
      return { ...state, loading: true };
    case BannersEditTypes.GETFILTER_RESET:
      return {
        ...state,
        elegibleFilterPDV: { ...INITIAL_STATE.elegibleFilterPDV },
        elegibleFilterFDV: { ...INITIAL_STATE.elegibleFilterFDV },
      };
    case BannersEditTypes.GETFILTER_SETCOMPLETED:
      return {
        ...state,
        elegibleFilterPDV: { ...INITIAL_STATE.elegibleFilterPDV, isCompleted: true },
        elegibleFilterFDV: { ...INITIAL_STATE.elegibleFilterFDV, isCompleted: true },
      };
    case BannersEditTypes.GETFILTER_PDV_SUCCESS:
      return {
        ...state,
        loading: false,
        data: { ...state.data },
        elegibleFilterPDV: {
          ...state.elegibleFilterPDV,
          filtros: action.payload.filtro,
          isCompleted: false,
        },
      };
    case BannersEditTypes.GETFILTER_FDV_SUCCESS:
      return {
        ...state,
        loading: false,
        data: { ...state.data },
        elegibleFilterFDV: {
          ...state.elegibleFilterPDV,
          filtros: action.payload.filtro,
          isCompleted: false,
        },
      };
    case BannersEditTypes.GETFILTER_COMPLETED:
      return {
        ...state,
        loading: false,
        data: { ...state.data },
        elegibleFilterPDV: { ...state.elegibleFilterPDV, isCompleted: true },
        elegibleFilterFDV: { ...state.elegibleFilterFDV, isCompleted: true },
      };
    case BannersEditTypes.SETFILTER_COUNT_PDV_REQUEST:
      return {
        ...state,
        elegibleFilterPDV: {
          ...state.elegibleFilterPDV,
          filterParticipantCount: action.payload.count,
        },
      };
    case BannersEditTypes.SETFILTER_COUNT_FDV_REQUEST:
      return {
        ...state,
        elegibleFilterFDV: {
          ...state.elegibleFilterFDV,
          filterParticipantCount: action.payload.count,
        },
      };
    case BannersEditTypes.RESET_BANNER:
      return {
        ...INITIAL_STATE,
      };
    case BannersEditTypes.UPLOAD_PARTICIPANT_REQUEST:
      return { ...state, loading: false, participantUpload: { loading_upload: true } };
    case BannersEditTypes.UPLOAD_PARTICIPANT_SUCCESS:
      return {
        ...state,
        loading: false,
        participantUpload: { loading_upload: false, success: action.payload.data.sucesso },
      };
    case BannersEditTypes.UPLOAD_PARTICIPANT_FAILURE:
      return {
        ...state,
        loading: false,
        participantUpload: { loading_upload: false, success: false },
      };
    case BannersEditTypes.LOAD_PARTICIPANT_REQUEST:
      return { ...state, loading: false };
    case BannersEditTypes.LOAD_PARTICIPANT_SUCCESS:
      return { ...state, loading: false, participantData: action.payload.data.ret };
    case BannersEditTypes.LOAD_PARTICIPANT_FAILURE:
      return { ...state, loading: false };
    case BannersEditTypes.DELETE_PARTICIPANT_REQUEST:
      return { ...state, loading: false };
    case BannersEditTypes.DELETE_PARTICIPANT_SUCCESS:
      return { ...state, loading: false, removingParticipantFile: action.payload.data.sucesso };
    case BannersEditTypes.DELETE_PARTICIPANT_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default reducer;
