import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import {
  loadRealtimeTotalErrosCadastrosSuccess,
  loadRealtimeTotalErrosCadastrosFailure,
} from './actions';

export function* loadRealtimeTotalErrosCadastrosRequest() {
  try {
    const { data } = yield call(API.get, 'dashboard/realtime/total/erros/cadastros');
    yield put(loadRealtimeTotalErrosCadastrosSuccess(data.itens, data.atualizacao));
  } catch (error) {
    yield put(loadRealtimeTotalErrosCadastrosFailure());
  }
}
