import React, { useState, useEffect, useRef } from 'react';
import {
  FormControl,
  InputLabel,
  FormControlLabel,
  TextField,
  Box,
  Paper,
  createStyles,
  makeStyles,
  Theme,
  Grid,
  Button,
  CircularProgress,
  Select,
  MenuItem,
} from '@material-ui/core';
import cn from 'classnames';
import Utils from 'common/Utils';
import Typography from '@material-ui/core/Typography';
import Api from '../../../common/Api';
import Checkbox from '@material-ui/core/Checkbox';
import * as Yup from 'yup';
import { FormHelperText } from '@material-ui/core';
import store from 'store';
import { showToastr } from 'store/toastr/actions';
import TelephoneMask from '../../../components/Masks/TelephoneMask';
import CPFMask from '../../../components/Masks/CPFMask';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    textCenter: {
      textAlign: 'center',
      display: 'block',
      width: '91%',
    },
    buttonActions: {
      padding: theme.spacing(1),
      minWidth: 120,
      textAlign: 'right',
      '& > button': {
        marginRight: 0,
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }),
);

export interface ListPerfil {
  idPerfil: number;
  nome: string;
  ativo: boolean;
  dataAtualizacao: string;
}

const UserRegister: React.FC<any> = ({ match, history }) => {
  const classes = useStyle();

  const inputLabel = useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = useState(0);
  const [state, setState] = useState({
    idUsuario: null,
    nome: '',
    email: '',
    senha: '',
    cpf: '',
    perfil: '',
    senhaParaEnvioEmail: null,
    telefone: '',
    idPerfil: 0,
    dataInclusao: '',
    dataAtualizacao: null,
    ativo: true,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [listPerfil, setListPerfil] = useState([
    {
      idPerfil: 0,
      nome: 'Selecione o Perfil',
      ativo: true,
      dataAtualizacao: '',
    },
  ]);

  // const regexCPF = new RegExp('/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/');
  // let regexpNumber: RegExp = /([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/;
  useEffect(() => {
    setLabelWidth(inputLabel?.current?.offsetWidth || 0);
  }, [labelWidth]);

  const schema = Yup.object().shape({
    email: Yup.string()
      .email('Insira um email válido!')
      .required('O email é obrigatório!'),
    nome: Yup.string('Insira um nome válido!').required('O Nome é obrigatório!'),
    telefone: Yup.string('Insira um telefone válido!').required('O telefone é obrigatório!'),
    cpf: Yup.string('Insira um cpf válido!').required(),
    // .trim()
    // .matches(/([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/g, 'CPF inválido')
  });

  async function loadUser() {
    if (match.params.id != undefined) {
      const result = await Api.get('/usuarios/' + match.params.id);
      setState(result.data.data);
    }
  }

  useEffect(() => {
    loadUser();
    getPerfilList();
  }, []);

  async function getPerfilList() {
    const resultPerfil = await Api.get('/perfil');
    if (resultPerfil.status === 200) setListPerfil(resultPerfil.data.perfis);
  }

  async function validateForm() {
    try {
      await schema.validate(state, {
        abortEarly: false,
      });
      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        let errors = '';
        err.errors.forEach((er, index) => {
          errors += er;
          if (err.errors.length > 1 && err.errors.length < index) {
            errors += ', ';
          }
        });
        store.dispatch(
          showToastr({
            message: errors,
            open: true,
            type: 'error',
          }),
        );
      }
    }
  }

  const handleUpdate = async () => {
    let validate = await validateForm();
    if (validate) {
      setLoading(true);
      await Api.put(`/usuarios/${state.idUsuario}`, state);
      setButtonDisabled(true);
      setLoading(false);
      setTimeout(function() {
        history.push('/usuarios');
      }, 2000);
    }
  };

  const handleSave = async () => {
    let validate = await validateForm();
    if (validate) {
      setLoading(true);
      await Api.post('usuarios', state);

      setButtonDisabled(true);
      setLoading(false);
      setTimeout(function() {
        history.push('/usuarios');
      }, 2000);
    }
  };

  const handleChangeName = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleChange = (prop: keyof any) => async (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    setState({ ...state, [prop]: event.target.value });

    let data = state;

    data[prop as string] = event.target.value;
    await schema
      .validate(data, {
        abortEarly: false,
      })
      .then(function(value) {
        setError(false);
        setHelperText('');
      })
      .catch(function(err) {
        if (err.errors && err.errors.length > 0) {
          setHelperText(err.errors[0]);
          setError(true);
        }
      });
  };

  return loading ? (
    <CircularProgress />
  ) : (
    <Paper style={{ padding: 16 }}>
      <Typography component="h1" variant="h6">
        INFORMAÇÕES DO USUÁRIO:
      </Typography>
      <Grid container alignItems="flex-start" spacing={2}>
        <Grid item xs={12}></Grid>
        <Grid item xs={6}>
          <FormControl fullWidth={true} variant="outlined" className={classes.formControl}>
            <TextField
              id="nome"
              required={true}
              value={state.nome}
              onChange={handleChange('nome')}
              label="Nome do Usuarío"
              variant="outlined"
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth={true} variant="outlined" className={classes.formControl}>
            <TextField
              id="telefone"
              required={true}
              value={state.telefone}
              onChange={handleChange('telefone')}
              label="Telefone"
              variant="outlined"
              type="text"
              name="telefone"
              fullWidth
              InputProps={{
                inputComponent: TelephoneMask as any,
              }}
            ></TextField>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth={true} variant="outlined" className={classes.formControl}>
            <TextField
              id="email"
              required={true}
              value={state.email}
              onChange={handleChange('email')}
              label="E-mail"
              variant="outlined"
              InputProps={{
                readOnly: !!state.idUsuario,
              }}
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth={true} variant="outlined" className={classes.formControl}>
            <TextField
              id="cpf"
              required={true}
              value={state.cpf}
              disabled={state?.idUsuario !== 0 && state?.idUsuario !== null}
              onChange={e => setState({ ...state, ['cpf']: Utils.maskCpf(e.target.value) })}
              label="CPF"
              variant="outlined"
              type="text"
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth={true} variant="outlined" className={classes.formControl}>
            <InputLabel ref={inputLabel} htmlFor="perfil">
              Perfil de Acesso*
            </InputLabel>
            <Select
              required
              native={false}
              value={state.idPerfil || ''}
              defaultValue={state.idPerfil || ''}
              labelWidth={labelWidth}
              onChange={handleChange('idPerfil')}
              inputProps={{
                name: 'Perfil',
                id: 'idPerfil',
              }}
            >
              {listPerfil.map((perfil: any, index) => {
                return (
                  <MenuItem key={index} value={perfil.idPerfil}>
                    {perfil.nome}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth={true} variant="outlined" className={classes.formControl}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.ativo}
                  onChange={handleChangeName}
                  name="ativo"
                  color="primary"
                />
              }
              label="Ativo"
            />
          </FormControl>
        </Grid>

        <Grid container={true}>
          <Grid item={true} xs={3} className={classes.formControl}>
            <Box fontSize={14} color="red"></Box>
          </Grid>
          <Grid item={true} xs={12} className={cn([classes.buttonActions])}>
            <Button
              onClick={!state.idUsuario ? handleSave : handleUpdate}
              variant="contained"
              color="secondary"
              disabled={buttonDisabled}
            >
              {!state.idUsuario ? 'Cadastrar' : 'Atualizar'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default UserRegister;
