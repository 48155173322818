import React, { useEffect, useState } from 'react';
import {
  Grid,
  FormControl,
  Box,
  TextField,
  createStyles,
  Paper,
  Select,
  makeStyles,
  Typography,
  Button,
  FormHelperText,
  IconButton,
  Tooltip,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Collapse,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from 'store';
import * as ReentryConfigActions from 'store/reentryConfigStore/actions';
import AddBonification from './AddBonification/AddBonification';
import { Add, Visibility } from '@material-ui/icons';
import { ConfigPromotionRequest } from 'store/reentryConfigStore/sagas';
import Utils from 'common/Utils';
import { TABLE_STYLE } from 'common/Constants';
import GenericTable from 'components/GenericTable/GenericTable';
import { IPromoConfig, IPromoList, TipoInclusaoParticipante } from 'store/reentryConfigStore/index';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { AppMessages } from 'common/AppMessages.enum';

const useStyle = makeStyles(() =>
  createStyles({
    formControl: {
      minWidth: 120,
    },
    grid: {
      margin: 'auto',
    },
    paddingLeft: {
      paddingLeft: '12px',
    },
    flex: {
      display: 'flex',
    },
    error: {
      color: '#ff0000',
    },
  }),
);

interface IReentryConfig {
  LoadAllTypesPromotions: () => void;
  LoadAllPromotionRegistred: () => void;
  promotionsList: IPromoConfig[];
  allPromoRegistred: Array<IPromoList>;
  allPromoRegistredLoading: boolean;
}

export interface TriggerPromo extends IPromoConfig {
  selected?: boolean;
}

export enum ETipoEmissaoBoletoInadimplente {
  EMISSAO_SOLICITACAO = 1,
  EMISSAO_FORCADA = 2,
}

export enum ETipoPromocaoIncentivo {
  INADIMPLENCIA_90 = 1,
  REINGRESSO = 2,
}

export interface CreatePromotion {
  descricao: string;
  quantidadeMesesExpiracaoPontos: number;
  vigenciaInicio: string;
  vigenciaFim: string;
  tipoInclusaoParticipante: TipoInclusaoParticipante | 0;
  reingressoPromocaoIncentivoTipo: Array<ReingressoPromocaoIncentivoTipo>;
  tipoReingressoPromocaoIncentivo: ETipoPromocaoIncentivo | 0;
  tipoEmissaoBoletoInadimplente: ETipoEmissaoBoletoInadimplente | null;
}
export interface ErrorsList {
  errorSelect: string;
  errorInput: string;
}

interface ReingressoPromocaoIncentivoTipo {
  tipoPromocaoIncentivoId: number;
  valor: string;
  codigo: string;
  tipoPromocaoIncentivoOpcaoId: number;
}

const IncentivePromoConfig: React.FC<IReentryConfig & RouteComponentProps> = ({
  LoadAllTypesPromotions,
  LoadAllPromotionRegistred,
  promotionsList,
  allPromoRegistred,
  allPromoRegistredLoading,
  history,
}) => {
  const classes = useStyle();
  const [triggerPromos, setTriggerPromos] = useState<Array<TriggerPromo>>([]);
  const [notTriggerPromos, setNotTriggerPromos] = useState<Array<TriggerPromo> | []>([]);
  const [target, setTarget] = useState<CreatePromotion>({
    descricao: '',
    vigenciaInicio: '',
    vigenciaFim: '',
    quantidadeMesesExpiracaoPontos: 0,
    tipoInclusaoParticipante: 0,
    tipoReingressoPromocaoIncentivo: 0,
    tipoEmissaoBoletoInadimplente: null,
    reingressoPromocaoIncentivoTipo: [
      {
        tipoPromocaoIncentivoId: 0,
        valor: '',
        codigo: '',
        tipoPromocaoIncentivoOpcaoId: 0,
      },
      {
        tipoPromocaoIncentivoId: 0,
        valor: '',
        codigo: '',
        tipoPromocaoIncentivoOpcaoId: 0,
      },
    ],
  });
  const [loadingConfig, setLoadingConfig] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorsList, setErrorsList] = useState<ErrorsList[]>([
    { errorSelect: '', errorInput: '' },
    { errorSelect: '', errorInput: '' },
  ]);

  useEffect(() => {
    LoadAllTypesPromotions();
    LoadAllPromotionRegistred();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!promotionsList.length) {
      setTriggerPromos(promotionsList.filter(item => item.trigger));
      setNotTriggerPromos(
        promotionsList
          .filter(item => !item.trigger)
          .map(x => {
            return { ...x, selected: false };
          }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!promotionsList.length]);

  const addMoreConfig = () => {
    setTarget({
      ...target,
      reingressoPromocaoIncentivoTipo: [
        ...target.reingressoPromocaoIncentivoTipo,
        {
          tipoPromocaoIncentivoId: 0,
          valor: '',
          codigo: '',
          tipoPromocaoIncentivoOpcaoId: 0,
        },
      ],
    });

    setErrorsList([...errorsList, { errorSelect: '', errorInput: '' }]);
  };

  const handlePromoIdChange = e => {
    let newArray = target.reingressoPromocaoIncentivoTipo.map((item, i) =>
      i === 0
        ? {
            ...item,
            tipoPromocaoIncentivoId:
              e.target.options.selectedIndex === 0
                ? 0
                : parseInt(
                    e.target.options[e.target.options.selectedIndex].getAttribute(
                      'data-incentivoid',
                    ),
                  ),
            tipoPromocaoIncentivoOpcaoId: parseInt(e.target.value),
            valor: '',
            codigo: e.target.options[e.target.options.selectedIndex].getAttribute('data-codigo'),
          }
        : item,
    );

    // REGRA DE NEGÓCIO - Caso PAGANDO seja TODAS MENSALIDADES, remove opção bonificação ABONO.
    if (newArray[0].codigo === 'todas-mensalidades') {
      setNotTriggerPromos(notTriggerPromos.filter(x => x.codigo !== 'ABONO'));
    } else {
      setNotTriggerPromos(promotionsList.filter(item => !item.trigger));
    }

    setTarget({
      ...target,
      reingressoPromocaoIncentivoTipo: newArray,
    });
  };

  const handlePromoValueChange = e => {
    let newArray = target.reingressoPromocaoIncentivoTipo.map((item, i) =>
      i === 0
        ? {
            ...item,
            valor: e.target.value,
          }
        : item,
    );

    setTarget({
      ...target,
      reingressoPromocaoIncentivoTipo: newArray,
    });
  };

  const handleOnSave = async () => {
    const hasErrors = getErrorMessages();

    if (!hasErrors) {
      setLoadingConfig(true);
      await ConfigPromotionRequest(target);
      LoadAllPromotionRegistred();
      return setLoadingConfig(false);
    }
  };

  const getErrorMessages = (): boolean => {
    const hasErrorInputDescription = Utils.hasInputError([target.descricao], true);

    let getErrors = errorsList.map((item, i) => {
      if (
        target.reingressoPromocaoIncentivoTipo[i].tipoPromocaoIncentivoId === 0 &&
        target.reingressoPromocaoIncentivoTipo[i].tipoPromocaoIncentivoOpcaoId === 0
      ) {
        return { ...item, errorSelect: 'Seleção Obrigatória' };
      }

      if (
        target.reingressoPromocaoIncentivoTipo[i].codigo !== 'todas-mensalidades' &&
        target.reingressoPromocaoIncentivoTipo[i].valor === ''
      ) {
        return { ...item, errorInput: 'Preenchimento obrigatório' };
      }

      if (
        target.reingressoPromocaoIncentivoTipo[i].codigo !== 'todas-mensalidades' &&
        target.reingressoPromocaoIncentivoTipo[i].valor !== ''
      ) {
        return { ...item, errorInput: '', errorSelect: '' };
      }

      if (
        target.reingressoPromocaoIncentivoTipo[i].tipoPromocaoIncentivoId !== 0 &&
        target.reingressoPromocaoIncentivoTipo[i].tipoPromocaoIncentivoOpcaoId !== 0
      ) {
        return { ...item, errorSelect: '' };
      } else {
        return { errorSelect: '', errorInput: '' };
      }
    });

    setErrorsList(getErrors);

    const isNotValidExpirationPoints =
      target.quantidadeMesesExpiracaoPontos <= 0 || target.quantidadeMesesExpiracaoPontos > 99;

    const hasErrosList = getErrors.some(e => e.errorInput !== '' || e.errorSelect !== '');

    const missIncludeParticipantType = target.tipoInclusaoParticipante === 0;

    const missTipoCampanha = target.tipoReingressoPromocaoIncentivo === 0;

    const tipoEmissaoBoletoInadimplente =
      target.tipoReingressoPromocaoIncentivo === ETipoPromocaoIncentivo.INADIMPLENCIA_90 &&
      target.tipoEmissaoBoletoInadimplente === null;

    const isDateValid =
      (!!target.vigenciaInicio || !!target.vigenciaFim) &&
      Utils.compareDate(target.vigenciaInicio, target.vigenciaFim) &&
      target.vigenciaInicio !== target.vigenciaFim;

    setErrorMessage(
      isDateValid ||
        hasErrorInputDescription ||
        isNotValidExpirationPoints ||
        missIncludeParticipantType ||
        missTipoCampanha ||
        tipoEmissaoBoletoInadimplente
        ? 'Campos obrigatórios em branco'
        : '',
    );

    return [
      isDateValid,
      hasErrorInputDescription,
      isNotValidExpirationPoints,
      hasErrosList,
      missIncludeParticipantType,
      missTipoCampanha,
    ].some(e => e);
  };

  const handleToView = (id: number) =>
    history.push(`/pagamentos/promocoesincentivo/visualizar/${id}`);

  const isQtyMonthType = (): boolean => {
    let triggerOptionsWithCode = triggerPromos[0].tipoPromocaoIncentivoOpcoes.map(x => {
      return { ...x, codigo: Utils.slugfy(x.descricao) };
    });

    return target.reingressoPromocaoIncentivoTipo[0].codigo === triggerOptionsWithCode[1].codigo
      ? true
      : false;
  };

  const EmissionModelBankSlipDebtor = () => {
    return (
      <Collapse
        in={target.tipoReingressoPromocaoIncentivo === ETipoPromocaoIncentivo.INADIMPLENCIA_90}
      >
        <FormControl
          required
          error={
            target.tipoReingressoPromocaoIncentivo === ETipoPromocaoIncentivo.INADIMPLENCIA_90 &&
            target.tipoEmissaoBoletoInadimplente === null &&
            !!errorMessage
          }
          fullWidth
          style={{ padding: '10px' }}
        >
          <FormLabel>Modelo de emissão dos boletos</FormLabel>
          <RadioGroup
            row
            aria-label="modelBankSlip"
            name="modelBankSlip"
            value={target.tipoEmissaoBoletoInadimplente}
            onChange={event => {
              setTarget({
                ...target,
                tipoEmissaoBoletoInadimplente: parseInt(event.target.value),
              });
            }}
          >
            <FormControlLabel
              value={ETipoEmissaoBoletoInadimplente.EMISSAO_SOLICITACAO}
              control={<Radio />}
              label="Emissão por Solicitação do Participante"
            />
            <FormControlLabel
              value={ETipoEmissaoBoletoInadimplente.EMISSAO_FORCADA}
              control={<Radio />}
              label="Emissão Forçada pelo Administrador"
            />
          </RadioGroup>
        </FormControl>
      </Collapse>
    );
  };

  return (
    <>
      <Paper elevation={2}>
        <form>
          <Box m="1.5em">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h5" component="h2">
                  Cadastro da Promoção de Incentivo
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth variant="outlined">
                  <Select
                    error={target.tipoReingressoPromocaoIncentivo === 0 && !!errorMessage}
                    required
                    native
                    inputProps={{
                      name: 'tipoPromocaoIncentivoOpcaoId',
                    }}
                    value={target.tipoReingressoPromocaoIncentivo}
                    onChange={(e: React.ChangeEvent<{ value: any }>) =>
                      setTarget({
                        ...target,
                        tipoReingressoPromocaoIncentivo: parseInt(e.target.value),
                        tipoEmissaoBoletoInadimplente:
                          ETipoPromocaoIncentivo.REINGRESSO === parseInt(e.target.value)
                            ? null
                            : target.tipoEmissaoBoletoInadimplente,
                      })
                    }
                  >
                    <option value={0}>Selecione o tipo de campanha...</option>
                    <option value={ETipoPromocaoIncentivo.INADIMPLENCIA_90}>
                      Inadimplencia 90
                    </option>
                    <option value={ETipoPromocaoIncentivo.REINGRESSO}>Reingresso</option>
                  </Select>
                </FormControl>
                {EmissionModelBankSlipDebtor()}
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth className={classes.formControl}>
                  <TextField
                    error={Utils.hasInputError(target.descricao, true) && !!errorMessage}
                    required
                    inputProps={{
                      name: 'descricao',
                      maxLength: 50,
                    }}
                    value={target.descricao}
                    onChange={(e: React.ChangeEvent<{ value: any }>) =>
                      setTarget({ ...target, descricao: e.target.value })
                    }
                    label="Descrição da Campanha"
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth className={classes.formControl}>
                  <TextField
                    error={
                      (target.quantidadeMesesExpiracaoPontos <= 0 ||
                        target.quantidadeMesesExpiracaoPontos > 99) &&
                      !!errorMessage
                    }
                    required
                    value={
                      target.quantidadeMesesExpiracaoPontos > 0
                        ? target.quantidadeMesesExpiracaoPontos
                        : null
                    }
                    onChange={(e: React.ChangeEvent<{ value: any }>) => {
                      if (e.target.value.length <= 2) {
                        setTarget({
                          ...target,
                          quantidadeMesesExpiracaoPontos: parseInt(e.target.value),
                        });
                      }
                    }}
                    label="Meses para expiração de pontos"
                    variant="outlined"
                    type="number"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth className={classes.formControl}>
                  <TextField
                    error={Utils.hasInputError(target.vigenciaInicio, true) && !!errorMessage}
                    required
                    type="date"
                    id="startDate"
                    name="vigenciaDe"
                    value={target.vigenciaInicio.substring(0, 10)}
                    onChange={(e: React.ChangeEvent<{ value: any }>) =>
                      setTarget({ ...target, vigenciaInicio: e.target.value })
                    }
                    label="Início da Vigência"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth className={classes.formControl}>
                  <TextField
                    error={
                      (Utils.hasInputError(target.vigenciaFim, true) ||
                        Utils.compareDate(target.vigenciaInicio, target.vigenciaFim)) &&
                      !!errorMessage
                    }
                    required
                    type="date"
                    id="endDate"
                    name="vigenciaFim"
                    value={target.vigenciaFim.substring(0, 10)}
                    onChange={(e: React.ChangeEvent<{ value: any }>) =>
                      setTarget({ ...target, vigenciaFim: e.target.value })
                    }
                    label="Fim da Vigência"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
                {Utils.compareDate(target.vigenciaInicio, target.vigenciaFim) &&
                  target.vigenciaInicio !== target.vigenciaFim && (
                    <FormHelperText className={classes.error}>
                      {AppMessages.DATE_START_BIGGERTHAN_END}
                    </FormHelperText>
                  )}
              </Grid>
              <Grid item xs={4}>
                <FormControl
                  required
                  error={target.tipoInclusaoParticipante === 0 && !!errorMessage}
                  fullWidth
                >
                  <FormLabel>Qual será o modelo inclusão de participantes?</FormLabel>
                  <RadioGroup
                    row
                    aria-label="modelInputParticipant"
                    name="modelInputParticipant"
                    onChange={event => {
                      setTarget({
                        ...target,
                        tipoInclusaoParticipante: parseInt(event.target.value),
                      });
                    }}
                  >
                    <FormControlLabel
                      value={`${TipoInclusaoParticipante.UPLOAD}`}
                      control={<Radio />}
                      label="Upload via arquivo"
                    />
                    <FormControlLabel
                      value={`${TipoInclusaoParticipante.PDV}`}
                      control={<Radio />}
                      label="Filtro PDV"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid container spacing={3} className={classes.grid}>
                {/* OPTIONS "PAGANDO" */}
                {triggerPromos.map(item => {
                  return (
                    <>
                      <Grid item xs={4}>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                          <Select
                            required
                            native
                            inputProps={{
                              name: 'tipoPromocaoIncentivoId',
                            }}
                            onChange={(e: React.ChangeEvent<{ value: any }>) =>
                              handlePromoIdChange(e)
                            }
                          >
                            <option key={item.id} value={item.id}>
                              {item.descricao}
                            </option>
                          </Select>
                        </FormControl>
                      </Grid>
                      {isQtyMonthType() && (
                        <Grid item xs={4}>
                          <FormControl fullWidth className={classes.formControl}>
                            <TextField
                              required
                              error={
                                target.reingressoPromocaoIncentivoTipo[0].codigo === 'meses' &&
                                target.reingressoPromocaoIncentivoTipo[0].valor === ''
                              }
                              inputProps={{
                                name: 'tipoPromocaoIncentivoValue',
                                maxLength: 2,
                              }}
                              value={target.reingressoPromocaoIncentivoTipo[0].valor}
                              onChange={(e: React.ChangeEvent<{ value: any }>) =>
                                handlePromoValueChange(e)
                              }
                              label="Quantidade Meses"
                              variant="outlined"
                              type="text"
                            />
                          </FormControl>
                          {!!errorsList[0].errorInput && (
                            <FormHelperText className={classes.error}>
                              {errorsList[0].errorInput}
                            </FormHelperText>
                          )}
                        </Grid>
                      )}

                      <Grid item xs={4}>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                          <Select
                            error={!!errorsList[0].errorSelect}
                            required
                            native
                            inputProps={{
                              name: 'tipoPromocaoIncentivoOpcaoId',
                            }}
                            onChange={(e: React.ChangeEvent<{ value: any }>) =>
                              handlePromoIdChange(e)
                            }
                          >
                            <option value={0}>Selecione...</option>
                            {!!item.tipoPromocaoIncentivoOpcoes.length &&
                              item.tipoPromocaoIncentivoOpcoes.map(incentivoOption => {
                                return (
                                  <option
                                    key={incentivoOption.id}
                                    value={incentivoOption.id}
                                    data-incentivoid={incentivoOption.tipoPromocaoIncentivoId}
                                    data-codigo={Utils.slugfy(incentivoOption.descricao)}
                                  >
                                    {incentivoOption.descricao}
                                  </option>
                                );
                              })}
                          </Select>
                          {!!errorsList[0].errorSelect && (
                            <FormHelperText className={classes.error}>
                              {errorsList[0].errorSelect}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </>
                  );
                })}
              </Grid>

              {/* BONIFICATION OPTIONS */}
              {!!notTriggerPromos.length &&
                target.reingressoPromocaoIncentivoTipo.map((x, i) => {
                  if (i > 0) {
                    return (
                      <AddBonification
                        key={i}
                        notTriggerPromos={notTriggerPromos}
                        setNotTriggerPromos={setNotTriggerPromos}
                        setTarget={setTarget}
                        target={target}
                        index={i}
                        errorsList={errorsList}
                        setErrorsList={setErrorsList}
                      />
                    );
                  }
                  return false;
                })}

              {/* Inativo a escolha de + de 1 incentivo pois necessita de desenvolvimento do back  */}
              {/* <Grid container>
                <Grid className={classes.paddingLeft}>
                  <Tooltip
                    open={promotionsList.length === target.reingressoPromocaoIncentivoTipo.length}
                    title="O limite de novos incentivos foi alcançado"
                  >
                    <span>
                      <Button
                        disabled={
                          promotionsList.length === target.reingressoPromocaoIncentivoTipo.length
                        }
                        onClick={addMoreConfig}
                        color="secondary"
                        startIcon={<Add />}
                      >
                        Novo Incentivo
                      </Button>
                    </span>
                  </Tooltip>
                </Grid>
              </Grid> */}

              <Grid item xs={12}>
                <Grid container justifyContent="flex-end" className={classes.flex}>
                  <Button
                    onClick={handleOnSave}
                    variant="contained"
                    disabled={loadingConfig}
                    color="secondary"
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
              <Grid item>
                {!!errorMessage && (
                  <FormHelperText className={classes.error}>{errorMessage}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} />
            </Grid>
          </Box>
        </form>
      </Paper>
      <Paper elevation={2}>
        <GenericTable
          title="Lista de Campanhas Promocionais"
          isLoading={allPromoRegistredLoading}
          data={allPromoRegistred}
          options={{
            paging: true,
            search: true,
            sorting: true,
          }}
          columns={[
            {
              searchable: true,
              title: 'Descrição',
              field: 'descricao',
              cellStyle: { ...TABLE_STYLE, width: '30%' },
            },
            {
              title: 'Data de Cadastro',
              field: 'dataInclusao',
              defaultSort: 'desc',
              type: 'datetime',
              customSort: (a, b) =>
                new Date(a.dataInclusao).getTime() - new Date(b.dataInclusao).getTime(),
              render: rowData => {
                return `${Utils.setDataHora(rowData.dataInclusao)}`;
              },
              cellStyle: { ...TABLE_STYLE },
            },
            {
              title: 'Início da Vigência',
              field: 'vigenciaInicio',
              type: 'datetime',
              render: rowData => {
                return `${Utils.setData(rowData.vigenciaInicio)}`;
              },
              cellStyle: { ...TABLE_STYLE },
            },
            {
              title: 'Fim da Vigência',
              field: 'vigenciaFim',
              type: 'datetime',
              render: rowData => {
                return `${Utils.setData(rowData.vigenciaFim)}`;
              },
              cellStyle: { ...TABLE_STYLE },
            },
            {
              searchable: false,
              title: 'Status',
              field: 'ativo',
              cellStyle: { ...TABLE_STYLE, width: '15%' },
              render: ({ ativo }) => (ativo ? 'Ativo' : 'Inativo'),
            },
            {
              searchable: false,
              title: 'Ações',
              cellStyle: {
                width: '10%',
                ...TABLE_STYLE,
              },
              render: ({ id }) => (
                <IconButton
                  color="primary"
                  onClick={() => handleToView(id)}
                  aria-label="Visualizar detalhes da campanha de incentivo"
                >
                  <Visibility fontSize="small" />
                </IconButton>
              ),
            },
          ]}
        />
      </Paper>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  promotionsList: state.reentryConfigStore.promotionsList.data,
  allPromoRegistred: state.reentryConfigStore.allPromotionRegistred.data,
  allPromoRegistredLoading: state.reentryConfigStore.allPromotionRegistred.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(ReentryConfigActions, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IncentivePromoConfig));
