import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import {
  createBannerSuccess,
  loadBannerSuccess,
  applyBannerFilterSuccess,
  loadBannerFailure,
  loadBannerFiltersPDVSuccess,
  loadBannerFiltersFDVSuccess,
  loadBannerFiltersCompleted,
  loadBannerFilterAppliedParticipantCountPDV,
  loadBannerFilterAppliedParticipantCountFDV,
  uploadBannerParticipantSuccess,
  uploadBannerParticipantFailure,
  loadParticipantsFileListSuccess,
  loadParticipantsFileListFailure,
  deleteParticipantFileSuccess,
  deleteParticipantFileFailure,
} from './actions';
import { showToastr } from 'store/toastr/actions';
import { AppMessages } from 'common/AppMessages.enum';
import { push } from 'connected-react-router';
import store from 'store';
import { loadSegmentsByClusters } from 'store/segments/actions';
import { loadClustersByIndustries } from 'store/clusters/actions';
import { loadIndustriesByRegions } from 'store/industries/actions';
import { STRUCTURE_PULICTARGET } from 'common/Constants';

export function* loadBanner(action) {
  try {
    const { data } = yield call(API.get, `banner/detalhe/${action.payload.id}`);
    yield put(loadBannerSuccess(data.banner));
  } catch (error) {
    console.log(error);
    yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
  }
}

export function* createBanner(action) {
  try {
    const { data } = yield call(API.post, 'banner/create', action.payload.data);
    const typeId = action.payload.data.tipoId;
    yield put(createBannerSuccess(data));
    yield put(
      showToastr({ message: AppMessages.SUCCESS_CREATE_BANNER, type: 'success', open: true }),
    );
    yield put(push(`/banners/editar/${typeId}/${data.id}`));
  } catch (error) {
    yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
  }
}

export function* updateBanner(action) {
  try {
    const { data } = yield call(API.patch, 'banner/update', action.payload.data);
    yield put(createBannerSuccess(data));
    yield put(
      showToastr({ message: AppMessages.UPDATE_CREATE_BANNER, type: 'success', open: true }),
    );
  } catch (error) {
    yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
  }
}

export function* applyBannerFilter(action) {
  try {
    const { data } = yield call(
      API.post,
      `/banner/${action.payload.id}/aplicarFiltro`,
      action.payload.filter,
    );
    yield put(applyBannerFilterSuccess(data));
    yield put(
      showToastr({ message: AppMessages.SUCCESS_APPLYFILTER, type: 'success', open: true }),
    );
    yield put(push(`/banners/editar/${action.payload.tipoId}/${action.payload.id}`));
  } catch (error) {
    yield put(loadBannerFailure());
    yield put(showToastr({ message: AppMessages.ERROR_APPLYFILTER, type: 'error', open: true }));
  }
}

export function* loadBannerFilters(action) {
  try {
    const { data } = yield call(
      API.get,
      `/banner/${action.payload.bannerId}/filtro/${action.payload.moduleType}`,
    );

    if (action.payload.moduleType === STRUCTURE_PULICTARGET.PDV) {
      yield put(loadBannerFiltersPDVSuccess(data));
    } else {
      yield put(loadBannerFiltersFDVSuccess(data));
    }

    if (data.filtro.regioes.length > 0) {
      const regs = data.filtro.regioes.map(e => e.name);
      yield put(loadIndustriesByRegions(regs));
    }

    if (data.filtro.distribuidores.length > 0) {
      const dists = data.filtro.distribuidores.map(e => e.name);
      yield put(loadClustersByIndustries(dists));
    }

    if (data.filtro.clusters.length > 0) {
      const ownClusters = store.getState().clusters.data;
      const clusters = ownClusters
        .filter(e => data.filtro.clusters.map(x => x.name).includes(e.nome))
        .map(e => e.id);
      yield put(loadSegmentsByClusters(clusters));
    }

    yield put(loadBannerFiltersCompleted());

    if (data.filtro && action.payload.moduleType === STRUCTURE_PULICTARGET.PDV) {
      let contagem;
      contagem = yield call(API.post, `/campanha/contagem/filtro`, [
        ...data.filtro.segmentos.map(({ id, name, selected, tipo }) => {
          return {
            id,
            nome: name,
            selected,
            tipo,
            value: name,
          };
        }),
      ]);
      yield put(loadBannerFilterAppliedParticipantCountPDV(contagem.data.total));
    }

    if (data.filtro && action.payload.moduleType === STRUCTURE_PULICTARGET.SALES_FORCE) {
      let contagem;
      contagem = yield call(API.post, `/campanha/contagem/filtro`, [
        ...data.filtro.perfis.map(({ id, name, selected, tipo }) => {
          return {
            id,
            nome: name,
            selected,
            tipo,
            value: id?.toString(),
          };
        }),
      ]);
      yield put(loadBannerFilterAppliedParticipantCountFDV(contagem.data.total));
    }
  } catch (error) {
    yield put(loadBannerFailure());
    yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
  }
}

export function* uploadBannerParticipant(action) {
  try {
    const { data } = yield call(API.post, action.payload.uploadUrl, action.payload.data);
    if (data.sucesso) {
      yield put(
        showToastr({ type: 'success', message: AppMessages.MAILING_UPLOAD_SUCCESS, open: true }),
      );

      yield put(uploadBannerParticipantSuccess(data));
    } else {
      yield put(
        showToastr({ type: 'error', message: AppMessages.ERROR_MAILING_PROCCESS, open: true }),
      );
      yield put(uploadBannerParticipantFailure());
    }
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
    yield put(uploadBannerParticipantFailure());
  }
}

export function* loadParticipantsFileList(action) {
  try {
    const { data } = yield call(
      API.get,
      `/banner/ListaParticipanteArquivo/${action.payload.bannerId}`,
    );

    yield put(loadParticipantsFileListSuccess(data));
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
    yield put(loadParticipantsFileListFailure());
  }
}

export function* deleteParticipantFile(action) {
  try {
    const { data } = yield call(
      API.post,
      `/banner/ListaParticipanteArquivo/Excluir/${action.payload.bannerParticipantFileId}`,
    );

    yield put(deleteParticipantFileSuccess(data));
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
    yield put(deleteParticipantFileFailure());
  }
}
