import { Reducer } from 'redux';

export enum TrackingBonificationReportTypes {
  LOAD_STATUS_REQUEST = '@TrackingBonificationReportTypes/LOAD_STATUS_REQUEST',
  LOAD_STATUS_SUCCESS = '@TrackingBonificationReportTypes/LOAD_STATUS_SUCCESS',
  LOAD_STATUS_FAILURE = '@TrackingBonificationReportTypes/LOAD_STATUS_FAILURE',
  LOAD_PENDING_CHAINING_NET_REQUEST = '@TrackingBonificationReportTypes/LOAD_PENDING_CHAINING_NET_REQUEST',
  LOAD_PENDING_CHAINING_NET_SUCCESS = '@TrackingBonificationReportTypes/LOAD_PENDING_CHAINING_NET_SUCCESS',
  LOAD_PENDING_CHAINING_NET_FAILURE = '@TrackingBonificationReportTypes/LOAD_PENDING_CHAINING_NET_FAILURE',
  LOAD_CHAINING_NET_DETAIL_REQUEST = '@TrackingBonificationReportTypes/LOAD_CHAINING_NET_DETAIL_REQUEST',
  LOAD_CHAINING_NET_DETAIL_SUCCESS = '@TrackingBonificationReportTypes/LOAD_CHAINING_NET_DETAIL_SUCCESS',
  LOAD_CHAINING_NET_DETAIL_FAILURE = '@TrackingBonificationReportTypes/LOAD_CHAINING_NET_DETAIL_FAILURE',
  APPROVE_CHAINING_NET_REQUEST = '@TrackingBonificationReportTypes/APROVE_CHAINING_NET_REQUEST',
  APPROVE_CHAINING_NET_SUCCESS = '@TrackingBonificationReportTypes/APROVE_CHAINING_NET_SUCCESS',
  APPROVE_CHAINING_NET_FAILURE = '@TrackingBonificationReportTypes/APROVE_CHAINING_NET_FAILURE',
  TRACKING_BONIFICATION_REPORT_CSV_REQUEST = '@TrackingBonificationReportTypes/TRACKING_BONIFICATION_REPORT_REQUEST',
  TRACKING_BONIFICATION_REPORT_CSV_SUCCESS = '@TrackingBonificationReportTypes/TRACKING_BONIFICATION_REPORT_SUCCESS',
  TRACKING_BONIFICATION_REPORT_CSV_FAILURE = '@TrackingBonificationReportTypes/TRACKING_BONIFICATION_REPORT_FAILURE',
  TRACKING_BONIFICATION_REPORT_CSV_RESET = '@TrackingBonificationReportTypes/TRACKING_BONIFICATION_REPORT_RESET',
}

export interface ITrackingBonificationReportParams {
  dataInicial: string;
  dataFinal: string;
}

export interface ITrackingBonificationReportItem {
  codigoSap: number;
  idResgateParceiro: number;
  idLinhaResgateNoParceiro: number;
  dataPedido: string;
  codigoProduto: string;
  nomeProduto: string;
  quantidadeResgatar: number;
  unidadeMedida: number;
}

export interface ITrackingBonificationReportDetailItem {
  matriz: string;
  matrizNome: string;
  matrizStatus: string;
  solicitadoEm: string;
  associado: string;
  associadoNome: string;
  associadoStatus: string;
  varejoPrime: string;
  conectaPayParaAssociado: string;
  dadosBancarios: {
    banco: string;
    agencia: string;
    conta: string;
    nome: string;
    documento: string;
  };
  statusId: number;
  motivoReprovacaoCancelamento: string;
  moderadoPor: string;
  dataModeracao: string;
}

export interface IStatusItem {
  id: number;
  nome: string;
}

export interface ITrackingBonificationReportApproval {
  idSolicitacao: number;
  idStatus: number;
  motivo: string;
}

export interface ITrackingBonificationReportState {
  readonly data: ITrackingBonificationReportItem[];
  readonly statusList: IStatusItem[];
  readonly dataDetail: ITrackingBonificationReportDetailItem;
  readonly loading: boolean;
  readonly loadingReport: boolean;
  readonly error: boolean;
  readonly success: boolean;
  readonly approved: any;
  readonly report: string;
}

export const INITIAL_STATE: ITrackingBonificationReportState = {
  data: [],
  statusList: [],
  dataDetail: {
    matriz: '',
    matrizNome: '',
    matrizStatus: '',
    solicitadoEm: '',
    associado: '',
    associadoNome: '',
    associadoStatus: '',
    varejoPrime: '',
    conectaPayParaAssociado: '',
    dadosBancarios: {
      banco: '',
      agencia: '',
      conta: '',
      nome: '',
      documento: '',
    },
    statusId: 0,
    motivoReprovacaoCancelamento: '',
    moderadoPor: '',
    dataModeracao: '',
  },
  loading: false,
  loadingReport: false,
  error: false,
  success: false,
  approved: undefined,
  report: '',
};

const reducer: Reducer<ITrackingBonificationReportState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TrackingBonificationReportTypes.LOAD_STATUS_REQUEST:
      return { ...state, loading: true };
    case TrackingBonificationReportTypes.LOAD_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        reportList: action.payload.data,
      };
    case TrackingBonificationReportTypes.LOAD_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        reportList: [],
        approved: false,
      };
    case TrackingBonificationReportTypes.LOAD_PENDING_CHAINING_NET_REQUEST:
      return { ...state, loading: true };
    case TrackingBonificationReportTypes.LOAD_PENDING_CHAINING_NET_SUCCESS:
      return { ...state, loading: false, error: false, success: true, data: action.payload.data };
    case TrackingBonificationReportTypes.LOAD_PENDING_CHAINING_NET_FAILURE:
      return { ...state, loading: false, error: true, success: false, data: [] };
    case TrackingBonificationReportTypes.LOAD_CHAINING_NET_DETAIL_REQUEST:
      return { ...state, loading: true, approved: undefined };
    case TrackingBonificationReportTypes.LOAD_CHAINING_NET_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        dataDetail: action.payload.data,
      };
    case TrackingBonificationReportTypes.LOAD_CHAINING_NET_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        dataDetail: INITIAL_STATE.dataDetail,
      };
    case TrackingBonificationReportTypes.APPROVE_CHAINING_NET_REQUEST:
      return { ...state, loading: true };
    case TrackingBonificationReportTypes.APPROVE_CHAINING_NET_SUCCESS:
      return { ...state, approved: action.payload.data.success, success: true, loading: false };
    case TrackingBonificationReportTypes.APPROVE_CHAINING_NET_FAILURE:
      return {
        ...state,
        approved: action.payload.success,
        loading: false,
        error: true,
        success: false,
      };
    case TrackingBonificationReportTypes.TRACKING_BONIFICATION_REPORT_CSV_REQUEST:
      return { ...state, loadingReport: true };
    case TrackingBonificationReportTypes.TRACKING_BONIFICATION_REPORT_CSV_SUCCESS:
      return { ...state, report: action.payload.data, success: true, loadingReport: false };
    case TrackingBonificationReportTypes.TRACKING_BONIFICATION_REPORT_CSV_FAILURE:
      return {
        ...state,
        report: '',
        loadingReport: false,
        error: true,
        success: false,
      };
    case TrackingBonificationReportTypes.TRACKING_BONIFICATION_REPORT_CSV_RESET:
      return {
        ...state,
        report: '',
        loadingReport: false,
        error: false,
        success: false,
      };
    default:
      return state;
  }
};

export default reducer;
