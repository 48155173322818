import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  CardContent,
  CardHeader,
  Card,
} from '@material-ui/core';
import { ApplicationState } from 'store';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { showToastr } from 'store/toastr/actions';
import * as PopupsEditActions from 'store/popup/edit/actions';
import Utils from 'common/Utils';
import React, { useEffect } from 'react';
import ElegiblesFilterItemPopup, {
  IFilterParamPopup,
} from 'components/Elegibles/ElegiblesFilterItemPopup';

interface ElegiblesPopupProps {
  selectedClusters: any[];
  selectedMetodosPagamentos: any[];
  selectedRegioes: any[];
  selectedZonas: any[];
  selectedTerritorios: any[];
  setStateClusters: any;
  stateClusters: any;
  setStateMethodsPayments: any;
  stateMethodsPayments: any;
  setStateRegions: any;
  stateRegions: any;
  setStateZone: any;
  stateZone: any;
  setStateTerritory: any;
  stateTerritory: any;
  loadFilterClusters: () => void;
  loadFilterMethodsPayments: () => void;
  loadFilterRegions: () => void;
  loadFilterTerritory: () => void;
  loadFilterZone: () => void;
  loadingClusters: boolean;
  clusters: any[];
  loadingMethodsPayments: boolean;
  metodosPagamentos: any[];
  loadingRegions: boolean;
  regions: any[];
  loadingTerritory: boolean;
  territorios: any[];
  loadingZone: boolean;
  zonas: any[];
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginBottom: theme.spacing(1),
    },
    root: {
      fontFamily: theme.typography.fontFamily,
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    cardContent: {
      padding: theme.spacing(0),
      marginBottom: theme.spacing(0),
    },
  }),
);

const ElegiblesPopup: React.FC<ElegiblesPopupProps> = ({ ...props }) => {
  const classes = useStyle();

  useEffect(() => {
    props.loadFilterClusters();
    props.loadFilterMethodsPayments();
    props.loadFilterRegions();
    props.loadFilterTerritory();
    props.loadFilterZone();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!props.loadingClusters) {
      props.setStateClusters([
        ...Utils.toFilterItemObjectSelecteItemsPopup<IFilterParamPopup>(
          props.clusters,
          'id',
          'label',
          'title',
          props.selectedClusters,
        ),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedClusters, props.clusters]);

  useEffect(() => {
    if (!props.loadingMethodsPayments) {
      props.setStateMethodsPayments([
        ...Utils.toFilterItemObjectSelecteItemsPopup<IFilterParamPopup>(
          props.metodosPagamentos,
          'id',
          'label',
          'title',
          props.selectedMetodosPagamentos,
        ),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedMetodosPagamentos, props.metodosPagamentos]);

  useEffect(() => {
    if (!props.loadingRegions) {
      props.setStateRegions([
        ...Utils.toFilterItemObjectSelecteItemsPopup<IFilterParamPopup>(
          props.regions,
          'id',
          'label',
          'title',
          props.selectedRegioes,
        ),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedRegioes, props.regions]);

  useEffect(() => {
    if (!props.loadingZone) {
      props.setStateZone([
        ...Utils.toFilterItemObjectSelecteItemsPopup<IFilterParamPopup>(
          props.zonas,
          'id',
          'label',
          'title',
          props.selectedZonas,
        ),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedZonas, props.zonas]);

  useEffect(() => {
    if (!props.loadingTerritory) {
      props.setStateTerritory([
        ...Utils.toFilterItemObjectSelecteItemsPopup<IFilterParamPopup>(
          props.territorios,
          'id',
          'label',
          'title',
          props.selectedTerritorios,
        ),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedTerritorios, props.territorios]);

  return (
    <>
      <Grid item xs={4}>
        <Card className={classes.root}>
          <CardHeader title="Clusters" />
          <CardContent className={classes.cardContent}>
            <ElegiblesFilterItemPopup
              isLoading={props.loadingClusters}
              itemName="clusters"
              searchTitle="Pesquisar clusters"
              setState={props.setStateClusters}
              state={props.stateClusters}
              forKey="id"
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card className={classes.root}>
          <CardHeader title="Métodos de Pagamentos" />
          <CardContent className={classes.cardContent}>
            <ElegiblesFilterItemPopup
              isLoading={props.loadingMethodsPayments}
              itemName="methodsPayments"
              searchTitle="Pesquisar métodos de pagamentos"
              setState={props.setStateMethodsPayments}
              state={props.stateMethodsPayments}
              forKey="id"
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card className={classes.root}>
          <CardHeader title="Regiões" />
          <CardContent className={classes.cardContent}>
            <ElegiblesFilterItemPopup
              isLoading={props.loadingRegions}
              itemName="regions"
              searchTitle="Pesquisar Regiões"
              setState={props.setStateRegions}
              state={props.stateRegions}
              forKey="id"
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card className={classes.root}>
          <CardHeader title="Zonas" />
          <CardContent className={classes.cardContent}>
            <ElegiblesFilterItemPopup
              isLoading={props.loadingZone}
              itemName="zonas"
              searchTitle="Pesquisar Zonas"
              setState={props.setStateZone}
              state={props.stateZone}
              forKey="id"
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card className={classes.root}>
          <CardHeader title="Territórios" />
          <CardContent className={classes.cardContent}>
            <ElegiblesFilterItemPopup
              isLoading={props.loadingTerritory}
              itemName="territorios"
              searchTitle="Pesquisar Territórios"
              setState={props.setStateTerritory}
              state={props.stateTerritory}
              forKey="id"
            />
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  popup: state.popupEdit.data,
  loading: state.popupEdit.loading,
  clusters: state.popupEdit.filtersPdv.clusters.data,
  loadingClusters: state.popupEdit.filtersPdv.clusters.loading,
  metodosPagamentos: state.popupEdit.filtersPdv.metodosPagamentos.data,
  loadingMethodsPayments: state.popupEdit.filtersPdv.metodosPagamentos.loading,
  regions: state.popupEdit.filtersPdv.regioes.data,
  loadingRegions: state.popupEdit.filtersPdv.regioes.loading,
  territorios: state.popupEdit.filtersPdv.territorios.data,
  loadingTerritory: state.popupEdit.filtersPdv.territorios.loading,
  zonas: state.popupEdit.filtersPdv.zonas.data,
  loadingZone: state.popupEdit.filtersPdv.zonas.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...PopupsEditActions,
      showToastr,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ElegiblesPopup);
