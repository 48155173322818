import React from 'react';
import { Button, makeStyles, createStyles, Theme, Grid } from '@material-ui/core';
import { FileCopyOutlined } from '@material-ui/icons';
import Utils from 'common/Utils';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      fontFamily: 'inherit',
      fontSize: '16px',
      border: '0',
      height: 'auto',
      marginBottom: '5px',
      outline: 'none',
    },
  }),
);

const CopyToClipboard = ({ content }) => {
  const classes = useStyle();
  return (
    <Grid container={true} spacing={2}>
      <Grid item={true} xs={11}>
        <textarea id="urlContent" className={classes.root}>
          {content}
        </textarea>
      </Grid>
      <Grid item={true} xs={1}>
        <Button onClick={e => Utils.copyToClipboard(e, 'urlContent')}>
          <FileCopyOutlined />
        </Button>
      </Grid>
    </Grid>
  );
};

export default CopyToClipboard;
