import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import { Dispatch } from 'redux';
import { RouteComponentProps } from 'react-router-dom';
import GenericTable from 'components/GenericTable/GenericTable';
import { Box, createStyles, IconButton, makeStyles, TextField, Theme } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import { TABLE_STYLE } from 'common/Constants';
import * as TrakingBonificationActions from 'store/trackingBonification/report/actions';
import { IChainingNetworkItem, IChainingNetworkParams, IStatusItem } from 'store/participant';
import { FormControl, Button, Grid, ButtonGroup } from '@material-ui/core';
import {
  ITrackingBonificationReportItem,
  ITrackingBonificationReportParams,
} from 'store/trackingBonification/report';
import Utils from 'common/Utils';
import { AppMessages } from 'common/AppMessages.enum';
// import ApprovingModal from './ApprovingModal';

interface IProps extends RouteComponentProps {
  statusList: IStatusItem[];
  reportList: ITrackingBonificationReportItem[];
  loading: boolean;
  error: boolean;
  loadingReport: boolean;
  report: string;
  loadStatusRequest: () => void;
  loadTrackingBonificationReportRequest: (data: ITrackingBonificationReportParams) => void;
  loadTrackingBonificationReportCSVRequest: (data: ITrackingBonificationReportParams) => void;
  trackingBonificationReportCsvReset: () => void;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    spacing: {
      marginBottom: theme.spacing(1),
    },
    buttonActions: {
      padding: theme.spacing(1),
      minWidth: 120,
      textAlign: 'left',
      '& > button': {
        marginRight: 0,
      },
    },
  }),
);

export const valuewRequestDefault: ITrackingBonificationReportParams = {
  dataInicial: '',
  dataFinal: '',
};

const TrackingBonificationReport: React.FC<IProps> = ({
  reportList,
  loading,
  error,
  report,
  loadTrackingBonificationReportRequest,
  loadTrackingBonificationReportCSVRequest,
  trackingBonificationReportCsvReset,
}) => {
  const classes = useStyle();
  const [status, setStatus] = useState(1);
  const [dataRequest, setDataRequest] = useState(valuewRequestDefault);
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (prop: keyof any) => async (event: React.ChangeEvent<{ value: unknown }>) =>
    setDataRequest({ ...dataRequest, [prop]: event.target.value });

  useEffect(() => {
    loadTrackingBonificationReportRequest(valuewRequestDefault);
  }, []);

  useEffect(() => {
    if (error) {
      setErrorMessage('Erro ao obter dados.');
    } else {
      setErrorMessage('');
    }
  }, [error]);

  const getErrorMessages = (): boolean => {
    const isDateValid =
      Utils.compareDate(dataRequest.dataInicial, dataRequest.dataFinal) &&
      dataRequest.dataInicial !== dataRequest.dataFinal;

    setErrorMessage(isDateValid ? AppMessages.DATE_START_BIGGERTHAN_END : '');

    return [!isDateValid].some(e => !e);
  };

  useEffect(() => {
    if (report !== undefined && report !== '') {
      var buffer = new ArrayBuffer(3);
      var dataView = new DataView(buffer);
      dataView.setUint8(0, 0xfe);
      dataView.setUint8(1, 0xbb);
      dataView.setUint8(2, 0xbf);

      var csvString = report;
      var universalBOM = '\uFEFF';
      var a = window.document.createElement('a');
      a.setAttribute(
        'href',
        'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + csvString),
      );

      let dateString = new Date().toLocaleDateString('pt-BR');
      let dateReplace = dateString.replace('/', '').replace('/', '');
      a.setAttribute('download', `RelatoriodeTrackingBonificacao${dateReplace}_SouzaCruz.csv`);
      window.document.body.appendChild(a);
      a.click();
      trackingBonificationReportCsvReset();
    }
  }, [report]);

  const handleSubmit = event => {
    event.preventDefault();

    const hasErrors = getErrorMessages();
    if (!hasErrors) {
      let dataRequestObj: ITrackingBonificationReportParams = {
        dataInicial: dataRequest.dataInicial === '' ? '' : dataRequest.dataInicial,
        dataFinal: dataRequest.dataFinal === '' ? '' : dataRequest.dataFinal,
      };

      loadTrackingBonificationReportRequest(dataRequestObj);
    }
  };
  const handleGenerateReport = () => {
    let dataRequestObj: ITrackingBonificationReportParams = {
      dataInicial: dataRequest.dataInicial === '' ? '' : dataRequest.dataInicial,
      dataFinal: dataRequest.dataFinal === '' ? '' : dataRequest.dataFinal,
    };

    loadTrackingBonificationReportCSVRequest(dataRequestObj);
  };

  return (
    <div>
      <h1>Relatorio Tracking de Bonificação</h1>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <FormControl className={classes.formControl}>
              <TextField
                type="date"
                id="dataInicial"
                value={dataRequest.dataInicial}
                onChange={handleChange('dataInicial')}
                label="Data do pedido inicial"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl className={classes.formControl}>
              <TextField
                type="date"
                id="dataInicial"
                value={dataRequest.dataFinal}
                onChange={handleChange('dataFinal')}
                label="Data do pedido inicial"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <ButtonGroup size="large" color="primary" aria-label="outlined primary button group">
              <Button type="submit" disabled={loading ? true : false}>
                Pesquisar
              </Button>
              <Button
                type="button"
                disabled={reportList.length > 0 ? false : true}
                onClick={() => handleGenerateReport()}
              >
                Exportar CSV
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item xs={12}>
            <Box
              style={{
                color: 'red',
                letterSpacing: '0.0075em;',
                fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                fontSize: '1rem',
              }}
            >
              {errorMessage}
            </Box>
          </Grid>
        </Grid>
      </form>
      <br />
      {!error && (
        <GenericTable
          title="Solicitações"
          isLoading={loading}
          data={reportList}
          options={{
            paging: true,
            search: true,
            pageSize: 10,
            sorting: false,
          }}
          columns={[
            {
              searchable: true,
              title: 'Código Sap',
              field: 'codigoSap',
              cellStyle: { ...TABLE_STYLE, width: '10%' },
            },
            {
              searchable: true,
              title: 'Parceiro Id',
              field: 'idResgateParceiro',
              cellStyle: { ...TABLE_STYLE, width: '12%' },
            },
            {
              searchable: true,
              title: 'Linha Parceiro',
              field: 'idLinhaResgateNoParceiro',
              cellStyle: { ...TABLE_STYLE, width: '12%' },
            },
            {
              searchable: true,
              title: 'Data Pedido',
              render: rowData => {
                return `${Utils.setData(rowData.dataPedido)}`;
              },
              cellStyle: { ...TABLE_STYLE, width: '10%' },
            },
            {
              searchable: false,
              title: 'Código Produto',
              field: 'codigoProduto',
              cellStyle: { ...TABLE_STYLE, width: '15%' },
            },
            {
              searchable: true,
              title: 'Nome do Produto',
              field: 'nomeProduto',
              cellStyle: { ...TABLE_STYLE, width: '10%' },
            },
            {
              searchable: true,
              title: 'Quantidade resgatada',
              field: 'quantidadeResgatar',
              cellStyle: { ...TABLE_STYLE, width: '10%' },
            },
            {
              searchable: true,
              title: 'Unidade de medida',
              field: 'unidadeMedida',
              cellStyle: { ...TABLE_STYLE, width: '10%' },
            },
          ]}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    reportList: state.trackingBonificationReport.data,
    report: state.trackingBonificationReport.report,
    loading: state.trackingBonificationReport.loading,
    error: state.trackingBonificationReport.error,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadTrackingBonificationReportRequest: (data: ITrackingBonificationReportParams) => {
      dispatch(TrakingBonificationActions.loadTrackingBonificationReportRequest(data));
    },
    loadTrackingBonificationReportCSVRequest: (data: ITrackingBonificationReportParams) => {
      dispatch(TrakingBonificationActions.loadTrackingBonificationReportCSVRequest(data));
    },
    trackingBonificationReportCsvReset: () => {
      dispatch(TrakingBonificationActions.trackingBonificationReportCsvReset());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackingBonificationReport);
