import { Reducer } from 'redux';

export enum ParticipantSeparateInvoiceFileTypes {
  LOAD_REQUEST = '@ParticipantSeparateInvoice/LOAD_REQUEST',
  LOAD_SUCCESS = '@ParticipantSeparateInvoice/LOAD_SUCCESS',
  LOAD_FAILURE = '@ParticipantSeparateInvoice/LOAD_FAILURE',
  UPLOAD_REQUEST = '@ParticipantSeparateInvoice/UPLOAD_REQUEST',
  UPLOAD_SUCCESS = '@ParticipantSeparateInvoice/UPLOAD_SUCCESS',
  UPLOAD_FAILURE = '@ParticipantSeparateInvoice/UPLOAD_FAILURE',
  DELETE_REQUEST = '@ParticipantSeparateInvoice/DELETE_REQUEST',
  DELETE_SUCCESS = '@ParticipantSeparateInvoice/DELETE_SUCCESS',
  DELETE_FAILURE = '@ParticipantSeparateInvoice/DELETE_FAILURE',
}

export interface IParticipantSeparateInvoiceDataFile {
  id: number;
  dataUpload: string;
  nomeArquivo: string;
  descricaoStatus: string;
}
export interface ISeparateInvoiceDetailListDataFile {
  numeroLinha: number;
  sap: number;
  descricaoErro: string;
}

export interface IParticipantSeparateInvoiceFileState {
  data: IParticipantSeparateInvoiceDataFile[];
  detailListData: {
    data: ISeparateInvoiceDetailListDataFile[];
    loading: boolean;
    error: boolean;
  };
  loading: boolean;
  error: boolean;
}

const INITIAL_STATE: IParticipantSeparateInvoiceFileState = {
  data: [],
  detailListData: {
    data: [],
    loading: false,
    error: false,
  },
  loading: false,
  error: false,
};

const reducer: Reducer<IParticipantSeparateInvoiceFileState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ParticipantSeparateInvoiceFileTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case ParticipantSeparateInvoiceFileTypes.LOAD_SUCCESS:
      return { ...state, loading: false, data: [...action.payload.data] };
    case ParticipantSeparateInvoiceFileTypes.LOAD_FAILURE:
      return { ...state, loading: false };
    case ParticipantSeparateInvoiceFileTypes.UPLOAD_REQUEST:
      return { ...state, loading: true };
    case ParticipantSeparateInvoiceFileTypes.UPLOAD_SUCCESS:
      return { ...state, loading: false, data: [...state.data, { ...action.payload.data }] };
    case ParticipantSeparateInvoiceFileTypes.UPLOAD_FAILURE:
      return { ...state, loading: false };
    case ParticipantSeparateInvoiceFileTypes.DELETE_REQUEST:
      return { ...state, loading: true };
    case ParticipantSeparateInvoiceFileTypes.DELETE_SUCCESS:
      return { ...state, loading: false, data: [...state.data] };
    case ParticipantSeparateInvoiceFileTypes.DELETE_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default reducer;
