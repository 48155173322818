import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import TrackComponent from './TrackComponent';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }),
);

const StepTracks: React.FC<any> = ({
  tracks,
  numberOfSteps,
  SaveTracks,
  refreshTracks,
  idCampanha,
}) => {
  const classes = useStyles();
  const [actualData, setActualData] = useState<any>({});
  const [activeStep, setActiveStep] = useState(1);
  const [skipped, setSkipped] = useState(new Set<number>());

  const saveTrackT = async payload => {
    SaveTracks(payload);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleNext = () => {
    let newSkipped = skipped;

    setActiveStep(activeStep + 1);
    setSkipped(newSkipped);
  };

  const getSteps = () => {
    var steps: Array<{ number: number; name: string }> = [];
    for (var i = 1; i <= numberOfSteps; i++) {
      let obj: { number: number; name: string } = { number: i, name: 'Faixa' };
      steps.push(obj);
    }

    return steps;
  };

  function getStepContent(step: number) {
    return (
      <TrackComponent
        trackData={actualData}
        number={step}
        numberOfSteps={numberOfSteps}
        saveTrack={saveTrackT}
        idCampanha={idCampanha}
        handleBack={handleBack}
        handleNext={handleNext}
      />
    );
  }

  function setData() {
    setActualData(
      tracks
        ?.filter(d => {
          return d.faixa == activeStep;
        })
        .pop(),
    );
  }

  useEffect(() => {
    setData();
  }, [tracks]);

  useEffect(() => {
    setData();
  }, [activeStep]);

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep - 1}>
        {numberOfSteps &&
          getSteps().map(item => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: { optional?: React.ReactNode; error?: boolean } = {};
            return (
              <Step key={item.number} {...stepProps}>
                <StepLabel {...labelProps}>{item.name}</StepLabel>
              </Step>
            );
          })}
      </Stepper>
      <div>
        <div>
          <Typography component={'span'} className={classes.instructions}>
            {getStepContent(activeStep)}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default StepTracks;
