import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import { Dispatch, bindActionCreators } from 'redux';

import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent } from '@material-ui/core';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as RealtimeActions from 'store/realtime/totalAceites/actions';
import { IRealtimeItem } from 'store/realtime/totalAceites';

interface AceiteTermosCardProps {
  loadRealtimeTotalAceitesRequest(): void;
  totalAceites: IRealtimeItem[];
  atualizacao: string | null;
  loading: boolean;
  error: boolean;
  refresh: number;
}

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const DashboardCard: React.FC<AceiteTermosCardProps> = ({
  loadRealtimeTotalAceitesRequest,
  totalAceites,
  atualizacao,
  loading,
  error,
  refresh,
}) => {
  const classes = useStyles();

  useEffect(() => {
    loadRealtimeTotalAceitesRequest();
  }, [refresh]);

  const optionsCard = useMemo(() => {
    let data: any = [];
    let total: number = 0;

    if (totalAceites && totalAceites.length > 0) {
      data = totalAceites.map(item => [item.nome, item.total]);
      total = totalAceites.reduce((accumulator, currentValue) => {
        accumulator = Number(accumulator) + currentValue.total;
        return accumulator;
      }, 0);
    }

    const options = {
      chart: {
        type: 'pie',
      },
      title: {
        text: 'TOTAL ADESÕES',
        align: 'center',
        verticalAlign: 'top',
      },
      subtitle: {
        text: `Total: ${new Intl.NumberFormat('pt-BR').format(total)}`,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      },
      plotOptions: {
        pie: {
          innerSize: 100,
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b><br>{point.y:,.0f}',
          },
        },
      },
      series: [
        {
          type: 'pie',
          name: 'Aceite do Termo',
          data,
        },
      ],
    };

    return options;
  }, [totalAceites, atualizacao]);

  return (
    <div>
      <Card className={classes.root} variant="outlined">
        <CardContent>
          {loading ? (
            <CircularProgress />
          ) : (
            <HighchartsReact highcharts={Highcharts} options={optionsCard} />
          )}

          <Grid container justify="space-between" spacing={1}>
            <Grid item>
              <Typography color="textSecondary" variant="caption">
                Total de adesões considerando aceite termos e primeiro acesso
              </Typography>
            </Grid>
          </Grid>
          <Grid container justify="space-between" spacing={1}>
            <Grid item>
              <Typography color="textSecondary" variant="caption">
                {error && (
                  <>
                    <WarningIcon /> Ops, não foi possível buscar as informações, tente novamente.
                  </>
                )}
                {atualizacao && `Última atualização: ${atualizacao}`}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  totalAceites: state.realtimeTotalAceites.data,
  atualizacao: state.realtimeTotalAceites.atualizacao,
  loading: state.realtimeTotalErrosIntegracaoBoletos.loading,
  error: state.realtimeTotalErrosIntegracaoBoletos.error,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...RealtimeActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(DashboardCard);
