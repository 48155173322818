import {
  Box,
  Button,
  createStyles,
  FormControl,
  Grid,
  makeStyles,
  Paper,
  Theme,
} from '@material-ui/core';
import cn from 'classnames';
import Utils from 'common/Utils';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from 'store';
import { IBenefitEdit, IGeneralDataCreateRequest } from 'store/benefit/edit';
import * as BenefitEditActions from 'store/benefit/edit/actions';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import Api from 'common/Api';

interface BenefitEditProps {
  benefit: IBenefitEdit;
  loading: boolean;
  createBenefit: (data: IGeneralDataCreateRequest) => void;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    textCenter: {
      textAlign: 'center',
      display: 'block',
      width: '91%',
    },
    buttonActions: {
      padding: theme.spacing(1),
      minWidth: 120,
      textAlign: 'right',
      '& > button': {
        marginRight: 0,
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    label: {
      fontWeight: 'bolder',
      fontSize: '14px',
      marginBottom: theme.spacing(1),
      display: 'block',
    },
  }),
);

const Description: React.FC<BenefitEditProps> = ({ benefit, loading, createBenefit }) => {
  const classes = useStyle();
  const [state, setState] = useState({ ...benefit });

  const [editorDescricaoGanhosState, setEditorDescricaoGanhosState] = useState(
    EditorState.createEmpty(),
  );
  const [editorSuporteState, setEditorSuporteState] = useState(EditorState.createEmpty());
  const [editorDuvidasFrequentesState, setEditorDuvidasFrequentesState] = useState(
    EditorState.createEmpty(),
  );

  useEffect(() => {
    setState({ ...benefit });

    initFieldsEditors(benefit);
  }, [benefit]);

  const initFieldsEditors = (benefit: IBenefitEdit) => {
    if (benefit.suporte) {
      setEditorSuporteState(Utils.stringToEditorText(benefit.suporte));
    }

    if (benefit.duvidasFrequentes) {
      setEditorDuvidasFrequentesState(Utils.stringToEditorText(benefit.duvidasFrequentes));
    }

    if (benefit.descricaoDosGanhos) {
      setEditorDescricaoGanhosState(Utils.stringToEditorText(benefit.descricaoDosGanhos));
    }
  };

  const onEditorStateDescricaoGanhosChange = editorState => {
    setEditorDescricaoGanhosState(editorState);
  };

  const onEditorStateSuporteChange = editorState => {
    setEditorSuporteState(editorState);
  };

  const onEditorStateDuvidasFrequentesChange = editorState => {
    setEditorDuvidasFrequentesState(editorState);
  };

  const uploadImageCallBack = file => {
    return new Promise(async (resolve, reject) => {
      const uploadUrl = `beneficio/${Number(benefit.id)}/filesEditor/upload`;
      const formData = new FormData();
      const formated = Utils.getFormatedFile(file);
      formData.append('file', formated);
      const { data } = await Api.post(uploadUrl, formData);
      resolve({
        data: {
          link: data.url,
        },
      });
    });
  };

  const handleOnSave = async () => {
    state.suporte = Utils.getEditorData(editorSuporteState);
    state.duvidasFrequentes = Utils.getEditorData(editorDuvidasFrequentesState);
    state.descricaoDosGanhos = Utils.getEditorData(editorDescricaoGanhosState);
    createBenefit(state);
  };

  return (
    <Paper>
      <Box p="1.5em">
        <form>
          <Box width={6 / 12}>
            <FormControl fullWidth={true} className={classes.formControl}>
              <Box className={classes.label} display="label">
                {' '}
                Descrição dos Ganhos:{' '}
              </Box>
              <Editor
                toolbar={{
                  image: {
                    uploadCallback: uploadImageCallBack,
                    urlEnabled: true,
                    uploadEnabled: true,
                    previewImage: true,
                  },
                }}
                editorState={editorDescricaoGanhosState}
                onEditorStateChange={onEditorStateDescricaoGanhosChange}
              />
            </FormControl>
          </Box>
          <Box width={6 / 12}>
            <FormControl fullWidth={true} className={classes.formControl}>
              <Box className={classes.label} display="label">
                {' '}
                Suporte:{' '}
              </Box>
              <Editor
                toolbar={{
                  image: {
                    uploadCallback: uploadImageCallBack,
                    urlEnabled: true,
                    uploadEnabled: true,
                    previewImage: true,
                  },
                }}
                editorState={editorSuporteState}
                onEditorStateChange={onEditorStateSuporteChange}
              />
            </FormControl>
          </Box>
          <Box width={6 / 12}>
            <FormControl fullWidth={true} className={classes.formControl}>
              <Box className={classes.label} display="label">
                {' '}
                Dúvidas Frequentes:{' '}
              </Box>
              <Editor
                toolbar={{
                  image: {
                    uploadCallback: uploadImageCallBack,
                    urlEnabled: true,
                    uploadEnabled: true,
                    previewImage: true,
                  },
                }}
                editorState={editorDuvidasFrequentesState}
                onEditorStateChange={onEditorStateDuvidasFrequentesChange}
              />
            </FormControl>
          </Box>
          <Grid container={true}>
            <Grid item={true} xs={6} className={cn([classes.buttonActions])}>
              <Button
                onClick={handleOnSave}
                disabled={loading}
                variant="contained"
                color="secondary"
              >
                {loading ? 'Aguarde...' : !benefit.id ? 'Cadastrar' : 'Atualizar'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Paper>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  benefit: state.benefit.data,
  loading: state.benefit.loading,
  error: state.benefit.error,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(BenefitEditActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Description as any);
