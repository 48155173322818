import React, { useEffect, useState } from 'react';
import { ApplicationState } from 'store';
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Button,
  Paper,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import GenericTable from 'components/GenericTable/GenericTable';
import Utils from 'common/Utils';
import { TABLE_STYLE } from 'common/Constants';
import { connect } from 'react-redux';
import * as PaymentsActions from 'store/payments/actions';
import { Dispatch, bindActionCreators } from 'redux';
import { IGenericUploadTable } from 'common/Constants';
import ModalUploadDetails from '../ModalUploadDetails/ModalUploadDetails';

interface HistoricUploadProps {
  historicUploadList: IGenericUploadTable[];
  loading: boolean;
  loadHistoricByUpload(id: number): void;
  loadUploadPDVDetailList(id: number): void;
  id: number;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      color: theme.palette.info.dark,
    },
    error: {
      color: theme.palette.error.main,
    },
    sucess: {
      color: theme.palette.success.dark,
    },
  }),
);

const HistoricUploadTable: React.FC<HistoricUploadProps> = ({
  historicUploadList,
  loading,
  loadHistoricByUpload,
  loadUploadPDVDetailList,
  id,
}) => {
  const classes = useStyle();
  const [openModalDetail, setOpenModalDetail] = useState(false);

  useEffect(() => {
    if (id) loadHistoricByUpload(id);
    // eslint-disable-next-line
  }, [id]);

  const handleModal = (id: number) => {
    loadUploadPDVDetailList(id);
    setOpenModalDetail(true);
  };

  return (
    <Paper elevation={3} style={{ margin: '2em' }}>
      <Box>
        <GenericTable
          title="Histórico de importações via Arquivo"
          isLoading={loading}
          data={historicUploadList}
          options={{
            paging: true,
            search: false,
            pageSize: 5,
            emptyRowsWhenPaging: false,
          }}
          columns={[
            {
              title: 'Nome do Arquivo',
              field: 'nomeArquivo',
              sorting: false,
              render: rowData => {
                return `${rowData.nomeArquivo.split('.')[0]}`;
              },
              cellStyle: { ...TABLE_STYLE },
            },
            {
              title: 'Data envio',
              field: 'dataInclusao',
              defaultSort: 'desc',
              type: 'datetime',
              customSort: (a, b) =>
                new Date(a.dataInclusao).getTime() - new Date(b.dataInclusao).getTime(),
              render: rowData => {
                return `${Utils.setDataHora(rowData.dataInclusao)}`;
              },
              cellStyle: { ...TABLE_STYLE },
            },
            {
              title: 'Status',
              field: 'descricaoStatus',
              sorting: false,
              render: rowData => {
                return `${rowData.descricaoStatus ? rowData.descricaoStatus : 'Iniciado'}`;
              },
              cellStyle: { ...TABLE_STYLE },
            },
            {
              title: 'Processando / Sucesso / Erro / Sobreposto',
              sorting: false,
              render: rowData => {
                return (
                  <>
                    <span className={classes.progress}>
                      {rowData.quantidadeLinhasProcessando
                        ? rowData.quantidadeLinhasProcessando
                        : 0}
                    </span>{' '}
                    /{' '}
                    <span className={classes.sucess}>
                      {rowData.quantidadeLinhasSucesso ? rowData.quantidadeLinhasSucesso : 0}
                    </span>{' '}
                    /{' '}
                    <span className={classes.error}>
                      {rowData.quantidadeLinhasErro ? rowData.quantidadeLinhasErro : 0}
                    </span>{' '}
                    /{' '}
                    <span className={classes.error}>
                      {rowData.quantidadeLinhasSobreposicao
                        ? rowData.quantidadeLinhasSobreposicao
                        : 0}
                    </span>
                  </>
                );
              },
              cellStyle: { ...TABLE_STYLE, textAlign: 'center' },
              headerStyle: { padding: '5px', textAlign: 'center' },
            },
            {
              title: 'Total',
              field: 'descricaoStatus',
              sorting: false,
              render: rowData => {
                return (
                  <span>{rowData.quantidadeLinhasTotal ? rowData.quantidadeLinhasTotal : 0}</span>
                );
              },
              cellStyle: { ...TABLE_STYLE },
            },
            {
              title: 'Descrição do Erro',
              field: 'descricaoerro',
              sorting: false,
              render: rowData => {
                return rowData.erro ? <span>{rowData.descricaoerro}</span> : 'Sem erros';
              },
              cellStyle: { ...TABLE_STYLE },
            },
            {
              title: 'Detalhamento',
              sorting: false,
              render: rowData => {
                return (
                  <Button
                    onClick={() => handleModal(rowData.id)}
                    style={{ textDecoration: 'underline' }}
                    size={'small'}
                  >
                    Ver Detalhes
                  </Button>
                );
              },
              cellStyle: { ...TABLE_STYLE },
            },
            {
              title: 'Ações',
              searchable: false,
              sorting: false,
              cellStyle: {
                width: '15%',
                ...TABLE_STYLE,
              },
              render: ({ urlArquivo }) => (
                <Tooltip title="Download do arquivo">
                  <IconButton onClick={() => window.open(urlArquivo)}>
                    <CloudDownload />
                  </IconButton>
                </Tooltip>
              ),
            },
          ]}
        />
      </Box>

      <ModalUploadDetails setOpen={setOpenModalDetail} open={openModalDetail} />
    </Paper>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  historicUploadList: state.payments.uploadFileHistoric.data,
  loading: state.payments.uploadFileHistoric.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...PaymentsActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HistoricUploadTable);
