import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Theme,
} from '@material-ui/core';
import BannerBenefitHomeUpload, {
  BannerBenefitType,
} from 'components/BannerHomeUpload/BannerBenefitHomeUpload';
import { connect } from 'react-redux';
import * as BenefitEditActions from 'store/benefit/edit/actions';
import { Dispatch, bindActionCreators } from 'redux';
import cn from 'classnames';
import Api from 'common/Api';
import { ApplicationState } from 'store';
import {
  IBenefitBanner,
  IGeneralData,
  IBenefitEdit,
  IGeneralDataCreateRequest,
} from 'store/benefit/edit';

interface BenefitBannerEdit {
  mostrarNaCalculadora: boolean;
  beneficioId: number;
}

interface BenefitBannerProps {
  benefit: IBenefitEdit;
  createBenefit: (data: IGeneralDataCreateRequest) => void;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    textCenter: {
      textAlign: 'center',
      display: 'block',
      width: '91%',
    },
    buttonActions: {
      padding: theme.spacing(1),
      minWidth: 120,
      textAlign: 'right',
      '& > button': {
        marginRight: 0,
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    label: {
      fontWeight: 'bolder',
      fontSize: '14px',
      marginBottom: theme.spacing(1),
      display: 'block',
    },
  }),
);

const Simulador: React.FC<BenefitBannerProps> = ({ benefit, createBenefit }) => {
  const classes = useStyle();

  const handleOnSave = async () => {
    state.mostrarNaCalculadora = mostrarCalc;
    createBenefit(state);
  };

  const [state, setState] = useState({ ...benefit });
  const [banners, setBanners] = useState(benefit.beneficioBanner);
  const [mostrarCalc, setMostrarCalc] = useState(benefit.mostrarNaCalculadora);
  useEffect(() => {
    setBanners(benefit.beneficioBanner);
  }, [benefit.beneficioBanner]);
  useEffect(() => {
    benefit.mostrarNaCalculadora = mostrarCalc;
  }, [mostrarCalc]);
  return (
    <Grid container={true} spacing={3}>
      <Grid container={true}>
        <Grid item={true} xs={5}>
          <FormControl fullWidth={true} className={classes.formControl}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={mostrarCalc}
                  onChange={e => {
                    setMostrarCalc(e.target.checked);
                  }}
                  name="mostrarNaCalculadora"
                  color="primary"
                />
              }
              label="Exibir na calculadora de Benefícios"
            />
            <Box fontSize={12} mb="1em" color="#d32f2f">
              Se esse benefício já foi incluso anteriormente com cálculos monetários, favor não
              selecionar acima para evitar duplicidade.
            </Box>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item={true} sm={3}>
        <BannerBenefitHomeUpload
          banner={banners.find(e => e.tipoId === BannerBenefitType.DESKTOP)}
          size={BannerBenefitType.DESKTOP}
          benefitId={Number(benefit.id)}
        />
      </Grid>
      <Grid item={true} sm={3}>
        <BannerBenefitHomeUpload
          banner={banners.find(e => e.tipoId === BannerBenefitType.MOBILE)}
          size={BannerBenefitType.MOBILE}
          benefitId={Number(benefit.id)}
        />
      </Grid>
      <Grid container={true}>
        <Grid item={true} xs={6} className={cn([classes.buttonActions])}>
          <Button onClick={handleOnSave} variant="contained" color="secondary">
            {'Atualizar'}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  benefit: state.benefit.data,
  loading: state.benefit.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...BenefitEditActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Simulador as any);
