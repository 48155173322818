import React, { useEffect, useState } from 'react';
import {
  Box,
  Paper,
  makeStyles,
  Theme,
  createStyles,
  TextField,
  Grid,
  FormControl,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from '@material-ui/core';

type FilterType = 'regions' | 'methodsPayments' | 'zonas' | 'clusters' | 'territorios' | 'sku';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginBottom: theme.spacing(1),
    },
    fullWidth: {
      width: '100%',
    },
    spacing: {
      marginBottom: theme.spacing(2),
    },
    listBox: {
      height: '260px',
      overflow: 'auto',
      minHeight: '260px',
    },
  }),
);

export interface IFilterPopupItemRequest {
  id: number;
  selected: boolean;
  label: string;
  title: string;
  value: string;
}

export interface IFilterParamPopup {
  id: number;
  label: string;
}

interface ElegiblesFilterItemProps {
  isLoading: boolean;
  searchTitle: string;
  itemName: FilterType;
  setState: any;
  state: IFilterPopupItemRequest[];
  forKey: keyof IFilterPopupItemRequest;
}

const ElegiblesFilterItemPopup: React.FC<ElegiblesFilterItemProps> = ({
  isLoading = false,
  searchTitle,
  setState,
  itemName,
  state,
  forKey,
}) => {
  const classes = useStyle();

  const [searchValue, setSearchValue] = useState('');
  const [selectAll, setSelectAll] = useState(true);

  const handleOnChange = (item: IFilterPopupItemRequest, checked: boolean, index: number) => {
    setState([
      ...state.map(e => {
        if (e[forKey] === item[forKey]) {
          return { ...e, selected: checked };
        } else {
          return { ...e };
        }
      }),
    ]);
  };

  const handleSelectAll = (checked: boolean) => {
    setSelectAll(checked);
    setState([
      ...state.map(e => {
        return {
          ...e,
          selected: checked,
        };
      }),
    ]);
  };

  useEffect(() => {
    const listItems = document.querySelectorAll(`#box_${itemName} label[title]`);

    if (listItems.length > 0) {
      listItems.forEach((e: any) => {
        if (e.textContent?.toUpperCase()?.indexOf(searchValue?.toUpperCase()) > -1) {
          e.classList.remove('d-none');
        } else {
          e.classList.add('d-none');
        }
      });
    }
  }, [searchValue, itemName]);

  return (
    <Grid item>
      <FormControl fullWidth className={classes.formControl}>
        <TextField
          id={searchValue}
          type="search"
          value={searchValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
          label={searchTitle}
          variant="filled"
          margin="dense"
        />
      </FormControl>
      <FormControl fullWidth className={classes.formControl}>
        <FormControlLabel
          disabled={state.length === 0}
          control={
            <Checkbox
              checked={selectAll && state.filter(e => !e.selected).length === 0}
              onChange={(e, checked) => handleSelectAll(checked)}
              value={selectAll}
              color="primary"
            />
          }
          label="Selecionar todos"
        />
      </FormControl>
      <Paper className={classes.listBox}>
        <Box p="1.5em" id={`box_${itemName}`}>
          {isLoading ? (
            <CircularProgress color="primary" />
          ) : (
            state.map((item: IFilterPopupItemRequest, index: number) => {
              return (
                <FormControlLabel
                  key={index}
                  title={item.label}
                  className={classes.fullWidth}
                  control={
                    <Checkbox
                      name={item.label}
                      id={`${item.id}_${index}`}
                      checked={item.selected}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                        handleOnChange(item, checked, index)
                      }
                      value={item.label}
                      color="secondary"
                    />
                  }
                  label={item.label}
                />
              );
            })
          )}
        </Box>
      </Paper>
    </Grid>
  );
};

export default ElegiblesFilterItemPopup;
