import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import imgLogo from '../../assets/project.png';
import ModalChangePassword, {
  IModalChangePasswordState,
} from '../../pages/Login/ChangePassword/ModalChangePassword';
import NavbarMenuItem from './NavbarMenuItem';
import AuthHelper from 'common/AuthHelper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      marginBottom: '20px',
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: 'none',
      cursor: 'pointer',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
      flexGrow: 1,
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    menuItems: {
      display: 'flex',
      margin: '0 0 0 30px',
      padding: '0',
      listStyle: 'none',
    },
    imgLogo: {
      maxHeight: '40px',
      verticalAlign: 'middle',
    },
  }),
);

const Navbar: React.FC<any> = ({ history }) => {
  const classes = useStyles();
  const [auth, setAuth] = React.useState(false);
  const [authName, setAuthName] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [menu, setMenu] = React.useState([
    {
      title: 'Home',
      router: '/home',
    },
  ]);
  const [modalConfirmation, setModalConfirmation] = React.useState<IModalChangePasswordState>({
    open: false,
    onConfirm: null,
  });

  const [menuDash, setMenuDash] = React.useState(false);

  useEffect(() => {
    setAuth(AuthHelper.isAutenticed());
    setAuthName(AuthHelper.getUserName());
  }, []);

  useEffect(() => {
    if (auth) searchMenuCache();
  }, [auth]);

  const openModal = () => {
    setModalConfirmation({
      open: true,
      onConfirm: null,
    });
    handleClose();
  };

  function searchMenuCache() {
    const menus = localStorage.getItem('menus');
    if (menus != null) {
      return setMenu(JSON.parse(menus));
    }
  }

  const sair = () => {
    localStorage.clear();
    setAuth(false);
    window.location.href = '/login';
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.grow}>
      {auth && (
        <AppBar position="static">
          <Toolbar>
            <Typography
              onClick={() => history.push('/')}
              className={classes.title}
              variant="h6"
              noWrap
            >
              <img src={imgLogo} className={classes.imgLogo} />
            </Typography>
            <div>
              <ul className={classes.menuItems}>
                {menu.map((item, index) => (
                  <NavbarMenuItem history={history} key={index} {...item} />
                ))}
                {/* </ul>
              {/* <div>
              <ul className={classes.userMenu}> */}
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  {auth && authName && <MenuItem disabled={true}>{authName}</MenuItem>}
                  <MenuItem onClick={openModal}>Alterar Senha</MenuItem>
                  <MenuItem onClick={sair}>Sair</MenuItem>
                </Menu>
              </ul>
            </div>

            <ModalChangePassword
              title="Alterar Senha."
              state={modalConfirmation}
              setState={setModalConfirmation}
              size="sm"
            />

            {/* <Button color="inherit" onClick={sair}>
              Sair
            </Button> */}
          </Toolbar>
        </AppBar>
      )}{' '}
    </div>
  );
};

export default withRouter(Navbar);
