import React, { useState } from 'react';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab/';
import { Box, Modal, Button, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useStylePreview } from './previewModalStyle';
import { POPUP_TIPO } from 'common/Constants';
import IconAlert from 'assets/images/icon_alert_v2.png';
import cn from 'classnames';

interface IPreviewModal {
  state: any;
}

const PreviewPopup: React.FC<IPreviewModal> = ({ state }) => {
  const classes = useStylePreview();
  const [openPreview, setOpenPreview] = useState(false);
  const [modalType, setModalType] = useState<string>('web');

  return (
    <>
      <ToggleButtonGroup
        style={{ marginRight: '1em' }}
        size="small"
        value={modalType}
        exclusive
        onChange={(event: React.MouseEvent<HTMLElement>, value: string): void => {
          if (!value) return;
          setModalType(value);
        }}
      >
        <ToggleButton value="web">Modo Desktop</ToggleButton>
        <ToggleButton value="mobile">Modo Mobile</ToggleButton>
      </ToggleButtonGroup>

      <Button
        style={{ marginRight: '1em' }}
        color="primary"
        variant="contained"
        onClick={() => setOpenPreview(true)}
      >
        Preview modal
      </Button>

      <Modal open={openPreview} onClose={() => setOpenPreview(false)}>
        <Box
          className={cn([
            state.codigoTamanho === '1'
              ? classes.boxSizeSmall
              : state.codigoTamanho === '2'
              ? classes.boxSizeMedium
              : classes.boxSizeBig,
            modalType === 'mobile' ? classes.boxPreviewMobile : classes.boxPreviewWeb,
          ])}
        >
          <Box>
            <img src={IconAlert} className={classes.iconAlert} alt="ico_alert" />
          </Box>

          <Box className={classes.contentBox}>
            <IconButton className={classes.iconClose} disableRipple disableFocusRipple>
              <Close />
            </IconButton>

            {parseInt(state.codigoTipo) === POPUP_TIPO.TEXTO_IMAGEM ? (
              <Box style={{ textAlign: 'center' }}>
                <img src={state.imagemVideoUri} style={{ maxWidth: '100%' }} alt={'Imagem'} />
              </Box>
            ) : parseInt(state.codigoTipo) === POPUP_TIPO.TEXTO_VIDEO ? (
              <Box style={{ textAlign: 'center' }}>
                <video controls style={{ maxWidth: '100%' }}>
                  <source src={state.imagemVideoUri} type="video/ogg" />
                </video>
              </Box>
            ) : (
              false
            )}

            {state.texto && (
              <Box
                className={cn([classes.fontParagraph])}
                dangerouslySetInnerHTML={{
                  __html: state.texto,
                }}
              />
            )}

            <Button className={classes.chooseBtn} onClick={() => setOpenPreview(true)}>
              {state.url ? 'Clique aqui para acessar' : 'Fechar'}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default PreviewPopup;
