import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import { loadSegmentSuccess } from './actions';
import { showToastr } from 'store/toastr/actions';
import { AppMessages } from 'common/AppMessages.enum';

export function* loadSegmentsByClusters(action) {
  try {
    const { data } = yield call(
      API.get,
      'shared/atividades',
      action.payload === undefined ? '' : action.payload.clusters,
    );
    yield put(loadSegmentSuccess(data));
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
  }
}

export function* loadSegments() {
  try {
    const { data } = yield call(API.get, 'shared/atividades', {});
    yield put(loadSegmentSuccess(data));
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
  }
}
