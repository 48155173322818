import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import {
  loadTrackImportFile as loadTrackImportFileAction,
  loadUploadFileSuccess,
  loadUploadFileFailure,
  loadFileTrackImportDetailsSuccess,
  loadFileTrackImportDetailsFailure,
  trackImportCsvSuccess,
  trackImportCsvFailure,
  deleteTrackImportCsvSuccess,
  deleteTrackImportCsvFailure,
} from './actions';
import { showToastr } from 'store/toastr/actions';
import { AppMessages } from 'common/AppMessages.enum';

export function* loadTrackImportFile(action) {
  try {
    const { data } = yield call(
      API.get,
      `/campanhaEan/StatusUpload?CampanhaFaixaAtingimentoId=${action.payload.id}`,
    );
    yield put(loadUploadFileSuccess(data));
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
    yield put(loadUploadFileFailure());
  }
}

export function* loadFileTrackImportDetails(action) {
  try {
    const { data } = yield call(
      API.get,
      `/campanhaEan/StatusProcessamento?idArquivo=${action.payload.id}`,
    );
    yield put(loadFileTrackImportDetailsSuccess(data));
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
    yield put(loadFileTrackImportDetailsFailure());
  }
}

export function* uploadTrackImportCsv(action) {
  try {
    const { data } = yield call(
      API.post,
      `campanhaEan/upload?CampanhaFaixaAtingimentoId=${action.payload.id}&TransfereCampanhaEscolhaParticipante=${action.payload.transferCampaign}`,
      action.payload.file,
    );

    if (!data.erro) {
      yield put(trackImportCsvSuccess(data));
      yield put(
        showToastr({
          type: 'success',
          message: AppMessages.CAMPAIGN_REPORT_UPLOAD_SUCCESS,
          open: true,
        }),
      );
    } else {
      yield put(
        showToastr({ type: 'error', message: AppMessages.ERROR_MAILING_PROCCESS, open: true }),
      );
      yield put(trackImportCsvFailure());
    }
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
    yield put(trackImportCsvFailure());
  }
}

export function* deleteTrackImportCsv(action) {
  try {
    const { data } = yield call(API.delete, action.payload.deleteUrl);
    yield put(deleteTrackImportCsvSuccess(data));
    yield put(
      showToastr({
        type: 'success',
        message: AppMessages.UPLOAD_CAMPAIGN_REPORT_DELETE_SUCCESS,
        open: true,
      }),
    );
    const module = { id: Number(data.id) };
    yield put(loadTrackImportFileAction(module.id));
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
    yield put(deleteTrackImportCsvFailure());
  }
}
