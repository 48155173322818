import { Reducer } from 'redux';
import Utils from 'common/Utils';

export enum PopupsEditTypes {
  LOAD_REQUEST = '@popupsEdit/LOAD_REQUEST',
  LOAD_SUCCESS = '@popupsEdit/LOAD_SUCCESS',
  LOAD_FAILURE = '@popupsEdit/LOAD_FAILURE',
  CREATE_REQUEST = '@popupsEdit/CREATE_REQUEST',
  CREATE_SUCCESS = '@popupsEdit/CREATE_SUCCESS',
  UPDATE_REQUEST = '@popupsEdit/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@popupsEdit/UPDATE_SUCCESS',
  APPLYFILTER_REQUEST = '@popupsEdit/APPLYFILTER_REQUEST',
  APPLYFILTER_SUCCESS = '@popupsEdit/APPLYFILTER_SUCCESS',
  APPLYFILTER_FAILURE = '@popupsEdit/APPLYFILTER_FAILURE',
  GETFILTER_REQUEST = '@popupsEdit/GETFILTER_REQUEST',
  GETFILTER_FAILURE = '@popupsEdit/GETFILTER_FAILURE',
  GETFILTER_COMPLETED = '@popupsEdit/GETFILTER_COMPLETED',
  GETFILTER_RESET = '@popupsEdit/GETFILTER_RESET',
  GETFILTER_SETCOMPLETED = '@popupsEdit/GETFILTER_SETCOMPLETED',
  GETFILTER_PDV_SUCCESS = '@popupsEdit/GETFILTER_PDV_SUCCESS',
  GETFILTER_FDV_SUCCESS = '@popupsEdit/GETFILTER_FDV_SUCCESS',
  LOAD_POPUP_FILTER_APPLIED_REQUEST = '@popupsEdit/LOAD_POPUP_FILTER_APPLIED_REQUEST',
  LOAD_POPUP_FILTER_APPLIED_SUCCESS = '@popupsEdit/LOAD_POPUP_FILTER_APPLIED_SUCCESS',
  LOAD_POPUP_FILTER_APPLIED_FAILURE = '@popupsEdit/LOAD_POPUP_FILTER_APPLIED_FAILURE',
  SETFILTER_COUNT_PDV_REQUEST = '@popupsEdit/SETFILTER_COUNT_PDV_REQUEST',
  SETFILTER_COUNT_FDV_REQUEST = '@popupsEdit/SETFILTER_COUNT_FDV_REQUEST',
  RESET_POPUP = '@popupsEdit/RESET_POPUP',
  UPLOAD_PARTICIPANT_REQUEST = '@popupsEdit/UPLOAD_PARTICIPANT_REQUEST',
  UPLOAD_PARTICIPANT_SUCCESS = '@popupsEdit/UPLOAD_PARTICIPANT_SUCCESS',
  UPLOAD_PARTICIPANT_FAILURE = '@popupsEdit/UPLOAD_PARTICIPANT_FAILURE',
  LOAD_PARTICIPANT_REQUEST = '@popupsEdit/LOAD_PARTICIPANT_REQUEST',
  LOAD_PARTICIPANT_SUCCESS = '@popupsEdit/LOAD_PARTICIPANT_SUCCESS',
  LOAD_PARTICIPANT_FAILURE = '@popupsEdit/LOAD_PARTICIPANT_FAILURE',
  DELETE_PARTICIPANT_REQUEST = '@popupsEdit/DELETE_PARTICIPANT_REQUEST',
  DELETE_PARTICIPANT_SUCCESS = '@popupsEdit/DELETE_PARTICIPANT_SUCCESS',
  DELETE_PARTICIPANT_FAILURE = '@popupsEdit/DELETE_PARTICIPANT_FAILURE',
  LOAD_FILTER_CLUSTERS_REQUEST = '@popupsEdit/LOAD_FILTER_CLUSTERS_REQUEST',
  LOAD_FILTER_CLUSTERS_SUCCESS = '@popupsEdit/LOAD_FILTER_CLUSTERS_SUCCESS',
  LOAD_FILTER_CLUSTERS_FAILURE = '@popupsEdit/LOAD_FILTER_CLUSTERS_FAILURE',
  LOAD_FILTER_METHODS_PAYMENTS_REQUEST = '@popupsEdit/LOAD_FILTER_METHODS_PAYMENTS_REQUEST',
  LOAD_FILTER_METHODS_PAYMENTS_SUCCESS = '@popupsEdit/LOAD_FILTER_METHODS_PAYMENTS_SUCCESS',
  LOAD_FILTER_METHODS_PAYMENTS_FAILURE = '@popupsEdit/LOAD_FILTER_METHODS_PAYMENTS_FAILURE',
  LOAD_FILTER_REGIONS_REQUEST = '@popupsEdit/LOAD_FILTER_REGIONS_REQUEST',
  LOAD_FILTER_REGIONS_SUCCESS = '@popupsEdit/LOAD_FILTER_REGIONS_SUCCESS',
  LOAD_FILTER_REGIONS_FAILURE = '@popupsEdit/LOAD_FILTER_REGIONS_FAILURE',
  LOAD_FILTER_ZONE_REQUEST = '@popupsEdit/LOAD_FILTER_ZONE_REQUEST',
  LOAD_FILTER_ZONE_SUCCESS = '@popupsEdit/LOAD_FILTER_ZONE_SUCCESS',
  LOAD_FILTER_ZONE_FAILURE = '@popupsEdit/LOAD_FILTER_ZONE_FAILURE',
  LOAD_FILTER_TERRITORY_REQUEST = '@popupsEdit/LOAD_FILTER_TERRITORY_REQUEST',
  LOAD_FILTER_TERRITORY_SUCCESS = '@popupsEdit/LOAD_FILTER_TERRITORY_SUCCESS',
  LOAD_FILTER_TERRITORY_FAILURE = '@popupsEdit/LOAD_FILTER_TERRITORY_FAILURE',
}

export interface IPopups {
  popupID: number;
  name: string;
  caption: string;
  label: string;
  link: string;
  anotherPage: boolean;
  urlDownload364: string;
  urlDownload688: string;
}

export interface IFilter {
  loading: boolean;
  data: any[];
}
export interface IFiltersPopups {
  clusters: IFilter;
  metodosPagamentos: IFilter;
  regioes: IFilter;
  zonas: IFilter;
  territorios: IFilter;
}

export interface IPopupFiltroSegmentacaoEdit {
  id: number;
  popupId: number;
  baseFull: boolean;
  participanteSituacao: boolean;
  participanteSituacaoValor: string;
  cluster: boolean;
  tipoPagamento: boolean;
  regiao: boolean;
  zona: boolean;
  territorio: boolean;
}
export interface IPopupEdit {
  id: number;
  nome: string;
  codigoTipo: string;
  texto: string;
  imagemVideoUri: string;
  codigoTela: string;
  vigenciaDe: string;
  vigenciaAte: string;
  usuarioId: number;
  ativo: boolean;
  mostrarNumeroVezes: string;
  arquivoCsvUri: string;
  nomeArquivo: string;
  codigoTipoSegmentacao: string;
  url: string;
  codigoTamanho: string;
  popupFiltroSegmentacao: IPopupFiltroSegmentacaoEdit;
  clusters: any[];
  metodosPagamentos: any[];
  regioes: any[];
  zonas: any[];
  territorios: any[];
  participantesArquivos: [];
}

export interface IPopupEditState {
  readonly data: IPopupEdit;
  readonly loading: boolean;
  readonly error: boolean;
  readonly participantUpload: any;
  readonly participantData: any;
  readonly removingParticipantFile: boolean;
  readonly filtersPdv: IFiltersPopups;
}

const INITIAL_STATE: IPopupEditState = {
  data: {
    id: 0,
    nome: '',
    codigoTipo: '1',
    texto: '',
    imagemVideoUri: '',
    codigoTela: '1',
    vigenciaAte: '',
    vigenciaDe: '',
    usuarioId: 0,
    ativo: true,
    mostrarNumeroVezes: '0',
    arquivoCsvUri: '',
    nomeArquivo: '',
    codigoTipoSegmentacao: '1',
    url: '',
    codigoTamanho: '1',
    popupFiltroSegmentacao: {
      id: 0,
      popupId: 0,
      baseFull: false,
      participanteSituacao: false,
      participanteSituacaoValor: '0',
      cluster: false,
      tipoPagamento: false,
      regiao: false,
      zona: false,
      territorio: false,
    },
    clusters: [],
    metodosPagamentos: [],
    regioes: [],
    zonas: [],
    territorios: [],
    participantesArquivos: [],
  },
  loading: false,
  error: false,
  participantUpload: {
    loading_upload: false,
    success: false,
  },
  participantData: [],
  removingParticipantFile: false,
  filtersPdv: {
    clusters: { data: [], loading: false },
    metodosPagamentos: { data: [], loading: false },
    regioes: { data: [], loading: false },
    zonas: { data: [], loading: false },
    territorios: { data: [], loading: false },
  },
};

const reducer: Reducer<IPopupEditState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PopupsEditTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case PopupsEditTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...action.payload.data,
          popupFiltroSegmentacao:
            action.payload.data.popupFiltroSegmentacao === undefined ||
            action.payload.data.popupFiltroSegmentacao.length === 0
              ? { ...state.data.popupFiltroSegmentacao }
              : action.payload.data.popupFiltroSegmentacao,
          vigenciaDe: Utils.toDateInputValue(action.payload.data.vigenciaDe),
          vigenciaAte: Utils.toDateInputValue(action.payload.data.vigenciaAte),
        },
      };
    case PopupsEditTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true, data: { ...state.data } };
    case PopupsEditTypes.CREATE_REQUEST:
      return { ...state, loading: true };
    case PopupsEditTypes.CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...action.payload.data,
          popupFiltroSegmentacao:
            action.payload.data.popupFiltroSegmentacao === undefined ||
            action.payload.data.popupFiltroSegmentacao.length === 0
              ? { ...state.data.popupFiltroSegmentacao }
              : action.payload.data.popupFiltroSegmentacao,
        },
      };
    case PopupsEditTypes.UPDATE_REQUEST:
      return { ...state, loading: true };
    case PopupsEditTypes.UPDATE_SUCCESS:
      return { ...state, loading: false, data: action.payload.data };
    case PopupsEditTypes.APPLYFILTER_REQUEST:
      return { ...state, loading: true };
    case PopupsEditTypes.APPLYFILTER_SUCCESS:
      return { ...state, loading: false, data: { ...state.data } };
    case PopupsEditTypes.APPLYFILTER_FAILURE:
      return { ...state, loading: false };
    case PopupsEditTypes.GETFILTER_REQUEST:
      return { ...state, loading: true };
    case PopupsEditTypes.RESET_POPUP:
      return {
        ...INITIAL_STATE,
      };
    case PopupsEditTypes.UPLOAD_PARTICIPANT_REQUEST:
      return { ...state, loading: false, participantUpload: { loading_upload: true } };
    case PopupsEditTypes.UPLOAD_PARTICIPANT_SUCCESS:
      return {
        ...state,
        loading: false,
        participantUpload: { loading_upload: false, success: action.payload.data.sucesso },
      };
    case PopupsEditTypes.UPLOAD_PARTICIPANT_FAILURE:
      return {
        ...state,
        loading: false,
        participantUpload: { loading_upload: false, success: false },
      };
    case PopupsEditTypes.LOAD_PARTICIPANT_REQUEST:
      return { ...state, loading: false };
    case PopupsEditTypes.LOAD_PARTICIPANT_SUCCESS:
      return { ...state, loading: false, participantData: action.payload.data.ret };
    case PopupsEditTypes.LOAD_PARTICIPANT_FAILURE:
      return { ...state, loading: false };
    case PopupsEditTypes.DELETE_PARTICIPANT_REQUEST:
      return { ...state, loading: false };
    case PopupsEditTypes.DELETE_PARTICIPANT_SUCCESS:
      return { ...state, loading: false, removingParticipantFile: action.payload.data.sucesso };
    case PopupsEditTypes.DELETE_PARTICIPANT_FAILURE:
      return { ...state, loading: false };
    case PopupsEditTypes.LOAD_FILTER_CLUSTERS_REQUEST:
      return {
        ...state,
        filtersPdv: {
          ...state.filtersPdv,
          clusters: {
            ...state.filtersPdv.clusters,
            loading: true,
          },
        },
      };
    case PopupsEditTypes.LOAD_FILTER_CLUSTERS_SUCCESS:
      return {
        ...state,
        filtersPdv: {
          ...state.filtersPdv,
          clusters: {
            ...state.filtersPdv.clusters,
            loading: false,
            data: action.payload.data.data,
          },
        },
      };
    case PopupsEditTypes.LOAD_FILTER_CLUSTERS_FAILURE:
      return {
        ...state,
        filtersPdv: {
          ...state.filtersPdv,
          clusters: {
            ...state.filtersPdv.clusters,
            data: [],
            loading: false,
          },
        },
      };
    case PopupsEditTypes.LOAD_FILTER_METHODS_PAYMENTS_REQUEST:
      return {
        ...state,
        filtersPdv: {
          ...state.filtersPdv,
          metodosPagamentos: {
            ...state.filtersPdv.metodosPagamentos,
            loading: true,
          },
        },
      };
    case PopupsEditTypes.LOAD_FILTER_METHODS_PAYMENTS_SUCCESS:
      return {
        ...state,
        filtersPdv: {
          ...state.filtersPdv,
          metodosPagamentos: {
            ...state.filtersPdv.metodosPagamentos,
            loading: false,
            data: action.payload.data.data,
          },
        },
      };
    case PopupsEditTypes.LOAD_FILTER_METHODS_PAYMENTS_FAILURE:
      return {
        ...state,
        filtersPdv: {
          ...state.filtersPdv,
          metodosPagamentos: {
            ...state.filtersPdv.metodosPagamentos,
            data: [],
            loading: false,
          },
        },
      };
    case PopupsEditTypes.LOAD_FILTER_REGIONS_REQUEST:
      return {
        ...state,
        filtersPdv: {
          ...state.filtersPdv,
          regioes: {
            ...state.filtersPdv.regioes,
            loading: true,
          },
        },
      };
    case PopupsEditTypes.LOAD_FILTER_REGIONS_SUCCESS:
      return {
        ...state,
        filtersPdv: {
          ...state.filtersPdv,
          regioes: {
            ...state.filtersPdv.regioes,
            loading: false,
            data: action.payload.data.data,
          },
        },
      };
    case PopupsEditTypes.LOAD_FILTER_REGIONS_FAILURE:
      return {
        ...state,
        filtersPdv: {
          ...state.filtersPdv,
          regioes: {
            ...state.filtersPdv.regioes,
            data: [],
            loading: false,
          },
        },
      };
    case PopupsEditTypes.LOAD_FILTER_TERRITORY_REQUEST:
      return {
        ...state,
        filtersPdv: {
          ...state.filtersPdv,
          territorios: {
            ...state.filtersPdv.territorios,
            loading: true,
          },
        },
      };
    case PopupsEditTypes.LOAD_FILTER_TERRITORY_SUCCESS:
      return {
        ...state,
        filtersPdv: {
          ...state.filtersPdv,
          territorios: {
            ...state.filtersPdv.territorios,
            loading: false,
            data: action.payload.data.data,
          },
        },
      };
    case PopupsEditTypes.LOAD_FILTER_TERRITORY_FAILURE:
      return {
        ...state,
        filtersPdv: {
          ...state.filtersPdv,
          territorios: {
            ...state.filtersPdv.territorios,
            data: [],
            loading: false,
          },
        },
      };
    case PopupsEditTypes.LOAD_FILTER_ZONE_REQUEST:
      return {
        ...state,
        filtersPdv: {
          ...state.filtersPdv,
          zonas: {
            ...state.filtersPdv.zonas,
            loading: true,
          },
        },
      };
    case PopupsEditTypes.LOAD_FILTER_ZONE_SUCCESS:
      return {
        ...state,
        filtersPdv: {
          ...state.filtersPdv,
          zonas: {
            ...state.filtersPdv.zonas,
            loading: false,
            data: action.payload.data.data,
          },
        },
      };
    case PopupsEditTypes.LOAD_FILTER_ZONE_FAILURE:
      return {
        ...state,
        filtersPdv: {
          ...state.filtersPdv,
          zonas: {
            ...state.filtersPdv.zonas,
            data: [],
            loading: false,
          },
        },
      };
    default:
      return state;
  }
};

export default reducer;
