import { action } from 'typesafe-actions';
import { TotalMalhaAtualizacoesTypes, IRealtimeItem } from './';

export const loadRealtimeTotalMalhaAtualizacoesRequest = () =>
  action(TotalMalhaAtualizacoesTypes.LOAD_REQUEST);
export const loadRealtimeTotalMalhaAtualizacoesSuccess = (
  data: IRealtimeItem[],
  atualizacao: string,
) => action(TotalMalhaAtualizacoesTypes.LOAD_SUCCESS, { data, atualizacao });
export const loadRealtimeTotalMalhaAtualizacoesFailure = () =>
  action(TotalMalhaAtualizacoesTypes.LOAD_FAILURE);
