import { action } from 'typesafe-actions';
import { CommunicationTypes, ICommunicationItem, ICommunicationUpdateRequest } from '.';
import { IDefaultPatternRequest } from 'common/Constants';

export const loadCommunicationItems = (id: number) =>
  action(CommunicationTypes.LOAD_REQUEST, { id });
export const loadCommunicationItemsSuccess = (data: ICommunicationItem[]) =>
  action(CommunicationTypes.LOAD_SUCCESS, { data });
export const loadCommunicationItemsFailure = () => action(CommunicationTypes.LOAD_FAILURE);

export const loadCommunicationItemContent = (campaignId: number, campanhaComunicacaoId: number) =>
  action(CommunicationTypes.LOAD_HTML_REQUEST, { campaignId, campanhaComunicacaoId });
export const loadCommunicationItemContentSuccess = (data: ICommunicationItem[]) =>
  action(CommunicationTypes.LOAD_HTML_SUCCESS, { data });

export const deleteCommunicationItemContent = (campaignId: number, communicationId: number) =>
  action(CommunicationTypes.DELETE_HTML_REQUEST, { campaignId, communicationId });
export const deleteCommunicationItemContentSuccess = data =>
  action(CommunicationTypes.DELETE_HTML_SUCCESS, { data });

export const updateCommunicationsContent = (
  campaignId: number,
  body: ICommunicationUpdateRequest,
) => action(CommunicationTypes.UPDATE_REQUEST, { campaignId, body });
export const updateCommunicationsContentSuccess = data =>
  action(CommunicationTypes.UPDATE_SUCCESS, { data });

export const loadCommunicationPattern = (campaignId: number) =>
  action(CommunicationTypes.LOAD_PATTERN_REQUEST, { campaignId });
export const loadCommunicationPatternSuccess = data =>
  action(CommunicationTypes.LOAD_PATTERN_SUCCESS, { data });

export const uploadCommunicationPattern = (campaignId: number, data: FormData) =>
  action(CommunicationTypes.UPLOAD_PATTERN_REQUEST, { campaignId, data });
export const uploadCommunicationPatternSuccess = data =>
  action(CommunicationTypes.UPLOAD_PATTERN_SUCCESS, { data });

export const updateCommunicationPattern = (campaignId: number, request: IDefaultPatternRequest) =>
  action(CommunicationTypes.UPDATE_PATTERN_REQUEST, { campaignId, request });
export const updateCommunicationPatternSuccess = data =>
  action(CommunicationTypes.UPDATE_PATTERN_SUCCESS, { data });
