import React, { useState } from 'react';
import Wrap from 'components/Wrap/Wrap';
import { RouteComponentProps } from 'react-router-dom';
import { Breadcrumbs, Link, Box, Typography } from '@material-ui/core';
import Tab, { ITab } from 'components/Tab/Tab';
import { connect } from 'react-redux';
import ParticipantExemption from './ParticipantExemption';

interface IParams {
  id?: string | undefined;
}

const ParticipantExemptionEdit: React.FC<RouteComponentProps<IParams>> = ({ match, history }) => {
  const [tabs] = useState<ITab[]>([
    {
      component: <ParticipantExemption />,
      renderLabel: (tab, index) => `Upload Excel`,
      disabled: true,
    },
  ]);

  return (
    <Box mb="1.5em">
      <Wrap>
        <Breadcrumbs>
          <Link color="inherit" onClick={() => history.push('/')}>
            Home
          </Link>
          <Typography color="inherit">Pagamentos</Typography>
          <Link color="inherit" onClick={() => history.push('/participantExemption')}>
            Isenção Participantes
          </Link>
        </Breadcrumbs>
      </Wrap>

      <Tab tabs={tabs} enableNavigationButton={false} />
    </Box>
  );
};

export default connect()(ParticipantExemptionEdit);
