import React, { useState, useEffect, useRef } from 'react';
import {
  Paper,
  Box,
  makeStyles,
  Theme,
  createStyles,
  Breadcrumbs,
  Typography,
  Link,
  FormControl,
  TextField,
  Grid,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import Wrap from 'components/Wrap/Wrap';
import Utils from 'common/Utils';
import FileUpload from 'components/FileUpload/FileUpload';
import { IBannerEdit } from 'store/banner/edit';
import CostumizedSelectionPDVBanner from 'components/CostumizedSelectionPDV/CostumizedSelectionPDVBanner';
import { STRUCTURE_PULICTARGET } from 'common/Constants';
import { AppMessages } from 'common/AppMessages.enum';
import Api from 'common/Api';
import { ApplicationState } from 'store';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as BannersEditActions from 'store/banner/edit/actions';
import * as MailingActions from 'store/mailing/actions';
import { showToastr } from 'store/toastr/actions';
import { IToastrState } from 'store/toastr';
import { IElegibleFilterItems } from 'store/campaign/edit';
import { IMailing } from 'store/mailing';
import { MODULE_TYPE } from 'common/Constants';
import ElegiblesBanner from 'components/Elegibles/ElegiblesBanner';
import ModalBannerExample, { BannerExample } from './ModalBannerExample';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: theme.typography.fontFamily,
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    thumbs: {
      maxWidth: '100%',
      maxHeight: '200px',
      borderRadius: '6px',
      marginBottom: '12px',
    },
    formControl: {
      marginBottom: theme.spacing(3),
    },
    textCenter: {
      textAlign: 'center',
      display: 'block',
      width: '91%',
    },
  }),
);

export enum BannerMode {
  DESKTOP = 1,
  MOBILE = 2,
}
export enum BannerType {
  HOME = 1,
  LIGTHBOX = 2,
  HOME_BENEFICIOS = 3,
}

interface IParams {
  id?: string | undefined;
  typeId?: string | undefined;
}

interface BannerEditProps {
  createBanner(data: IBannerEdit): void;
  updateBanner(data: IBannerEdit): void;
  loadBanner(id: number): void;
  showToastr(options: IToastrState): void;
  resetBannerEdit(): void;
  loadMailing(id: number, moduleType: MODULE_TYPE): void;
  banner: IBannerEdit;
  filterPDV: IElegibleFilterItems;
  mailing: IMailing[];
  loading: boolean;
}

const BannerEdit: React.FC<BannerEditProps & RouteComponentProps<IParams>> = ({
  history,
  match,
  createBanner,
  updateBanner,
  loadBanner,
  loadMailing,
  showToastr,
  banner,
  filterPDV,
  resetBannerEdit,
  mailing,
  loading,
}) => {
  const classes = useStyle();
  const [bannerId] = useState(match.params.id);
  const [typeId] = useState(match.params.typeId);
  const [state, setState] = useState<IBannerEdit>({ ...banner });
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [isLoadingMobileImage, setIsLoadingMobileImage] = useState(false);
  const [modalOpen, setModalOpen] = useState({ open: false, url: '' });

  const handleSave = () => {
    const hasErrors = getErrorMessages();

    if (state.imagemMobileUri === '' || state.imagemUri === '') {
      showToastr({
        message: 'Imagens obrigatórias para o cadastro do banner.',
        type: 'error',
        open: true,
      });
      return;
    }

    if (!hasErrors) {
      if (!bannerId) createBanner({ ...state, tipoId: Number(match.params.typeId) });
      if (bannerId) updateBanner({ ...state, tipoId: Number(match.params.typeId) });
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const getErrorMessages = (): boolean => {
    const isDateValid = Utils.compareDate(state.vigenciaDe, state.vigenciaAte);
    const isInputRequiredValid = Utils.hasInputError(
      [state.url, state.vigenciaAte, state.vigenciaDe],
      true,
    );

    setErrorMessage(
      isDateValid
        ? AppMessages.DATE_START_BIGGERTHAN_END
        : isInputRequiredValid
        ? AppMessages.FORM_FIELDS_REQUIRED
        : '',
    );

    return [!isDateValid, !isInputRequiredValid].some(e => !e);
  };

  useEffect(() => {
    if (errorMessage !== '') {
      showToastr({
        message: errorMessage,
        type: 'error',
        open: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  useEffect(() => {
    setState({ ...state, tipoId: Number(match.params.typeId) });
    return () => resetBannerEdit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (bannerId) {
      loadBanner(Number(bannerId));
      loadMailing(Number(bannerId), MODULE_TYPE.BANNER);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bannerId]);

  useEffect(() => {
    setState({ ...state, ...banner });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [banner]);

  const handleStateChange = ({ target }) => {
    setState({ ...state, [target.name]: target.value });
  };

  const onUploadImage = async (file: any, type: number) => {
    const formData = new FormData();
    formData.append('file', file);
    if (type === 1) setIsLoadingImage(true);
    if (type === 2) setIsLoadingMobileImage(true);

    try {
      const { data } = await Api.post(`banner/upload`, formData);
      if (type === 1) {
        setState({ ...state, imagemUri: data.url });
        setIsLoadingImage(false);
      } else {
        setState({ ...state, imagemMobileUri: data.url });
        setIsLoadingMobileImage(false);
      }

      if (bannerId) {
        handleSave();
      }

      showToastr({ message: 'Banner enviado com sucesso.', type: 'success', open: true });
    } catch (error) {}
  };

  const getBannerSizeSugestion = (mode: BannerMode) => {
    if (typeId) {
      if (parseInt(typeId) === BannerType.HOME && mode === BannerMode.DESKTOP) {
        return 'Sugestão de resolução: 1920px x 700px';
      } else if (parseInt(typeId) === BannerType.HOME && mode === BannerMode.MOBILE) {
        return 'Sugestão de resolução: 375px x 300px';
      } else if (parseInt(typeId) === BannerType.HOME_BENEFICIOS && mode === BannerMode.DESKTOP) {
        return 'Sugestão de resolução: 1920px x 400px';
      } else if (parseInt(typeId) === BannerType.HOME_BENEFICIOS && mode === BannerMode.MOBILE) {
        return 'Sugestão de resolução: 375px x 300px';
      } else {
        return '';
      }
    }
  };

  const getBannerBannerExample = (mode: BannerMode) => {
    if (typeId) {
      if (parseInt(typeId) === BannerType.HOME && mode === BannerMode.DESKTOP) {
        return setModalOpen({ open: true, url: BannerExample.HOME_BANNER_DESKTOP });
      } else if (parseInt(typeId) === BannerType.HOME && mode === BannerMode.MOBILE) {
        return setModalOpen({ open: true, url: BannerExample.HOME_BANNER_MOBILE });
      } else if (parseInt(typeId) === BannerType.HOME_BENEFICIOS && mode === BannerMode.DESKTOP) {
        return setModalOpen({ open: true, url: BannerExample.HOME_BENEFICIO_DESKTOP });
      } else if (parseInt(typeId) === BannerType.HOME_BENEFICIOS && mode === BannerMode.MOBILE) {
        return setModalOpen({ open: true, url: BannerExample.HOME_BENEFICIO_MOBILE });
      } else {
        return '';
      }
    }
  };

  return (
    <Box>
      <Wrap>
        <Breadcrumbs>
          <Link color="inherit" onClick={() => history.push('/')}>
            Home
          </Link>
          <Link color="inherit" onClick={() => history.push('/banners')}>
            Gestão de Banners
          </Link>
          <Typography color="textPrimary">{!!bannerId ? 'Editar' : 'Cadastrar'}</Typography>
        </Breadcrumbs>
      </Wrap>

      <Paper className={classes.root}>
        <form>
          <Grid container={true} spacing={3}>
            <Grid item={true} md={6}>
              <FormControl fullWidth={true} variant="outlined" className={classes.formControl}>
                <TextField
                  error={Utils.hasInputError(state.url, true) && !!errorMessage}
                  required={true}
                  type="text"
                  id="url"
                  name="url"
                  value={state.url}
                  label="URL"
                  onChange={e => handleStateChange(e)}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
              <Box className={classes.formControl}>
                {
                  <>
                    {isLoadingImage ? (
                      <CircularProgress />
                    ) : (
                      state.imagemUri && (
                        <>
                          <img
                            src={state.imagemUri}
                            alt={state.imagemUri}
                            className={classes.thumbs}
                          />
                          <Box>
                            <Typography variant="body2" color="textSecondary" component="span">
                              {getBannerSizeSugestion(1)}
                            </Typography>
                            <Button
                              variant="contained"
                              component="label"
                              size="small"
                              style={{ marginLeft: 10 }}
                              onClick={() => getBannerBannerExample(1)}
                            >
                              Ver exemplo
                            </Button>
                          </Box>
                        </>
                      )
                    )}
                    <FileUpload
                      fullWidth={true}
                      onUpload={(file: any) => onUploadImage(file, 1)}
                      acceptType={['jpeg', 'jpg', 'png']}
                      showThumbnail={false}
                      loading={isLoadingImage}
                      text={'Selecione a imagem desktop:'}
                    />
                  </>
                }
              </Box>
            </Grid>
            <Grid item={true} xs={6}>
              <Grid container={true} spacing={3}>
                <Grid item={true} md={5}>
                  <FormControl fullWidth={true} className={classes.formControl}>
                    <TextField
                      error={Utils.hasInputError(state.vigenciaDe, true) && !!errorMessage}
                      required={true}
                      type="date"
                      id="startDate"
                      name="vigenciaDe"
                      value={state.vigenciaDe}
                      onChange={e => handleStateChange(e)}
                      label="Vigência da Campanha"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item={true} md={2}>
                  <FormControl fullWidth={true} variant="outlined" className={classes.formControl}>
                    <InputLabel className={classes.textCenter}>Até</InputLabel>
                  </FormControl>
                </Grid>
                <Grid item={true} md={5}>
                  <FormControl fullWidth={true} variant="outlined" className={classes.formControl}>
                    <TextField
                      error={Utils.hasInputError(state.vigenciaAte, true) && !!errorMessage}
                      required={true}
                      type="date"
                      id="endDate"
                      name="vigenciaAte"
                      value={state.vigenciaAte}
                      onChange={e => handleStateChange(e)}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Box className={classes.formControl}>
                {
                  <Box className={classes.formControl}>
                    {isLoadingMobileImage ? (
                      <CircularProgress />
                    ) : (
                      state.imagemMobileUri && (
                        <>
                          <img
                            src={state.imagemMobileUri}
                            alt={state.imagemMobileUri}
                            className={classes.thumbs}
                          />
                          <Box>
                            <Typography variant="body2" color="textSecondary" component="span">
                              {getBannerSizeSugestion(2)}
                            </Typography>
                            <Button
                              variant="contained"
                              component="label"
                              size="small"
                              style={{ marginLeft: 10 }}
                              onClick={() => getBannerBannerExample(2)}
                            >
                              Ver exemplo
                            </Button>
                          </Box>
                        </>
                      )
                    )}
                    <FileUpload
                      fullWidth={true}
                      onUpload={(file: any) => onUploadImage(file, 2)}
                      acceptType={['jpeg', 'jpg', 'png']}
                      showThumbnail={false}
                      loading={isLoadingMobileImage}
                      text={'Selecione a imagem mobile:'}
                    />
                  </Box>
                }
              </Box>
            </Grid>
          </Grid>
          <Box className={classes.formControl}>
            <Box fontSize={12} color="rgba(0, 0, 0, 0.54)">
              Opções:
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.janela}
                    onChange={(e, checked) => setState({ ...state, janela: checked })}
                    value="janela"
                    color="primary"
                  />
                }
                label="Target blank (nova janela)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.clicavel}
                    onChange={(e, checked) => setState({ ...state, clicavel: checked })}
                    value="clicavel"
                    color="primary"
                  />
                }
                label="Clicável"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.exibirInadimplente}
                    onChange={(e, checked) => setState({ ...state, exibirInadimplente: checked })}
                    value="exibirInadimplente"
                    color="primary"
                  />
                }
                label="Exibir para Inadimplentes"
              />
            </Box>
          </Box>
          <Box fontSize={14} color="red">
            {errorMessage}
          </Box>
          <Box mb="1.5em" textAlign="right">
            <Button disabled={loading} color="primary" variant="contained" onClick={handleSave}>
              {loading ? 'Aguarde...' : 'Salvar'}
            </Button>
          </Box>
        </form>
      </Paper>
      {!!bannerId && (
        <Paper className={classes.root}>
          <ElegiblesBanner
            bannerFilters={banner.filtros}
            bannerId={bannerId}
            publicTarget={STRUCTURE_PULICTARGET.PDV}
            filter={filterPDV}
            mailing={mailing}
            typeId={typeId}
          />
          <CostumizedSelectionPDVBanner bannerId={Number(bannerId)} />
        </Paper>
      )}
      <ModalBannerExample modalOpen={modalOpen} setModalOpen={setModalOpen} />
    </Box>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  banner: state.bannerEdit.data,
  loading: state.bannerEdit.loading,
  filterPDV: state.bannerEdit.elegibleFilterPDV.filtros,
  mailing: state.mailing.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...BannersEditActions,
      ...MailingActions,
      showToastr,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(BannerEdit);
