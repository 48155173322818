import React from 'react';
import { makeStyles, Theme, createStyles, Badge, IconButton } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      maxWidth: '100%',
      width: '200px',
      height: '200px',
      fontSize: '11px',
      color: '#555',
      borderRadius: '10px',
    },
    item: {
      border: '1px dashed #eee',
      marginBottom: '1rem',
      marginRight: '1rem',
      backgroundColor: 'white',
      display: 'inline-block',
      cursor: 'move',
      width: '200px',
      height: '200px',
      position: 'relative',
    },
    edit: {
      position: 'absolute',
      top: '0',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover > button': {
        display: 'block',
      },
    },
    editButton: {
      background: '#eee',
      display: 'none',
    },
  }),
);

export interface BannerOrderingCardProps {
  id: any;
  imgUrl: string;
  order: number;
  typeId: number;
  index: number;
}

const BannerOrderingCard: React.FC<BannerOrderingCardProps & RouteComponentProps> = ({
  id,
  typeId,
  imgUrl,
  order,
  history,
}) => {
  const classes = useStyle();

  return (
    <div className={classes.item}>
      <Badge color="primary" max={9999} badgeContent={order}>
        <img src={imgUrl} alt={imgUrl} className={classes.img} />
      </Badge>
      <div className={classes.edit}>
        <IconButton
          onClick={() => history.push(`banners/editar/${typeId}/${id}`)}
          color="secondary"
          className={classes.editButton}
        >
          <Edit />
        </IconButton>
      </div>
    </div>
  );
};

export default withRouter(BannerOrderingCard);
