import { loadCampaign } from './../campaign/edit/actions';
import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import {
  loadMailing as loadMalingAction,
  loadMailingSuccess,
  loadMailingFailure,
  uploadMailingFailure,
  uploadMailingSuccess,
  deleteMailingSuccess,
  deleteMailingFailure,
} from './actions';
import { showToastr } from 'store/toastr/actions';
import { AppMessages } from 'common/AppMessages.enum';
import store from 'store';
import { MODULE_TYPE } from 'common/Constants';

export function* loadMailing(action) {
  try {
    const module = action.payload.moduleType === MODULE_TYPE.CAMPAIGN ? 'campanha' : 'banner';
    const { data } = yield call(API.get, `campanha/${action.payload.id}/ListaParticipanteArquivo`);
    // const { data } = yield call(API.get, `${module}/${action.payload.id}/mailing`);
    yield put(loadMailingSuccess(data.arquivos));
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
    yield put(loadMailingFailure());
  }
}

export function* uploadMailing(action) {
  try {
    const { data } = yield call(API.post, action.payload.uploadUrl, action.payload.data);
    if (data.sucesso) {
      yield put(uploadMailingSuccess(data));
      yield put(
        showToastr({ type: 'success', message: AppMessages.MAILING_UPLOAD_SUCCESS, open: true }),
      );
      yield put(loadCampaign(action.payload.id));
    } else {
      yield put(
        showToastr({ type: 'error', message: AppMessages.ERROR_MAILING_PROCCESS, open: true }),
      );
      yield put(uploadMailingFailure());
    }
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
    yield put(uploadMailingFailure());
  }
}

export function* deleteMailing(action) {
  try {
    const { data } = yield call(API.delete, action.payload.deleteUrl);
    yield put(deleteMailingSuccess(data));
    yield put(
      showToastr({ type: 'success', message: AppMessages.MAILING_DELETE_SUCCESS, open: true }),
    );
    const module = action.payload.deleteUrl.includes('banner')
      ? {
          type: MODULE_TYPE.BANNER,
          id: Number(store.getState().bannerEdit.data.id),
        }
      : { type: MODULE_TYPE.CAMPAIGN, id: Number(store.getState().campaignEdit.data.id) };
    yield put(loadMalingAction(module.id, module.type));
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
    yield put(deleteMailingFailure());
  }
}
