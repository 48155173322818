import React, { useEffect, useState } from 'react';
import { ApplicationState } from 'store';
import { TABLE_STYLE } from 'common/Constants';
import {
  IconButton,
  Grid,
  TextField,
  FormControl,
  Button,
  Typography,
  Box,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import { Delete, ToggleOff, ToggleOn } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import { Dispatch, bindActionCreators } from 'redux';
import * as PlanManagementActions from 'store/planManagement/actions';
import { connect } from 'react-redux';
import GenericTable from 'components/GenericTable/GenericTable';
import { IPlan } from 'store/planManagement';
import Api from '../../../../common/Api';
import ModalConfirmation, {
  IModalConfirmationState,
} from 'components/ModalConfirmation/ModalConfirmation';

import * as ToastrActions from 'store/toastr/actions';
import { IToastrState } from 'store/toastr';

interface PlanFileProps {
  planList: IPlan[];
  loadAllPlanAvaliable(): void;
  showToastr(options: IToastrState): void;
}

interface ModalProps {
  title: string;
  descricao: string;
  valor: any;
  type: string;
  codigo: string;
  id: number;
}

const useStyle = makeStyles(() =>
  createStyles({
    title: {
      display: 'flex',
      alignContent: 'center',
    },
  }),
);

const ManagementExistingPlan: React.FC<PlanFileProps & ModalProps> = ({
  planList,
  loadAllPlanAvaliable,
  showToastr,
}) => {
  const classes = useStyle();
  const [modalConfirmation, setModalConfirmation] = useState<IModalConfirmationState>({
    open: false,
    onConfirm: null,
  });
  const [modalProps, setModalProps] = useState<ModalProps>({
    title: '',
    descricao: '',
    valor: 0,
    type: '',
    codigo: '',
    id: 0,
  });

  useEffect(() => {
    loadAllPlanAvaliable();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (modalProps.type) {
      setModalConfirmation({
        open: true,
        onConfirm: () => updatePlan(modalProps.type),
      });
    }
    // eslint-disable-next-line
  }, [modalProps]);

  const updatePlan = async (type: string) => {
    const formatValueCommaToDot = modalProps.valor.replace(',', '.');

    if (modalProps.codigo === 'PADRAO') {
      showToastr({
        type: 'error',
        message: 'Não é possível alterar parâmetros de um plano padrão',
        open: true,
      });
    } else {
      if (type === 'edit') {
        // requisição para EDITAR plano
        const response = await Api.put(`/planos/update/${modalProps.id}`, {
          valor: parseFloat(formatValueCommaToDot),
          descricao: modalProps.descricao,
        });

        if (response && response.status === 200) {
          showToastr({ type: 'success', message: response.data.id.message, open: true });
        }
      } else if (type === 'inactive') {
        // requisição para ATIVAR plano
        const response = await Api.put(`/planos/ativar/${modalProps.id}`);

        if (response && response.status === 200) {
          showToastr({ type: 'success', message: response.data.id.message, open: true });
        }
      } else if (type === 'active') {
        // requisição para DESATIVAR plano
        const response = await Api.delete(`/planos/${modalProps.id}`);

        if (response && response.status === 200) {
          showToastr({ type: 'success', message: response.data.id.message, open: true });
        }
      } else if (type === 'new') {
        // requisição para CRIAR plano
        const response = await Api.post(`/planos/create`, {
          descricao: modalProps.descricao,
          valor: parseFloat(formatValueCommaToDot),
          ativo: true,
        });

        if (response && response.status === 200) {
          showToastr({ type: 'success', message: response.data.id.message, open: true });
        }
      }
    }

    loadAllPlanAvaliable();
  };

  const handleModalTarget = (rowData, type, title) => {
    const formatValueDotToComma =
      rowData.valor === 0 ? '' : rowData.valor.toString().replace('.', ',');

    setModalProps({ ...rowData, type, title, valor: formatValueDotToComma });
  };

  const contentModal = () => {
    if (modalProps.type === 'edit') {
      return (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl fullWidth={true}>
              <TextField
                name="plan"
                id="plan"
                label="Nome do Plano"
                inputProps={{ maxLength: 50 }}
                value={modalProps.descricao}
                onChange={(e: React.ChangeEvent<{ value: any }>) =>
                  setModalProps({ ...modalProps, descricao: e.target.value })
                }
                variant="outlined"
                fullWidth
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="valorPlano"
              label="Mensalidade"
              value={modalProps.valor}
              onChange={(e: React.ChangeEvent<{ value: any }>) => {
                let regex = /^\d+(,\d{0,2})?$/;

                if (regex.test(e.target.value)) {
                  setModalProps({ ...modalProps, valor: e.target.value });
                }
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      );
    } else if (modalProps.type === 'active') {
      return (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            Realmente deseja desativar este plano?
          </Grid>
        </Grid>
      );
    } else if (modalProps.type === 'inactive') {
      return (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            Realmente deseja ativar este plano?
          </Grid>
        </Grid>
      );
    } else if (modalProps.type === 'new') {
      return (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl fullWidth={true}>
              <TextField
                name="plan"
                id="plan"
                label="Nome do Plano"
                inputProps={{ maxLength: 50 }}
                value={modalProps.descricao}
                onChange={(e: React.ChangeEvent<{ value: any }>) =>
                  setModalProps({ ...modalProps, descricao: e.target.value })
                }
                variant="outlined"
                fullWidth
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="valorPlano"
              label="Mensalidade"
              value={modalProps.valor || ''}
              onChange={(e: React.ChangeEvent<{ value: any }>) => {
                let regex = /^\d+(,\d{0,2})?$/;

                if (regex.test(e.target.value) || e.target.value === '') {
                  setModalProps({ ...modalProps, valor: e.target.value });
                }
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      );
    }
  };

  const titleContent = () => {
    return (
      <Box className={classes.title}>
        <Box mr="1em">
          <Typography variant="h6">Gestão de Planos Existentes</Typography>
        </Box>
        <Box>
          <Button
            color="primary"
            onClick={() =>
              handleModalTarget(
                {
                  descricao: '',
                  valor: 0,
                },
                'new',
                'Novo Plano',
              )
            }
          >
            Criar novo plano
          </Button>
        </Box>
      </Box>
    );
  };

  return !!planList.length ? (
    <>
      <GenericTable
        title={titleContent()}
        isLoading={false}
        data={planList}
        options={{
          paging: true,
          sorting: true,
          search: true,
        }}
        columns={[
          {
            title: 'Nome do Plano',
            field: 'descricao',
            render: rowData => {
              return `${rowData.descricao}`;
            },
            cellStyle: { ...TABLE_STYLE },
          },
          {
            title: 'Mensalidade',
            field: 'valor',
            render: rowData => {
              return rowData.valor.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
            cellStyle: { ...TABLE_STYLE },
          },
          {
            title: 'Status',
            field: 'ativo',
            render: rowData => {
              return `${rowData.ativo ? 'Ativo' : 'Inativo'}`;
            },
            cellStyle: { ...TABLE_STYLE },
          },
          {
            title: 'Ações',
            searchable: false,
            sorting: false,
            cellStyle: {
              width: '15%',
              ...TABLE_STYLE,
            },
            render: rowData => (
              <>
                {rowData.ativo ? (
                  <IconButton
                    color="primary"
                    onClick={() => handleModalTarget(rowData, 'active', 'Desativação do plano')}
                  >
                    <ToggleOn />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => handleModalTarget(rowData, 'inactive', 'Ativação do plano')}
                  >
                    <ToggleOff />
                  </IconButton>
                )}
                <IconButton
                  color="primary"
                  onClick={() => handleModalTarget(rowData, 'edit', 'Alteração de Plano')}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </>
            ),
          },
        ]}
      />

      <ModalConfirmation
        title={modalProps.title}
        content={contentModal()}
        state={modalConfirmation}
        setState={setModalConfirmation}
      />
    </>
  ) : null;
};

const mapStateToProps = (state: ApplicationState) => ({
  planList: state.planManagement.planAvaliableList.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...PlanManagementActions, ...ToastrActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ManagementExistingPlan);
