import { action } from 'typesafe-actions';
import { TotalMalhaAtivacoesTypes, IRealtimeItem } from './';

export const loadRealtimeTotalMalhaAtivacoesRequest = () =>
  action(TotalMalhaAtivacoesTypes.LOAD_REQUEST);
export const loadRealtimeTotalMalhaAtivacoesSuccess = (
  data: IRealtimeItem[],
  atualizacao: string,
) => action(TotalMalhaAtivacoesTypes.LOAD_SUCCESS, { data, atualizacao });
export const loadRealtimeTotalMalhaAtivacoesFailure = () =>
  action(TotalMalhaAtivacoesTypes.LOAD_FAILURE);
