import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  colors,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RssFeedIcon from '@material-ui/icons/RssFeed';
import WarningIcon from '@material-ui/icons/Warning';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from 'store';
import { IRealtimeItem } from 'store/realtime/totalMalhaCancelamentos';
import * as RealtimeActions from 'store/realtime/totalMalhaCancelamentos/actions';

interface CancelamentosCardProps {
  loadRealtimeTotalMalhaCancelamentosRequest(): void;
  totalMalhaCancelamentos: IRealtimeItem[];
  atualizacao: string | null;
  loading: boolean;
  error: boolean;
  refresh: number;
}

export interface ITotalMalhaCancelamentosState {
  itemsCard: IRealtimeItem[];
}

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    flexGrow: 1,
  },
  table: {
    width: '100%',
  },
  paper: {
    padding: 'spacing(2)',
    textAlign: 'center',
    color: 'palette.text.secondary',
    height: 40,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  avatar: {
    backgroundColor: colors.orange[600],
    height: 45,
    width: 45,
  },
  differenceIcon: {
    color: colors.red[900],
  },
  differenceValue: {
    color: colors.red[900],
  },
});

const DashboardCard: React.FC<CancelamentosCardProps> = ({
  loadRealtimeTotalMalhaCancelamentosRequest,
  totalMalhaCancelamentos,
  atualizacao,
  loading,
  error,
  refresh,
}) => {
  const classes = useStyles();

  useEffect(() => {
    loadRealtimeTotalMalhaCancelamentosRequest();
  }, [refresh]);

  return (
    <div>
      <Card className={classes.root} variant="outlined">
        <CardHeader
          avatar={
            <Avatar className={classes.avatar}>
              <RssFeedIcon />
            </Avatar>
          }
          title={
            <Typography color="textSecondary" gutterBottom variant="h6">
              CANCELAMENTOS
            </Typography>
          }
          subheader="Total de cancelamentos de varejos recebidos via integração nos últimos 5 dias."
        />
        <CardContent>
          {loading && <CircularProgress />}
          {totalMalhaCancelamentos.length > 0 && (
            <Grid container justify="space-between" spacing={2}>
              <Grid item justify="center" color="textSecondary">
                <Typography color="textSecondary">DATA</Typography>
              </Grid>
              <Grid item justify="center" color="textSecondary">
                <Typography color="textSecondary">TOTAL</Typography>
              </Grid>
            </Grid>
          )}
          {totalMalhaCancelamentos.length > 0 &&
            totalMalhaCancelamentos.map(row => (
              <Grid container justify="space-between" spacing={2}>
                <Grid item justify="center">
                  {row.nome}
                </Grid>
                <Grid item justify="center">
                  <b> {row.total}</b>
                </Grid>
              </Grid>
            ))}
          <Grid container justify="space-between" spacing={1}>
            <Grid item>
              <Typography color="textSecondary" variant="caption">
                {error && (
                  <>
                    <WarningIcon /> Ops, não foi possível buscar as informações, tente novamente.
                  </>
                )}
                {atualizacao && `Última atualização: ${atualizacao}`}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  totalMalhaCancelamentos: state.realtimeTotalMalhaCancelamentos.data,
  atualizacao: state.realtimeTotalMalhaCancelamentos.atualizacao,
  loading: state.realtimeTotalMalhaCancelamentos.loading,
  error: state.realtimeTotalMalhaCancelamentos.error,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...RealtimeActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(DashboardCard);
