import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import {
  LoadAllTypesPromotionsFailure,
  LoadAllTypesPromotionsSuccess,
  loadAllUploadHistoricSuccess,
  loadAllUploadHistoricFailure,
  loadUploadDetailSuccess,
  loadUploadDetailFailure,
  LoadAllPromotionRegistredSuccess,
  LoadAllPromotionRegistredFailure,
  LoadPromotionCampaignByIdSuccess,
  LoadPromotionCampaignByIdFailure,
  LoadCuttOffDaySuccess,
  LoadCuttOffDayFailure,
  createFilterPDVSuccess,
  createFilterPDVFailure,
  loadFilterPDVSuccess,
  loadFilterPDVFailure,
} from './actions';

export function* LoadAllTypesPromotions() {
  try {
    const { data } = yield call(API.get, '/participante-reingresso/tipoPromocao');

    yield put(LoadAllTypesPromotionsSuccess(data));
  } catch (error) {
    yield put(LoadAllTypesPromotionsFailure());
  }
}

export function* loadAllUploadHistoric() {
  try {
    const { data } = yield call(API.get, '/participante-reingresso/all');

    yield put(loadAllUploadHistoricSuccess(data));
  } catch (error) {
    yield put(loadAllUploadHistoricFailure());
  }
}

export function* loadUploadDetail(action) {
  try {
    const { data } = yield call(API.get, `/participante-reingresso/${action.payload.id}/linhas`);

    yield put(loadUploadDetailSuccess(data));
  } catch (error) {
    yield put(loadUploadDetailFailure());
  }
}

export async function CuttOffDayRequest(data) {
  try {
    const result = await API.post(
      `/participante-reingresso/DataCorte?diaCorte=${data.numeroDia}&inicioVigencia=${data.inicioVigencia}&fimVigencia=${data.fimVigencia}`,
    );
    return result;
  } catch (error) {
    return error;
  }
}

export async function ConfigPromotionRequest(data) {
  try {
    const result = await API.post(`/participante-reingresso/CampanhaPromocional`, { ...data });
    return result;
  } catch (error) {
    return error;
  }
}

export function* LoadAllPromotionRegistred() {
  try {
    const { data } = yield call(API.get, '/participante-reingresso/CampanhaPromocional');

    yield put(LoadAllPromotionRegistredSuccess(data));
  } catch (error) {
    yield put(LoadAllPromotionRegistredFailure());
  }
}

export async function UploadPromoByFileRequest(file, data) {
  try {
    const result = await API.post(
      `/participante-reingresso/upload?idPromocaoIncentivo=${data.idPromocaoIncentivo}`,
      file,
    );
    return result;
  } catch (error) {
    return error;
  }
}

export function* LoadPromotionCampaignById(action) {
  try {
    const { data } = yield call(
      API.get,
      `/participante-reingresso/CampanhaPromocional/${action.payload.id}`,
    );

    yield put(LoadPromotionCampaignByIdSuccess(data));
  } catch (error) {
    yield put(LoadPromotionCampaignByIdFailure());
  }
}

export function* LoadCuttOffDay() {
  try {
    const { data } = yield call(API.get, 'participante-reingresso/DataCorte');

    yield put(LoadCuttOffDaySuccess(data));
  } catch (error) {
    yield put(LoadCuttOffDayFailure());
  }
}

export function* createFilterPDV(action) {
  try {
    const { data } = yield call(
      API.post,
      '/reingresso-promocao-incentivo-filtro/create',
      action.payload.data,
    );

    yield put(createFilterPDVSuccess(data));
  } catch (error) {
    yield put(createFilterPDVFailure());
  }
}

export function* loadFilterPDV(action) {
  try {
    const { data } = yield call(
      API.get,
      `/reingresso-promocao-incentivo-filtro/listar?reingressoPromocaoIncentivoId=${action.payload.id}`,
    );

    yield put(loadFilterPDVSuccess(data));
  } catch (error) {
    yield put(loadFilterPDVFailure());
  }
}
