import React from 'react';
import Wrap from 'components/Wrap/Wrap';
import { RouteComponentProps } from 'react-router-dom';
import { Breadcrumbs, Link, Box } from '@material-ui/core';
import CuttOffDate from './CuttOffDate/CuttOffDate';

interface IParams {
  id?: string | undefined;
}

const CuttOffDateIndex: React.FC<RouteComponentProps<IParams>> = ({ match, history }) => {
  return (
    <Box mb="1.5em">
      <Wrap>
        <Breadcrumbs>
          <Link color="inherit" onClick={() => history.push('/')}>
            Home
          </Link>
          <Link color="inherit" onClick={() => history.push('/pagamentos/data-corte')}>
            Data de Corte
          </Link>
        </Breadcrumbs>
      </Wrap>

      <CuttOffDate />
    </Box>
  );
};

export default CuttOffDateIndex;
