import { Reducer } from 'redux';
import Utils from 'common/Utils';
import {
  IUploadVolume,
  IUploadVolumeDetail,
  IUploadFile,
} from 'components/GenericImportTable/GenericImportTable';

export enum CampaignCountingTypes {
  LOAD_REQUEST = '@campaignCounting/LOAD_REQUEST',
  LOAD_SUCCESS = '@campaignCounting/LOAD_SUCCESS',
  LOAD_FAILURE = '@campaignCounting/LOAD_FAILURE',
  GENERATE_REPORT_REQUEST = '@campaignCounting/GENERATE_REPORT_REQUEST',
  GENERATE_REPORT_SUCCESS = '@campaignCounting/GENERATE_REPORT_SUCCESS',
  GENERATE_REPORT_FAILURE = '@campaignCounting/GENERATE_REPORT_FAILURE',
  GENERATE_REPORT_RESET_PARAMS = '@campaignCounting/GENERATE_REPORT_RESET_PARAMS',
  LOAD_VOLUME_REQUEST = '@campaignCounting/LOAD_VOLUME_REQUEST',
  LOAD_VOLUME_SUCCESS = '@campaignCounting/LOAD_VOLUME_SUCCESS',
  LOAD_VOLUME_FAILURE = '@campaignCounting/LOAD_VOLUME_FAILURE',
  LOAD_DETAIL_LIST_REQUEST = '@campaignCounting/LOAD_DETAIL_LIST_REQUEST',
  LOAD_DETAIL_LIST_SUCCESS = '@campaignCounting/LOAD_DETAIL_LIST_SUCCESS',
  LOAD_DETAIL_LIST_FAILURE = '@campaignCounting/LOAD_DETAIL_LIST_FAILURE',
  UPLOAD_REQUEST = '@campaignCounting/UPLOAD_REQUEST',
  UPLOAD_SUCCESS = '@campaignCounting/UPLOAD_SUCCESS',
  UPLOAD_FAILURE = '@campaignCounting/UPLOAD_FAILURE',
}

export interface ICampaignReportParams {
  campanhaId: number;
  mesReferencia: number;
  anoReferencia: number;
}

export interface ICampaignCountingItem {
  id: number;
  nome: string;
  vigenciaAte: string;
  vigenciaDe: string;
  mesReferencia: number;
  anoReferencia: number;
  aceites: number;
}
export interface ICampaignCoutingState {
  readonly data: ICampaignCountingItem[];
  readonly loading: boolean;
  readonly error: boolean;
  readonly loadingReport: boolean;
  readonly report: string;
  readonly uploadVolume: IUploadVolume;
  readonly uploadVolumeDetail: IUploadVolumeDetail;
  readonly uploadFile: IUploadFile;
}

const INITIAL_STATE: ICampaignCoutingState = {
  data: [],
  loading: false,
  error: false,
  loadingReport: false,
  report: '',
  uploadVolume: {
    data: [],
    loading: false,
  },
  uploadVolumeDetail: {
    data: [],
    loading: false,
  },
  uploadFile: {
    loading: false,
    success: false,
    error: false,
  },
};

const reducer: Reducer<ICampaignCoutingState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CampaignCountingTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case CampaignCountingTypes.LOAD_SUCCESS:
      const formated = action.payload.data.map((e: ICampaignCountingItem) => {
        return {
          ...e,
          vigenciaDe: Utils.setData(e.vigenciaDe),
          vigenciaAte: Utils.setData(e.vigenciaAte),
        };
      });
      return { ...state, loading: false, error: false, data: formated };
    case CampaignCountingTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true, data: [] };
    case CampaignCountingTypes.GENERATE_REPORT_REQUEST:
      return { ...state, loadingReport: true };
    case CampaignCountingTypes.GENERATE_REPORT_SUCCESS:
      return { ...state, report: action.payload.data };
    case CampaignCountingTypes.GENERATE_REPORT_FAILURE:
      return { ...state, loadingReport: false };
    case CampaignCountingTypes.GENERATE_REPORT_RESET_PARAMS:
      return { ...state, loadingReport: false, report: '' };
    case CampaignCountingTypes.LOAD_VOLUME_REQUEST:
      return {
        ...state,
        uploadVolume: {
          ...state.uploadVolume,
          loading: true,
        },
      };
    case CampaignCountingTypes.LOAD_VOLUME_SUCCESS:
      return {
        ...state,
        uploadVolume: {
          ...state.uploadVolume,
          data: [...action.payload.data],
          loading: false,
          success: true,
        },
      };
    case CampaignCountingTypes.LOAD_VOLUME_FAILURE:
      return {
        ...state,
        uploadVolume: {
          ...state.uploadVolume,
          loading: false,
          success: false,
          error: true,
        },
      };
    case CampaignCountingTypes.LOAD_DETAIL_LIST_REQUEST:
      return {
        ...state,
        uploadVolumeDetail: {
          ...state.uploadVolumeDetail,
          loading: true,
        },
      };
    case CampaignCountingTypes.LOAD_DETAIL_LIST_SUCCESS:
      return {
        ...state,
        uploadVolumeDetail: {
          ...state.uploadVolumeDetail,
          data: [...action.payload.data],
          loading: false,
          success: true,
        },
      };
    case CampaignCountingTypes.LOAD_DETAIL_LIST_FAILURE:
      return {
        ...state,
        uploadVolumeDetail: {
          ...state.uploadVolumeDetail,
          loading: false,
          success: false,
          error: true,
        },
      };
    case CampaignCountingTypes.UPLOAD_REQUEST:
      return {
        ...state,
        uploadFile: {
          ...state.uploadFile,
          loading: true,
          success: false,
          error: false,
        },
      };
    case CampaignCountingTypes.UPLOAD_SUCCESS:
      return {
        ...state,
        uploadVolume: {
          ...state.uploadVolume,
          data: [...state.uploadVolume.data, action.payload.data],
        },
        uploadFile: {
          ...state.uploadFile,
          loading: false,
          success: true,
          error: false,
        },
      };
    case CampaignCountingTypes.UPLOAD_FAILURE:
      return {
        ...state,
        uploadFile: {
          ...state.uploadFile,
          loading: false,
          success: false,
          error: true,
        },
      };
    default:
      return state;
  }
};

export default reducer;
