import { Reducer } from 'redux';

export enum MigrationOfPointsTypes {
  LOAD_STATUS_REQUEST = '@MigrationOfPointsTypes/LOAD_STATUS_REQUEST',
  LOAD_STATUS_SUCCESS = '@MigrationOfPointsTypes/LOAD_STATUS_SUCCESS',
  LOAD_STATUS_FAILURE = '@MigrationOfPointsTypes/LOAD_STATUS_FAILURE',
  LOAD_PENDING_MIGRATIONS_REQUEST = '@MigrationOfPointsTypes/LOAD_PENDING_MIGRATIONS_REQUEST',
  LOAD_PENDING_MIGRATIONS_SUCCESS = '@MigrationOfPointsTypes/LOAD_PENDING_MIGRATIONS_SUCCESS',
  LOAD_PENDING_MIGRATIONS_FAILURE = '@MigrationOfPointsTypes/LOAD_PENDING_MIGRATIONS_FAILURE',
  LOAD_PENDING_MIGRATION_DETAIL_REQUEST = '@MigrationOfPointsTypes/LOAD_PENDING_MIGRATION_DETAIL_REQUEST',
  LOAD_PENDING_MIGRATION_DETAIL_SUCCESS = '@MigrationOfPointsTypes/LOAD_PENDING_MIGRATION_DETAIL_SUCCESS',
  LOAD_PENDING_MIGRATION_DETAIL_FAILURE = '@MigrationOfPointsTypes/LOAD_PENDING_MIGRATION_DETAIL_FAILURE',
  LOAD_PENDING_MIGRATION_CSV_REQUEST = '@MigrationOfPointsTypes/LOAD_PENDING_MIGRATION_CSV_REQUEST',
  LOAD_PENDING_MIGRATION_CSV_SUCCESS = '@MigrationOfPointsTypes/LOAD_PENDING_MIGRATION_CSV_SUCCESS',
  LOAD_PENDING_MIGRATION_CSV_FAILURE = '@MigrationOfPointsTypes/LOAD_PENDING_MIGRATION_CSV_FAILURE',
  LOAD_PENDING_MIGRATION_CSV_RESET = '@MigrationOfPointsTypes/LOAD_PENDING_MIGRATION_CSV_RESET',
  MODERATE_MIGRATION_REQUEST = '@MigrationOfPointsTypes/MODERATE_MIGRATION_REQUEST',
  MODERATE_MIGRATION_SUCCESS = '@MigrationOfPointsTypes/MODERATE_MIGRATION_SUCCESS',
  MODERATE_MIGRATION_FAILURE = '@MigrationOfPointsTypes/MODERATE_MIGRATION_FAILURE',
  LOAD_MIGRATION_REPORT_REQUEST = '@MigrationOfPointsTypes/LOAD_MIGRATION_REPORT_REQUEST',
  LOAD_MIGRATION_REPORT_SUCCESS = '@MigrationOfPointsTypes/LOAD_MIGRATION_REPORT_SUCCESS',
  LOAD_MIGRATION_REPORT_FAILURE = '@MigrationOfPointsTypes/LOAD_MIGRATION_REPORT_FAILURE',
  LOAD_MIGRATION_REPORT_RESET = '@MigrationOfPointsTypes/LOAD_MIGRATION_REPORT_RESET',
}

export interface IMigrationOfPointsParams {
  idStatus: number;
  documento: string;
}

export interface IMigrationOfPointsItem {
  id: number;
  userName: string;
  razaoSocial: string;
  dataSolicitacao: string;
  dataSolicitacaoStr: string;
  totalPontos: number;
  statusId: number;
  status: string;
  motivo: string;
  moderadoPor: string;
  dataAprovacao: string;
  dataModeracao: string;
}

export interface IMigrationPointsDetail {
  nome: string;
  userName: string;
  pontosAtuais: number;
  pontos: number;
  idParticipante: number;
  idParticipanteRelacao: number;
}

export interface IMigrationPointsModeration {
  idSolicitacao: number;
  idStatus: number;
  motivo: string;
}

export interface IStatusItem {
  id: number;
  codigo: string;
  nome: string;
}

export interface IMigrationOfPointsState {
  readonly data: IMigrationOfPointsItem[];
  readonly migrationDetail: IMigrationPointsDetail[];
  readonly statusList: IStatusItem[];
  readonly loading: boolean;
  readonly loadingReport: boolean;
  readonly error: boolean;
  readonly success: boolean;
  readonly approved: any;
  readonly migrationCsv: string;
  readonly report: string;
}

export const INITIAL_STATE: IMigrationOfPointsState = {
  data: [],
  migrationDetail: [],
  statusList: [],
  loading: false,
  loadingReport: false,
  error: false,
  success: false,
  approved: undefined,
  migrationCsv: '',
  report: '',
};

const reducer: Reducer<IMigrationOfPointsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MigrationOfPointsTypes.LOAD_STATUS_REQUEST:
      return { ...state, loading: true };
    case MigrationOfPointsTypes.LOAD_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        statusList: action.payload.data,
      };
    case MigrationOfPointsTypes.LOAD_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        statusList: [],
        approved: false,
      };
    case MigrationOfPointsTypes.LOAD_PENDING_MIGRATIONS_REQUEST:
      return { ...state, loading: true };
    case MigrationOfPointsTypes.LOAD_PENDING_MIGRATIONS_SUCCESS:
      return { ...state, loading: false, error: false, success: true, data: action.payload.data };
    case MigrationOfPointsTypes.LOAD_PENDING_MIGRATIONS_FAILURE:
      return { ...state, loading: false, error: true, success: false, data: [] };
    case MigrationOfPointsTypes.LOAD_PENDING_MIGRATION_DETAIL_REQUEST:
      return { ...state, loading: true, approved: undefined };
    case MigrationOfPointsTypes.LOAD_PENDING_MIGRATION_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        migrationDetail: action.payload.data,
      };
    case MigrationOfPointsTypes.LOAD_PENDING_MIGRATION_DETAIL_FAILURE:
      return { ...state, loading: false, error: true, success: false };
    case MigrationOfPointsTypes.LOAD_PENDING_MIGRATION_CSV_REQUEST:
      return { ...state, loading: true };
    case MigrationOfPointsTypes.LOAD_PENDING_MIGRATION_CSV_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        migrationCsv: action.payload.data,
      };
    case MigrationOfPointsTypes.LOAD_PENDING_MIGRATION_CSV_FAILURE:
      return { ...state, loading: false, error: true, success: false };
    case MigrationOfPointsTypes.LOAD_PENDING_MIGRATION_CSV_RESET:
      return { ...state, loading: false, error: false, success: false, migrationCsv: '' };
    case MigrationOfPointsTypes.MODERATE_MIGRATION_REQUEST:
      return { ...state, loading: true };
    case MigrationOfPointsTypes.MODERATE_MIGRATION_SUCCESS:
      return { ...state, approved: action.payload.data.success, success: true, loading: false };
    case MigrationOfPointsTypes.MODERATE_MIGRATION_FAILURE:
      return {
        ...state,
        approved: action.payload.data.success,
        loading: false,
        error: true,
        success: false,
      };
    case MigrationOfPointsTypes.LOAD_MIGRATION_REPORT_REQUEST:
      return { ...state, loading: true };
    case MigrationOfPointsTypes.LOAD_MIGRATION_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        report: action.payload.data,
      };
    case MigrationOfPointsTypes.LOAD_MIGRATION_REPORT_FAILURE:
      return { ...state, loading: false, error: true, success: false };
    case MigrationOfPointsTypes.LOAD_MIGRATION_REPORT_RESET:
      return { ...state, loading: false, error: false, success: false, report: '' };
    default:
      return state;
  }
};

export default reducer;
