import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from 'store';
import * as SkuActions from 'store/sku/actions';
import { ISkuEdit, ISkuLisItem } from 'store/sku';
import Utils from 'common/Utils';
import ElegiblesFilterItemPopup, {
  IFilterParamPopup,
} from 'components/Elegibles/ElegiblesFilterItemPopup';
interface SkuProps {
  loadSku(id: number): void;
  loadAllSkuList(): void;
  createSku(data: ISkuEdit): void;
  updateSku(data: ISkuEdit): void;
  campaignId?: number;
  loading: boolean;
  loadingSelectedSku: boolean;
  loadingSkuList: boolean;
  sku;
  skuList: ISkuLisItem[];
  campaign;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    cardContent: {
      padding: theme.spacing(0),
      marginBottom: theme.spacing(0),
    },
    root: {
      fontFamily: theme.typography.fontFamily,
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    formControl: {
      marginBottom: theme.spacing(1),
    },
    fullWidth: {
      width: '100%',
    },
    spacing: {
      marginBottom: theme.spacing(2),
    },
    buttonActions: {
      padding: theme.spacing(1),
      minWidth: 120,
      textAlign: 'right',
      '& > button': {
        marginRight: 0,
      },
    },
    listBox: {
      heigth: '260px',
      overflow: 'auto',
      minHeight: '260px',
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& > button': {
        marginLeft: theme.spacing(2),
        marginRight: '0 !important',
      },
    },
  }),
);

const Sku: React.FC<SkuProps> = ({
  loading,
  createSku,
  loadSku,
  sku,
  skuList,
  campaign,
  updateSku,
  loadAllSkuList,
  loadingSelectedSku,
  loadingSkuList,
}) => {
  const classes = useStyle();
  const [skuState, setSkuState] = useState<any[]>([]);

  useEffect(() => {
    loadAllSkuList();
    loadSku(Number(campaign.id));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!loadingSkuList && !loadingSelectedSku) {
      let skuIds = sku.eans.map(x => {
        return x.id;
      });

      setSkuState([
        ...Utils.toFilterItemObjectSelecteItemsPopup<IFilterParamPopup>(
          skuList,
          'id',
          'label',
          'title',
          skuIds,
        ),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skuList, sku]);

  const handleSave = () => {
    let filteredSkusIds = skuState
      .filter(x => {
        return x.selected;
      })
      .map(x => {
        return x.id;
      });

    if (!!sku.eans.length) {
      updateSku({ eans: filteredSkusIds, campanhaId: campaign.id });
    } else {
      createSku({ eans: filteredSkusIds, campanhaId: campaign.id });
    }
  };

  return (
    <>
      <Paper elevation={3}>
        <form>
          <Box p="1.5em">
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={4} lg={4}>
                <Card className={classes.root}>
                  <CardHeader title="Eans" />
                  <CardContent className={classes.cardContent}>
                    <ElegiblesFilterItemPopup
                      isLoading={loadingSelectedSku}
                      itemName="sku"
                      searchTitle="Pesquisar SKU"
                      setState={setSkuState}
                      state={skuState}
                      forKey="id"
                    />
                  </CardContent>
                </Card>
                <Box mb="1.5em" textAlign="right">
                  <Button
                    disabled={loading}
                    color="secondary"
                    variant="contained"
                    onClick={handleSave}
                  >
                    {loading ? 'Aguarde...' : 'Salvar'}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Paper>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  campaign: state.campaignEdit.data,
  sku: state.sku.data,
  loadingSelectedSku: state.sku.loading,
  skuList: state.sku.skuList,
  loadingSkuList: state.sku.loadingSkuList,
  loading: state.campaignEdit.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ ...SkuActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Sku);
