import { action } from 'typesafe-actions';
import { PaymentMethodsTypes, IPaymentMethodsItem, IPaymentMethodsParams } from './';

export const loadPaymentMethodRequest = () =>
  action(PaymentMethodsTypes.LOAD_PAYMENT_METHOD_REQUEST);

export const loadPaymentMethodSuccess = (data: IPaymentMethodsItem[]) =>
  action(PaymentMethodsTypes.LOAD_PAYMENT_METHOD_SUCCESS, { data });

export const loadPaymentMethodFailure = () =>
  action(PaymentMethodsTypes.LOAD_PAYMENT_METHOD_FAILURE);

export const generatePaymentMethodReportRequest = (data: IPaymentMethodsParams) =>
  action(PaymentMethodsTypes.GENERATE_PAYMENT_METHOD_REPORT_REQUEST, { data });

export const generatePaymentMethodReportSuccess = (data: undefined) =>
  action(PaymentMethodsTypes.GENERATE_PAYMENT_METHOD_REPORT_SUCCESS, { data });

export const generatePaymentMethodReportFailure = () =>
  action(PaymentMethodsTypes.GENERATE_PAYMENT_METHOD_REPORT_FAILURE);

export const generatePaymentMethodReportResetParams = () =>
  action(PaymentMethodsTypes.GENERATE_PAYMENT_METHOD_REPORT_RESET_PARAMS);

//ConectaPay

export const loadPaymentReceivablesMethodRequest = () =>
  action(PaymentMethodsTypes.LOAD_PAYMENT_RECEIVABLES_METHOD_REQUEST);

export const loadPaymentReceivablesMethodSuccess = (data: IPaymentMethodsItem[]) =>
  action(PaymentMethodsTypes.LOAD_PAYMENT_RECEIVABLES_METHOD_SUCCESS, { data });

export const loadPaymentReceivablesMethodFailure = () =>
  action(PaymentMethodsTypes.LOAD_PAYMENT_RECEIVABLES_METHOD_FAILURE);

export const generatePaymentReceivablesMethodReportRequest = (data: IPaymentMethodsParams) =>
  action(PaymentMethodsTypes.GENERATE_PAYMENT_RECEIVABLES_METHOD_REPORT_REQUEST, { data });

export const generatePaymentReceivablesMethodReportSuccess = (data: undefined) =>
  action(PaymentMethodsTypes.GENERATE_PAYMENT_RECEIVABLES_METHOD_REPORT_SUCCESS, { data });

export const generatePaymentReceivablesMethodReportFailure = () =>
  action(PaymentMethodsTypes.GENERATE_PAYMENT_RECEIVABLES_METHOD_REPORT_FAILURE);

export const generatePaymentReceivablesMethodReportResetParams = () =>
  action(PaymentMethodsTypes.GENERATE_PAYMENT_RECEIVABLES_METHOD_REPORT_RESET_PARAMS);
