import React, { useEffect, useState } from 'react';
import { ApplicationState } from 'store';
import { Box, createStyles, makeStyles, Modal, Paper } from '@material-ui/core';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import * as PaymentsActions from 'store/payments/actions';
import Elegibles from 'components/Elegibles/Elegibles';
import FileUpload from 'components/FileUpload/FileUpload';

export interface ModalConfigProps {
  open: boolean;
  type: string;
  id: number;
  readOnly: boolean;
  tableDataId?: boolean;
}

interface FilterParamModalProps {
  historicFilterPDVList: any;
  loading: boolean;
  modalConfig: ModalConfigProps;
  setModalConfig: React.Dispatch<React.SetStateAction<ModalConfigProps>>;
  uploadFile(file: FormData, id: number): void;
  loadHistoricFilterPDVList(id: number): void;
}

const useStyle = makeStyles(() =>
  createStyles({
    box: {
      position: 'absolute',
      top: '50%',
      overflowY: 'auto',
      left: '50%',
      height: '85%',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      backgroundColor: '#ffffff',
      border: '2px solid #000',
      padding: '25px',
    },
  }),
);

const FilterFileModal: React.FC<FilterParamModalProps> = ({
  historicFilterPDVList,
  loading,
  modalConfig,
  setModalConfig,
  uploadFile,
  loadHistoricFilterPDVList,
}) => {
  const [filterList, setFilterList] = useState({
    clusters: [],
    atividades: [],
    ufs: [],
  });
  const modal = useStyle();

  const handleUpload = (file: any) => {
    const data = new FormData();
    data.append('file', file);

    uploadFile(data, modalConfig.id);
    setModalConfig({ open: false, type: '', id: 0, readOnly: false });
  };

  useEffect(() => {
    if (!!historicFilterPDVList.length && modalConfig.type === 'pdv' && !modalConfig.readOnly) {
      let activeFilterPdv = historicFilterPDVList.find(x => x.ativo);

      return setFilterList({
        clusters: activeFilterPdv.clusters,
        atividades: activeFilterPdv.atividades,
        ufs: activeFilterPdv.ufs,
      });
    } else if (modalConfig.readOnly) {
      let activeFilterPdv = historicFilterPDVList.find(item => {
        return item.tableData.id === modalConfig.tableDataId;
      });
      return setFilterList({
        clusters: activeFilterPdv.clusters,
        atividades: activeFilterPdv.atividades,
        ufs: activeFilterPdv.ufs,
      });
    } else {
      return setFilterList({
        clusters: [],
        atividades: [],
        ufs: [],
      });
    }
    // eslint-disable-next-line
  }, [historicFilterPDVList]);

  useEffect(() => {
    if (modalConfig.id !== 0 && modalConfig.type === 'pdv') {
      loadHistoricFilterPDVList(modalConfig.id);
    }
    // eslint-disable-next-line
  }, [modalConfig.id]);

  return (
    <>
      <Modal
        open={modalConfig.open}
        onClose={() => setModalConfig({ open: false, type: '', id: 0, readOnly: false })}
      >
        <Box className={modal.box}>
          <Paper elevation={3}>
            {modalConfig.type === 'file' ? (
              <Box p="1.5em" pt="0.1em">
                <Box fontSize="h5.fontSize" mt="1em">
                  Definir seleção customizada:
                </Box>
                <Box fontSize={14} mb="1em">
                  <strong>Importante!</strong> Para realizar a seleção customizada faça o upload de
                  um arquivo CSV contendo a base de CodigoSap elegíveis.
                </Box>

                <FileUpload
                  onUpload={handleUpload}
                  acceptType={['csv', 'xls', 'xlsx']}
                  disabled={loading}
                />
              </Box>
            ) : (
              <Elegibles
                disabled={modalConfig.readOnly}
                campaignFilters={filterList}
                dataCorteId={modalConfig.id}
                publicTarget={1}
                setModalCuttOffDateConfig={setModalConfig}
              />
            )}
          </Paper>
        </Box>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  historicFilterPDVList: state.payments.historicFilterPDV.data,
  loading: state.payments.historicFilterPDV.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...PaymentsActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FilterFileModal);
