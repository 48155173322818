import React, { useState } from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  Card,
  CardHeader,
  CardMedia,
  CardActions,
  CircularProgress,
  CardContent,
  Typography,
} from '@material-ui/core';
import { PLACEHOLDER_IMAGE } from 'common/Constants';
import FileUpload from 'components/FileUpload/FileUpload';
import { ICampaignBanner } from 'store/campaign/edit';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import * as CampaignEditActions from 'store/campaign/edit/actions';
import * as ToastrActions from 'store/toastr/actions';
import { IToastrState } from 'store/toastr/index';
import Api from 'common/Api';
import Utils from 'common/Utils';
import { AppMessages } from 'common/AppMessages.enum';

export enum BannerHomeSize {
  SMALL = 1,
  MEDIUM = 2,
  BIG = 3,
}

export function getBannerSizeDescription(size: BannerHomeSize) {
  switch (size) {
    case BannerHomeSize.SMALL:
      return 'Pequeno';
    case BannerHomeSize.MEDIUM:
      return 'Médio';
    case BannerHomeSize.BIG:
      return 'Grande';
    default:
      return '';
  }
}

export function getBannerSizeSugestion(size: BannerHomeSize) {
  switch (size) {
    case BannerHomeSize.SMALL:
      return 'Sugestão de resolução: 600px x 500px';
    case BannerHomeSize.MEDIUM:
      return 'Sugestão de resolução: 1200px x 600px';
    case BannerHomeSize.BIG:
      return 'Sugestão de resolução: 1600px x 600px';
    default:
      return '';
  }
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    spacing: {
      padding: theme.spacing(2),
    },
    media: {
      height: 0,
      paddingTop: '56.25%',
    },
  }),
);

const acceptTypes = ['png', 'jpg', 'jpeg', 'gif'];

interface BannerHomeUploadProps {
  size: BannerHomeSize;
  banner?: ICampaignBanner;
  campaignId: number;
  uploadCampaignBanner(data: FormData, id: number): void;
  loadCampaign(id: number): void;
  showToastr(options: IToastrState): void;
}

const BannerHomeUpload: React.FC<BannerHomeUploadProps> = ({
  size,
  banner,
  campaignId,
  uploadCampaignBanner,
  loadCampaign,
  showToastr,
}) => {
  const classes = useStyle();

  const [thumbnail, setThumbnail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleThumbnail = (file?: any) => {
    const isTypeValid = acceptTypes.includes(file?.name.split('.')[1]);
    if (isTypeValid) {
      const reader = new FileReader() as any;

      reader.onloadend = () => {
        setThumbnail(reader.result);
      };

      reader.readAsDataURL(file);
      setThumbnail('');
    }
  };

  const handleUploadBanner = async (file: any) => {
    setLoading(true);
    const formData = new FormData();
    const formated = Utils.getFormatedFile(file);
    formData.append('Banner', formated);
    formData.append('CampanhaId', campaignId.toString());
    formData.append('CampanhaBannerTipoId', size.toString());
    await Api.post(`/campanha/${campaignId}/banner/upload`, formData);
    loadCampaign(Number(campaignId));
    setLoading(false);
    showToastr({ type: 'success', message: AppMessages.SUCCESS_UPLOAD_BANNER, open: true });
  };

  return (
    <Card>
      <CardMedia
        image={thumbnail ? thumbnail : banner ? banner.url : PLACEHOLDER_IMAGE}
        title={getBannerSizeDescription(size)}
        className={classes.media}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {`Banner - ${getBannerSizeDescription(size)}`}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {getBannerSizeSugestion(size)}
        </Typography>
      </CardContent>
      <CardActions disableSpacing={true}>
        {loading ? (
          <CircularProgress color="secondary" size={30} />
        ) : (
          <FileUpload
            onUpload={handleUploadBanner}
            onSetThumbnail={handleThumbnail}
            acceptType={['png', 'jpg', 'jpeg', 'gif']}
            compact={true}
            fullWidth={true}
          />
        )}
      </CardActions>
    </Card>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...CampaignEditActions, ...ToastrActions }, dispatch);

export default connect(null, mapDispatchToProps)(BannerHomeUpload);
