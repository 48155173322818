import { loadBenefit as loadCampaignRefresh, resetBenefit } from './actions';
import { AppMessages } from 'common/AppMessages.enum';
import { showToastr } from 'store/toastr/actions';
import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import {
  loadBenefit as loadBenefitRequest,
  loadBenefitSuccess,
  createBenefitSuccess,
  createBenefitFailure,
  loadCampaignFiltersSuccess,
  applyBenefitFilterSuccess,
  loadCampaignFiltersCompleted,
  uploadCampaignBannerSuccess,
  uploadCampaignBannerFailure,
  updateHasRegulationSuccess,
  updateRequiredRegulationSuccess,
  loadCampaignFilterAppliedParticipantCount,
  loadTargetPublicOptionsSuccess,
  loadTargetPublicOptionsFailure,
} from './actions';

import { loadMailing } from 'store/mailing/actions';
import { push } from 'connected-react-router';
import Utils from 'common/Utils';
import { STRUCTURE_PULICTARGET, MODULE_TYPE } from 'common/Constants';

export function* loadBenefit(action) {
  try {
    const { data } = yield call(API.get, `/beneficio/${action.payload.id}`);
    yield put(resetBenefit());
    yield put(loadBenefitSuccess(data));
  } catch (error) {
    yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
    Utils.redirect(error);
  }
}

export function* createBenefit(action) {
  try {
    if (action.payload.id === '') {
      const { data } = yield call(API.post, `/beneficio/create`, action.payload);

      yield put(createBenefitSuccess({ ...data, ...action.payload }));
      yield put(
        showToastr({ message: AppMessages.SUCCESS_CREATE_BENEFIT, type: 'success', open: true }),
      );
      yield put(push(`/benefit/edit/${data.id}`));
    } else {
      const { data } = yield call(
        API.patch,
        `/beneficio/${action.payload.id}/update`,
        action.payload,
      );

      yield put(createBenefitSuccess({ ...data, ...action.payload }));
      yield put(
        showToastr({ message: AppMessages.SUCCESS_CREATE_BENEFIT, type: 'success', open: true }),
      );
      yield put(push(`/benefit/edit/${action.payload.id}`));
    }
  } catch (error) {
    yield put(createBenefitFailure());
    yield put(showToastr({ message: AppMessages.ERROR_CREATE_BENEFIT, type: 'error', open: true }));
  }
}

export function* applyBenefitFilter(action) {
  try {
    const { data } = yield call(
      API.post,
      `/beneficio/${action.payload.id}/filtro`,
      action.payload.filter,
    );
    yield put(applyBenefitFilterSuccess(data));
    yield put(
      showToastr({ message: AppMessages.SUCCESS_APPLYFILTER, type: 'success', open: true }),
    );
    yield put(push(`/benefit/edit/${action.payload.id}`));
    yield put(loadMailing(action.payload.id, MODULE_TYPE.CAMPAIGN));
    yield put(loadCampaignRefresh(action.payload.id));
  } catch (error) {
    yield put(createBenefitFailure());
    yield put(showToastr({ message: AppMessages.ERROR_APPLYFILTER, type: 'error', open: true }));
  }
}

export function* loadBenefitFilters(action) {
  try {
    const { data } = yield call(
      API.get,
      `/beneficio/${action.payload.campaignId}/filtro/${action.payload.moduleType}`,
    );

    yield put(loadCampaignFiltersSuccess(data));

    yield put(loadCampaignFiltersCompleted());

    if (action.payload.moduleType > 0) {
      let contagem;
      if (data.filtros && action.payload.moduleType === STRUCTURE_PULICTARGET.PDV)
        contagem = yield call(API.post, `/beneficio/contagem/filtro`, [
          ...data.filtros.atividades.map(({ id, name, selected, tipo, value }) => {
            return {
              id,
              nome: name,
              selected,
              tipo,
              value,
            };
          }),
        ]);

      if (data.filtros && action.payload.moduleType === STRUCTURE_PULICTARGET.SALES_FORCE)
        contagem = yield call(API.post, `/beneficio/contagem/filtro`, [
          ...data.filtros.perfis.map(({ id, name, selected, tipo, value }) => {
            return {
              id,
              nome: name,
              selected,
              tipo,
              value,
            };
          }),
        ]);

      // console.log('contagem 152 sagas.ts', contagem);

      yield put(loadCampaignFilterAppliedParticipantCount(contagem.data.total));
    }
  } catch (error) {
    console.log('erro catch faiilure campaign', error);
    yield put(createBenefitFailure());
    yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
  }
}

export function* uploadCampaignBanner(action) {
  try {
    const { data } = yield call(
      API.post,
      `/beneficio/${action.payload.id}/banner/upload`,
      action.payload.data,
    );
    yield put(uploadCampaignBannerSuccess(data));
    yield put(
      showToastr({ message: AppMessages.SUCCESS_UPLOAD_BANNER, type: 'success', open: true }),
    );
    yield put(loadBenefitRequest(action.payload.id));
  } catch (error) {
    yield put(uploadCampaignBannerFailure());
    yield put(showToastr({ message: AppMessages.ERROR_UPLOAD_BANNER, type: 'error', open: true }));
  }
}

export function* updateHasRegulation(action) {
  const campaignId = action.payload.campaignId;
  const value = action.payload.value;
  try {
    yield call(API.patch, `beneficio/${campaignId}/regulamento/${value}`);
    yield put(updateHasRegulationSuccess(value));
    yield put(
      showToastr({
        type: 'success',
        message: AppMessages.UPDATE_HAS_REGULATION_SUCCESS,
        open: true,
      }),
    );
  } catch (error) {
    yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
  }
}

export function* updateRequiredRegulation(action) {
  const campaignId = action.payload.campaignId;
  const value = action.payload.value;
  try {
    yield call(API.patch, `beneficio/${campaignId}/regulamento/aceite/${value}`);
    yield put(updateRequiredRegulationSuccess(value));
    yield put(
      showToastr({
        type: 'success',
        message: AppMessages.UPDATE_REQUIRED_REGULATION_SUCCESS,
        open: true,
      }),
    );
  } catch (error) {
    yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
  }
}

export function* loadBenefitTargetPublicOptions(action) {
  try {
    const { data } = yield call(API.get, `/beneficio/ElegivelFiltroTipos`);

    yield put(loadTargetPublicOptionsSuccess(data));
  } catch (error) {
    yield put(loadTargetPublicOptionsFailure());
    yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
  }
}
