import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import store from 'store';
import { showToastr } from 'store/toastr/actions';
import * as Yup from 'yup';
import Api from '../../../common/Api';
import { ILoginRequest } from '../../../store/login/index';
export interface IModalResetPasswordState {
  open: boolean;
  onConfirm: any;
}

interface ModalResetPassword {
  title: string;
  state: IModalResetPasswordState;
  setState: Dispatch<SetStateAction<IModalResetPasswordState>>;
  size?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
}

export interface LoginResetPassword {
  UserName: string;
}

interface ValidationErrors {
  [key: string]: string;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: theme.typography.fontFamily,
      height: '80vh',
    },
    label: {
      fontWeight: 'bolder',
      fontSize: '10px',
      marginBottom: theme.spacing(1),
      display: 'block',
    },
    spacingBottom: {
      marginBottom: theme.spacing(2),
    },
  }),
);

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um email válido!')
    .required('O email é obrigatório!'),
});

const ModalResetPassword: React.FC<ModalResetPassword> = ({ state, setState, title, size }) => {
  const classes = useStyle();

  const [loginResetData, setLoginResetData] = React.useState<ILoginRequest>({
    email: '',
    senha: '',
  });

  const [error, setError] = React.useState(false);
  const [helperText, setHelperText] = React.useState('');

  const [isButtonLoginDisabled, setIsButtonLoginDisabled] = useState(true);

  useEffect(() => {
    if (loginResetData.email.trim()) {
      setIsButtonLoginDisabled(false);
    } else {
      setIsButtonLoginDisabled(true);
    }
  }, [loginResetData]);

  const handleLoginResetChange = (prop: keyof ILoginRequest) => async (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    setLoginResetData({ ...loginResetData, [prop]: event.target.value });
    let data = loginResetData;
    data[prop as string] = event.target.value;
    await schema
      .validate(data, {
        abortEarly: false,
      })
      .then(function(value) {
        setError(false);
        setIsButtonLoginDisabled(false);
        setHelperText('');
      })
      .catch(function(err) {
        if (err.errors && err.errors.length > 0) {
          setHelperText(err.errors[0]);
          setError(true);
          setIsButtonLoginDisabled(true);
        }
      });
  };

  const handleOnCofirm = async () => {
    try {
      await schema.validate(loginResetData, {
        abortEarly: false,
      });
      const resetPassword = await Api.post('/auth/reenvio', loginResetData);

      if (resetPassword && resetPassword.data && resetPassword.data.success) {
        if (state.onConfirm) state.onConfirm();
        setState({ ...state, open: false, onConfirm: null });
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        let errors = '';
        err.errors.forEach((er: string, index: number) => {
          errors += er;
          if (err.errors.length > 1 && err.errors.length < index) {
            errors += ', ';
          }
        });
        store.dispatch(
          showToastr({
            message: errors,
            open: true,
            type: 'error',
          }),
        );
      }
    }
  };

  return (
    <Dialog className={classes.root} maxWidth={size || 'xs'} fullWidth={true} open={state.open}>
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent dividers={true}>
        <Typography component="h1" variant="h6">
          Digite seu email cadastrado para resetar a senha!
        </Typography>

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="e-mail"
          name="email"
          autoComplete="email"
          autoFocus
          onChange={handleLoginResetChange('email')}
        />
        <FormHelperText error={error}>{helperText}</FormHelperText>
      </DialogContent>

      <DialogActions>
        <Button
          color="secondary"
          onClick={() => setState({ ...state, open: false, onConfirm: null })}
        >
          Cancelar
        </Button>
        <Button color="primary" onClick={handleOnCofirm} disabled={isButtonLoginDisabled}>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalResetPassword;
