import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import BannerBenefitHomeUpload, {
  BannerBenefitType,
} from 'components/BannerHomeUpload/BannerBenefitHomeUpload';
import { connect } from 'react-redux';
import * as BenefitEditActions from 'store/benefit/edit/actions';
import { Dispatch, bindActionCreators } from 'redux';
import { ApplicationState } from 'store';
import { IBenefitBanner } from 'store/benefit/edit';

interface BannersProps {
  benefitBanners: IBenefitBanner[];
  benefitId: number;
}

const Banners: React.FC<BannersProps> = ({ benefitBanners, benefitId }) => {
  const [banners, setBanners] = useState(benefitBanners);
  React.useEffect(() => {
    setBanners(benefitBanners);
  }, [benefitBanners]);
  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} sm={3}>
        <BannerBenefitHomeUpload
          banner={banners.find(e => e.tipoId === BannerBenefitType.SUPERIOR)}
          size={BannerBenefitType.SUPERIOR}
          benefitId={benefitId}
        />
      </Grid>
      <Grid item={true} sm={3}>
        <BannerBenefitHomeUpload
          banner={banners.find(e => e.tipoId === BannerBenefitType.LOGO)}
          size={BannerBenefitType.LOGO}
          benefitId={benefitId}
        />
      </Grid>
      <Grid item={true} sm={3}>
        <BannerBenefitHomeUpload
          banner={banners.find(e => e.tipoId === BannerBenefitType.CARD)}
          size={BannerBenefitType.CARD}
          benefitId={benefitId}
        />
      </Grid>
      <Grid item={true} sm={3}>
        <BannerBenefitHomeUpload
          banner={banners.find(e => e.tipoId === BannerBenefitType.DETALHE)}
          size={BannerBenefitType.DETALHE}
          benefitId={benefitId}
        />
      </Grid>
      <Grid item={true} sm={3}>
        <BannerBenefitHomeUpload
          banner={banners.find(e => e.tipoId === BannerBenefitType.SUPERIOR_MOBILE)}
          size={BannerBenefitType.SUPERIOR_MOBILE}
          benefitId={benefitId}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  benefitId: state.benefit.data.id,
  benefitBanners: state.benefit.data.beneficioBanner,
  loading: state.benefit.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...BenefitEditActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Banners as any);
