import React from 'react';
import { Button } from '@material-ui/core';
import Api from 'common/Api';

export default function DownloadClientBaseCampaign() {
  const handleDownloadPdvs = () => {
    const downloadLink = document.createElement('a');
    downloadLink.href = '/modelo/ModeloBaseElegivelCampanha.csv';
    downloadLink.download = 'ModeloBaseElegivelCampanha.csv';
    document.body.appendChild(downloadLink);
    downloadLink.click();
  };

  return (
    <Button color="primary" onClick={handleDownloadPdvs}>
      Clique para fazer o download do modelo
    </Button>
  );
}
