import React from 'react';
import { Button } from '@material-ui/core';

const DownloadClientTrackImportExcel: React.FC<any> = ({ numeroFaixa }) => {
  const handleDownloadPdvs = () => {
    const downloadLink = document.createElement('a');
    let nomArquivo = '';
    switch (numeroFaixa) {
      case 2:
        nomArquivo = 'ModeloMarcasBonificadasFaixa2.csv';
        break;
      default:
        nomArquivo = 'ModeloMarcasBonificadasFaixa1e3.csv';
    }

    downloadLink.href = '/modelo/' + nomArquivo;
    downloadLink.download = nomArquivo;

    document.body.appendChild(downloadLink);
    downloadLink.click();
  };

  return (
    <Button color="primary" onClick={handleDownloadPdvs}>
      Clique para fazer o download do modelo
    </Button>
  );
};

export default DownloadClientTrackImportExcel;
