import React, { useState, useEffect, useCallback } from 'react';
import {
  TextField,
  Box,
  Paper,
  createStyles,
  makeStyles,
  Grid,
  Button,
  CircularProgress,
} from '@material-ui/core';
import cn from 'classnames';
import * as Yup from 'yup';
import Api from '../../../common/Api';
import store from 'store';
import { showToastr } from 'store/toastr/actions';
import CheckBoxList from 'components/CheckBoxList/CheckBoxList';

const useStyle = makeStyles(theme =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    textCenter: {
      textAlign: 'center',
      display: 'block',
      width: '91%',
    },
    buttonActions: {
      padding: theme.spacing(1),
      minWidth: 120,
      textAlign: 'right',
      '& > button': {
        marginRight: 0,
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }),
);

const PerfilRegister = ({ match, history }) => {
  const classes = useStyle();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMenus, setLoadingMenus] = useState(false);
  const [loadingState, setLoadingState] = useState(false);

  const [state, setState] = useState({
    idPerfil: 0,
    nome: '',
    menus: [],
  });

  const schema = Yup.object().shape({
    nome: Yup.string().required('O Nome do Perfil é obrigatório!'),
    menus: Yup.array().min(1, 'É obrigatório definir ao menos um menu checado'),
  });

  async function validateForm() {
    try {
      await schema.validate(state, {
        abortEarly: false,
      });
      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        let errors = '';
        err.errors.forEach((er, index) => {
          errors += er;
          if (err.errors.length > 1 && err.errors.length < index) {
            errors += ', ';
          }
        });
        store.dispatch(
          showToastr({
            message: errors,
            open: true,
            type: 'error',
          }),
        );
      }
    }
  }

  const handleUpdate = async () => {
    let validate = await validateForm();

    if (validate) {
      setLoading(true);
      await Api.put(`/perfil/update`, state);
      history.push('/perfil');
      setLoading(false);
    }
  };

  const handleOnSave = async () => {
    let validate = await validateForm();

    if (validate) {
      setLoading(true);
      await Api.post(`/perfil/create`, state);
      history.push('/perfil');
      setLoading(false);
    }
  };

  const handleChange = prop => async event => {
    setState({ ...state, [prop]: event.target.value });
  };

  const handleChangeSelect = prop => {
    setState({
      ...state,
      menus: prop,
    });
  };

  const loadPerfil = useCallback(async () => {
    if (match.params.id !== undefined) {
      setLoadingState(true);
      const result = await Api.get('/perfil/' + match.params.id);
      if (result) {
        setLoadingState(false);
        setState(result?.data?.perfil);
      }
    }
  }, [match.params.id]);

  const loadMenus = useCallback(async () => {
    setLoadingMenus(true);
    const result = await Api.get('/perfil/menus');
    if (result) {
      setLoadingMenus(false);
      setItems(result?.data?.data);
    }
  }, []);

  useEffect(() => {
    loadPerfil();
    loadMenus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (
    <CircularProgress />
  ) : (
    <Paper>
      <Box p="1.5em">
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <TextField
              id="nome"
              required={true}
              disabled={state.idPerfil > 0}
              value={state.nome}
              onChange={handleChange('nome')}
              label="Nome do Perfil"
              variant="outlined"
              InputProps={{
                readOnly: state.idPerfil > 0,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <CheckBoxList
              loading={loadingMenus || loadingState}
              items={items}
              selectedItems={state.menus}
              onChange={handleChangeSelect}
            />
          </Grid>

          <Grid container={true}>
            <Grid item={true} xs={12} className={cn([classes.buttonActions])}>
              <Button
                onClick={state.idPerfil === 0 ? handleOnSave : handleUpdate}
                variant="contained"
                color="secondary"
              >
                {state.idPerfil === 0 ? 'Cadastrar' : 'Atualizar'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default PerfilRegister;
