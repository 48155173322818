import React from 'react';
import { Box, createStyles, makeStyles, Modal } from '@material-ui/core';
import GenericTable from 'components/GenericTable/GenericTable';
import { TABLE_STYLE } from 'common/Constants';
import * as XLSX from 'xlsx';
import { IDetailModalHook } from '../GenericImportTable';
import { GetApp } from '@material-ui/icons';

export interface IDetailListDataFile {
  numeroLinha: number;
  sap: number;
  descricaoErro: string;
}

interface DetailsProps {
  data: IDetailListDataFile[];
  loading: boolean;
  detailModal: IDetailModalHook;
  setDetailModal: React.Dispatch<React.SetStateAction<IDetailModalHook>>;
}

const useStyle = makeStyles(() =>
  createStyles({
    box: {
      position: 'absolute',
      top: '50%',
      overflowY: 'auto',
      left: '50%',
      height: '85%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      backgroundColor: '#ffffff',
      border: '2px solid #000',
      padding: '25px',
    },
  }),
);

const ModalDetails: React.FC<DetailsProps> = ({ data, loading, detailModal, setDetailModal }) => {
  const modal = useStyle();

  const DownloadExcel = () => {
    const workSheet = XLSX.utils.json_to_sheet(data);
    const workBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workBook, workSheet, 'erros');

    // Binary string
    XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });
    // Download
    XLSX.writeFile(workBook, `${detailModal.fileName}_listagem_erros.xlsx`);
  };

  return (
    <>
      <Modal
        open={detailModal.isOpen}
        onClose={() => setDetailModal({ isOpen: false, fileName: '' })}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={modal.box}>
          <GenericTable
            title="Listagem de linhas com erro"
            isLoading={loading}
            data={data}
            options={{
              paging: false,
              search: true,
              sorting: true,
            }}
            actions={[
              {
                icon: () => <GetApp />,
                tooltip: 'Exportar em Excel',
                onClick: DownloadExcel,
                isFreeAction: true,
              },
            ]}
            columns={[
              {
                title: 'Número da linha no Documento',
                field: 'numeroLinha',
                render: rowData => {
                  return `${rowData.numeroLinha}`;
                },
                cellStyle: { ...TABLE_STYLE },
                customSort: (a, b) => a.numeroLinha - b.numeroLinha,
                defaultSort: 'asc',
              },
              {
                title: 'SAP Referência',
                field: 'sap',
                render: rowData => {
                  return `${rowData.sap ? rowData.sap : 'SAP não encontrado'}`;
                },
                cellStyle: { ...TABLE_STYLE },
                sorting: false,
              },
              {
                title: 'Status',
                field: 'descricaoStatus',
                render: rowData => {
                  return `${rowData.descricaoStatus}`;
                },
                cellStyle: { ...TABLE_STYLE },
                sorting: false,
              },
              {
                title: 'Processado',
                field: 'erro',
                render: rowData => {
                  return `${!rowData.erro ? 'Sim' : 'Não'}`;
                },
                cellStyle: { ...TABLE_STYLE },
                sorting: false,
              },
              {
                title: 'Descrição do Erro',
                field: 'descricaoErro',
                render: rowData => {
                  return `${
                    rowData.descricaoErro
                      ? rowData.descricaoErro
                      : rowData.status === 3
                      ? 'Foi cancelado devido a sobreposição de uma outra importação'
                      : 'Sem erros'
                  }`;
                },
                cellStyle: { ...TABLE_STYLE },
                sorting: false,
              },
            ]}
          />
        </Box>
      </Modal>
    </>
  );
};

export default ModalDetails;
