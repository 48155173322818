import { action } from 'typesafe-actions';
import { TotalErrosAccesstageTypes, IRealtimeItem } from './';

export const loadRealtimeTotalErrosAccesstageRequest = () =>
  action(TotalErrosAccesstageTypes.LOAD_REQUEST);
export const loadRealtimeTotalErrosAccesstageSuccess = (
  data: IRealtimeItem[],
  atualizacao: string,
) => action(TotalErrosAccesstageTypes.LOAD_SUCCESS, { data, atualizacao });
export const loadRealtimeTotalErrosAccesstageFailure = () =>
  action(TotalErrosAccesstageTypes.LOAD_FAILURE);
