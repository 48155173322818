import React, { useEffect, useState, useRef } from 'react';
import {
  Grid,
  createStyles,
  makeStyles,
  Theme,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Box,
  OutlinedInput,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from 'store';
import * as ReentryConfigActions from 'store/reentryConfigStore/actions';
import {
  IPromoList,
  IFilterCreate,
  IFilterLoad,
  TipoInclusaoParticipante,
} from 'store/reentryConfigStore/index';
import GenericElegibles, {
  ISelectedFilters,
  initialFilters,
} from 'components/Elegibles/GenericElegibles';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      margin: '0 12px',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  }),
);

interface IReentryFilterPDV {
  allPromoRegistred: Array<IPromoList>;
  filterData: IFilterLoad[];
  filterSuccess: boolean;
  filterError: boolean;
  filterLoading: boolean;
  loadAllUploadHistoric: () => void;
  createFilterPDV: (data: IFilterCreate) => void;
  loadFilterPDV: (reingressoPromocaoIncentivoId: number) => void;
  loadFilterPDVReset: () => void;
}

const FilterPDV: React.FC<IReentryFilterPDV> = ({
  loadAllUploadHistoric,
  allPromoRegistred,
  filterData,
  filterLoading,
  filterSuccess,
  filterError,
  createFilterPDV,
  loadFilterPDV,
  loadFilterPDVReset,
}) => {
  const classes = useStyle();
  const [promocaoIncentivoId, setPromocaoIncentivoId] = useState<number>(0);
  const [limiteParcelas, setLimiteParcelas] = useState<number>(0);
  const [selectedFilters, setSelectedFilters] = useState<ISelectedFilters>(initialFilters);
  const [promoPDVList, setPromoPDVList] = useState<Array<any>>([]);
  const [labelWidth, setLabelWidth] = useState(0);
  const inputLabel = useRef<HTMLLabelElement>(null);

  useEffect(() => {
    setLabelWidth(inputLabel?.current?.offsetWidth || 0);
  }, [labelWidth]);

  useEffect(() => {
    loadAllUploadHistoric();
    loadFilterPDVReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (promocaoIncentivoId !== 0) loadFilterPDV(promocaoIncentivoId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promocaoIncentivoId]);

  useEffect(() => {
    setPromoPDVList(
      allPromoRegistred.filter(
        item =>
          item.tipoInclusaoParticipante === TipoInclusaoParticipante.PDV ||
          item.tipoInclusaoParticipante === 0,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filterSuccess) {
      let isActiveFilter = filterData.find(item => item.ativo);

      setLimiteParcelas(isActiveFilter!.limiteParcelasPendentes);

      setSelectedFilters({
        ufs: isActiveFilter!.ufs,
        clusters: isActiveFilter!.clusters,
        atividades: isActiveFilter!.atividades,
      });

      return;
    } else if (filterError) {
      setLimiteParcelas(0);
      setSelectedFilters(initialFilters);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSuccess, filterError]);

  const handleCreatePDV = (id, filter) => {
    let date = {
      reingressoPromocaoIncentivoId: id,
      limiteParcelasPendentes: limiteParcelas,
      ...filter,
    };
    createFilterPDV(date);
  };

  return (
    <Paper elevation={2}>
      <Box style={{ display: 'flex', padding: '1em 8px' }}>
        Selecione uma promoção de incentivo para associar a um Filtro PDV.
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <FormControl variant="outlined" className={classes.formControl} fullWidth>
            <InputLabel id="promosDisponiveis" ref={inputLabel} shrink>
              Promoções de Incentivo
            </InputLabel>
            <Select
              displayEmpty={!promoPDVList.length}
              disabled={!promoPDVList.length}
              renderValue={
                !promoPDVList.length ? () => 'Não há promoções associadas a filtro PDV.' : undefined
              }
              labelId="promosDisponiveis"
              id="promosDisponiveis"
              label="Promoções de Incentivo"
              input={<OutlinedInput notched labelWidth={labelWidth} />}
              onChange={(e: React.ChangeEvent<{ value: any }>) =>
                setPromocaoIncentivoId(parseInt(e.target.value))
              }
            >
              {!!promoPDVList.length &&
                promoPDVList.map(promo => {
                  return (
                    <MenuItem key={promo.id} value={promo.id}>
                      {promo.descricao}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>

        {promocaoIncentivoId !== 0 && !filterLoading && (
          <Grid item xs={3}>
            <FormControl variant="outlined" className={classes.formControl} fullWidth>
              <TextField
                required
                error={limiteParcelas === 0 || isNaN(limiteParcelas)}
                value={limiteParcelas > 0 ? limiteParcelas : ''}
                InputLabelProps={{ shrink: limiteParcelas > 0 ?? false }}
                onChange={(e: React.ChangeEvent<{ value: any }>) => {
                  if (e.target.value.length <= 2) {
                    setLimiteParcelas(parseInt(e.target.value));
                  }
                }}
                label={
                  <>Limite {filterError ? '' : <strong>Atual</strong>} de Mensalidades Pendentes</>
                }
                variant="outlined"
                type="number"
              />
            </FormControl>
          </Grid>
        )}

        {promocaoIncentivoId !== 0 && (
          <Grid item xs={12} className={classes.grid}>
            <GenericElegibles
              disabled={limiteParcelas === 0 || isNaN(limiteParcelas)}
              selectedFilters={selectedFilters}
              applyFilterId={promocaoIncentivoId}
              loading={filterLoading}
              applyFilter={handleCreatePDV}
            />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  allPromoRegistred: state.reentryConfigStore.allPromotionRegistred.data,
  filterData: state.reentryConfigStore.loadFilter.data,
  filterLoading: state.reentryConfigStore.loadFilter.loading,
  filterSuccess: state.reentryConfigStore.loadFilter.success,
  filterError: state.reentryConfigStore.loadFilter.error,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(ReentryConfigActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FilterPDV);
