import { Reducer } from 'redux';

export enum KpiTypes {
  GENERIC_SUCCESS = '@Kpi/GENERIC_SUCCESS',
  GENERIC_FAILURE = '@Kpi/GENERIC_FAILURE',
  LOAD_REQUEST = '@Kpi/LOAD_REQUEST',
  LOAD_SUCCESS = '@Kpi/LOAD_SUCCESS',
  LOAD_FAILURE = '@Kpi/LOAD_FAILURE',
  CONFIGURED_KPI_REQUEST = '@Kpi/LOAD_CONFIGURED_KPI',
  CONFIGURED_KPI_SUCCESS = '@Kpi/LOAD_CONFIGURED_KPI_SUCCESS',
  CONFIGURED_KPI_FAILURE = '@Kpi/CONFIGURED_KPI_FAILURE',
  SAVE_KPI_REQUEST = '@Kpi/SAVE_KPI_REQUEST',
  DELETE_KPI_REQUEST = '@Kpi/DELETE_KPI_REQUEST',
  UPLOAD_KPI_FILE_REQUEST = '@Kpi/UPLOAD_KPI_FILE_REQUEST',
  UPLOAD_KPI_FILE_CAMPAIGN_REGULAR_REQUEST = '@Kpi/UPLOAD_KPI_FILE_CAMPAIGN_REGULAR_REQUEST',
  UPLOAD_KPI_FILE_SELLIN_REQUEST = '@Kpi/UPLOAD_KPI_FILE_SELLIN_REQUEST',
  UPLOAD_KPI_FILE_SELLIN_VEO_REQUEST = '@Kpi/UPLOAD_KPI_FILE_SELLIN_VEO_REQUEST',
  UPLOAD_KPI_FILE_VEO_REQUEST = '@Kpi/UPLOAD_KPI_FILE_VEO_REQUEST',
  UPLOAD_KPI_FILE_SUCCESS = '@Kpi/UPLOAD_KPI_FILE_SUCCESS',
  UPLOAD_KPI_FILE_FAILURE = '@Kpi/UPLOAD_KPI_FILE_FAILURE',
  KPI_FILE_PROCESSED_REQUEST = '@Kpi/KPI_FILE_PROCESSED_REQUEST',
  KPI_FILE_PROCESSED_SUCCESS = '@Kpi/KPI_FILE_PROCESSED_SUCCESS',
  KPI_FILE_PROCESSED_FAILURE = '@Kpi/KPI_FILE_PROCESSED_FAILURE',
  FDV_PROFILES_REQUEST = '@Kpi/FDV_PROFILES_REQUEST',
  FDV_PROFILES_SUCCESS = '@Kpi/FDV_PROFILES_SUCCESS',
  RESET_CONFIGURED_KPI = '@Kpi/RESET_CONFIGURED_KPI',
  RESET_KPI_FILE = '@Kpi/RESET_KPI_FILE',
}

export interface IKpi {
  list: any[];
  selected: any[];
}

export interface IKpiState {
  readonly data: any;
  readonly configuredKpi: IConfiguredKPI;
  readonly loading: boolean;
  readonly error: boolean;
  readonly file: IKpiFile;
  readonly fdvProfiles: IFdvProfile[];
}

export interface IConfiguredKPI {
  id: number;
  campanhaId: number;
  periodo: number;
  positivacaoId: number;
  positivacaoFaturamentoMinimo: number;
  canalOnline: boolean;
  canalOffline: boolean;
  file: IKpiFile;
  segmentos: IKpiSegmentos[];
  faixas: IKpiFaixas[];
  atividades: IKpiSegmentos[];
  produto: IKpiFaixas[];
  tipo: number;
}

export interface IKpiFile {
  id: number;
  campanhaId: number;
  usuario: string;
  downloadUrl: string;
  processado: boolean;
  sucesso: boolean;
  ativo: boolean;
  dataInclusao: string;
  erro: string;
}

export interface IKpiFaixas {
  perfilId: number;
  atingirDe: number;
  atingirAte: number;
  ean: string;
  segmentos: IKpiSegmentos[];
}

export interface IKpiSegmentos {
  id: number;
  nome: string;
  clusters: IKpiCluster[];
}

export interface IKpiCluster {
  clusterId: number;
  clusterName: string;
  pontos: number;
  valor: number;
  unidades: number;
}

export interface IFdvProfile {
  id: number;
  nome: string;
  perfilPaiId: number;
}

const INITIAL_STATE: IKpiState = {
  data: [],
  configuredKpi: {
    id: 0,
    campanhaId: 0,
    periodo: 0,
    canalOnline: false,
    canalOffline: false,
    positivacaoId: 0,
    positivacaoFaturamentoMinimo: 0,
    file: {
      id: 0,
      campanhaId: 0,
      usuario: '',
      downloadUrl: '',
      processado: false,
      sucesso: false,
      ativo: false,
      dataInclusao: '',
      erro: '',
    },
    faixas: [],
    segmentos: [],
    atividades: [],
    produto: [],
    tipo: 0,
  },
  loading: false,
  error: false,
  file: {
    id: 0,
    campanhaId: 0,
    usuario: '',
    downloadUrl: '',
    processado: false,
    sucesso: false,
    ativo: false,
    dataInclusao: '',
    erro: '',
  },
  fdvProfiles: [],
};

const reducer: Reducer<IKpiState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case KpiTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case KpiTypes.LOAD_SUCCESS:
      return { ...state, loading: false, error: false, data: action.payload.data };
    case KpiTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true, data: { list: [], selected: [] } };
    case KpiTypes.CONFIGURED_KPI_REQUEST:
      return { ...state, loading: true };
    case KpiTypes.CONFIGURED_KPI_SUCCESS:
      return {
        ...state,
        loading: false,
        configuredKpi: {
          ...action.payload.data,
        },
        file: action.payload.data.file,
      };
    case KpiTypes.CONFIGURED_KPI_FAILURE:
      return { ...state, loading: false, configuredKpi: { ...state.configuredKpi } };
    case KpiTypes.SAVE_KPI_REQUEST:
      return { ...state, loading: true };
    case KpiTypes.GENERIC_SUCCESS:
      return { ...state, loading: false };
    case KpiTypes.GENERIC_FAILURE:
      return { ...state, loading: false };
    case KpiTypes.DELETE_KPI_REQUEST:
      return { ...state, loading: true };
    case KpiTypes.UPLOAD_KPI_FILE_REQUEST:
      return { ...state, loading: true };
    case KpiTypes.UPLOAD_KPI_FILE_CAMPAIGN_REGULAR_REQUEST:
      return { ...state, loading: true };
    case KpiTypes.UPLOAD_KPI_FILE_SELLIN_REQUEST:
      return { ...state, loading: true };
    case KpiTypes.UPLOAD_KPI_FILE_SELLIN_VEO_REQUEST:
      return { ...state, loading: true };
    case KpiTypes.UPLOAD_KPI_FILE_VEO_REQUEST:
      return { ...state, loading: true };
    case KpiTypes.UPLOAD_KPI_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        file: action.payload.data,
      };
    case KpiTypes.UPLOAD_KPI_FILE_FAILURE:
      return {
        ...state,
        loading: false,
        file: { ...state.file },
      };
    case KpiTypes.KPI_FILE_PROCESSED_REQUEST:
      return { ...state, loading: true };
    case KpiTypes.KPI_FILE_PROCESSED_SUCCESS:
      return {
        ...state,
        loading: false,
        file: {
          ...state.file,
          processado: action.payload.data.processado,
          sucesso: action.payload.data.sucesso,
        },
      };
    case KpiTypes.KPI_FILE_PROCESSED_FAILURE:
      return {
        ...state,
        loading: false,
        file: { ...state.file },
      };
    case KpiTypes.FDV_PROFILES_REQUEST:
      return { ...state, loading: true };
    case KpiTypes.FDV_PROFILES_SUCCESS:
      return { ...state, loading: false, fdvProfiles: action.payload.data };
    case KpiTypes.RESET_CONFIGURED_KPI:
      return { ...state, loading: false, configuredKpi: { ...INITIAL_STATE.configuredKpi } };
    case KpiTypes.RESET_KPI_FILE:
      return { ...state, loading: false, file: INITIAL_STATE.file };
    default:
      return state;
  }
};

export default reducer;
