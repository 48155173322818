import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import * as MigrationOfPointsActions from './actions';

export function* loadStatusRequest() {
  try {
    const { data } = yield call(API.get, 'SolicitacaoPontos/status');

    yield put(MigrationOfPointsActions.loadStatusSuccess(data));
  } catch (error) {
    yield put(MigrationOfPointsActions.loadStatusFailure());
  }
}

export function* loadPendingMigrationsRequest(action) {
  try {
    const { data } = yield call(API.post, 'SolicitacaoPontos/list', action.payload.data);

    yield put(MigrationOfPointsActions.loadPendingMigrationsSuccess(data));
  } catch (error) {
    yield put(MigrationOfPointsActions.loadPendingMigrationsFailure());
  }
}

export function* loadPendingMigrationDetailRequest(action) {
  try {
    const { data } = yield call(
      API.get,
      `SolicitacaoPontos/detalhes/${action.payload.idSolicitacao}`,
    );

    yield put(MigrationOfPointsActions.loadPendingMigrationDetailSuccess(data));
  } catch (error) {
    yield put(MigrationOfPointsActions.loadPendingMigrationDetailFailure());
  }
}

export function* loadPendingMigrationCsvRequest(action) {
  try {
    const { data } = yield call(
      API.post,
      `SolicitacaoPontos/MigracaoPontosCsv?idSolicitacao=${action.payload.idSolicitacao}`,
    );

    yield put(MigrationOfPointsActions.loadPendingMigrationCsvSuccess(data));
  } catch (error) {
    yield put(MigrationOfPointsActions.loadPendingMigrationCsvFailure());
  }
}

export function* moderateMigrationRequest(action) {
  try {
    const { data } = yield call(API.post, `SolicitacaoPontos/Moderar`, action.payload.data);

    yield put(MigrationOfPointsActions.moderateMigrationSuccess(data));
  } catch (error) {
    yield put(MigrationOfPointsActions.moderateMigrationFailure());
  }
}

export function* loadMigrationReportRequest(action) {
  try {
    const { data } = yield call(API.post, 'SolicitacaoPontos/csv', action.payload.data);

    yield put(MigrationOfPointsActions.loadMigrationReportSuccess(data));
  } catch (error) {
    yield put(MigrationOfPointsActions.loadMigrationReportFailure());
  }
}
