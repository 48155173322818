import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import FileUpload from 'components/FileUpload/FileUpload';
import * as PlanManagementActions from 'store/planManagement/actions';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import { Dispatch, bindActionCreators } from 'redux';
import PlanAlterationLotFile from './uploadFile/PlanAlterationLotFile';
import ModalConfirmation, {
  IModalConfirmationState,
} from 'components/ModalConfirmation/ModalConfirmation';
import Api from '../../../../common/Api';
import DownloadClientBaseBeneficioCSV from 'components/DownloadClientBase/DownloadClientBaseBenefitCSV';
import ModalDetails from './modalDetails/modalDetails';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  }),
);

interface UploadProps {
  loadAllPlanAvaliable(): void;
  loadImportationPlanbyLot(): void;
  planList;
}

interface IPlanParams {
  id?: number | null;
  valor?: number | null;
  descricao?: string | undefined;
}

const PlanAlterationLot: React.FC<UploadProps & IPlanParams> = ({
  loadAllPlanAvaliable,
  loadImportationPlanbyLot,
  planList,
}) => {
  const classes = useStyle();

  const [allActivePlan, setAllActivePlan] = useState<IPlanParams[]>([]);
  const [handlePlan, setHandlePlan] = useState<IPlanParams>({});
  const [loadingChangePlan, setLoadingChangePlan] = useState(false);
  const [modalConfirmation, setModalConfirmation] = useState<IModalConfirmationState>({
    open: false,
    onConfirm: null,
  });
  const [openModalDetail, setOpenModalDetail] = useState(false);

  useEffect(() => {
    loadAllPlanAvaliable();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setAllActivePlan(planList.filter(item => item.ativo === true));
    // eslint-disable-next-line
  }, [planList]);

  const handleUpload = (file: any) => {
    const data = new FormData();
    data.append('file', file);
    changePlanByLot(handlePlan.id, data);
  };

  const changePlanByLot = async (planId, file: FormData) => {
    setLoadingChangePlan(true);
    const response = await Api.post(
      `/planos/upload-plano-lote?planoMensalidadePrimeId=${planId}`,
      file,
    );

    if (response && response.status === 200) {
      loadImportationPlanbyLot();
    }

    setLoadingChangePlan(false);
  };

  const handleChangePlan = event => {
    const handlePlan = allActivePlan.find(plan => {
      return event.target.value === plan.id;
    });
    setHandlePlan({ ...handlePlan });
  };

  return (
    <>
      <Box>
        <Box fontSize="h5.fontSize" mt="1em">
          Definir seleção customizada:
        </Box>
        <Box fontSize={14} mb="1em">
          <strong>Importante!</strong> Para realizar a seleção customizada faça o upload de um
          arquivo CSV contendo a base de CodigoSap elegíveis.
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={3} style={{ padding: '3px' }}>
            <FormControl variant="outlined" className={classes.formControl} fullWidth>
              <InputLabel id="planoPlanosDisponiveis">Planos disponíveis</InputLabel>
              <Select
                labelId="planoPlanosDisponiveis"
                id="planoPlanosDisponiveis"
                value={handlePlan.descricao}
                label="Plano do Participante"
                onChange={handleChangePlan}
              >
                {!!allActivePlan.length &&
                  allActivePlan.map((plan: any) => {
                    return (
                      <MenuItem key={plan.id} value={plan.id}>
                        {plan.descricao} / Valor: R$ {plan.valor}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FileUpload
              callback={file => {
                setModalConfirmation({ open: true, onConfirm: () => handleUpload(file) });
              }}
              acceptType={['csv']}
              disabled={!handlePlan?.id || loadingChangePlan}
              withConfirmation={true}
            />
          </Grid>
        </Grid>

        <Box mt="1em">
          <DownloadClientBaseBeneficioCSV />
        </Box>

        <Box mt="1em">
          <PlanAlterationLotFile setOpen={setOpenModalDetail} />
        </Box>
      </Box>
      <ModalConfirmation
        title="Alteração de Plano em lote de Participantes"
        content={'Confirma a execução da alteração de plano em lote destes varejos?'}
        state={modalConfirmation}
        setState={setModalConfirmation}
      />
      <ModalDetails setOpen={setOpenModalDetail} open={openModalDetail} />
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  planList: state.planManagement.planAvaliableList.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(PlanManagementActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PlanAlterationLot);
