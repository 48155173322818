import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import {
  loadSku as loadSkuRequest,
  createSkuSuccess,
  updateSkuSuccess,
  loadSkuSuccess,
  resetSku,
  loadAllSkuListSuccess,
} from './actions';
import { showToastr } from 'store/toastr/actions';
import { AppMessages } from 'common/AppMessages.enum';

export function* loadSku(action) {
  try {
    const { data } = yield call(API.get, `campanhaEan/detalhe/${action.payload.id}`);
    // yield put(resetSku());
    yield put(loadSkuSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
  }
}

export function* loadAllSkuList() {
  try {
    const { data } = yield call(API.get, `/shared/eans`);
    yield put(loadAllSkuListSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
  }
}

export function* createSku(action) {
  try {
    const { data } = yield call(API.post, 'campanhaEan/create', action.payload.data);
    yield put(createSkuSuccess(data));
    yield put(loadSkuRequest(data.id));
    yield put(showToastr({ message: AppMessages.SUCCESS_CREATE_SKU, type: 'success', open: true }));
  } catch (error) {
    yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
  }
}

export function* updateSku(action) {
  try {
    const { data } = yield call(API.patch, 'campanhaEan/update', action.payload.data);
    yield put(updateSkuSuccess(data));
    yield put(loadSkuRequest(action.payload.data.campanhaId));
    yield put(showToastr({ message: AppMessages.UPDATE_CREATE_SKU, type: 'success', open: true }));
  } catch (error) {
    yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
  }
}
