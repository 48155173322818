import React, { useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import FileUpload from 'components/FileUpload/FileUpload';
import * as TrackImportActions from 'store/trackImport/actions';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import { Dispatch, bindActionCreators } from 'redux';
import TrackImportList from './importList/TrackImportList';
import ModalErrorDetails from './modalErrorDetails/modalErrorDetails';
import DownloadClientTrackImportExcel from 'components/DownloadClientBase/DownloadClientTrackImportExcel';

interface UploadReportProps {
  campanhaFaixaAtingimentoId?: any;
  numeroFaixa?: number;
  loadTrackImportFile(campanhaFaixaAtingimentoId: number): void;
  uploadTrackImportCsv(file: FormData, id: number, transferCampaign: boolean | undefined): void;
  trackTableData;
  loading: boolean;
}

const TrackImport: React.FC<UploadReportProps> = ({
  campanhaFaixaAtingimentoId,
  numeroFaixa,
  loadTrackImportFile,
  trackTableData,
  loading,
  uploadTrackImportCsv,
}) => {
  const [open, setOpen] = useState(false);
  const [transfereBonificacao, setTransfereBonificacao] = useState<boolean | undefined>(undefined);
  useEffect(() => {
    if (campanhaFaixaAtingimentoId > 0) loadTrackImportFile(campanhaFaixaAtingimentoId);
    // eslint-disable-next-line
  }, [campanhaFaixaAtingimentoId]);

  const modalProps = {
    open,
    setOpen,
  };

  const handleUpload = (file: any) => {
    const data = new FormData();
    data.append('file', file);

    if (numeroFaixa === 2)
      uploadTrackImportCsv(data, campanhaFaixaAtingimentoId, transfereBonificacao);
    else uploadTrackImportCsv(data, campanhaFaixaAtingimentoId, false);
  };

  return (
    <>
      <Box>
        <Box fontSize="h5.fontSize" mt="1em">
          Importação de arquivo para bonificação por UF
        </Box>

        <Box fontSize="h5.fontSize" mt="1em">
          <Alert severity="warning">
            <strong>Instruções para importação do arquivo!</strong>
            <ul>
              <li>1. Baixe o arquivo modelo no botão "Clique para fazer o download do modelo".</li>
              <li>
                2. Preencha o arquivo com UF, SKU1, SKU2 (Quando a faixa do upload for a de 100%) e
                Permite Seleção.
              </li>
              <li>
                3. Clique no campo para "Selecionar" para a seleção do seu arquivo. Fique atento ao
                formato obrigatório ".csv".
              </li>
              <li>4. Clique no botão "Enviar".</li>
              <li>
                5. Aguarde o processamento do seu arquivo. Assim que processado ele será finalizado.
                Se não houver erros, ele estará apto para utilização.
              </li>
            </ul>

            <strong>Observações</strong>
            <ul>
              <li>O upload será realizado para a "faixa atual" no momento do envio.</li>
              <li>
                O arquivo deve conter, obrigatoriamente, 27 UF's distintas. Ou seja, todos os
                estados deverão ser preenchidos no arquivo.
              </li>
              <li>Coluna "SKU2" deve ser preenchido apenas na faixa de atingimento 100%.</li>
              <li>
                Coluna "Permite Seleção" deve ser preenchido apenas na faixa de atingimento 100%.
              </li>
              <li>
                Coluna "Permite Seleção" deve ser configurada com o valor "Sim" ou "Não".
                <ul>
                  <li>
                    "Sim": Quando houver possibilidade de escolha entre "SKU1 + SKU1" ou "SKU1 +
                    SKU2".
                  </li>
                  <li>
                    "Não": Quando não houver possibilidade de escolha entre "SKU1 + SKU1" ou "SKU1 +
                    SKU2". Se essa opção for a escolhida, obrigatoriamente, a opção de prêmio será
                    EXCLUSIVAMENTE "SKU1 + SKU2".
                  </li>
                </ul>
              </li>
            </ul>
          </Alert>
        </Box>

        <Box mt="1em">
          {numeroFaixa === 2 && (
            <FormControl fullWidth>
              <FormLabel>Deseja realizar a transferência da bonificação?</FormLabel>
              <RadioGroup
                row
                aria-label="transfereBonificacao"
                name="transfereBonificacao"
                onChange={event => {
                  setTransfereBonificacao(event.target.value === '1' ? true : false);
                }}
              >
                <FormControlLabel value="1" control={<Radio />} label="Sim" />
                <FormControlLabel value="2" control={<Radio />} label="Não" />
              </RadioGroup>
            </FormControl>
          )}
          <FileUpload
            disabled={numeroFaixa === 2 && transfereBonificacao == undefined}
            onUpload={handleUpload}
            acceptType={['csv']}
          />
        </Box>

        <Box mt="1em">
          <DownloadClientTrackImportExcel numeroFaixa={numeroFaixa} />
        </Box>

        <Box mt="1em">
          <TrackImportList trackTableData={trackTableData} loading={loading} {...modalProps} />
        </Box>
      </Box>
      <ModalErrorDetails {...modalProps} />
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  trackTableData: state.storeTrackImportFile.data,
  loading: state.storeTrackImportFile.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(TrackImportActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TrackImport);
