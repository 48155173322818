import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import {
  loadRealtimeTotalErrosIntegracaoBoletosSuccess,
  loadRealtimeTotalErrosIntegracaoBoletosFailure,
} from './actions';

export function* loadRealtimeTotalErrosIntegracaoBoletosRequest() {
  try {
    const { data } = yield call(API.get, 'dashboard/realtime/total/erros/deliveryPay');
    yield put(loadRealtimeTotalErrosIntegracaoBoletosSuccess(data.itens, data.atualizacao));
  } catch (error) {
    yield put(loadRealtimeTotalErrosIntegracaoBoletosFailure());
  }
}
