import React, { Dispatch, SetStateAction, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import { Dispatch as ReduxDispatch } from 'redux';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
  Theme,
  Grid,
  FormControl,
  Divider,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {
  IStatusItem,
  IMigrationOfPointsItem,
  IMigrationPointsDetail,
  IMigrationPointsModeration,
  IMigrationOfPointsParams,
} from 'store/migrationOfPoints';
import * as MigrationOfPointsActions from 'store/migrationOfPoints/actions';

interface ModalApprovingChaining {
  loading: boolean;
  approved: boolean;
  title: string;
  state: boolean;
  setState: Dispatch<SetStateAction<boolean>>;
  size?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
  data: IMigrationOfPointsItem;
  loadMigrationParams: IMigrationOfPointsParams;
  statusList?: IStatusItem[];
  migrationDetail: IMigrationPointsDetail[];
  migrationCsv: string;
  loadPendingMigrationDetailRequest: (idSolicitacao: number) => void;
  loadPendingMigrationCsvRequest: (idSolicitacao: number) => void;
  loadPendingMigrationCsvReset: () => void;
  moderateMigrationRequest: (data: IMigrationPointsModeration) => void;
  loadPendingMigrationsRequest: (data: IMigrationOfPointsParams) => void;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: theme.typography.fontFamily,
      height: '100vh',
    },
    label: {
      fontWeight: 'bolder',
      fontSize: '10px',
      marginBottom: theme.spacing(1),
      display: 'block',
    },
    spacingBottom: {
      marginBottom: theme.spacing(2),
    },
  }),
);

const ModerationModal: React.FC<ModalApprovingChaining> = ({
  loading,
  approved,
  state,
  setState,
  title,
  size,
  data,
  loadMigrationParams,
  statusList,
  migrationDetail,
  migrationCsv,
  loadPendingMigrationDetailRequest,
  loadPendingMigrationCsvRequest,
  loadPendingMigrationCsvReset,
  moderateMigrationRequest,
  loadPendingMigrationsRequest,
}) => {
  const classes = useStyle();
  const [status, setStatus] = useState(0);
  const [stateStatusList, setStateStatusList] = useState<IStatusItem[]>([]);
  const [denyingReason, setDenyingReason] = useState('');
  const [approvalModal, setApprovalModal] = useState(false);
  const [denyingModal, setDenyingModal] = useState(false);

  useEffect(() => {
    if (statusList) setStateStatusList(statusList);
  }, [statusList]);

  useEffect(() => {
    loadPendingMigrationDetailRequest(data.id);
    setStatus(data.statusId);
  }, [data]);

  useEffect(() => {
    if (migrationCsv !== undefined && migrationCsv !== '') {
      var buffer = new ArrayBuffer(3);
      var dataView = new DataView(buffer);
      dataView.setUint8(0, 0xfe);
      dataView.setUint8(1, 0xbb);
      dataView.setUint8(2, 0xbf);

      var csvString = migrationCsv;
      var universalBOM = '\uFEFF';
      var a = window.document.createElement('a');
      a.setAttribute(
        'href',
        'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + csvString),
      );

      let date = new Date();
      let reportName = `${date.getMonth() + 1}${date.getFullYear()}_${
        data.userName
      }_migracaoPontos.csv`;
      a.setAttribute('download', reportName);
      window.document.body.appendChild(a);
      a.click();

      loadPendingMigrationCsvReset();
    }
  }, [migrationCsv]);

  useEffect(() => {
    setApprovalModal(false);
    setDenyingModal(false);
    setState(false);
    loadPendingMigrationsRequest(loadMigrationParams);
  }, [approved]);

  const handleDownloadModel = e => {
    e.preventDefault();

    loadPendingMigrationCsvRequest(data.id);
  };

  const handleConfirmation = () => {
    //Aprovado
    if (status === 2) setApprovalModal(true);
    else if (status === 3 || status === 4) setDenyingModal(true);
  };

  const handleApproval = approved => {
    let payload = {
      idSolicitacao: data.id,
      idStatus: status,
      motivo: approved ? '' : denyingReason,
    };

    if (data.id !== 0) {
      moderateMigrationRequest(payload);
      setApprovalModal(false);
      setDenyingModal(false);
    }
  };

  return (
    <Dialog className={classes.root} maxWidth={size || 'xs'} fullWidth={true} open={state}>
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent dividers={true}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormControl fullWidth={true}>
              <TextField
                disabled={true}
                value={data.userName}
                variant="outlined"
                label="Varejo matriz / Solicitante"
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth={true}>
              <TextField
                disabled={true}
                value={data.razaoSocial}
                variant="outlined"
                label="Nome / Razão social"
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth={true}>
              <TextField
                disabled={true}
                value={data.dataSolicitacaoStr}
                variant="outlined"
                label="Pedido efetuado em"
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth={true}>
              <TextField
                disabled={true}
                value={data.totalPontos}
                variant="outlined"
                label="Total de pontos"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Divider light />
          </Grid>
        </Grid>
        <br />
        {migrationDetail.length === 0
          ? 'Buscando detalhes...'
          : migrationDetail.map(detail => (
              <Grid container spacing={2} key={detail.idParticipante}>
                <Grid item xs={4}>
                  <FormControl fullWidth={true}>
                    <TextField
                      disabled={true}
                      value={detail.userName}
                      variant="outlined"
                      label="Varejo Associado"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth={true}>
                    <TextField
                      disabled={true}
                      value={detail.nome}
                      variant="outlined"
                      label="Nome / Razão social do varejo solicitante"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth={true}>
                    <TextField
                      disabled={true}
                      value={detail.pontosAtuais}
                      variant="outlined"
                      label="Saldo de pontos atual"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth={true}>
                    <TextField
                      error={detail.pontosAtuais < detail.pontos * -1 ? true : false}
                      disabled={true}
                      value={detail.pontos * -1}
                      variant="outlined"
                      label="Pontos a migrar"
                    />
                  </FormControl>
                </Grid>
              </Grid>
            ))}
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Divider light />
          </Grid>
          <Grid item xs={12}>
            <small>
              <a href="#" onClick={e => handleDownloadModel(e)}>
                Clique aqui
              </a>{' '}
              para baixar a planilha de pedido de migração de pontos e envie-a pelo{' '}
              <b>portal de serviços da LTM.</b>
            </small>
            <br />
            <small>
              A planilha contemplará o pedido de migração somente dos varejos que possuem o saldo
              solicitado
            </small>
            <br />
            <small>
              Modere o pedido como Aprovado, <b>somente</b> após a conclusão do processo de migração
              para dar visibilidade real ao varejo matriz
            </small>
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Divider light />
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth variant="outlined">
              <TextField
                name="statusPedido"
                id="statusPedido"
                select
                required
                value={status}
                label="Informe o status final desse pedido:"
                onChange={(e: React.ChangeEvent<{ value: any }>) =>
                  setStatus(parseInt(e.target.value))
                }
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
                disabled={data.statusId > 1 || loading ? true : false}
              >
                {stateStatusList.map(stat => (
                  <option key={stat.id} value={stat.id}>
                    {stat.nome}
                  </option>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={9}>
            {data.statusId > 2 ? (
              <Typography variant="h6">
                Motivo reprovação/cancelamento: <small>{data.motivo}</small>
              </Typography>
            ) : (
              ''
            )}
          </Grid>
          <Grid item xs={12}>
            {data.statusId > 1 ? (
              <Typography variant="h6">
                Registro moderado por:{' '}
                <small>
                  {data.moderadoPor} em {data.dataModeracao}
                </small>
              </Typography>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={() => setState(false)}>
          Cancelar
        </Button>
        <Button
          color="primary"
          onClick={() => handleConfirmation()}
          disabled={data.statusId > 1 || loading ? true : false}
        >
          {loading ? 'Requisitando...' : 'Salvar'}
        </Button>
      </DialogActions>
      <Dialog className={classes.root} maxWidth={'xs'} fullWidth={true} open={approvalModal}>
        <DialogTitle id="approvalConfirmation">Tem certeza?</DialogTitle>
        <DialogContent dividers={true}>
          <Typography variant="h6">
            Você tem certeza de que deseja APROVAR esse pedido? O varejo matriz verá que o seu
            pedido foi aprovado, porém o processo de Migração de Pontos necessita ser solicitado via
            Portal de Serviços da LTM.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setApprovalModal(false)}>
            Cancelar
          </Button>
          <Button color="primary" onClick={() => handleApproval(true)}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog className={classes.root} maxWidth={'xs'} fullWidth={true} open={denyingModal}>
        <DialogTitle id="approvalConfirmation">Tem certeza?</DialogTitle>
        <DialogContent dividers={true}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">
                Você tem certeza de que deseja REPROVAR/CANCELAR esse pedido? Essa ação não poderá
                ser revertida
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth={true}>
                <TextField
                  id="denying_reason"
                  label="Justifique o motivo"
                  multiline
                  rows={5}
                  onChange={e => setDenyingReason(e.target.value)}
                  value={denyingReason}
                  variant="outlined"
                  inputProps={{
                    maxLength: 500,
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setDenyingModal(false)}>
            Cancelar
          </Button>
          <Button
            color="primary"
            onClick={() => handleApproval(false)}
            disabled={denyingReason === '' ? true : false}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    statusList: state.migrationOfPoints.statusList,
    migrationDetail: state.migrationOfPoints.migrationDetail,
    approved: state.migrationOfPoints.approved,
    loading: state.migrationOfPoints.loading,
    error: state.migrationOfPoints.error,
    success: state.migrationOfPoints.success,
    migrationCsv: state.migrationOfPoints.migrationCsv,
  };
};

const mapDispatchToProps = (dispatch: ReduxDispatch) => {
  return {
    loadPendingMigrationDetailRequest: (idSolicitacao: number) => {
      dispatch(MigrationOfPointsActions.loadPendingMigrationDetailRequest(idSolicitacao));
    },
    loadPendingMigrationCsvRequest: (idSolicitacao: number) => {
      dispatch(MigrationOfPointsActions.loadPendingMigrationCsvRequest(idSolicitacao));
    },
    loadPendingMigrationCsvReset: () => {
      dispatch(MigrationOfPointsActions.loadPendingMigrationCsvReset());
    },
    moderateMigrationRequest: (data: IMigrationPointsModeration) => {
      dispatch(MigrationOfPointsActions.moderateMigrationRequest(data));
    },
    loadPendingMigrationsRequest: (data: IMigrationOfPointsParams) => {
      dispatch(MigrationOfPointsActions.loadPendingMigrationsRequest(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModerationModal);
