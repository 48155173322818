import { Reducer } from 'redux';

export enum TotalErrosIntegracaoBoletosTypes {
  LOAD_REQUEST = '@totalErrosIntegracaoBoletos/LOAD_REQUEST',
  LOAD_SUCCESS = '@totalErrosIntegracaoBoletos/LOAD_SUCCESS',
  LOAD_FAILURE = '@totalErrosIntegracaoBoletos/LOAD_FAILURE',
}

export interface IRealtimeItem {
  id: number;
  nome: string;
  total: number;
}

export interface ITotalErrosIntegracaoBoletosState {
  data: IRealtimeItem[];
  atualizacao: string | null;
  loading: boolean;
  error: boolean;
  success: boolean;
}

const INITIAL_STATE = {
  data: [],
  atualizacao: '',
  loading: false,
  error: false,
  success: false,
};

const reducer: Reducer<ITotalErrosIntegracaoBoletosState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TotalErrosIntegracaoBoletosTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case TotalErrosIntegracaoBoletosTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        data: action.payload.data,
        atualizacao: action.payload.atualizacao,
      };
    case TotalErrosIntegracaoBoletosTypes.LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        data: INITIAL_STATE.data,
      };
    default:
      return state;
  }
};

export default reducer;
