import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import {
  loadRealtimeTotalMalhaAtualizacoesSuccess,
  loadRealtimeTotalMalhaAtualizacoesFailure,
} from './actions';

export function* loadRealtimeTotalMalhaAtualizacoesRequest() {
  try {
    const { data } = yield call(API.get, 'dashboard/realtime/total/malha/atualizacoes');
    yield put(loadRealtimeTotalMalhaAtualizacoesSuccess(data.itens, data.atualizacao));
  } catch (error) {
    yield put(loadRealtimeTotalMalhaAtualizacoesFailure());
  }
}
