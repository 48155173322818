import { action } from 'typesafe-actions';
import { UploadConectaPayFileTypes } from '.';
import { MODULE_TYPE } from 'common/Constants';

export const loadUploadConectaPayFile = (id: number) =>
  action(UploadConectaPayFileTypes.LOAD_REQUEST, { id });
export const loadUploadFileSuccess = data =>
  action(UploadConectaPayFileTypes.LOAD_SUCCESS, { data });
export const loadUploadFileFailure = () => action(UploadConectaPayFileTypes.LOAD_FAILURE);

export const uploadConectaPayCsv = (
  uploadUrl: string,
  data: FormData,
  callbackAction: any,
  id: number,
  type: number,
) =>
  action(UploadConectaPayFileTypes.UPLOAD_REQUEST, { uploadUrl, data, callbackAction, id, type });
export const uploadConectaPayCsvSuccess = data =>
  action(UploadConectaPayFileTypes.UPLOAD_SUCCESS, { data });
export const uploadConectaPayCsvFailure = () => action(UploadConectaPayFileTypes.UPLOAD_FAILURE);

export const deleteUploadConectaPayCsv = (deleteUrl: string) =>
  action(UploadConectaPayFileTypes.DELETE_REQUEST, { deleteUrl });
export const deleteUploadConectaPayCsvSuccess = data =>
  action(UploadConectaPayFileTypes.DELETE_SUCCESS, { data });
export const deleteUploadConectaPayCsvFailure = () =>
  action(UploadConectaPayFileTypes.DELETE_FAILURE);
