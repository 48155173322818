import {
  Box,
  Button,
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  makeStyles,
  Paper,
  TextField,
  Theme,
} from '@material-ui/core';
import cn from 'classnames';
import { AppMessages } from 'common/AppMessages.enum';
import Utils from 'common/Utils';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from 'store';
import { IBenefitEdit, IGeneralData, IGeneralDataCreateRequest } from 'store/benefit/edit';
import * as BenefitEditActions from 'store/benefit/edit/actions';

interface BenefitEditProps {
  benefit: IBenefitEdit;
  loading: boolean;
  createBenefit: (data: IGeneralDataCreateRequest) => void;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    textCenter: {
      textAlign: 'center',
      display: 'block',
      width: '91%',
    },
    buttonActions: {
      padding: theme.spacing(1),
      minWidth: 120,
      textAlign: 'right',
      '& > button': {
        marginRight: 0,
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    label: {
      fontWeight: 'bolder',
      fontSize: '14px',
      marginBottom: theme.spacing(1),
      display: 'block',
    },
  }),
);

const GeneralData: React.FC<BenefitEditProps> = ({ benefit, loading, createBenefit }) => {
  const classes = useStyle();
  const [state, setState] = useState({ ...benefit });

  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setState({ ...benefit });
  }, [benefit]);

  const handleOnSave = async () => {
    const hasErrors = getErrorMessages();

    if (!hasErrors) {
      state.ordem = Number(state.ordem);
      createBenefit(state);
    }
  };

  const handleChange = (prop: keyof IGeneralData) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.type === 'checkbox') setState({ ...state, [prop]: event.target.checked });
    else setState({ ...state, [prop]: event.target.value });
  };

  const getErrorMessages = (): boolean => {
    const isDateValid =
      Utils.compareDate(state.vigenciaDe, state.vigenciaAte) &&
      state.vigenciaDe !== state.vigenciaAte;
    const isInputRequiredValid = Utils.hasInputError(
      [state.nome, state.vigenciaAte, state.vigenciaDe],
      true,
    );
    const isNameOutOfRange = state.nome.length < 5 || state.nome.length > 100;

    const isOrder = !state.ordem || state.ordem <= 0;

    setErrorMessage(
      isOrder
        ? 'O campo Ordem deve ser preenchido.'
        : isDateValid
        ? AppMessages.DATE_START_BIGGERTHAN_END
        : isInputRequiredValid
        ? AppMessages.FORM_FIELDS_REQUIRED
        : isNameOutOfRange
        ? 'Nome do benefício deve ter no mínimo 5 e no máximo 100 caracteres.'
        : '',
    );

    return [!isDateValid, !isInputRequiredValid, !isNameOutOfRange, !isOrder].some(e => !e);
  };

  return (
    <Paper>
      <Box p="1.5em">
        <form>
          <Box width={6 / 12}>
            <FormControl fullWidth={true} className={classes.formControl}>
              <TextField
                error={Utils.hasInputError(state.nome, true) && !!errorMessage}
                id="nome"
                required={true}
                value={state.nome || ''}
                onChange={handleChange('nome')}
                label="Nome do benefício"
                variant="outlined"
                inputProps={{
                  maxLength: 50,
                }}
              />
            </FormControl>
          </Box>
          <Box width={6 / 12}>
            <FormControl fullWidth={true} className={classes.formControl}>
              <TextField
                error={Utils.hasInputError(state.resumoCard, true) && !!errorMessage}
                id="resumoCard"
                required={true}
                value={state.resumoCard || ''}
                onChange={handleChange('resumoCard')}
                label="Resumo Card"
                variant="outlined"
                inputProps={{
                  maxLength: 75,
                }}
              />
            </FormControl>
          </Box>
          <Box width={6 / 12}>
            <FormControl fullWidth={true} className={classes.formControl}>
              <TextField
                id="urlRedirect"
                value={state.urlRedirect || ''}
                onChange={handleChange('urlRedirect')}
                label="Link"
                variant="outlined"
                inputProps={{
                  maxLength: 255,
                }}
              />
            </FormControl>
          </Box>
          <Grid container={true}>
            <Grid item={true} xs={2}>
              <FormControl fullWidth={true} className={classes.formControl}>
                <TextField
                  error={Utils.hasInputError(state.vigenciaDe, true) && !!errorMessage}
                  required={true}
                  type="date"
                  id="startDate"
                  value={state.vigenciaDe.substring(0, 10)}
                  onChange={handleChange('vigenciaDe')}
                  label="Vigência da Benefício"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item={true} xs={2}>
              <FormControl fullWidth={true} variant="outlined" className={classes.formControl}>
                <InputLabel className={classes.textCenter}>Até</InputLabel>
              </FormControl>
            </Grid>
            <Grid item={true} xs={2}>
              <FormControl fullWidth={true} variant="outlined" className={classes.formControl}>
                <TextField
                  error={Utils.hasInputError(state.vigenciaAte, true) && !!errorMessage}
                  required={true}
                  type="date"
                  id="endDate"
                  value={state.vigenciaAte.substring(0, 10)}
                  onChange={handleChange('vigenciaAte')}
                  variant="outlined"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container={true}>
            <Grid item={true} xs={2}>
              <FormControl fullWidth={true} className={classes.formControl}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.ativo}
                      onChange={handleChange('ativo')}
                      name="ativo"
                      color="primary"
                    />
                  }
                  label="Ativo"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Box width={1 / 6}>
            <FormControl fullWidth={true} className={classes.formControl}>
              <TextField
                id="ordem"
                required={true}
                value={state.ordem || ''}
                onChange={handleChange('ordem')}
                label="Ordem Exibição"
                variant="outlined"
                type="number"
              />
            </FormControl>
          </Box>
          <Grid container={true}>
            <Grid item={true} xs={3} className={classes.formControl}>
              <Box fontSize={14} color="red">
                {errorMessage}
              </Box>
            </Grid>
            <Grid item={true} xs={3} className={cn([classes.buttonActions])}>
              <Button
                onClick={handleOnSave}
                disabled={loading}
                variant="contained"
                color="secondary"
              >
                {loading ? 'Aguarde...' : !benefit.id ? 'Cadastrar' : 'Atualizar'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Paper>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  benefit: state.benefit.data,
  loading: state.benefit.loading,
  error: state.benefit.error,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(BenefitEditActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GeneralData as any);
