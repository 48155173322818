import { Reducer } from 'redux';

export enum PaymentMethodsTypes {
  LOAD_PAYMENT_METHOD_REQUEST = '@paymentMethod/LOAD_PAYMENT_METHOD_REQUEST',
  LOAD_PAYMENT_METHOD_SUCCESS = '@paymentMethod/LOAD_PAYMENT_METHOD_SUCCESS',
  LOAD_PAYMENT_METHOD_FAILURE = '@paymentMethod/LOAD_PAYMENT_METHOD_FAILURE',
  GENERATE_PAYMENT_METHOD_REPORT_REQUEST = '@paymentMethod/GENERATE_PAYMENT_METHOD_REPORT_REQUEST',
  GENERATE_PAYMENT_METHOD_REPORT_SUCCESS = '@paymentMethod/GENERATE_PAYMENT_METHOD_REPORT_SUCCESS',
  GENERATE_PAYMENT_METHOD_REPORT_FAILURE = '@paymentMethod/GENERATE_PAYMENT_METHOD_REPORT_FAILURE',
  GENERATE_PAYMENT_METHOD_REPORT_RESET_PARAMS = '@paymentMethod/GENERATE_PAYMENT_METHOD_REPORT_RESET_PARAMS',

  LOAD_PAYMENT_RECEIVABLES_METHOD_REQUEST = '@paymentMethod/LOAD_PAYMENT_RECEIVABLES_METHOD_REQUEST',
  LOAD_PAYMENT_RECEIVABLES_METHOD_SUCCESS = '@paymentMethod/LOAD_PAYMENT_RECEIVABLES_METHOD_SUCCESS',
  LOAD_PAYMENT_RECEIVABLES_METHOD_FAILURE = '@paymentMethod/LOAD_PAYMENT_RECEIVABLES_METHOD_FAILURE',

  GENERATE_PAYMENT_RECEIVABLES_METHOD_REPORT_REQUEST = '@paymentMethod/GENERATE_PAYMENT_RECEIVABLES_METHOD_REPORT_REQUEST',
  GENERATE_PAYMENT_RECEIVABLES_METHOD_REPORT_SUCCESS = '@paymentMethod/GENERATE_PAYMENT_RECEIVABLES_METHOD_REPORT_SUCCESS',
  GENERATE_PAYMENT_RECEIVABLES_METHOD_REPORT_FAILURE = '@paymentMethod/GENERATE_PAYMENT_RECEIVABLES_METHOD_REPORT_FAILURE',
  GENERATE_PAYMENT_RECEIVABLES_METHOD_REPORT_RESET_PARAMS = '@paymentMethod/GENERATE_PAYMENT_RECEIVABLES_METHOD_REPORT_RESET_PARAMS',
}

export interface IPaymentMethodsParams {
  idTipoPgto: number;
  pagination: {
    limit: number;
    offset: number;
  };
}

export interface IPaymentMethodsItem {
  id: number;
  codigo: string;
  nome: string;
}

export interface IPaymentMethodState {
  readonly data: IPaymentMethodsItem[];
  readonly loading: boolean;
  readonly error: boolean;
  readonly success: boolean;
  readonly loadingReport: boolean;
  readonly report: string;
}

const INITIAL_STATE: IPaymentMethodState = {
  data: [],
  loading: false,
  error: false,
  success: false,
  loadingReport: false,
  report: '',
};

const reducer: Reducer<IPaymentMethodState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PaymentMethodsTypes.LOAD_PAYMENT_METHOD_REQUEST:
      return { ...state, loading: true };
    case PaymentMethodsTypes.LOAD_PAYMENT_METHOD_SUCCESS:
      return { ...state, loading: false, error: false, success: true, data: action.payload.data };
    case PaymentMethodsTypes.LOAD_PAYMENT_METHOD_FAILURE:
      return { ...state, loading: false, error: true, success: false, data: [] };
    case PaymentMethodsTypes.GENERATE_PAYMENT_METHOD_REPORT_REQUEST:
      return { ...state, loadingReport: true };
    case PaymentMethodsTypes.GENERATE_PAYMENT_METHOD_REPORT_SUCCESS:
      return { ...state, report: action.payload.data, success: true };
    case PaymentMethodsTypes.GENERATE_PAYMENT_METHOD_REPORT_FAILURE:
      return { ...state, loadingReport: false, error: true, success: false };
    case PaymentMethodsTypes.GENERATE_PAYMENT_METHOD_REPORT_RESET_PARAMS:
      return { ...state, loadingReport: false, report: '' };

    case PaymentMethodsTypes.LOAD_PAYMENT_RECEIVABLES_METHOD_REQUEST:
      return { ...state, loading: true };
    case PaymentMethodsTypes.LOAD_PAYMENT_RECEIVABLES_METHOD_SUCCESS:
      return { ...state, loading: false, error: false, success: true, data: action.payload.data };
    case PaymentMethodsTypes.LOAD_PAYMENT_RECEIVABLES_METHOD_FAILURE:
      return { ...state, loading: false, error: true, success: false, data: [] };

    case PaymentMethodsTypes.GENERATE_PAYMENT_RECEIVABLES_METHOD_REPORT_REQUEST:
      return { ...state, loadingReport: true };
    case PaymentMethodsTypes.GENERATE_PAYMENT_RECEIVABLES_METHOD_REPORT_SUCCESS:
      return { ...state, report: action.payload.data, success: true };
    case PaymentMethodsTypes.GENERATE_PAYMENT_RECEIVABLES_METHOD_REPORT_FAILURE:
      return { ...state, loadingReport: false, error: true, success: false };
    case PaymentMethodsTypes.GENERATE_PAYMENT_RECEIVABLES_METHOD_REPORT_RESET_PARAMS:
      return { ...state, loadingReport: false, report: '' };

    default:
      return state;
  }
};

export default reducer;
