import React, { useEffect, useState } from 'react';
import { Paper } from '@material-ui/core';
import { ApplicationState } from 'store';
import * as CampaignCountingActions from 'store/campaign/report/actions';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import DownloadClientBaseUploadVolume from 'components/DownloadClientBase/DownloadClientBaseUploadVolume';

import GenericImportTable, {
  ImportGenericTableData,
  IUploadVolume,
  IUploadVolumeDetail,
} from 'components/GenericImportTable/GenericImportTable';
interface UploadProps {
  loadVolume(): void;
  loadDetailsVolume(id: number): void;
  uploadVolumeByFile(file: FormData): void;
  uploadVolume: IUploadVolume;
  uploadVolumeDetail: IUploadVolumeDetail;
}

const UploadHome: React.FC<UploadProps> = ({
  loadVolume,
  loadDetailsVolume,
  uploadVolumeByFile,
  uploadVolume,
  uploadVolumeDetail,
}) => {
  const [dataGenericTable, setDataGenericTable] = useState<ImportGenericTableData>({
    title: 'Histórico de Upload de Volumes',
    responseTable: [],
    responseModal: [],
    loadingModal: true,
    loadingTable: true,
    disabledHandleUpload: false,
  });

  useEffect(() => {
    loadVolume();
  }, [loadVolume]);

  useEffect(() => {
    // Array da tabela de upload
    if (!uploadVolume.loading) {
      setDataGenericTable({
        ...dataGenericTable,
        responseTable: uploadVolume.data,
        loadingTable: uploadVolume.loading,
      });
    } else if (uploadVolume.loading) {
      setDataGenericTable({ ...dataGenericTable, loadingTable: uploadVolume.loading });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadVolume.data, uploadVolume.loading]);

  useEffect(() => {
    // Array da tabela de detalhamento do modal
    if (!uploadVolumeDetail.loading) {
      setDataGenericTable({
        ...dataGenericTable,
        responseModal: uploadVolumeDetail.data,
        loadingModal: uploadVolumeDetail.loading,
      });
    } else if (uploadVolumeDetail.loading) {
      // Troca de state loadingModal
      setDataGenericTable({ ...dataGenericTable, loadingModal: uploadVolumeDetail.loading });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadVolumeDetail.data, uploadVolumeDetail.loading]);

  const uploadByFile = file => {
    uploadVolumeByFile(file);
  };

  return (
    <Paper>
      <GenericImportTable
        props={dataGenericTable}
        exampleUploadFile={() => <DownloadClientBaseUploadVolume />}
        uploadApi={uploadByFile}
        detailModalApi={loadDetailsVolume}
      />
    </Paper>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  uploadVolume: state.campaignCouting.uploadVolume,
  uploadVolumeDetail: state.campaignCouting.uploadVolumeDetail,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(CampaignCountingActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UploadHome);
