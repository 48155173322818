import { Reducer } from 'redux';
import {
  ETipoPromocaoIncentivo,
  ETipoEmissaoBoletoInadimplente,
} from 'pages/Payments/IncentivePromotion/IncentivePromoConfig';

export enum ReentryConfigStoreTypes {
  LOAD_ALL_TYPES_PROMOTIONS_REQUEST = '@reentryConfigStore/LOAD_ALL_TYPES_PROMOTIONS_REQUEST',
  LOAD_ALL_TYPES_PROMOTIONS_SUCCESS = '@reentryConfigStore/LOAD_ALL_TYPES_PROMOTIONS_SUCCESS',
  LOAD_ALL_TYPES_PROMOTIONS_FAILURE = '@reentryConfigStore/LOAD_ALL_TYPES_PROMOTIONS_FAILURE',
  LOAD_ALL_UPLOAD_HISTORIC_REQUEST = '@reentryConfigStore/LOAD_ALL_UPLOAD_HISTORIC_REQUEST',
  LOAD_ALL_UPLOAD_HISTORIC_SUCCESS = '@reentryConfigStore/LOAD_ALL_UPLOAD_HISTORIC_SUCCESS',
  LOAD_ALL_UPLOAD_HISTORIC_FAILURE = '@reentryConfigStore/LOAD_ALL_UPLOAD_HISTORIC_FAILURE',
  LOAD_UPLOAD_DETAIL_REQUEST = '@reentryConfigStore/LOAD_UPLOAD_DETAIL_REQUEST',
  LOAD_UPLOAD_DETAIL_SUCCESS = '@reentryConfigStore/LOAD_UPLOAD_DETAIL_SUCCESS',
  LOAD_UPLOAD_DETAIL_FAILURE = '@reentryConfigStore/LOAD_UPLOAD_DETAIL_FAILURE',
  LOAD_PROMOTION_REGISTRED_REQUEST = '@reentryConfigStore/LOAD_PROMOTION_REGISTRED_REQUEST',
  LOAD_PROMOTION_REGISTRED_SUCCESS = '@reentryConfigStore/LOAD_PROMOTION_REGISTRED_SUCCESS',
  LOAD_PROMOTION_REGISTRED_FAILURE = '@reentryConfigStore/LOAD_PROMOTION_REGISTRED_FAILURE',
  LOAD_PROMOTION_BY_ID_REQUEST = '@reentryConfigStore/LOAD_PROMOTION_BY_ID_REQUEST',
  LOAD_PROMOTION_BY_ID_SUCCESS = '@reentryConfigStore/LOAD_PROMOTION_BY_ID_SUCCESS',
  LOAD_PROMOTION_BY_ID_FAILURE = '@reentryConfigStore/LOAD_PROMOTION_BY_ID_FAILURE',
  LOAD_CUT_OFF_DAY_REQUEST = '@reentryConfigStore/LOAD_CUT_OFF_DAY_REQUEST',
  LOAD_CUT_OFF_DAY_SUCCESS = '@reentryConfigStore/LOAD_CUT_OFF_DAY_SUCCESS',
  LOAD_CUT_OFF_DAY_FAILURE = '@reentryConfigStore/LOAD_CUT_OFF_DAY_FAILURE',
  CREATE_FILTER_PDV_REQUEST = '@reentryConfigStore/CREATE_FILTER_PDV_REQUEST',
  CREATE_FILTER_PDV_SUCCESS = '@reentryConfigStore/CREATE_FILTER_PDV_SUCCESS',
  CREATE_FILTER_PDV_FAILURE = '@reentryConfigStore/CREATE_FILTER_PDV_FAILURE',
  LOAD_FILTER_PDV_REQUEST = '@reentryConfigStore/LOAD_FILTER_PDV_REQUEST',
  LOAD_FILTER_PDV_SUCCESS = '@reentryConfigStore/LOAD_FILTER_PDV_SUCCESS',
  LOAD_FILTER_PDV_FAILURE = '@reentryConfigStore/LOAD_FILTER_PDV_FAILURE',
  LOAD_FILTER_PDV_RESET = '@reentryConfigStore/LOAD_FILTER_PDV_RESET',
}

export interface typePromoOptions {
  id: number;
  tipoPromocaoIncentivoId: number;
  descricao: string;
}
export interface IPromoConfig {
  id: number;
  codigo: string;
  descricao: string;
  trigger: boolean;
  tipoPromocaoIncentivoOpcoes: typePromoOptions[];
}

export enum TipoInclusaoParticipante {
  UPLOAD = 1,
  PDV = 2,
}
export interface IPromoList {
  id: number;
  descricao: string;
  quantidadeMesesExpiracao: number;
  ativo: boolean;
  tipoInclusaoParticipante: TipoInclusaoParticipante | 0;
}
export interface IPromoDetail {
  promocaoIncentivo: {
    tipoPromocaoIncentivo: ETipoPromocaoIncentivo | 0;
    tipoInclusaoParticipante: TipoInclusaoParticipante | 0;
    tipoEmissaoBoletoInadimplente?: ETipoEmissaoBoletoInadimplente;
    quantidadeMesesExpiracao: number;
    descricao: string;
    vigenciaFim: string;
    vigenciaInicio: string;
  };
  promocaoIncentivoConfiguracoes?: IPromoDetailConfig[];
}
export interface IPromoDetailConfig {
  id: number;
  descricao: string;
  valor: string;
  opcao: string;
  texto: string;
}
export interface ICuttOffDay {
  id: number;
  numeroDia: number;
  usuarioId: number;
  inicioVigencia: string;
  fimVigencia: string;
  dataInclusao: string;
}

export interface IImportByLotList {
  id: number;
  nomeArquivo: string;
  descricaoStatus: string;
}

export interface IDetailListDataFile {
  numeroLinha: number;
  sap: number;
  descricaoErro: string;
}

export interface IFilterCreate {
  reingressoPromocaoIncentivoId: number;
  limiteParcelasPendentes: number;
  ufs: number[] | [];
  clusters: number[] | [];
  atividades: number[] | [];
}
export interface IFilterLoad extends IFilterCreate {
  ativo: boolean;
}

export interface IReentryState {
  promotionsList: {
    data: IPromoConfig[];
    loading: boolean;
    error: boolean;
  };
  uploadHistoric: {
    data: IImportByLotList[];
    loading: boolean;
    error: boolean;
  };
  detailFileList: {
    data: IDetailListDataFile[];
    loading: boolean;
    error: boolean;
  };
  allPromotionRegistred: {
    data: IPromoList[];
    loading: boolean;
    error: boolean;
  };
  promoDetailById: {
    data: IPromoDetail;
    loading: boolean;
    error: boolean;
  };
  cuttOffDay: {
    data: ICuttOffDay[];
    loading: boolean;
    error: boolean;
  };
  loadFilter: {
    data: IFilterLoad[];
    loading: boolean;
    error: boolean;
    success: boolean;
  };
  createFilter: {
    data: any;
    loading: boolean;
    error: boolean;
  };
}

export const INITIAL_STATE: IReentryState = {
  promotionsList: {
    data: [],
    loading: false,
    error: false,
  },
  uploadHistoric: {
    data: [],
    loading: false,
    error: false,
  },
  detailFileList: {
    data: [],
    loading: false,
    error: false,
  },
  allPromotionRegistred: {
    data: [],
    loading: false,
    error: false,
  },
  promoDetailById: {
    data: {
      promocaoIncentivo: {
        tipoPromocaoIncentivo: 0,
        tipoInclusaoParticipante: 0,
        tipoEmissaoBoletoInadimplente: 0,
        quantidadeMesesExpiracao: 0,
        descricao: '',
        vigenciaFim: '',
        vigenciaInicio: '',
      },
      promocaoIncentivoConfiguracoes: [],
    },
    loading: false,
    error: false,
  },
  cuttOffDay: {
    data: [],
    loading: false,
    error: false,
  },
  createFilter: {
    data: {},
    loading: false,
    error: false,
  },
  loadFilter: {
    data: [],
    loading: false,
    success: false,
    error: false,
  },
};

const reducer: Reducer<IReentryState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ReentryConfigStoreTypes.LOAD_ALL_TYPES_PROMOTIONS_REQUEST:
      return {
        ...state,
        promotionsList: {
          ...state.promotionsList,
          loading: true,
        },
      };
    case ReentryConfigStoreTypes.LOAD_ALL_TYPES_PROMOTIONS_SUCCESS:
      return {
        ...state,
        promotionsList: {
          ...state.promotionsList,
          data: [...action.payload.data.data],
          loading: false,
          success: true,
        },
      };
    case ReentryConfigStoreTypes.LOAD_ALL_TYPES_PROMOTIONS_FAILURE:
      return {
        ...state,
        promotionsList: {
          ...state.promotionsList,
          loading: false,
          error: false,
        },
      };
    case ReentryConfigStoreTypes.LOAD_ALL_UPLOAD_HISTORIC_REQUEST:
      return {
        ...state,
        uploadHistoric: {
          ...state.uploadHistoric,
          loading: true,
        },
      };
    case ReentryConfigStoreTypes.LOAD_ALL_UPLOAD_HISTORIC_SUCCESS:
      return {
        ...state,
        uploadHistoric: {
          ...state.uploadHistoric,
          data: [...action.payload.data],
          loading: false,
          success: true,
        },
      };
    case ReentryConfigStoreTypes.LOAD_ALL_UPLOAD_HISTORIC_FAILURE:
      return {
        ...state,
        uploadHistoric: {
          ...state.uploadHistoric,
          loading: false,
          error: false,
        },
      };
    case ReentryConfigStoreTypes.LOAD_UPLOAD_DETAIL_REQUEST:
      return {
        ...state,
        detailFileList: {
          ...state.detailFileList,
          loading: true,
        },
      };
    case ReentryConfigStoreTypes.LOAD_UPLOAD_DETAIL_SUCCESS:
      return {
        ...state,
        detailFileList: {
          ...state.detailFileList,
          data: [...action.payload.data],
          loading: false,
          success: true,
        },
      };
    case ReentryConfigStoreTypes.LOAD_UPLOAD_DETAIL_FAILURE:
      return {
        ...state,
        detailFileList: {
          ...state.detailFileList,
          loading: false,
          error: false,
        },
      };
    case ReentryConfigStoreTypes.LOAD_PROMOTION_REGISTRED_REQUEST:
      return {
        ...state,
        allPromotionRegistred: {
          ...state.allPromotionRegistred,
          loading: true,
        },
      };
    case ReentryConfigStoreTypes.LOAD_PROMOTION_REGISTRED_SUCCESS:
      return {
        ...state,
        allPromotionRegistred: {
          ...state.allPromotionRegistred,
          data: [...action.payload.data.data],
          loading: false,
          success: true,
        },
      };
    case ReentryConfigStoreTypes.LOAD_PROMOTION_REGISTRED_FAILURE:
      return {
        ...state,
        allPromotionRegistred: {
          ...state.allPromotionRegistred,
          loading: false,
          error: false,
        },
      };
    case ReentryConfigStoreTypes.LOAD_PROMOTION_BY_ID_REQUEST:
      return {
        ...state,
        promoDetailById: {
          ...state.promoDetailById,
          loading: true,
        },
      };
    case ReentryConfigStoreTypes.LOAD_PROMOTION_BY_ID_SUCCESS:
      return {
        ...state,
        promoDetailById: {
          ...state.promoDetailById,
          data: action.payload.data.data,
          loading: false,
          success: true,
        },
      };
    case ReentryConfigStoreTypes.LOAD_PROMOTION_BY_ID_FAILURE:
      return {
        ...state,
        promoDetailById: {
          ...state.promoDetailById,
          loading: false,
          error: false,
        },
      };
    case ReentryConfigStoreTypes.LOAD_CUT_OFF_DAY_REQUEST:
      return {
        ...state,
        cuttOffDay: {
          ...state.cuttOffDay,
          loading: true,
        },
      };
    case ReentryConfigStoreTypes.LOAD_CUT_OFF_DAY_SUCCESS:
      return {
        ...state,
        cuttOffDay: {
          ...state.cuttOffDay,
          data: [...action.payload.data.data],
          loading: false,
          success: true,
        },
      };
    case ReentryConfigStoreTypes.LOAD_CUT_OFF_DAY_FAILURE:
      return {
        ...state,
        cuttOffDay: {
          ...state.cuttOffDay,
          loading: false,
          error: false,
        },
      };
    case ReentryConfigStoreTypes.CREATE_FILTER_PDV_REQUEST:
      return {
        ...state,
        createFilter: {
          ...state.createFilter,
          loading: true,
          success: false,
          error: false,
        },
      };
    case ReentryConfigStoreTypes.CREATE_FILTER_PDV_SUCCESS:
      return {
        ...state,
        createFilter: {
          ...state.createFilter,
          data: action.payload.data.id,
          loading: false,
          success: true,
        },
      };
    case ReentryConfigStoreTypes.CREATE_FILTER_PDV_FAILURE:
      return {
        ...state,
        createFilter: {
          ...state.createFilter,
          loading: false,
          error: true,
        },
      };
    case ReentryConfigStoreTypes.LOAD_FILTER_PDV_REQUEST:
      return {
        ...state,
        loadFilter: {
          ...state.loadFilter,
          loading: true,
          success: false,
          error: false,
        },
      };
    case ReentryConfigStoreTypes.LOAD_FILTER_PDV_SUCCESS:
      return {
        ...state,
        loadFilter: {
          ...state.loadFilter,
          data: action.payload.data.data,
          loading: false,
          success: true,
        },
      };
    case ReentryConfigStoreTypes.LOAD_FILTER_PDV_FAILURE:
      return {
        ...state,
        loadFilter: {
          ...state.loadFilter,
          data: INITIAL_STATE.loadFilter.data,
          loading: false,
          success: false,
          error: true,
        },
      };
    case ReentryConfigStoreTypes.LOAD_FILTER_PDV_RESET:
      return {
        ...state,
        loadFilter: {
          ...state.loadFilter,
          ...INITIAL_STATE.loadFilter,
        },
      };
    default:
      return state;
  }
};

export default reducer;
