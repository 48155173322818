import React, { useState } from 'react';
import {
  Grid,
  Paper,
  Box,
  makeStyles,
  Theme,
  createStyles,
  IconButton,
  Divider,
} from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: theme.typography.fontFamily,
      marginBottom: theme.spacing(2),
    },
    padding: {
      padding: theme.spacing(2),
    },
  }),
);

interface AccordionProps {
  title: any;
  customAction?: any;
  children: any;
}

const Accordion: React.FC<AccordionProps> = ({ title, children, customAction }) => {
  const classes = useStyle();
  const [open, setOpen] = useState(false);

  return (
    <Paper elevation={3} className={classes.root}>
      <Grid className={classes.padding} alignItems="center" container={true}>
        <Grid item={true} xs={11}>
          {title}
        </Grid>
        <Grid item={true} xs={1}>
          <Box textAlign="right">
            {customAction}
            <IconButton onClick={() => setOpen(!open)}>
              {open ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>
        </Grid>
      </Grid>

      {open && (
        <>
          <Divider />
          <Box className={classes.padding}>{children}</Box>
        </>
      )}
    </Paper>
  );
};

export default Accordion;
