import decode from 'jwt-decode';

export default class AuthHelper {
  public static isAutenticed(): boolean {
    const token = localStorage.getItem('accessToken');
    if (token !== null) {
      const decoded = decode(token);
      if (decoded && decoded.exp > Date.now() / 1000) {
        return true;
      }
    }
    localStorage.clear();
    return false;
  }
  public static getUserName(): string {
    const token = localStorage.getItem('accessToken');
    if (token !== null) {
      const decoded = decode(token);
      if (decoded && decoded.exp > Date.now() / 1000) {
        return decoded.Nome;
      }
    }
    return '';
  }
}
