import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import {
  loadAllUploadBankSlipSuccess,
  loadAllUploadBankSlipFailure,
  loadDetailUploadBankSlipSuccess,
  loadDetailUploadBankSlipFailure,
  uploadSingleBankSlipByFileSuccess,
  uploadSingleBankSlipByFileFailure,
} from './actions';

export function* loadAllUploadBankSlip() {
  try {
    const { data } = yield call(API.get, `/participante-pagamento-avulso/all`);
    yield put(loadAllUploadBankSlipSuccess(data));
  } catch (error) {
    yield put(loadAllUploadBankSlipFailure());
  }
}

export function* loadDetailUploadBankSlip(action) {
  try {
    const { data } = yield call(
      API.get,
      `/participante-pagamento-avulso/${action.payload.id}/linhas`,
    );
    yield put(loadDetailUploadBankSlipSuccess(data));
  } catch (error) {
    yield put(loadDetailUploadBankSlipFailure());
  }
}

export function* uploadSingleBankSlipByFile(action) {
  try {
    const { data } = yield call(
      API.post,
      `/participante-pagamento-avulso/upload`,
      action.payload.file,
    );
    yield put(uploadSingleBankSlipByFileSuccess(data));
  } catch (error) {
    yield put(uploadSingleBankSlipByFileFailure());
  }
}
