import React, { useState, useEffect, useRef } from 'react';
import Utils from 'common/Utils';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';

// See docs
// https://jpuri.github.io/react-draft-wysiwyg/#/docs

interface IEditorHTML {
  initialContent?: string;
  moreOptions?: Array<string>;
  overrideAllToobar?: Object;
  onBlur(event, editorState: EditorState): void; // Callback to handle change of 'editorState'  to string html
}

const defaultProps = {
  options: ['inline', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji'],
  fontSize: {
    options: [12, 14, 16, 18, 24, 30, 36],
  },
};

const EditorHtml: React.FC<IEditorHTML> = ({ overrideAllToobar, initialContent, onBlur }) => {
  const isFirstRender = useRef(true);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [settings, setSettings] = useState<Object>(defaultProps);

  useEffect(() => {
    if (overrideAllToobar) {
      setSettings({ ...settings, toolbar: overrideAllToobar });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overrideAllToobar]);

  useEffect(() => {
    if (initialContent && isFirstRender) {
      isFirstRender.current = false;
      return setEditorState(Utils.stringToEditorText(initialContent));
    }
  }, [initialContent]);

  return (
    <Editor
      toolbar={settings}
      editorState={editorState}
      onEditorStateChange={setEditorState}
      onBlur={onBlur}
    />
  );
};

export default EditorHtml;
