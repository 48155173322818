import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import { AppMessages } from 'common/AppMessages.enum';
import { showToastr } from 'store/toastr/actions';
import { push } from 'connected-react-router';
import Utils from 'common/Utils';

import {
  createUploadCampaignReportSuccess,
  createUploadCampaignReportFailure,
  loadCampaignReportSuccess,
} from './actions';
import { debug } from 'console';

export function* loadUploadCampaignReport(action) {
  try {
    const { data } = yield call(API.get, `/uploadCampanhaRelatorio/${action.payload.id}`);
    yield put(loadCampaignReportSuccess(data));
  } catch (error) {
    yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
    Utils.redirect(error);
  }
}

export function* createUploadCampaignReportRequest(action) {
  try {
    if (action.payload.id === '') {
      const { data } = yield call(API.post, `/uploadCampanhaRelatorio/create`, action.payload);

      yield put(createUploadCampaignReportSuccess({ ...data, ...action.payload }));
      yield put(
        showToastr({ message: AppMessages.SUCCESS_CREATE_CAMPAIGN, type: 'success', open: true }),
      );
      yield put(push(`/CampaignReport/edit/${data.data.id}`));
    } else {
      const { data } = yield call(
        API.patch,
        `/uploadCampanhaRelatorio/${action.payload.id}/editar`,
        action.payload,
      );

      yield put(createUploadCampaignReportSuccess({ ...data, ...action.payload }));
      yield put(
        showToastr({ message: AppMessages.SUCCESS_CREATE_CAMPAIGN, type: 'success', open: true }),
      );
      yield put(push(`/campaignReport/edit/${data.id}`));
    }
  } catch (error) {
    yield put(createUploadCampaignReportFailure());
    yield put(
      showToastr({ message: AppMessages.ERROR_CREATE_CAMPAIGN, type: 'error', open: true }),
    );
  }
}
