import React, { useState } from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  Card,
  CardHeader,
  Button,
  Box,
  CardMedia,
  CardActions,
  CircularProgress,
  CardContent,
  Typography,
} from '@material-ui/core';
import { PLACEHOLDER_IMAGE } from 'common/Constants';
import FileUpload from 'components/FileUpload/FileUpload';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import * as BenefitEditActions from 'store/benefit/edit/actions';
import * as ToastrActions from 'store/toastr/actions';
import { IToastrState } from 'store/toastr/index';
import Api from 'common/Api';
import Utils from 'common/Utils';
import { AppMessages } from 'common/AppMessages.enum';
import { IBenefitBanner } from 'store/benefit/edit';
import ModalBannerExample, { BannerExample } from './ModalBannerExample/ModalBannerExample';

export enum BannerBenefitType {
  SUPERIOR = 1,
  CARD = 2,
  LOGO = 3,
  DETALHE = 4,
  SUPERIOR_MOBILE = 5,
  DESKTOP = 6,
  MOBILE = 7,
}

export function getBannerSizeDescription(size: BannerBenefitType) {
  switch (size) {
    case BannerBenefitType.SUPERIOR:
      return 'Superior';
    case BannerBenefitType.CARD:
      return 'Card';
    case BannerBenefitType.LOGO:
      return 'Logo';
    case BannerBenefitType.DETALHE:
      return 'Imagem Detalhe';
    case BannerBenefitType.SUPERIOR_MOBILE:
      return 'Superior Mobile';
    case BannerBenefitType.DESKTOP:
      return 'Desktop';
    case BannerBenefitType.MOBILE:
      return 'Mobile';
    default:
      return '';
  }
}

export function getBannerSizeSugestion(size: BannerBenefitType) {
  switch (size) {
    case BannerBenefitType.SUPERIOR:
      return 'Sugestão de resolução: 1920px x 400px';
    case BannerBenefitType.CARD:
      return 'Sugestão de resolução: 280px x 460px';
    case BannerBenefitType.LOGO:
      return 'Sugestão de resolução: 1180px x 300px';
    case BannerBenefitType.DETALHE:
      return 'Sugestão de resolução: 330px x 243px';
    case BannerBenefitType.SUPERIOR_MOBILE:
      return 'Sugestão de resolução: 375px x 300px';
    case BannerBenefitType.DESKTOP:
      return 'Sugestão de resolução: 616px x 345px';
    case BannerBenefitType.MOBILE:
      return 'Sugestão de resolução: 400px x 396px';
    default:
      return '';
  }
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    spacing: {
      padding: theme.spacing(2),
    },
    media: {
      height: 0,
      paddingTop: '56.25%',
    },
  }),
);

const acceptTypes = ['png', 'jpg', 'jpeg', 'gif'];

interface BannerHomeUploadProps {
  size: BannerBenefitType;
  banner?: IBenefitBanner;
  benefitId: number;
  uploadCampaignBanner(data: FormData, id: number): void;
  loadBenefit(id: number): void;
  showToastr(options: IToastrState): void;
}

const BannerBenefitHomeUpload: React.FC<BannerHomeUploadProps> = ({
  size,
  banner,
  benefitId,
  uploadCampaignBanner,
  loadBenefit,
  showToastr,
}) => {
  const classes = useStyle();

  const [thumbnail, setThumbnail] = useState('');
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState({ open: false, url: '' });

  const handleThumbnail = (file?: any) => {
    const isTypeValid = acceptTypes.includes(file?.name.split('.')[1]);
    if (isTypeValid) {
      const reader = new FileReader() as any;

      reader.onloadend = () => {
        setThumbnail(reader.result);
      };

      reader.readAsDataURL(file);
      setThumbnail('');
    }
  };

  const handleUploadBanner = async (file: any) => {
    setLoading(true);
    const formData = new FormData();
    const formated = Utils.getFormatedFile(file);
    formData.append('Banner', formated);
    formData.append('BeneficioId', benefitId.toString());
    formData.append('BeneficioBannerTipoId', size.toString());
    await Api.post(`/beneficio/${benefitId}/banner/upload`, formData);
    loadBenefit(Number(benefitId));
    setLoading(false);
    showToastr({ type: 'success', message: AppMessages.SUCCESS_UPLOAD_BANNER, open: true });
  };

  const getBannerBannerExample = (size: BannerBenefitType) => {
    switch (size) {
      case BannerBenefitType.SUPERIOR:
        return setModalOpen({ open: true, url: BannerExample.SUPERIOR });
      case BannerBenefitType.SUPERIOR_MOBILE:
        return setModalOpen({ open: true, url: BannerExample.SUPERIOR_MOBILE });
      case BannerBenefitType.CARD:
        return setModalOpen({ open: true, url: BannerExample.CARD });
      case BannerBenefitType.LOGO:
        return setModalOpen({ open: true, url: BannerExample.LOGO });
      case BannerBenefitType.DETALHE:
        return setModalOpen({ open: true, url: BannerExample.DETALHE });
      default:
        return '';
    }
  };

  return (
    <Card>
      <CardMedia
        image={thumbnail ? thumbnail : banner ? banner.url : PLACEHOLDER_IMAGE}
        title={getBannerSizeDescription(size)}
        className={classes.media}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {`Banner - ${getBannerSizeDescription(size)}`}
        </Typography>
        <Box>
          <Typography variant="body2" color="textSecondary" component="span">
            {getBannerSizeSugestion(size)}
          </Typography>
          <Button
            variant="contained"
            component="label"
            size="small"
            style={{ marginLeft: 10 }}
            onClick={() => getBannerBannerExample(size)}
          >
            Ver exemplo
          </Button>
        </Box>
      </CardContent>
      <CardActions disableSpacing={true}>
        {loading ? (
          <CircularProgress color="secondary" size={30} />
        ) : (
          <FileUpload
            onUpload={handleUploadBanner}
            onSetThumbnail={handleThumbnail}
            acceptType={['png', 'jpg', 'jpeg', 'gif']}
            compact={true}
            fullWidth={true}
          />
        )}
      </CardActions>
      <ModalBannerExample modalOpen={modalOpen} setModalOpen={setModalOpen} />
    </Card>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...BenefitEditActions, ...ToastrActions }, dispatch);

export default connect(null, mapDispatchToProps)(BannerBenefitHomeUpload);
