import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import FileUpload from 'components/FileUpload/FileUpload';
import DownloadClientBase from 'components/DownloadClientBase/DownloadClientBase';
import * as BannerEditActions from 'store/banner/edit/actions';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import { Dispatch, bindActionCreators } from 'redux';
import { MODULE_TYPE, STRUCTURE_PULICTARGET } from 'common/Constants';
import ModalConfirmation, {
  IModalConfirmationState,
} from 'components/ModalConfirmation/ModalConfirmation';
import MailingListParticipantBanner from 'components/MailingList/MailingListParticipantBanner';
import DownloadClientBaseBeneficioCSV from 'components/DownloadClientBase/DownloadClientBaseBenefitCSV';

interface CostumizedSelectionPDVProps {
  bannerId?: number;
  uploadBannerParticipant(uploadUrl: string, data: FormData, id: number, type: number): void;
  loadParticipantsFileList(bannerId: number): void;
  filterApplied: number;
  participantUpload: any;
  participantData: any;
}

const CostumizedSelectionPDVBanner: React.FC<CostumizedSelectionPDVProps> = ({
  bannerId,
  uploadBannerParticipant,
  loadParticipantsFileList,
  filterApplied,
  participantUpload,
  participantData,
}) => {
  const [url] = useState(`/banner/${bannerId}`);

  const [modalConfirmation, setModalConfirmation] = useState<IModalConfirmationState>({
    open: false,
    onConfirm: null,
  });

  useEffect(() => {
    if (!participantUpload.loading_upload) loadParticipantsFileList(Number(bannerId));
  }, [participantUpload]);

  const handleUpload = (file: any) => {
    const data = new FormData();
    data.append('file', file);
    const uploadUrl = `${url}/mailing`;

    uploadBannerParticipant(uploadUrl, data, Number(bannerId), 1);
  };

  useEffect(() => {
    loadParticipantsFileList(Number(bannerId));
  }, [bannerId]);

  return (
    <>
      <Box>
        <Box fontSize="h5.fontSize" mt="1em">
          Definir seleção customizada:
        </Box>
        <Box fontSize={14} mb="1em">
          <strong>Importante!</strong> Para realizar a selação customizada faça o upload de um
          arquivo CSV contendo a base de código SAP elegiveis
        </Box>
        <FileUpload
          onUpload={handleUpload}
          acceptType={['csv']}
          loading={participantUpload.loading_upload}
        />
        <Box mt="1em">
          <DownloadClientBaseBeneficioCSV />
        </Box>
        <Box mt="1em">
          <MailingListParticipantBanner data={participantData} bannerId={bannerId} />
        </Box>
      </Box>
      <ModalConfirmation
        title="Aplicar Filtro"
        content={`Filtro já aplicado para ${
          filterApplied === STRUCTURE_PULICTARGET.PDV ? 'Cliente (PDV)' : 'Força de Vendas'
        }. Deseja mesmo aplicar para ${
          filterApplied === STRUCTURE_PULICTARGET.PDV ? 'Força de Vendas' : 'Cliente (PDV)'
        }?`}
        state={modalConfirmation}
        setState={setModalConfirmation}
      />
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    participantData: state.bannerEdit.participantData,
    participantUpload: state.bannerEdit.participantUpload,
    filterApplied: state.campaignEdit.filterApplied,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...BannerEditActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CostumizedSelectionPDVBanner);
