import React from 'react';
import { IMailing } from 'store/mailing';
import { TABLE_STYLE } from 'common/Constants';
import { IconButton } from '@material-ui/core';
import { Delete, CloudDownload } from '@material-ui/icons';
import Utils from 'common/Utils';
import { Dispatch, bindActionCreators } from 'redux';
import * as MailingActions from 'store/mailing/actions';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import GenericTable from 'components/GenericTable/GenericTable';

interface MailingListProps {
  mailings: IMailing[];
  deleteMailing(deleteUrl: string): void;
  campaignId?: number;
  bannerId?: number;
  loading: boolean;
}

const MailingList: React.FC<MailingListProps> = ({
  mailings,
  deleteMailing,
  campaignId,
  bannerId,
  loading,
}) => {
  const location = useLocation().pathname;

  const handleOnDelete = (mailingId: number) => {
    const deleteUrl = location.includes('campanhas')
      ? `campanha/${campaignId}/mailing/${mailingId}`
      : `banner/${bannerId}/mailing/${mailingId}`;
    deleteMailing(deleteUrl);
  };

  const columns = location.includes('campanhas')
    ? [
        {
          title: 'Data envio',
          render: rowData => {
            return `${Utils.setData(rowData.dataUpload)}`;
          },
          cellStyle: { ...TABLE_STYLE },
        },
        {
          title: 'Sucesso',
          render: rowData => {
            return rowData.sucesso === true ? 'Sim' : 'Não';
          },
          cellStyle: { ...TABLE_STYLE },
        },
        {
          title: 'Erro',
          render: rowData => {
            return rowData.erro === true ? 'Sim' : 'Não';
          },
          cellStyle: { ...TABLE_STYLE },
        },
        {
          title: 'Ativo',
          render: rowData => {
            return rowData.ativo === 1 ? 'Sim' : 'Não';
          },
          cellStyle: { ...TABLE_STYLE },
        },
        {
          title: 'Ações',
          cellStyle: {
            width: '15%',
            ...TABLE_STYLE,
          },
          render: ({ id, downloadUrl }) => (
            <>
              <IconButton disabled={loading} onClick={() => window.open(downloadUrl)}>
                <CloudDownload />
              </IconButton>
              <IconButton disabled={loading} onClick={() => handleOnDelete(id)}>
                <Delete fontSize="small" />
              </IconButton>
            </>
          ),
        },
      ]
    : [
        {
          title: 'Data envio',
          render: rowData => {
            return `${Utils.setData(rowData.dataUpload)}`;
          },
          cellStyle: { ...TABLE_STYLE },
        },

        {
          title: 'Nome do Arquivo',
          render: rowData => {
            return `${rowData.nomeArquivo}`;
          },
          cellStyle: { ...TABLE_STYLE },
        },
        {
          title: 'Ações',
          cellStyle: {
            width: '15%',
            ...TABLE_STYLE,
          },
          render: ({ id, downloadUrl }) => (
            <>
              <IconButton disabled={loading} onClick={() => window.open(downloadUrl)}>
                <CloudDownload />
              </IconButton>
              <IconButton disabled={loading} onClick={() => handleOnDelete(id)}>
                <Delete fontSize="small" />
              </IconButton>
            </>
          ),
        },
      ];

  return mailings.length > 0 ? (
    <GenericTable
      title="Seleções Customizadas"
      isLoading={false}
      data={mailings}
      options={{
        paging: false,
        search: false,
      }}
      columns={columns}
    />
  ) : null;
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(MailingActions, dispatch);

export default connect(null, mapDispatchToProps)(MailingList);
