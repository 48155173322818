import { action } from 'typesafe-actions';
import { IUploadCampaignReportEdit, UploadCampaignReportTypes } from '.';

export const loadUploadCampaignReport = (id: number) =>
  action(UploadCampaignReportTypes.LOAD_REQUEST, { id });

export const loadCampaignReportSuccess = (data: IUploadCampaignReportEdit) =>
  action(UploadCampaignReportTypes.LOAD_SUCCESS, { data });

export const resetCampaignReport = () => action(UploadCampaignReportTypes.RESET_CAMPAIGN_REPORT);

export const createUploadCampaignReportRequest = data =>
  action(UploadCampaignReportTypes.CREATE_REQUEST, data);
export const createUploadCampaignReportSuccess = data =>
  action(UploadCampaignReportTypes.CREATE_SUCCESS, data);
export const createUploadCampaignReportFailure = () =>
  action(UploadCampaignReportTypes.CREATE_FAILURE);
