import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import {
  loadPendingAutomaticCreditSuccess,
  loadPendingAutomaticCreditFailure,
  approveAutomaticCreditFailure,
  approveAutomaticCreditSuccess,
  loadAutomaticCreditDetailSuccess,
  loadAutomaticCreditDetailFailure,
  loadStatusFailure,
  loadStatusSuccess,
  automaticCreditReportSuccess,
  automaticCreditReportFailure,
} from './actions';

export function* loadStatusAutomaticCreditRequest() {
  try {
    const { data } = yield call(API.get, 'solicitacao/status');

    yield put(loadStatusSuccess(data));
  } catch (error) {
    yield put(loadStatusFailure());
  }
}

export function* loadPendingAutomaticCreditRequest(action) {
  try {
    const { data } = yield call(API.post, 'campanha/credito-automatico/list', action.payload.data);

    yield put(loadPendingAutomaticCreditSuccess(data));
  } catch (error) {
    yield put(loadPendingAutomaticCreditFailure());
  }
}

export function* loadAutomaticCreditDetailRequest(action) {
  try {
    const { data } = yield call(
      API.get,
      `campanha/credito-automatico/detail/${action.payload.idSolicitacao}`,
    );

    yield put(loadAutomaticCreditDetailSuccess(data));
  } catch (error) {
    yield put(loadAutomaticCreditDetailFailure());
  }
}

export function* approveAutomaticCreditRequest(action) {
  try {
    const { data } = yield call(
      API.post,
      'campanha/credito-automatico/aprovaReprova',
      action.payload.data,
    );

    yield put(approveAutomaticCreditSuccess(data));
  } catch (error) {
    yield put(approveAutomaticCreditFailure(false));
  }
}

export function* automaticCreditReportRequest(action) {
  try {
    const { data } = yield call(API.post, 'solicitacao/csv', action.payload.data);

    yield put(automaticCreditReportSuccess(data));
  } catch (error) {
    yield put(automaticCreditReportFailure());
  }
}
