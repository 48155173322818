import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import {
  loadCommunicationItems as loadCommunicationItemsActions,
  loadCommunicationItemsSuccess,
  loadCommunicationItemsFailure,
  loadCommunicationItemContent as loadCommunicationItemContentAction,
  loadCommunicationItemContentSuccess,
  deleteCommunicationItemContentSuccess,
  updateCommunicationsContentSuccess,
  loadCommunicationPatternSuccess,
  uploadCommunicationPatternSuccess,
  updateCommunicationPatternSuccess,
} from './actions';
import { showToastr } from 'store/toastr/actions';
import { AppMessages } from 'common/AppMessages.enum';

export function* loadCommunicationItems(action) {
  try {
    const campaignId = action.payload.id;
    const { data } = yield call(API.get, `campanha/${campaignId}/comunicacao`);
    yield put(loadCommunicationItemsSuccess(data));

    yield* data.map(function*(e) {
      if (e.campanhaComunicacaoId) {
        yield put(loadCommunicationItemContentAction(campaignId, e.campanhaComunicacaoId));
      }
    });
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
    yield put(loadCommunicationItemsFailure());
  }
}

export function* loadCommunicationItemContent(action) {
  try {
    const campaignId = action.payload.campaignId;
    const campanhaComunicacaoId = action.payload.campanhaComunicacaoId;

    if (campaignId && campanhaComunicacaoId) {
      const { data } = yield call(
        API.get,
        `/campanha/${campaignId}/comunicacao/${campanhaComunicacaoId}`,
      );
      yield put(loadCommunicationItemContentSuccess(data));
    }
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
  }
}

export function* deleteCommunicationItemContent(action) {
  try {
    const campaignId = action.payload.campaignId;
    const communicationId = action.payload.communicationId;
    yield call(API.delete, `/campanha/${campaignId}/comunicacao/${communicationId}`);
    yield put(deleteCommunicationItemContentSuccess(communicationId));
    yield put(
      showToastr({
        type: 'success',
        message: AppMessages.DELETE_COMMUNICATION_SUCCESS,
        open: true,
      }),
    );
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
  }
}

export function* updateCommunicationsContent(action) {
  try {
    const { data } = yield call(
      API.put,
      `/campanha/${action.payload.campaignId}/comunicacao`,
      action.payload.body,
    );
    yield put(updateCommunicationsContentSuccess(data));
    yield put(loadCommunicationItemsActions(action.payload.campaignId));
    yield put(
      showToastr({
        type: 'success',
        message: AppMessages.UPDATE_COMMUNICATION_SUCCESS,
        open: true,
      }),
    );
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
  }
}

export function* loadCommunicationPattern(action) {
  try {
    const { data } = yield call(
      API.get,
      `/campanha/${action.payload.campaignId}/comunicacao/padrao`,
    );
    yield put(loadCommunicationPatternSuccess(data));
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
  }
}

export function* uploadCommunicationPattern(action) {
  try {
    const { data } = yield call(
      API.post,
      `/campanha/${action.payload.campaignId}/comunicacao/upload`,
      action.payload.data,
    );
    yield put(uploadCommunicationPatternSuccess(data));
    yield put(
      showToastr({
        type: 'success',
        message: AppMessages.UPLOAD_COMMUNICATION_SUCCESS,
        open: true,
      }),
    );
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
  }
}

export function* updateCommunicationPattern(action) {
  try {
    const { data } = yield call(
      API.put,
      `/campanha/${action.payload.campaignId}/comunicacao`,
      action.payload.request,
    );
    yield put(updateCommunicationPatternSuccess(data));
    yield put(
      showToastr({
        type: 'success',
        message: AppMessages.UPDATE_COMMUNICATION_SUCCESS,
        open: true,
      }),
    );
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
  }
}
