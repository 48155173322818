import React, { useState } from 'react';
import Wrap from 'components/Wrap/Wrap';
import { RouteComponentProps } from 'react-router-dom';
import { Breadcrumbs, Link, Box } from '@material-ui/core';
import Tab, { ITab } from 'components/Tab/Tab';
import PlanAlterationLot from './PlanAlterationLot';

interface IParams {
  id?: string | undefined;
}

const PlanAlterationLotIndex: React.FC<RouteComponentProps<IParams>> = ({ match, history }) => {
  const [tabs] = useState<ITab[]>([
    {
      component: <PlanAlterationLot />,
      renderLabel: (tab, index) => `Upload CSV`,
      disabled: true,
    },
  ]);

  return (
    <Box mb="1.5em">
      <Wrap>
        <Breadcrumbs>
          <Link color="inherit" onClick={() => history.push('/')}>
            Home
          </Link>
          <Link color="inherit" onClick={() => history.push('/participantes/gestaodeplanos')}>
            Gestão de Planos
          </Link>
          <Link
            color="inherit"
            onClick={() => history.push('/participantes/gestaodeplanos/alteracaoplanoslote')}
          >
            Alteração de Planos em Lote
          </Link>
        </Breadcrumbs>
      </Wrap>

      <Tab tabs={tabs} enableNavigationButton={false} />
    </Box>
  );
};

export default PlanAlterationLotIndex;
