import { Reducer } from 'redux';
import {
  IUploadVolume,
  IUploadVolumeDetail,
  IUploadFile,
} from 'components/GenericImportTable/GenericImportTable';

export enum ParticipantAllowanceFileTypes {
  LOAD_REQUEST = '@ParticipantAllowance/LOAD_REQUEST',
  LOAD_SUCCESS = '@ParticipantAllowance/LOAD_SUCCESS',
  LOAD_FAILURE = '@ParticipantAllowance/LOAD_FAILURE',
  LOAD_DETAILS_REQUEST = '@ParticipantAllowance/LOAD_DETAILS_REQUEST',
  LOAD_DETAILS_SUCCESS = '@ParticipantAllowance/LOAD_DETAILS_SUCCESS',
  LOAD_DETAILS_FAILURE = '@ParticipantAllowance/LOAD_DETAILS_FAILURE',
  UPLOAD_REQUEST = '@ParticipantAllowance/UPLOAD_REQUEST',
  UPLOAD_SUCCESS = '@ParticipantAllowance/UPLOAD_SUCCESS',
  UPLOAD_FAILURE = '@ParticipantAllowance/UPLOAD_FAILURE',
}

export interface IParticipantAllowanceFileState {
  readonly uploadVolume: IUploadVolume;
  readonly uploadVolumeDetail: IUploadVolumeDetail;
  readonly uploadFile: IUploadFile;
}

const INITIAL_STATE: IParticipantAllowanceFileState = {
  uploadVolume: {
    data: [],
    loading: false,
  },
  uploadVolumeDetail: {
    data: [],
    loading: false,
  },
  uploadFile: {
    loading: false,
    success: false,
    error: false,
  },
};

const reducer: Reducer<IParticipantAllowanceFileState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ParticipantAllowanceFileTypes.LOAD_REQUEST:
      return {
        ...state,
        uploadVolume: {
          ...state.uploadVolume,
          loading: true,
        },
      };
    case ParticipantAllowanceFileTypes.LOAD_SUCCESS:
      return {
        ...state,
        uploadVolume: {
          ...state.uploadVolume,
          data: [...action.payload.data],
          loading: false,
          success: true,
        },
      };
    case ParticipantAllowanceFileTypes.LOAD_FAILURE:
      return {
        ...state,
        uploadVolume: {
          ...state.uploadVolume,
          loading: false,
          success: false,
          error: true,
        },
      };
    case ParticipantAllowanceFileTypes.LOAD_DETAILS_REQUEST:
      return {
        ...state,
        uploadVolumeDetail: {
          ...state.uploadVolumeDetail,
          loading: true,
        },
      };
    case ParticipantAllowanceFileTypes.LOAD_DETAILS_SUCCESS:
      return {
        ...state,
        uploadVolumeDetail: {
          ...state.uploadVolumeDetail,
          data: [...action.payload.data],
          loading: false,
          success: true,
        },
      };
    case ParticipantAllowanceFileTypes.LOAD_DETAILS_FAILURE:
      return {
        ...state,
        uploadVolumeDetail: {
          ...state.uploadVolumeDetail,
          loading: false,
          success: false,
          error: true,
        },
      };
    case ParticipantAllowanceFileTypes.UPLOAD_REQUEST:
      return {
        ...state,
        uploadFile: {
          ...state.uploadFile,
          loading: true,
          success: false,
          error: false,
        },
      };
    case ParticipantAllowanceFileTypes.UPLOAD_SUCCESS:
      return {
        ...state,
        uploadVolume: {
          ...state.uploadVolume,
          data: [...state.uploadVolume.data, action.payload.data],
        },
        uploadFile: {
          ...state.uploadFile,
          loading: false,
          success: true,
          error: false,
        },
      };
    case ParticipantAllowanceFileTypes.UPLOAD_FAILURE:
      return {
        ...state,
        uploadFile: {
          ...state.uploadFile,
          loading: false,
          success: false,
          error: true,
        },
      };
    default:
      return state;
  }
};

export default reducer;
