import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import {
  loadRealtimeTotalPrimeirosAcessosClusterSuccess,
  loadRealtimeTotalPrimeirosAcessosClusterFailure,
} from './actions';

export function* loadRealtimeTotalPrimeirosAcessosClusterRequest() {
  try {
    const { data } = yield call(API.get, 'dashboard/realtime/total/primeirosAcessos/cluster');
    yield put(loadRealtimeTotalPrimeirosAcessosClusterSuccess(data.itens, data.atualizacao));
  } catch (error) {
    yield put(loadRealtimeTotalPrimeirosAcessosClusterFailure());
  }
}
