import React, { Dispatch, SetStateAction, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import { Dispatch as ReduxDispatch } from 'redux';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
  Theme,
  Grid,
  FormControl,
  Divider,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import * as ChainingNetworkActions from 'store/participant/actions';
import {
  IStatusItem,
  IChainingNetDetailItem,
  INITIAL_STATE,
  IChainingNetApproval,
  IChainingNetworkParams,
} from 'store/participant';

interface ModalApprovingChaining {
  loading: boolean;
  title: string;
  state: boolean;
  setState: Dispatch<SetStateAction<boolean>>;
  size?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
  idSolicitacao: number;
  statusList?: IStatusItem[];
  dataDetailRequest: IChainingNetDetailItem;
  loadChainingNetDetailRequest: (idSolicitacao: number) => void;
  approveChainingNetRequest: (data: IChainingNetApproval) => void;
  approved: boolean;
  loadChainingParams: IChainingNetworkParams;
  loadPendingChainingNetRequest: (data: IChainingNetworkParams) => void;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: theme.typography.fontFamily,
      height: '100vh',
    },
    label: {
      fontWeight: 'bolder',
      fontSize: '10px',
      marginBottom: theme.spacing(1),
      display: 'block',
    },
    spacingBottom: {
      marginBottom: theme.spacing(2),
    },
  }),
);

const ApprovingModal: React.FC<ModalApprovingChaining> = ({
  loading,
  state,
  setState,
  title,
  size,
  idSolicitacao,
  statusList,
  loadChainingNetDetailRequest,
  dataDetailRequest,
  approveChainingNetRequest,
  approved,
  loadChainingParams,
  loadPendingChainingNetRequest,
}) => {
  const classes = useStyle();
  const [status, setStatus] = useState(0);
  const [stateStatusList, setStateStatusList] = useState<IStatusItem[]>([]);
  const [dataDetail, setDataDetail] = useState<IChainingNetDetailItem>(INITIAL_STATE.dataDetail);
  const [denyingReason, setDenyingReason] = useState('');
  const [approvalModal, setApprovalModal] = useState(false);
  const [denyingModal, setDenyingModal] = useState(false);

  useEffect(() => {
    if (idSolicitacao !== 0) loadChainingNetDetailRequest(idSolicitacao);
  }, [idSolicitacao]);

  useEffect(() => {
    setDataDetail(dataDetailRequest);
    setStatus(dataDetailRequest.statusId);
  }, [dataDetailRequest]);

  useEffect(() => {
    if (statusList) setStateStatusList(statusList);
  }, [statusList]);

  useEffect(() => {
    setApprovalModal(false);
    setDenyingModal(false);
    setState(false);
    loadPendingChainingNetRequest(loadChainingParams);
  }, [approved]);

  const handleConfirmation = () => {
    //Aprovado
    if (status === 2) setApprovalModal(true);
    else if (status === 3 || status === 4) setDenyingModal(true);
  };

  const handleApproval = approved => {
    let data = {
      idSolicitacao: idSolicitacao,
      idStatus: status,
      motivo: approved ? '' : denyingReason,
    };

    if (idSolicitacao !== 0) {
      approveChainingNetRequest(data);
      setApprovalModal(false);
      setDenyingModal(false);
    }
  };

  return (
    <Dialog className={classes.root} maxWidth={size || 'xs'} fullWidth={true} open={state}>
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent dividers={true}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormControl fullWidth={true}>
              <TextField
                disabled={true}
                value={dataDetail.matriz}
                variant="outlined"
                label="Varejo matriz / Solicitante"
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth={true}>
              <TextField
                disabled={true}
                value={dataDetail.matrizNome}
                variant="outlined"
                label="Nome / Razão social"
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth={true}>
              <TextField
                disabled={true}
                value={dataDetail.matrizStatus}
                variant="outlined"
                label="Status"
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth={true}>
              <TextField
                disabled={true}
                value={dataDetail.solicitadoEm}
                variant="outlined"
                label="Pedido efetuado em"
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth={true}>
              <TextField
                disabled={true}
                value={dataDetail.associado}
                variant="outlined"
                label="Varejo associado solicitado"
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth={true}>
              <TextField
                disabled={true}
                value={dataDetail.associadoNome}
                variant="outlined"
                label="Nome / Razão social"
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth={true}>
              <TextField
                disabled={true}
                value={dataDetail.associadoStatus}
                variant="outlined"
                label="Status"
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth={true}>
              <TextField
                disabled={true}
                value={dataDetail.varejoPrime}
                variant="outlined"
                label="Varejo prime"
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6">
              O varejo solicitante pediu a Conecta Pay para o varejo associado?
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <TextField
              disabled={true}
              value={dataDetail.conectaPayParaAssociado}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">
              <small>
                Dados bancários: &nbsp;
                <code>
                  Banco: {dataDetail.dadosBancarios.banco} / Agência:{' '}
                  {dataDetail.dadosBancarios.agencia} / Conta: {dataDetail.dadosBancarios.conta} /
                  Nome favorecido: {dataDetail.dadosBancarios.nome} / Documento do favorecido:{' '}
                  {dataDetail.dadosBancarios.documento}
                </code>
              </small>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider light />
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <FormControl fullWidth variant="outlined">
              <TextField
                name="statusPedido"
                id="statusPedido"
                select
                required
                value={status}
                label="Informe o status final desse pedido:"
                onChange={(e: React.ChangeEvent<{ value: any }>) =>
                  setStatus(parseInt(e.target.value))
                }
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
                disabled={dataDetail.statusId > 1 || loading ? true : false}
              >
                {stateStatusList.map(stat => (
                  <option key={stat.id} value={stat.id}>
                    {stat.nome}
                  </option>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={9}>
            {dataDetail.statusId > 2 ? (
              <Typography variant="h6">
                Motivo reprovação/cancelamento:{' '}
                <small>{dataDetail.motivoReprovacaoCancelamento}</small>
              </Typography>
            ) : (
              ''
            )}
          </Grid>
          <Grid item xs={12}>
            {dataDetail.statusId > 1 ? (
              <Typography variant="h6">
                Registro moderado por:{' '}
                <small>
                  {dataDetail.moderadoPor} em {dataDetail.dataModeracao}
                </small>
              </Typography>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={() => setState(false)}>
          Cancelar
        </Button>
        <Button
          color="primary"
          onClick={() => handleConfirmation()}
          disabled={dataDetail.statusId > 1 || loading}
        >
          {loading ? 'Requisitando...' : 'Salvar'}
        </Button>
      </DialogActions>
      <Dialog className={classes.root} maxWidth={'xs'} fullWidth={true} open={approvalModal}>
        <DialogTitle id="approvalConfirmation">Tem certeza?</DialogTitle>
        <DialogContent dividers={true}>
          <Typography variant="h6">
            Você tem certeza de que deseja APROVAR esse pedido? Essa ação desencadeará processos que
            não poderão ser revertidos, como por exemplo, tornar um varejo associado em varejo prime
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setApprovalModal(false)}>
            Cancelar
          </Button>
          <Button color="primary" onClick={() => handleApproval(true)}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog className={classes.root} maxWidth={'xs'} fullWidth={true} open={denyingModal}>
        <DialogTitle id="approvalConfirmation">Tem certeza?</DialogTitle>
        <DialogContent dividers={true}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">
                Você tem certeza de que deseja REPROVAR/CANCELAR esse pedido? Essa ação não poderá
                ser revertida
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth={true}>
                <TextField
                  id="denying_reason"
                  label="Justifique o motivo"
                  multiline
                  rows={5}
                  onChange={e => setDenyingReason(e.target.value)}
                  value={denyingReason}
                  variant="outlined"
                  inputProps={{
                    maxLength: 500,
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setDenyingModal(false)}>
            Cancelar
          </Button>
          <Button
            color="primary"
            onClick={() => handleApproval(false)}
            disabled={denyingReason === '' ? true : false}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    dataDetailRequest: state.participant.dataDetail,
    statusList: state.participant.statusList,
    approved: state.participant.approved,
    loading: state.participant.loading,
    error: state.participant.error,
    success: state.participant.success,
  };
};

const mapDispatchToProps = (dispatch: ReduxDispatch) => {
  return {
    loadChainingNetDetailRequest: (idSolicitacao: number) => {
      dispatch(ChainingNetworkActions.loadChainingNetDetailRequest(idSolicitacao));
    },
    approveChainingNetRequest: (data: IChainingNetApproval) => {
      dispatch(ChainingNetworkActions.approveChainingNetRequest(data));
    },
    loadPendingChainingNetRequest: (data: IChainingNetworkParams) => {
      dispatch(ChainingNetworkActions.loadPendingChainingNetRequest(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApprovingModal);
