import React, { useEffect } from 'react';
import { TABLE_STYLE } from 'common/Constants';
import { IconButton } from '@material-ui/core';
import { Delete, CloudDownload } from '@material-ui/icons';
import Utils from 'common/Utils';
import { Dispatch, bindActionCreators } from 'redux';
import * as BannerEditActions from 'store/banner/edit/actions';
import { connect } from 'react-redux';
import GenericTable from 'components/GenericTable/GenericTable';
import { ApplicationState } from 'store';

interface MailingListProps {
  data: any;
  deleteParticipantFile(bannerParticipantFileId: number): void;
  loadParticipantsFileList(bannerId: number): void;
  bannerId?: number;
  removingParticipantFile: any;
}

const MailingListParticipantBanner: React.FC<MailingListProps> = ({
  data,
  deleteParticipantFile,
  loadParticipantsFileList,
  bannerId,
  removingParticipantFile,
}) => {
  const handleOnDelete = (bannerParticipantFileId: number) => {
    deleteParticipantFile(bannerParticipantFileId);
  };

  useEffect(() => {
    if (removingParticipantFile) loadParticipantsFileList(Number(bannerId));
  }, [removingParticipantFile]);

  return data.length > 0 ? (
    <GenericTable
      title="Seleções Customizadas"
      isLoading={false}
      data={data}
      options={{
        paging: false,
        search: false,
      }}
      columns={[
        {
          title: 'Data envio',
          render: rowData => {
            return `${Utils.setData(rowData.dataInclusao)}`;
          },
          cellStyle: { ...TABLE_STYLE },
        },
        {
          title: 'Nome do arquivo',
          field: 'nomeArquivo',
          cellStyle: { ...TABLE_STYLE },
        },
        {
          title: 'Sucesso',
          render: rowData => {
            return `${rowData.sucesso ? 'Sim' : 'Não'}`;
          },
          cellStyle: { ...TABLE_STYLE },
        },
        {
          title: 'Processado',
          render: rowData => {
            return `${rowData.processado ? 'Sim' : 'Não'}`;
          },
          cellStyle: { ...TABLE_STYLE },
        },
        {
          title: 'Ações',
          cellStyle: {
            width: '15%',
            ...TABLE_STYLE,
          },
          render: ({ id, downloadUrl }) => (
            <>
              <IconButton onClick={() => window.open(downloadUrl)}>
                <CloudDownload />
              </IconButton>
              <IconButton onClick={() => handleOnDelete(id)}>
                <Delete fontSize="small" />
              </IconButton>
            </>
          ),
        },
      ]}
    />
  ) : null;
};

const mapStateToProps = (state: ApplicationState) => ({
  removingParticipantFile: state.bannerEdit.removingParticipantFile,
});
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(BannerEditActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MailingListParticipantBanner);
