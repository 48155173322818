import React from 'react';
import { IMailing } from 'store/mailing';
import { TABLE_STYLE } from 'common/Constants';
import { IconButton } from '@material-ui/core';
import { Delete, CloudDownload } from '@material-ui/icons';
import Utils from 'common/Utils';
import { Dispatch, bindActionCreators } from 'redux';
import * as UploadConectaPayFileActions from 'store/UploadConectaPayReport/uploadFile/actions';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import GenericTable from 'components/GenericTable/GenericTable';
import { IUploadConectaPayDataFile } from 'store/UploadConectaPayReport/uploadFile';

interface UploadConectaPayDataFileProps {
  uploadFile: IUploadConectaPayDataFile[];
  conectaPayReportId?: number;
  deleteUploadConectaPayCsv(deleteUrl: string): void;
}

const UploadConectaPayFile: React.FC<UploadConectaPayDataFileProps> = ({
  uploadFile,
  conectaPayReportId,
  deleteUploadConectaPayCsv,
}) => {
  const location = useLocation().pathname;

  const handleOnDelete = (conectaPayReportId: number) => {
    const deleteUrl = `conectaPay/${conectaPayReportId}/InativarArquivo`;
    deleteUploadConectaPayCsv(deleteUrl);
  };

  return uploadFile.length > 0 ? (
    <GenericTable
      title="Upload ConectaPay"
      isLoading={false}
      data={uploadFile}
      options={{
        paging: false,
        search: false,
      }}
      columns={[
        {
          title: 'Data envio',
          render: rowData => {
            return `${Utils.setData(rowData.dataUpload)}`;
          },
          cellStyle: { ...TABLE_STYLE },
        },
        {
          title: 'Sucesso',
          render: rowData => {
            return rowData.sucesso === true ? 'Sim' : 'Não';
          },
          cellStyle: { ...TABLE_STYLE },
        },
        {
          title: 'Erro',
          render: rowData => {
            return rowData.erro === true ? 'Sim' : 'Não';
          },
          cellStyle: { ...TABLE_STYLE },
        },
        {
          title: 'Processado',
          render: rowData => {
            return rowData.processado === true ? 'Sim' : 'Não';
          },
          cellStyle: { ...TABLE_STYLE },
        },
        {
          title: 'Ativo',
          render: rowData => {
            return rowData.ativo === true ? 'Sim' : 'Não';
          },
          cellStyle: { ...TABLE_STYLE },
        },
        {
          title: 'Ações',
          cellStyle: {
            width: '15%',
            ...TABLE_STYLE,
          },
          render: ({ id, downloadUrl }) => (
            <>
              <IconButton onClick={() => window.open(downloadUrl)}>
                <CloudDownload />
              </IconButton>
              <IconButton onClick={() => handleOnDelete(id)}>
                <Delete fontSize="small" />
              </IconButton>
            </>
          ),
        },
      ]}
    />
  ) : null;
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(UploadConectaPayFileActions, dispatch);

export default connect(null, mapDispatchToProps)(UploadConectaPayFile);
