import React, { useState, useEffect } from 'react';
import { Box, Button, Paper, makeStyles, createStyles, Theme } from '@material-ui/core';
import * as ToastrActions from 'store/toastr/actions';
import { AppMessages } from 'common/AppMessages.enum';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import cn from 'classnames';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import AttachFileIcon from '@material-ui/icons/AttachFile';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-block',
    },
    fullWidth: {
      width: '100%',
    },
    label: {
      display: 'inline-block',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    small: {
      fontSize: '11px',
    },
    wrap: {
      padding: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      fontSize: 12,
      color: 'rgba(0, 0, 0, 0.54)',
      position: 'absolute',
      top: '-12px',
    },
    relative: {
      position: 'relative',
    },
    thumbnail: {
      maxWidth: '100%',
      borderRadius: '6px 6px 0 0',
    },
  }),
);

export interface IFile {
  name: string;
  lastModified: number;
  lastModifiedDate: string;
  webkitRelativePath: string;
  size: number;
  type: string;
}

type AcceptFileType =
  | 'xlsx'
  | 'xls'
  | 'csv'
  | 'png'
  | 'jpeg'
  | 'jpg'
  | 'gif'
  | 'avi'
  | 'mp4'
  | 'mpeg';

export interface IFileUpload {
  onUpload?(file: IFile | undefined, resetFileUpload?: any): void;
  onSetThumbnail?(file: IFile | undefined): void;
  showThumbnail?: boolean;
  showToastr(options): void;
  acceptType?: AcceptFileType[];
  compact?: boolean;
  fullWidth?: boolean;
  parentClasses?: string;
  disabled?: boolean;
  label?: string;
  loading?: boolean;
  text?: string;
  maxWidth?: string;
  callback?(file: IFile | undefined, resetFileUpload?: any): void;
  withConfirmation?: boolean;
}

const FileUpload: React.ComponentType<IFileUpload> = ({
  onUpload,
  onSetThumbnail,
  acceptType,
  showToastr,
  compact = false,
  fullWidth = false,
  parentClasses,
  disabled = false,
  label,
  showThumbnail = false,
  loading = false,
  text = 'Selecione um arquivo...',
  maxWidth = '100%',
  callback,
  withConfirmation = false,
}) => {
  const classes = useStyle();

  const [file, setFile] = useState<IFile | undefined>(undefined);
  const [thumbnail, setThumbnail] = useState('');

  const handleOnFileChange = (e: React.ChangeEvent<{ files: any }>) => {
    const isFileTypeValid = acceptType
      ? acceptType.includes(e.target.files[0]?.name.split('.')[1])
      : true;
    if (isFileTypeValid) {
      setFile(e.target.files[0]);
    } else {
      showToastr({ message: AppMessages.INVALID_FILE_TYPE, type: 'error', open: true });
    }
  };

  const handleUpload = event => {
    if (withConfirmation && callback) {
      // Opcional confirmation (Example: Used with ModalConfirmation - check example searching on component "PlanAlterationLot")
      event.preventDefault();
      callback(file, resetFileUpload());
      return;
    } else if (onUpload) {
      onUpload(file, resetFileUpload());
    }
  };

  const resetFileUpload = () => setFile(undefined);

  useEffect(() => {
    if (onSetThumbnail) onSetThumbnail(file);

    if (file) {
      if (showThumbnail) {
        const reader = new FileReader() as any;
        reader.onloadend = () => {
          setThumbnail(reader.result);
        };
        reader.readAsDataURL(file);
        setThumbnail('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return (
    <Box className={classes.relative}>
      {label && <label className={classes.title}>{label}</label>}
      <Paper className={cn([classes.root, fullWidth ? classes.fullWidth : '', parentClasses])}>
        {thumbnail && (
          <Box>
            <img
              src={thumbnail}
              alt={thumbnail}
              style={{ maxWidth: maxWidth, borderRadius: '6px 6px 0 0' }}
            />
          </Box>
        )}
        <Box className={cn([classes.wrap])}>
          <Button
            variant="contained"
            size={compact ? 'small' : 'medium'}
            className={compact ? classes.small : ''}
            startIcon={<AttachFileIcon />}
            component="label"
          >
            {file ? 'Alterar' : 'Selecionar'}
            <input
              type="file"
              className="d-none"
              onChange={handleOnFileChange}
              disabled={disabled}
            />
          </Button>
          <span className={cn([classes.label, compact ? classes.small : ''])}>
            {file ? file.name : text}
          </span>
          <Button
            size={compact ? 'small' : 'medium'}
            className={compact ? classes.small : ''}
            variant="contained"
            color="secondary"
            onClick={handleUpload}
            disabled={disabled || loading || !file}
            endIcon={<CloudUploadIcon />}
          >
            {loading ? 'Aguarde...' : 'Enviar'}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(ToastrActions, dispatch);

export default connect(null, mapDispatchToProps)(FileUpload);
