import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import FileUpload from 'components/FileUpload/FileUpload';
import * as UploadConectaPayFileActions from 'store/UploadConectaPayReport/uploadFile/actions';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import { Dispatch, bindActionCreators } from 'redux';
import { MODULE_TYPE, STRUCTURE_PULICTARGET } from 'common/Constants';
import UploadConectaPayFile from './uploadFile/UploadConectaPayFile';
import DownloadClientBaseBeneficioCSV from 'components/DownloadClientBase/DownloadClientBaseBenefitCSV';

interface UploadReportProps {
  conectaPayReportId?: number;
  loadUploadConectaPayFile(conectaPayReportId: number, moduleType: MODULE_TYPE): void;
  uploadConectaPayCsv(
    uploadUrl: string,
    data: FormData,
    callbackAction: any,
    id: number,
    type: number,
  ): void;
  uploadFile;
}

const UploadConectaPayReport: React.FC<UploadReportProps> = ({
  conectaPayReportId,
  loadUploadConectaPayFile,
  uploadFile,
  uploadConectaPayCsv,
}) => {
  console.log(uploadFile);
  const location = useLocation().pathname;
  const [url] = useState(`/conectapay`);

  useEffect(() => {
    loadUploadConectaPayFile(Number(conectaPayReportId), 1);
  }, []);

  const handleUpload = (file: any) => {
    const data = new FormData();
    data.append('file', file);
    const uploadUrl = `${url}/upload`;

    uploadConectaPayCsv(uploadUrl, data, '', Number(conectaPayReportId), 1);
  };

  return (
    <>
      <Box>
        <Box fontSize="h5.fontSize" mt="1em">
          Definir seleção customizada:
        </Box>
        <Box fontSize={14} mb="1em">
          <strong>Importante!</strong> Para realizar a seleção customizada faça o upload de um
          arquivo CSV contendo a base de CodigoSap elegíveis.
        </Box>

        <FileUpload onUpload={handleUpload} acceptType={['csv']} />

        <Box mt="1em">
          <DownloadClientBaseBeneficioCSV />
        </Box>

        <Box mt="1em">
          <UploadConectaPayFile uploadFile={uploadFile} conectaPayReportId={conectaPayReportId} />
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  uploadFile: state.storeUploadConectaPayReportFile.data,
  loading: state.storeUploadConectaPayReportFile.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(UploadConectaPayFileActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UploadConectaPayReport);
