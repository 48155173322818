import React, { useState, useEffect } from 'react';
import Wrap from 'components/Wrap/Wrap';
import { RouteComponentProps } from 'react-router-dom';
import { Breadcrumbs, Typography, Link, Box } from '@material-ui/core';
import Tab, { ITab } from 'components/Tab/Tab';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import { Dispatch, bindActionCreators } from 'redux';
import { IUploadCampaignReportEdit } from 'store/uploadCampaignReport';
import * as UploadCampaignEditActions from 'store/uploadCampaignReport/actions';
import UploadCampaignReportGeneralData from './UploadCampaignReportGeneralData';
import UploadReport from './UploadReport';
import * as UploadCampaingFileActions from 'store/uploadCampaignReport/uploadFile/actions';

interface IParams {
  id?: string | undefined;
}

interface UploadCampaignEditProps {
  loadUploadCampaignReport: (id: number) => void;
  resetCampaignReport: () => void;
  uploadCampaignReport: IUploadCampaignReportEdit;
  loading: boolean;
}

const UploadCampaignReportEdit: React.FC<UploadCampaignEditProps &
  RouteComponentProps<IParams>> = ({
  match,
  history,
  loadUploadCampaignReport,
  resetCampaignReport,
  uploadCampaignReport,
  loading,
}) => {
  useEffect(() => {
    if (match.params.id) {
      loadUploadCampaignReport(+match.params.id);
    }
    return () => resetCampaignReport();
  }, []);

  const isTabsDisabled = (): boolean => !match.params.id && !uploadCampaignReport.id;

  const [tabs] = useState<ITab[]>([
    {
      component: <UploadCampaignReportGeneralData />,
      renderLabel: (tab, index) => `${index + 1}. Dados upload`,
      disabled: false,
    },
    {
      component: <UploadReport campaignReportId={Number(match.params.id)} />,
      renderLabel: (tab, index) => `${index + 1}. Upload CSV`,
      disabled: isTabsDisabled(),
    },
  ]);

  return (
    <Box mb="1.5em">
      <Wrap>
        <Breadcrumbs>
          <Link color="inherit" onClick={() => history.push('/')}>
            Home
          </Link>
          <Link color="inherit" onClick={() => history.push('/Report/UploadCampaignReportExcel')}>
            Gestão de Uploads
          </Link>
          <Typography color="textPrimary">
            {!!uploadCampaignReport.id ? 'Editar' : 'Cadastrar'}
          </Typography>
        </Breadcrumbs>
      </Wrap>

      <Tab tabs={tabs} enableNavigationButton={true} disableNextButton={!uploadCampaignReport.id} />
    </Box>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  uploadCampaignReport: state.uploadCampaignReport.data,
  loading: state.uploadCampaignReport.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UploadCampaignEditActions, ...UploadCampaingFileActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UploadCampaignReportEdit);
