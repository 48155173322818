import { STRUCTURE_PULICTARGET } from 'common/Constants';
import { Reducer } from 'redux';
import { ModuleFilterType, IFilterItemRequest } from 'common/Constants';
import { BannerBenefitType } from 'components/BannerHomeUpload/BannerBenefitHomeUpload';

export enum BenefitEditTypes {
  LOAD_REQUEST = '@benefitEdit/LOAD_REQUEST',
  LOAD_SUCCESS = '@benefitEdit/LOAD_SUCCESS',
  LOAD_FAILURE = '@benefitEdit/LOAD_FAILURE',
  RESET_BENEFIT = '@benefitEdit/RESET_BENEFIT',
  CREATE_REQUEST = '@benefitEdit/CREATE_REQUEST',
  CREATE_SUCCESS = '@benefitEdit/CREATE_SUCCESS',
  CREATE_FAILURE = '@benefitEdit/CREATE_FAILURE',
  APPLYFILTER_REQUEST = '@benefitEdit/APPLYFILTER_REQUEST',
  APPLYFILTER_SUCCESS = '@benefitEdit/APPLYFILTER_SUCCESS',
  APPLYFILTER_FAILURE = '@benefitEdit/APPLYFILTER_FAILURE',
  GETFILTER_REQUEST = '@benefitEdit/GETFILTER_REQUEST',
  GETFILTER_SUCCESS = '@benefitEdit/GETFILTER_SUCCESS',
  GETFILTER_FAILURE = '@benefitEdit/GETFILTER_FAILURE',
  GETFILTER_COMPLETED = '@benefitEdit/GETFILTER_COMPLETED',
  GETFILTER_RESET = '@benefitEdit/GETFILTER_RESET',
  GETFILTER_SETCOMPLETED = '@benefitEdit/GETFILTER_SETCOMPLETED',
  UPLOAD_BANNER_REQUEST = '@benefitEdit/UPLOAD_BANNER_REQUEST',
  UPLOAD_BANNER_SUCCESS = '@benefitEdit/UPLOAD_BANNER_SUCCESS',
  UPLOAD_BANNER_FAILURE = '@benefitEdit/UPLOAD_BANNER_FAILURE',
  UPDATE_HAS_REGULATION_REQUEST = '@benefitEdit/UPDATE_HAS_REGULATION_REQUEST',
  UPDATE_HAS_REGULATION_SUCCESS = '@benefitEdit/UPDATE_HAS_REGULATION_SUCCESS',
  UPDATE_REQUIRED_REGULATION_REQUEST = '@benefitEdit/UPDATE_REQUIRED_REGULATION_REQUEST',
  UPDATE_REQUIRED_REGULATION_SUCCESS = '@benefitEdit/UPDATE_REQUIRED_REGULATION_SUCCESS',
  LOAD_benefit_FILTER_APPLIED_REQUEST = '@benefitEdit/LOAD_benefit_FILTER_APPLIED_REQUEST',
  LOAD_benefit_FILTER_APPLIED_SUCCESS = '@benefitEdit/LOAD_benefit_FILTER_APPLIED_SUCCESS',
  LOAD_benefit_FILTER_APPLIED_FAILURE = '@benefitEdit/LOAD_benefit_FILTER_APPLIED_FAILURE',
  SETFILTER_COUNT_REQUEST = '@benefitEdit/SETFILTER_COUNT_REQUEST',
  LOAD_TARGETPUBLIC_REQUEST = '@benefitEdit/LOAD_TARGETPUBLIC_REQUEST',
  LOAD_TARGETPUBLIC_SUCCESS = '@benefitEdit/LOAD_TARGETPUBLIC_SUCCESS',
  LOAD_TARGETPUBLIC_FAILURE = '@benefitEdit/LOAD_TARGETPUBLIC_FAILURE',
}

export interface IGeneralData {
  nome: string;
  resumoCard: string;
  descricaoDosGanhos: string;
  urlRedirect: string;
  vigenciaDe: string;
  vigenciaAte: string;
  ativo: boolean;
  suporte: string;
  duvidasFrequentes: string;
  filtroPorParticipante: number;
  filtros: any;
  beneficioBanner: IBenefitBanner[];
  ordem: number;
  mostrarNaCalculadora: boolean;
}

export interface IBenefitBanner {
  id: number;
  benefitId: number;
  tipoId: BannerBenefitType;
  url: string;
}

export interface IGeneralDataCreateRequest {
  nome: string;
  resumoCard: string;
  descricaoDosGanhos: string;
  urlRedirect: string;
  vigenciaDe: string;
  vigenciaAte: string;
  ativo: boolean;
}

export interface IBenefitEdit extends IGeneralData {
  id: string | undefined;
}

export interface IElegibleTypeOptions {
  id: number;
  nome: string;
}

export interface IBenefitEditState {
  readonly data: IBenefitEdit;
  readonly elegibleTypeOptions: IElegibleTypeOptions[];
  readonly loading: boolean;
  readonly error: boolean;
}

export const INITIAL_STATE: IBenefitEditState = {
  data: {
    id: '',
    vigenciaAte: '',
    vigenciaDe: '',
    resumoCard: '',
    descricaoDosGanhos: '',
    urlRedirect: '',
    nome: '',
    ativo: false,
    suporte: '',
    duvidasFrequentes: '',
    filtroPorParticipante: 0,
    beneficioBanner: [],
    ordem: 0,
    mostrarNaCalculadora: false,
    filtros: {
      atividades: [],
      clusters: [],
      ufs: [],
    },
  },
  elegibleTypeOptions: [],
  loading: false,
  error: false,
};

const reducer: Reducer<IBenefitEditState> = (state = INITIAL_STATE, action) => {
  console.log('action create Benefit', action);
  switch (action.type) {
    case BenefitEditTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case BenefitEditTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...action.payload.data,
          elegivelFiltroTipo:
            action.payload.data.elegivelFiltroTipo === null
              ? 0
              : action.payload.data.elegivelFiltroTipo,
        },
        elegibleTypeBlocked:
          action.payload.data.elegivelFiltroTipo === 0 ||
          action.payload.data.elegivelFiltroTipo === null
            ? false
            : true,
      };
    case BenefitEditTypes.RESET_BENEFIT:
      return { ...state, loading: false, error: false, data: INITIAL_STATE.data };
    case BenefitEditTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true, data: { ...INITIAL_STATE.data } };
    case BenefitEditTypes.CREATE_REQUEST:
      return { ...state, loading: true };
    case BenefitEditTypes.CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: { ...state.data, ...action.payload, id: action.payload.id },
      };
    case BenefitEditTypes.CREATE_FAILURE:
      return { ...state, loading: false };
    case BenefitEditTypes.APPLYFILTER_REQUEST:
      return { ...state, loading: true };
    case BenefitEditTypes.APPLYFILTER_SUCCESS:
      return { ...state, loading: false, data: { ...state.data } };
    case BenefitEditTypes.APPLYFILTER_FAILURE:
      return { ...state, loading: false };
    case BenefitEditTypes.GETFILTER_REQUEST:
      return { ...state, loading: true, filterParticipantCount: 0 };
    case BenefitEditTypes.GETFILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: { ...state.data },
        elegibleFilter: { ...action.payload, isCompleted: false },
      };
    case BenefitEditTypes.GETFILTER_COMPLETED:
      return {
        ...state,
        loading: false,
        data: { ...state.data },
      };
    case BenefitEditTypes.UPLOAD_BANNER_REQUEST:
      return { ...state, loading: true };
    case BenefitEditTypes.UPLOAD_BANNER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: { ...state.data },
      };
    case BenefitEditTypes.UPLOAD_BANNER_FAILURE:
      return { ...state, loading: false };
    case BenefitEditTypes.UPDATE_HAS_REGULATION_REQUEST:
      return { ...state };
    case BenefitEditTypes.UPDATE_HAS_REGULATION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          temRegulamento: action.payload.value,
        },
      };
    case BenefitEditTypes.UPDATE_REQUIRED_REGULATION_REQUEST:
      return { ...state };
    case BenefitEditTypes.UPDATE_REQUIRED_REGULATION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          temRegulamentoAceite: action.payload.value,
        },
      };
    case BenefitEditTypes.SETFILTER_COUNT_REQUEST:
      return {
        ...state,
        filterParticipantCount: action.payload.count,
      };
    case BenefitEditTypes.LOAD_TARGETPUBLIC_REQUEST:
      return { ...state };
    case BenefitEditTypes.LOAD_TARGETPUBLIC_SUCCESS:
      return {
        ...state,
        elegibleTypeOptions: action.payload.data.elegivelFiltroTipos,
      };
    case BenefitEditTypes.LOAD_TARGETPUBLIC_FAILURE:
      return { ...state };
    default:
      return state;
  }
};

export default reducer;
