import { action } from 'typesafe-actions';
import { PaymentsTypes } from '.';

export const loadCuttOffList = () => action(PaymentsTypes.LOAD_CUTTOFF_LIST_REQUEST);
export const loadCuttOffListSuccess = data =>
  action(PaymentsTypes.LOAD_CUTTOFF_LIST_SUCCESS, { data });
export const loadCuttOffListFailure = () => action(PaymentsTypes.LOAD_CUTTOFF_LIST_FAILURE);

export const createCuttOffDateRequest = data =>
  action(PaymentsTypes.CREATE_CUTTOFF_REQUEST, { data });
export const createCuttOffDateSuccess = data =>
  action(PaymentsTypes.CREATE_CUTTOFF_SUCCESS, { data });
export const createCuttOffDateFailure = () => action(PaymentsTypes.CREATE_CUTTOFF_FAILURE);

export const updateCuttOffDateRequest = data =>
  action(PaymentsTypes.UPDATE_CUTTOFF_REQUEST, { data });
export const updateCuttOffDateSuccess = data =>
  action(PaymentsTypes.UPDATE_CUTTOFF_SUCCESS, { data });
export const updateCuttOffDateFailure = () => action(PaymentsTypes.UPDATE_CUTTOFF_FAILURE);

export const loadHistoricByUpload = idDataCorte =>
  action(PaymentsTypes.LOAD_HISTORIC_BY_UPLOAD_REQUEST, { idDataCorte });
export const loadHistoricByUploadSuccess = data =>
  action(PaymentsTypes.LOAD_HISTORIC_BY_UPLOAD_SUCCESS, { data });
export const loadHistoricByUploadFailure = () =>
  action(PaymentsTypes.LOAD_HISTORIC_BY_UPLOAD_FAILURE);

export const uploadFile = (file: FormData, idDataCorte: number) =>
  action(PaymentsTypes.UPLOAD_FILE_REQUEST, { file, idDataCorte });
export const uploadFileSuccess = data => action(PaymentsTypes.UPLOAD_FILE_SUCCESS, { data });
export const uploadFileFailure = () => action(PaymentsTypes.UPLOAD_FILE_FAILURE);

export const loadHistoricFilterPDVList = idDataCorte =>
  action(PaymentsTypes.LOAD_HISTORIC_FILTER_PDV_REQUEST, { idDataCorte });
export const loadHistoricFilterPDVListSuccess = data =>
  action(PaymentsTypes.LOAD_HISTORIC_FILTER_PDV_SUCCESS, { data });
export const loadHistoricFilterPDVListFailure = () =>
  action(PaymentsTypes.LOAD_HISTORIC_FILTER_PDV_FAILURE);

export const uploadFilterPDV = data => action(PaymentsTypes.UPLOAD_FILTER_PDV_REQUEST, { data });
export const uploadFilterPDVSuccess = data =>
  action(PaymentsTypes.UPLOAD_FILTER_PDV_SUCCESS, { data });
export const uploadFilterPDVFailure = () => action(PaymentsTypes.UPLOAD_FILTER_PDV_FAILURE);

export const loadUploadPDVDetailList = (id: number) =>
  action(PaymentsTypes.LOAD_UPLOAD_PDV_DETAIL_LIST_REQUEST, { id });
export const loadUploadPDVDetailListSuccess = data =>
  action(PaymentsTypes.LOAD_UPLOAD_PDV_DETAIL_LIST_SUCCESS, { data });
export const loadUploadPDVDetailListFailure = () =>
  action(PaymentsTypes.LOAD_UPLOAD_PDV_DETAIL_LIST_FAILURE);
