import { Reducer } from 'redux';

export enum UploadDataFileChainingNetworkTypes {
  LOAD_REQUEST = '@UploadChainingNetwork/LOAD_REQUEST',
  LOAD_SUCCESS = '@UploadChainingNetwork/LOAD_SUCCESS',
  LOAD_FAILURE = '@UploadChainingNetwork/LOAD_FAILURE',
  UPLOAD_REQUEST = '@UploadChainingNetwork/UPLOAD_REQUEST',
  UPLOAD_SUCCESS = '@UploadChainingNetwork/UPLOAD_SUCCESS',
  UPLOAD_FAILURE = '@UploadChainingNetwork/UPLOAD_FAILURE',
  DELETE_REQUEST = '@UploadChainingNetwork/DELETE_REQUEST',
  DELETE_SUCCESS = '@UploadChainingNetwork/DELETE_SUCCESS',
  DELETE_FAILURE = '@UploadChainingNetwork/DELETE_FAILURE',
  RESET_REQUEST = '@UploadChainingNetwork/RESET_REQUEST',
}

export interface IUploadChainingNetworkDataFile {
  id: number;
  descricao: string;
}

export interface IUploadDataFileChainingNetworkState {
  data: IUploadChainingNetworkDataFile[];
  loading: boolean;
  error: boolean;
}

const INITIAL_STATE: IUploadDataFileChainingNetworkState = {
  data: [],
  loading: false,
  error: false,
};

const reducer: Reducer<IUploadDataFileChainingNetworkState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UploadDataFileChainingNetworkTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case UploadDataFileChainingNetworkTypes.LOAD_SUCCESS:
      return { ...state, loading: false, data: [...action.payload.data] };
    case UploadDataFileChainingNetworkTypes.RESET_REQUEST:
      return { ...state, loading: false, error: false, data: [] };
    case UploadDataFileChainingNetworkTypes.LOAD_FAILURE:
      return { ...state, loading: false };
    case UploadDataFileChainingNetworkTypes.UPLOAD_REQUEST:
      return { ...state, loading: true };
    case UploadDataFileChainingNetworkTypes.UPLOAD_SUCCESS:
      return { ...state, loading: false, data: [...state.data, { ...action.payload.data }] };
    case UploadDataFileChainingNetworkTypes.UPLOAD_FAILURE:
      return { ...state, loading: false };
    case UploadDataFileChainingNetworkTypes.DELETE_REQUEST:
      return { ...state, loading: true };
    case UploadDataFileChainingNetworkTypes.DELETE_SUCCESS:
      return { ...state, loading: false, data: [...state.data] };
    case UploadDataFileChainingNetworkTypes.DELETE_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default reducer;
