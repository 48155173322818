export enum AppMessages {
  FORM_FIELDS_REQUIRED = 'Campos obrigatórios em branco. Por favor, verifique',
  DATE_START_BIGGERTHAN_END = 'Data final deve ser maior que a data inicial',
  SUCCESS_CREATE_CAMPAIGN = 'Campanha salva com sucesso.',
  SUCCESS_CREATE_CONECTAPAY = 'Arquivo salvo com sucesso.',
  SUCCESS_CREATE_BANNER = 'Banner cadastrado com sucesso.',
  UPDATE_CREATE_BANNER = 'Banner atualizado com sucesso.',
  ERROR_CREATE_CAMPAIGN = 'Erro ao salvar campanha.',
  ERROR_CREATE_CONECTAPAY = 'Erro ao salvar arquivo conectapay.',
  ERROR_GENERIC_400 = 'Erro ao obter dados.',
  ERROR_GENERIC_FILEUPLOAD = 'Erro ao enviar arquivo.',
  ERROR_APPLYFILTER = 'Erro ao aplicar filtros.',
  SUCCESS_APPLYFILTER = 'Filtro aplicado com sucesso.',
  SUCCESS_SAVE_KPI = 'Campanha configurada com sucesso',
  SUCCESS_DELETE_KPI = 'KPI excluido com sucesso',
  SUCCESS_UPLOAD_GENERIC = 'Arquivo enviado com sucesso',
  ERROR_SAVE_GENERIC = 'Erro ao salvar dados',
  MAILING_DELETE_SUCCESS = 'Mailing excluído.',
  MAILING_UPLOAD_SUCCESS = 'Mailing salvo com sucesso.',
  INVALID_FILE_TYPE = 'Tipo de arquivo inválido.',
  SUCCESS_UPLOAD_BANNER = 'Banner enviado com sucesso.',
  ERROR_UPLOAD_BANNER = 'Erro ao enviar banner.',
  DELETE_COMMUNICATION_SUCCESS = 'Comunicação excluída com sucesso!',
  UPDATE_COMMUNICATION_SUCCESS = 'Comunicação alterada com sucesso!',
  UPLOAD_COMMUNICATION_SUCCESS = 'Arquivo de comunicação enviado com sucesso!',
  DELETE_REGULATION_SUCCESS = 'Regulamento excluído com sucesso!',
  UPDATE_REGULATION_SUCCESS = 'Regulamento alterado com sucesso!',
  UPLOAD_REGULATION_SUCCESS = 'Arquivo de regulamento enviado com sucesso!',
  UPDATE_HAS_REGULATION_SUCCESS = 'Regulamento atualizado com sucesso.',
  UPDATE_REQUIRED_REGULATION_SUCCESS = 'Aceite do regulamento atualizado com sucesso.',
  ERROR_MAILING_PROCCESS = 'Erro ao processar arquivo.',
  SUCCESS_CREATE_USER = 'Usuario cadastrado com sucesso.',
  ERROR_CREATE_USER = 'Erro ao cadastrar usuarío.',
  SUCCESS_UPLOAD_POPUP = 'Popup enviado com sucesso.',
  ERROR_UPLOAD_POPUP = 'Erro ao enviar popup.',
  SUCCESS_CREATE_POPUP = 'Popup cadastrado com sucesso.',
  UPDATE_CREATE_POPUP = 'Popup atualizado com sucesso.',
  UPLOAD_CAMPAIGN_REPORT_DELETE_SUCCESS = 'Upload excluído.',
  CAMPAIGN_REPORT_UPLOAD_SUCCESS = 'Upload salvo com sucesso.',
  ERROR_CREATE_PREMIACAO = 'Erro ao salvar premiação.',
  SUCCESS_CREATE_PREMIACAO = 'Premiação enviada com sucesso',
  SUCCESS_CREATE_SKU = 'Sku enviado com sucesso',
  ERROR_CREATE_SKU = 'Erro ao salvar sku.',
  UPDATE_CREATE_SKU = 'Sku atualizado com sucesso.',
  SUCCESS_CREATE_BENEFIT = 'Benefício salvo com sucesso.',
  ERROR_CREATE_BENEFIT = 'Erro ao salvar benefício',
  SUCCESS_CREATE_PRODUCT = 'Produto cadastrado com sucesso.',
  ERROR_CREATE_PRODUCT = 'Erro ao cadastrar produto.',
}
