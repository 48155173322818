import { action } from 'typesafe-actions';
import { ClustersTypes, ICluster } from './';

export const loadClusters = () => action(ClustersTypes.LOAD_REQUEST);

export const loadClustersByIndustries = (distribuitors: string[]) =>
  action(ClustersTypes.LOAD_REQUEST, { distribuitors });

export const loadClustersSuccess = (data: ICluster[]) =>
  action(ClustersTypes.LOAD_SUCCESS, { data });
