import { Reducer } from 'redux';

export enum ProductListTypes {
  LOAD_REQUEST = '@ProductList/LOAD_REQUEST',
  LOAD_SUCCESS = '@ProductList/LOAD_SUCCESS',
  LOAD_FAILURE = '@ProductList/LOAD_FAILURE',
}

export interface IProductListItem {
  id: number;
  nome: string;
  status: string;
}

export interface IProductListState {
  readonly data: IProductListItem[];
  readonly loading: boolean;
  readonly error: boolean;
}

const INITIAL_STATE: IProductListState = {
  data: [],
  loading: false,
  error: false,
};

const reducer: Reducer<IProductListState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ProductListTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case ProductListTypes.LOAD_SUCCESS:
      return { ...state, loading: false, error: false, data: action.payload.data.data };
    case ProductListTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true, data: [] };
    default:
      return state;
  }
};

export default reducer;
