import React, { useState, useEffect } from 'react';
import Wrap from 'components/Wrap/Wrap';
import { RouteComponentProps } from 'react-router-dom';
import { Breadcrumbs, Typography, Link, Box } from '@material-ui/core';
import Tab, { ITab } from 'components/Tab/Tab';
import { connect } from 'react-redux';
import { IUploadConectaPayReportEdit } from 'store/UploadConectaPayReport';
import UploadConectaPayReport from './UploadConectaPayReport';

interface IParams {
  id?: string | undefined;
}

interface UploadConectaPayEditProps {
  loadUploadConectaPayReport: (id: number) => void;
  resetConectaPayReport: () => void;
  uploadConectaPayReport: IUploadConectaPayReportEdit;
  loading: boolean;
}

const UploadConectaPayReportEdit: React.FC<UploadConectaPayEditProps &
  RouteComponentProps<IParams>> = ({ match, history }) => {
  const [tabs] = useState<ITab[]>([
    {
      component: <UploadConectaPayReport conectaPayReportId={Number(match.params.id)} />,
      renderLabel: (tab, index) => `Upload CSV`,
      disabled: false,
    },
  ]);

  return (
    <Box mb="1.5em">
      <Wrap>
        <Breadcrumbs>
          <Link color="inherit" onClick={() => history.push('/')}>
            Home
          </Link>
          <Link color="inherit" onClick={() => history.push('/ConectaPay')}>
            Gestão de Uploads
          </Link>
        </Breadcrumbs>
      </Wrap>

      <Tab tabs={tabs} enableNavigationButton={false} />
    </Box>
  );
};

export default connect()(UploadConectaPayReportEdit);
