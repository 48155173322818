import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import { Dispatch, bindActionCreators } from 'redux';

import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { Avatar, colors } from '@material-ui/core';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import WarningIcon from '@material-ui/icons/Warning';

import * as RealtimeActions from 'store/realtime/totalErrosAccesstage/actions';
import { IRealtimeItem } from 'store/realtime/totalErrosAccesstage';

interface ErrosAccesstageCardProps {
  loadRealtimeTotalErrosAccesstageRequest(): void;
  totalErrosAccesstage: IRealtimeItem[];
  atualizacao: string | null;
  loading: boolean;
  error: boolean;
  refresh: number;
}

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  avatar: {
    backgroundColor: colors.red[600],
    height: 45,
    width: 45,
  },
  avatarSuccess: {
    backgroundColor: colors.green[600],
    height: 45,
    width: 45,
  },
  differenceIcon: {
    color: colors.red[900],
  },
  differenceValue: {
    color: colors.red[900],
  },
});

const DashboardCard: React.FC<ErrosAccesstageCardProps> = ({
  loadRealtimeTotalErrosAccesstageRequest,
  totalErrosAccesstage,
  atualizacao,
  loading,
  error,
  refresh,
}) => {
  const classes = useStyles();

  useEffect(() => {
    loadRealtimeTotalErrosAccesstageRequest();
  }, [refresh]);

  const totalErros = useMemo(() => {
    if (totalErrosAccesstage[0]) {
      const { total } = totalErrosAccesstage[0];
      return total;
    }

    return 0;
  }, [totalErrosAccesstage, atualizacao]);

  return (
    <div>
      <Card className={classes.root} variant="outlined">
        <CardHeader
          avatar={
            <Avatar className={totalErros <= 0 ? classes.avatarSuccess : classes.avatar}>
              <CardMembershipIcon />
            </Avatar>
          }
          title={
            <Typography color="textSecondary" gutterBottom variant="h6">
              ACCESSTAGE
            </Typography>
          }
          subheader="Integrações com erro de processamento no parceiro"
        />
        <CardContent>
          <Typography color="textPrimary" variant="h3">
            {loading ? <CircularProgress /> : error ? '' : totalErros}
          </Typography>
          <Grid container justify="space-between" spacing={1}>
            <Grid item>
              <Typography color="textSecondary" variant="caption">
                {error && (
                  <>
                    <WarningIcon /> Ops, não foi possível buscar as informações, tente novamente.
                  </>
                )}
                {atualizacao && `Última atualização: ${atualizacao}`}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  totalErrosAccesstage: state.realtimeTotalErrosAccesstage.data,
  atualizacao: state.realtimeTotalErrosAccesstage.atualizacao,
  loading: state.realtimeTotalErrosIntegracaoBoletos.loading,
  error: state.realtimeTotalErrosIntegracaoBoletos.error,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...RealtimeActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(DashboardCard);
