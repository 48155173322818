import React from 'react';
import { IMailing } from 'store/benefitMailing';
import { TABLE_STYLE } from 'common/Constants';
import { IconButton } from '@material-ui/core';
import { Delete, CloudDownload } from '@material-ui/icons';
import Utils from 'common/Utils';
import { Dispatch, bindActionCreators } from 'redux';
import * as BenefitMailingActions from 'store/benefitMailing/actions';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import GenericTable from 'components/GenericTable/GenericTable';

interface MailingListProps {
  mailings: IMailing[];
  deleteBenefitMailing(deleteUrl: string): void;
  benefitId?: number;
  bannerId?: number;
  loading: boolean;
}

const MailingListBenefit: React.FC<MailingListProps> = ({
  mailings,
  deleteBenefitMailing,
  benefitId,
  bannerId,
  loading,
}) => {
  const location = useLocation().pathname;

  const handleOnDelete = (mailingId: number) => {
    const deleteUrl = `beneficio/${benefitId}/mailing/${mailingId}`;
    deleteBenefitMailing(deleteUrl);
  };

  const columns = location.includes('benefit')
    ? [
        {
          title: 'Data envio',
          render: rowData => {
            return `${Utils.setData(rowData.dataUpload)}`;
          },
          cellStyle: { ...TABLE_STYLE },
        },
        {
          title: 'Sucesso',
          render: rowData => {
            return rowData.sucesso === true ? 'Sim' : 'Não';
          },
          cellStyle: { ...TABLE_STYLE },
        },
        {
          title: 'Erro',
          render: rowData => {
            return rowData.erro === true ? 'Sim' : 'Não';
          },
          cellStyle: { ...TABLE_STYLE },
        },
        {
          title: 'Processado',
          render: rowData => {
            return rowData.processado === true ? 'Sim' : 'Não';
          },
          cellStyle: { ...TABLE_STYLE },
        },
        {
          title: 'Ativo',
          render: rowData => {
            return rowData.ativo === 1 ? 'Sim' : 'Não';
          },
          cellStyle: { ...TABLE_STYLE },
        },
        {
          title: 'Ações',
          cellStyle: {
            width: '15%',
            ...TABLE_STYLE,
          },
          render: ({ id, downloadUrl }) => (
            <>
              <IconButton disabled={loading} onClick={() => window.open(downloadUrl)}>
                <CloudDownload />
              </IconButton>
              <IconButton disabled={loading} onClick={() => handleOnDelete(id)}>
                <Delete fontSize="small" />
              </IconButton>
            </>
          ),
        },
      ]
    : [
        {
          title: 'Data envio',
          render: rowData => {
            return `${Utils.setData(rowData.dataUpload)}`;
          },
          cellStyle: { ...TABLE_STYLE },
        },

        {
          title: 'Nome do Arquivo',
          render: rowData => {
            return `${rowData.nomeArquivo}`;
          },
          cellStyle: { ...TABLE_STYLE },
        },
        {
          title: 'Ações',
          cellStyle: {
            width: '15%',
            ...TABLE_STYLE,
          },
          render: ({ id, downloadUrl }) => (
            <>
              <IconButton disabled={loading} onClick={() => window.open(downloadUrl)}>
                <CloudDownload />
              </IconButton>
              <IconButton disabled={loading} onClick={() => handleOnDelete(id)}>
                <Delete fontSize="small" />
              </IconButton>
            </>
          ),
        },
      ];

  return mailings.length > 0 ? (
    <GenericTable
      title="Seleções Customizadas"
      isLoading={false}
      data={mailings}
      options={{
        paging: false,
        search: false,
      }}
      columns={columns}
    />
  ) : null;
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(BenefitMailingActions, dispatch);

export default connect(null, mapDispatchToProps)(MailingListBenefit);
