import React, { useEffect, useState } from 'react';
import {
  Box,
  Paper,
  makeStyles,
  Theme,
  createStyles,
  TextField,
  Grid,
  FormControl,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from '@material-ui/core';
import { IFilterItemRequest, ElegiblesType } from 'common/Constants';

type FilterType = 'regions' | 'distribuitors' | 'segments' | 'clusters' | 'profiles';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginBottom: theme.spacing(1),
    },
    fullWidth: {
      width: '100%',
    },
    spacing: {
      marginBottom: theme.spacing(2),
    },
    listBox: {
      height: '260px',
      overflow: 'auto',
      minHeight: '260px',
    },
  }),
);

interface ElegiblesFilterItemProps {
  isLoading: boolean;
  searchTitle: string;
  itemName: FilterType;
  setState: any;
  state: IFilterItemRequest[];
  forKey: keyof IFilterItemRequest;
  onPost(): void;
}

const ElegiblesFilterItem: React.FC<ElegiblesFilterItemProps> = ({
  isLoading = false,
  searchTitle,
  setState,
  itemName,
  state,
  forKey,
  onPost,
}) => {
  const classes = useStyle();

  const [searchValue, setSearchValue] = useState('');
  const [selectAll, setSelectAll] = useState(true);
  const [stateEffect, setStateEffect] = useState(0);

  const handleOnChange = (item: IFilterItemRequest, checked: boolean) => {
    setState([
      ...state.map(e => {
        if (e[forKey] === item[forKey]) {
          return { ...e, selected: checked };
        } else {
          return { ...e };
        }
      }),
    ]);

    setStateEffect(stateEffect + 1);
  };

  const handleSelectAll = (checked: boolean) => {
    setSelectAll(checked);
    setState([
      ...state.map(e => {
        return {
          ...e,
          selected: checked,
        };
      }),
    ]);
    setStateEffect(stateEffect + 1);
  };

  useEffect(() => {
    onPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateEffect]);

  useEffect(() => {
    const listItems = document.querySelectorAll(`#box_${itemName} label[title]`);

    if (listItems.length > 0) {
      listItems.forEach((e: any) => {
        if (e.textContent?.toUpperCase()?.indexOf(searchValue?.toUpperCase()) > -1) {
          e.classList.remove('d-none');
        } else {
          e.classList.add('d-none');
        }
      });
    }
  }, [searchValue, itemName]);

  return (
    <Grid item={true} md={4}>
      <FormControl fullWidth={true} className={classes.formControl}>
        <TextField
          id={searchValue}
          type="search"
          value={searchValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
          label={searchTitle}
          variant="filled"
          margin="dense"
        />
      </FormControl>
      <FormControl fullWidth={true} className={classes.formControl}>
        <FormControlLabel
          disabled={state.length === 0}
          control={
            <Checkbox
              checked={selectAll && state.filter(e => !e.selected).length === 0}
              onChange={(e, checked) => handleSelectAll(checked)}
              value={selectAll}
              color="primary"
            />
          }
          label="Selecionar todos"
        />
      </FormControl>
      <Paper className={classes.listBox}>
        <Box p="1.5em" id={`box_${itemName}`}>
          {isLoading ? (
            <CircularProgress color="primary" />
          ) : (
            state.map((item: IFilterItemRequest, index: number) => {
              return (
                <FormControlLabel
                  key={index}
                  title={item.nome}
                  className={classes.fullWidth}
                  control={
                    <Checkbox
                      name={item.nome}
                      id={`${item.id}_${index}`}
                      checked={item.selected}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                        handleOnChange(item, checked)
                      }
                      value={item.nome}
                      color="secondary"
                    />
                  }
                  label={item.nome}
                />
              );
            })
          )}
        </Box>
      </Paper>
    </Grid>
  );
};

export default ElegiblesFilterItem;
