import { action } from 'typesafe-actions';
import { ParticipantExemptionFileTypes } from '.';

export const loadParticipantExemptionFile = () =>
  action(ParticipantExemptionFileTypes.LOAD_REQUEST);
export const loadUploadFileSuccess = data =>
  action(ParticipantExemptionFileTypes.LOAD_SUCCESS, { data });
export const loadUploadFileFailure = () => action(ParticipantExemptionFileTypes.LOAD_FAILURE);

export const loadFileExemptionDetails = (id: number) =>
  action(ParticipantExemptionFileTypes.LOAD_DETAILS_REQUEST, { id });
export const loadFileExemptionDetailsSuccess = data =>
  action(ParticipantExemptionFileTypes.LOAD_DETAILS_SUCCESS, { data });
export const loadFileExemptionDetailsFailure = () =>
  action(ParticipantExemptionFileTypes.LOAD_DETAILS_FAILURE);

export const uploadParticipantExemptionCsv = (file: FormData) =>
  action(ParticipantExemptionFileTypes.UPLOAD_REQUEST, { file });
export const participantExemptionCsvSuccess = data =>
  action(ParticipantExemptionFileTypes.UPLOAD_SUCCESS, { data });
export const participantExemptionCsvFailure = () =>
  action(ParticipantExemptionFileTypes.UPLOAD_FAILURE);
