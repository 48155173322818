import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import { loadClustersSuccess } from './actions';
import { showToastr } from 'store/toastr/actions';
import { AppMessages } from 'common/AppMessages.enum';

export function* loadClustersByIndustries(action) {
  try {
    const { data } = yield call(
      API.get,
      'shared/clusters',
      action.payload === undefined ? '' : action.payload.distribuitors,
    );
    yield put(loadClustersSuccess(data));
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
  }
}

export function* loadClusters() {
  try {
    const { data } = yield call(API.get, 'shared/clusters', {});
    yield put(loadClustersSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
  }
}
