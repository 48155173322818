import { action } from 'typesafe-actions';
import { UploadCampaignFileTypes } from '.';
import { MODULE_TYPE } from 'common/Constants';

export const loadUploadFile = (id: number) => action(UploadCampaignFileTypes.LOAD_REQUEST, { id });
export const loadUploadFileSuccess = data => action(UploadCampaignFileTypes.LOAD_SUCCESS, { data });
export const loadUploadFileFailure = () => action(UploadCampaignFileTypes.LOAD_FAILURE);

export const uploadCampaingCsv = (
  uploadUrl: string,
  data: FormData,
  callbackAction: any,
  id: number,
  type: number,
) => action(UploadCampaignFileTypes.UPLOAD_REQUEST, { uploadUrl, data, callbackAction, id, type });
export const uploadCampaingCsvSuccess = data =>
  action(UploadCampaignFileTypes.UPLOAD_SUCCESS, { data });
export const uploadCampaingCsvFailure = () => action(UploadCampaignFileTypes.UPLOAD_FAILURE);

export const deleteUploadCampaignCsv = (deleteUrl: string) =>
  action(UploadCampaignFileTypes.DELETE_REQUEST, { deleteUrl });
export const deleteUploadCampaignCsvSuccess = data =>
  action(UploadCampaignFileTypes.DELETE_SUCCESS, { data });
export const deleteUploadCampaignCsvFailure = () => action(UploadCampaignFileTypes.DELETE_FAILURE);
