import React from 'react';
import InputMask from 'react-input-mask';

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function TelephoneMask(props: TextMaskCustomProps) {
  const { inputRef, ...other } = props;

  React.useEffect(() => {
    console.log('props', props);
    console.log('...other', other);
  }, []);
  return <InputMask {...other} mask="(99) 99999-9999" maskChar=" " />;
}

export default TelephoneMask;
