import React, { Dispatch, SetStateAction } from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@material-ui/core';

export interface IModalConfirmationState {
  open: boolean;
  onConfirm: any;
}

interface ModalConfirmationProps {
  title: string;
  content: any;
  state: IModalConfirmationState;
  setState: Dispatch<SetStateAction<IModalConfirmationState>>;
  size?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: theme.typography.fontFamily,
    },
    label: {
      fontWeight: 'bolder',
      fontSize: '14px',
      marginBottom: theme.spacing(1),
      display: 'block',
    },
    spacingBottom: {
      marginBottom: theme.spacing(2),
    },
  }),
);

const ModalConfirmation: React.FC<ModalConfirmationProps> = ({
  state,
  setState,
  title,
  content,
  size,
}) => {
  const classes = useStyle();

  const handleOnCofirm = () => {
    if (state.onConfirm) state.onConfirm();
    setState({ ...state, open: false, onConfirm: null });
  };

  return (
    <Dialog className={classes.root} maxWidth={size || 'xs'} fullWidth={true} open={state.open}>
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent dividers={true}>{content}</DialogContent>
      <DialogActions>
        <Button onClick={() => setState({ ...state, open: false, onConfirm: null })}>
          Cancelar
        </Button>
        <Button onClick={handleOnCofirm} variant="contained" color="primary">
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalConfirmation;
