import { Reducer } from 'redux';

export enum SegmentTypes {
  LOAD_REQUEST = '@segment/LOAD_REQUEST',
  LOAD_SUCCESS = '@segment/LOAD_SUCCESS',
  LOAD_FAILURE = '@segment/LOAD_FAILURE',
}

export interface ISegment {
  id: number;
  nome: string;
}

export interface ISegmentState {
  data: ISegment[];
  loading: boolean;
  error: boolean;
}

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
};

const reducer: Reducer<ISegmentState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SegmentTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case SegmentTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data.atividades,
      };
    case SegmentTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true, data: { ...INITIAL_STATE.data } };
    default:
      return state;
  }
};

export default reducer;
