import { Reducer } from 'redux';
import Utils from 'common/Utils';

export enum BenefitListTypes {
  LOAD_REQUEST = '@BenefitList/LOAD_REQUEST',
  LOAD_SUCCESS = '@BenefitList/LOAD_SUCCESS',
  LOAD_FAILURE = '@BenefitList/LOAD_FAILURE',
}

export interface IBenefitListItem {
  id: number;
  nome: string;
  resumoCard: string;
  descricaoDosGanhos: string;
  urlRedirect: string;
  vigenciaDe: string;
  vigenciaAte: string;
  ordem: number;
}

export interface IBenefitListState {
  readonly data: IBenefitListItem[];
  readonly loading: boolean;
  readonly error: boolean;
}

const INITIAL_STATE: IBenefitListState = {
  data: [],
  loading: false,
  error: false,
};

const reducer: Reducer<IBenefitListState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BenefitListTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case BenefitListTypes.LOAD_SUCCESS:
      const formated = action.payload.data.map((e: IBenefitListItem) => {
        return {
          ...e,
          vigenciaDe: Utils.setData(e.vigenciaDe),
          vigenciaAte: Utils.setData(e.vigenciaAte),
        };
      });
      return { ...state, loading: false, error: false, data: formated };
    case BenefitListTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true, data: [] };
    default:
      return state;
  }
};

export default reducer;
