import { makeStyles, createStyles } from '@material-ui/core';

export const useStylePreview = makeStyles(() =>
  createStyles({
    boxPreviewWeb: {
      position: 'absolute',
      top: '50%',
      overflowY: 'auto',
      left: '50%',
      height: 'auto',
      transform: 'translate(-50%, -50%)',
      boxSizing: 'border-box',
    },
    boxSizeSmall: {
      width: '400px',
    },
    boxSizeMedium: {
      width: '600px',
    },
    boxSizeBig: {
      width: '800px',
    },
    boxPreviewMobile: {
      position: 'absolute',
      top: '50%',
      overflowY: 'auto',
      left: '50%',
      height: 'auto',
      width: '360px',
      maxWidth: '330px',
      transform: 'translate(-50%, -50%)',
      boxSizing: 'border-box',
    },
    iconClose: {
      position: 'absolute',
      top: '15px',
      right: '30px',
    },
    iconAlert: {
      position: 'relative',
      zIndex: 1,
      top: '45px',
      left: '35px',
    },
    contentBox: {
      position: 'relative',
      backgroundColor: '#ffffff',
      padding: '80px 45px 45px 45px',
      borderRadius: '10px',
    },
    contentBoxMobile: {
      position: 'relative',
      backgroundColor: '#ffffff',
      padding: '60px 45px 45px 45px',
      borderRadius: '10px',
    },
    fontParagraph: {
      color: '#21346c',
      fontSize: '14px',
      fontFamily: 'Arial, Helvetica, sans-serif;',
      paddingBottom: '10px',
    },
    modalHeader: {
      marginBottom: '10px',
      color: '#21346c',
      fontSize: '22px',
      fontFamily: 'Arial, Helvetica, sans-serif;',
      fontWeight: 'bolder',
    },
    modalHeaderMobile: {
      marginBottom: '10px',
      color: '#21346c',
      fontSize: '20px',
      fontFamily: 'Arial, Helvetica, sans-serif;',
      fontWeight: 'bolder',
    },
    modalContent: {
      fontFamily: 'Arial, Helvetica, sans-serif;',
      paddingBottom: '10px',
      fontSize: '14px',
      color: '#63717a',
      margin: 0,
      maxHeight: 55,
      marginBottom: '5px',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: '10px',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#b6bbc7',
        borderRadius: '10px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#21346c',
      },
    },
    carouselItem: {
      width: 300,
      marginRight: '16px',
    },
    carouselBox: {
      marginTop: 0,
      overflowY: 'inherit',
      '&> div:nth-child(2)': {
        right: '-10px',
        top: '110px',
      },
      '&> div:last-child': {
        left: '-25px',
        top: '100px',
      },
    },
    carouselArrow: {
      backgroundColor: '#381cad !important',
      '&:hover': {
        backgroundColor: '#381cad !important',
        filter: 'unset',
      },
    },
    // mediaDefault: {
    //   background: `#29168f no-repeat center 25% url(${logoConectaP})`,
    //   backgroundSize: '70%',
    //   height: 300,
    // },
    cardBox: {
      padding: '0 5px 0 0',
      height: '90px',
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      boxSizing: 'border-box',
      marginBottom: '20px',
      marginTop: '5px',
      '&::-webkit-scrollbar': {
        width: '10px',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#b6bbc7',
        borderRadius: '10px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#21346c',
      },
    },
    cardTitle: {
      fontFamily: 'Arial, Helvetica, sans-serif;',
      fontWeight: 'bolder',
      marginTop: 0,
      marginBottom: 10,
      fontSize: '17px',
      color: '#63717a',
    },
    cardDescription: {
      fontFamily: 'Arial, Helvetica, sans-serif;',
      marginTop: 0,
      marginBottom: 10,
      fontSize: '14px',
      color: '#63717a',
    },
    chooseBtn: {
      height: '40px',
      lineHeight: '40px',
      padding: '0 20px',
      fontWeight: 'bolder',
      letterSpacing: '0px',
      wordSpacing: '1px',
      display: 'flex',
      justifyContent: 'space-between',
      borderRadius: '20px',
      margin: 'auto',
      border: 0,
      fontSize: 12,
      color: '#ffffff',
      background: 'linear-gradient(45deg, #381cad, #8f23d6)',
      fontFamily: 'Arial, Helvetica, sans-serif;',
    },
  }),
);
