import React, { Dispatch, SetStateAction, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import { Dispatch as ReduxDispatch } from 'redux';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
  Theme,
  Grid,
  FormControl,
  Divider,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import * as AutomaticCreditworkActions from 'store/counting/automaticCredit/actions';
import {
  IStatusItem,
  IAutomaticCreditDetailItem,
  INITIAL_STATE,
  IAutomaticCreditApproval,
  IAutomaticCreditParams,
} from 'store/counting/automaticCredit';
import Utils from 'common/Utils';

interface ModalApprovingAutomaticCredit {
  loading: boolean;
  title: string;
  state: boolean;
  setState: Dispatch<SetStateAction<boolean>>;
  size?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
  idApuracaoLote: number;
  statusList?: IStatusItem[];
  dataDetailRequest: IAutomaticCreditDetailItem;
  loadAutomaticCreditDetailRequest: (idApuracaoLote: number) => void;
  approveAutomaticCreditRequest: (data: IAutomaticCreditApproval) => void;
  approved: boolean;
  loadChainingParams: IAutomaticCreditParams;
  loadPendingAutomaticCreditRequest: (data: IAutomaticCreditParams) => void;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: theme.typography.fontFamily,
      height: '100vh',
    },
    label: {
      fontWeight: 'bolder',
      fontSize: '10px',
      marginBottom: theme.spacing(1),
      display: 'block',
    },
    spacingBottom: {
      marginBottom: theme.spacing(2),
    },
  }),
);

const ApprovingModal: React.FC<ModalApprovingAutomaticCredit> = ({
  loading,
  state,
  setState,
  title,
  size,
  idApuracaoLote,
  statusList,
  loadAutomaticCreditDetailRequest,
  dataDetailRequest,
  approveAutomaticCreditRequest,
  approved,
  loadChainingParams,
  loadPendingAutomaticCreditRequest,
}) => {
  const classes = useStyle();
  const [status, setStatus] = useState(0);
  const [stateStatusList, setStateStatusList] = useState<IStatusItem[]>([]);
  const [dataDetail, setDataDetail] = useState<IAutomaticCreditDetailItem>(
    INITIAL_STATE.dataDetail,
  );
  const [denyingReason, setDenyingReason] = useState('');
  const [approvalModal, setApprovalModal] = useState(false);
  const [denyingModal, setDenyingModal] = useState(false);
  const [observacao, setObservacao] = useState('');

  useEffect(() => {
    if (idApuracaoLote !== 0) loadAutomaticCreditDetailRequest(idApuracaoLote);
  }, [idApuracaoLote]);

  useEffect(() => {
    setDataDetail(dataDetailRequest);
    setStatus(dataDetailRequest.statusId);
  }, [dataDetailRequest]);

  useEffect(() => {
    setApprovalModal(false);
    setDenyingModal(false);
    setState(false);
    loadPendingAutomaticCreditRequest(loadChainingParams);
  }, [approved]);

  const handleConfirmation = () => {
    setApprovalModal(true);
  };

  const handleChange = (prop: keyof IAutomaticCreditDetailItem) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDataDetail({ ...dataDetail, [prop]: event.target.value });
  };

  const handleApproval = approved => {
    let data = {
      idApuracaoLote: idApuracaoLote,
      observacao: approved ? dataDetail.observacao : denyingReason,
      aprovado: approved,
    };

    approveAutomaticCreditRequest(data);
    setApprovalModal(false);
    setDenyingModal(false);
  };

  return (
    <Dialog className={classes.root} maxWidth={size || 'xs'} fullWidth={true} open={state}>
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent dividers={true}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormControl fullWidth={true}>
              <TextField
                disabled={true}
                value={dataDetail.nomeCampanha}
                variant="outlined"
                label="Nome da Campanha"
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth={true}>
              <TextField
                disabled={true}
                value={dataDetail.tipoApuracao}
                variant="outlined"
                label="Tipo de Apuração"
              />
            </FormControl>
          </Grid>

          <Grid item xs={2}>
            <FormControl fullWidth={true}>
              <TextField
                disabled={true}
                value={
                  dataDetail.dataAberturaChamado
                    ? Utils.setData(dataDetail.dataAberturaChamado)
                    : dataDetail.dataAberturaChamado
                }
                variant="outlined"
                label="Data Abertura Chamado"
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth={true}>
              <TextField
                disabled={true}
                value={Utils.formatDecimalToAPI(dataDetail.valorTotalEmPontos)}
                variant="outlined"
                label="Valor Total em pontos"
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth={true}>
              <TextField
                disabled={true}
                value={dataDetail.valorTotalEmReais}
                variant="outlined"
                label="Valor total em reais"
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth={true}>
              <TextField
                disabled={true}
                value={dataDetail.aprovado ? 'Aprovado' : 'Aguardando'}
                variant="outlined"
                label="Status"
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h6">Quantidade total de participante</Typography>
          </Grid>
          <Grid item xs={1}>
            <TextField
              disabled={true}
              value={dataDetail.quantidadeTotalParticipantes}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Divider light />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth={true}>
              <TextField
                disabled={dataDetail.observacao ? true : false}
                value={dataDetail.observacao}
                variant="outlined"
                label="Observação"
                onChange={handleChange('observacao')}
              />
            </FormControl>
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {dataDetail.aprovado ? (
              <Typography variant="h6">
                Registro moderado por:{' '}
                <small>
                  {dataDetail.moderadoPor} em {Utils.setData(dataDetail.dataModeracao)}
                </small>
              </Typography>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          color="inherit"
          onClick={() => setDenyingModal(true)}
          disabled={dataDetail.aprovado || loading}
        >
          Rejeitar
        </Button>
        <Button color="secondary" onClick={() => setState(false)}>
          Cancelar
        </Button>
        <Button
          color="primary"
          onClick={() => handleConfirmation()}
          disabled={dataDetail.aprovado || loading}
        >
          {loading ? 'Requisitando...' : 'Aprovar'}
        </Button>
      </DialogActions>
      <Dialog className={classes.root} maxWidth={'xs'} fullWidth={true} open={approvalModal}>
        <DialogTitle id="approvalConfirmation">Tem certeza?</DialogTitle>
        <DialogContent dividers={true}>
          <Typography variant="h6">Você tem certeza de que deseja APROVAR esse pedido?</Typography>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setApprovalModal(false)}>
            Cancelar
          </Button>
          <Button color="primary" onClick={() => handleApproval(true)} disabled={loading}>
            {loading ? 'Requisitando...' : 'Confirmar'}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog className={classes.root} maxWidth={'xs'} fullWidth={true} open={denyingModal}>
        <DialogTitle id="approvalConfirmation">Tem certeza?</DialogTitle>
        <DialogContent dividers={true}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">
                Você tem certeza de que deseja REPROVAR/CANCELAR esse pedido? Essa ação não poderá
                ser revertida
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth={true}>
                <TextField
                  id="denying_reason"
                  label="Justifique o motivo"
                  multiline
                  rows={5}
                  onChange={e => setDenyingReason(e.target.value)}
                  value={denyingReason}
                  variant="outlined"
                  inputProps={{
                    maxLength: 500,
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setDenyingModal(false)}>
            Cancelar
          </Button>
          <Button
            color="primary"
            onClick={() => handleApproval(false)}
            disabled={denyingReason === '' ? true : false}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    dataDetailRequest: state.automaticCredit.dataDetail,
    statusList: state.automaticCredit.statusList,
    approved: state.automaticCredit.approved,
    loading: state.automaticCredit.loading,
    error: state.automaticCredit.error,
    success: state.automaticCredit.success,
  };
};

const mapDispatchToProps = (dispatch: ReduxDispatch) => {
  return {
    loadAutomaticCreditDetailRequest: (idApuracaoLote: number) => {
      dispatch(AutomaticCreditworkActions.loadAutomaticCreditDetailRequest(idApuracaoLote));
    },
    approveAutomaticCreditRequest: (data: IAutomaticCreditApproval) => {
      dispatch(AutomaticCreditworkActions.approveAutomaticCreditRequest(data));
    },
    loadPendingAutomaticCreditRequest: (data: IAutomaticCreditParams) => {
      dispatch(AutomaticCreditworkActions.loadPendingAutomaticCreditRequest(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApprovingModal);
