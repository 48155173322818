import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import IntervalTimer from 'react-interval-timer';
import Highcharts from 'highcharts';
import CadastrosCard from 'components/Realtime/CadastrosCard';
import AceiteTermosCard from 'components/Realtime/AceiteTermosCard';
import UltimosCadastrosCard from 'components/Realtime/UltimosCadastrosCard';
import UltimosCancelamentosCard from 'components/Realtime/UltimosCancelamentosCard';

import MeiosPagamentoCard from 'components/Realtime/MeiosPagamentoCard';
import PrimeirosAcessosClusterCard from 'components/Realtime/PrimeirosAcessosClusterCard';

import ErrosCadastroCard from 'components/Realtime/ErrosCadastroCard';
import ErrosAccesstageCard from 'components/Realtime/ErrosAccesstageCard';
import ErrosCancelamentosCard from 'components/Realtime/ErrosCancelamentosCard';
import ErrosIntegracaoBoletosCard from 'components/Realtime/ErrosIntegracaoBoletosCard';

import AtivacoesCard from 'components/Realtime/AtivacoesCard';
import AtualizacoesCard from 'components/Realtime/AtualizacoesCard';
import CancelamentosCard from 'components/Realtime/CancelamentosCard';
import VolumeCard from 'components/Realtime/VolumeCard';

const Realtime = props => {
  Highcharts.setOptions({
    colors: ['#00bff3', '#381cad', '#fb8c00', '#43a047', '#e53935', '#2E0554', '#A5D531'],
    lang: {
      decimalPoint: ',',
      thousandsSep: '.',
    },
  });

  const [refreshCount, setRefreshCount] = useState(0);

  return (
    <>
      <IntervalTimer
        timeout={300000}
        callback={() => {
          setRefreshCount(() => refreshCount + 1);
        }}
        enabled={true}
        repeat={true}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3} spacing={2}>
          <ErrosAccesstageCard refresh={refreshCount} />
        </Grid>
        <Grid item xs={12} sm={3} spacing={2}>
          <ErrosCancelamentosCard refresh={refreshCount} />
        </Grid>
        <Grid item xs={12} sm={3} spacing={2}>
          <ErrosCadastroCard refresh={refreshCount} />
        </Grid>
        <Grid item xs={12} sm={3} spacing={2}>
          <ErrosIntegracaoBoletosCard refresh={refreshCount} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3} spacing={2}>
          <CadastrosCard refresh={refreshCount} />
        </Grid>
        <Grid item xs={12} sm={3} spacing={2}>
          <AceiteTermosCard refresh={refreshCount} />
        </Grid>
        <Grid item xs={12} sm={3} spacing={2}>
          <PrimeirosAcessosClusterCard refresh={refreshCount} />
        </Grid>
        <Grid item xs={12} sm={3} spacing={2}>
          <MeiosPagamentoCard refresh={refreshCount} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} spacing={2}>
          <UltimosCadastrosCard refresh={refreshCount} />
        </Grid>
        <Grid item xs={12} sm={6} spacing={2}>
          <UltimosCancelamentosCard refresh={refreshCount} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3} spacing={2}>
          <AtivacoesCard refresh={refreshCount} />
        </Grid>
        <Grid item xs={12} sm={3} spacing={2}>
          <AtualizacoesCard refresh={refreshCount} />
        </Grid>
        <Grid item xs={12} sm={3} spacing={2}>
          <CancelamentosCard refresh={refreshCount} />
        </Grid>
        <Grid item xs={12} sm={3} spacing={2}>
          <VolumeCard refresh={refreshCount} />
        </Grid>
      </Grid>
    </>
  );
};

export default Realtime;
