import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import Utils from 'common/Utils';
import {
  loadPaymentMethodSuccess,
  loadPaymentMethodFailure,
  generatePaymentMethodReportSuccess,
  generatePaymentMethodReportFailure,
} from './actions';

export async function getPowerBIAcumuloReport() {
  const result = await API.get(`/Relatorio/Acumulo`);
  return result;
}

export async function getPowerBIResgateReport() {
  const result = await API.get(`/Relatorio/Resgates`);
  return result;
}

export async function getPowerBIAdesaoReport() {
  const result = await API.get(`/Relatorio/Adesao`);
  return result;
}

export async function getPowerBIPerformanceReport() {
  const result = await API.get(`/Relatorio/Performance`);
  return result;
}

export async function getPowerBIPaymentReport() {
  const result = await API.get(`/Relatorio/Pagamentos`);
  return result;
}

export async function getPowerBIMarketplaceReport() {
  const result = await API.get(`/Relatorio/Marketplace`);
  return result;
}

export function* loadPaymentMethodRequest() {
  try {
    const { data } = yield call(API.get, 'Shared/TiposPagamento');

    yield put(loadPaymentMethodSuccess(data.tiposPagament));
  } catch (error) {
    yield put(loadPaymentMethodFailure());
  }
}

export function* generatePaymentMethodReportRequest(action) {
  try {
    const { data } = yield call(API.post, 'participantes/csvMeiosPgto', action.payload.data);

    yield put(generatePaymentMethodReportSuccess(data));
  } catch (error) {
    yield put(generatePaymentMethodReportFailure());
  }
}

///RECEIVABLES

export function* loadPaymentReceivablesMethodRequest() {
  try {
    const { data } = yield call(API.get, 'Shared/TipoPagamentoDebitoRecebiveis');

    yield put(loadPaymentMethodSuccess(data.tiposPagament));
  } catch (error) {
    yield put(loadPaymentMethodFailure());
  }
}

export function* generatePaymentReceivablesMethodReportRequest(action) {
  try {
    const { data } = yield call(API.post, 'participantes/csvAdesaoConectaPay', action.payload.data);

    yield put(generatePaymentMethodReportSuccess(data));
  } catch (error) {
    yield put(generatePaymentMethodReportFailure());
  }
}
