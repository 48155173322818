import React, { useEffect, useState, useCallback } from 'react';
import Wrap from 'components/Wrap/Wrap';
import {
  Breadcrumbs,
  Typography,
  Link,
  Grid,
  TextField,
  Box,
  Paper,
  createStyles,
  makeStyles,
  Theme,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import cn from 'classnames';
import Api from '../../../common/Api';
import * as PlanManagementActions from 'store/planManagement/actions';
import * as RegionsActions from 'store/regions/actions';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import { Dispatch, bindActionCreators } from 'redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Utils from 'common/Utils';
import { Timeline } from '@material-ui/lab';
import TimelineItemComponent from './TimelineItemComponent';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '25ch',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    buttonActions: {
      padding: theme.spacing(1),
      minWidth: 120,
      float: 'right',
      '& > button': {
        marginRight: 0,
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    button: {
      alignSelf: 'center',
      textAlign: 'right',
    },
  }),
);

interface IParams {
  id?: string | undefined;
}
interface IDadosParticipante {
  nome: string;
  razaoSocial: string;
  email: string;
  cpf: string;
  dataNascimento: string;
  endereco: string;
  numero: string;
  complemento: string;
  bairro: string;
  cidade: string;
  cep: string;
  uf: string;
  telefone1: string;
  telefone2: string;
  distribuidorCnpj: string;
  photoUrl: string;
  categoria: string;
  superior: string;
  distribuidorNome: string;
  apelido: string;
  ativo: boolean;
  dataAtivacao: string;
  dataDesativacao: string;
  dataPrimeiraAdesao: string;
  motivoDesativacao: string;
  tipoDeAtividade: string;
  funcao: string;
  codigoSap: string;
  participantePlanoMensalidadePrime: {
    descricao: string;
    id: number;
    valor: number;
  };
}

interface IPlanParams {
  id: any;
  valor: number;
  descricao: string;
}
interface IPlanProps {
  loadAllPlanAvaliable(): void;
  loadRegions(): void;
  planList;
  ufState;
}

export const dadosParticipante: any = {
  nome: '',
  razaoSocial: '',
  email: '',
  cpf: '',
  dataNascimento: '',
  endereco: '',
  numero: '',
  complemento: '',
  bairro: '',
  cidade: '',
  cep: '',
  uf: '',
  telefone1: '',
  telefone2: '',
  distribuidorCnpj: '',
  photoUrl: '',
  categoria: '',
  superior: '',
  distribuidorNome: '',
  apelido: '',
  ativo: false,
  dataAtivacao: '',
  dataDesativacao: '',
  dataPrimeiraAdesao: '',
  motivoDesativacao: '',
  tipoDeAtividade: '',
  funcao: '',
  codigoSap: '',
  participantePlanoMensalidadePrime: {
    descricao: '',
    id: 0,
    valor: 0,
  },
};

export const allPlanAvaliable: any = {
  id: '',
  valor: 0,
  descricao: '',
  ativo: false,
};

const ParticipantDetails: React.FC<IDadosParticipante &
  RouteComponentProps<IParams> &
  IPlanParams &
  IPlanProps> = ({ match, history, loadAllPlanAvaliable, planList, loadRegions, ufState }) => {
  const classes = useStyle();

  const [participant, setParticipant] = useState(dadosParticipante);
  const [allActivePlan, setAllActivePlan] = useState<IPlanParams[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingChangePlan, setLoadingChangePlan] = useState(false);
  const [loadingTimeline, setLoadingTimeline] = useState(true);
  const [timeline, setTimeline] = useState<
    {
      dataHistorico: string;
      descricao: string;
      tipoHistorico: string;
    }[]
  >([]);

  const loadTimeline = useCallback(async () => {
    const response = await Api.get(`/participantes/${match.params.id}/timeline`);
    setLoadingTimeline(true);

    if (response !== undefined && response.status === 200) setTimeline(response.data.data);
    setLoadingTimeline(false);

    // eslint-disable-next-line
  }, [match.params.id]);

  const loadParticipant = useCallback(async () => {
    setLoading(true);
    const response = await Api.get(`/participantes/${match.params.id}/detalhes`);
    if (response !== undefined && response.status === 200) setParticipant(response.data.data);
    setLoading(false);
  }, [match.params.id]);

  useEffect(() => {
    loadParticipant();
    loadAllPlanAvaliable();
    loadTimeline();
  }, [loadParticipant, loadAllPlanAvaliable, loadTimeline]);

  useEffect(() => {
    loadRegions();
  }, [loadRegions]);

  useEffect(() => {
    if (!!planList.length) setAllActivePlan(planList.filter(item => item.ativo === true));
  }, [planList]);

  const formatDate = useCallback(value => {
    try {
      if (value === null || value === undefined || value === '') return ' ';

      return `${new Date(value).toLocaleDateString('pt-BR')} ${new Date(value).toLocaleTimeString(
        'pt-BR',
      )}`;
    } catch (e) {
      console.log(e);
      return value;
    }
  }, []);

  const changeParticipantPlan = async participanteId => {
    setLoadingChangePlan(true);

    let data = {
      email: participant.email,
      cep: participant.cep,
      endereco: participant.endereco,
      complemento: participant.complemento,
      bairro: participant.bairro,
      cidade: participant.cidade,
      ufId: participant.ufId,
      idPlano: participant.participantePlanoMensalidadePrime.id,
    };

    await Api.patch(`/participantes/${participanteId}`, { ...data });
    setLoadingChangePlan(false);
  };

  return loading ? (
    <CircularProgress />
  ) : (
    <>
      <Wrap>
        <Breadcrumbs>
          <Link color="inherit" onClick={() => history.push('/')}>
            Home
          </Link>
          <Link
            color="inherit"
            onClick={() => history.push('/participantes/gestaodeparticipantes')}
          >
            Gestão de Participantes
          </Link>
          <Typography color="textPrimary">Detalhes</Typography>
        </Breadcrumbs>
      </Wrap>
      <Paper style={{ padding: 16 }}>
        <Typography component="h1" variant="h6">
          Informações do Varejo:{' '}
          <Button
            onClick={() => history.push('/participantes/gestaodeparticipantes')}
            variant="contained"
            color="secondary"
            className={cn([classes.buttonActions])}
          >
            Voltar
          </Button>
        </Typography>
        <Box p="1.5em">
          <Grid container spacing={3}>
            <Grid item xs={12}></Grid>
            <Grid item xs={3}>
              <TextField
                id="codigoSap"
                label="Codigo SAP"
                value={participant.codigoSap ?? ' '}
                variant="outlined"
                fullWidth
                disabled={true}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="inscricaoEstadual"
                label="Inscrição Estadual"
                value={participant.inscricaoEstadual ?? ' '}
                variant="outlined"
                fullWidth
                disabled={true}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="nome"
                label="Razão Social"
                value={participant.razaoSocial ?? ' '}
                variant="outlined"
                fullWidth
                disabled={true}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="tipoDeAtividade"
                label="Tipo de Atividade"
                value={participant.tipoDeAtividade ?? ' '}
                variant="outlined"
                fullWidth
                disabled={true}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="tipoDaEmpresa"
                label="Tipo da Empresa"
                value={participant.tipoDaEmpresa ?? ' '}
                variant="outlined"
                fullWidth
                disabled={true}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="status"
                label="Status"
                value={participant.ativo ? 'Ativo' : 'Desativado'}
                variant="outlined"
                fullWidth
                disabled={true}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="dataAtivacao"
                label="Ativado Em"
                value={formatDate(participant.dataAtivacao ?? ' ')}
                variant="outlined"
                fullWidth
                disabled={true}
              />
            </Grid>

            {participant.dataDesativacao && (
              <>
                <Grid item xs={3}>
                  <TextField
                    id="dataDesativacao"
                    label="Desativado Em"
                    value={formatDate(participant.dataDesativacao)}
                    variant="outlined"
                    fullWidth
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    id="motivoDesativacao"
                    label="Motivo Desativação"
                    value={participant.motivoDesativacao ?? ' '}
                    variant="outlined"
                    fullWidth
                    disabled={true}
                  />
                </Grid>
              </>
            )}

            {participant.dataCancelamento && (
              <>
                <Grid item xs={3}>
                  <TextField
                    id="dataCancelamento"
                    label="Cancelado Em"
                    value={formatDate(participant.dataCancelamento)}
                    variant="outlined"
                    fullWidth
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    id="motivoCancelamento"
                    label="Motivo Cancelamento"
                    value={participant.motivoCancelamento ?? ' '}
                    variant="outlined"
                    fullWidth
                    disabled={true}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Box>
        <Typography component="h1" variant="h6">
          Informações do Participante:
        </Typography>
        <Box p="1.5em">
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <TextField
                id="cpf"
                label={participant.tipo === 1 ? 'Pessoa Jurídica' : 'Pessoa Física'}
                value={participant.username}
                variant="outlined"
                disabled={true}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="nome"
                label="Nome"
                value={participant.nome ?? ''}
                variant="outlined"
                disabled={true}
                fullWidth
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                id="email"
                label="E-mail"
                value={participant.email ?? ' '}
                onChange={event => setParticipant({ ...participant, email: event.target.value })}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="telefone1"
                label="Telefone"
                value={participant.telefone1 ?? ' '}
                variant="outlined"
                disabled={true}
                fullWidth
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                id="contatoCpfCnpj"
                label="Contato CPF"
                value={participant.contatoCpfCnpj ?? ' '}
                variant="outlined"
                disabled={true}
                fullWidth
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                id="contatoNome"
                label="Contato Nome"
                value={participant.contatoNome ?? ' '}
                variant="outlined"
                disabled={true}
                fullWidth
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                id="contatoEmail"
                label="Contato E-mail"
                value={participant.contatoEmail ?? ' '}
                variant="outlined"
                disabled={true}
                fullWidth
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                id="contatoTelefone"
                label="Contato Telefone"
                value={participant.contatoTelefone ?? ' '}
                variant="outlined"
                disabled={true}
                fullWidth
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                id="dataNascimento"
                label="Data Nascimento"
                value={formatDate(participant.dataNascimento)}
                variant="outlined"
                fullWidth
                disabled={true}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                id="dataPrimeiroAcesso"
                label="Primeiro Acesso"
                value={formatDate(participant.dataPrimeiroAcesso)}
                variant="outlined"
                fullWidth
                disabled={true}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                id="aceiteNovidades"
                label="Aceita Novidades?"
                value={participant.aceiteNovidades ? 'Sim' : 'Não'}
                variant="outlined"
                fullWidth
                disabled={true}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                id="aceiteTermos"
                label="Aceitou os termos?"
                value={participant.aceiteTermos ? 'Sim' : 'Não'}
                variant="outlined"
                fullWidth
                disabled={true}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                id="CEP"
                label="CEP"
                value={participant.cep ?? ' '}
                onChange={event =>
                  setParticipant({ ...participant, cep: Utils.formatString(event.target.value) })
                }
                inputProps={{ maxLength: 8 }}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="endereco"
                label="Endereco"
                value={participant.endereco ?? ' '}
                onChange={event =>
                  setParticipant({
                    ...participant,
                    endereco: Utils.formatString(event.target.value),
                  })
                }
                variant="outlined"
                fullWidth
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                id="complemento"
                label="Complemento"
                value={participant.complemento ?? ' '}
                onChange={event =>
                  setParticipant({
                    ...participant,
                    complemento: Utils.formatString(event.target.value),
                  })
                }
                variant="outlined"
                fullWidth
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                id="Bairro"
                label="Bairro"
                value={participant.bairro}
                onChange={event =>
                  setParticipant({ ...participant, bairro: Utils.formatString(event.target.value) })
                }
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="Cidade"
                label="Cidade"
                value={participant.cidade}
                onChange={event =>
                  setParticipant({ ...participant, cidade: Utils.formatString(event.target.value) })
                }
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <Autocomplete
                id="UF"
                disableClearable={true}
                value={ufState.find(item => {
                  return item.id === participant.ufId;
                })}
                getOptionLabel={option => option.name}
                onChange={(event: any, newValue: any) => {
                  if (newValue) {
                    setParticipant({ ...participant, ufId: newValue.id });
                  }
                }}
                options={ufState}
                renderInput={params => <TextField {...params} label="Estado" variant="outlined" />}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="contatoPreferencial"
                label="Contato Preferencial"
                value={
                  participant?.idPreferredContact === 1
                    ? `Celular: ${participant?.preferredContact}`
                    : participant?.idPreferredContact === 2
                    ? `E-mail: ${participant?.preferredContact}`
                    : ' '
                }
                variant="outlined"
                fullWidth
                disabled={true}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="dataPrimeiraAdesao"
                label="Data primeira Adesão"
                value={formatDate(participant.dataPrimeiraAdesao)}
                variant="outlined"
                disabled={true}
                fullWidth
              />
            </Grid>

            <Grid item xs={3}>
              <Autocomplete
                disableClearable={true}
                id="planoParticipante"
                value={allActivePlan.find(item => {
                  return item.id === participant.participantePlanoMensalidadePrime.id;
                })}
                getOptionLabel={option => option.descricao}
                onChange={(event: any, newValue: any) => {
                  if (newValue) {
                    setParticipant({
                      ...participant,
                      participantePlanoMensalidadePrime: { ...newValue },
                    });
                  }
                }}
                options={allActivePlan}
                renderInput={params => (
                  <TextField {...params} label="Plano do Participante" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item xs={8}></Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                color="secondary"
                className={cn([classes.buttonActions])}
                disabled={loadingChangePlan}
                onClick={() => changeParticipantPlan(participant.id)}
              >
                {loadingChangePlan ? 'Carregando...' : 'Salvar'}
              </Button>
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
        </Box>
        <Typography component="h1" variant="h6">
          Informações do Marketplace:
        </Typography>
        <Box p="1.5em">
          <Grid container spacing={3}>
            <Grid item xs={12}></Grid>
            <Grid item xs={3}>
              <TextField
                id="cadMarketplace"
                label="Cadastrado no Marketplace"
                value={participant.cloudLoyaltyParticipanteId > 0 ? 'Sim' : 'Não'}
                variant="outlined"
                fullWidth
                disabled={true}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="cloudLoyaltyParticipanteId"
                label="ID Marketplace"
                value={participant.cloudLoyaltyParticipanteId ?? ' '}
                variant="outlined"
                fullWidth
                disabled={true}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="cloudLoyaltyParticipanteCreatedAt"
                label="Data criação Marketplace"
                value={formatDate(participant.cloudLoyaltyParticipanteCreatedAt)}
                variant="outlined"
                fullWidth
                disabled={true}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                id="cloudLoyaltyParticipanteUpdatedAt"
                label="Data atualização Marketplace"
                value={formatDate(participant.cloudLoyaltyParticipanteUpdatedAt)}
                variant="outlined"
                fullWidth
                disabled={true}
              />
            </Grid>
          </Grid>
        </Box>
        <Typography component="h1" variant="h6">
          Timeline do Participante:
        </Typography>
        <Box p="1.5em">
          <Grid container spacing={3}>
            <Grid item xs={2} />
            <Grid item xs={8}>
              {loadingTimeline ? (
                'Carregando linha do tempo do participante...'
              ) : !!timeline.length ? (
                <Timeline align="alternate">
                  {timeline.map(item => {
                    return (
                      <TimelineItemComponent
                        dataHistorico={item.dataHistorico}
                        descricao={item.descricao}
                        tipoHistorico={item.tipoHistorico}
                      />
                    );
                  })}
                </Timeline>
              ) : (
                'Falha ao carregar linha do tempo, por favor recarregue a página.'
              )}
            </Grid>
            <Grid item xs={2} />
          </Grid>
        </Box>
        {participant.tipoPagamento && (
          <>
            <Typography component="h1" variant="h6">
              Informações de Pagamento:
            </Typography>
            <Box p="1.5em">
              <Grid container spacing={3}>
                <Grid item xs={12}></Grid>
                <Grid item xs={3}>
                  <TextField
                    id="tipoPagamento"
                    label="Tipo de Pagamento"
                    value={
                      participant.tipoPagamento === 1
                        ? 'Boleto'
                        : participant.tipoPagamento === 2
                        ? 'Débito de recebíveis '
                        : ''
                    }
                    variant="outlined"
                    fullWidth
                    disabled={true}
                  />
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </Paper>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  planList: state.planManagement.planAvaliableList.data,
  loading: state.planManagement.planAvaliableList.loading,
  ufState: state.regions.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...PlanManagementActions, ...RegionsActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantDetails);
