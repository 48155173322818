import React from 'react';
import { Box, makeStyles, Theme, createStyles } from '@material-ui/core';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    pill: {
      fontSize: 11,
      fontWeight: 400,
      letterSpacing: '1px',
      color: 'white',
      background: theme.palette.primary.main,
      borderRadius: '8px',
      display: 'inline-block',
      marginLeft: '5px',
      padding: '3px 5px',
    },
  }),
);

interface BannerAccordionTitleProps {
  type: string;
}

const BannerAccordionTitle: React.FC<BannerAccordionTitleProps> = ({ type }) => {
  const classes = useStyle();
  return (
    <Box>
      Banner
      <span className={classes.pill}>{type}</span>
    </Box>
  );
};

export default BannerAccordionTitle;
