import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import FileUpload from 'components/FileUpload/FileUpload';
import * as UploadCampaignFileActions from 'store/uploadCampaignReport/uploadFile/actions';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import { Dispatch, bindActionCreators } from 'redux';
import { MODULE_TYPE, STRUCTURE_PULICTARGET } from 'common/Constants';
import UploadCampaignFile from './uploadFile/UploadCampaignFile';

interface UploadReportProps {
  campaignReportId?: number;
  loadUploadFile(campaignReportId: number, moduleType: MODULE_TYPE): void;
  uploadCampaingCsv(
    uploadUrl: string,
    data: FormData,
    callbackAction: any,
    id: number,
    type: number,
  ): void;
  uploadFile;
}

const UploadReport: React.FC<UploadReportProps> = ({
  campaignReportId,
  loadUploadFile,
  uploadFile,
  uploadCampaingCsv,
}) => {
  console.log(uploadFile);
  const location = useLocation().pathname;
  const [url] = useState(`/uploadCampanhaRelatorio/${campaignReportId}`);

  useEffect(() => {
    loadUploadFile(Number(campaignReportId), 1);
  }, []);

  const handleUpload = (file: any) => {
    const data = new FormData();
    data.append('file', file);
    const uploadUrl = `${url}/uploadCsv`;

    uploadCampaingCsv(uploadUrl, data, '', Number(campaignReportId), 1);
  };

  return (
    <>
      <Box>
        <Box fontSize="h5.fontSize" mt="1em">
          Definir seleção customizada:
        </Box>
        <Box fontSize={14} mb="1em">
          <strong>Importante!</strong> Para realizar a seleção customizada faça o upload de
          umarquivo CSV.
        </Box>

        <FileUpload onUpload={handleUpload} acceptType={['csv']} />

        <Box mt="1em">
          <UploadCampaignFile uploadFile={uploadFile} campaignReportId={campaignReportId} />
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  uploadFile: state.storeUploadCampaignReportFile.data,
  loading: state.storeUploadCampaignReportFile.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(UploadCampaignFileActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UploadReport);
