import { action } from 'typesafe-actions';
import { FdvListTypes, IFdvListItem, IDistributor } from './';

export const loadRequest = (url: string, data: any) =>
  action(FdvListTypes.LOAD_REQUEST, { url, data });
export const loadSuccess = (data: IFdvListItem[]) => action(FdvListTypes.LOAD_SUCCESS, { data });
export const loadFailure = () => action(FdvListTypes.LOAD_FAILURE);
export const loadDistributorsRequest = () => action(FdvListTypes.LOAD_DISTRIBUTORS_REQUEST);
export const loadDistributorsSuccess = (data: IDistributor[]) =>
  action(FdvListTypes.LOAD_DISTRIBUTORS_SUCCESS, { data });
export const loadDistributorsFailure = () => action(FdvListTypes.LOAD_DISTRIBUTORS_FAILURE);
