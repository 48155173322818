import {
  Box,
  Button,
  createStyles,
  FormControl,
  IconButton,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Theme,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
/* eslint-disable react-hooks/exhaustive-deps */
import { CloudDownload } from '@material-ui/icons';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { TABLE_STYLE } from 'common/Constants';
import MaterialTable from 'material-table';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from 'store';
import * as KpiActions from 'store/kpi/actions';
import { showToastr } from 'store/toastr/actions';
import FileUpload from '../FileUpload/FileUpload';
import { AppKpi } from 'common/AppKpi.enum';
import Utils from 'common/Utils';
import * as SkuActions from 'store/sku/actions';
import Api from 'common/Api';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(2),
      minWidth: 120,
    },
    spacing: {
      marginBottom: theme.spacing(2),
    },
    buttonActions: {
      padding: theme.spacing(1),
      minWidth: 120,
      textAlign: 'right',
      '& > button': {
        marginRight: 0,
      },
    },
  }),
);

const KpiVeo = ({
  kpi,
  campaign,
  uploadKpiFileVeoRequest,
  loadRequest,
  saveKpiRequest,
  loadConfiguredKpi,
  loading,
  file,
  sku,
  loadSku,
}) => {
  const classes = useStyle();
  const [labelWidth, setLabelWidth] = React.useState(0);

  const [refYears, setRefYears] = useState([] as any);
  const [yearReference, setYearReference] = React.useState(0);

  const [refMonths, setRefMonths] = useState([] as any);
  const [monthReference, setMonthReference] = React.useState(0);

  const [typeMetaValue, setTypeMetaValue] = React.useState(0);
  const [kpiTypesMeta, setKpiTypesMeta] = useState([] as any);

  const [errorMessage, setErrorMessage] = useState('');

  const [validMonthActual, setvalidMonthActual] = React.useState(true);

  useEffect(() => {
    if (campaign.id > 0) {
      loadRequest();
      loadConfiguredKpi(campaign.id);
      loadSku(Number(campaign.id));
    }
  }, [campaign.id]);

  useEffect(() => {
    setRefYears(Utils.generateArrayOfYears());
    setRefMonths(Utils.generateArrayOfMonths());
  }, [kpiTypesMeta]);

  useEffect(() => {
    setRefYears(Utils.generateArrayOfYears());
    setRefMonths(Utils.generateArrayOfMonths());
    getAllTypeMeta();
  }, []);

  const handleUpload = (file: any) => {
    const hasErrors = getErrorMessages();

    if (!hasErrors) {
      const data = new FormData();
      data.append('file', file);
      uploadKpiFileVeoRequest(data, campaign.id, monthReference, yearReference, typeMetaValue);
    }
  };

  const returnStatus = file => {
    if (!file.ativo) {
      return 'Inativo';
    }
    if (file.processado && file.sucesso) {
      return 'Sucesso';
    }
    if (!file.processado && file.ativo && !file.erro) {
      return 'Em processamento';
    }
    if (!file.processado && !file.sucesso && file.erro) {
      return file.erro;
    }
    if (!file.processado && !file.sucesso && !file.erro) {
      return 'Erro';
    }
  };

  const returnTypeMeta = file => {
    if (file.tipoMetaId !== null && kpiTypesMeta && kpiTypesMeta.length > 0)
      return kpiTypesMeta.find(ean => {
        return ean.id === file.tipoMetaId;
      }).nome;
  };

  const getAllTypeMeta = async () => {
    const { data } = await Api.get(`/Shared/KpiTipoMeta`);
    setKpiTypesMeta(data);
  };

  const handleMonthChange = (event: React.ChangeEvent<{ value: any }>) => {
    setMonthReference(+event.target.value);

    let monthActual = Utils.validMonthActual(+event.target.value);
    setvalidMonthActual(monthActual);
  };

  const handleYearChange = (event: React.ChangeEvent<{ value: any }>) => {
    setYearReference(+event.target.value);
  };

  const handleTypeMetaReferenceChange = (event: React.ChangeEvent<{ value: any }>) => {
    setTypeMetaValue(+event.target.value);
  };

  const getErrorMessages = (): boolean => {
    const isMonthValid = monthReference === 0;
    const isYearValid = yearReference === 0;
    const isTypeMetaValid = typeMetaValue === 0;

    setErrorMessage(
      isMonthValid
        ? 'Mês Referência é obrigatorio'
        : isYearValid
        ? 'Ano Referência é obrigatorio'
        : isTypeMetaValid
        ? 'Tipo de meta é obrigatorio'
        : '',
    );

    return [!isMonthValid, !isYearValid, !isTypeMetaValid].some(e => !e);
  };

  return (
    <Paper>
      <Box p="1.5em">
        <form>
          <Box width={1} className={classes.spacing}>
            <Box fontSize="h5.fontSize">Upload de KPI</Box>
            <Box className={classes.spacing}>
              Faça o upload do arquivo de KPI, códigoSAp dos elegíveis para o crédito automático{' '}
              <br /> Ao enviar um arquivo novo, os anteriores serão inativados.
            </Box>
            <FileUpload
              onUpload={handleUpload}
              acceptType={['csv']}
              loading={loading}
              text={'Selecione o arquivo...'}
            />
          </Box>

          <Grid container={true}>
            <Grid item={true} xs={2}>
              <FormControl fullWidth={true} variant="outlined" className={classes.formControl}>
                <Box className={classes.spacing}> Mês Referência </Box>
                <Select
                  required
                  native
                  value={monthReference}
                  labelWidth={labelWidth}
                  onChange={(e: React.ChangeEvent<{ value: any }>) => handleMonthChange(e)}
                  inputProps={{
                    name: 'monthReference',
                    id: 'monthReference',
                  }}
                >
                  <option value="0">Selecione...</option>

                  {refMonths.map(method => (
                    <option key={method} value={method}>
                      {method}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item={true} xs={1}>
              <FormControl
                fullWidth={true}
                variant="outlined"
                className={classes.formControl}
              ></FormControl>
            </Grid>
            <Grid item={true} xs={2}>
              <FormControl fullWidth={true} variant="outlined" className={classes.formControl}>
                <Box className={classes.spacing}> Ano Referência </Box>
                <Select
                  required
                  native
                  value={yearReference}
                  labelWidth={labelWidth}
                  onChange={(e: React.ChangeEvent<{ value: any }>) => handleYearChange(e)}
                  inputProps={{
                    name: 'yearReference',
                    id: 'yearReference',
                  }}
                >
                  <option value="0">Selecione...</option>

                  {refYears.map(method => (
                    <option key={method} value={method}>
                      {method}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item={true} xs={1}>
              <FormControl
                fullWidth={true}
                variant="outlined"
                className={classes.formControl}
              ></FormControl>
            </Grid>

            {kpiTypesMeta && kpiTypesMeta.length > 0 && (
              <Grid item={true} xs={2}>
                <FormControl fullWidth={true} variant="outlined" className={classes.formControl}>
                  <Box className={classes.spacing}> Tipo de Meta</Box>
                  <Select
                    disabled={kpiTypesMeta && kpiTypesMeta.length === 0}
                    required
                    native
                    value={typeMetaValue}
                    labelWidth={labelWidth}
                    onChange={(e: React.ChangeEvent<{ value: any }>) =>
                      handleTypeMetaReferenceChange(e)
                    }
                    inputProps={{
                      nome: 'eanReference',
                      id: 'eanReference',
                    }}
                  >
                    <option value="0">Selecione...</option>

                    {kpiTypesMeta.map(method => (
                      <option key={method.id} value={method.id}>
                        {method.nome}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>

          {!validMonthActual && (
            <Box fontSize={14} color="red">
              Você está subindo uma campanha fora do mês de vigência
            </Box>
          )}
          <Box fontSize={14} color="red">
            {errorMessage}
          </Box>

          {campaign.kpiTipo >= AppKpi.SELLIN && file && file.length > 0 && (
            <Box width={1} className={classes.spacing} mt="1em">
              <Box fontSize="h5.fontSize">Arquivos de KPI</Box>
              <Box className={classes.spacing}>Lista de arquivos de KPI vinculados a campanha:</Box>
              <MaterialTable
                options={{
                  search: false,
                  paging: false,
                  showTitle: false,
                  toolbar: false,
                  headerStyle: { border: '1px solid' },
                }}
                columns={[
                  {
                    title: 'Data envio',
                    render: (rowData: any) => {
                      return moment(rowData?.dataInclusao).format('DD/MM/YYYY - HH:mm:SS');
                    },
                    cellStyle: { ...TABLE_STYLE, border: '1px solid', width: '10%' },
                  },
                  {
                    title: 'Usuário',
                    field: 'usuario',
                    cellStyle: { ...TABLE_STYLE, border: '1px solid', width: '10%' },
                  },
                  {
                    title: 'Baixar',
                    cellStyle: {
                      ...TABLE_STYLE,
                      border: '1px solid',
                      width: '5%',
                    },
                    render: ({ downloadUrl, retorno }) => (
                      <>
                        {downloadUrl && downloadUrl !== '' && (
                          <IconButton onClick={() => window.open(downloadUrl)}>
                            <CloudUploadIcon />
                          </IconButton>
                        )}
                        {retorno && retorno !== '' && (
                          <IconButton onClick={() => window.open(retorno)}>
                            <CloudDownload />
                          </IconButton>
                        )}
                      </>
                    ),
                  },
                  {
                    title: 'Status',
                    cellStyle: { ...TABLE_STYLE, border: '1px solid', width: '10%' },
                    render: rowData => returnStatus(rowData),
                  },
                  {
                    title: 'Tipo de Meta',
                    render: rowData => returnTypeMeta(rowData),
                    cellStyle: { ...TABLE_STYLE, border: '1px solid', width: '10%' },
                  },
                ]}
                isLoading={false}
                data={file}
              />
            </Box>
          )}
        </form>
      </Box>
    </Paper>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  sku: state.sku.data,
  campaign: state.campaignEdit.data,
  kpi: state.kpi.data,
  file: state.kpi.file,
  loading: state.kpi.loading || state.campaignEdit.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...KpiActions, showToastr, ...SkuActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(KpiVeo);
