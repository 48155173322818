import React, { useEffect } from 'react';
import Wrap from 'components/Wrap/Wrap';
import { Breadcrumbs, Typography, Link, Button, Box, IconButton } from '@material-ui/core';
import { ApplicationState } from 'store';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as CampaignActions from 'store/uploadCampaignReport/list/actions';
import { ICampaignListItem } from 'store/uploadCampaignReport/list';
import { TABLE_STYLE } from 'common/Constants';
import Utils from 'common/Utils';
import EditIcon from '@material-ui/icons/Edit';
import { RouteComponentProps } from 'react-router-dom';
import GenericTable from 'components/GenericTable/GenericTable';
import { CloudDownload } from '@material-ui/icons';

interface IProps extends RouteComponentProps {
  uploadCampaignReportList: ICampaignListItem[];
  loading: boolean;
  loadRequest: () => void;
}

const UploadCampaignList: React.FC<IProps> = ({
  uploadCampaignReportList,
  loadRequest,
  loading,
  history,
}) => {
  useEffect(() => {
    loadRequest();
  }, []);
  const handleOnEdit = (id: number) => history.push(`/CampaignReport/edit/${id}`);
  const handleOnCreate = () => history.push(`/CampaignReport/create`);

  return (
    <>
      <Wrap>
        <Breadcrumbs>
          <Link color="inherit" onClick={() => history.push('/')}>
            Home
          </Link>
          <Typography color="textPrimary">Gestão de Uploads</Typography>
        </Breadcrumbs>
      </Wrap>

      <Box m="0 0 1.5em 0">
        <Button variant="contained" color="primary" onClick={handleOnCreate}>
          Criar Uploads relatórios
        </Button>
      </Box>

      <GenericTable
        title="Campanhas"
        isLoading={loading}
        data={uploadCampaignReportList}
        options={{
          paging: true,
          search: true,
          pageSize: 10,
          sorting: false,
        }}
        columns={[
          {
            searchable: true,
            title: 'Tipo de relatório',
            field: 'nome_Relatorio',
            cellStyle: { ...TABLE_STYLE, width: '20%' },
          },
          {
            searchable: false,
            title: 'Descrição',
            field: 'descricao',
            cellStyle: { ...TABLE_STYLE, width: '20%' },
          },
          {
            searchable: false,
            title: 'Data Processamento',
            field: 'dataProcessamento',
            cellStyle: { ...TABLE_STYLE, width: '15%' },
          },
          {
            searchable: false,
            title: 'Ações',
            cellStyle: {
              width: '10%',
              ...TABLE_STYLE,
            },
            render: ({ id, url_Arquivo }) => (
              <>
                <IconButton onClick={() => window.open(url_Arquivo)}>
                  <CloudDownload />
                </IconButton>
                <IconButton color="primary" onClick={() => handleOnEdit(id)}>
                  <EditIcon fontSize="small" />
                </IconButton>
              </>
            ),
          },
        ]}
      />
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  uploadCampaignReportList: state.uploadCampaignReportList.data,
  loading: state.uploadCampaignReportList.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(CampaignActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UploadCampaignList);
