import React, { useEffect } from 'react';
import { ApplicationState } from 'store';
import { Box, Paper, Button } from '@material-ui/core';
import GenericTable from 'components/GenericTable/GenericTable';
import { TABLE_STYLE } from 'common/Constants';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import * as PaymentsActions from 'store/payments/actions';
import { IHistoricFilterPDVData } from 'store/payments/index';
import Utils from 'common/Utils';

interface HistoricUploadProps {
  historicFilterPDVList: IHistoricFilterPDVData[];
  loading: boolean;
  loadHistoricFilterPDVList(id: number): void;
  handleModal(type: string, id: number, readOnly: boolean, tableDataId?: boolean): void;
  id: number;
}

const HistoricPDVTable: React.FC<HistoricUploadProps> = ({
  historicFilterPDVList,
  loading,
  id,
  loadHistoricFilterPDVList,
  handleModal,
}) => {
  useEffect(() => {
    if (id) loadHistoricFilterPDVList(id);
    // eslint-disable-next-line
  }, [id]);

  return (
    <Paper elevation={3} style={{ margin: '2em' }}>
      <Box>
        <GenericTable
          title="Histórico de importações via Filtro PDV"
          isLoading={loading}
          data={historicFilterPDVList}
          options={{
            paging: true,
            search: false,
            sorting: true,
            pageSize: 5,
            emptyRowsWhenPaging: false,
          }}
          columns={[
            {
              title: 'Data Início',
              field: 'dataInicio',
              type: 'datetime',
              render: rowData => {
                return `${Utils.setData(rowData.dataInicio)}`;
              },
              cellStyle: { ...TABLE_STYLE },
            },
            {
              title: 'Data Fim',
              field: 'dataFim',
              type: 'datetime',
              render: rowData => {
                return rowData.dataFim
                  ? `${Utils.setData(rowData.dataFim)}`
                  : rowData.ativo
                  ? 'Atualmente ativo'
                  : '';
              },
              cellStyle: { ...TABLE_STYLE },
            },
            {
              title: 'Status',
              field: 'ativo',
              defaultSort: 'desc',
              customSort: (a, b) => a.ativo - b.ativo,
              editable: 'never',
              render: rowData => {
                return `${rowData.ativo ? 'Ativo' : 'Inativo'}`;
              },
              cellStyle: { ...TABLE_STYLE },
            },
            {
              title: 'Detalhamento',
              sorting: false,
              render: rowData => {
                return (
                  <Button
                    onClick={() => handleModal('pdv', id, true, rowData.tableData.id)}
                    style={{ textDecoration: 'underline' }}
                    size={'small'}
                  >
                    Ver detalhamento de filtro pdv
                  </Button>
                );
              },
              cellStyle: { ...TABLE_STYLE },
            },
          ]}
        />
      </Box>
    </Paper>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  historicFilterPDVList: state.payments.historicFilterPDV.data,
  loading: state.payments.historicFilterPDV.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...PaymentsActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HistoricPDVTable);
