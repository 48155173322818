import React, { useEffect } from 'react';
import { Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

const NavbarMenuItem: React.FC<any> = ({ title, router, childrens, history }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    backDropClick?: any,
    router?: any,
  ) => {
    console.log('handleCLick ', router);
    if (backDropClick) {
      setAnchorEl(null);
      return false;
    }

    if (router) {
      history?.push(router);
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        disableElevation={true}
        onClick={e => handleClick(e, null, router)}
      >
        {title}
      </Button>

      {childrens && (
        <Menu
          id={`menu-item-${title}`}
          anchorEl={anchorEl}
          keepMounted={true}
          open={Boolean(anchorEl)}
          onClose={handleClick}
        >
          {childrens.map((children, index: number) => {
            return (
              <MenuItem
                key={index}
                onClick={(event: any) => handleClick(event, null, children.router)}
              >
                {children.title}
              </MenuItem>
            );
          })}
        </Menu>
      )}

      {/* {childrens.length > 0 && (
        <Menu
          id={`menu-item-${title}`}
          anchorEl={anchorEl}
          keepMounted={true}
          open={Boolean(anchorEl)}
          onClose={handleClick}
        >
          {childrens.map((children: any, index: number) => {
            return (
              <MenuItem key={index} onClick={(event: any) => handleClick(event, null, children.to)}>
                {children.title}
              </MenuItem>
            );
          })}
        </Menu>
      )} */}
    </>
  );
};

export default NavbarMenuItem;
