import { action } from 'typesafe-actions';
import { PlanManagementTypes, IPlan, IDetailListDataFile, IImportByLotList } from './';

export const loadAllPlanAvaliable = () => action(PlanManagementTypes.LOAD_ALL_PLAN_REQUEST);

export const loadAllPlanAvaliableSuccess = (data: IPlan[]) =>
  action(PlanManagementTypes.LOAD_ALL_PLAN_SUCCESS, { data });

export const loadAllPlanAvaliableFailure = () => action(PlanManagementTypes.LOAD_ALL_PLAN_FAILURE);

export const loadFileDetails = (id: number) =>
  action(PlanManagementTypes.LOAD_DETAIL_FILE_REQUEST, { id });

export const loadFileDetailsSuccess = (data: IDetailListDataFile[]) =>
  action(PlanManagementTypes.LOAD_DETAIL_FILE_SUCCESS, { data });

export const loadFileDetailsFailure = () => action(PlanManagementTypes.LOAD_DETAIL_FILE_FAILURE);

export const loadImportationPlanbyLot = () =>
  action(PlanManagementTypes.LOAD_IMPORTATION_BY_LOT_REQUEST);

export const loadImportationPlanbyLotSuccess = (data: IImportByLotList[]) =>
  action(PlanManagementTypes.LOAD_IMPORTATION_BY_LOT_SUCCESS, { data });

export const loadImportationPlanbyLotFailure = () =>
  action(PlanManagementTypes.LOAD_IMPORTATION_BY_LOT_FAILURE);
