import { Reducer } from 'redux';
import { IGenericUploadTable, IGenericUploadDetailTable } from 'common/Constants';

export enum PaymentsTypes {
  LOAD_CUTTOFF_LIST_REQUEST = '@PaymentsTypes/LOAD_CUTTOFF_LIST_REQUEST',
  LOAD_CUTTOFF_LIST_SUCCESS = '@PaymentsTypes/LOAD_CUTTOFF_LIST_SUCCESS',
  LOAD_CUTTOFF_LIST_FAILURE = '@PaymentsTypes/LOAD_CUTTOFF_LIST_FAILURE',
  CREATE_CUTTOFF_REQUEST = '@PaymentsTypes/CREATE_CUTTOFF_REQUEST',
  CREATE_CUTTOFF_SUCCESS = '@PaymentsTypes/CREATE_CUTTOFF_SUCCESS',
  CREATE_CUTTOFF_FAILURE = '@PaymentsTypes/CREATE_CUTTOFF_FAILURE',
  UPDATE_CUTTOFF_REQUEST = '@PaymentsTypes/UPDATE_CUTTOFF_REQUEST',
  UPDATE_CUTTOFF_SUCCESS = '@PaymentsTypes/UPDATE_CUTTOFF_SUCCESS',
  UPDATE_CUTTOFF_FAILURE = '@PaymentsTypes/UPDATE_CUTTOFF_FAILURE',
  LOAD_VOLUME_REQUEST = '@PaymentsTypes/LOAD_VOLUME_REQUEST',
  LOAD_VOLUME_SUCCESS = '@PaymentsTypes/LOAD_VOLUME_SUCCESS',
  LOAD_VOLUME_FAILURE = '@PaymentsTypes/LOAD_VOLUME_FAILURE',
  LOAD_UPLOAD_PDV_DETAIL_LIST_REQUEST = '@PaymentsTypes/LOAD_UPLOAD_PDV_DETAIL_LIST_REQUEST',
  LOAD_UPLOAD_PDV_DETAIL_LIST_SUCCESS = '@PaymentsTypes/LOAD_UPLOAD_PDV_DETAIL_LIST_SUCCESS',
  LOAD_UPLOAD_PDV_DETAIL_LIST_FAILURE = '@PaymentsTypes/LOAD_UPLOAD_PDV_DETAIL_LIST_FAILURE',
  LOAD_HISTORIC_BY_UPLOAD_REQUEST = '@PaymentsTypes/LOAD_HISTORIC_BY_UPLOAD_REQUEST',
  LOAD_HISTORIC_BY_UPLOAD_SUCCESS = '@PaymentsTypes/LOAD_HISTORIC_BY_UPLOAD_SUCCESS',
  LOAD_HISTORIC_BY_UPLOAD_FAILURE = '@PaymentsTypes/LOAD_HISTORIC_BY_UPLOAD_FAILURE',
  UPLOAD_FILE_REQUEST = '@PaymentsTypes/UPLOAD_FILE_REQUEST',
  UPLOAD_FILE_SUCCESS = '@PaymentsTypes/UPLOAD_FILE_SUCCESS',
  UPLOAD_FILE_FAILURE = '@PaymentsTypes/UPLOAD_FILE_FAILURE',
  UPLOAD_FILTER_PDV_REQUEST = '@PaymentsTypes/UPLOAD_FILTER_PDV_REQUEST',
  UPLOAD_FILTER_PDV_SUCCESS = '@PaymentsTypes/UPLOAD_FILTER_PDV_SUCCESS',
  UPLOAD_FILTER_PDV_FAILURE = '@PaymentsTypes/UPLOAD_FILTER_PDV_FAILURE',
  LOAD_HISTORIC_FILTER_PDV_REQUEST = '@PaymentsTypes/LOAD_HISTORIC_FILTER_PDV_REQUEST',
  LOAD_HISTORIC_FILTER_PDV_SUCCESS = '@PaymentsTypes/LOAD_HISTORIC_FILTER_PDV_SUCCESS',
  LOAD_HISTORIC_FILTER_PDV_FAILURE = '@PaymentsTypes/LOAD_HISTORIC_FILTER_PDV_FAILURE',
}

export interface ICuttOffData {
  numeroDia: number;
  id: number;
  mesReferencia: number;
  anoReferencia: number;
  ativo: boolean;
  dataInclusao: Date;
}

export interface IHistoricFilterPDVData {
  ativo: boolean;
  dataInicio: Date;
  dataFim: Date;
  ufs: number[];
  atividades: number[];
  clusters: number[];
  dataCorteId: number;
}

export interface ICuttOffList {
  data: ICuttOffData[];
  loading: boolean;
}

export interface ICuttOffGenericProps {
  success: boolean;
  loading: boolean;
}

export interface IUploadFileHistoric {
  data: IGenericUploadTable[];
  loading: boolean;
}

export interface IUploadFilterPDVHistoric {
  data: IHistoricFilterPDVData[];
  loading: boolean;
}

export interface IUploadPDVDetail {
  data: IGenericUploadDetailTable[];
  loading: boolean;
}

export interface IPaymentState {
  readonly cuttOffList: ICuttOffList;
  readonly createCuttOff: ICuttOffGenericProps;
  readonly updateCuttOff: ICuttOffGenericProps;
  readonly uploadFile: ICuttOffGenericProps;
  readonly uploadFilterPdv: ICuttOffGenericProps;
  readonly historicFilterPDV: IUploadFilterPDVHistoric;
  readonly uploadFileHistoric: IUploadFileHistoric;
  readonly uploadPDVDetail: IUploadPDVDetail;
}

const INITIAL_STATE: IPaymentState = {
  cuttOffList: {
    data: [],
    loading: false,
  },
  createCuttOff: {
    success: false,
    loading: false,
  },
  updateCuttOff: {
    success: false,
    loading: false,
  },
  uploadFileHistoric: {
    data: [],
    loading: false,
  },
  uploadFile: {
    success: false,
    loading: false,
  },
  historicFilterPDV: {
    data: [],
    loading: false,
  },
  uploadFilterPdv: {
    success: false,
    loading: false,
  },
  uploadPDVDetail: {
    data: [],
    loading: false,
  },
};

const reducer: Reducer<IPaymentState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PaymentsTypes.LOAD_CUTTOFF_LIST_REQUEST:
      return {
        ...state,
        cuttOffList: {
          ...state.cuttOffList,
          loading: true,
        },
      };
    case PaymentsTypes.LOAD_CUTTOFF_LIST_SUCCESS:
      return {
        ...state,
        cuttOffList: {
          ...state.cuttOffList,
          data: [...action.payload.data.data],
          loading: false,
          success: true,
        },
      };
    case PaymentsTypes.LOAD_CUTTOFF_LIST_FAILURE:
      return {
        ...state,
        cuttOffList: {
          ...state.cuttOffList,
          loading: false,
          success: false,
          error: true,
        },
      };
    case PaymentsTypes.CREATE_CUTTOFF_REQUEST:
      return {
        ...state,
        createCuttOff: {
          ...state.createCuttOff,
          loading: true,
        },
      };
    case PaymentsTypes.CREATE_CUTTOFF_SUCCESS:
      return {
        ...state,
        createCuttOff: {
          ...state.createCuttOff,
          data: action.payload.data,
          loading: false,
          success: true,
        },
      };
    case PaymentsTypes.CREATE_CUTTOFF_FAILURE:
      return {
        ...state,
        createCuttOff: {
          ...state.createCuttOff,
          loading: false,
          success: false,
          error: true,
        },
      };
    case PaymentsTypes.UPDATE_CUTTOFF_REQUEST:
      return {
        ...state,
        updateCuttOff: {
          ...state.updateCuttOff,
          loading: true,
        },
      };
    case PaymentsTypes.UPDATE_CUTTOFF_SUCCESS:
      return {
        ...state,
        updateCuttOff: {
          ...state.updateCuttOff,
          data: action.payload.data,
          loading: false,
          success: true,
        },
      };
    case PaymentsTypes.UPDATE_CUTTOFF_FAILURE:
      return {
        ...state,
        updateCuttOff: {
          ...state.updateCuttOff,
          loading: false,
          success: false,
          error: true,
        },
      };
    case PaymentsTypes.LOAD_HISTORIC_BY_UPLOAD_REQUEST:
      return {
        ...state,
        uploadFileHistoric: {
          ...state.uploadFileHistoric,
          loading: true,
        },
      };
    case PaymentsTypes.LOAD_HISTORIC_BY_UPLOAD_SUCCESS:
      return {
        ...state,
        uploadFileHistoric: {
          ...state.uploadFileHistoric,
          data: [...action.payload.data],
          loading: false,
          success: true,
        },
      };
    case PaymentsTypes.LOAD_HISTORIC_BY_UPLOAD_FAILURE:
      return {
        ...state,
        uploadFileHistoric: {
          ...state.uploadFileHistoric,
          data: [],
          loading: false,
          success: false,
          error: true,
        },
      };
    case PaymentsTypes.LOAD_UPLOAD_PDV_DETAIL_LIST_REQUEST:
      return {
        ...state,
        uploadPDVDetail: {
          ...state.uploadPDVDetail,
          loading: true,
        },
      };
    case PaymentsTypes.LOAD_UPLOAD_PDV_DETAIL_LIST_SUCCESS:
      return {
        ...state,
        uploadPDVDetail: {
          ...state.uploadPDVDetail,
          data: [...action.payload.data],
          loading: false,
          success: true,
        },
      };
    case PaymentsTypes.LOAD_UPLOAD_PDV_DETAIL_LIST_FAILURE:
      return {
        ...state,
        uploadPDVDetail: {
          ...state.uploadPDVDetail,
          data: [],
          loading: false,
          error: false,
        },
      };
    case PaymentsTypes.UPLOAD_FILE_REQUEST:
      return {
        ...state,
        uploadFile: {
          ...state.uploadFile,
          loading: true,
        },
      };
    case PaymentsTypes.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        uploadFile: {
          ...state.uploadFile,
          data: action.payload.data,
          loading: false,
          success: true,
        },
      };
    case PaymentsTypes.UPLOAD_FILE_FAILURE:
      return {
        ...state,
        uploadFile: {
          ...state.uploadFile,
          loading: false,
          error: false,
        },
      };
    case PaymentsTypes.UPLOAD_FILTER_PDV_REQUEST:
      return {
        ...state,
        uploadFilterPdv: {
          ...state.uploadFilterPdv,
          loading: true,
        },
      };
    case PaymentsTypes.UPLOAD_FILTER_PDV_SUCCESS:
      return {
        ...state,
        uploadFilterPdv: {
          ...state.uploadFilterPdv,
          data: action.payload.data,
          loading: false,
          success: true,
        },
      };
    case PaymentsTypes.UPLOAD_FILTER_PDV_FAILURE:
      return {
        ...state,
        uploadFilterPdv: {
          ...state.uploadFilterPdv,
          loading: false,
          error: false,
        },
      };
    case PaymentsTypes.LOAD_HISTORIC_FILTER_PDV_REQUEST:
      return {
        ...state,
        historicFilterPDV: {
          ...state.historicFilterPDV,
          loading: true,
        },
      };
    case PaymentsTypes.LOAD_HISTORIC_FILTER_PDV_SUCCESS:
      return {
        ...state,
        historicFilterPDV: {
          ...state.historicFilterPDV,
          data: action.payload.data.data,
          loading: false,
          success: true,
        },
      };
    case PaymentsTypes.LOAD_HISTORIC_FILTER_PDV_FAILURE:
      return {
        ...state,
        historicFilterPDV: {
          ...state.historicFilterPDV,
          data: [],
          loading: false,
          error: false,
        },
      };
    default:
      return state;
  }
};

export default reducer;
