import { Reducer } from 'redux';

export enum UploadCampaignFileTypes {
  LOAD_REQUEST = '@UploadCampaignFile/LOAD_REQUEST',
  LOAD_SUCCESS = '@UploadCampaignFile/LOAD_SUCCESS',
  LOAD_FAILURE = '@UploadCampaignFile/LOAD_FAILURE',
  UPLOAD_REQUEST = '@UploadCampaignFile/UPLOAD_REQUEST',
  UPLOAD_SUCCESS = '@UploadCampaignFile/UPLOAD_SUCCESS',
  UPLOAD_FAILURE = '@UploadCampaignFile/UPLOAD_FAILURE',
  DELETE_REQUEST = '@UploadCampaignFile/DELETE_REQUEST',
  DELETE_SUCCESS = '@UploadCampaignFile/DELETE_SUCCESS',
  DELETE_FAILURE = '@UploadCampaignFile/DELETE_FAILURE',
}

export interface IUploadCampaignDataFile {
  id: number;
  descricao: string;
}

export interface IUploadCampaignFileState {
  data: IUploadCampaignDataFile[];
  loading: boolean;
  error: boolean;
}

const INITIAL_STATE: IUploadCampaignFileState = {
  data: [],
  loading: false,
  error: false,
};

const reducer: Reducer<IUploadCampaignFileState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UploadCampaignFileTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case UploadCampaignFileTypes.LOAD_SUCCESS:
      return { ...state, loading: false, data: [...action.payload.data] };
    case UploadCampaignFileTypes.LOAD_FAILURE:
      return { ...state, loading: false };
    case UploadCampaignFileTypes.UPLOAD_REQUEST:
      return { ...state, loading: true };
    case UploadCampaignFileTypes.UPLOAD_SUCCESS:
      return { ...state, loading: false, data: [...state.data, { ...action.payload.data }] };
    case UploadCampaignFileTypes.UPLOAD_FAILURE:
      return { ...state, loading: false };
    case UploadCampaignFileTypes.DELETE_REQUEST:
      return { ...state, loading: true };
    case UploadCampaignFileTypes.DELETE_SUCCESS:
      return { ...state, loading: false, data: [...state.data] };
    case UploadCampaignFileTypes.DELETE_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default reducer;
