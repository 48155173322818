import React from 'react';
import { ApplicationState } from 'store';
import { Box, createStyles, makeStyles, Modal } from '@material-ui/core';
import GenericTable from 'components/GenericTable/GenericTable';
import { TABLE_STYLE } from 'common/Constants';
import { connect } from 'react-redux';
import { IDetailListDataFile } from 'store/planManagement';

interface DetailsProps {
  data: IDetailListDataFile[];
  loading: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyle = makeStyles(() =>
  createStyles({
    box: {
      position: 'absolute',
      top: '50%',
      overflowY: 'auto',
      left: '50%',
      height: '85%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      backgroundColor: '#ffffff',
      border: '2px solid #000',
      padding: '25px',
    },
  }),
);

const ModalUploadDetails: React.FC<DetailsProps> = ({ data, loading, open, setOpen }) => {
  const modal = useStyle();

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={modal.box}>
          {!!data.length && (
            <GenericTable
              title="Listagem de linhas com erro"
              isLoading={loading}
              data={data}
              options={{
                paging: false,
                search: true,
                sorting: true,
              }}
              columns={[
                {
                  title: 'Número da linha no Documento',
                  field: 'numeroLinha',
                  render: rowData => {
                    return `${rowData.numeroLinha}`;
                  },
                  cellStyle: { ...TABLE_STYLE },
                  customSort: (a, b) => a.numeroLinha - b.numeroLinha,
                  defaultSort: 'asc',
                },
                {
                  title: 'SAP Referência',
                  field: 'sap',
                  render: rowData => {
                    return `${rowData.sap ? rowData.sap : 'SAP não encontrado'}`;
                  },
                  cellStyle: { ...TABLE_STYLE },
                  sorting: false,
                },
                {
                  title: 'Status',
                  field: 'descricaoStatus',
                  render: rowData => {
                    return `${rowData.descricaoStatus}`;
                  },
                  cellStyle: { ...TABLE_STYLE },
                  sorting: false,
                },
                {
                  title: 'Processado',
                  field: 'erro',
                  render: rowData => {
                    return `${!rowData.erro ? 'Sim' : 'Não'}`;
                  },
                  cellStyle: { ...TABLE_STYLE },
                  sorting: false,
                },
                {
                  title: 'Descrição do Erro',
                  field: 'descricaoErro',
                  render: rowData => {
                    return `${
                      rowData.descricaoErro
                        ? rowData.descricaoErro
                        : rowData.status === 3
                        ? 'Foi cancelado devido a sobreposição de uma outra importação'
                        : 'Sem erros'
                    }`;
                  },
                  cellStyle: { ...TABLE_STYLE },
                  sorting: false,
                },
              ]}
            />
          )}
        </Box>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  data: state.payments.uploadPDVDetail.data,
  loading: state.payments.uploadPDVDetail.loading,
});

export default connect(mapStateToProps)(ModalUploadDetails);
