import React from 'react';
import MaterialTable from 'material-table';

const GenericTable = ({ columns, isLoading, title, data, ...rest }) => {
  return (
    <MaterialTable
      localization={{
        body: {
          emptyDataSourceMessage: 'Não há registros encontrados',
        },
        pagination: {
          labelRowsSelect: 'Linhas',
          labelDisplayedRows: '{from}-{to} de {count}',
        },
        toolbar: {
          searchPlaceholder: 'Procurar',
          searchTooltip: 'Procurar',
        },
      }}
      options={rest.options}
      columns={columns}
      isLoading={isLoading}
      title={title}
      data={data}
      {...rest}
    />
  );
};

export default GenericTable;
