import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { ApplicationState } from 'store';
import * as ToastrActions from 'store/toastr/actions';
import { IToastrState } from 'store/toastr';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

interface DispatchProp {
  hideToastr(): void;
}

function Alert(props: AlertProps) {
  return <MuiAlert {...props} />;
}

const Toastr: React.FC<IToastrState & DispatchProp> = ({ open, message, type, hideToastr }) => {
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    hideToastr();
  };

  return (
    <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
      <Alert elevation={6} variant="filled" severity={type}>
        {message}
      </Alert>
    </Snackbar>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  ...state.toastr,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(ToastrActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Toastr as any);
