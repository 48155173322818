import { action } from 'typesafe-actions';
import {
  TrackingBonificationReportTypes,
  ITrackingBonificationReportItem,
  ITrackingBonificationReportParams,
  IStatusItem,
} from './';

export const loadStatusRequest = () => action(TrackingBonificationReportTypes.LOAD_STATUS_REQUEST);

export const loadStatusSuccess = (data: IStatusItem[]) =>
  action(TrackingBonificationReportTypes.LOAD_STATUS_SUCCESS, { data });

export const loadStatusFailure = () => action(TrackingBonificationReportTypes.LOAD_STATUS_FAILURE);

export const loadTrackingBonificationReportRequest = (data: ITrackingBonificationReportParams) =>
  action(TrackingBonificationReportTypes.LOAD_PENDING_CHAINING_NET_REQUEST, { data });

export const loadTrackingBonificationReportSuccess = (data: ITrackingBonificationReportItem[]) =>
  action(TrackingBonificationReportTypes.LOAD_PENDING_CHAINING_NET_SUCCESS, { data });

export const loadTrackingBonificationReportFailure = () =>
  action(TrackingBonificationReportTypes.LOAD_PENDING_CHAINING_NET_FAILURE);

export const loadTrackingBonificationReportCSVRequest = (data: ITrackingBonificationReportParams) =>
  action(TrackingBonificationReportTypes.TRACKING_BONIFICATION_REPORT_CSV_REQUEST, { data });

export const trackingBonificationReportCsvSuccess = (data: string) =>
  action(TrackingBonificationReportTypes.TRACKING_BONIFICATION_REPORT_CSV_SUCCESS, { data });

export const trackingBonificationReportCsvFailure = () =>
  action(TrackingBonificationReportTypes.TRACKING_BONIFICATION_REPORT_CSV_FAILURE);

export const trackingBonificationReportCsvReset = () =>
  action(TrackingBonificationReportTypes.TRACKING_BONIFICATION_REPORT_CSV_RESET);
