import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import {
  loadRealtimeTotalMeiosPagamentosSuccess,
  loadRealtimeTotalMeiosPagamentosFailure,
} from './actions';

export function* loadRealtimeTotalMeiosPagamentosRequest() {
  try {
    const { data } = yield call(API.get, 'dashboard/realtime/total/meiosPagamento');
    yield put(loadRealtimeTotalMeiosPagamentosSuccess(data.itens, data.atualizacao));
  } catch (error) {
    yield put(loadRealtimeTotalMeiosPagamentosFailure());
  }
}
