import { action } from 'typesafe-actions';
import { ParticipantAllowanceFileTypes } from '.';

export const loadParticipantAllowanceFile = () =>
  action(ParticipantAllowanceFileTypes.LOAD_REQUEST);
export const loadUploadFileSuccess = data =>
  action(ParticipantAllowanceFileTypes.LOAD_SUCCESS, { data });
export const loadUploadFileFailure = () => action(ParticipantAllowanceFileTypes.LOAD_FAILURE);

export const loadFileAllowanceDetails = (id: number) =>
  action(ParticipantAllowanceFileTypes.LOAD_DETAILS_REQUEST, { id });
export const loadFileAllowanceDetailsSuccess = data =>
  action(ParticipantAllowanceFileTypes.LOAD_DETAILS_SUCCESS, { data });
export const loadFileAllowanceDetailsFailure = () =>
  action(ParticipantAllowanceFileTypes.LOAD_DETAILS_FAILURE);

export const uploadParticipantAllowanceCsv = (file: FormData) =>
  action(ParticipantAllowanceFileTypes.UPLOAD_REQUEST, { file });
export const participantAllowanceCsvSuccess = data =>
  action(ParticipantAllowanceFileTypes.UPLOAD_SUCCESS, { data });
export const participantAllowanceCsvFailure = () =>
  action(ParticipantAllowanceFileTypes.UPLOAD_FAILURE);
