import { call, put, all } from 'redux-saga/effects';
import API from 'common/Api';
import { loadFailure, loadSuccess } from './actions';
import Utils from 'common/Utils';

export function* loadPopList() {
  try {
    const { data } = yield call(API.get, 'popup/all');

    yield put(loadSuccess(data));
  } catch (error) {
    yield put(loadFailure());
    Utils.redirect(error);
  }
}
