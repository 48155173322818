import { Card, CardContent, CircularProgress, Grid, Typography } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from 'store';
import { IRealtimeItem } from 'store/realtime/totalMeiosPagamentos';
import * as RealtimeActions from 'store/realtime/totalMeiosPagamentos/actions';

interface TotalMeiosPagamentosCardProps {
  loadRealtimeTotalMeiosPagamentosRequest(): void;
  totalMeiosPagamentos: IRealtimeItem[];
  atualizacao: string | null;
  loading: boolean;
  error: boolean;
  refresh: number;
}

export interface ITotalMeiosPagamentosState {
  itemsCard: IRealtimeItem[];
}

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  avatar: {
    backgroundColor: red[500],
  },
});

const DashboardCard: React.FC<TotalMeiosPagamentosCardProps> = ({
  loadRealtimeTotalMeiosPagamentosRequest,
  totalMeiosPagamentos,
  atualizacao,
  loading,
  error,
  refresh,
}) => {
  const classes = useStyles();

  useEffect(() => {
    loadRealtimeTotalMeiosPagamentosRequest();
  }, [refresh]);

  const optionsCard = useMemo(() => {
    let data: any = [];
    let total: number = 0;

    if (totalMeiosPagamentos && totalMeiosPagamentos.length > 0) {
      data = totalMeiosPagamentos.map(item => [item.nome, item.total]);
      total = totalMeiosPagamentos.reduce((accumulator, currentValue) => {
        accumulator = Number(accumulator) + currentValue.total;
        return accumulator;
      }, 0);
    }

    const options = {
      chart: {
        type: 'column',
      },
      title: {
        text: 'TOTAL MEIOS DE PAGAMENTO',
        align: 'center',
        verticalAlign: 'top',
      },
      subtitle: {
        text: `Total: ${new Intl.NumberFormat('pt-BR').format(total)}`,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        type: 'category',
      },
      yAxis: {
        title: {
          text: '',
        },
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b><br/>',
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y:,.0f}',
          },
        },
      },
      series: [
        {
          name: 'Meio de Pagamento',
          colorByPoint: true,
          data,
        },
      ],
    };

    return options;
  }, [totalMeiosPagamentos, atualizacao]);

  return (
    <div>
      <Card className={classes.root} variant="outlined">
        <CardContent>
          {loading ? (
            <CircularProgress />
          ) : (
            <HighchartsReact highcharts={Highcharts} options={optionsCard} />
          )}
          <Grid container justify="space-between" spacing={1}>
            <Grid item>
              <Typography color="textSecondary" variant="caption">
                Total de adesões por meio de pagamento
              </Typography>
            </Grid>
          </Grid>
          <Grid container justify="space-between" spacing={1}>
            <Grid item>
              <Typography color="textSecondary" variant="caption">
                {error && (
                  <>
                    <WarningIcon /> Ops, não foi possível buscar as informações, tente novamente.
                  </>
                )}
                {atualizacao && `Última atualização: ${atualizacao}`}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  totalMeiosPagamentos: state.realtimeTotalMeiosPagamentos.data,
  atualizacao: state.realtimeTotalMeiosPagamentos.atualizacao,
  loading: state.realtimeTotalErrosIntegracaoBoletos.loading,
  error: state.realtimeTotalErrosIntegracaoBoletos.error,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...RealtimeActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(DashboardCard);
