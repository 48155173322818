import React, { Suspense } from 'react';
import { Container } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Navbar from 'components/Navbar/Navbar';
import Toastr from 'components/Toastr/Toastr';
import { ConnectedRouter } from 'connected-react-router';
import BannerEdit from 'pages/Banner/Edit/BannerEdit';
import BannerList from 'pages/Banner/List/BannerList';
import CampaignEdit from 'pages/Campaign/Edit/CampaignEdit';
import CampaignList from 'pages/Campaign/List/CampaignList';
import Home from 'pages/Home/Home';
import Login from 'pages/Login/Login';
import PerfilRegister from 'pages/Perfil/Edit/PerfilEdit';
import PerfilList from 'pages/Perfil/List/PerfilList';
import UserRegister from 'pages/User/Edit/UserEdit';
import UserList from 'pages/User/List/UserList';

import SearchParticipant from '../pages/Participant/List/SearchParticipant';

// Produtos - Gestão de Produtos
import ProductRegister from 'pages/Product/Edit/ProductEdit';
import ProductList from '../pages/Product/List/ProductList';

// Partipantes - Gestão de Planos
import PlanManagement from '../pages/Participant/PlanManagement/PlanManagement';
import PlanAlterationLotIndex from '../pages/Participant/PlanManagement/PlanAlterationLot/PlanAlterationLotIndex';
import ManagementExistingPlan from '../pages/Participant/PlanManagement/ManagementExistingPlan/ManagementExistingPlan';

import ParticipantDetails from '../pages/Participant/Details/ParticipantDetails';
import ChainingNetwork from '../pages/Participant/Net/ChainingNetwork';

//Dashboards e Relatorios
import DBonificacao from './../pages/Dashboard/Bonificacao';
import DPontuacao from './../pages/Dashboard/Pontuacao';
import DAdesao from './../pages/Dashboard/Adesao';
import DPagamentos from './../pages/Dashboard/Payment';
import DPerformance from './../pages/Dashboard/Performance';
import DMarketplace from './../pages/Dashboard/Marketplace';
import Realtime from './../pages/Dashboard/Realtime';

import CampaignCouting from './../pages/Campaign/Report/CampaignCounting';
import UploadVolumeIndex from './../pages/Campaign/UploadVolume/UploadVolumeIndex';

import RetailPayment from './../pages/Dashboard/RetailPayment';
import MembershipConectaPay from './../pages/Dashboard/MembershipConectaPay';

import { Route } from 'react-router-dom';
import { history } from 'store';

import PrivateRoute from './privateRoute';
import PointsMigration from 'pages/Participant/Points/PointsMigration';

// Participantes - Data de Corte Reingressos
import CutOffDateIndex from 'pages/Participant/ReentryCuttOffDate/CutOffDateIndex';
// Pagamentos - Campanha de Incentivo Reingressos
import IncentivePromotionIndex from 'pages/Payments/IncentivePromotion/IncentivePromotionIndex';

import PopupList from 'pages/Popup/List/PopupList';
import PopupEdit from 'pages/Popup/Edit/PopupEdit';

import UploadCampaignList from 'pages/UploadCampaignReport/list/UploadCampaignList';
import UploadCampaignReportGeneralData from 'pages/UploadCampaignReport/UploadCampaignReportEdit';
import CampaignReportExcel from '../pages/CampaignReport/CampaignReportExcel';
import ChainingNetworkList from 'pages/ChainingNetwork/list/ChainingNetworkList';
import ChainingNetworkMain from 'pages/ChainingNetwork/ChainingNetworkMain';
import UploadConectaPayReport from 'pages/UploadConectaPayReport/UploadConectaPayReport';
import UploadConectaPayReportEdit from 'pages/UploadConectaPayReport/UploadConectaPayReportEdit';
import AutomaticCreditList from 'pages/Counting/AutomaticCredit/AutomaticCreditList';
import BenefitMain from 'pages/Benefit/BenefitMain';
import GeneralData from 'pages/Benefit/generalData/GeneralData';
import BenefitList from 'pages/Benefit/list/BenefitList';

import GenerateBankSlip from 'pages/Payments/GenerateBankSlip/GenerateBankSlip';
import CuttOffDateIndex from 'pages/Payments/PaymentCuttOffDate/CuttOffDateIndex';
import ParticipantAllowanceEdit from 'pages/Payments/ParticipanteAllowance/ParticipantAllowanceEdit';
import ParticipantExcemptionEdit from 'pages/Payments/ParticipanteExemption/ParticipantExemptionEdit';
import TrackingBonificationReport from 'pages/TrackingBonification/Report/TrackingBonificationReport';
import ParticipantSeparateInvoiceEdit from 'pages/Payments/ParticipantSeparateInvoice/ParticipantSeparateInvoiceEdit';

const theme = createTheme({
  palette: {
    primary: {
      main: '#381cad',
    },
    secondary: {
      main: '#FFAB46',
    },
  },
});

history.listen(async () => {
  window.scrollTo(0, 0);
});

const Routes = () => {
  return (
    <ConnectedRouter history={history}>
      <Suspense fallback={<span />}>
        <ThemeProvider theme={theme}>
          <Navbar />
          <Container maxWidth={false}>
            <Route path="/login" component={Login} />
            <PrivateRoute path="/" exact={true} component={Home} />
            <PrivateRoute path="/usuarios" exact={true} component={UserList} />
            <PrivateRoute path="/usuarios/cadastrar" component={UserRegister} />
            <PrivateRoute path="/usuarios/editar/:id" component={UserRegister} />
            <PrivateRoute path="/banners" exact={true} component={BannerList} />
            <PrivateRoute path="/banners/cadastrar" exact={true} component={BannerEdit} />
            <PrivateRoute path="/banners/cadastrar/:typeId" component={BannerEdit} />
            <PrivateRoute path="/banners/editar/:typeId/:id" component={BannerEdit} />
            <PrivateRoute
              path="/campanhas/gestaodecampanhas"
              exact={true}
              component={CampaignList}
            />
            <PrivateRoute path="/campanhas/gestaodecampanhas/cadastrar" component={CampaignEdit} />
            <PrivateRoute path="/campanhas/gestaodecampanhas/editar/:id" component={CampaignEdit} />
            <PrivateRoute
              path="/campanhas/uploaddevolume"
              exact={true}
              component={UploadVolumeIndex}
            />

            <PrivateRoute path="/perfil" exact={true} component={PerfilList} />
            <PrivateRoute path="/perfil/cadastrar" component={PerfilRegister} />
            <PrivateRoute path="/perfil/editar/:id" component={PerfilRegister} />
            <PrivateRoute
              path="/participantes/gestaodeparticipantes"
              exact={true}
              component={SearchParticipant}
            />
            <PrivateRoute
              path="/participantes/gestaodeplanos"
              exact={true}
              component={PlanManagement}
            />
            <PrivateRoute
              path="/participantes/gestaodeplanos/alteracaoplanoslote"
              exact={true}
              component={PlanAlterationLotIndex}
            />
            <PrivateRoute
              path="/participantes/gestaodeplanos/planosexistentes"
              exact={true}
              component={ManagementExistingPlan}
            />
            <PrivateRoute
              path="/participantes/encadeamentoderedes"
              exact={true}
              component={ChainingNetwork}
            />
            <PrivateRoute
              path="/participantes/migracaodepontos"
              exact={true}
              component={PointsMigration}
            />
            <PrivateRoute
              path="/participantes/reingresso"
              exact={true}
              component={CutOffDateIndex}
            />
            <PrivateRoute
              path="/pagamentos/promocoesincentivo"
              exact={true}
              component={IncentivePromotionIndex}
            />
            <PrivateRoute
              path="/pagamentos/promocoesincentivo/visualizar/:id"
              component={IncentivePromotionIndex}
            />

            <PrivateRoute path="/participante/detalhes/:id" component={ParticipantDetails} />
            <PrivateRoute path="/Dashboard/Rescue" exact={true} component={DBonificacao} />
            <PrivateRoute path="/Dashboard/Score" exact={true} component={DPontuacao} />
            <PrivateRoute path="/Dashboard/Accession" exact={true} component={DAdesao} />
            <PrivateRoute path="/Dashboard/Realtime" exact={true} component={Realtime} />
            <PrivateRoute
              path="/Report/RegularCampaignCount"
              exact={true}
              component={CampaignCouting}
            />
            <PrivateRoute
              path="/Report/RetailPaymentMethod"
              exact={true}
              component={RetailPayment}
            />
            <PrivateRoute
              path="/Report/MembershipConectaPay"
              exact={true}
              component={MembershipConectaPay}
            />
            <PrivateRoute path="/Dashboard/Marketplace" exact={true} component={DMarketplace} />
            <PrivateRoute path="/Dashboard/Performance" exact={true} component={DPerformance} />
            <PrivateRoute path="/Dashboard/Payments" exact={true} component={DPagamentos} />
            <PrivateRoute path="/popups" exact={true} component={PopupList} />
            <PrivateRoute path="/popups/cadastrar" exact={true} component={PopupEdit} />
            <PrivateRoute path="/popups/editar/:id" component={PopupEdit} />
            <PrivateRoute
              path="/Report/UploadCampaignReportExcel"
              exact={true}
              component={UploadCampaignList}
            />
            <PrivateRoute
              path="/Report/CampaignReportExcel"
              exact={true}
              component={CampaignReportExcel}
            />
            <PrivateRoute
              path="/CampaignReport/create"
              exact={true}
              component={UploadCampaignReportGeneralData}
            />
            <PrivateRoute
              path="/CampaignReport/edit/:id"
              exact={true}
              component={UploadCampaignReportGeneralData}
            />
            <PrivateRoute path="/ChainingNetwork" exact={true} component={ChainingNetworkList} />
            <PrivateRoute
              path="/ChainingNetwork/create"
              exact={true}
              component={ChainingNetworkMain}
            />
            <PrivateRoute
              path="/ChainingNetwork/edit/:id"
              exact={true}
              component={ChainingNetworkMain}
            />
            <PrivateRoute path="/conectapay" exact={true} component={UploadConectaPayReportEdit} />
            <PrivateRoute path="/AutomaticCredit" exact={true} component={AutomaticCreditList} />
            <PrivateRoute path="/Benefit" exact={true} component={BenefitList} />
            <PrivateRoute path="/Benefit/create" exact={true} component={BenefitMain} />
            <PrivateRoute path="/Benefit/edit/:id" component={BenefitMain} />
            <PrivateRoute path="/singlePayment" exact={true} component={GenerateBankSlip} />
            <PrivateRoute path="/pagamentos/datacorte" exact={true} component={CuttOffDateIndex} />
            <PrivateRoute
              path="/participantAllowance"
              exact={true}
              component={ParticipantAllowanceEdit}
            />
            <PrivateRoute
              path="/participantExemption"
              exact={true}
              component={ParticipantExcemptionEdit}
            />
            <PrivateRoute
              path="/participant/TrackingBonificationReport"
              exact={true}
              component={TrackingBonificationReport}
            />
            <PrivateRoute
              path="/pagamentos/tipoenvionotasfiscais"
              exact={true}
              component={ParticipantSeparateInvoiceEdit}
            />
            <PrivateRoute path="/produtos/gestaodeprodutos" exact={true} component={ProductList} />
            <PrivateRoute path="/produtos/cadastrar" component={ProductRegister} />
            <PrivateRoute path="/produtos/editar/:id" component={ProductRegister} />
          </Container>
          <Toastr />
        </ThemeProvider>
      </Suspense>
    </ConnectedRouter>
  );
};

export default Routes;
