import React, { useState, useEffect, useRef } from 'react';
import {
  Paper,
  Box,
  makeStyles,
  Theme,
  createStyles,
  Breadcrumbs,
  Typography,
  Link,
  FormControl,
  TextField,
  Grid,
  InputLabel,
  FormControlLabel,
  Button,
  CircularProgress,
  Select,
  Checkbox,
  IconButton,
} from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import { RouteComponentProps } from 'react-router-dom';
import Wrap from 'components/Wrap/Wrap';
import Utils from 'common/Utils';
import FileUpload from 'components/FileUpload/FileUpload';
import { IPopupEdit, IPopupFiltroSegmentacaoEdit } from 'store/popup/edit';
import { AppMessages } from 'common/AppMessages.enum';
import Api from 'common/Api';
import { ApplicationState } from 'store';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as PopupsEditActions from 'store/popup/edit/actions';
import { showToastr } from 'store/toastr/actions';
import { IToastrState } from 'store/toastr';
import { IElegibleFilterItems } from 'store/campaign/edit';
import { IMailing } from 'store/mailing';
import { MODULE_TYPE, POPUP_TIPO, POPUP_TIPO_SEGMENTACAO, TABLE_STYLE } from 'common/Constants';
import { CloudDownload } from '@material-ui/icons';
import MaterialTable from 'material-table';
import ElegiblesPopup from 'components/Elegibles/ElegiblesPopup';
import { IFilterPopupItemRequest } from 'components/Elegibles/ElegiblesFilterItemPopup';
import EditorHtml from 'components/EditorHtml/EditorHtml';
import PreviewPopup from './PreviewPopup';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: theme.typography.fontFamily,
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    imagem: {
      maxWidth: '320px',
      maxHeight: '200px',
      borderRadius: '6px',
      marginBottom: '12px',
    },
    formControl: {
      marginBottom: theme.spacing(3),
    },
    textCenter: {
      textAlign: 'center',
      display: 'block',
      width: '91%',
    },
  }),
);

const returnStatus = file => {
  if (file.processado && file.sucesso) {
    return 'Sucesso';
  } else if (file.processado) {
    return 'Erro';
  } else return 'Em processamento';
};

const returnAtivo = file => {
  if (file.ativo) {
    return 'Ativo';
  } else return 'Inativo';
};

interface IParams {
  id?: string | undefined;
  typeId?: string | undefined;
}

interface IPopupEditProps {
  createPopup(data: IPopupEdit): void;
  updatePopup(data: IPopupEdit): void;
  loadPopup(id: number): void;
  showToastr(options: IToastrState): void;
  resetPopupEdit(): void;
  loadMailing(id: number, moduleType: MODULE_TYPE): void;
  loadtipoPopupOptions(CodigoTipo: number): void;
  uploadPopupParticipant(uploadUrl: string, data: FormData, id: number, type: number): void;
  setPopupFilterCompleted(): void;
  popup: IPopupEdit;
  popupFiltroSegmentacao: IPopupFiltroSegmentacaoEdit;
  filterPDV: IElegibleFilterItems;
  isFilterPDVCompleted: boolean;
  filterParticipantCountPDV: number;
  mailing: IMailing[];
  participantUpload: any;
  loading: boolean;
}

const PopupEdit: React.FC<IPopupEditProps & RouteComponentProps<IParams>> = ({
  history,
  match,
  createPopup,
  updatePopup,
  loadPopup,
  resetPopupEdit,
  showToastr,
  popup,
  loading,
}) => {
  const classes = useStyle();

  const inputLabel = useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = useState(0);

  const [popupId] = useState(match.params.id);
  const [state, setState] = useState<IPopupEdit>({
    id: 0,
    nome: '',
    codigoTipo: '1',
    texto: '',
    imagemVideoUri: '',
    codigoTela: '1',
    vigenciaAte: '',
    vigenciaDe: '',
    usuarioId: 0,
    ativo: true,
    mostrarNumeroVezes: '0',
    arquivoCsvUri: '',
    nomeArquivo: '',
    codigoTipoSegmentacao: '1',
    url: '',
    codigoTamanho: '1',
    popupFiltroSegmentacao: {
      id: 0,
      popupId: 0,
      baseFull: true,
      participanteSituacao: false,
      participanteSituacaoValor: '0',
      cluster: false,
      tipoPagamento: false,
      regiao: false,
      zona: false,
      territorio: false,
    },
    clusters: [],
    metodosPagamentos: [],
    regioes: [],
    zonas: [],
    territorios: [],
    participantesArquivos: [],
  });
  const [popupFiltroSegmentacao, setPopupFiltroSegmentacao] = useState<IPopupFiltroSegmentacaoEdit>(
    { ...popup.popupFiltroSegmentacao },
  );
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [isLoadingCsv, setIsLoadingCsv] = useState(false);
  const [isDisabledControl, setIsDisabledControl] = useState(false);

  const [stateClusters, setStateClusters] = useState<IFilterPopupItemRequest[]>([]);
  const [stateMethodsPayments, setStateMethodsPayments] = useState<IFilterPopupItemRequest[]>([]);
  const [stateRegions, setStateRegions] = useState<IFilterPopupItemRequest[]>([]);
  const [stateZone, setStateZone] = useState<IFilterPopupItemRequest[]>([]);
  const [stateTerritory, setStateTerritory] = useState<IFilterPopupItemRequest[]>([]);

  const elegiblesProps = {
    selectedClusters: state.clusters,
    selectedMetodosPagamentos: state.metodosPagamentos,
    selectedRegioes: state.regioes,
    selectedZonas: state.zonas,
    selectedTerritorios: state.territorios,
    setStateMethodsPayments: setStateMethodsPayments,
    stateMethodsPayments: stateMethodsPayments,
    setStateClusters: setStateClusters,
    stateClusters: stateClusters,
    setStateRegions: setStateRegions,
    stateRegions: stateRegions,
    setStateZone: setStateZone,
    stateZone: stateZone,
    setStateTerritory: setStateTerritory,
    stateTerritory: stateTerritory,
  };

  const getErrorMessages = (): boolean => {
    const isDateValid = Utils.compareDate(state.vigenciaDe, state.vigenciaAte);
    const isInputRequiredValid = Utils.hasInputError(
      [state.nome, state.vigenciaAte, state.vigenciaDe, state.texto],
      true,
    );

    setErrorMessage(
      isDateValid
        ? AppMessages.DATE_START_BIGGERTHAN_END
        : isInputRequiredValid
        ? AppMessages.FORM_FIELDS_REQUIRED
        : '',
    );

    return [!isDateValid, !isInputRequiredValid].some(e => !e);
  };

  useEffect(() => {
    if (errorMessage !== '') {
      showToastr({
        message: errorMessage,
        type: 'error',
        open: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  useEffect(() => {
    setState({ ...state, id: Number(match.params.id) });
    return () => resetPopupEdit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (popupId) {
      setIsDisabledControl(true);
      loadPopup(Number(popupId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupId]);

  useEffect(() => {
    setTipoPopup(parseInt(state.codigoTipo));
  }, [state.codigoTipo]);

  useEffect(() => {
    setTipoSegmentacaoPopup(parseInt(state.codigoTipoSegmentacao));
  }, [state.codigoTipoSegmentacao]);

  useEffect(() => {
    setState({ ...state, ...popup });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popup]);

  useEffect(() => {
    setPopupFiltroSegmentacao({ ...popupFiltroSegmentacao, ...popup.popupFiltroSegmentacao });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popup.popupFiltroSegmentacao]);

  useEffect(() => {
    setLabelWidth(inputLabel?.current?.offsetWidth || 0);
  }, [labelWidth]);

  const handleStateChange = ({ target }) => {
    setState({ ...state, [target.name]: target.value });
  };

  const handleChangeName = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleChange = (prop: keyof IPopupEdit) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.type === 'checkbox') setState({ ...state, [prop]: event.target.checked });
    else setState({ ...state, [prop]: event.target.value });
  };

  const [tipoPopup, setTipoPopup] = useState(1);
  const [codigoTipoSegmentacao, setTipoSegmentacaoPopup] = useState(1);

  const handleTipoPopupChange = (event: React.ChangeEvent<{ value: string }>) => {
    setTipoPopup(parseInt(event.target.value));
    setState({ ...state, codigoTipo: event.target.value });
  };

  const handleTipoSegmentacaoPopupChange = (event: React.ChangeEvent<{ value: string }>) => {
    setTipoSegmentacaoPopup(parseInt(event.target.value));
    setState({ ...state, codigoTipoSegmentacao: event.target.value });
  };

  const onUploadImage = async (file: any, type: number) => {
    const formData = new FormData();

    formData.append('file', file);

    setIsLoadingImage(true);

    try {
      const { data } = await Api.post(`popup/upload`, formData);

      setState({ ...state, imagemVideoUri: data.url });
      setIsLoadingImage(false);

      showToastr({ message: 'Imagem enviada com sucesso.', type: 'success', open: true });
    } catch (error) {}
  };

  const onUploadVideo = async (file: any, type: number) => {
    const formData = new FormData();

    formData.append('file', file);

    setIsLoadingImage(true);

    try {
      const { data } = await Api.post(`popup/upload`, formData);

      setState({ ...state, imagemVideoUri: data.url });
      setIsLoadingImage(false);

      showToastr({ message: 'Vídeo enviado com sucesso.', type: 'success', open: true });
    } catch (error) {}
  };

  const onUploadCsv = async (file: any, type: number) => {
    const formData = new FormData();

    formData.append('file', file);

    setIsLoadingCsv(true);

    try {
      const { data } = await Api.post(`popup/upload`, formData);

      setState({
        ...state,
        nomeArquivo: file.name,
        arquivoCsvUri: data.url,
      });
      setIsLoadingCsv(false);

      showToastr({ message: 'CSV enviado com sucesso.', type: 'success', open: true });
    } catch (error) {}
  };

  const handleSave = () => {
    const hasErrors = getErrorMessages();

    if (tipoPopup === POPUP_TIPO.TEXTO_IMAGEM && state.imagemVideoUri === '') {
      showToastr({
        message: 'Imagem obrigatória para o cadastro do popup.',
        type: 'error',
        open: true,
      });
      return;
    }

    if (tipoPopup === POPUP_TIPO.TEXTO_VIDEO && state.imagemVideoUri === '') {
      showToastr({
        message: 'Vídeo obrigatório para o cadastro do popup.',
        type: 'error',
        open: true,
      });
      return;
    }

    if (
      codigoTipoSegmentacao === POPUP_TIPO_SEGMENTACAO.ARQUIVO &&
      state.arquivoCsvUri === '' &&
      state.participantesArquivos.length === 0
    ) {
      showToastr({
        message: 'Arquivo CSV dos participantes obrigatórias para o cadastro do popup.',
        type: 'error',
        open: true,
      });
      return;
    }

    state.popupFiltroSegmentacao = popupFiltroSegmentacao;

    let dataClusters = stateClusters.filter(item => item.selected);
    let dataMetodosPagamentos = stateMethodsPayments.filter(elem => elem.selected);
    let dataRegions = stateRegions.filter(item => item.selected);
    let dataZonas = stateZone.filter(item => item.selected);
    let dataTerritorios = stateTerritory.filter(item => item.selected);

    let data = {
      ...state,
      clusters: dataClusters,
      metodosPagamentos: dataMetodosPagamentos,
      regioes: dataRegions,
      zonas: dataZonas,
      territorios: dataTerritorios,
    };

    if (!hasErrors) {
      if (!popupId) createPopup({ ...data });
      if (popupId) updatePopup({ ...data, id: Number(match.params.id) });
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const handleChangePopupFiltroSegmentacao = event => {
    setPopupFiltroSegmentacao({
      ...popupFiltroSegmentacao,
      [event.target.name]: event.target.checked,
    });
  };

  const handleRadioPopupFiltroSegmentacaoChange = event => {
    setPopupFiltroSegmentacao({
      ...popupFiltroSegmentacao,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Box>
      <Wrap>
        <Breadcrumbs>
          <Link color="inherit" onClick={() => history.push('/')}>
            Home
          </Link>
          <Link color="inherit" onClick={() => history.push('/popups')}>
            Gestão de Popups
          </Link>
          <Typography color="textPrimary">{!!popupId ? 'Editar' : 'Cadastrar'}</Typography>
        </Breadcrumbs>
      </Wrap>

      <Paper className={classes.root}>
        <form>
          <Grid container={true} spacing={3}>
            <Grid item={true} md={6}>
              <FormControl fullWidth={true} variant="outlined" className={classes.formControl}>
                <TextField
                  id="nome"
                  error={Utils.hasInputError(state.nome, true) && !!errorMessage}
                  required={true}
                  value={state.nome || ''}
                  onChange={handleChange('nome')}
                  label="Nome do Popup"
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    maxLength: 255,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item={true} md={6}>
              <Grid container={true} spacing={3}>
                <Grid item={true} md={5}>
                  <FormControl fullWidth={true} className={classes.formControl}>
                    <TextField
                      error={Utils.hasInputError(state.vigenciaDe, true) && !!errorMessage}
                      required={true}
                      type="date"
                      id="startDate"
                      name="vigenciaDe"
                      value={state.vigenciaDe}
                      onChange={e => handleStateChange(e)}
                      label="Vigência da Campanha"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item={true} md={2}>
                  <FormControl fullWidth={true} variant="outlined" className={classes.formControl}>
                    <InputLabel className={classes.textCenter}>Até</InputLabel>
                  </FormControl>
                </Grid>
                <Grid item={true} md={5}>
                  <FormControl fullWidth={true} variant="outlined" className={classes.formControl}>
                    <TextField
                      error={Utils.hasInputError(state.vigenciaAte, true) && !!errorMessage}
                      required={true}
                      type="date"
                      id="endDate"
                      name="vigenciaAte"
                      value={state.vigenciaAte}
                      onChange={e => handleStateChange(e)}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item={true} md={3}>
              <FormControl fullWidth={true} variant="outlined" className={classes.formControl}>
                <TextField
                  id="mostrarNumeroVezes"
                  required={true}
                  error={Utils.hasInputError(state.mostrarNumeroVezes, true) && !!errorMessage}
                  value={state.mostrarNumeroVezes}
                  onChange={handleChange('mostrarNumeroVezes')}
                  label="Aparecer (nº vezes)"
                  variant="outlined"
                  fullWidth
                />
              </FormControl>
              <Box fontSize={14} mb="1em">
                Informe a quantidade de vezes que deseja que o popup apareça para o participante. 0
                = todas as vezes ou nº de vezes que deseja
              </Box>
            </Grid>
            <Grid item={true} md={3}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Tela</FormLabel>
                <RadioGroup
                  aria-label="codigoTela"
                  name="codigoTela"
                  value={state.codigoTela}
                  onChange={handleChange('codigoTela')}
                  row
                >
                  <FormControlLabel
                    disabled={isDisabledControl}
                    value="1"
                    control={<Radio />}
                    label="Home"
                  />
                  <FormControlLabel
                    disabled={isDisabledControl}
                    value="2"
                    control={<Radio />}
                    label="Campanha"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item={true} md={4}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Tamanho</FormLabel>
                <RadioGroup
                  aria-label="codigoTamanho"
                  name="codigoTamanho"
                  value={state.codigoTamanho}
                  onChange={handleChange('codigoTamanho')}
                  row
                >
                  <FormControlLabel
                    disabled={isDisabledControl}
                    value="1"
                    control={<Radio />}
                    label="P:small com width: 400px"
                  />
                  <FormControlLabel
                    disabled={isDisabledControl}
                    value="2"
                    control={<Radio />}
                    label="M:medium com width: 600px"
                  />
                  <FormControlLabel
                    disabled={isDisabledControl}
                    value="3"
                    control={<Radio />}
                    label="G:big com width: 800px"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {popupId && (
              <Grid item={true} md={2}>
                <FormControl fullWidth={true} variant="outlined" className={classes.formControl}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.ativo}
                        onChange={handleChangeName}
                        name="ativo"
                        color="primary"
                      />
                    }
                    label="Ativo"
                  />
                </FormControl>
              </Grid>
            )}

            <Grid item={true} md={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel ref={inputLabel} htmlFor="public">
                  Tipo
                </InputLabel>
                <Select
                  required
                  native
                  value={state.codigoTipo}
                  disabled={isDisabledControl}
                  labelWidth={labelWidth}
                  onChange={(e: React.ChangeEvent<{ value: any }>) => handleTipoPopupChange(e)}
                  inputProps={{
                    name: 'tipoPopup',
                    id: 'tipoPopup',
                  }}
                >
                  <option value="1">Somente Texto</option>
                  <option value="2">Texto + imagem</option>
                  <option value="3">Texto + vídeo</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item={true} md={12}>
              <FormControl fullWidth={true} variant="outlined" className={classes.formControl}>
                <EditorHtml
                  onBlur={(event, editorState) => {
                    setState({ ...state, texto: Utils.getEditorData(editorState) });
                  }}
                  initialContent={state.texto}
                />
              </FormControl>
            </Grid>
            {tipoPopup === POPUP_TIPO.TEXTO_IMAGEM && (
              <Grid item={true} md={12}>
                {isLoadingImage ? (
                  <CircularProgress />
                ) : (
                  <>
                    {state.imagemVideoUri && (
                      <img src={state.imagemVideoUri} alt={'Imagem'} className={classes.imagem} />
                    )}
                    <FileUpload
                      fullWidth={true}
                      onUpload={(file: any) => onUploadImage(file, 1)}
                      acceptType={['jpeg', 'jpg', 'png']}
                      label="Imagem"
                      showThumbnail={true}
                      maxWidth="320px"
                    />
                  </>
                )}
              </Grid>
            )}
            {tipoPopup === POPUP_TIPO.TEXTO_VIDEO && (
              <Grid item={true} md={12}>
                {isLoadingImage ? (
                  <CircularProgress />
                ) : (
                  <>
                    {state.imagemVideoUri && (
                      <video controls className={classes.imagem}>
                        <source src={state.imagemVideoUri} type="video/ogg" />
                      </video>
                    )}
                    <FileUpload
                      fullWidth={true}
                      onUpload={(file: any) => onUploadVideo(file, 1)}
                      acceptType={['avi', 'mp4', 'mpeg']}
                      label="Vídeo"
                      showThumbnail={false}
                    />
                  </>
                )}
              </Grid>
            )}
            <Grid item={true} md={12}>
              <FormControl fullWidth={true} variant="outlined" className={classes.formControl}>
                <TextField
                  id="url"
                  required={false}
                  value={state.url || ''}
                  onChange={handleChange('url')}
                  label="Link de redirecionamento"
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    maxLength: 500,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item={true} md={6}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Segmentação por</FormLabel>
                <RadioGroup
                  aria-label="codigoTipoSegmentacao"
                  name="codigoTipoSegmentacao"
                  value={state.codigoTipoSegmentacao}
                  onChange={(e: React.ChangeEvent<{ value: any }>) =>
                    handleTipoSegmentacaoPopupChange(e)
                  }
                  row
                >
                  <FormControlLabel
                    disabled={isDisabledControl}
                    value="1"
                    control={<Radio />}
                    label="Filtro"
                  />
                  <FormControlLabel
                    disabled={isDisabledControl}
                    value="2"
                    control={<Radio />}
                    label="Arquivo CSV"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {codigoTipoSegmentacao === POPUP_TIPO_SEGMENTACAO.FILTRO && (
              <>
                <Grid item={true} md={12}>
                  <Box fontSize="h5.fontSize">Filtros:</Box>
                </Grid>
                <Grid item={true} md={12}>
                  <FormControl fullWidth={true} variant="outlined">
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={isDisabledControl}
                          checked={popupFiltroSegmentacao.baseFull}
                          onChange={handleChangePopupFiltroSegmentacao}
                          name="baseFull"
                          color="primary"
                        />
                      }
                      label="Base full"
                    />
                  </FormControl>
                  <FormControl fullWidth={true} variant="outlined">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={popupFiltroSegmentacao.participanteSituacao}
                          onChange={handleChangePopupFiltroSegmentacao}
                          name="participanteSituacao"
                          color="primary"
                        />
                      }
                      label="Participante situação"
                    />
                  </FormControl>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="participanteSituacaoValor"
                      name="participanteSituacaoValor"
                      value={popupFiltroSegmentacao.participanteSituacaoValor}
                      onChange={handleRadioPopupFiltroSegmentacaoChange}
                      row
                    >
                      <FormControlLabel
                        disabled={!popupFiltroSegmentacao.participanteSituacao}
                        value="1"
                        control={<Radio />}
                        label="Adimplente"
                      />
                      <FormControlLabel
                        disabled={!popupFiltroSegmentacao.participanteSituacao}
                        value="2"
                        control={<Radio />}
                        label="Inadimplente"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {!popupFiltroSegmentacao.baseFull && (
                  <Grid container className={classes.root} spacing={2}>
                    <ElegiblesPopup {...elegiblesProps} />
                  </Grid>
                )}
              </>
            )}
            {codigoTipoSegmentacao === POPUP_TIPO_SEGMENTACAO.ARQUIVO && (
              <Grid item={true} md={12}>
                <Box fontSize="h5.fontSize">Participantes:</Box>
                <Box fontSize={14} mb="1em">
                  <strong>Importante!</strong> Faça o upload de um arquivo CSV contendo a base de
                  Códigos Sap dos participantes
                </Box>
                {isLoadingCsv ? (
                  <CircularProgress />
                ) : (
                  <>
                    {state.arquivoCsvUri && (
                      <Box fontSize={14} mt="1em">
                        Nome arquivo: {state.nomeArquivo}
                      </Box>
                    )}
                    <FileUpload
                      fullWidth={true}
                      onUpload={(file: any) => onUploadCsv(file, 1)}
                      acceptType={['csv']}
                      label="Arquivo CSV"
                      showThumbnail={false}
                    />
                    {state.id > 0 &&
                      state.participantesArquivos &&
                      state.participantesArquivos.length > 0 && (
                        <>
                          <Box width={12 / 12} mt="1em">
                            <MaterialTable
                              options={{
                                search: false,
                                paging: false,
                                showTitle: false,
                                toolbar: false,
                                headerStyle: { border: '1px solid' },
                              }}
                              columns={[
                                {
                                  title: 'Data envio',
                                  render: ({ dataInclusao }) => {
                                    return `${Utils.setDataHora(
                                      Utils.setLocalDateTime(dataInclusao),
                                    )}`;
                                  },
                                  cellStyle: { ...TABLE_STYLE, border: '1px solid' },
                                },
                                {
                                  title: 'Usuário',
                                  field: 'usuario',
                                  cellStyle: { ...TABLE_STYLE, border: '1px solid' },
                                },
                                {
                                  title: 'Baixar',
                                  cellStyle: {
                                    ...TABLE_STYLE,
                                    border: '1px solid',
                                  },
                                  render: ({ downloadUrl }) => (
                                    <>
                                      <IconButton onClick={() => window.open(downloadUrl)}>
                                        <CloudDownload />
                                      </IconButton>
                                    </>
                                  ),
                                },
                                {
                                  title: 'Status',
                                  cellStyle: { ...TABLE_STYLE, border: '1px solid' },
                                  render: rowData => returnStatus(rowData),
                                },
                                {
                                  title: 'Erro',
                                  field: 'erro',
                                  cellStyle: { ...TABLE_STYLE, border: '1px solid' },
                                },
                                {
                                  title: 'Situação',
                                  field: 'ativo',
                                  cellStyle: { ...TABLE_STYLE, border: '1px solid' },
                                  render: rowData => returnAtivo(rowData),
                                },
                              ]}
                              isLoading={false}
                              data={state.participantesArquivos}
                            />
                          </Box>
                        </>
                      )}
                  </>
                )}
              </Grid>
            )}
          </Grid>
          <Box mt={2} fontSize={14} color="red">
            {errorMessage}
          </Box>
        </form>
      </Paper>
      <Box mb="1.5em" textAlign="right">
        <PreviewPopup state={state} />
        <Button disabled={loading} color="primary" variant="contained" onClick={handleSave}>
          {loading ? 'Aguarde...' : 'Salvar'}
        </Button>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  popup: state.popupEdit.data,
  loading: state.popupEdit.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...PopupsEditActions,
      showToastr,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(PopupEdit);
