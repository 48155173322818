import { action } from 'typesafe-actions';
import { TotalMalhaCancelamentosTypes, IRealtimeItem } from './';

export const loadRealtimeTotalMalhaCancelamentosRequest = () =>
  action(TotalMalhaCancelamentosTypes.LOAD_REQUEST);
export const loadRealtimeTotalMalhaCancelamentosSuccess = (
  data: IRealtimeItem[],
  atualizacao: string,
) => action(TotalMalhaCancelamentosTypes.LOAD_SUCCESS, { data, atualizacao });
export const loadRealtimeTotalMalhaCancelamentosFailure = () =>
  action(TotalMalhaCancelamentosTypes.LOAD_FAILURE);
