import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import {
  loadRealtimeTotalErrosAccesstageSuccess,
  loadRealtimeTotalErrosAccesstageFailure,
} from './actions';

export function* loadRealtimeTotalErrosAccesstageRequest() {
  try {
    const { data } = yield call(API.get, 'dashboard/realtime/total/erros/accesstage');
    yield put(loadRealtimeTotalErrosAccesstageSuccess(data.itens, data.atualizacao));
  } catch (error) {
    yield put(loadRealtimeTotalErrosAccesstageFailure());
  }
}
