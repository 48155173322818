import React, { useState, useEffect } from 'react';
import Wrap from 'components/Wrap/Wrap';
import { RouteComponentProps } from 'react-router-dom';
import { Breadcrumbs, Typography, Link, Box } from '@material-ui/core';
import Tab, { ITab } from 'components/Tab/Tab';
import { connect } from 'react-redux';
import UploadChainingNetwork from './uploadFile/UploadChainingNetwork';

interface IParams {
  id?: string | undefined;
}

const ChainingNetworkMain: React.FC<RouteComponentProps<IParams>> = ({ match, history }) => {
  const [tabs] = useState<ITab[]>([
    {
      component: <UploadChainingNetwork chainingNetworkId={Number(match.params.id)} />,
      renderLabel: (tab, index) => `Upload CSV`,
      disabled: false,
    },
  ]);

  return (
    <Box mb="1.5em">
      <Wrap>
        <Breadcrumbs>
          <Link color="inherit" onClick={() => history.push('/')}>
            Home
          </Link>
          <Link color="inherit" onClick={() => history.push('/ChainingNetwork')}>
            Gestão de Uploads
          </Link>
          <Typography color="textPrimary">{!!match.params.id ? 'Editar' : 'Cadastrar'}</Typography>
        </Breadcrumbs>
      </Wrap>

      <Tab tabs={tabs} enableNavigationButton={true} disableNextButton={!match.params.id} />
    </Box>
  );
};

export default connect()(ChainingNetworkMain);
