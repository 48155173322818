import React from 'react';
import { Typography, Box } from '@material-ui/core';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel: React.FC<TabPanelProps> = (props: TabPanelProps) => {
  const { children, value, index, ...rest } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...rest}
    >
      {value === index && <Box pt="1.5em">{children}</Box>}
    </Typography>
  );
};

export default TabPanel;
