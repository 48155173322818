import { action } from 'typesafe-actions';
import {
  ReentryConfigStoreTypes,
  IPromoConfig,
  IImportByLotList,
  IPromoList,
  IPromoDetail,
  IFilterCreate,
} from './';

export const LoadAllTypesPromotions = () =>
  action(ReentryConfigStoreTypes.LOAD_ALL_TYPES_PROMOTIONS_REQUEST);

export const LoadAllTypesPromotionsSuccess = (data: IPromoConfig[]) =>
  action(ReentryConfigStoreTypes.LOAD_ALL_TYPES_PROMOTIONS_SUCCESS, { data });

export const LoadAllTypesPromotionsFailure = () =>
  action(ReentryConfigStoreTypes.LOAD_ALL_TYPES_PROMOTIONS_FAILURE);

export const loadAllUploadHistoric = () =>
  action(ReentryConfigStoreTypes.LOAD_ALL_UPLOAD_HISTORIC_REQUEST);

export const loadAllUploadHistoricSuccess = (data: IImportByLotList[]) =>
  action(ReentryConfigStoreTypes.LOAD_ALL_UPLOAD_HISTORIC_SUCCESS, { data });

export const loadAllUploadHistoricFailure = () =>
  action(ReentryConfigStoreTypes.LOAD_ALL_UPLOAD_HISTORIC_FAILURE);

export const loadUploadDetail = (id: number) =>
  action(ReentryConfigStoreTypes.LOAD_UPLOAD_DETAIL_REQUEST, { id });

export const loadUploadDetailSuccess = (data: IImportByLotList[]) =>
  action(ReentryConfigStoreTypes.LOAD_UPLOAD_DETAIL_SUCCESS, { data });

export const loadUploadDetailFailure = () =>
  action(ReentryConfigStoreTypes.LOAD_UPLOAD_DETAIL_FAILURE);

export const LoadAllPromotionRegistred = () =>
  action(ReentryConfigStoreTypes.LOAD_PROMOTION_REGISTRED_REQUEST);

export const LoadAllPromotionRegistredSuccess = (data: IPromoList[]) =>
  action(ReentryConfigStoreTypes.LOAD_PROMOTION_REGISTRED_SUCCESS, { data });

export const LoadAllPromotionRegistredFailure = () =>
  action(ReentryConfigStoreTypes.LOAD_PROMOTION_REGISTRED_FAILURE);

export const LoadPromotionCampaignById = (id: number) =>
  action(ReentryConfigStoreTypes.LOAD_PROMOTION_BY_ID_REQUEST, { id });

export const LoadPromotionCampaignByIdSuccess = (data: IPromoDetail) =>
  action(ReentryConfigStoreTypes.LOAD_PROMOTION_BY_ID_SUCCESS, { data });

export const LoadPromotionCampaignByIdFailure = () =>
  action(ReentryConfigStoreTypes.LOAD_PROMOTION_BY_ID_FAILURE);

export const LoadCuttOffDay = () => action(ReentryConfigStoreTypes.LOAD_CUT_OFF_DAY_REQUEST);

export const LoadCuttOffDaySuccess = (data: IPromoConfig[]) =>
  action(ReentryConfigStoreTypes.LOAD_CUT_OFF_DAY_SUCCESS, { data });

export const LoadCuttOffDayFailure = () => action(ReentryConfigStoreTypes.LOAD_CUT_OFF_DAY_FAILURE);

export const createFilterPDV = (data: IFilterCreate) =>
  action(ReentryConfigStoreTypes.CREATE_FILTER_PDV_REQUEST, { data });

export const createFilterPDVSuccess = data =>
  action(ReentryConfigStoreTypes.CREATE_FILTER_PDV_SUCCESS, { data });

export const createFilterPDVFailure = () =>
  action(ReentryConfigStoreTypes.CREATE_FILTER_PDV_FAILURE);

export const loadFilterPDV = (id: number) =>
  action(ReentryConfigStoreTypes.LOAD_FILTER_PDV_REQUEST, { id });

export const loadFilterPDVSuccess = data =>
  action(ReentryConfigStoreTypes.LOAD_FILTER_PDV_SUCCESS, { data });

export const loadFilterPDVFailure = () => action(ReentryConfigStoreTypes.LOAD_FILTER_PDV_FAILURE);

export const loadFilterPDVReset = () => action(ReentryConfigStoreTypes.LOAD_FILTER_PDV_RESET);
