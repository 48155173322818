import React from 'react';
import { IconButton } from '@material-ui/core';
import { TABLE_STYLE } from 'common/Constants';
import EditIcon from '@material-ui/icons/Edit';
import GenericTable from 'components/GenericTable/GenericTable';
import { RouteComponentProps } from 'react-router-dom';
import { ContactSupportOutlined } from '@material-ui/icons';
import Utils from '../../../common/Utils';

const ParticipantList: React.FC<any> = ({ history, listParticipants, loading }) => {
  const handleOnEdit = (id: number) => {
    history.push(`/participante/detalhes/${id}`);
  };

  return (
    <>
      {listParticipants && listParticipants.length > 0 && (
        <GenericTable
          title="Participantes"
          isLoading={loading}
          data={listParticipants}
          options={{
            paging: true,
            search: false,
            pageSize: 10,
            sorting: false,
          }}
          columns={[
            {
              title: 'Código SAP',
              field: 'codigoSap',
              cellStyle: { ...TABLE_STYLE },
            },
            {
              title: 'Nome',
              field: 'nome',
              cellStyle: { ...TABLE_STYLE, width: '30%' },
            },
            {
              title: 'Documento',
              field: 'username',
              cellStyle: { ...TABLE_STYLE, width: '15%' },
            },
            {
              title: 'Atividade',
              field: 'tipoDeAtividade',
              cellStyle: { ...TABLE_STYLE, width: '15%' },
            },
            {
              title: 'Estado',
              field: 'uf',
              cellStyle: { ...TABLE_STYLE },
            },
            {
              title: 'Status',
              field: 'ativo',
              cellStyle: { ...TABLE_STYLE, width: '5%' },
              render: ({ ativo }) => (ativo ? 'Ativo' : 'Inativo'),
            },
            {
              title: 'Ações',
              cellStyle: {
                width: '2%',
                ...TABLE_STYLE,
              },
              render: ({ id }) => (
                <IconButton color="primary" onClick={() => handleOnEdit(id)}>
                  <EditIcon fontSize="small" />
                </IconButton>
              ),
            },
          ]}
        />
      )}
    </>
  );
};

export default ParticipantList;
