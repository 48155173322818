import { action } from 'typesafe-actions';
import { SkuTypes, ISkuEdit, IEanItem } from './';

export const loadSku = (id: number) => action(SkuTypes.LOAD_REQUEST, { id });
export const loadSkuSuccess = (data: IEanItem[]) => action(SkuTypes.LOAD_SUCCESS, { data });

export const loadAllSkuList = () => action(SkuTypes.LOAD_ALL_SKU_REQUEST);
export const loadAllSkuListSuccess = (data: IEanItem[]) =>
  action(SkuTypes.LOAD_ALL_SKU_SUCCESS, { data });

export const loadSkuFailure = () => action(SkuTypes.LOAD_FAILURE);
export const resetSku = () => action(SkuTypes.RESET_SKU);

export const createSku = (data: ISkuEdit) => action(SkuTypes.CREATE_REQUEST, { data });
export const createSkuSuccess = data => action(SkuTypes.CREATE_SUCCESS, { data });

export const updateSku = (data: ISkuEdit) => action(SkuTypes.UPDATE_REQUEST, { data });
export const updateSkuSuccess = data => action(SkuTypes.UPDATE_SUCCESS, { data });
