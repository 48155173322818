import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import store from 'store';
import { showToastr } from 'store/toastr/actions';
import * as Yup from 'yup';
import Api from '../../../common/Api';

export interface IModalChangePasswordState {
  open: boolean;
  onConfirm: any;
}

interface ModalChangePassword {
  title: string;
  state: IModalChangePasswordState;
  setState: Dispatch<SetStateAction<IModalChangePasswordState>>;
  size?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
}

export interface ChangePassword {
  senhaAtual: string;
  senha: string;
  ConfirmarNovaSenha: string;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: theme.typography.fontFamily,
      height: '80vh',
    },
    label: {
      fontWeight: 'bolder',
      fontSize: '10px',
      marginBottom: theme.spacing(1),
      display: 'block',
    },
    spacingBottom: {
      marginBottom: theme.spacing(2),
    },
  }),
);

const schema = Yup.object().shape({
  senhaAtual: Yup.string().required('Preencha a senha antiga'),
  senha: Yup.string()
    .min(6, 'Sua nova senha deve ter no mínimo 6 caracteres')
    .required('Preencha a nova senha'),
  ConfirmarNovaSenha: Yup.string().oneOf([Yup.ref('senha'), null], 'Senhas não correspondem'),
});

const ModalResetPassword: React.FC<ModalChangePassword> = ({ state, setState, title, size }) => {
  const classes = useStyle();

  const [loginResetData, setLoginResetData] = React.useState<ChangePassword>({
    senhaAtual: '',
    senha: '',
    ConfirmarNovaSenha: '',
  });

  const [isButtonLoginDisabled, setIsButtonLoginDisabled] = useState(true);
  const [error, setError] = React.useState(false);
  const [helperText, setHelperText] = React.useState('');

  useEffect(() => {}, [loginResetData]);

  const handleLoginResetChange = (prop: keyof ChangePassword) => async (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    setLoginResetData({ ...loginResetData, [prop]: event.target.value });
    let form = loginResetData;
    form[prop as string] = event.target.value;

    await schema
      .validate(form, {
        abortEarly: false,
      })
      .then(function(value) {
        setError(false);
        setIsButtonLoginDisabled(false);
        setHelperText('');
      })
      .catch(function(err) {
        if (err.errors && err.errors.length > 0) {
          setHelperText(err.errors[0]);
          setError(true);
          setIsButtonLoginDisabled(true);
        }
      });
  };

  const handleOnCofirm = async () => {
    try {
      await schema.validate(loginResetData, {
        abortEarly: false,
      });

      const resetPassword = await Api.post('/usuarios/nova-senha', loginResetData);

      if (resetPassword && resetPassword.data && resetPassword.data.success) {
        if (state.onConfirm) state.onConfirm();
        setState({ ...state, open: false, onConfirm: null });
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        let errors = '';
        err.errors.forEach((er: string, index: number) => {
          errors += er;
          if (err.errors.length > 1 && err.errors.length < index) {
            errors += ', ';
          }
        });
        store.dispatch(
          showToastr({
            message: errors,
            open: true,
            type: 'error',
          }),
        );
      }
    }
  };

  return (
    <Dialog className={classes.root} open={state.open}>
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent dividers={true}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="SenhaAtual"
          label="Digite sua senha Atual"
          name="SenhaAtual"
          type="password"
          autoFocus
          onChange={handleLoginResetChange('senhaAtual')}
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="senha"
          label="Digite uma nova senha"
          name="senha"
          type="password"
          onChange={handleLoginResetChange('senha')}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="ConfirmarNovaSenha"
          type="password"
          label="Digite a confirmação da nova senha"
          name="ConfirmarNovaSenha"
          onChange={handleLoginResetChange('ConfirmarNovaSenha')}
        />
        <FormHelperText error={error}>{helperText}</FormHelperText>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={() => setState({ ...state, open: false, onConfirm: null })}
        >
          Cancelar
        </Button>
        <Button color="primary" onClick={handleOnCofirm} disabled={isButtonLoginDisabled}>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalResetPassword;
