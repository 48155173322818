import React, { useState, useEffect } from 'react';
import { Box, TextField, createStyles, makeStyles, Theme, Grid } from '@material-ui/core';
import BtnSteps from './BtnSteps';
import store from 'store';
import { showToastr } from 'store/toastr/actions';
import TrackImport from './TrackImport';
import TrackUfList from './ufList/TrackUfList';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    faixas: {
      marginTop: 13,
    },
    button: {
      marginRight: theme.spacing(1),
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    completed: {
      display: 'inline-block',
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    buttonActions: {
      padding: theme.spacing(1),
      minWidth: 120,
      textAlign: 'right',
      '& > button': {
        marginRight: 4,
      },
    },
  }),
);

function parsePercent(value): number {
  if (value) {
    const val = parseFloat(value.replace(/\D/g, ''));
    if (!isNaN(val) && val > 0) {
      return val;
    }
  }
  return 0;
}

const TrackComponent: React.FC<any> = ({
  trackData,
  number,
  numberOfSteps,
  saveTrack,
  idCampanha,
  handleNext,
  handleBack,
}) => {
  const classes = useStyles();
  const [formError, setFormError] = useState(false);

  const [dados, setDados] = useState(trackData);

  function validateForm() {
    if (dados && dados.percentual > 0 && dados.pacotes > 0) {
      return true;
    }

    if (!dados || !dados.percentual || dados.percentual <= 0) {
      setFormError(true);

      store.dispatch(
        showToastr({
          message: 'O campo "Percentual" deve ser preenchido com um valor maior que zero',
          open: true,
          type: 'error',
        }),
      );

      return false;
    }

    if (!dados || !dados.pacotes || dados.pacotes <= 0) {
      setFormError(true);

      store.dispatch(
        showToastr({
          message: 'O campo "Pacotes" deve ser preenchido com um valor maior que zero',
          open: true,
          type: 'error',
        }),
      );

      return false;
    }

    return false;
  }

  useEffect(() => {
    setDados(trackData);
  }, [trackData]);

  async function SaveTrack() {
    dados.idCampanha = idCampanha;
    dados.faixa = number;

    if (validateForm()) await saveTrack(dados);
  }

  async function nextTrack() {
    if (validateForm()) {
      handleNext();
    }
  }

  async function prevTrack() {
    handleBack();
  }

  return (
    <div>
      <Grid container spacing={2} xs={12}>
        <Grid item xs={6}>
          <TextField
            id="percentual"
            label="Percentual"
            onChange={e =>
              setDados({
                ...dados,
                ['percentual']: parsePercent(e.target.value),
              })
            }
            value={dados && dados.percentual != 0 ? dados.percentual : ''}
            variant="outlined"
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="pacotes"
            // error={formError}
            label="Pacotes"
            onChange={e =>
              setDados({
                ...dados,
                ['pacotes']: Number(e.target.value),
              })
            }
            value={dados && dados?.pacotes != 0 ? dados.pacotes : ''}
            variant="outlined"
            fullWidth
          />
        </Grid>

        <Grid item xs={12}></Grid>

        <BtnSteps
          classes={classes}
          nextTrack={nextTrack}
          handleBack={prevTrack}
          saveTrack={SaveTrack}
          activeStep={number}
          numberOfSteps={numberOfSteps}
        />

        <Grid item xs={12} />

        {trackData?.idFaixa > 0 && (
          <Grid item xs={12}>
            <Box fontSize="h5.fontSize" mt="1em">
              Listagem de UFS X EAN/SKU
            </Box>

            <TrackUfList campanhaFaixaAtingimentoId={trackData?.idFaixa} />
          </Grid>
        )}

        <Grid item xs={12} />

        {trackData?.idFaixa > 0 && (
          <Grid item xs={12}>
            <TrackImport numeroFaixa={number} campanhaFaixaAtingimentoId={trackData?.idFaixa} />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default TrackComponent;
