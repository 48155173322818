import { AppMessages } from './../../common/AppMessages.enum';
import { showToastr } from './../toastr/actions';
import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import { loadRegionsSuccess } from './actions';

export function* loadRegions() {
  try {
    const { data } = yield call(API.get, 'shared/ufs', {});
    yield put(loadRegionsSuccess(data));
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
  }
}
