import { Reducer } from 'redux';

export enum BenefitMailingTypes {
  LOAD_REQUEST = '@benefitMaling/LOAD_REQUEST',
  LOAD_SUCCESS = '@benefitMaling/LOAD_SUCCESS',
  LOAD_FAILURE = '@benefitMaling/LOAD_FAILURE',
  UPLOAD_REQUEST = '@benefitMaling/UPLOAD_REQUEST',
  UPLOAD_SUCCESS = '@benefitMaling/UPLOAD_SUCCESS',
  UPLOAD_FAILURE = '@benefitMaling/UPLOAD_FAILURE',
  DELETE_REQUEST = '@benefitMaling/DELETE_REQUEST',
  DELETE_SUCCESS = '@benefitMaling/DELETE_SUCCESS',
  DELETE_FAILURE = '@benefitMaling/DELETE_FAILURE',
}

export interface IMailing {
  id: number;
  usuario: string;
  downloadUrl: string;
  dataInclusao: string;
  filename: string;
}

export interface IBenefitMailingState {
  data: IMailing[];
  loading: boolean;
  error: boolean;
}

const INITIAL_STATE: IBenefitMailingState = {
  data: [],
  loading: false,
  error: false,
};

const reducer: Reducer<IBenefitMailingState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BenefitMailingTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case BenefitMailingTypes.LOAD_SUCCESS:
      return { ...state, loading: false, data: [...action.payload.data] };
    case BenefitMailingTypes.LOAD_FAILURE:
      return { ...state, loading: false };
    case BenefitMailingTypes.UPLOAD_REQUEST:
      return { ...state, loading: true };
    case BenefitMailingTypes.UPLOAD_SUCCESS:
      return { ...state, loading: false, data: [...state.data, { ...action.payload.data }] };
    case BenefitMailingTypes.UPLOAD_FAILURE:
      return { ...state, loading: false };
    case BenefitMailingTypes.DELETE_REQUEST:
      return { ...state, loading: true };
    case BenefitMailingTypes.DELETE_SUCCESS:
      return { ...state, loading: false, data: [...state.data] };
    case BenefitMailingTypes.DELETE_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default reducer;
