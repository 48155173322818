import React, { useState, SetStateAction } from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Theme,
  createStyles,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Utils from 'common/Utils';
import Api from 'common/Api';
import { EditorState } from 'draft-js';

export interface IModalSinglePatternEdit {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<SetStateAction<any>>;
  item: any;
  forKey: string;
  title: string;
  onConfirm: any;
  uploadUrl: string;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: theme.typography.fontFamily,
    },
    label: {
      fontWeight: 'bolder',
      fontSize: '14px',
      marginBottom: theme.spacing(1),
      display: 'block',
    },
    spacingBottom: {
      marginBottom: theme.spacing(2),
    },
    minHeight: {
      minHeight: '450px',
    },
  }),
);

const ModalSinglePatternEdit: React.FC<IModalSinglePatternEdit> = ({
  isModalOpen,
  setIsModalOpen,
  item,
  forKey,
  title,
  onConfirm,
  uploadUrl,
}) => {
  const classes = useStyle();
  const [state, setState] = useState<{ item: any; html: string }>({
    item,
    html: EditorState.createEmpty(),
  });

  React.useEffect(() => {
    if (item.html !== '') {
      setState({
        ...state,
        item,
        html: item.html ? Utils.stringToEditorText(item.html) : EditorState.createEmpty(),
      });
    }
  }, [item]);

  const handleOnConfirm = () => {
    if (onConfirm) {
      const data = Utils.getEditorData(state.html);
      onConfirm(item.clusterId, data);
    }

    onCloseModal();
  };

  const onCloseModal = () => {
    setIsModalOpen({
      item: {
        perfilId: 0,
        perfilNome: '',
        [forKey]: 0,
        html: '',
      },
      open: false,
    });
    setState({
      item: { perfilId: 0, perfilNome: '', [forKey]: 0, html: '' },
      html: EditorState.createEmpty(),
    });
  };

  const uploadImageCallBack = async (file: any, resetFileUpload) => {
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();
      formData.append('file', file);
      const { data } = await Api.post(uploadUrl, formData);
      resolve({
        data: {
          link: data.url,
        },
      });
    });
  };

  return (
    <Dialog className={classes.root} maxWidth="md" fullWidth={true} open={isModalOpen}>
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent dividers={true}>
        <Box className={classes.minHeight}>
          <Editor
            toolbar={{
              image: {
                uploadCallback: uploadImageCallBack,
                urlEnabled: true,
                uploadEnabled: true,
                previewImage: true,
              },
            }}
            editorState={state.html}
            onEditorStateChange={editorState => {
              setState({ ...state, html: editorState });
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseModal}>Fechar</Button>
        <Button onClick={() => handleOnConfirm()} variant="contained" color="primary">
          Salvar comunicação
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalSinglePatternEdit;
