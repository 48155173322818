import React, { useEffect } from 'react';
import Wrap from 'components/Wrap/Wrap';
import { Breadcrumbs, Typography, Link, Button, Box, IconButton } from '@material-ui/core';
import { ApplicationState } from 'store';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as CampaignActions from 'store/campaign/list/actions';
import { ICampaignListItem } from 'store/campaign/list';
import { TABLE_STYLE } from 'common/Constants';
import EditIcon from '@material-ui/icons/Edit';
import { RouteComponentProps } from 'react-router-dom';
import GenericTable from 'components/GenericTable/GenericTable';

interface IProps extends RouteComponentProps {
  campaigns: ICampaignListItem[];
  loading: boolean;
  loadCampaignListRequest: () => void;
}

const CampaignList: React.FC<IProps> = ({
  campaigns,
  loadCampaignListRequest,
  loading,
  history,
}) => {
  useEffect(() => {
    loadCampaignListRequest();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnEdit = (id: number) => history.push(`/campanhas/gestaodecampanhas/editar/${id}`);
  const handleOnCreate = () => history.push(`/campanhas/gestaodecampanhas/cadastrar`);

  return (
    <>
      <Wrap>
        <Breadcrumbs>
          <Link color="inherit" onClick={() => history.push('/')}>
            Home
          </Link>
          <Typography color="textPrimary">Gestão de Campanhas</Typography>
        </Breadcrumbs>
      </Wrap>

      <Box m="0 0 1.5em 0">
        <Button variant="contained" color="primary" onClick={handleOnCreate}>
          Criar Campanha
        </Button>
      </Box>

      <GenericTable
        title="Campanhas"
        isLoading={loading}
        data={campaigns}
        options={{
          paging: true,
          search: true,
          pageSize: 10,
          sorting: false,
        }}
        columns={[
          {
            searchable: true,
            title: 'Nome',
            field: 'nome',
            cellStyle: { ...TABLE_STYLE, width: '60%' },
          },
          {
            searchable: false,
            title: 'Vigência De',
            field: 'vigenciaDe',
            cellStyle: { ...TABLE_STYLE, width: '15%' },
          },
          {
            searchable: false,
            title: 'Até',
            field: 'vigenciaAte',
            cellStyle: { ...TABLE_STYLE, width: '15%' },
          },
          {
            searchable: false,
            title: 'Ações',
            cellStyle: {
              width: '10%',
              ...TABLE_STYLE,
            },
            render: ({ id }) => (
              <IconButton color="primary" onClick={() => handleOnEdit(id)}>
                <EditIcon fontSize="small" />
              </IconButton>
            ),
          },
        ]}
      />
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  campaigns: state.campaignList.data,
  loading: state.campaignList.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(CampaignActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CampaignList);
