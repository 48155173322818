import { Reducer } from 'redux';

export enum ClustersTypes {
  LOAD_REQUEST = '@clusters/LOAD_REQUEST',
  LOAD_SUCCESS = '@clusters/LOAD_SUCCESS',
  LOAD_FAILURE = '@clusters/LOAD_FAILURE',
}

export interface ICluster {
  id: number;
  nome: string;
}

export interface IClusterState {
  data: ICluster[];
  loading: boolean;
  error: boolean;
}

// (Indefinido = 0), (Bronze = 1), (Prata = 2), (Ouro = 3), (Diamante = 4);

const INITIAL_STATE: IClusterState = {
  data: [
    { id: 1, nome: 'Prime' },
    { id: 2, nome: 'Varejo' },
  ],
  loading: false,
  error: false,
};

const reducer: Reducer<IClusterState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ClustersTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case ClustersTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data.clusters,
      };
    case ClustersTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true, data: { ...INITIAL_STATE.data } };
    default:
      return state;
  }
};

export default reducer;
