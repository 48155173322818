import { Reducer } from 'redux';
import Utils from 'common/Utils';

export enum UserListTypes {
  LOAD_REQUEST = '@UserList/LOAD_REQUEST',
  LOAD_SUCCESS = '@UserList/LOAD_SUCCESS',
  LOAD_FAILURE = '@UserList/LOAD_FAILURE',
}

export interface IUserListItem {
  id: number;
  nome: string;
  email: string;
  telefone: string;
  status: string;
  perfil: string;
}

export interface IUserListState {
  readonly data: IUserListItem[];
  readonly loading: boolean;
  readonly error: boolean;
}

const INITIAL_STATE: IUserListState = {
  data: [],
  loading: false,
  error: false,
};

const reducer: Reducer<IUserListState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserListTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case UserListTypes.LOAD_SUCCESS:
      return { ...state, loading: false, error: false, data: action.payload.data.data };
    case UserListTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true, data: [] };
    default:
      return state;
  }
};

export default reducer;
