import { action } from 'typesafe-actions';
import { BenefitEditTypes, IBenefitEdit } from './';

export const loadBenefit = (id: number) => action(BenefitEditTypes.LOAD_REQUEST, { id });
export const loadBenefitSuccess = (data: IBenefitEdit) =>
  action(BenefitEditTypes.LOAD_SUCCESS, { data });
export const resetBenefit = () => action(BenefitEditTypes.RESET_BENEFIT);

export const createBenefit = data => action(BenefitEditTypes.CREATE_REQUEST, data);
export const createBenefitSuccess = data => action(BenefitEditTypes.CREATE_SUCCESS, data);
export const createBenefitFailure = () => action(BenefitEditTypes.CREATE_FAILURE);

export const applyBenefitFilter = data =>
  action(BenefitEditTypes.APPLYFILTER_REQUEST, { id: data.id, filter: data.filter });
export const applyBenefitFilterSuccess = data => action(BenefitEditTypes.APPLYFILTER_SUCCESS, data);

export const loadBenefitFilters = (benefitId: number, moduleType: number) =>
  action(BenefitEditTypes.GETFILTER_REQUEST, { benefitId, moduleType });
export const loadCampaignFiltersSuccess = data => action(BenefitEditTypes.GETFILTER_SUCCESS, data);
export const loadCampaignFiltersCompleted = () => action(BenefitEditTypes.GETFILTER_COMPLETED);
export const resetCampaignFilters = () => action(BenefitEditTypes.GETFILTER_RESET);
export const setCampaignFilterCompleted = () => action(BenefitEditTypes.GETFILTER_SETCOMPLETED);

export const uploadCampaignBanner = (data: FormData, id: number) =>
  action(BenefitEditTypes.UPLOAD_BANNER_REQUEST, { data, id });
export const uploadCampaignBannerSuccess = data =>
  action(BenefitEditTypes.UPLOAD_BANNER_SUCCESS, { data });
export const uploadCampaignBannerFailure = () => action(BenefitEditTypes.UPLOAD_BANNER_FAILURE);

export const updateHasRegulation = (campaignId: number, value: boolean) =>
  action(BenefitEditTypes.UPDATE_HAS_REGULATION_REQUEST, { campaignId, value });
export const updateHasRegulationSuccess = value =>
  action(BenefitEditTypes.UPDATE_HAS_REGULATION_SUCCESS, { value });

export const updateRequiredRegulation = (campaignId: number, value: boolean) =>
  action(BenefitEditTypes.UPDATE_REQUIRED_REGULATION_REQUEST, { campaignId, value });
export const updateRequiredRegulationSuccess = value =>
  action(BenefitEditTypes.UPDATE_REQUIRED_REGULATION_SUCCESS, { value });

export const loadCampaignFilterAppliedParticipantCount = (count: number) =>
  action(BenefitEditTypes.SETFILTER_COUNT_REQUEST, { count });

export const loadBenefitTargetPublicOptions = () =>
  action(BenefitEditTypes.LOAD_TARGETPUBLIC_REQUEST);
export const loadTargetPublicOptionsSuccess = data =>
  action(BenefitEditTypes.LOAD_TARGETPUBLIC_SUCCESS, { data });
export const loadTargetPublicOptionsFailure = () =>
  action(BenefitEditTypes.LOAD_TARGETPUBLIC_FAILURE);
