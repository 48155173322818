import { loadCampaign as loadCampaignRefresh, resetCampaign } from './actions';
import { AppMessages } from 'common/AppMessages.enum';
import { showToastr } from 'store/toastr/actions';
import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import {
  loadCampaign as loadCampaignRequest,
  loadCampaignSuccess,
  createCampaignSuccess,
  createCampaignFailure,
  loadCampaignFiltersSuccess,
  applyCampaignFilterSuccess,
  loadCampaignFiltersCompleted,
  uploadCampaignBannerSuccess,
  uploadCampaignBannerFailure,
  updateHasRegulationSuccess,
  updateRequiredRegulationSuccess,
  loadCampaignFilterAppliedParticipantCount,
  loadTargetPublicOptionsSuccess,
  loadTargetPublicOptionsFailure,
} from './actions';

import { loadMailing } from 'store/mailing/actions';
import { push } from 'connected-react-router';
import Utils from 'common/Utils';
import { STRUCTURE_PULICTARGET, MODULE_TYPE } from 'common/Constants';

export function* loadCampaign(action) {
  try {
    const { data } = yield call(API.get, `/campanha/${action.payload.id}`);
    yield put(resetCampaign());
    yield put(loadCampaignSuccess(data));
  } catch (error) {
    yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
    Utils.redirect(error);
  }
}

export function* createCampaign(action) {
  try {
    if (action.payload.id === 0) {
      const { data } = yield call(API.post, `/campanha/create`, action.payload);

      yield put(createCampaignSuccess({ ...data, ...action.payload }));
      yield put(
        showToastr({ message: AppMessages.SUCCESS_CREATE_CAMPAIGN, type: 'success', open: true }),
      );
      yield put(push(`/campanhas/gestaodecampanhas/editar/${data.id}`));
    } else {
      const { data } = yield call(
        API.patch,
        `/campanha/${action.payload.id}/update`,
        action.payload,
      );

      yield put(createCampaignSuccess({ ...data, ...action.payload }));
      yield put(
        showToastr({ message: AppMessages.SUCCESS_CREATE_CAMPAIGN, type: 'success', open: true }),
      );
      yield put(push(`/campanhas/gestaodecampanhas/editar/${action.payload.id}`));
    }
  } catch (error) {
    yield put(createCampaignFailure());
    yield put(
      showToastr({ message: AppMessages.ERROR_CREATE_CAMPAIGN, type: 'error', open: true }),
    );
  }
}

export function* applyCampaignFilter(action) {
  try {
    const { data } = yield call(
      API.post,
      `/campanha/${action.payload.id}/filtro`,
      action.payload.filter,
    );
    yield put(applyCampaignFilterSuccess(data));
    yield put(
      showToastr({ message: AppMessages.SUCCESS_APPLYFILTER, type: 'success', open: true }),
    );
    yield put(push(`/campanhas/gestaodecampanhas/editar/${action.payload.id}`));
    yield put(loadMailing(action.payload.id, MODULE_TYPE.CAMPAIGN));
    yield put(loadCampaignRefresh(action.payload.id));
  } catch (error) {
    yield put(createCampaignFailure());
  }
}

export function* loadCampaignFilters(action) {
  try {
    const { data } = yield call(
      API.get,
      `/campanha/${action.payload.campaignId}/filtro/${action.payload.moduleType}`,
    );

    yield put(loadCampaignFiltersSuccess(data));

    // console.log('dt sagas campanha', data);

    // if (data.filtros.ufs.length > 0) {
    //   // const regs = data.filtros.ufs.map(e => e.name);
    //   yield put(loadIndustriesByRegions(data.filtros.ufs));
    //   // yield put(loadIndustriesByRegions(regs));
    // }

    // if (data.filtros.atividades.length > 0) {
    // // if (data.filtros.distribuidores.length > 0) {
    //   const dists = data.filtros.atividades.map(e => e.name);
    //   yield put(loadClustersByIndustries(data.filtros.atividades));
    //   // yield put(loadClustersByIndustries(dists));
    // }

    // if (data.filtros.clusters.length > 0) {
    //   const ownClusters = store.getState().clusters.data;
    //   const clusters = ownClusters
    //     .filter(e => data.filtros.clusters.map(x => x.name).includes(e.nome))
    //     .map(e => e.id);
    //   yield put(loadSegmentsByClusters(clusters));
    // }

    yield put(loadCampaignFiltersCompleted());

    if (action.payload.moduleType > 0) {
      let contagem;
      if (data.filtros && action.payload.moduleType === STRUCTURE_PULICTARGET.PDV)
        contagem = yield call(API.post, `/campanha/contagem/filtro`, [
          ...data.filtros.atividades.map(({ id, name, selected, tipo, value }) => {
            return {
              id,
              nome: name,
              selected,
              tipo,
              value,
            };
          }),
        ]);

      if (data.filtros && action.payload.moduleType === STRUCTURE_PULICTARGET.SALES_FORCE)
        contagem = yield call(API.post, `/campanha/contagem/filtro`, [
          ...data.filtros.perfis.map(({ id, name, selected, tipo, value }) => {
            return {
              id,
              nome: name,
              selected,
              tipo,
              value,
            };
          }),
        ]);

      // console.log('contagem 152 sagas.ts', contagem);

      yield put(loadCampaignFilterAppliedParticipantCount(contagem.data.total));
    }
  } catch (error) {
    console.log('erro catch faiilure campaign', error);
    yield put(createCampaignFailure());
    yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
  }
}

export function* uploadCampaignBanner(action) {
  try {
    const { data } = yield call(
      API.post,
      `/campanha/${action.payload.id}/banner/upload`,
      action.payload.data,
    );
    yield put(uploadCampaignBannerSuccess(data));
    yield put(
      showToastr({ message: AppMessages.SUCCESS_UPLOAD_BANNER, type: 'success', open: true }),
    );
    yield put(loadCampaignRequest(action.payload.id));
  } catch (error) {
    yield put(uploadCampaignBannerFailure());
    yield put(showToastr({ message: AppMessages.ERROR_UPLOAD_BANNER, type: 'error', open: true }));
  }
}

export function* updateHasRegulation(action) {
  const campaignId = action.payload.campaignId;
  const value = action.payload.value;
  try {
    yield call(API.patch, `campanha/${campaignId}/regulamento/${value}`);
    yield put(updateHasRegulationSuccess(value));
    yield put(
      showToastr({
        type: 'success',
        message: AppMessages.UPDATE_HAS_REGULATION_SUCCESS,
        open: true,
      }),
    );
  } catch (error) {
    yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
  }
}

export function* updateRequiredRegulation(action) {
  const campaignId = action.payload.campaignId;
  const value = action.payload.value;
  try {
    yield call(API.patch, `campanha/${campaignId}/regulamento/aceite/${value}`);
    yield put(updateRequiredRegulationSuccess(value));
    yield put(
      showToastr({
        type: 'success',
        message: AppMessages.UPDATE_REQUIRED_REGULATION_SUCCESS,
        open: true,
      }),
    );
  } catch (error) {
    yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
  }
}

export function* loadTargetPublicOptions(action) {
  try {
    const campaignId = action.payload.campaignId;
    const { data } = yield call(API.get, `/shared/ElegivelFiltroTipos/${campaignId}`);

    yield put(loadTargetPublicOptionsSuccess(data));
  } catch (error) {
    yield put(loadTargetPublicOptionsFailure());
    yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
  }
}
