import { action } from 'typesafe-actions';
import { TotalMeiosPagamentosTypes, IRealtimeItem } from './';

export const loadRealtimeTotalMeiosPagamentosRequest = () =>
  action(TotalMeiosPagamentosTypes.LOAD_REQUEST);
export const loadRealtimeTotalMeiosPagamentosSuccess = (
  data: IRealtimeItem[],
  atualizacao: string,
) => action(TotalMeiosPagamentosTypes.LOAD_SUCCESS, { data, atualizacao });
export const loadRealtimeTotalMeiosPagamentosFailure = () =>
  action(TotalMeiosPagamentosTypes.LOAD_FAILURE);
