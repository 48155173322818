import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import {
  loadUploadChainingNetworkFile as loadUploadFileAction,
  loadUploadFileSuccess,
  loadUploadFileFailure,
  uploadChainingNetworkCsvSuccess,
  uploadChainingNetworkCsvFailure,
  deleteChainingNetworkCsvSuccess,
  deleteChainingNetworkCsvFailure,
} from './actions';
import { showToastr } from 'store/toastr/actions';
import { AppMessages } from 'common/AppMessages.enum';
import store from 'store';
import { MODULE_TYPE } from 'common/Constants';

export function* loadUploadChainingNetworkFile(action) {
  try {
    const { data } = yield call(API.get, `encadeamentoRede/${action.payload.id}/UploadManual`);
    yield put(loadUploadFileSuccess(data.data));
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
    yield put(loadUploadFileFailure());
  }
}

export function* uploadChainingNetworkCsv(action) {
  try {
    const { data } = yield call(API.post, action.payload.uploadUrl, action.payload.data);
    if (data.sucesso) {
      yield put(uploadChainingNetworkCsvSuccess(data));
      yield put(
        showToastr({
          type: 'success',
          message: AppMessages.CAMPAIGN_REPORT_UPLOAD_SUCCESS,
          open: true,
        }),
      );
    } else {
      yield put(
        showToastr({ type: 'error', message: AppMessages.ERROR_MAILING_PROCCESS, open: true }),
      );
      yield put(uploadChainingNetworkCsvFailure());
    }
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
    yield put(uploadChainingNetworkCsvFailure());
  }
}

export function* deleteChainingNetworkCsv(action) {
  try {
    const { data } = yield call(API.delete, action.payload.deleteUrl);
    yield put(deleteChainingNetworkCsvSuccess(data));
    yield put(
      showToastr({
        type: 'success',
        message: AppMessages.UPLOAD_CAMPAIGN_REPORT_DELETE_SUCCESS,
        open: true,
      }),
    );
    const module = { id: Number(data.id) };
    yield put(loadUploadFileAction(module.id));
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
    yield put(deleteChainingNetworkCsvFailure());
  }
}
