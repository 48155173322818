import { all, takeLatest, takeEvery } from 'redux-saga/effects';

//User List
import { UserListTypes } from './user/list';
import { loadUserList } from './user/list/sagas';

// Campaign List
import { CampaignListTypes } from './campaign/list';
import { loadCampaignList } from './campaign/list/sagas';

import { ConectaPayListTypes } from './conectaPay/list';
import { loadConectaPayList } from './conectaPay/list/sagas';

// Campaign Edit
import { CampaignEditTypes } from './campaign/edit';
import {
  loadCampaign,
  createCampaign,
  applyCampaignFilter,
  loadCampaignFilters,
  uploadCampaignBanner,
  updateHasRegulation,
  updateRequiredRegulation,
  loadTargetPublicOptions,
} from './campaign/edit/sagas';

import { CampaignCountingTypes } from './campaign/report';
import {
  loadCampaignCounting,
  generateCampaignCountingReport,
  loadVolume,
  loadDetailsVolume,
  uploadVolumeByFile,
} from './campaign/report/sagas';

import { ConectaPayCountingTypes } from './conectaPay/report';
import {
  loadConectaPayCounting,
  generateConectaPayCountingReport,
} from './conectaPay/report/sagas';

//Perfil
import { PerfilTypes } from './perfil';
import { loadPerfil } from './perfil/sagas';

// Industries
import { IndustryTypes } from './industries';
import { loadIndustries, loadIndustriesByRegions } from './industries/sagas';

// Industries
import { DistribuitorTypes } from './distribuitors';
import { loadDistribuitors, loadDistribuitorsByRegions } from './distribuitors/sagas';

// Regions
import { RegionsTypes } from './regions/index';
import { loadRegions } from './regions/sagas';

// Clusters
import { ClustersTypes } from './clusters/index';
import { loadClustersByIndustries, loadClusters } from './clusters/sagas';

// Profiles
import { ProfileTypes } from './profiles';
import { loadProfiles } from './profiles/sagas';

// Payments
import { PaymentsTypes } from './payments';
import {
  loadCuttOffList,
  createCuttOffDate,
  loadHistoricByUpload,
  loadHistoricFilterPDVList,
  updateCuttOffDate,
  uploadFilterPDV,
  loadUploadPDVDetailList,
  uploadFile,
} from './payments/sagas';

// Segments
import { SegmentTypes } from './segments';
import { loadSegmentsByClusters, loadSegments } from './segments/sagas';

// Kpi
import { KpiTypes } from './kpi';
import {
  loadKPIs,
  loadConfiguredKpi,
  saveKpi,
  uploadKpiFile,
  loadFdvProfiles,
  uploadKpiFileSellin,
  uploadKpiFileSellinVeo,
  uploadKpiFileVeo,
  uploadKpiFileCampaignRegular,
} from './kpi/sagas';

// Mailing
import { MailingTypes } from './mailing';
import { loadMailing, uploadMailing, deleteMailing } from './mailing/sagas';

// Plan Management
import { PlanManagementTypes } from './planManagement';
import {
  loadAllPlanAvaliable,
  loadFileDetails,
  loadImportationPlanbyLot,
} from './planManagement/sagas';

// Reactivation & Reentry
import { ReentryConfigStoreTypes } from './reentryConfigStore';
import {
  LoadAllTypesPromotions,
  loadAllUploadHistoric,
  loadUploadDetail,
  LoadAllPromotionRegistred,
  LoadPromotionCampaignById,
  LoadCuttOffDay,
  createFilterPDV,
  loadFilterPDV,
} from './reentryConfigStore/sagas';

// Communication
import { CommunicationTypes } from './communication';
import {
  loadCommunicationItems,
  loadCommunicationItemContent,
  deleteCommunicationItemContent,
  updateCommunicationsContent,
  loadCommunicationPattern,
  uploadCommunicationPattern,
  updateCommunicationPattern,
} from './communication/sagas';

// Regulation
import { RegulationTypes } from './regulation';
import {
  loadRegulationItems,
  loadRegulationItemContent,
  deleteRegulationItemContent,
  updateRegulationsContent,
  loadRegulationPattern,
  uploadRegulationPattern,
  updateRegulationPattern,
} from './regulation/sagas';

// Banners List
import { BannersListTypes } from './banner/list/index';
import { loadBannerTypes, loadBannerByTypes } from './banner/list/sagas';

// Banners Edit
import { BannersEditTypes } from './banner/edit';
import {
  createBanner,
  loadBanner,
  updateBanner,
  applyBannerFilter,
  loadBannerFilters,
  uploadBannerParticipant,
  loadParticipantsFileList,
  deleteParticipantFile,
} from './banner/edit/sagas';

// Popups List
import { PopupsListTypes } from './popup/list/index';
import { loadPopList } from './popup/list/sagas';

// Popups Edit
import { PopupsEditTypes } from './popup/edit';
import {
  createPopup,
  loadPopup,
  updatePopup,
  applyPopupFilter,
  loadPopupFilters,
  uploadPopupParticipant,
  loadFilterClusters,
  loadFilterMethodsPayments,
  loadFilterRegions,
  loadFilterTerritory,
  loadFilterZone,
} from './popup/edit/sagas';

//FDV List
import { FdvListTypes } from './fdvList/index';
import { loadFdvList, loadDistributors } from './fdvList/sagas';

import { PaymentMethodsTypes } from './dashboard/index';
import {
  loadPaymentMethodRequest,
  generatePaymentMethodReportRequest,
  loadPaymentReceivablesMethodRequest,
  generatePaymentReceivablesMethodReportRequest,
} from './dashboard/sagas';

import { GenerateBankSlipTypes } from './GenerateBankSlip/index';
import {
  loadAllUploadBankSlip,
  loadDetailUploadBankSlip,
  uploadSingleBankSlipByFile,
} from './GenerateBankSlip/sagas';

import { CampaignReportTypes } from './campaignReport/index';
import {
  loadTypesCampaignReportRequest,
  generateCampaignReportRequest,
} from './campaignReport/sagas';

import { ChainingNetworkTypes } from './participant/index';
import {
  loadPendingChainingNetRequest,
  approveChainingNetRequest,
  loadStatusRequest,
  loadChainingNetDetailRequest,
  chainingNetReportRequest,
} from './participant/sagas';

import { MigrationOfPointsTypes } from './migrationOfPoints';
import {
  loadStatusRequest as loadStatusRequestMigration,
  loadPendingMigrationsRequest,
  loadPendingMigrationDetailRequest,
  loadPendingMigrationCsvRequest,
  moderateMigrationRequest,
  loadMigrationReportRequest,
} from './migrationOfPoints/sagas';

//DASHBOARD Realtime
import { TotalCadastroTypes } from './realtime/totalCadastros/index';
import { loadRealtimeTotalCadastrosRequest } from './realtime/totalCadastros/sagas';

import { TotalAceitesTypes } from './realtime/totalAceites/index';
import { loadRealtimeTotalAceitesRequest } from './realtime/totalAceites/sagas';

import { TotalUltimosCadastrosTypes } from './realtime/totalUltimosCadastros/index';
import { loadRealtimeTotalCadastrosUltimosDiasRequest } from './realtime/totalUltimosCadastros/sagas';

import { TotalUltimosCancelamentosTypes } from './realtime/totalUltimosCancelamentos/index';
import { loadRealtimeTotalCancelamentosUltimosDiasRequest } from './realtime/totalUltimosCancelamentos/sagas';

import { TotalMeiosPagamentosTypes } from './realtime/totalMeiosPagamentos/index';
import { loadRealtimeTotalMeiosPagamentosRequest } from './realtime/totalMeiosPagamentos/sagas';

import { TotalPrimeirosAcessosClusterTypes } from './realtime/totalPrimeirosAcessosCluster/index';
import { loadRealtimeTotalPrimeirosAcessosClusterRequest } from './realtime/totalPrimeirosAcessosCluster/sagas';

import { TotalErrosAccesstageTypes } from './realtime/totalErrosAccesstage/index';
import { loadRealtimeTotalErrosAccesstageRequest } from './realtime/totalErrosAccesstage/sagas';

import { TotalErrosCadastrosTypes } from './realtime/totalErrosCadastros/index';
import { loadRealtimeTotalErrosCadastrosRequest } from './realtime/totalErrosCadastros/sagas';

import { TotalErrosCancelamentosTypes } from './realtime/totalErrosCancelamentos/index';
import { loadRealtimeTotalErrosCancelamentosRequest } from './realtime/totalErrosCancelamentos/sagas';

import { TotalErrosIntegracaoBoletosTypes } from './realtime/totalErrosIntegracaoBoletos/index';
import { loadRealtimeTotalErrosIntegracaoBoletosRequest } from './realtime/totalErrosIntegracaoBoletos/sagas';

import { TotalMalhaAtivacoesTypes } from './realtime/totalMalhaAtivacoes/index';
import { loadRealtimeTotalMalhaAtivacoesRequest } from './realtime/totalMalhaAtivacoes/sagas';

import { TotalMalhaAtualizacoesTypes } from './realtime/totalMalhaAtualizacoes/index';
import { loadRealtimeTotalMalhaAtualizacoesRequest } from './realtime/totalMalhaAtualizacoes/sagas';

import { TotalMalhaAtualizacoesVolumeTypes } from './realtime/totalMalhaAtualizacoesVolume/index';
import { loadRealtimeTotalMalhaAtualizacoesVolumeRequest } from './realtime/totalMalhaAtualizacoesVolume/sagas';

import { TotalMalhaCancelamentosTypes } from './realtime/totalMalhaCancelamentos/index';
import { loadRealtimeTotalMalhaCancelamentosRequest } from './realtime/totalMalhaCancelamentos/sagas';

import { UploadCampaignReportTypes } from './uploadCampaignReport/index';
import {
  createUploadCampaignReportRequest,
  loadUploadCampaignReport,
} from './uploadCampaignReport/sagas';

import { UploadCampaignListTypes } from './uploadCampaignReport/list/index';
import { loadUploadCampaignReportList } from './uploadCampaignReport/list/sagas';

import { UploadCampaignFileTypes } from './uploadCampaignReport/uploadFile/index';
import {
  loadUploadFile,
  uploadCampaingCsv,
  deleteUploadCampaignCsv,
} from './uploadCampaignReport/uploadFile/sagas';

import { UploadChainingNetworkListTypes } from './chainingNetwork/list';
import { loadUploadChainingNetworkList } from './chainingNetwork/list/sagas';

import { UploadDataFileChainingNetworkTypes } from './chainingNetwork/uploadFile';
import {
  deleteChainingNetworkCsv,
  loadUploadChainingNetworkFile,
  uploadChainingNetworkCsv,
} from './chainingNetwork/uploadFile/sagas';

import { UploadConectaPayFileTypes } from './UploadConectaPayReport/uploadFile/index';
import {
  uploadConectaPayCsv,
  deleteUploadConectaPayCsv,
  loadUploadConectaPayFile,
} from './UploadConectaPayReport/uploadFile/sagas';
import { AutomaticCreditTypes } from './counting/automaticCredit/index';
import {
  approveAutomaticCreditRequest,
  automaticCreditReportRequest,
  loadAutomaticCreditDetailRequest,
  loadPendingAutomaticCreditRequest,
  loadStatusAutomaticCreditRequest,
} from './counting/automaticCredit/sagas';

import { PremiacaoTypes } from './premiacao';
import { loadPremiacao, createPremiacao } from './premiacao/sagas';
import { SkuTypes } from './sku';
import { createSku, loadSku, loadAllSkuList, updateSku } from './sku/sagas';

import { BenefitEditTypes } from './benefit/edit';
import {
  applyBenefitFilter,
  createBenefit,
  loadBenefit,
  loadBenefitTargetPublicOptions,
} from './benefit/edit/sagas';

import { BenefitListTypes } from './benefit/list';
import { loadBenefitList } from './benefit/list/sagas';
import { BenefitMailingTypes } from './benefitMailing';
import {
  deleteBenefitMailing,
  loadBenefitMailing,
  uploadBenefitMailing,
} from './benefitMailing/sagas';

import { ParticipantAllowanceFileTypes } from './participantAllowance/uploadFile';
import {
  loadParticipantAllowanceFile,
  loadFileAllowanceDetails,
  uploadParticipantAllowanceCsv,
} from './participantAllowance/uploadFile/sagas';

import { ParticipantExemptionFileTypes } from './participantExemption/uploadFile';
import {
  loadParticipantExemptionFile,
  loadFileExemptionDetails,
  uploadParticipantExemptionCsv,
} from './participantExemption/uploadFile/sagas';

import { TrackingBonificationReportTypes } from './trackingBonification/report/index';
import {
  loadTrackingBonificationReportCSVRequest,
  loadTrackingBonificationReportRequest,
} from './trackingBonification/report/sagas';
import { ParticipantSeparateInvoiceFileTypes } from './participantSeparateInvoice/uploadFile';

import {
  loadParticipantSeparateInvoiceFile,
  uploadParticipantSeparateInvoiceCsv,
} from './participantSeparateInvoice/uploadFile/sagas';

// Product List
import { ProductListTypes } from './product/list';
import { loadProductList } from './product/list/sagas';

import { TrackImportFileTypes } from './trackImport';
import {
  deleteTrackImportCsv,
  loadTrackImportFile,
  loadFileTrackImportDetails,
  uploadTrackImportCsv,
} from './trackImport/sagas';

export default function* rootSaga() {
  return yield all([
    takeLatest(BannersListTypes.LOAD_TYPES_REQUEST, loadBannerTypes),
    takeLatest(BannersListTypes.LOAD_BANNERS_BY_TYPE_REQUEST, loadBannerByTypes),
    takeLatest(BannersEditTypes.CREATE_REQUEST, createBanner),
    takeLatest(BannersEditTypes.UPDATE_REQUEST, updateBanner),
    takeLatest(BannersEditTypes.LOAD_REQUEST, loadBanner),
    takeLatest(BannersEditTypes.APPLYFILTER_REQUEST, applyBannerFilter),
    takeEvery(BannersEditTypes.GETFILTER_REQUEST, loadBannerFilters),

    takeLatest(PopupsListTypes.LOAD_REQUEST, loadPopList),
    takeLatest(PopupsEditTypes.CREATE_REQUEST, createPopup),
    takeLatest(PopupsEditTypes.UPDATE_REQUEST, updatePopup),
    takeLatest(PopupsEditTypes.LOAD_REQUEST, loadPopup),
    takeLatest(PopupsEditTypes.APPLYFILTER_REQUEST, applyPopupFilter),
    takeEvery(PopupsEditTypes.GETFILTER_REQUEST, loadPopupFilters),

    takeLatest(CampaignListTypes.LOAD_REQUEST, loadCampaignList),
    takeLatest(CampaignEditTypes.LOAD_REQUEST, loadCampaign),
    takeLatest(CampaignEditTypes.CREATE_REQUEST, createCampaign),
    takeLatest(CampaignEditTypes.APPLYFILTER_REQUEST, applyCampaignFilter),
    takeLatest(CampaignEditTypes.GETFILTER_REQUEST, loadCampaignFilters),
    takeEvery(CampaignEditTypes.UPLOAD_BANNER_REQUEST, uploadCampaignBanner),
    takeLatest(CampaignEditTypes.UPDATE_HAS_REGULATION_REQUEST, updateHasRegulation),
    takeLatest(CampaignEditTypes.UPDATE_REQUIRED_REGULATION_REQUEST, updateRequiredRegulation),
    takeLatest(CampaignEditTypes.LOAD_TARGETPUBLIC_REQUEST, loadTargetPublicOptions),
    takeLatest(CampaignCountingTypes.LOAD_REQUEST, loadCampaignCounting),
    takeLatest(CampaignCountingTypes.GENERATE_REPORT_REQUEST, generateCampaignCountingReport),
    takeLatest(CampaignCountingTypes.LOAD_VOLUME_REQUEST, loadVolume),
    takeLatest(CampaignCountingTypes.LOAD_DETAIL_LIST_REQUEST, loadDetailsVolume),
    takeLatest(CampaignCountingTypes.UPLOAD_REQUEST, uploadVolumeByFile),
    takeLatest(CommunicationTypes.LOAD_REQUEST, loadCommunicationItems),
    takeEvery(CommunicationTypes.LOAD_HTML_REQUEST, loadCommunicationItemContent),
    takeLatest(CommunicationTypes.DELETE_HTML_REQUEST, deleteCommunicationItemContent),
    takeLatest(CommunicationTypes.UPDATE_REQUEST, updateCommunicationsContent),
    takeLatest(CommunicationTypes.LOAD_PATTERN_REQUEST, loadCommunicationPattern),
    takeLatest(CommunicationTypes.UPLOAD_PATTERN_REQUEST, uploadCommunicationPattern),
    takeLatest(CommunicationTypes.UPDATE_PATTERN_REQUEST, updateCommunicationPattern),
    takeLatest(ClustersTypes.LOAD_REQUEST, loadClustersByIndustries),
    takeLatest(ClustersTypes.LOAD_REQUEST, loadClusters),
    takeLatest(IndustryTypes.LOAD_REQUEST, loadIndustries),
    takeLatest(RegionsTypes.LOAD_REQUEST, loadRegions),
    takeLatest(SegmentTypes.LOAD_REQUEST, loadSegmentsByClusters),
    takeLatest(SegmentTypes.LOAD_REQUEST, loadSegments),
    takeLatest(KpiTypes.LOAD_REQUEST, loadKPIs),
    takeLatest(KpiTypes.CONFIGURED_KPI_REQUEST, loadConfiguredKpi),
    takeLatest(KpiTypes.SAVE_KPI_REQUEST, saveKpi),
    takeLatest(KpiTypes.UPLOAD_KPI_FILE_CAMPAIGN_REGULAR_REQUEST, uploadKpiFileCampaignRegular),
    takeLatest(KpiTypes.UPLOAD_KPI_FILE_REQUEST, uploadKpiFile),
    takeLatest(KpiTypes.UPLOAD_KPI_FILE_SELLIN_REQUEST, uploadKpiFileSellin),
    takeLatest(KpiTypes.UPLOAD_KPI_FILE_SELLIN_VEO_REQUEST, uploadKpiFileSellinVeo),
    takeLatest(KpiTypes.UPLOAD_KPI_FILE_VEO_REQUEST, uploadKpiFileVeo),
    takeLatest(KpiTypes.FDV_PROFILES_REQUEST, loadFdvProfiles),
    takeLatest(MailingTypes.LOAD_REQUEST, loadMailing),
    takeLatest(MailingTypes.UPLOAD_REQUEST, uploadMailing),
    takeLatest(MailingTypes.DELETE_REQUEST, deleteMailing),
    takeLatest(BenefitMailingTypes.UPLOAD_REQUEST, uploadBenefitMailing),
    takeLatest(BenefitMailingTypes.LOAD_REQUEST, loadBenefitMailing),
    takeLatest(BenefitMailingTypes.DELETE_REQUEST, deleteBenefitMailing),
    takeLatest(RegulationTypes.LOAD_REQUEST, loadRegulationItems),
    takeEvery(RegulationTypes.LOAD_HTML_REQUEST, loadRegulationItemContent),
    takeLatest(RegulationTypes.DELETE_HTML_REQUEST, deleteRegulationItemContent),
    takeLatest(RegulationTypes.UPDATE_REQUEST, updateRegulationsContent),
    takeLatest(RegulationTypes.LOAD_PATTERN_REQUEST, loadRegulationPattern),
    takeLatest(RegulationTypes.UPLOAD_PATTERN_REQUEST, uploadRegulationPattern),
    takeLatest(RegulationTypes.UPDATE_PATTERN_REQUEST, updateRegulationPattern),
    takeLatest(FdvListTypes.LOAD_REQUEST, loadFdvList),
    takeLatest(FdvListTypes.LOAD_DISTRIBUTORS_REQUEST, loadDistributors),
    takeLatest(UserListTypes.LOAD_REQUEST, loadUserList),
    takeLatest(PerfilTypes.LOAD_REQUEST, loadPerfil),
    takeLatest(BannersEditTypes.UPLOAD_PARTICIPANT_REQUEST, uploadBannerParticipant),
    takeLatest(BannersEditTypes.LOAD_PARTICIPANT_REQUEST, loadParticipantsFileList),
    takeLatest(BannersEditTypes.DELETE_PARTICIPANT_REQUEST, deleteParticipantFile),

    takeLatest(PopupsEditTypes.UPLOAD_PARTICIPANT_REQUEST, uploadPopupParticipant),
    takeLatest(PopupsEditTypes.LOAD_PARTICIPANT_REQUEST, loadParticipantsFileList),
    takeLatest(PopupsEditTypes.DELETE_PARTICIPANT_REQUEST, deleteParticipantFile),
    takeLatest(PopupsEditTypes.LOAD_FILTER_CLUSTERS_REQUEST, loadFilterClusters),
    takeLatest(PopupsEditTypes.LOAD_FILTER_METHODS_PAYMENTS_REQUEST, loadFilterMethodsPayments),
    takeLatest(PopupsEditTypes.LOAD_FILTER_REGIONS_REQUEST, loadFilterRegions),
    takeLatest(PopupsEditTypes.LOAD_FILTER_TERRITORY_REQUEST, loadFilterTerritory),
    takeLatest(PopupsEditTypes.LOAD_FILTER_ZONE_REQUEST, loadFilterZone),

    takeLatest(PaymentMethodsTypes.LOAD_PAYMENT_METHOD_REQUEST, loadPaymentMethodRequest),
    takeLatest(
      PaymentMethodsTypes.GENERATE_PAYMENT_METHOD_REPORT_REQUEST,
      generatePaymentMethodReportRequest,
    ),
    takeLatest(GenerateBankSlipTypes.LOAD_REQUEST, loadAllUploadBankSlip),
    takeLatest(GenerateBankSlipTypes.LOAD_DETAIL_REQUEST, loadDetailUploadBankSlip),
    takeLatest(GenerateBankSlipTypes.UPLOAD_REQUEST, uploadSingleBankSlipByFile),
    takeLatest(PaymentsTypes.LOAD_CUTTOFF_LIST_REQUEST, loadCuttOffList),
    takeLatest(PaymentsTypes.CREATE_CUTTOFF_REQUEST, createCuttOffDate),
    takeLatest(PaymentsTypes.UPDATE_CUTTOFF_REQUEST, updateCuttOffDate),
    takeLatest(PaymentsTypes.UPLOAD_FILTER_PDV_REQUEST, uploadFilterPDV),
    takeLatest(PaymentsTypes.LOAD_HISTORIC_BY_UPLOAD_REQUEST, loadHistoricByUpload),
    takeLatest(PaymentsTypes.LOAD_HISTORIC_FILTER_PDV_REQUEST, loadHistoricFilterPDVList),
    takeLatest(PaymentsTypes.LOAD_UPLOAD_PDV_DETAIL_LIST_REQUEST, loadUploadPDVDetailList),
    takeLatest(PaymentsTypes.UPLOAD_FILE_REQUEST, uploadFile),
    takeLatest(
      ChainingNetworkTypes.LOAD_PENDING_CHAINING_NET_REQUEST,
      loadPendingChainingNetRequest,
    ),
    takeLatest(ChainingNetworkTypes.APPROVE_CHAINING_NET_REQUEST, approveChainingNetRequest),
    takeLatest(ChainingNetworkTypes.LOAD_STATUS_REQUEST, loadStatusRequest),
    takeLatest(ChainingNetworkTypes.LOAD_CHAINING_NET_DETAIL_REQUEST, loadChainingNetDetailRequest),
    takeLatest(ChainingNetworkTypes.CHAINING_NET_REPORT_REQUEST, chainingNetReportRequest),
    takeLatest(
      PaymentMethodsTypes.LOAD_PAYMENT_RECEIVABLES_METHOD_REQUEST,
      loadPaymentReceivablesMethodRequest,
    ),
    takeLatest(
      PaymentMethodsTypes.GENERATE_PAYMENT_RECEIVABLES_METHOD_REPORT_REQUEST,
      generatePaymentReceivablesMethodReportRequest,
    ),
    takeLatest(MigrationOfPointsTypes.LOAD_STATUS_REQUEST, loadStatusRequestMigration),
    takeLatest(
      MigrationOfPointsTypes.LOAD_PENDING_MIGRATIONS_REQUEST,
      loadPendingMigrationsRequest,
    ),
    takeLatest(
      MigrationOfPointsTypes.LOAD_PENDING_MIGRATION_DETAIL_REQUEST,
      loadPendingMigrationDetailRequest,
    ),
    takeLatest(
      MigrationOfPointsTypes.LOAD_PENDING_MIGRATION_CSV_REQUEST,
      loadPendingMigrationCsvRequest,
    ),
    takeLatest(MigrationOfPointsTypes.MODERATE_MIGRATION_REQUEST, moderateMigrationRequest),
    takeLatest(MigrationOfPointsTypes.LOAD_MIGRATION_REPORT_REQUEST, loadMigrationReportRequest),
    takeLatest(TotalCadastroTypes.LOAD_REQUEST, loadRealtimeTotalCadastrosRequest),
    takeLatest(TotalAceitesTypes.LOAD_REQUEST, loadRealtimeTotalAceitesRequest),
    takeLatest(
      TotalUltimosCadastrosTypes.LOAD_REQUEST,
      loadRealtimeTotalCadastrosUltimosDiasRequest,
    ),
    takeLatest(
      TotalUltimosCancelamentosTypes.LOAD_REQUEST,
      loadRealtimeTotalCancelamentosUltimosDiasRequest,
    ),
    takeLatest(TotalMeiosPagamentosTypes.LOAD_REQUEST, loadRealtimeTotalMeiosPagamentosRequest),
    takeLatest(
      TotalPrimeirosAcessosClusterTypes.LOAD_REQUEST,
      loadRealtimeTotalPrimeirosAcessosClusterRequest,
    ),

    takeLatest(TotalErrosAccesstageTypes.LOAD_REQUEST, loadRealtimeTotalErrosAccesstageRequest),
    takeLatest(TotalErrosCadastrosTypes.LOAD_REQUEST, loadRealtimeTotalErrosCadastrosRequest),
    takeLatest(
      TotalErrosCancelamentosTypes.LOAD_REQUEST,
      loadRealtimeTotalErrosCancelamentosRequest,
    ),
    takeLatest(
      TotalErrosIntegracaoBoletosTypes.LOAD_REQUEST,
      loadRealtimeTotalErrosIntegracaoBoletosRequest,
    ),

    takeLatest(TotalMalhaAtivacoesTypes.LOAD_REQUEST, loadRealtimeTotalMalhaAtivacoesRequest),
    takeLatest(TotalMalhaAtualizacoesTypes.LOAD_REQUEST, loadRealtimeTotalMalhaAtualizacoesRequest),
    takeLatest(
      TotalMalhaAtualizacoesVolumeTypes.LOAD_REQUEST,
      loadRealtimeTotalMalhaAtualizacoesVolumeRequest,
    ),
    takeLatest(
      TotalMalhaCancelamentosTypes.LOAD_REQUEST,
      loadRealtimeTotalMalhaCancelamentosRequest,
    ),
    takeLatest(UploadCampaignListTypes.LOAD_REQUEST, loadUploadCampaignReportList),
    takeLatest(CampaignReportTypes.GENERATE_CAMPAIGN_REPORT_REQUEST, generateCampaignReportRequest),
    takeLatest(UploadCampaignReportTypes.LOAD_REQUEST, loadUploadCampaignReport),
    takeLatest(UploadCampaignReportTypes.CREATE_REQUEST, createUploadCampaignReportRequest),
    takeLatest(
      CampaignReportTypes.LOAD_TYPE_CAMPAIGN_REPORT_REQUEST,
      loadTypesCampaignReportRequest,
    ),
    takeLatest(CampaignReportTypes.GENERATE_CAMPAIGN_REPORT_REQUEST, generateCampaignReportRequest),
    takeLatest(UploadCampaignFileTypes.LOAD_REQUEST, loadUploadFile),
    takeLatest(UploadCampaignFileTypes.UPLOAD_REQUEST, uploadCampaingCsv),
    takeLatest(UploadCampaignFileTypes.DELETE_REQUEST, deleteUploadCampaignCsv),
    takeLatest(UploadChainingNetworkListTypes.LOAD_REQUEST, loadUploadChainingNetworkList),
    takeLatest(UploadDataFileChainingNetworkTypes.LOAD_REQUEST, loadUploadChainingNetworkFile),
    takeLatest(UploadDataFileChainingNetworkTypes.UPLOAD_REQUEST, uploadChainingNetworkCsv),
    takeLatest(UploadDataFileChainingNetworkTypes.DELETE_REQUEST, deleteChainingNetworkCsv),

    takeLatest(UploadConectaPayFileTypes.LOAD_REQUEST, loadUploadConectaPayFile),
    takeLatest(UploadConectaPayFileTypes.UPLOAD_REQUEST, uploadConectaPayCsv),
    takeLatest(UploadConectaPayFileTypes.DELETE_REQUEST, deleteUploadConectaPayCsv),
    takeLatest(
      AutomaticCreditTypes.LOAD_PENDING_AUTOMATIC_CREDIT_REQUEST,
      loadPendingAutomaticCreditRequest,
    ),
    takeLatest(
      AutomaticCreditTypes.APPROVE_AUTOMATIC_CREDIT_REQUEST,
      approveAutomaticCreditRequest,
    ),
    takeLatest(AutomaticCreditTypes.LOAD_STATUS_REQUEST, loadStatusAutomaticCreditRequest),
    takeLatest(
      AutomaticCreditTypes.LOAD_AUTOMATIC_CREDIT_DETAIL_REQUEST,
      loadAutomaticCreditDetailRequest,
    ),
    takeLatest(AutomaticCreditTypes.AUTOMATIC_CREDIT_REPORT_REQUEST, automaticCreditReportRequest),
    takeLatest(PremiacaoTypes.LOAD_REQUEST, loadPremiacao),
    takeLatest(PremiacaoTypes.CREATE_REQUEST, createPremiacao),

    takeLatest(SkuTypes.LOAD_REQUEST, loadSku),
    takeLatest(SkuTypes.LOAD_ALL_SKU_REQUEST, loadAllSkuList),
    takeLatest(SkuTypes.CREATE_REQUEST, createSku),
    takeLatest(SkuTypes.UPDATE_REQUEST, updateSku),

    takeLatest(BenefitEditTypes.CREATE_REQUEST, createBenefit),
    takeLatest(BenefitEditTypes.LOAD_REQUEST, loadBenefit),
    takeLatest(BenefitEditTypes.LOAD_TARGETPUBLIC_REQUEST, loadBenefitTargetPublicOptions),
    takeLatest(BenefitEditTypes.APPLYFILTER_REQUEST, applyBenefitFilter),

    takeLatest(BenefitListTypes.LOAD_REQUEST, loadBenefitList),

    takeLatest(ParticipantAllowanceFileTypes.LOAD_REQUEST, loadParticipantAllowanceFile),
    takeLatest(ParticipantAllowanceFileTypes.LOAD_DETAILS_REQUEST, loadFileAllowanceDetails),
    takeLatest(ParticipantAllowanceFileTypes.UPLOAD_REQUEST, uploadParticipantAllowanceCsv),

    takeLatest(ParticipantExemptionFileTypes.LOAD_REQUEST, loadParticipantExemptionFile),
    takeLatest(ParticipantExemptionFileTypes.LOAD_DETAILS_REQUEST, loadFileExemptionDetails),
    takeLatest(ParticipantExemptionFileTypes.UPLOAD_REQUEST, uploadParticipantExemptionCsv),

    takeLatest(
      TrackingBonificationReportTypes.LOAD_PENDING_CHAINING_NET_REQUEST,
      loadTrackingBonificationReportRequest,
    ),
    takeLatest(
      TrackingBonificationReportTypes.TRACKING_BONIFICATION_REPORT_CSV_REQUEST,
      loadTrackingBonificationReportCSVRequest,
    ),
    takeLatest(PlanManagementTypes.LOAD_ALL_PLAN_REQUEST, loadAllPlanAvaliable),
    takeLatest(PlanManagementTypes.LOAD_DETAIL_FILE_REQUEST, loadFileDetails),
    takeLatest(PlanManagementTypes.LOAD_IMPORTATION_BY_LOT_REQUEST, loadImportationPlanbyLot),

    takeLatest(ReentryConfigStoreTypes.LOAD_ALL_TYPES_PROMOTIONS_REQUEST, LoadAllTypesPromotions),
    takeLatest(ReentryConfigStoreTypes.LOAD_ALL_UPLOAD_HISTORIC_REQUEST, loadAllUploadHistoric),
    takeLatest(ReentryConfigStoreTypes.LOAD_UPLOAD_DETAIL_REQUEST, loadUploadDetail),
    takeLatest(ReentryConfigStoreTypes.LOAD_PROMOTION_REGISTRED_REQUEST, LoadAllPromotionRegistred),
    takeLatest(ReentryConfigStoreTypes.LOAD_PROMOTION_BY_ID_REQUEST, LoadPromotionCampaignById),
    takeLatest(ReentryConfigStoreTypes.LOAD_CUT_OFF_DAY_REQUEST, LoadCuttOffDay),
    takeLatest(ReentryConfigStoreTypes.CREATE_FILTER_PDV_REQUEST, createFilterPDV),
    takeLatest(ReentryConfigStoreTypes.LOAD_FILTER_PDV_REQUEST, loadFilterPDV),

    takeLatest(
      ParticipantSeparateInvoiceFileTypes.LOAD_REQUEST,
      loadParticipantSeparateInvoiceFile,
    ),
    takeLatest(
      ParticipantSeparateInvoiceFileTypes.UPLOAD_REQUEST,
      uploadParticipantSeparateInvoiceCsv,
    ),

    takeLatest(ProductListTypes.LOAD_REQUEST, loadProductList),

    takeLatest(TrackImportFileTypes.LOAD_REQUEST, loadTrackImportFile),
    takeLatest(TrackImportFileTypes.LOAD_DETAILS_REQUEST, loadFileTrackImportDetails),
    takeLatest(TrackImportFileTypes.UPLOAD_REQUEST, uploadTrackImportCsv),
    takeLatest(TrackImportFileTypes.DELETE_REQUEST, deleteTrackImportCsv),
  ]);
}
