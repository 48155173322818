import { action } from 'typesafe-actions';
import { CampaignEditTypes, ICampaignEdit } from './';

export const loadCampaign = (id: number) => action(CampaignEditTypes.LOAD_REQUEST, { id });
export const loadCampaignSuccess = (data: ICampaignEdit) =>
  action(CampaignEditTypes.LOAD_SUCCESS, { data });
export const resetCampaign = () => action(CampaignEditTypes.RESET_CAMPAIGN);

export const createCampaign = data => action(CampaignEditTypes.CREATE_REQUEST, data);
export const createCampaignSuccess = data => action(CampaignEditTypes.CREATE_SUCCESS, data);
export const createCampaignFailure = () => action(CampaignEditTypes.CREATE_FAILURE);

export const applyCampaignFilter = data =>
  action(CampaignEditTypes.APPLYFILTER_REQUEST, { id: data.id, filter: data.filter });
export const applyCampaignFilterSuccess = data =>
  action(CampaignEditTypes.APPLYFILTER_SUCCESS, data);

export const loadCampaignFilters = (campaignId: number, moduleType: number) =>
  action(CampaignEditTypes.GETFILTER_REQUEST, { campaignId, moduleType });
export const loadCampaignFiltersSuccess = data => action(CampaignEditTypes.GETFILTER_SUCCESS, data);
export const loadCampaignFiltersCompleted = () => action(CampaignEditTypes.GETFILTER_COMPLETED);
export const resetCampaignFilters = () => action(CampaignEditTypes.GETFILTER_RESET);
export const setCampaignFilterCompleted = () => action(CampaignEditTypes.GETFILTER_SETCOMPLETED);

export const uploadCampaignBanner = (data: FormData, id: number) =>
  action(CampaignEditTypes.UPLOAD_BANNER_REQUEST, { data, id });
export const uploadCampaignBannerSuccess = data =>
  action(CampaignEditTypes.UPLOAD_BANNER_SUCCESS, { data });
export const uploadCampaignBannerFailure = () => action(CampaignEditTypes.UPLOAD_BANNER_FAILURE);

export const updateHasRegulation = (campaignId: number, value: boolean) =>
  action(CampaignEditTypes.UPDATE_HAS_REGULATION_REQUEST, { campaignId, value });
export const updateHasRegulationSuccess = value =>
  action(CampaignEditTypes.UPDATE_HAS_REGULATION_SUCCESS, { value });

export const updateRequiredRegulation = (campaignId: number, value: boolean) =>
  action(CampaignEditTypes.UPDATE_REQUIRED_REGULATION_REQUEST, { campaignId, value });
export const updateRequiredRegulationSuccess = value =>
  action(CampaignEditTypes.UPDATE_REQUIRED_REGULATION_SUCCESS, { value });

export const loadCampaignFilterAppliedParticipantCount = (count: number) =>
  action(CampaignEditTypes.SETFILTER_COUNT_REQUEST, { count });

export const loadTargetPublicOptions = (campaignId: number) =>
  action(CampaignEditTypes.LOAD_TARGETPUBLIC_REQUEST, { campaignId });
export const loadTargetPublicOptionsSuccess = data =>
  action(CampaignEditTypes.LOAD_TARGETPUBLIC_SUCCESS, { data });
export const loadTargetPublicOptionsFailure = () =>
  action(CampaignEditTypes.LOAD_TARGETPUBLIC_FAILURE);
