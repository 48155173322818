import axios from 'axios';
import store from 'store';
import { showToastr } from 'store/toastr/actions';
import Utils from './Utils';

const Api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  timeout: parseInt(`${process.env.REACT_APP_API_ENDPOINT_TIMEOUT}`),
  headers: {
    'Ocp-Apim-Subscription-Key': process.env.REACT_APP_API_SUBSCRIPTION,
  },
});

Api.interceptors.request.use(async config => {
  config.headers.Accept = 'application/json';
  config.headers.Authorization = `Bearer ${Utils.getToken()}`;
  return config;
});

Api.interceptors.response.use(
  async response => {
    if (response.status === 200 && response.data && response.data.message) {
      store.dispatch(
        showToastr({
          message: response.data.message,
          open: true,
          type: 'success',
        }),
      );
    }
    return response;
  },
  async error => {
    if (error.response.status === 401) {
      Utils.redirect(error);
    }
    if (error.response.status === 400) {
      if (error.response.data && !error.response.data.sucesso && error.response.data.message) {
        store.dispatch(
          showToastr({
            message: error.response.data.message,
            open: true,
            type: 'error',
          }),
        );
      }
    }
    if (error.response.status === 404) {
      if (error.response.data && !error.response.data.sucesso) {
        store.dispatch(
          showToastr({
            message: error.response.data.message || 'Ops, não foi encontrado!',
            open: true,
            type: 'error',
          }),
        );
      }
    }
  },
);

export default Api;
