import {
  Box,
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  Paper,
  Select,
  Theme,
} from '@material-ui/core';
import { MODULE_TYPE, STRUCTURE_PULICTARGET } from 'common/Constants';
import CostumizedBenefitSelectionPDV from 'components/CostumizedSelectionPDV/CostumizedBenefitSelectionPDV';
import ElegiblesBenefit from 'components/ElegiblesBenefit/ElegiblesBenefit';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from 'store';
import { IBenefitEdit, IElegibleTypeOptions } from 'store/benefit/edit';
import * as BenefitEditActions from 'store/benefit/edit/actions';
import { IMailing } from 'store/benefitMailing';
import * as BenefitMailingActions from 'store/benefitMailing/actions';
import * as RegionsActions from 'store/regions/actions';

interface StructureProps {
  benefit: IBenefitEdit;
  loadBenefitTargetPublicOptions(): void;
  elegibleTypeBlocked: boolean;
  elegibleTypeOptions: IElegibleTypeOptions[];
  isFilterCompleted: boolean;
  filterParticipantCount: number;
  benefitMailing: IMailing[];
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    spacing: {
      marginBottom: theme.spacing(2),
    },
  }),
);

const Structure: React.FC<StructureProps> = ({
  benefit,
  loadBenefitTargetPublicOptions,
  elegibleTypeOptions,
  isFilterCompleted,
  filterParticipantCount,
  benefitMailing,
}) => {
  const classes = useStyle();
  const [targetPublic, setTargetPublic] = useState(0);

  useEffect(() => {
    loadBenefitTargetPublicOptions();
  }, []);

  useEffect(() => {
    setTargetPublic(benefit.filtroPorParticipante);
  }, [benefit.filtroPorParticipante]);

  const handleTargetPublicChange = (event: React.ChangeEvent<{ value: number }>) => {
    let newValue: number = +event.target.value;
    setTargetPublic(newValue);
  };

  return (
    <Paper>
      <Box p="1.5em" className={classes.formControl}>
        <Box fontSize="h5.fontSize" className={classes.spacing}>
          Público alvo da campanha
        </Box>
        <Box width={1} className={classes.spacing}>
          <FormControl fullWidth variant="outlined">
            <Select
              disabled={benefit.filtroPorParticipante > 0}
              required
              native
              value={targetPublic}
              onChange={(e: React.ChangeEvent<{ value: any }>) => handleTargetPublicChange(e)}
              inputProps={{
                name: 'public',
                id: 'public',
              }}
            >
              <option value="">Selecione o público alvo da campanha...</option>
              {elegibleTypeOptions.map(type => (
                <option key={type.id} value={type.id}>
                  {type.nome}
                </option>
              ))}
            </Select>
          </FormControl>
        </Box>
        {targetPublic === STRUCTURE_PULICTARGET.PDV && (
          <>
            <ElegiblesBenefit
              campaignFilters={benefit.filtros}
              benefitId={benefit.id}
              publicTarget={targetPublic}
              isFilterCompleted={isFilterCompleted}
              filterParticipantCount={filterParticipantCount}
              mailing={benefitMailing}
            />
          </>
        )}
        {targetPublic === STRUCTURE_PULICTARGET.MAILING && (
          <CostumizedBenefitSelectionPDV
            benefitId={Number(benefit.id)}
            publicTarget={targetPublic}
            mailing={benefitMailing}
          />
        )}
      </Box>
    </Paper>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  benefit: state.benefit.data,
  elegibleTypeOptions: state.benefit.elegibleTypeOptions,
  loading: state.regions.loading,
  isFilterCompleted: state.campaignEdit.elegibleFilter.isCompleted,
  filterParticipantCount: state.campaignEdit.filterParticipantCount,
  benefitMailing: state.benefitMailing.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { ...RegionsActions, ...BenefitEditActions, ...BenefitMailingActions },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Structure as any);
