import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import {
  loadRealtimeTotalCancelamentosUltimosDiasSuccess,
  loadRealtimeTotalCancelamentosUltimosDiasFailure,
} from './actions';

export function* loadRealtimeTotalCancelamentosUltimosDiasRequest() {
  try {
    const { data } = yield call(API.get, 'dashboard/realtime/total/cancelamentos/dias');
    yield put(loadRealtimeTotalCancelamentosUltimosDiasSuccess(data.itens, data.atualizacao));
  } catch (error) {
    yield put(loadRealtimeTotalCancelamentosUltimosDiasFailure());
  }
}
