import { Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from 'store';
import * as GenerateBankSlipActions from 'store/GenerateBankSlip/actions';
import GenericImportTable, {
  ImportGenericTableData,
  IUploadVolume,
  IUploadVolumeDetail,
} from 'components/GenericImportTable/GenericImportTable';
import DownloadClientSingleBankSlip from 'components/DownloadClientBase/DownloadClientSingleBankSlip';

interface StructureProps {
  uploadSingleBankSlipByFile(file: FormData): void;
  loadDetailUploadBankSlip(id: number): void;
  loadAllUploadBankSlip(): void;
  uploadVolume: IUploadVolume;
  uploadVolumeDetail: IUploadVolumeDetail;
}

const Structure: React.FC<StructureProps> = ({
  loadDetailUploadBankSlip,
  loadAllUploadBankSlip,
  uploadSingleBankSlipByFile,
  uploadVolume,
  uploadVolumeDetail,
}) => {
  const [dataGenericTable, setDataGenericTable] = useState<ImportGenericTableData>({
    title: 'Histórico de Upload de Boleto Avulso',
    responseTable: [],
    responseModal: [],
    loadingModal: true,
    loadingTable: true,
    disabledHandleUpload: false,
  });

  useEffect(() => {
    // GET All Upload List
    loadAllUploadBankSlip();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Array da tabela de upload
    if (!uploadVolume.loading) {
      setDataGenericTable({
        ...dataGenericTable,
        responseTable: uploadVolume.data,
        loadingTable: uploadVolume.loading,
      });
    } else if (uploadVolume.loading) {
      setDataGenericTable({ ...dataGenericTable, loadingTable: uploadVolume.loading });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadVolume.data, uploadVolume.loading]);

  useEffect(() => {
    // Array da tabela de detalhamento do modal
    if (!uploadVolumeDetail.loading) {
      setDataGenericTable({
        ...dataGenericTable,
        responseModal: uploadVolumeDetail.data,
        loadingModal: uploadVolumeDetail.loading,
      });
    } else if (uploadVolumeDetail.loading) {
      // Troca de state loadingModal
      setDataGenericTable({ ...dataGenericTable, loadingModal: uploadVolumeDetail.loading });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadVolumeDetail.data, uploadVolumeDetail.loading]);

  const uploadByFile = file => {
    uploadSingleBankSlipByFile(file);
  };

  return (
    <Paper>
      <GenericImportTable
        props={dataGenericTable}
        exampleUploadFile={() => <DownloadClientSingleBankSlip />}
        uploadApi={uploadByFile}
        detailModalApi={loadDetailUploadBankSlip}
      />
    </Paper>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  uploadVolume: state.generateBankSlip.uploadVolume,
  uploadVolumeDetail: state.generateBankSlip.uploadVolumeDetail,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(GenerateBankSlipActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Structure as any);
