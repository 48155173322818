import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import FileUpload from 'components/FileUpload/FileUpload';
import DownloadClientBaseBeneficioCSV from 'components/DownloadClientBase/DownloadClientBaseBenefitCSV';
import * as BenefitMailingActions from 'store/benefitMailing/actions';
import * as CampaignEditActions from 'store/campaign/edit/actions';
import * as BannerEditActions from 'store/banner/edit/actions';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import { Dispatch, bindActionCreators } from 'redux';
import { IMailing } from 'store/benefitMailing';
import MailingList from 'components/MailingList/MailingList';
import { MODULE_TYPE, STRUCTURE_PULICTARGET } from 'common/Constants';
import ModalConfirmation, {
  IModalConfirmationState,
} from 'components/ModalConfirmation/ModalConfirmation';
import MailingListBenefit from 'components/MailingList/MailingListBenefit';

interface CostumizedSelectionPDVProps {
  benefitId?: number;
  bannerId?: number;
  mailing: IMailing[];
  loadBenefitMailing(benefitId: number, moduleType: MODULE_TYPE): void;
  uploadBenefitMailing(
    uploadUrl: string,
    data: FormData,
    callbackAction: any,
    id: number,
    type: number,
  ): void;
  loadCampaignFilters(benefitId: number, publicTarget: number);
  filterApplied: number;
  publicTarget?: number;
  loading: boolean;
}

const CostumizedBenefitSelectionPDV: React.FC<CostumizedSelectionPDVProps> = ({
  benefitId,
  bannerId,
  loadBenefitMailing,
  mailing,
  uploadBenefitMailing,
  loadCampaignFilters,
  filterApplied,
  publicTarget,
  loading,
}) => {
  const location = useLocation().pathname;

  const [modalConfirmation, setModalConfirmation] = useState<IModalConfirmationState>({
    open: false,
    onConfirm: null,
  });

  useEffect(() => {
    loadBenefitMailing(Number(benefitId), MODULE_TYPE.BENEFIT);
  }, []);

  const handleUpload = (file: any) => {
    const data = new FormData();
    data.append('file', file);

    const uploadUrl = `beneficio/${benefitId}/upload`;

    if (filterApplied > 0 && filterApplied !== publicTarget) {
      setModalConfirmation({
        open: true,
        onConfirm: () =>
          uploadBenefitMailing(uploadUrl, data, loadCampaignFilters, Number(benefitId), 1),
      });
      return;
    } else {
      uploadBenefitMailing(uploadUrl, data, loadCampaignFilters, Number(benefitId), 1);
    }
  };

  return (
    <>
      <Box>
        <Box fontSize="h5.fontSize" mt="1em">
          Definir seleção customizada:
        </Box>
        <Box fontSize={14} mb="1em">
          <strong>Importante!</strong> Para realizar a seleção customizada faça o upload de um
          arquivo CSV contendo a base de Código SAP elegíveis.
        </Box>
        <FileUpload onUpload={handleUpload} acceptType={['csv']} loading={loading} />
        <Box mt="1em">
          <DownloadClientBaseBeneficioCSV />
        </Box>
        <Box mt="1em">
          <MailingListBenefit
            mailings={mailing}
            bannerId={bannerId}
            benefitId={benefitId}
            loading={loading}
          />
        </Box>
      </Box>
      <ModalConfirmation
        title="Aplicar Filtro"
        content={`Filtro já aplicado para ${
          filterApplied === STRUCTURE_PULICTARGET.PDV ? 'Cliente (PDV)' : 'Força de Vendas'
        }. Deseja mesmo aplicar para ${
          filterApplied === STRUCTURE_PULICTARGET.PDV ? 'Força de Vendas' : 'Cliente (PDV)'
        }?`}
        state={modalConfirmation}
        setState={setModalConfirmation}
      />
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  loading: state.benefitMailing.loading,
  filterApplied: state.campaignEdit.filterApplied,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...BenefitMailingActions,
      ...BannerEditActions,
      ...CampaignEditActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CostumizedBenefitSelectionPDV);
