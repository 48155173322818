import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import Utils from 'common/Utils';
import { loadSuccess, loadFailure } from './actions';

export function* loadProductList() {
  try {
    const { data } = yield call(API.get, '/ean-sku/all');
    yield put(loadSuccess(data));
  } catch (error) {
    yield put(loadFailure());
    Utils.redirect(error);
  }
}
