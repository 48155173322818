import React, { useEffect, useState, useRef } from 'react';
import {
  Grid,
  createStyles,
  makeStyles,
  Theme,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from 'store';
import * as ReentryConfigActions from 'store/reentryConfigStore/actions';
import { IPromoList, TipoInclusaoParticipante } from 'store/reentryConfigStore/index';
import ReentryImportTable, { ImportGenericTableData } from './ImportTable/ReentryImportTable';
import { UploadPromoByFileRequest } from 'store/reentryConfigStore/sagas';
import DownloadClientReentryModel from 'components/DownloadClientBase/DownloadClientReentryModel';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      margin: '0 12px',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  }),
);

interface IUploadReentry {
  uploadHistoricLoading: boolean;
  uploadHistoricData: Array<any>;
  detailFileList: Array<any>;
  allPromoRegistred: Array<IPromoList>;
  uploadDetailListLoading: boolean;
  loadAllUploadHistoric: () => void;
  loadUploadDetail: (id: number) => void;
}

const UploadCampaign: React.FC<IUploadReentry> = ({
  loadAllUploadHistoric,
  loadUploadDetail,
  uploadHistoricData,
  uploadHistoricLoading,
  detailFileList,
  uploadDetailListLoading,
  allPromoRegistred,
}) => {
  const classes = useStyle();

  const [dataGenericTable, setDataGenericTable] = useState<ImportGenericTableData>({
    title: 'Histórico de Upload de Arquivos',
    responseTable: [],
    responseModal: [],
    loadingModal: true,
    loadingTable: true,
    disabledHandleUpload: false,
  });
  const [uploadData, setUploadData] = useState<any>({ idPromocaoIncentivo: null });
  const [promoUploadList, setPromoUploadList] = useState<Array<any>>([]);
  const [labelWidth, setLabelWidth] = useState(0);
  const inputLabel = useRef<HTMLLabelElement>(null);

  useEffect(() => {
    setLabelWidth(inputLabel?.current?.offsetWidth || 0);
  }, [labelWidth]);

  useEffect(() => {
    loadAllUploadHistoric();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPromoUploadList(
      allPromoRegistred.filter(
        item =>
          item.tipoInclusaoParticipante === TipoInclusaoParticipante.UPLOAD ||
          item.tipoInclusaoParticipante === 0,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Array da tabela de upload
    if (!uploadHistoricLoading) {
      setDataGenericTable({
        ...dataGenericTable,
        responseTable: uploadHistoricData.map(item => {
          return {
            ...item.importacao,
            campanhaIncentivo: item.campanhaIncentivo,
            campanhaIncentivoId: item.campanhaIncentivoId,
          };
        }),
        loadingTable: uploadHistoricLoading,
      });
    } else if (uploadHistoricLoading) {
      setDataGenericTable({ ...dataGenericTable, loadingTable: uploadHistoricLoading });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadHistoricData, uploadHistoricLoading]);

  useEffect(() => {
    // Array da tabela de detalhamento do modal
    if (!uploadDetailListLoading) {
      setDataGenericTable({
        ...dataGenericTable,
        responseModal: detailFileList,
        loadingModal: uploadDetailListLoading,
      });
    } else if (uploadDetailListLoading) {
      // Troca de state loadingModal
      setDataGenericTable({ ...dataGenericTable, loadingModal: uploadDetailListLoading });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailFileList, uploadDetailListLoading]);

  useEffect(() => {
    uploadData.idPromocaoIncentivo > 0
      ? setDataGenericTable({ ...dataGenericTable, disabledHandleUpload: false })
      : setDataGenericTable({ ...dataGenericTable, disabledHandleUpload: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadData]);

  const handleChangePromo = e => {
    setUploadData({ ...uploadData, idPromocaoIncentivo: parseInt(e.target.value) });
  };

  const uploadPromotionByFile = async file => {
    setDataGenericTable({ ...dataGenericTable, disabledHandleUpload: true });
    await UploadPromoByFileRequest(file, uploadData);
    return setDataGenericTable({ ...dataGenericTable, disabledHandleUpload: false });
  };

  return (
    <Paper elevation={2}>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <FormControl variant="outlined" className={classes.formControl} fullWidth>
            <InputLabel id="promosDisponiveis" ref={inputLabel} shrink>
              Promoções de Incentivo
            </InputLabel>
            <Select
              displayEmpty={!promoUploadList.length}
              disabled={!promoUploadList.length}
              renderValue={
                !promoUploadList.length
                  ? () => 'Não há promoções associadas a filtro PDV.'
                  : undefined
              }
              input={<OutlinedInput notched labelWidth={labelWidth} />}
              labelId="promosDisponiveis"
              id="promosDisponiveis"
              label="Promoções de Incentivo"
              onChange={handleChangePromo}
            >
              {!!promoUploadList.length &&
                promoUploadList.map(promo => {
                  return (
                    <MenuItem key={promo.id} value={promo.id}>
                      {promo.descricao}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4} />
        <Grid item className={classes.grid}>
          <Alert severity="warning">
            O conteúdo do arquivo mais recente será cumulativo em relação ao arquivo anterior. Todos
            os arquivos cadastrados permanecerão válidos e ativos.
          </Alert>
        </Grid>
        <Grid item xs={12} className={classes.grid}>
          <ReentryImportTable
            props={dataGenericTable}
            uploadApi={uploadPromotionByFile}
            detailModalApi={loadUploadDetail}
            exampleUploadFile={DownloadClientReentryModel}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  uploadHistoricData: state.reentryConfigStore.uploadHistoric.data,
  uploadHistoricLoading: state.reentryConfigStore.uploadHistoric.loading,
  detailFileList: state.reentryConfigStore.detailFileList.data,
  uploadDetailListLoading: state.reentryConfigStore.detailFileList.loading,
  allPromoRegistred: state.reentryConfigStore.allPromotionRegistred.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(ReentryConfigActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UploadCampaign);
