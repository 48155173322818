import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import Utils from 'common/Utils';
import {
  loadTypesCampaignReportSuccess,
  loadTypesCampaignReportFailure,
  generateCampaignReportSuccess,
  generateCampaignReportFailure,
} from './actions';

export function* loadTypesCampaignReportRequest() {
  try {
    const { data } = yield call(API.get, 'campanhaRelatorio/TipoCampanhaRelatorio');
    yield put(loadTypesCampaignReportSuccess(data.data));
  } catch (error) {
    yield put(loadTypesCampaignReportFailure());
  }
}
export function* generateCampaignReportRequest(action) {
  try {
    const { data } = yield call(
      API.post,
      'campanhaRelatorio/csvTipoCampanhaRelatorio',
      action.payload.data,
    );

    yield put(generateCampaignReportSuccess(data));
  } catch (error) {
    yield put(generateCampaignReportFailure());
  }
}
