import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import bannerList from './banner/list';
import bannerEdit from './banner/edit';
import popupList from './popup/list';
import popupEdit from './popup/edit';
import campaignList from './campaign/list';
import campaignEdit from './campaign/edit';
import campaignCouting from './campaign/report';

import conectaPayList from './conectaPay/list';
import conectaPayEdit from './conectaPay/edit';
import conectaPayCouting from './conectaPay/report';

import communication from './communication';
import clusters from './clusters';
import industries from './industries';
import profiles from './profiles';
import regions from './regions';
import toastr from './toastr';
import segments from './segments';
import kpi from './kpi';
import mailing from './mailing';
import regulation from './regulation';
import distribuitors from './distribuitors';

import fdvList from './fdvList';

import userList from './user/list';
import userEdit from './user/edit';
import perfil from './perfil';

import paymentMethod from './dashboard';
import payments from './payments';
import paymentReceivablesMethod from './dashboard';

import generateBankSlip from './GenerateBankSlip';

import participant from './participant';

import migrationOfPoints from './migrationOfPoints';

//DASHBOARD-REALTIME
import realtimeTotalCadastro from './realtime/totalCadastros';
import realtimeTotalAceites from './realtime/totalAceites';
import realtimeTotalUltimosCadastros from './realtime/totalUltimosCadastros';
import realtimeTotalUltimosCancelamentos from './realtime/totalUltimosCancelamentos';
import realtimeTotalMeiosPagamentos from './realtime/totalMeiosPagamentos';
import realtimeTotalPrimeirosAcessosCluster from './realtime/totalPrimeirosAcessosCluster';
import realtimeTotalErrosAccesstage from './realtime/totalErrosAccesstage';
import realtimeTotalErrosCadastros from './realtime/totalErrosCadastros';
import realtimeTotalErrosCancelamentos from './realtime/totalErrosCancelamentos';
import realtimeTotalErrosIntegracaoBoletos from './realtime/totalErrosIntegracaoBoletos';
import realtimeTotalMalhaAtivacoes from './realtime/totalMalhaAtivacoes';
import realtimeTotalMalhaAtualizacoes from './realtime/totalMalhaAtualizacoes';
import realtimeTotalMalhaAtualizacoesVolume from './realtime/totalMalhaAtualizacoesVolume';
import realtimeTotalMalhaCancelamentos from './realtime/totalMalhaCancelamentos';

//Campaign Report
import uploadCampaignReport from './uploadCampaignReport';
import campaignReport from './campaignReport';
import uploadCampaignReportList from './uploadCampaignReport/list';
import storeUploadCampaignReportFile from './uploadCampaignReport/uploadFile';

//Conecta Pay Report
import uploadConectaPayReport from './UploadConectaPayReport';
import conectaPayReport from './conectaPayReport';
import uploadConectaPayReportList from './UploadConectaPayReport/list';
import storeUploadConectaPayReportFile from './UploadConectaPayReport/uploadFile';

//ChainingNetwork
import uploadChainingNetworkList from './chainingNetwork/list';
import uploadDataFileChainingNetworkList from './chainingNetwork/uploadFile';

import automaticCredit from './counting/automaticCredit';

import premiacao from './premiacao';

import sku from './sku';
import benefit from './benefit/edit';
import benefitList from './benefit/list';
import benefitMailing from './benefitMailing';
import storeParticipantAllowanceFile from './participantAllowance/uploadFile';
import storeParticipantExemptionFile from './participantExemption/uploadFile';

import trackingBonificationReport from './trackingBonification/report';

// Plan Management
import planManagement from './planManagement';

// Reactivation & Reentry
import reentryConfigStore from './reentryConfigStore';

import storeParticipantSeparateInvoiceFile from './participantSeparateInvoice/uploadFile';

import productList from './product/list';
import productEdit from './product/edit';

import storeTrackImportFile from './trackImport';

export default (history: any) =>
  combineReducers({
    router: connectRouter(history),
    bannerList,
    bannerEdit,
    popupList,
    popupEdit,
    campaignList,
    campaignEdit,
    campaignCouting,
    conectaPayList,
    conectaPayEdit,
    conectaPayCouting,
    communication,
    clusters,
    industries,
    profiles,
    regions,
    toastr,
    segments,
    kpi,
    mailing,
    regulation,
    distribuitors,
    fdvList,
    userList,
    userEdit,
    perfil,
    paymentMethod,
    participant,
    payments,
    paymentReceivablesMethod,
    generateBankSlip,
    migrationOfPoints,
    realtimeTotalCadastro,
    realtimeTotalAceites,
    realtimeTotalUltimosCadastros,
    realtimeTotalUltimosCancelamentos,
    realtimeTotalMeiosPagamentos,
    realtimeTotalPrimeirosAcessosCluster,
    realtimeTotalErrosAccesstage,
    realtimeTotalErrosCadastros,
    realtimeTotalErrosCancelamentos,
    realtimeTotalErrosIntegracaoBoletos,
    realtimeTotalMalhaAtivacoes,
    realtimeTotalMalhaAtualizacoes,
    realtimeTotalMalhaAtualizacoesVolume,
    realtimeTotalMalhaCancelamentos,
    uploadCampaignReport,
    campaignReport,
    uploadCampaignReportList,
    storeUploadCampaignReportFile,
    uploadConectaPayReport,
    conectaPayReport,
    uploadConectaPayReportList,
    storeUploadConectaPayReportFile,
    uploadChainingNetworkList,
    uploadDataFileChainingNetworkList,
    automaticCredit,
    premiacao,
    sku,
    benefit,
    benefitList,
    benefitMailing,
    storeParticipantAllowanceFile,
    storeParticipantExemptionFile,
    trackingBonificationReport,
    planManagement,
    storeParticipantSeparateInvoiceFile,
    reentryConfigStore,
    productList,
    productEdit,
    storeTrackImportFile,
  });
