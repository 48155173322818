import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import { loadRealtimeTotalAceitesSuccess, loadRealtimeTotalAceitesFailure } from './actions';

export function* loadRealtimeTotalAceitesRequest() {
  try {
    const { data } = yield call(API.get, 'dashboard/realtime/total/aceites');
    yield put(loadRealtimeTotalAceitesSuccess(data.itens, data.atualizacao));
  } catch (error) {
    yield put(loadRealtimeTotalAceitesFailure());
  }
}
