import React, { useState, useEffect } from 'react';
import { getPowerBIMarketplaceReport } from '../../store/dashboard/sagas';
import PowerbiEmbedded from 'react-powerbi';

const Marketplace = props => {
  const [powerBI, setPowerBI] = useState({
    reportId: '',
    embedUrl: '',
    accessToken: '',
    embedTokenExpiry: '',
  });

  useEffect(() => {
    loadPowerBI();
  }, []);

  async function loadPowerBI() {
    const { data } = await getPowerBIMarketplaceReport();

    setPowerBI(data);
  }

  return (
    <>
      <h1>Dashboard de Bonificação</h1>
      {powerBI.reportId !== '' ? (
        <PowerbiEmbedded
          id={`${powerBI.reportId}`} // required
          embedUrl={`${powerBI.embedUrl}`} // required
          accessToken={`${powerBI.accessToken}`} // required
          filterPaneEnabled={false}
          navContentPaneEnabled={false}
          settings={{
            embedTokenExpiry: powerBI.embedTokenExpiry,
          }}
          width="100%"
          height="100vh"
        />
      ) : (
        'Carregando parâmetros de relatório...'
      )}
    </>
  );
};

export default Marketplace;
