import React, { useState, useEffect } from 'react';
import TabPanel from 'components/TabPanel/TabPanel';
import { Tabs, Tab as MTab } from '@material-ui/core';
import { Box, Button, makeStyles, Theme, createStyles } from '@material-ui/core';
import { useAfterChange } from 'common/CustomHooks';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& button': {},
    },
    spacingRight: {
      marginRight: theme.spacing(1),
    },
    spacing: {
      marginTop: theme.spacing(2),
    },
  }),
);

export interface ITab {
  component: React.ReactNode;
  label?: string;
  renderLabel?: (data: ITab, index: number) => string;
  disabled: boolean;
}

interface TabProps {
  tabs: ITab[];
  enableNavigationButton?: boolean;
  disableNextButton?: boolean;
  getActiveTab?: (tab: number) => void;
  initialTab?: number;
  validateBeforeChange?: (changeTab: (event: React.ChangeEvent<{}>, tab: number) => void) => void;
}

const Tab: React.FC<TabProps> = ({
  tabs,
  enableNavigationButton = false,
  disableNextButton = false,
  getActiveTab,
  initialTab = 0,
  validateBeforeChange,
}) => {
  const classes = useStyle();

  const [selectedTab, setSelectedTab] = useState(initialTab);

  useEffect(() => {
    setSelectedTab(initialTab);
  }, [initialTab]);

  useAfterChange(() => {
    if (getActiveTab) {
      getActiveTab(selectedTab);
    }
  }, [selectedTab]);

  const handleChange = (event: React.ChangeEvent<{}>, tab: number) => setSelectedTab(tab);

  const getLabel = (tab: ITab, index: number) =>
    tab.label ? tab.label : tab.renderLabel?.(tab, index);

  const handleValidateBeforeChange = (e: React.ChangeEvent<{}>, tab: number) => {
    if (validateBeforeChange) validateBeforeChange(() => handleChange(e, tab));
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={selectedTab}
        onChange={validateBeforeChange ? handleValidateBeforeChange : handleChange}
        indicatorColor="primary"
      >
        {tabs.map((tab, index) => (
          <MTab label={getLabel(tab, index)} key={index} disabled={tab.disabled} />
        ))}
      </Tabs>
      {tabs.map(({ component }, index) => (
        <TabPanel key={index} value={selectedTab} index={index}>
          {component}
        </TabPanel>
      ))}

      {enableNavigationButton && (
        <Box className={classes.spacing}>
          {selectedTab > 0 && (
            <Button
              variant="contained"
              onClick={() => setSelectedTab(selectedTab - 1)}
              disabled={selectedTab === 0}
              className={classes.spacingRight}
            >
              Voltar
            </Button>
          )}
          <Button
            variant="contained"
            onClick={() => setSelectedTab(selectedTab + 1)}
            disabled={selectedTab === tabs.length - 1 || disableNextButton}
            color="primary"
          >
            Próximo
          </Button>
        </Box>
      )}
    </div>
  );
};

export default Tab;
