import { Reducer } from 'redux';

export enum RegionsTypes {
  LOAD_REQUEST = '@regions/LOAD_REQUEST',
  LOAD_SUCCESS = '@regions/LOAD_SUCCESS',
  LOAD_FAILURE = '@regions/LOAD_FAILURE',
}

export interface IRegion {
  id: number;
  nome: string;
}

export interface IRegionsState {
  data: IRegion[];
  loading: boolean;
}

const INITIAL_STATE: IRegionsState = {
  data: [],
  loading: false,
};

const reducer: Reducer<IRegionsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RegionsTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case RegionsTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data.ufs,
      };
    case RegionsTypes.LOAD_FAILURE:
      return { ...state, loading: false, data: { ...INITIAL_STATE.data } };
    default:
      return state;
  }
};

export default reducer;
