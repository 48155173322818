import { action } from 'typesafe-actions';
import { ProductEditTypes, IProductEdit } from './';

export const loadProduct = (id: number) => action(ProductEditTypes.LOAD_REQUEST, { id });
export const loadProductSuccess = (data: IProductEdit) =>
  action(ProductEditTypes.LOAD_SUCCESS, { data });

export const createProduct = data => action(ProductEditTypes.CREATE_REQUEST, data);
export const createProductSuccess = data => action(ProductEditTypes.CREATE_SUCCESS, data);
export const createProductFailure = () => action(ProductEditTypes.CREATE_FAILURE);

export const updateProduct = data => action(ProductEditTypes.UPDATE_REQUEST, data);

export const updateProductSuccess = data => action(ProductEditTypes.UPDATE_SUCCESS, data);

export const updateProductFailure = () => action(ProductEditTypes.UPDATE_FAILURE);

export const loadProductFilterAppliedParticipantCount = (count: number) =>
  action(ProductEditTypes.SETFILTER_COUNT_REQUEST, { count });
