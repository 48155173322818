import { action } from 'typesafe-actions';
import { TotalMalhaAtualizacoesVolumeTypes, IRealtimeItem } from './';

export const loadRealtimeTotalMalhaAtualizacoesVolumeRequest = () =>
  action(TotalMalhaAtualizacoesVolumeTypes.LOAD_REQUEST);
export const loadRealtimeTotalMalhaAtualizacoesVolumeSuccess = (
  data: IRealtimeItem[],
  atualizacao: string,
) => action(TotalMalhaAtualizacoesVolumeTypes.LOAD_SUCCESS, { data, atualizacao });
export const loadRealtimeTotalMalhaAtualizacoesVolumeFailure = () =>
  action(TotalMalhaAtualizacoesVolumeTypes.LOAD_FAILURE);
