import React, { useState, useEffect } from 'react';
import { TABLE_STYLE } from 'common/Constants';
import { IconButton, Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import Utils from 'common/Utils';
import { Dispatch, bindActionCreators } from 'redux';
import * as PlanManagementActions from 'store/planManagement/actions';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import GenericTable from 'components/GenericTable/GenericTable';
import Api from '../../../../../common/Api';
export interface IUploadFileList {
  id?: number;
  dataUpload?: string;
  nomeArquivo?: string;
  descricaoStatus?: string;
}
export interface IUploadFileListProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  loadFileDetails(id: number): void;
  loadImportationPlanbyLot(): void;
  importByLotList;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      color: theme.palette.info.dark,
    },
    error: {
      color: theme.palette.error.main,
    },
    sucess: {
      color: theme.palette.success.dark,
    },
  }),
);

const PlanAlterationLotFile: React.FC<IUploadFileList & IUploadFileListProps> = props => {
  const classes = useStyle();

  useEffect(() => {
    props.loadImportationPlanbyLot();
    // eslint-disable-next-line
  }, []);

  const handleModal = (id: number) => {
    props.loadFileDetails(id);
    props.setOpen(true);
  };

  return !!props.importByLotList.length ? (
    <GenericTable
      title="Alteração de planos de participantes em lote"
      isLoading={false}
      data={props.importByLotList}
      options={{
        paging: true,
        sorting: true,
        search: true,
      }}
      columns={[
        {
          title: 'Nome do Arquivo',
          field: 'nomeArquivo',
          sorting: false,
          render: rowData => {
            return `${rowData.nomeArquivo.split('.')[0]}`;
          },
          cellStyle: { ...TABLE_STYLE },
        },
        {
          title: 'Data envio',
          field: 'dataInclusao',
          defaultSort: 'desc',
          type: 'datetime',
          customSort: (a, b) =>
            new Date(a.dataInclusao).getTime() - new Date(b.dataInclusao).getTime(),
          render: rowData => {
            return `${Utils.setDataHora(rowData.dataInclusao)}`;
          },
          cellStyle: { ...TABLE_STYLE },
        },
        {
          title: 'Status',
          field: 'descricaoStatus',
          sorting: false,
          render: rowData => {
            return `${rowData.descricaoStatus ? rowData.descricaoStatus : 'Iniciado'}`;
          },
          cellStyle: { ...TABLE_STYLE },
        },
        {
          title: 'Processando / Sucesso / Erro',
          sorting: false,
          render: rowData => {
            return (
              <>
                <span className={classes.progress}>
                  {rowData.quantidadeLinhasProcessando ? rowData.quantidadeLinhasProcessando : 0}
                </span>{' '}
                /{' '}
                <span className={classes.sucess}>
                  {rowData.quantidadeLinhasSucesso ? rowData.quantidadeLinhasSucesso : 0}
                </span>{' '}
                /{' '}
                <span className={classes.error}>
                  {rowData.quantidadeLinhasErro ? rowData.quantidadeLinhasErro : 0}
                </span>
              </>
            );
          },
          cellStyle: { ...TABLE_STYLE, textAlign: 'center' },
          headerStyle: { padding: '5px', textAlign: 'center' },
        },
        {
          title: 'Total',
          field: 'descricaoStatus',
          sorting: false,
          render: rowData => {
            return <span>{rowData.quantidadeLinhasTotal ? rowData.quantidadeLinhasTotal : 0}</span>;
          },
          cellStyle: { ...TABLE_STYLE },
        },
        {
          title: 'Descrição do Erro',
          field: 'descricaoerro',
          sorting: false,
          render: rowData => {
            return rowData.erro ? <span>{rowData.descricaoerro}</span> : 'Sem erros';
          },
          cellStyle: { ...TABLE_STYLE },
        },
        {
          title: 'Detalhamento',
          sorting: false,
          render: rowData => {
            return (
              <Button
                onClick={() => handleModal(rowData.id)}
                style={{ textDecoration: 'underline' }}
                size={'small'}
              >
                Ver Detalhes
              </Button>
            );
          },
          cellStyle: { ...TABLE_STYLE },
        },
        {
          title: 'Ações',
          searchable: false,
          sorting: false,
          cellStyle: {
            width: '15%',
            ...TABLE_STYLE,
          },
          render: ({ id, urlArquivo }) => (
            <>
              <IconButton onClick={() => window.open(urlArquivo)}>
                <CloudDownload />
              </IconButton>
            </>
          ),
        },
      ]}
    />
  ) : null;
};

const mapStateToProps = (state: ApplicationState) => ({
  importByLotList: state.planManagement.importationByLotList.data,
  loading: state.planManagement.importationByLotList.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(PlanManagementActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PlanAlterationLotFile);
