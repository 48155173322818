import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import imageLogo from '../../assets/images/bg-login.png';
import { getAdminToken } from '../../common/Auth';
import { ILoginRequest } from '../../store/login/index';
import ModalResetPassword, { IModalResetPasswordState } from '../Login/Reset/ModalResetPassword';
import * as Yup from 'yup';
import { FormHelperText } from '@material-ui/core';
import store from 'store';
import { showToastr } from 'store/toastr/actions';

const useStyles = makeStyles(theme => ({
  root: {
    height: '80vh',
  },
  image: {
    backgroundImage: 'url(' + imageLogo + ')',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'contain',
    backgroundPosition: 'left',
    Heigth: '498px',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
const SignInSide: React.FC<any & RouteComponentProps<any>> = ({ history }) => {
  const classes = useStyles();

  const [loginData, setLoginData] = useState<ILoginRequest>({
    email: '',
    senha: '',
  });

  const schema = Yup.object().shape({
    email: Yup.string()
      .email('Insira um email válido!')
      .required('O email é obrigatório!'),
    senha: Yup.string().required('A senha é obrigatório!'),
  });

  const [modalConfirmation, setModalConfirmation] = useState<IModalResetPasswordState>({
    open: false,
    onConfirm: null,
  });

  const openModal = () => {
    setModalConfirmation({
      open: true,
      onConfirm: null,
    });
  };

  const [isButtonLoginDisabled, setIsButtonLoginDisabled] = useState(true);
  const [error, setError] = React.useState(false);
  const [helperText, setHelperText] = React.useState('');

  useEffect(() => {
    localStorage.clear();
    if (loginData.email.trim() && loginData.senha.trim()) {
      setIsButtonLoginDisabled(false);
    } else {
      setIsButtonLoginDisabled(true);
    }
  }, [loginData]);

  const handleLoginChange = (prop: keyof ILoginRequest) => async (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    setLoginData({ ...loginData, [prop]: event.target.value });
    let data = loginData;
    data[prop as string] = event.target.value;
    await schema
      .validate(data, {
        abortEarly: false,
      })
      .then(function(value) {
        setError(false);
        setIsButtonLoginDisabled(false);
        setHelperText('');
      })
      .catch(function(err) {
        if (err.errors && err.errors.length > 0) {
          setHelperText(err.errors[0]);
          setError(true);
          setIsButtonLoginDisabled(true);
        }
      });
  };

  async function loginSubmit() {
    try {
      await schema.validate(loginData, {
        abortEarly: false,
      });
      const send = await getAdminToken(loginData);
      if (send && send.accessToken) window.location.href = '/home';
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        let errors = '';
        err.errors.forEach((er, index) => {
          errors += er;
          if (err.errors.length > 1 && err.errors.length < index) {
            errors += ', ';
          }
        });
        store.dispatch(
          showToastr({
            message: errors,
            open: true,
            type: 'error',
          }),
        );
      }
    }
  }

  return (
    // <Box m={5} p={5}>

    <Grid container component="main" className={classes.root}>
      <Grid item xs={12} />
      <Grid item xs={12} />
      <Grid item xs={12} />
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Entre com sua conta existente
          </Typography>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            // value={loginData.Username}
            label="E-mail"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={handleLoginChange('email')}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="senha"
            label="Senha"
            type="password"
            id="senha"
            // value={loginData.PassWord}
            autoComplete="current-password"
            onChange={handleLoginChange('senha')}
          />
          <FormHelperText error={error}>{helperText}</FormHelperText>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            className={classes.submit}
            disabled={isButtonLoginDisabled}
            onClick={() => loginSubmit()}
          >
            Entrar
          </Button>
          <Button color="secondary" onClick={openModal}>
            Esqueceu a senha?
          </Button>
          <ModalResetPassword
            title="Resetar Senha"
            state={modalConfirmation}
            setState={setModalConfirmation}
            size="sm"
          />
          <Box mt={5}></Box>
        </div>
      </Grid>
    </Grid>
    // </Box>
  );
};

export default SignInSide;
