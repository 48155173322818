import React, { useState, useEffect } from 'react';
import {
  FormControl,
  TextField,
  Box,
  Paper,
  createStyles,
  makeStyles,
  Theme,
  Grid,
  Button,
  CircularProgress,
} from '@material-ui/core';
import cn from 'classnames';
import Typography from '@material-ui/core/Typography';
import Api from '../../../common/Api';
import * as Yup from 'yup';
import store from 'store';
import { showToastr } from 'store/toastr/actions';
import { ApplicationState } from 'store';
import FileUpload from 'components/FileUpload/FileUpload';
import { Dispatch, bindActionCreators } from 'redux';
import * as ProductsEditActions from 'store/product/edit/actions';
import { connect } from 'react-redux';
import Alert from '@material-ui/lab/Alert';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    textCenter: {
      textAlign: 'center',
      display: 'block',
      width: '91%',
    },
    thumbs: {
      maxWidth: '100%',
      maxHeight: '200px',
      borderRadius: '6px',
      marginBottom: '12px',
    },
    buttonActions: {
      padding: theme.spacing(1),
      minWidth: 120,
      textAlign: 'right',
      '& > button': {
        marginRight: 0,
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }),
);

const ProductRegister: React.FC<any> = ({ match, history, showToastr }) => {
  const classes = useStyle();

  const [productId] = useState(match.params.id);
  const [state, setState] = useState({
    id: 0,
    nome: '',
    codigo: '',
    imagemUrl: '',
    ativo: true,
    nomeCis: '',
  });
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const schema = Yup.object().shape({
    nome: Yup.string('Insira um nome válido!').required('O Nome é obrigatório!'),
    codigo: Yup.string('Insira um código EAN/SKU válido!').required(
      'O código EAN/SKU é obrigatório!',
    ),
    imagemUrl: Yup.string('Insira uma imagem para o EAN/SKU!').required(
      'A imagem do EAN/SKU é obrigatória!',
    ),
  });

  async function loadProduct() {
    if (match.params.id != undefined) {
      const result = await Api.get('/ean-sku/' + match.params.id);

      setState(result.data.data);
    }
  }

  const onUploadImage = async (file: any) => {
    const formData = new FormData();
    formData.append('file', file);
    setIsLoadingImage(true);

    try {
      const { data } = await Api.post(`ean-sku/upload`, formData);

      setState({ ...state, imagemUrl: data.url });
      setIsLoadingImage(false);
      showToastr({ message: 'Imagem enviada com sucesso.', type: 'success', open: true });
    } catch (error) {}
  };

  useEffect(() => {
    loadProduct();
  }, []);

  async function validateForm() {
    try {
      await schema.validate(state, {
        abortEarly: false,
      });
      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        let errors = '';
        err.errors.forEach((er, index) => {
          errors += er;
          if (err.errors.length > 1 && err.errors.length < index) {
            errors += ', ';
          }
        });
        store.dispatch(
          showToastr({
            message: errors,
            open: true,
            type: 'error',
          }),
        );
      }
    }
  }

  const handleUpdate = async () => {
    let validate = await validateForm();
    if (validate) {
      setLoading(true);
      await Api.patch(`/ean-sku/${state.id}`, state);
      setButtonDisabled(true);
      setLoading(false);
      setTimeout(function() {
        history.push('/produtos/gestaodeprodutos');
      }, 2000);
    }
  };

  const handleSave = async () => {
    let validate = await validateForm();
    if (validate) {
      setLoading(true);
      await Api.post('/ean-sku/create', state);

      setButtonDisabled(true);
      setLoading(false);
      setTimeout(function() {
        history.push('/produtos/gestaodeprodutos');
      }, 2000);
    }
  };

  const handleChange = (prop: keyof any) => async (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    setState({ ...state, [prop]: event.target.value });

    let data = state;

    data[prop as string] = event.target.value;
    await schema
      .validate(data, {
        abortEarly: false,
      })
      .then(function(value) {
        setError(false);
        setHelperText('');
      })
      .catch(function(err) {
        if (err.errors && err.errors.length > 0) {
          setHelperText(err.errors[0]);
          setError(true);
        }
      });
  };

  return loading ? (
    <CircularProgress />
  ) : (
    <Paper style={{ padding: 16 }}>
      <Typography component="h1" variant="h6">
        INFORMAÇÕES DO PRODUTO:
      </Typography>
      <Grid container alignItems="flex-start" spacing={2}>
        <Grid item xs={12}></Grid>

        <Grid item xs={6}>
          <FormControl fullWidth={true} variant="outlined" className={classes.formControl}>
            <TextField
              id="ean_sku"
              required={true}
              value={state.codigo}
              onChange={handleChange('codigo')}
              label="EAN/SKU"
              variant="outlined"
              disabled={productId > 0}
              fullWidth
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth={true} variant="outlined" className={classes.formControl}>
            <TextField
              id="nome"
              required={true}
              value={state.nome}
              onChange={handleChange('nome')}
              label="Descrição do Produto"
              variant="outlined"
              fullWidth
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth={true} variant="outlined" className={classes.formControl}>
            <TextField
              id="nomeCIS"
              required={true}
              value={state.nomeCis}
              onChange={handleChange('nomeCis')}
              label="Nome CIS"
              variant="outlined"
              fullWidth
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}></Grid>

        <Grid item={true} xs={6}>
          <Box className={classes.formControl}>
            {
              <>
                {isLoadingImage ? (
                  <CircularProgress />
                ) : (
                  state.imagemUrl && (
                    <img src={state.imagemUrl} alt={state.imagemUrl} className={classes.thumbs} />
                  )
                )}
                <Box fontSize="h5.fontSize" mt="1em">
                  <Alert severity="warning">
                    <strong>Instruções para upload de imagem do produto</strong>
                    <ul>
                      <li>
                        1. <strong>IMPORTANTE:</strong> A imagem <strong>NÃO</strong> deve possuir
                        fundo transparente, inclua de modo que possua SOMENTE a imagem do produto
                        sem planos de fundos transparentes ou coloridos.
                      </li>
                      <li>2. Tamanho ideal: 420(largura) x 600(altura) px.</li>
                      <li>
                        3. As imagens devem possuir um aspect ratio 0,7 (420 / 600 = 0,7). Qualquer
                        variação desta proporção de dimensão pode gerar distorções na apresentação
                        da imagem no portal.
                      </li>
                    </ul>
                  </Alert>
                </Box>
                <FileUpload
                  fullWidth={true}
                  onUpload={(file: any) => onUploadImage(file)}
                  acceptType={['jpeg', 'jpg', 'png']}
                  showThumbnail={false}
                  loading={isLoadingImage}
                  text={'Selecione a imagem desktop:'}
                />
              </>
            }
          </Box>
        </Grid>

        <Grid container={true}>
          <Grid item={true} xs={3} className={classes.formControl}>
            <Box fontSize={14} color="red"></Box>
          </Grid>
          <Grid item={true} xs={12} className={cn([classes.buttonActions])}>
            <Button
              onClick={!state.id ? handleSave : handleUpdate}
              variant="contained"
              color="secondary"
              disabled={buttonDisabled}
            >
              {!state.id ? 'Cadastrar' : 'Atualizar'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

const mapStateToProps = (state: ApplicationState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...ProductsEditActions,
      showToastr,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProductRegister);
