import React, { useMemo } from 'react';
import {
  TimelineItem,
  TimelineSeparator,
  TimelineContent,
  TimelineDot,
  TimelineConnector,
} from '@material-ui/lab';
import { Typography, Paper, createStyles, makeStyles, Theme } from '@material-ui/core';
import Utils from 'common/Utils';
import {
  NewReleases,
  FiberNewOutlined,
  Block,
  Beenhere,
  GavelSharp,
  DoneAll,
  MoneyOff,
  Replay,
  Power,
} from '@material-ui/icons';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    dateTag: {
      textAlign: 'end',
      paddingTop: '10px',
    },
  }),
);

const timelineItemHelper = [
  {
    code: 'ATIVACAO',
    name: 'Ativação',
    icon: (
      <TimelineDot color="primary">
        <FiberNewOutlined />
      </TimelineDot>
    ),
  },
  {
    code: 'PRIMEIRO_ACESSO',
    name: 'Primeiro acesso',
    icon: (
      <TimelineDot color="primary">
        <DoneAll />
      </TimelineDot>
    ),
  },
  {
    code: 'ACEITE_TERMOS',
    name: 'Aceite de termos',
    icon: (
      <TimelineDot color="secondary">
        <GavelSharp />
      </TimelineDot>
    ),
  },
  {
    code: 'ULTIMO_ACESSO',
    name: 'Último acesso',
    icon: (
      <TimelineDot color="primary">
        <Power />
      </TimelineDot>
    ),
  },
  {
    code: 'CANCELAMENTO_DESATIVACAO',
    name: 'Cancelamento/Desativação',
    icon: (
      <TimelineDot>
        <Block color="error" />
      </TimelineDot>
    ),
  },
  {
    code: 'REATIVACAO',
    name: 'Reativação',
    icon: (
      <TimelineDot color="primary">
        <Replay />
      </TimelineDot>
    ),
  },
  {
    code: 'PLANO_MENSALIDADE_SELECAO',
    name: 'Seleção da mensalidade do plano',
    icon: (
      <TimelineDot>
        <NewReleases color="primary" />
      </TimelineDot>
    ),
  },
  {
    code: 'INADIMPLENCIA_90',
    name: 'Inadimplência 90+ dias',
    icon: (
      <TimelineDot color="secondary">
        <MoneyOff />
      </TimelineDot>
    ),
  },
  {
    code: 'CODIGO_SAP_SELECAO',
    name: 'Código SAP gerado',
    icon: (
      <TimelineDot color="primary">
        <Beenhere />
      </TimelineDot>
    ),
  },
];

interface IPlanProps {
  dataHistorico: string;
  descricao: string;
  tipoHistorico: string;
}

const TimelineItemComponent: React.FC<IPlanProps> = ({
  dataHistorico,
  descricao,
  tipoHistorico,
}) => {
  const classes = useStyle();
  const target = useMemo(() => timelineItemHelper.find(x => x.code === tipoHistorico), [
    tipoHistorico,
  ]);

  return (
    <>
      {!!target && (
        <TimelineItem>
          <TimelineSeparator>
            {React.createElement(() => target.icon)}
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} className={classes.paper}>
              <Typography variant="h6" component="h1">
                {target.name}
              </Typography>
              <Typography>{descricao}</Typography>
              <Typography variant="body2" color="textSecondary" className={classes.dateTag}>
                {Utils.setDataHora(dataHistorico)}
              </Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
      )}
    </>
  );
};

export default TimelineItemComponent;
