import React, { useEffect, useState } from 'react';
import Wrap from 'components/Wrap/Wrap';
import {
  Breadcrumbs,
  Typography,
  Link,
  makeStyles,
  Theme,
  createStyles,
  Box,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import Accordion from 'components/Accordion/Accordion';
import BannerAccordionTitle from 'components/BannerAccordionTitle/BannerAccordionTitle';
import BannerOrderingCard from 'components/BannerOrderingCard/BannerOrderingCard';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import { Dispatch, bindActionCreators } from 'redux';
import * as BannersListActions from 'store/banner/list/actions';
import { IBannerListTypes, IBannersDataList, IBannerDataItem } from 'store/banner/list';
import { Add } from '@material-ui/icons';
import Api from 'common/Api';
import { SortableElement, SortableContainer } from 'react-sortable-hoc';
import arrayMove from 'array-move';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    wrap: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
    },
  }),
);

interface BannerListProps extends RouteComponentProps {
  loadBannerTypes(): void;
  loadBannersByTypes(typesId: number[]): void;
  types: IBannerListTypes[];
  data: IBannersDataList;
  loading: boolean;
}

const BannerList: React.FC<BannerListProps> = ({
  history,
  loadBannerTypes,
  loadBannersByTypes,
  types,
  data,
  loading,
}) => {
  const classes = useStyle();
  const [banners, setBanners] = useState<any>([]);

  useEffect(() => {
    loadBannerTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (types.length > 0) {
      loadBannersByTypes(types.map(e => e.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [types]);

  const SortableItem = SortableElement(({ tipoId, id, imagemUri, ordem, sortIndex }) => (
    <BannerOrderingCard
      typeId={tipoId}
      key={id}
      index={id}
      id={id}
      imgUrl={imagemUri}
      order={sortIndex + 1}
    />
  ));

  const SortableList = SortableContainer(({ items }) => {
    return (
      <Box className={classes.wrap}>
        {items &&
          items.map((value: IBannerDataItem, index) => (
            <SortableItem key={`item-${value.id}`} index={index} {...value} sortIndex={index} />
          ))}
      </Box>
    );
  });

  const onSortEnd = async ({ oldIndex, newIndex }, typeId, e) => {
    const industriaCnpj = banners[typeId][oldIndex].industriaCnpj;
    const items = arrayMove(banners[typeId], oldIndex, newIndex) as IBannerDataItem[];
    const newOrder = items.map((banner: IBannerDataItem, index) => {
      return {
        ...banner,
        ordem: index,
      };
    });

    const objRequest = newOrder.map((e: IBannerDataItem) => {
      return { id: e.id, order: e.ordem };
    });

    try {
      await Api.post(`/banner/${industriaCnpj}/reorder`, objRequest);

      setBanners({
        ...banners,
        [typeId]: arrayMove(banners[typeId], oldIndex, newIndex),
      });

      loadBannersByTypes(types.map(e => e.id));
    } catch (error) {}
  };

  useEffect(() => {
    setBanners(data);
  }, [data]);

  const getActionButton = (type: IBannerListTypes) => (
    <IconButton color="primary" onClick={() => history.push(`/banners/cadastrar/${type.id}`)}>
      <Add />
    </IconButton>
  );

  return (
    <>
      <Wrap>
        <Breadcrumbs>
          <Link color="inherit" onClick={() => history.push('/')}>
            Home
          </Link>
          <Typography color="textPrimary">Gestão de Banners</Typography>
        </Breadcrumbs>
      </Wrap>

      {loading ? (
        <CircularProgress />
      ) : (
        types.map((type: IBannerListTypes, index: number) => {
          return (
            <Accordion
              key={index}
              customAction={getActionButton(type)}
              title={<BannerAccordionTitle type={type.nome} />}
            >
              <Box className={classes.wrap}>
                <SortableList
                  axis={'xy'}
                  lockToContainerEdges={true}
                  pressDelay={200}
                  items={banners[type.id]?.sort((a, b) => a.ordem - b.ordem)}
                  onSortEnd={(value, e) => onSortEnd(value, type.id, e)}
                />
              </Box>
            </Accordion>
          );
        })
      )}
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  types: state.bannerList.types,
  loading: state.bannerList.loading,
  data: state.bannerList.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...BannersListActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BannerList);
