import { action } from 'typesafe-actions';
import { ParticipantSeparateInvoiceFileTypes } from '.';

export const loadParticipantSeparateInvoiceFile = (id: number) =>
  action(ParticipantSeparateInvoiceFileTypes.LOAD_REQUEST, { id });
export const loadUploadFileSuccess = data =>
  action(ParticipantSeparateInvoiceFileTypes.LOAD_SUCCESS, { data });
export const loadUploadFileFailure = () => action(ParticipantSeparateInvoiceFileTypes.LOAD_FAILURE);

export const uploadParticipantSeparateInvoiceCsv = (file: FormData) =>
  action(ParticipantSeparateInvoiceFileTypes.UPLOAD_REQUEST, { file });
export const participantSeparateInvoiceCsvSuccess = data =>
  action(ParticipantSeparateInvoiceFileTypes.UPLOAD_SUCCESS, { data });
export const participantSeparateInvoiceCsvFailure = () =>
  action(ParticipantSeparateInvoiceFileTypes.UPLOAD_FAILURE);

export const deleteParticipantSeparateInvoiceCsv = (deleteUrl: string) =>
  action(ParticipantSeparateInvoiceFileTypes.DELETE_REQUEST, { deleteUrl });
export const deleteParticipantSeparateInvoiceCsvSuccess = data =>
  action(ParticipantSeparateInvoiceFileTypes.DELETE_SUCCESS, { data });
export const deleteParticipantSeparateInvoiceCsvFailure = () =>
  action(ParticipantSeparateInvoiceFileTypes.DELETE_FAILURE);
