import { action } from 'typesafe-actions';
import { PopupsEditTypes, IPopupEdit } from './';

export const loadPopup = (id: number) => action(PopupsEditTypes.LOAD_REQUEST, { id });
export const loadPopupSuccess = data => action(PopupsEditTypes.LOAD_SUCCESS, { data });
export const loadPopupFailure = () => action(PopupsEditTypes.LOAD_FAILURE);

export const createPopup = (data: IPopupEdit) => action(PopupsEditTypes.CREATE_REQUEST, { data });
export const createPopupSuccess = data => action(PopupsEditTypes.CREATE_SUCCESS, { data });

export const updatePopup = (data: IPopupEdit) => action(PopupsEditTypes.UPDATE_REQUEST, { data });
export const updatePopupSuccess = data => action(PopupsEditTypes.UPDATE_SUCCESS, { data });

export const applyPopupFilter = data =>
  action(PopupsEditTypes.APPLYFILTER_REQUEST, { id: data.id, filter: data.filter });
export const applyPopupFilterSuccess = data => action(PopupsEditTypes.APPLYFILTER_SUCCESS, data);

export const loadPopupFilters = (popupId: number, moduleType: number) =>
  action(PopupsEditTypes.GETFILTER_REQUEST, { popupId, moduleType });
export const loadPopupFiltersCompleted = () => action(PopupsEditTypes.GETFILTER_COMPLETED);

export const loadPopupFiltersPDVSuccess = data =>
  action(PopupsEditTypes.GETFILTER_PDV_SUCCESS, data);
export const loadPopupFiltersFDVSuccess = data =>
  action(PopupsEditTypes.GETFILTER_FDV_SUCCESS, data);

export const resetPopupEdit = () => action(PopupsEditTypes.RESET_POPUP);
export const resetPopupFilters = () => action(PopupsEditTypes.GETFILTER_RESET);
export const setPopupFilterCompleted = () => action(PopupsEditTypes.GETFILTER_SETCOMPLETED);

export const loadPopupFilterAppliedParticipantCountPDV = (count: number) =>
  action(PopupsEditTypes.SETFILTER_COUNT_PDV_REQUEST, { count });
export const loadPopupFilterAppliedParticipantCountFDV = (count: number) =>
  action(PopupsEditTypes.SETFILTER_COUNT_FDV_REQUEST, { count });

export const uploadPopupParticipant = (
  uploadUrl: string,
  data: FormData,
  id: number,
  type: number,
) =>
  action(PopupsEditTypes.UPLOAD_PARTICIPANT_REQUEST, {
    uploadUrl,
    data,
    id,
    type,
  });
export const uploadPopupParticipantSuccess = data =>
  action(PopupsEditTypes.UPLOAD_PARTICIPANT_SUCCESS, { data });
export const uploadPopupParticipantFailure = () =>
  action(PopupsEditTypes.UPLOAD_PARTICIPANT_FAILURE);

export const loadParticipantsFileList = (popupId: number) =>
  action(PopupsEditTypes.LOAD_PARTICIPANT_REQUEST, { popupId });
export const loadParticipantsFileListSuccess = data =>
  action(PopupsEditTypes.LOAD_PARTICIPANT_SUCCESS, { data });
export const loadParticipantsFileListFailure = () =>
  action(PopupsEditTypes.LOAD_PARTICIPANT_FAILURE);

export const deleteParticipantFile = (popupParticipantFileId: number) =>
  action(PopupsEditTypes.DELETE_PARTICIPANT_REQUEST, { popupParticipantFileId });
export const deleteParticipantFileSuccess = data =>
  action(PopupsEditTypes.DELETE_PARTICIPANT_SUCCESS, { data });
export const deleteParticipantFileFailure = () =>
  action(PopupsEditTypes.DELETE_PARTICIPANT_FAILURE);

export const loadFilterClusters = () => action(PopupsEditTypes.LOAD_FILTER_CLUSTERS_REQUEST);
export const loadFilterClustersSuccess = data =>
  action(PopupsEditTypes.LOAD_FILTER_CLUSTERS_SUCCESS, { data });
export const loadFilterClustersFailure = () => action(PopupsEditTypes.LOAD_FILTER_CLUSTERS_FAILURE);

export const loadFilterMethodsPayments = () =>
  action(PopupsEditTypes.LOAD_FILTER_METHODS_PAYMENTS_REQUEST);
export const loadFilterMethodsPaymentsSuccess = data =>
  action(PopupsEditTypes.LOAD_FILTER_METHODS_PAYMENTS_SUCCESS, { data });
export const loadFilterMethodsPaymentsFailure = () =>
  action(PopupsEditTypes.LOAD_FILTER_METHODS_PAYMENTS_FAILURE);

export const loadFilterRegions = () => action(PopupsEditTypes.LOAD_FILTER_REGIONS_REQUEST);
export const loadFilterRegionsSuccess = data =>
  action(PopupsEditTypes.LOAD_FILTER_REGIONS_SUCCESS, { data });
export const loadFilterRegionsFailure = () => action(PopupsEditTypes.LOAD_FILTER_REGIONS_FAILURE);

export const loadFilterTerritory = () => action(PopupsEditTypes.LOAD_FILTER_TERRITORY_REQUEST);
export const loadFilterTerritorySuccess = data =>
  action(PopupsEditTypes.LOAD_FILTER_TERRITORY_SUCCESS, { data });
export const loadFilterTerritoryFailure = () =>
  action(PopupsEditTypes.LOAD_FILTER_TERRITORY_FAILURE);

export const loadFilterZone = () => action(PopupsEditTypes.LOAD_FILTER_ZONE_REQUEST);
export const loadFilterZoneSuccess = data =>
  action(PopupsEditTypes.LOAD_FILTER_ZONE_SUCCESS, { data });
export const loadFilterZoneFailure = () => action(PopupsEditTypes.LOAD_FILTER_ZONE_FAILURE);
