import {
  Box,
  Button,
  createStyles,
  FormControl,
  Grid,
  Select,
  InputLabel,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import cn from 'classnames';
import { AppMessages } from 'common/AppMessages.enum';
import Utils from 'common/Utils';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from 'store';
import * as PremiacaoActions from 'store/premiacao/actions';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { ICampaignEdit } from 'store/campaign/edit';
import { IPremiacao } from 'store/premiacao';
import Api from 'common/Api';
import fi from 'date-fns/esm/locale/fi/index.js';
import * as CampaignActions from 'store/campaign/edit/actions';

interface PremiacaoProps {
  campaign: ICampaignEdit;
  loading: boolean;
  loadPremiacao(id: number): void;
  loadCampaign(id: number): void;
  createPremiacao: (data: IPremiacao) => void;
  premiacao: IPremiacao;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    textCenter: {
      textAlign: 'center',
      display: 'block',
      width: '91%',
    },
    buttonActions: {
      padding: theme.spacing(1),
      minWidth: 120,
      textAlign: 'right',
      '& > button': {
        marginRight: 0,
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    spacing: {
      marginBottom: theme.spacing(2),
    },
  }),
);

const Premiacao: React.FC<PremiacaoProps> = ({
  campaign,
  loading,
  createPremiacao,
  premiacao,
  loadPremiacao,
  loadCampaign,
}) => {
  const pediodDayDisableValue = 1;
  const classes = useStyle();
  const [stateCampaign, setStateCampaign] = useState(campaign);
  const [statePremiacao, setStatePremiacao] = useState(premiacao);
  const [errorMessage, setErrorMessage] = useState('');

  const [labelWidth, setLabelWidth] = React.useState(0);

  const [typePeriodCreditValue, setTypesPeriodCreditValue] = React.useState(0);
  const [typesPeriodCredit, setTypesPeriodCredit] = useState([] as any);
  const [typesPeriodCreditOriginal, setTypesPeriodCreditOriginal] = useState([] as any);

  useEffect(() => {
    getAllTypePeriodCredit();
    loadPremiacao(Number(campaign.id));
  }, []);

  useEffect(() => {
    setStatePremiacao({ ...premiacao });
  }, [premiacao]);

  useEffect(() => {
    if (campaign.tipoPeriodoCreditoId) {
      setTypesPeriodCreditValue(campaign.tipoPeriodoCreditoId);
    }
  }, [campaign.tipoPeriodoCreditoId]);

  const handleOnSave = async () => {
    const hasErrors = getErrorMessages();

    if (!hasErrors) {
      createPremiacao({
        ...statePremiacao,
        id: premiacao.id,
        campanhaId: Number(campaign.id),
        tipoPeriodoCreditoId: typePeriodCreditValue,
        creditoAutomatico: stateCampaign.creditoAutomatico,
      });

      setTimeout(function() {
        if (!campaign.campanhaRegular) window.location.href = '/campanhas/gestaodecampanhas';
      }, 3000);
    }
  };

  const handleCampaignChange = (prop: keyof any) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.type === 'checkbox' && event.target.checked) {
      setStateCampaign({ ...stateCampaign, [prop]: event.target.checked });
      setTypesPeriodCredit(typesPeriodCreditOriginal);
    } else {
      setStateCampaign({ ...stateCampaign, [prop]: event.target.checked });
      let dataPeriod = typesPeriodCredit.filter(f => {
        return f.id !== pediodDayDisableValue;
      });
      setTypesPeriodCredit(dataPeriod);
    }
  };

  const handlePremiacaoChange = (prop: keyof any) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setStatePremiacao({ ...statePremiacao, [prop]: Number(event.target.value) });
  };

  const handlePeriodCreditChange = (event: React.ChangeEvent<{ value: any }>) => {
    setTypesPeriodCreditValue(+event.target.value);
  };

  const getAllTypePeriodCredit = async () => {
    const { data } = await Api.get(`/Shared/TipoPeriodoCredito`);
    setTypesPeriodCreditOriginal(data);
    filterPeriodCreditDay(data);
  };

  const filterPeriodCreditDay = data => {
    if (!campaign.creditoAutomatico) {
      let dataPeriod = data.filter(f => {
        return f.id !== pediodDayDisableValue;
      });
      setTypesPeriodCredit(dataPeriod);
    } else {
      setTypesPeriodCredit(data);
    }
  };

  const getErrorMessages = (): boolean => {
    const isValorPremiacaoValid = statePremiacao.valorPremiacao === 0;
    const isPeriodoCreditoValid = typePeriodCreditValue === 0;
    setErrorMessage(
      isValorPremiacaoValid
        ? 'O campo Valor Premiação é obrigatorio'
        : isPeriodoCreditoValid
        ? 'O campo Periodicidade é obrigatorio'
        : '',
    );

    return [!isValorPremiacaoValid, !isPeriodoCreditoValid].some(e => !e);
  };

  return (
    <Paper>
      <Box p="1.5em">
        <form>
          <Box width={6 / 12}>
            <FormControl fullWidth={true} className={classes.formControl}>
              <TextField
                id="valorPremicao"
                required={true}
                value={statePremiacao.valorPremiacao || ''}
                onChange={handlePremiacaoChange('valorPremiacao')}
                label="Valor da Premiação"
                variant="outlined"
              />
            </FormControl>
          </Box>

          <Box width={6 / 12}>
            <FormControl fullWidth={true} className={classes.formControl}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={stateCampaign.creditoAutomatico}
                    onChange={handleCampaignChange('creditoAutomatico')}
                    name="checkedCreditoAutomaticoCampaign"
                    color="primary"
                  />
                }
                label="Crédito Automático"
              />
            </FormControl>
          </Box>

          <Box width={1} className={classes.spacing}>
            <Box className={classes.spacing}>
              Ao selecionar Crédito Automático, os pontos serão creditados automaticamente de acordo
              com a periodicidade selecionada, sem nenhum tipo de moderação BAT.
            </Box>
          </Box>

          <Grid container={true}>
            <Grid item={true} xs={2}>
              <FormControl fullWidth={true} variant="outlined" className={classes.formControl}>
                <Box className={classes.spacing}> Periodicidade</Box>
                <Select
                  required
                  native
                  value={typePeriodCreditValue}
                  labelWidth={labelWidth}
                  onChange={(e: React.ChangeEvent<{ value: any }>) => handlePeriodCreditChange(e)}
                  inputProps={{
                    name: 'typePeriodCreditValue',
                    id: 'typePeriodCreditValue',
                  }}
                >
                  <option value="0">Selecione...</option>

                  {typesPeriodCredit.map(method => (
                    <option key={method.id} value={method.id}>
                      {method.descricao}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container={true}>
            <Grid item={true} xs={3} className={classes.formControl}>
              <Box fontSize={14} color="red">
                {errorMessage}
              </Box>
            </Grid>
            <Grid item={true} xs={3} className={cn([classes.buttonActions])}>
              {campaign.campanhaRegular && (
                <Button
                  onClick={handleOnSave}
                  disabled={loading}
                  variant="contained"
                  color="secondary"
                >
                  {loading ? 'Aguarde...' : !premiacao.id ? 'Cadastrar' : 'Atualizar'}
                </Button>
              )}

              {!campaign.campanhaRegular && (
                <Button
                  onClick={handleOnSave}
                  disabled={loading}
                  variant="contained"
                  color="secondary"
                >
                  {loading
                    ? 'Aguarde...'
                    : !premiacao.id
                    ? 'Cadastrar & Completar'
                    : 'Atualizar & Completar'}
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </Box>
    </Paper>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  premiacao: state.premiacao.data,
  campaign: state.campaignEdit.data,
  loading: state.campaignEdit.loading,
  error: state.campaignEdit.error,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...PremiacaoActions, ...CampaignActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Premiacao as any);
