import React, { useEffect, useState } from 'react';
import Wrap from 'components/Wrap/Wrap';
import {
  Breadcrumbs,
  Typography,
  Link,
  Grid,
  IconButton,
  FormControl,
  FormControlLabel,
  TextField,
  Box,
  Paper,
  createStyles,
  makeStyles,
  Theme,
  Button,
  CircularProgress,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import cn from 'classnames';
import Api from '../../../common/Api';
import ListParticipant from './ListParticipant';
import Utils from '../../../common/Utils';
import UF from '../../../common/Estados.json';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    textCenter: {
      textAlign: 'center',
      display: 'block',
      width: '91%',
    },
    buttonActions: {
      padding: theme.spacing(1),
      minWidth: 120,
      textAlign: 'right',
      '& > button': {
        marginRight: 4,
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }),
);

export const valuewRequestDefault = {
  codigoSap: '',
  cnpj: '',
  cpf: '',
  uf: '',
  ativo: true,
  cidade: '',
  segmento: null,
  cluster: null,
  dataInicial: null,
  dataFinal: null,
  pagination: {
    Limit: 0,
    offset: 0,
  },
};

interface IParams {
  id?: string | undefined;
}

const SearchParticipant: React.FC<any & RouteComponentProps<IParams>> = ({ history }) => {
  const classes = useStyle();
  const inputLabel = React.useRef<HTMLLabelElement>(null);

  const [dataRequest, setDataRequest] = useState(valuewRequestDefault);

  const [participants, setParticipants] = useState([]);
  const [cluster, setCluster] = useState([]);
  const [segments, setSegments] = useState([]);
  const [gridShow, setGridShow] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadCluster();
    loadSegments();
  }, []);

  const handleChange = (prop: keyof any) => async (event: React.ChangeEvent<{ value: unknown }>) =>
    setDataRequest({ ...dataRequest, [prop]: event.target.value });

  const handleChangeName = event => {
    setDataRequest({ ...dataRequest, [event.target.name]: event.target.checked });
  };

  const resetFilter = () => setDataRequest(valuewRequestDefault);

  async function removeMask() {
    let nCpf = dataRequest.cpf.replace(/[^0-9]+/g, '');
    let nCnpj = dataRequest.cnpj.replace(/[^0-9]+/g, '');

    setDataRequest({
      ...dataRequest,
      ['cnpj']: nCnpj,
    });

    setDataRequest({
      ...dataRequest,
      ['cpf']: nCpf,
    });
  }

  async function loadCluster() {
    await Api.get('/shared/clusters').then(function(response) {
      if (
        response !== undefined &&
        response.status === 200 &&
        response.data &&
        response.data.clusters &&
        response.data.clusters.length > 0
      ) {
        setCluster(response.data.clusters);
      }
    });
    // if (resultCluster !== undefined && resultCluster.status === 200) ;
  }

  async function loadSegments() {
    const resultSegments = await Api.get('/shared/atividades');
    if (
      resultSegments !== undefined &&
      resultSegments.status === 200 &&
      resultSegments.data &&
      resultSegments.data.atividades &&
      resultSegments.data.atividades.length > 0
    ) {
      setSegments(resultSegments.data.atividades);
    }
  }

  async function searchParticipants() {
    setLoading(true);
    await removeMask();

    await Api.post('/participantes', dataRequest).then(function(response) {
      if (response !== undefined && response.status === 200) {
        setParticipants(response.data.data);
        setGridShow(true);
      } else {
        setParticipants([]);
      }
    });
    setLoading(false);
  }

  async function DownloadCSVParticipants() {
    setLoading(true);
    await removeMask();

    await Api.post('/participantes/csv', dataRequest).then(response => {
      if (response !== undefined && response.status === 200) {
        // create BOM UTF-8
        var buffer = new ArrayBuffer(3);
        var dataView = new DataView(buffer);
        dataView.setUint8(0, 0xfe);
        dataView.setUint8(1, 0xbb);
        dataView.setUint8(2, 0xbf);

        var csvString = response.data;
        var universalBOM = '\uFEFF';
        var a = window.document.createElement('a');
        a.setAttribute(
          'href',
          'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + csvString),
        );

        let dateString = new Date().toLocaleDateString('pt-BR');
        let dateReplace = dateString.replace('/', '').replace('/', '');
        a.setAttribute('download', `RelatorioDeParticipantesAdmin${dateReplace}_SouzaCruz.csv`);
        window.document.body.appendChild(a);
        a.click();
        setLoading(false);
      }
    });
  }

  return (
    <>
      <Wrap>
        <Breadcrumbs>
          <Link color="inherit" onClick={() => history.push('/')}>
            Home
          </Link>
          <Typography color="textPrimary">Gestão de Participantes</Typography>
        </Breadcrumbs>
      </Wrap>

      <Paper style={{ padding: 10 }}>
        <Typography component="h1" variant="h6">
          Preencha o filtro para listar os participantes:
        </Typography>
        <Box p="1.5em">
          <Grid container justify-content="center" alignItems="flex-start" spacing={2}>
            <FormControl variant="outlined" className={classes.formControl}>
              <TextField
                id="cpf"
                value={Utils.maskCpf(dataRequest.cpf)}
                type="text"
                onChange={e =>
                  setDataRequest({
                    ...dataRequest,
                    ['cpf']: Utils.maskCpf(e.target.value),
                  })
                }
                label="CPF"
                variant="outlined"
                fullWidth
              />
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl}>
              <TextField
                id="cnpj"
                value={dataRequest.cnpj}
                onChange={e =>
                  setDataRequest({
                    ...dataRequest,
                    ['cnpj']: Utils.setCnpjMask(e.target.value),
                  })
                }
                label="CNPJ"
                variant="outlined"
                fullWidth
                // InputProps={{
                //   inputComponent: CnpjMask as any,
                // }}
              />
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
              <TextField
                id="sap"
                value={dataRequest.codigoSap}
                onChange={handleChange('codigoSap')}
                label="Sap"
                variant="outlined"
                fullWidth
              />
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl}>
              <TextField
                id="cidade"
                value={dataRequest.cidade}
                onChange={handleChange('cidade')}
                label="Cidade"
                variant="outlined"
                fullWidth
              />
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel ref={inputLabel} htmlFor="perfil">
                Estado
              </InputLabel>
              <Select
                native={false}
                value={dataRequest.uf}
                onChange={handleChange('uf')}
                inputProps={{
                  name: 'nome',
                  id: 'sigla',
                }}
              >
                {UF.UF.map((uf: any, index) => {
                  return (
                    <MenuItem key={index} value={uf.sigla}>
                      {uf.sigla}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel>
                {/* <InputLabel ref={inputLabel}> */}
                Cluster
              </InputLabel>
              <Select
                native={false}
                value={dataRequest.cluster}
                onChange={handleChange('cluster')}
                inputProps={{
                  nome: 'nome',
                  id: 'id',
                }}
              >
                {cluster.map((cluster: any, index) => {
                  return (
                    <MenuItem key={index} value={cluster.id}>
                      {cluster.nome}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel>
                {/* <InputLabel ref={inputLabel}> */}
                Segmento
              </InputLabel>
              <Select
                native={false}
                value={dataRequest.segmento}
                onChange={handleChange('segmento')}
                inputProps={{
                  nome: 'nome',
                  id: 'id',
                }}
              >
                {segments.map((segments: any, index) => {
                  return (
                    <MenuItem key={index} value={segments.id}>
                      {segments.nome}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={dataRequest.ativo}
                    onChange={handleChangeName}
                    name="ativo"
                    color="primary"
                  />
                }
                label="Ativo"
              />
            </FormControl>

            <FormControl className={classes.formControl}>
              <TextField
                type="date"
                id="dataInicial"
                value={dataRequest.dataInicial}
                onChange={handleChange('dataInicial')}
                label="Data de cadastro inicial"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                type="date"
                id="dataFinal"
                value={dataRequest.dataFinal}
                onChange={handleChange('dataFinal')}
                label="Data de cadastro final"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item={true} xs={12} className={cn([classes.buttonActions])}>
            <Button onClick={resetFilter} variant="outlined" color="secondary" disabled={loading}>
              Limpar filtro
            </Button>

            <Button
              onClick={DownloadCSVParticipants}
              variant="contained"
              color="secondary"
              disabled={participants.length === 0 || loading}
            >
              Baixar CSV
            </Button>

            <Button
              onClick={searchParticipants}
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {loading ? 'Pesquisando...' : 'Pesquisar'}
            </Button>
          </Grid>
        </Box>
      </Paper>
      {gridShow && (
        <ListParticipant listParticipants={participants} loading={loading} history={history} />
      )}
    </>
  );
};

export default SearchParticipant;
