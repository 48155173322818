import { loadPremiacao as loadPremiacaoRefresh, resetPremiacao } from './actions';
import { loadCampaign } from './../campaign/edit/actions';
import { AppMessages } from 'common/AppMessages.enum';
import { showToastr } from 'store/toastr/actions';
import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import {
  loadPremiacao as loadPremiacaoRequest,
  loadPremiacaoSuccess,
  createPremiacaoSuccess,
  createPremiacaoFailure,
} from './actions';

import { push } from 'connected-react-router';
import Utils from 'common/Utils';

export function* loadPremiacao(action) {
  try {
    const { data } = yield call(API.get, `/campanha/premiacao/${action.payload.id}`);
    yield put(resetPremiacao());
    yield put(loadPremiacaoSuccess(data));
  } catch (error) {
    yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
    Utils.redirect(error);
  }
}

export function* createPremiacao(action) {
  try {
    if (action.payload.id === 0) {
      const { data } = yield call(API.post, `/campanha/premiacao/create`, action.payload);
      yield put(createPremiacaoSuccess({ ...data, ...action.payload }));
      yield put(
        showToastr({ message: AppMessages.SUCCESS_CREATE_PREMIACAO, type: 'success', open: true }),
      );
    } else {
      const { data } = yield call(
        API.patch,
        `/campanha/premiacao/${action.payload.campanhaId}/update`,
        action.payload,
      );

      yield put(createPremiacaoSuccess({ ...data, ...action.payload }));
      yield put(
        showToastr({ message: AppMessages.ERROR_CREATE_PREMIACAO, type: 'success', open: true }),
      );
      yield put(
        showToastr({
          type: 'success',
          message: AppMessages.SUCCESS_CREATE_PREMIACAO,
          open: true,
        }),
      );
    }
  } catch (error) {
    yield put(createPremiacaoFailure());
    yield put(
      showToastr({ message: AppMessages.ERROR_CREATE_PREMIACAO, type: 'error', open: true }),
    );
  }
}
