import React from 'react';
import { TABLE_STYLE } from 'common/Constants';
import { IconButton, Button, createStyles, makeStyles, Theme, Tooltip } from '@material-ui/core';
import { CloudDownload, FindInPage } from '@material-ui/icons';
import Utils from 'common/Utils';
import GenericTable from 'components/GenericTable/GenericTable';

interface IParamsProps {
  data: {
    title: string;
    responseTable: Array<any>;
    loadingTable: boolean;
  };
  setDetailModal: React.Dispatch<React.SetStateAction<{ isOpen: boolean; fileName: string }>>;
  detailModalApi: (id: number) => void;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      color: theme.palette.info.dark,
    },
    error: {
      color: theme.palette.error.main,
    },
    sucess: {
      color: theme.palette.success.dark,
    },
  }),
);

const Table: React.FC<IParamsProps> = ({ data, setDetailModal, detailModalApi }) => {
  const classes = useStyle();

  const handleModal = (id: number, fileName: string) => {
    detailModalApi(id);
    setDetailModal({ isOpen: true, fileName });
  };

  return (
    <GenericTable
      title={data.title}
      isLoading={data.loadingTable}
      options={{
        paging: true,
        search: true,
        pageSize: 5,
        emptyRowsWhenPaging: false,
      }}
      data={data.responseTable}
      columns={[
        {
          title: 'Ref. da Campanha Incentivo',
          field: 'campanhaIncentivo',
          sorting: false,
          render: rowData => {
            return `${rowData.campanhaIncentivo}`;
          },
          cellStyle: { ...TABLE_STYLE },
        },
        {
          title: 'Nome do Arquivo',
          field: 'nomeArquivo',
          sorting: false,
          render: rowData => {
            return `${rowData.nomeArquivo.split('.')[0]}`;
          },
          cellStyle: { ...TABLE_STYLE },
        },
        {
          title: 'Data envio',
          field: 'dataInclusao',
          defaultSort: 'desc',
          type: 'datetime',
          customSort: (a, b) =>
            new Date(a.dataInclusao).getTime() - new Date(b.dataInclusao).getTime(),
          render: rowData => {
            return `${Utils.setDataHora(rowData.dataInclusao)}`;
          },
          customFilterAndSearch: (filter, rowData) => {
            let stringDate = Utils.setData(rowData.dataInclusao);
            return stringDate.includes(filter);
          },
          cellStyle: { ...TABLE_STYLE },
        },
        {
          title: 'Status',
          field: 'descricaoStatus',
          sorting: false,
          render: rowData => {
            return `${rowData.descricaoStatus ? rowData.descricaoStatus : 'Iniciado'}`;
          },
          cellStyle: { ...TABLE_STYLE },
        },
        {
          title: 'Processando / Sucesso / Erro',
          sorting: false,
          render: rowData => {
            return (
              <>
                <span className={classes.progress}>
                  {rowData.quantidadeLinhasProcessando ? rowData.quantidadeLinhasProcessando : 0}
                </span>{' '}
                /{' '}
                <span className={classes.sucess}>
                  {rowData.quantidadeLinhasSucesso ? rowData.quantidadeLinhasSucesso : 0}
                </span>{' '}
                /{' '}
                <span className={classes.error}>
                  {rowData.quantidadeLinhasErro ? rowData.quantidadeLinhasErro : 0}
                </span>
              </>
            );
          },
          cellStyle: { ...TABLE_STYLE, textAlign: 'center' },
          headerStyle: { padding: '5px', textAlign: 'center' },
        },
        {
          title: 'Total',
          field: 'descricaoStatus',
          sorting: false,
          render: rowData => {
            return <span>{rowData.quantidadeLinhasTotal ? rowData.quantidadeLinhasTotal : 0}</span>;
          },
          cellStyle: { ...TABLE_STYLE },
        },
        {
          title: 'Descrição do Erro',
          field: 'descricaoerro',
          sorting: false,
          render: rowData => {
            return rowData.erro ? <span>{rowData.descricaoerro}</span> : 'Sem erros';
          },
          cellStyle: { ...TABLE_STYLE },
        },
        {
          title: 'Detalhamento',
          sorting: false,
          render: rowData => {
            return (
              <Button
                onClick={() => handleModal(rowData.id, rowData.nomeArquivo.split('.')[0])}
                style={{ textDecoration: 'underline' }}
                size={'small'}
              >
                Ver Detalhes
              </Button>
            );
          },
          cellStyle: { ...TABLE_STYLE },
        },
        {
          title: 'Ações',
          searchable: false,
          sorting: false,
          cellStyle: {
            width: '15%',
            ...TABLE_STYLE,
          },
          render: ({ id, urlArquivo, campanhaIncentivoId }) => (
            <>
              <Tooltip title="Baixe o arquivo utilizado no upload">
                <IconButton onClick={() => window.open(urlArquivo)}>
                  <CloudDownload />
                </IconButton>
              </Tooltip>
              <Tooltip title="Visualize em nova aba Campanha Incentivo de referência">
                <IconButton
                  onClick={() =>
                    window.open(`/pagamentos/promocoesincentivo/visualizar/${campanhaIncentivoId}`)
                  }
                >
                  <FindInPage />
                </IconButton>
              </Tooltip>
            </>
          ),
        },
      ]}
    />
  );
};

export default Table;
