import React, { useState, useEffect } from 'react';
import { Box, Paper } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import FileUpload from 'components/FileUpload/FileUpload';
import * as UploadDataFileChainingNetworkctions from 'store/chainingNetwork/uploadFile/actions';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import { Dispatch, bindActionCreators } from 'redux';
import { MODULE_TYPE, STRUCTURE_PULICTARGET } from 'common/Constants';
import UploadListChainingNetwork from './list/UploadListChainingNetwork';

interface UploadReportProps {
  chainingNetworkId?: number;
  loadUploadChainingNetworkFile(chainingNetworkId: number, moduleType: MODULE_TYPE): void;
  uploadChainingNetworkCsv(
    uploadUrl: string,
    data: FormData,
    callbackAction: any,
    id: number,
    type: number,
  ): void;
  uploadFile;
}

const UploadChainingNetwork: React.FC<UploadReportProps> = ({
  chainingNetworkId,
  loadUploadChainingNetworkFile,
  uploadFile,
  uploadChainingNetworkCsv,
}) => {
  const location = useLocation().pathname;
  const [url] = useState(`/encadeamentoRede`);
  chainingNetworkId = location.includes('create') ? 0 : chainingNetworkId;

  useEffect(() => {
    if (Number(chainingNetworkId) > 0 || chainingNetworkId) {
      loadUploadChainingNetworkFile(Number(chainingNetworkId), 1);
    }
  }, []);

  const handleUpload = (file: any) => {
    const data = new FormData();
    data.append('file', file);
    const uploadUrl = `${url}/uploadCsv`;
    uploadChainingNetworkCsv(uploadUrl, data, '', Number(chainingNetworkId), 1);
  };

  return (
    <>
      <Paper>
        <Box p="1.5em">
          <form>
            <Box>
              <Box fontSize="h5.fontSize" mt="1em">
                Definir seleção customizada:
              </Box>
              <Box fontSize={14} mb="1em">
                <strong>Importante!</strong> Para realizar a seleção customizada faça o upload de um
                arquivo CSV.
              </Box>

              <FileUpload onUpload={handleUpload} acceptType={['csv']} />

              <Box mt="1em">
                <UploadListChainingNetwork
                  uploadFile={uploadFile}
                  chainingNetworkId={chainingNetworkId}
                />
              </Box>
            </Box>
          </form>
        </Box>
      </Paper>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  uploadFile: state.uploadDataFileChainingNetworkList.data,
  loading: state.uploadDataFileChainingNetworkList.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(UploadDataFileChainingNetworkctions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UploadChainingNetwork);
