import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import {
  loadRealtimeTotalMalhaAtualizacoesVolumeSuccess,
  loadRealtimeTotalMalhaAtualizacoesVolumeFailure,
} from './actions';

export function* loadRealtimeTotalMalhaAtualizacoesVolumeRequest() {
  try {
    const { data } = yield call(API.get, 'dashboard/realtime/total/malha/atualizacoes/volume');
    yield put(loadRealtimeTotalMalhaAtualizacoesVolumeSuccess(data.itens, data.atualizacao));
  } catch (error) {
    yield put(loadRealtimeTotalMalhaAtualizacoesVolumeFailure());
  }
}
