import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import Utils from 'common/Utils';
import {
  loadSuccess,
  loadFailure,
  generateReportSuccess,
  generateReportFailure,
  loadVolumeSuccess,
  loadVolumeFailure,
  loadDetailsVolumeSuccess,
  loadDetailsVolumeFailure,
  uploadVolumeByFileSuccess,
  uploadVolumeByFileFailure,
} from './actions';

export function* loadCampaignCounting() {
  try {
    const { data } = yield call(API.get, 'campanha/ApuracaoCampanhaRegular');
    yield put(loadSuccess(data));
  } catch (error) {
    yield put(loadFailure());
    Utils.redirect(error);
  }
}

export function* generateCampaignCountingReport(action) {
  try {
    const { data } = yield call(
      API.post,
      'campanha/RelatorioApuracaoCampanha',
      action.payload.data,
    );

    yield put(generateReportSuccess(data));
  } catch (error) {
    yield put(generateReportFailure());
    Utils.redirect(error);
  }
}

export function* loadVolume() {
  try {
    const { data } = yield call(API.get, '/volume/all');

    yield put(loadVolumeSuccess(data));
  } catch (error) {
    yield put(loadVolumeFailure());
  }
}

export function* loadDetailsVolume(action) {
  try {
    const { data } = yield call(API.get, `/volume/${action.payload.id}/linhas`);

    yield put(loadDetailsVolumeSuccess(data));
  } catch (error) {
    yield put(loadDetailsVolumeFailure());
  }
}

export function* uploadVolumeByFile(action) {
  try {
    const { data } = yield call(API.post, `/volume/upload`, action.payload.file);
    yield put(uploadVolumeByFileSuccess(data));
  } catch (error) {
    yield put(uploadVolumeByFileFailure());
  }
}
