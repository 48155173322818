import { action } from 'typesafe-actions';
import {
  AutomaticCreditTypes,
  IAutomaticCreditItem,
  IAutomaticCreditParams,
  IStatusItem,
  IAutomaticCreditApproval,
} from './';

export const loadStatusAutomaticCreditRequest = () =>
  action(AutomaticCreditTypes.LOAD_STATUS_REQUEST);

export const loadStatusSuccess = (data: IStatusItem[]) =>
  action(AutomaticCreditTypes.LOAD_STATUS_SUCCESS, { data });

export const loadStatusFailure = () => action(AutomaticCreditTypes.LOAD_STATUS_FAILURE);

export const loadPendingAutomaticCreditRequest = (data: IAutomaticCreditParams) =>
  action(AutomaticCreditTypes.LOAD_PENDING_AUTOMATIC_CREDIT_REQUEST, { data });

export const loadPendingAutomaticCreditSuccess = (data: IAutomaticCreditItem[]) =>
  action(AutomaticCreditTypes.LOAD_PENDING_AUTOMATIC_CREDIT_SUCCESS, { data });

export const loadPendingAutomaticCreditFailure = () =>
  action(AutomaticCreditTypes.LOAD_PENDING_AUTOMATIC_CREDIT_FAILURE);

export const loadAutomaticCreditDetailRequest = (idSolicitacao: number) =>
  action(AutomaticCreditTypes.LOAD_AUTOMATIC_CREDIT_DETAIL_REQUEST, { idSolicitacao });

export const loadAutomaticCreditDetailSuccess = (data: IAutomaticCreditItem) =>
  action(AutomaticCreditTypes.LOAD_AUTOMATIC_CREDIT_DETAIL_SUCCESS, { data });

export const loadAutomaticCreditDetailFailure = () =>
  action(AutomaticCreditTypes.LOAD_AUTOMATIC_CREDIT_DETAIL_FAILURE);

export const approveAutomaticCreditRequest = (data: IAutomaticCreditApproval) =>
  action(AutomaticCreditTypes.APPROVE_AUTOMATIC_CREDIT_REQUEST, { data });

export const approveAutomaticCreditSuccess = (data: undefined) =>
  action(AutomaticCreditTypes.APPROVE_AUTOMATIC_CREDIT_SUCCESS, { data });

export const approveAutomaticCreditFailure = (success: boolean) =>
  action(AutomaticCreditTypes.APPROVE_AUTOMATIC_CREDIT_FAILURE, { success });

export const automaticCreditReportRequest = (data: IAutomaticCreditParams) =>
  action(AutomaticCreditTypes.AUTOMATIC_CREDIT_REPORT_REQUEST, { data });

export const automaticCreditReportSuccess = (data: string) =>
  action(AutomaticCreditTypes.AUTOMATIC_CREDIT_REPORT_SUCCESS, { data });

export const automaticCreditReportFailure = () =>
  action(AutomaticCreditTypes.AUTOMATIC_CREDIT_REPORT_FAILURE);

export const automaticCreditReportReset = () =>
  action(AutomaticCreditTypes.AUTOMATIC_CREDIT_REPORT_RESET);
