import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import { loadRealtimeTotalCadastrosSuccess, loadRealtimeTotalCadastrosFailure } from './actions';

export function* loadRealtimeTotalCadastrosRequest() {
  try {
    const { data } = yield call(API.get, 'dashboard/realtime/total/cadastros');
    yield put(loadRealtimeTotalCadastrosSuccess(data.itens, data.atualizacao));
  } catch (error) {
    yield put(loadRealtimeTotalCadastrosFailure());
  }
}
