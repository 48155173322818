import { action } from 'typesafe-actions';
import { BannersListTypes, IBannerListTypes } from './';

export const loadBannerTypes = () => action(BannersListTypes.LOAD_TYPES_REQUEST);
export const loadBannerTypesSuccess = (data: IBannerListTypes[]) =>
  action(BannersListTypes.LOAD_TYPES_SUCCESS, { data });

export const loadBannersByTypes = (typesId: number[]) =>
  action(BannersListTypes.LOAD_BANNERS_BY_TYPE_REQUEST, { typesId });
export const loadBannersByTypesSuccess = data =>
  action(BannersListTypes.LOAD_BANNERS_BY_TYPE_SUCCESS, { data });
