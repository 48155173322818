import { Box, Button, createStyles, Grid, makeStyles, Paper, Theme } from '@material-ui/core';
import Api from 'common/Api';
import { ElegiblesType, IFilterItemRequest } from 'common/Constants';
import Utils from 'common/Utils';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from 'store';
import { ICluster } from 'store/clusters';
import * as ClustersActions from 'store/clusters/actions';
import { IRegion } from 'store/regions';
import * as RegionsActions from 'store/regions/actions';
import { ISegment } from 'store/segments';
import * as SegmentsActions from 'store/segments/actions';
import ElegiblesFilterItem from './ElegiblesFilterItem';

interface ElegiblesProps {
  loadRegions(): void;
  loadClusters(): void;
  loadSegments(): void;
  applyFilter(id: number, filter: ISelectedFilters): void;
  regions: IRegion[];
  clusters: ICluster[];
  segments: ISegment[];
  loadingRegions: boolean;
  loadingClusters: boolean;
  loadingSegments: boolean;
  loading: boolean;
  selectedFilters?: ISelectedFilters;
  applyFilterId: number;
  disabled?: boolean;
}

export interface ISelectedFilters {
  ufs: number[] | [];
  clusters: number[] | [];
  atividades: number[] | [];
}

export const initialFilters = {
  ufs: [],
  clusters: [],
  atividades: [],
};

export interface IElegiblesState {
  selectAllRegion: boolean;
  selectAllCluster: boolean;
  selectAllSegment: boolean;
  regions: IFilterItemRequest[];
  segments: IFilterItemRequest[];
  cluster: IFilterItemRequest[];
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginBottom: theme.spacing(1),
    },
    fullWidth: {
      width: '100%',
    },
    spacing: {
      marginBottom: theme.spacing(2),
    },
    listBox: {
      heigth: '260px',
      overflow: 'auto',
      minHeight: '260px',
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& > button': {
        marginLeft: theme.spacing(2),
        marginRight: '0 !important',
      },
    },
  }),
);

const GenericElegibles: React.FC<ElegiblesProps> = ({
  regions,
  clusters,
  segments,
  loadingRegions,
  loadingClusters,
  loadingSegments,
  loadRegions,
  loadClusters,
  loadSegments,
  selectedFilters = initialFilters,
  applyFilter,
  loading,
  applyFilterId,
  disabled = false,
}) => {
  const classes = useStyle();

  const [regionsState, setRegionsState] = React.useState<IFilterItemRequest[]>([]);
  const [clustersState, setClustersState] = React.useState<IFilterItemRequest[]>([]);
  const [segmentsState, setSegmentsState] = React.useState<IFilterItemRequest[]>([]);
  const [participantCount, setParticipantCount] = useState('');

  const isApplyDisabled = () => {
    return (
      regionsState.filter(e => e.selected).length === 0 ||
      clustersState.filter(e => e.selected).length === 0 ||
      segmentsState.filter(e => e.selected).length === 0
    );
  };

  const handleClearFilters = () => {
    setRegionsState([
      ...Utils.toFilterItemObject<IRegion>(regions, 'id', 'nome', ElegiblesType.REGION),
    ]);

    setClustersState([
      ...Utils.toFilterItemObject<ICluster>(clusters, 'id', 'nome', ElegiblesType.CLUSTER),
    ]);

    setSegmentsState([
      ...Utils.toFilterItemObject<ISegment>(segments, 'id', 'nome', ElegiblesType.SEGMENT),
    ]);

    setParticipantCount('');
  };

  const handlePostSegments = async () => {
    try {
      const ufs = regionsState
        .filter(e => {
          return e.selected;
        })
        .map(e => {
          return e.id;
        });

      const clusters = clustersState
        .filter(e => {
          return e.selected;
        })
        .map(e => {
          return e.id;
        });

      const atividades = segmentsState
        .filter(e => {
          return e.selected;
        })
        .map(e => {
          return e.id;
        });

      if (ufs.length > 0 && clusters.length > 0 && atividades.length > 0) {
        const { data } = await Api.post(`/shared/contagem/filtro`, {
          ufs,
          clusters,
          atividades,
        });
        if (data) {
          setParticipantCount(
            `Os filtros aplicados resultaram na seleção de <strong>${data}</strong> participantes`,
          );
        }
      } else {
        setParticipantCount('');
      }
    } catch (error) {
      setParticipantCount('');
    }
  };

  const handleApplyFilter = (clear?: boolean) => {
    const ufs = clear
      ? []
      : regionsState
          .filter(e => {
            return e.selected;
          })
          .map(e => {
            return e.id;
          });

    const clusters = clear
      ? []
      : clustersState
          .filter(e => {
            return e.selected;
          })
          .map(e => {
            return e.id;
          });

    const atividades = clear
      ? []
      : segmentsState
          .filter(e => {
            return e.selected;
          })
          .map(e => {
            return e.id;
          });

    let filter = {
      ufs,
      clusters,
      atividades,
    };

    applyFilter(applyFilterId, filter);
  };

  useEffect(() => {
    setRegionsState([
      ...Utils.toFilterItemObjectSelecteItems<IRegion>(
        regions,
        'id',
        'nome',
        ElegiblesType.REGION,
        selectedFilters.ufs,
      ),
    ]);
  }, [regions, selectedFilters]);

  useEffect(() => {
    setClustersState([
      ...Utils.toFilterItemObjectSelecteItems<ICluster>(
        clusters,
        'id',
        'nome',
        ElegiblesType.CLUSTER,
        selectedFilters.clusters,
      ),
    ]);
  }, [clusters, selectedFilters]);

  useEffect(() => {
    setSegmentsState([
      ...Utils.toFilterItemObjectSelecteItems<ISegment>(
        segments,
        'id',
        'nome',
        ElegiblesType.SEGMENT,
        selectedFilters.atividades,
      ),
    ]);
  }, [segments, selectedFilters]);

  useEffect(() => {
    loadRegions();
    loadClusters();
    loadSegments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handlePostSegments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segments]);

  return (
    <>
      <Box className={classes.spacing}>
        Defina os participantes elegíveis à campanha utilizando uma das opções abaixo:
      </Box>

      <Paper elevation={3}>
        <Box p="1.5em">
          <Box fontSize="h4.fontSize">PDV</Box>
          <Box fontSize={12} className={classes.spacing}>
            Selecione a região, cluster e segmento:
          </Box>

          <Grid container={true} spacing={3} className={classes.spacing}>
            <ElegiblesFilterItem
              isLoading={loadingRegions || loading}
              itemName="regions"
              searchTitle="Pesquisar regiões"
              setState={setRegionsState}
              state={regionsState}
              forKey="nome"
              onPost={() => handlePostSegments()}
            />

            <ElegiblesFilterItem
              isLoading={loadingClusters || loading}
              itemName="clusters"
              searchTitle="Pesquisar clusters"
              setState={setClustersState}
              state={clustersState}
              forKey="id"
              onPost={() => handlePostSegments()}
            />
            <ElegiblesFilterItem
              isLoading={loadingSegments || loading}
              itemName="segments"
              searchTitle="Pesquisar segmentos"
              setState={setSegmentsState}
              state={segmentsState}
              forKey="nome"
              onPost={() => handlePostSegments()}
            />
          </Grid>

          <Box alignItems="center" className={classes.actions}>
            <Box fontSize={12}>
              <div
                dangerouslySetInnerHTML={{
                  __html: participantCount,
                }}
              ></div>
              {}
            </Box>
            <Button
              color="secondary"
              variant="contained"
              disabled={loading || isApplyDisabled() || disabled}
              onClick={() => handleApplyFilter(false)}
            >
              {loading ? 'Aguarde...' : 'Salvar'}
            </Button>
            <Button variant="outlined" onClick={handleClearFilters} disabled={loading}>
              {loading ? 'Aguarde...' : 'Limpar filtros'}
            </Button>
          </Box>
        </Box>
      </Paper>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  regions: state.regions.data,
  clusters: state.clusters.data,
  segments: state.segments.data,
  loadingRegions: state.regions.loading,
  loadingClusters: state.clusters.loading,
  loadingSegments: state.segments.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...RegionsActions,
      ...SegmentsActions,
      ...ClustersActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(GenericElegibles);
