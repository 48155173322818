import React, { useState, useEffect } from 'react';
import Wrap from 'components/Wrap/Wrap';
import UserRegister from './UserRegister';
import { RouteComponentProps } from 'react-router-dom';
import { Breadcrumbs, Typography, Link, Box } from '@material-ui/core';

interface IParams {
  id?: string | undefined;
}

const UserEdit: React.FC<any & RouteComponentProps<IParams>> = ({ history, match }) => {
  return (
    <Box mb="1.5em">
      <Wrap>
        <Breadcrumbs>
          <Link color="inherit" onClick={() => history.push('/')}>
            Home
          </Link>
          <Link color="inherit" onClick={() => history.push('/usuarios')}>
            Gestão de Usuaríos
          </Link>
          <Typography color="textPrimary">Cadastrar</Typography>
        </Breadcrumbs>
      </Wrap>

      <UserRegister loading={false} match={match} history={history} />
    </Box>
  );
};

export default UserEdit;
