import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import Table from './Table/Table';
import ModalDetails from './ModalDetails/ModalDetails';
import DefaultExampleModel from 'components/DownloadClientBase/DownloadClientBaseCampaign';
import FileUpload from 'components/FileUpload/FileUpload';

export interface ImportGenericTableData {
  title: string;
  responseTable: Array<any>;
  responseModal: Array<any>;
  loadingModal: boolean;
  loadingTable: boolean;
  disabledHandleUpload: boolean;
}
interface ImportGenericTableProps {
  props: ImportGenericTableData;
  uploadApi: (file) => void;
  detailModalApi: (id: number) => void;
  exampleUploadFile?(): JSX.Element;
  children?: JSX.Element;
}

const ReentryImportTable: React.FC<ImportGenericTableProps> = ({
  props,
  uploadApi,
  detailModalApi,
  children,
  exampleUploadFile,
}) => {
  const [detailModal, setDetailModal] = useState<{ isOpen: boolean; fileName: string }>({
    isOpen: false,
    fileName: '',
  });
  const handleUpload = (file: any) => {
    const data = new FormData();
    data.append('file', file);

    uploadApi(data);
  };

  const modalProps = {
    detailModal,
    setDetailModal,
  };

  return (
    <Box width={'100%'}>
      <Box fontSize="h5.fontSize" mt="1em">
        Definir seleção customizada:
      </Box>
      <Box fontSize={14} mb="1em">
        <strong>Importante!</strong> Para realizar a seleção customizada faça o upload de um arquivo
        Excel contendo a base de CodigoSap elegíveis.
      </Box>

      {children}

      <FileUpload
        disabled={props.disabledHandleUpload}
        onUpload={handleUpload}
        acceptType={['xls', 'xlsx', 'csv']}
      />

      <Box mt="1em">{exampleUploadFile ? exampleUploadFile() : <DefaultExampleModel />}</Box>

      <Box>
        <Table data={props} {...modalProps} detailModalApi={detailModalApi} />
      </Box>
      <ModalDetails data={props.responseModal} loading={props.loadingModal} {...modalProps} />
    </Box>
  );
};

export default ReentryImportTable;
