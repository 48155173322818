import { action } from 'typesafe-actions';
import { CampaignCountingTypes, ICampaignCountingItem, ICampaignReportParams } from './';

export const loadRequest = () => action(CampaignCountingTypes.LOAD_REQUEST);
export const loadSuccess = (data: ICampaignCountingItem[]) =>
  action(CampaignCountingTypes.LOAD_SUCCESS, { data });
export const loadFailure = () => action(CampaignCountingTypes.LOAD_FAILURE);

export const generateReportRequest = (data: ICampaignReportParams) =>
  action(CampaignCountingTypes.GENERATE_REPORT_REQUEST, { data });
export const generateReportSuccess = (data: undefined) =>
  action(CampaignCountingTypes.GENERATE_REPORT_SUCCESS, { data });
export const generateReportFailure = () => action(CampaignCountingTypes.GENERATE_REPORT_FAILURE);
export const generateReportResetParams = () =>
  action(CampaignCountingTypes.GENERATE_REPORT_RESET_PARAMS);

export const loadVolume = () => action(CampaignCountingTypes.LOAD_VOLUME_REQUEST);
export const loadVolumeSuccess = data =>
  action(CampaignCountingTypes.LOAD_VOLUME_SUCCESS, { data });
export const loadVolumeFailure = () => action(CampaignCountingTypes.LOAD_VOLUME_FAILURE);

export const loadDetailsVolume = (id: number) =>
  action(CampaignCountingTypes.LOAD_DETAIL_LIST_REQUEST, { id });
export const loadDetailsVolumeSuccess = data =>
  action(CampaignCountingTypes.LOAD_DETAIL_LIST_SUCCESS, { data });
export const loadDetailsVolumeFailure = () =>
  action(CampaignCountingTypes.LOAD_DETAIL_LIST_FAILURE);

export const uploadVolumeByFile = (file: FormData) =>
  action(CampaignCountingTypes.UPLOAD_REQUEST, { file });
export const uploadVolumeByFileSuccess = data =>
  action(CampaignCountingTypes.UPLOAD_SUCCESS, { data });
export const uploadVolumeByFileFailure = () => action(CampaignCountingTypes.UPLOAD_FAILURE);
