import { action } from 'typesafe-actions';
import { UploadDataFileChainingNetworkTypes } from '.';
import { MODULE_TYPE } from 'common/Constants';

export const loadUploadChainingNetworkFile = (id: number) =>
  action(UploadDataFileChainingNetworkTypes.LOAD_REQUEST, { id });
export const loadUploadFileSuccess = data =>
  action(UploadDataFileChainingNetworkTypes.LOAD_SUCCESS, { data });
export const loadUploadFileFailure = () => action(UploadDataFileChainingNetworkTypes.LOAD_FAILURE);
export const resetChainingNetworkFile = () =>
  action(UploadDataFileChainingNetworkTypes.RESET_REQUEST);

export const uploadChainingNetworkCsv = (
  uploadUrl: string,
  data: FormData,
  callbackAction: any,
  id: number,
  type: number,
) =>
  action(UploadDataFileChainingNetworkTypes.UPLOAD_REQUEST, {
    uploadUrl,
    data,
    callbackAction,
    id,
    type,
  });
export const uploadChainingNetworkCsvSuccess = data =>
  action(UploadDataFileChainingNetworkTypes.UPLOAD_SUCCESS, { data });
export const uploadChainingNetworkCsvFailure = () =>
  action(UploadDataFileChainingNetworkTypes.UPLOAD_FAILURE);

export const deleteChainingNetworkCsv = (deleteUrl: string) =>
  action(UploadDataFileChainingNetworkTypes.DELETE_REQUEST, { deleteUrl });
export const deleteChainingNetworkCsvSuccess = data =>
  action(UploadDataFileChainingNetworkTypes.DELETE_SUCCESS, { data });
export const deleteChainingNetworkCsvFailure = () =>
  action(UploadDataFileChainingNetworkTypes.DELETE_FAILURE);
