import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import BannerHomeUpload, { BannerHomeSize } from 'components/BannerHomeUpload/BannerHomeUpload';
import { connect } from 'react-redux';
import * as CampaignEditActions from 'store/campaign/edit/actions';
import { ICampaignBanner } from 'store/campaign/edit';
import { Dispatch, bindActionCreators } from 'redux';
import { ApplicationState } from 'store';

interface BannersProps {
  campaignBanners: ICampaignBanner[];
  campaignId: number;
}

const Banners: React.FC<BannersProps> = ({ campaignBanners, campaignId }) => {
  const [banners, setBanners] = useState(campaignBanners);

  React.useEffect(() => {
    setBanners(campaignBanners);
  }, [campaignBanners]);

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} sm={4}>
        <BannerHomeUpload
          banner={banners.find(e => e.tipoId === BannerHomeSize.SMALL)}
          size={BannerHomeSize.SMALL}
          campaignId={campaignId}
        />
      </Grid>
      <Grid item={true} sm={4}>
        <BannerHomeUpload
          banner={banners.find(e => e.tipoId === BannerHomeSize.MEDIUM)}
          size={BannerHomeSize.MEDIUM}
          campaignId={campaignId}
        />
      </Grid>
      <Grid item={true} sm={4}>
        <BannerHomeUpload
          banner={banners.find(e => e.tipoId === BannerHomeSize.BIG)}
          size={BannerHomeSize.BIG}
          campaignId={campaignId}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  campaignId: state.campaignEdit.data.id,
  campaignBanners: state.campaignEdit.data.campanhaBanner,
  loading: state.campaignEdit.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...CampaignEditActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Banners as any);
