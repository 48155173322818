import React, { useEffect, useState } from 'react';
import { ApplicationState } from 'store';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AppMessages } from 'common/AppMessages.enum';
import cn from 'classnames';

import {
  IUploadCampaignReportEdit,
  IUploadCampaignReportCreateRequest,
  IUploadCampaignReportData,
} from 'store/uploadCampaignReport';
import * as UploadCampaignReportActions from 'store/uploadCampaignReport/actions';
import * as CampaignReportActions from 'store/campaignReport/actions';
import { ITypeCampaignReportItem } from 'store/campaignReport';
import Utils from 'common/Utils';
import {
  Box,
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  Paper,
  Select,
  Theme,
  Button,
  Grid,
  TextField,
} from '@material-ui/core';

interface UploadCampaignEditProps {
  typesCampaignReport: ITypeCampaignReportItem[];
  campaignReport: IUploadCampaignReportEdit;
  loading: boolean;
  loadTypesCampaignReportRequest: () => void;
  createUploadCampaignReportRequest: (data: IUploadCampaignReportCreateRequest) => void;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    textCenter: {
      textAlign: 'center',
      display: 'block',
      width: '91%',
    },
    buttonActions: {
      padding: theme.spacing(1),
      minWidth: 120,
      textAlign: 'right',
      '& > button': {
        marginRight: 0,
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    spacing: {
      marginBottom: theme.spacing(2),
    },
  }),
);

const UploadCampaignReportGeneralData: React.FC<UploadCampaignEditProps> = ({
  campaignReport,
  createUploadCampaignReportRequest,
  loadTypesCampaignReportRequest,
  typesCampaignReport,
}) => {
  const classes = useStyle();
  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = useState(0);

  const [state, setState] = useState({ ...campaignReport });
  const [errorMessage, setErrorMessage] = useState('');

  const [errorMessageTypeReport, setErrorMessageTypeReport] = useState(false);

  const handleChange = (prop: keyof IUploadCampaignReportData) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.type === 'checkbox') setState({ ...state, [prop]: event.target.checked });
    else setState({ ...state, [prop]: event.target.value });
  };

  const setPaymentMethod = (prop: keyof IUploadCampaignReportData) => (
    event: React.ChangeEvent<{ value: any }>,
  ) => {
    setState({ ...state, [prop]: parseInt(event.target.value) });
  };

  const handleOnSave = async () => {
    const hasErrors = getErrorMessages();
    if (!hasErrors) {
      createUploadCampaignReportRequest(state);
    }
  };

  const getErrorMessages = (): boolean => {
    const isInputRequiredValid = Utils.hasInputError(
      [state.descricao, state.data_Processamento],
      true,
    );
    const isNameOutOfRange = state.descricao.length < 5 || state.descricao.length > 100;

    const isSelectedTypeReport = state.tipoCampanhaRelatorio <= 0;

    setErrorMessageTypeReport(isSelectedTypeReport);

    setErrorMessage(
      isSelectedTypeReport
        ? AppMessages.FORM_FIELDS_REQUIRED
        : isInputRequiredValid
        ? AppMessages.FORM_FIELDS_REQUIRED
        : isNameOutOfRange
        ? 'Nome da campanha deve ter no mínimo 5 e no máximo 100 caracteres.'
        : '',
    );

    return [!isInputRequiredValid, !isNameOutOfRange, !isSelectedTypeReport].some(e => !e);
  };

  useEffect(() => {
    setLabelWidth(inputLabel?.current?.offsetWidth || 0);

    loadTypesCampaignReportRequest();
  }, [labelWidth]);

  useEffect(() => {
    setState({ ...campaignReport });
  }, [campaignReport]);

  return (
    <Paper>
      <Box p="1.5em">
        <form>
          <Box width={6 / 12}>
            <FormControl fullWidth={true} className={classes.formControl}>
              <TextField
                error={Utils.hasInputError(state.descricao, true) && !!errorMessage}
                id="descricao"
                value={state.descricao || ''}
                onChange={handleChange('descricao')}
                label="Descrição"
                variant="outlined"
                inputProps={{
                  maxLength: 25,
                }}
              />
            </FormControl>
          </Box>
          <Box width={6 / 12}>
            <FormControl fullWidth={true} variant="outlined" className={classes.formControl}>
              <InputLabel ref={inputLabel} htmlFor="campaignReport.tipoCampanhaRelatorio">
                Selecione tipo de relatório {errorMessageTypeReport}
              </InputLabel>
              <Select
                error={!!errorMessageTypeReport && !!errorMessage}
                required
                native
                value={state.tipoCampanhaRelatorio || 0}
                labelWidth={labelWidth}
                onChange={setPaymentMethod('tipoCampanhaRelatorio')}
                inputProps={{
                  name: 'campaignReport.tipoCampanhaRelatorio',
                  id: 'campaignReport.tipoCampanhaRelatorio',
                }}
              >
                <option value={0}>Todos</option>

                {typesCampaignReport.map(method => (
                  <option key={method.id} value={method.id}>
                    {method.nome}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Grid container={true}>
            <Grid item={true} xs={2}>
              <FormControl fullWidth={true} className={classes.formControl}>
                <TextField
                  error={Utils.hasInputError(state.data_Processamento, true) && !!errorMessage}
                  required={true}
                  type="date"
                  id="dataProcessamento"
                  value={state.data_Processamento.substring(0, 10)}
                  onChange={handleChange('data_Processamento')}
                  label="Data Processamento"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container={true}>
            <Grid item={true} xs={3} className={classes.formControl}>
              <Box fontSize={14} color="red">
                {errorMessage}
              </Box>
            </Grid>
            <Grid item={true} xs={3} className={cn([classes.buttonActions])}>
              <Button onClick={handleOnSave} variant="contained" color="secondary">
                Cadastrar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Paper>
  );
};
const mapStateToProps = (state: ApplicationState) => ({
  typesCampaignReport: state.campaignReport.typesReports,
  campaignReport: state.uploadCampaignReport.data,
  loading: state.uploadCampaignReport.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    Object.assign({}, UploadCampaignReportActions, CampaignReportActions),
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(UploadCampaignReportGeneralData as any);
