import React, { useState, useEffect } from 'react';
import { IMailing } from 'store/mailing';
import { TABLE_STYLE } from 'common/Constants';
import { IconButton } from '@material-ui/core';
import { Delete, CloudDownload } from '@material-ui/icons';
import Utils from 'common/Utils';
import { Dispatch, bindActionCreators } from 'redux';
import * as UploadChainingNetworkActions from 'store/chainingNetwork/uploadFile/actions';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import GenericTable from 'components/GenericTable/GenericTable';
import { IUploadChainingNetworkDataFile } from 'store/chainingNetwork/uploadFile';

interface UploadCampaignDataFileProps {
  uploadFile: IUploadChainingNetworkDataFile[];
  chainingNetworkId?: number;
  deleteChainingNetworkCsv(deleteUrl: string): void;
  resetChainingNetworkFile(): void;
}

const UploadListChainingNetwork: React.FC<UploadCampaignDataFileProps> = ({
  uploadFile,
  chainingNetworkId,
  deleteChainingNetworkCsv,
  resetChainingNetworkFile,
}) => {
  useEffect(() => {
    if (Number(chainingNetworkId) === 0 || chainingNetworkId) {
      resetChainingNetworkFile();
    }
  }, []);

  const location = useLocation().pathname;

  const handleOnDelete = (chainingNetworkId: number) => {
    const deleteUrl = `encadeamentoRede/${chainingNetworkId}/upload`;
    deleteChainingNetworkCsv(deleteUrl);
  };

  return uploadFile.length > 0 ? (
    <GenericTable
      title="Upload Campanha Relatório"
      isLoading={false}
      data={uploadFile}
      options={{
        paging: false,
        search: false,
      }}
      columns={[
        {
          title: 'Nome Arquivo',
          render: rowData => {
            return `${rowData.nome_Arquivo_Original}`;
          },
          cellStyle: { ...TABLE_STYLE },
        },
        {
          title: 'Data Processamento',
          render: rowData => {
            return `${Utils.setData(rowData.dt_Processamento_Original)}`;
          },
          cellStyle: { ...TABLE_STYLE },
        },
        {
          title: 'Ações',
          cellStyle: {
            width: '15%',
            ...TABLE_STYLE,
          },
          render: ({ id, url_Arquivo_Original }) => (
            <>
              <IconButton onClick={() => window.open(url_Arquivo_Original)}>
                <CloudDownload />
              </IconButton>
              <IconButton onClick={() => handleOnDelete(id)}>
                <Delete fontSize="small" />
              </IconButton>
            </>
          ),
        },
      ]}
    />
  ) : null;
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(UploadChainingNetworkActions, dispatch);

export default connect(null, mapDispatchToProps)(UploadListChainingNetwork);
