import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import {
  loadRealtimeTotalErrosCancelamentosSuccess,
  loadRealtimeTotalErrosCancelamentosFailure,
} from './actions';

export function* loadRealtimeTotalErrosCancelamentosRequest() {
  try {
    const { data } = yield call(API.get, 'dashboard/realtime/total/erros/cancelamentos');
    yield put(loadRealtimeTotalErrosCancelamentosSuccess(data.itens, data.atualizacao));
  } catch (error) {
    yield put(loadRealtimeTotalErrosCancelamentosFailure());
  }
}
