import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import { Dispatch } from 'redux';
import { RouteComponentProps } from 'react-router-dom';
import { FormControl, Button, Grid, ButtonGroup, TextField, IconButton } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import { TABLE_STYLE } from 'common/Constants';
import GenericTable from 'components/GenericTable/GenericTable';
import * as MigrationOfPointsActions from 'store/migrationOfPoints/actions';
import ModerationModal from './ModerationModal';
import {
  IMigrationOfPointsItem,
  IMigrationOfPointsParams,
  IStatusItem,
} from 'store/migrationOfPoints';

interface IProps extends RouteComponentProps {
  statusList: IStatusItem[];
  pendingApprovals: IMigrationOfPointsItem[];
  loading: boolean;
  report: string;
  loadStatusRequest: () => void;
  loadPendingMigrationsRequest: (data: IMigrationOfPointsParams) => void;
  loadMigrationReportRequest: (data: IMigrationOfPointsParams) => void;
  loadMigrationReportReset: () => void;
}

const PointsMigration: React.FC<IProps> = ({
  loadStatusRequest,
  loadPendingMigrationsRequest,
  loadMigrationReportRequest,
  loadMigrationReportReset,
  statusList,
  pendingApprovals,
  loading,
  report,
}) => {
  const [status, setStatus] = useState(1);
  const [document, setDocument] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState({
    id: 0,
    userName: '',
    razaoSocial: '',
    dataSolicitacao: '',
    dataSolicitacaoStr: '',
    totalPontos: 0,
    statusId: 0,
    status: '',
    motivo: '',
    moderadoPor: '',
    dataAprovacao: '',
    dataModeracao: '',
  });

  useEffect(() => {
    loadStatusRequest();

    let data = {
      idStatus: status,
      documento: document,
    };

    loadPendingMigrationsRequest(data);
  }, []);

  useEffect(() => {
    if (data.id !== 0) setOpenModal(true);
  }, [data]);

  useEffect(() => {
    if (!openModal) setData({ ...data, id: 0 });
  }, [openModal]);

  useEffect(() => {
    if (report !== undefined && report !== '') {
      var buffer = new ArrayBuffer(3);
      var dataView = new DataView(buffer);
      dataView.setUint8(0, 0xfe);
      dataView.setUint8(1, 0xbb);
      dataView.setUint8(2, 0xbf);

      var csvString = report;
      var universalBOM = '\uFEFF';
      var a = window.document.createElement('a');
      a.setAttribute(
        'href',
        'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + csvString),
      );

      let date = new Date();
      let reportName = `${date.getMonth() + 1}${date.getFullYear()}_Relatorio_Moderacao_Pontos.csv`;
      a.setAttribute('download', reportName);
      window.document.body.appendChild(a);
      a.click();

      loadMigrationReportReset();
    }
  }, [report]);

  const handleSubmit = event => {
    event.preventDefault();

    let data = {
      idStatus: status,
      documento: document,
    };

    loadPendingMigrationsRequest(data);
  };

  const handleApproving = (data: IMigrationOfPointsItem) => {
    setData(data);
  };

  const handleGenerateReport = event => {
    event.preventDefault();

    let data = {
      idStatus: status,
      documento: document,
    };

    loadMigrationReportRequest(data);
  };

  return (
    <div>
      <h1>Migração de pontos</h1>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <TextField
                name="status"
                id="status"
                size="small"
                select
                required
                value={status}
                label="Selecione o status"
                onChange={(e: React.ChangeEvent<{ value: any }>) =>
                  setStatus(parseInt(e.target.value))
                }
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              >
                <option value="0">Todos</option>

                {statusList.map(status => (
                  <option key={status.id} value={status.id}>
                    {status.nome}
                  </option>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth={true}>
              <TextField
                name="document"
                id="document"
                value={document}
                onChange={(e: React.ChangeEvent<{ value: any }>) => setDocument(e.target.value)}
                variant="outlined"
                size="small"
                label="Código SAP, CPF ou CNPJ"
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <ButtonGroup size="large" color="primary" aria-label="outlined primary button group">
              <Button type="submit" disabled={loading ? true : false}>
                Pesquisar
              </Button>
              <Button
                type="button"
                disabled={loading ? true : false}
                onClick={e => handleGenerateReport(e)}
              >
                Exportar CSV
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </form>
      <br />
      <GenericTable
        title="Solicitações"
        isLoading={loading}
        data={pendingApprovals}
        options={{
          paging: true,
          search: true,
          pageSize: 10,
          sorting: false,
        }}
        columns={[
          {
            searchable: true,
            title: 'Matriz',
            field: 'userName',
            cellStyle: { ...TABLE_STYLE, width: '15%' },
          },
          {
            searchable: true,
            title: 'Razão social / Nome',
            field: 'razaoSocial',
            cellStyle: { ...TABLE_STYLE, width: '12%' },
          },
          {
            searchable: true,
            title: 'Total de pontos a migrar',
            field: 'totalPontos',
            cellStyle: { ...TABLE_STYLE, width: '12%' },
          },
          {
            searchable: false,
            title: 'Solicitado em',
            field: 'dataSolicitacaoStr',
            cellStyle: { ...TABLE_STYLE, width: '15%' },
          },
          {
            searchable: true,
            title: 'Status',
            field: 'status',
            cellStyle: { ...TABLE_STYLE, width: '10%' },
          },
          {
            searchable: false,
            title: 'Moderação de pedido',
            cellStyle: {
              width: '30%',
              ...TABLE_STYLE,
            },
            render: data => (
              <IconButton color="primary" onClick={() => handleApproving(data)}>
                <Edit fontSize="small" />
              </IconButton>
            ),
          },
        ]}
      />
      <ModerationModal
        title="Moderação de pedido"
        state={openModal}
        setState={setOpenModal}
        size="lg"
        data={data}
        loadMigrationParams={{
          idStatus: status,
          documento: document,
        }}
      />
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    statusList: state.migrationOfPoints.statusList,
    pendingApprovals: state.migrationOfPoints.data,
    loading: state.migrationOfPoints.loading,
    error: state.migrationOfPoints.error,
    success: state.migrationOfPoints.success,
    loadingReport: state.migrationOfPoints.loadingReport,
    report: state.migrationOfPoints.report,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadStatusRequest: () => {
      dispatch(MigrationOfPointsActions.loadStatusRequest());
    },
    loadPendingMigrationsRequest: (data: IMigrationOfPointsParams) => {
      dispatch(MigrationOfPointsActions.loadPendingMigrationsRequest(data));
    },
    loadMigrationReportRequest: (data: IMigrationOfPointsParams) => {
      dispatch(MigrationOfPointsActions.loadMigrationReportRequest(data));
    },
    loadMigrationReportReset: () => {
      dispatch(MigrationOfPointsActions.loadMigrationReportReset());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PointsMigration);
