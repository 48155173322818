import { action } from 'typesafe-actions';
import {
  MigrationOfPointsTypes,
  IMigrationOfPointsParams,
  IMigrationOfPointsItem,
  IStatusItem,
  IMigrationPointsDetail,
  IMigrationPointsModeration,
} from './';

export const loadStatusRequest = () => action(MigrationOfPointsTypes.LOAD_STATUS_REQUEST);

export const loadStatusSuccess = (data: IStatusItem[]) =>
  action(MigrationOfPointsTypes.LOAD_STATUS_SUCCESS, { data });

export const loadStatusFailure = () => action(MigrationOfPointsTypes.LOAD_STATUS_FAILURE);

export const loadPendingMigrationsRequest = (data: IMigrationOfPointsParams) =>
  action(MigrationOfPointsTypes.LOAD_PENDING_MIGRATIONS_REQUEST, { data });

export const loadPendingMigrationsSuccess = (data: IMigrationOfPointsItem[]) =>
  action(MigrationOfPointsTypes.LOAD_PENDING_MIGRATIONS_SUCCESS, { data });

export const loadPendingMigrationsFailure = () =>
  action(MigrationOfPointsTypes.LOAD_PENDING_MIGRATIONS_FAILURE);

export const loadPendingMigrationDetailRequest = (idSolicitacao: number) =>
  action(MigrationOfPointsTypes.LOAD_PENDING_MIGRATION_DETAIL_REQUEST, { idSolicitacao });

export const loadPendingMigrationDetailSuccess = (data: IMigrationPointsDetail[]) =>
  action(MigrationOfPointsTypes.LOAD_PENDING_MIGRATION_DETAIL_SUCCESS, { data });

export const loadPendingMigrationDetailFailure = () =>
  action(MigrationOfPointsTypes.LOAD_PENDING_MIGRATION_DETAIL_FAILURE);

export const loadPendingMigrationCsvRequest = (idSolicitacao: number) =>
  action(MigrationOfPointsTypes.LOAD_PENDING_MIGRATION_CSV_REQUEST, { idSolicitacao });

export const loadPendingMigrationCsvSuccess = (data: string) =>
  action(MigrationOfPointsTypes.LOAD_PENDING_MIGRATION_CSV_SUCCESS, { data });

export const loadPendingMigrationCsvFailure = () =>
  action(MigrationOfPointsTypes.LOAD_PENDING_MIGRATION_CSV_FAILURE);

export const loadPendingMigrationCsvReset = () =>
  action(MigrationOfPointsTypes.LOAD_PENDING_MIGRATION_CSV_RESET);

export const moderateMigrationRequest = (data: IMigrationPointsModeration) =>
  action(MigrationOfPointsTypes.MODERATE_MIGRATION_REQUEST, { data });

export const moderateMigrationSuccess = (data: string) =>
  action(MigrationOfPointsTypes.MODERATE_MIGRATION_SUCCESS, { data });

export const moderateMigrationFailure = () =>
  action(MigrationOfPointsTypes.MODERATE_MIGRATION_FAILURE);

export const loadMigrationReportRequest = (data: IMigrationOfPointsParams) =>
  action(MigrationOfPointsTypes.LOAD_MIGRATION_REPORT_REQUEST, { data });

export const loadMigrationReportSuccess = (data: string) =>
  action(MigrationOfPointsTypes.LOAD_MIGRATION_REPORT_SUCCESS, { data });

export const loadMigrationReportFailure = () =>
  action(MigrationOfPointsTypes.LOAD_MIGRATION_REPORT_FAILURE);

export const loadMigrationReportReset = () =>
  action(MigrationOfPointsTypes.LOAD_MIGRATION_REPORT_RESET);
