import React, { useEffect } from 'react';
import Wrap from 'components/Wrap/Wrap';
import { Breadcrumbs, Typography, Link, Button, Box, IconButton, Badge } from '@material-ui/core';
import { ApplicationState } from 'store';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as PopupActions from 'store/popup/list/actions';
import { IPopupListItem } from 'store/popup/list';
import { TABLE_STYLE } from 'common/Constants';
import Utils from 'common/Utils';
import EditIcon from '@material-ui/icons/Edit';
import { RouteComponentProps } from 'react-router-dom';
import GenericTable from 'components/GenericTable/GenericTable';

interface IProps extends RouteComponentProps {
  popups: IPopupListItem[];
  loading: boolean;
  loadRequest: () => void;
}

const PopupList: React.FC<IProps> = ({ popups, loadRequest, loading, history }) => {
  useEffect(() => {
    loadRequest();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnEdit = (id: number) => history.push(`/popups/editar/${id}`);
  const handleOnCreate = () => history.push(`/popups/cadastrar`);

  return (
    <>
      <Wrap>
        <Breadcrumbs>
          <Link color="inherit" onClick={() => history.push('/')}>
            Home
          </Link>
          <Typography color="textPrimary">Gestão de Popups</Typography>
        </Breadcrumbs>
      </Wrap>

      <Box m="0 0 1.5em 0">
        <Button variant="contained" color="primary" onClick={handleOnCreate}>
          Criar Popup
        </Button>
      </Box>

      <GenericTable
        title="Popups"
        isLoading={loading}
        data={popups}
        options={{
          paging: true,
          search: true,
          pageSize: 10,
          sorting: false,
        }}
        columns={[
          {
            searchable: true,
            title: 'Nome',
            field: 'nome',
            cellStyle: { ...TABLE_STYLE, width: '30%' },
          },
          {
            searchable: true,
            title: 'Tipo',
            field: 'nomeTipo',
            cellStyle: { ...TABLE_STYLE, width: '15%' },
          },
          {
            searchable: true,
            title: 'Tela',
            field: 'nomeTela',
            cellStyle: { ...TABLE_STYLE, width: '15%' },
          },
          {
            searchable: false,
            title: 'Vigência De',
            field: 'vigenciaDe',
            cellStyle: { ...TABLE_STYLE, width: '15%' },
          },
          {
            searchable: false,
            title: 'Até',
            field: 'vigenciaAte',
            cellStyle: { ...TABLE_STYLE, width: '15%' },
          },
          {
            searchable: false,
            title: 'Status',
            field: 'ativo',
            cellStyle: { ...TABLE_STYLE, width: '15%' },
            render: ({ ativo }) => (ativo ? 'Ativo' : 'Inativo'),
          },
          {
            searchable: false,
            title: 'Ações',
            cellStyle: {
              width: '10%',
              ...TABLE_STYLE,
            },
            render: ({ id }) => (
              <IconButton color="primary" onClick={() => handleOnEdit(id)}>
                <EditIcon fontSize="small" />
              </IconButton>
            ),
          },
        ]}
      />
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  popups: state.popupList.data,
  loading: state.popupList.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(PopupActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PopupList);
