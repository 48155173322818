import React, { useState, useEffect } from 'react';
import Wrap from 'components/Wrap/Wrap';
import { RouteComponentProps } from 'react-router-dom';
import { Breadcrumbs, Typography, Link, Box } from '@material-ui/core';
import Tab, { ITab } from 'components/Tab/Tab';
import { connect } from 'react-redux';
import GeneralData from 'pages/Benefit/generalData/GeneralData';
import { ApplicationState } from 'store';
import { Dispatch, bindActionCreators } from 'redux';
import * as BenefitEditActions from 'store/benefit/edit/actions';
import { IBenefitEdit } from 'store/benefit/edit';
import Structure from 'pages/Benefit/Structure/Structure';
import Banners from 'pages/Benefit/Banners/Banners';
import Description from './Description/Description';
import Simulador from './Simulador/Simulador';

interface IParams {
  id?: string | undefined;
}

interface BenefitEditProps {
  loadBenefit: (id: number) => void;
  resetBenefit: () => void;
  benefit: IBenefitEdit;
  loading: boolean;
}

const BenefitMain: React.FC<BenefitEditProps & RouteComponentProps<IParams>> = ({
  match,
  history,
  loadBenefit,
  resetBenefit,
  benefit,
}) => {
  useEffect(() => {
    if (match.params.id) {
      loadBenefit(+match.params.id);
    }

    return () => resetBenefit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [tabs] = useState<ITab[]>([
    {
      component: <GeneralData />,
      renderLabel: (tab, index) => `${index + 1}. Dados Gerais`,
      disabled: false,
    },
    {
      component: <Description />,
      renderLabel: (tab, index) => `${index + 1}. Descrição`,
      disabled: false,
    },
    {
      component: <Structure />,
      renderLabel: (tab, index) => `${index + 1}. Estruturas`,
      disabled: false,
    },
    {
      component: <Banners />,
      renderLabel: (tab, index) => `${index + 1}. Banners `,
      disabled: false,
    },
    {
      component: <Simulador />,
      renderLabel: (tab, index) => `${index + 1}. Simulador `,
      disabled: false,
    },
  ]);

  return (
    <Box mb="1.5em">
      <Wrap>
        <Breadcrumbs>
          <Link color="inherit" onClick={() => history.push('/')}>
            Home
          </Link>
          <Link color="inherit" onClick={() => history.push('/benefit')}>
            Gestão de Benefícios
          </Link>
          <Typography color="textPrimary">{!!match.params.id ? 'Editar' : 'Cadastrar'}</Typography>
        </Breadcrumbs>
      </Wrap>

      <Tab tabs={tabs} enableNavigationButton={true} disableNextButton={!match.params.id} />
    </Box>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  benefit: state.benefit.data,
  loading: state.benefit.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...BenefitEditActions,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapDispatchToProps)(BenefitMain);
