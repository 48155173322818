import { action } from 'typesafe-actions';
import { TotalErrosIntegracaoBoletosTypes, IRealtimeItem } from './';

export const loadRealtimeTotalErrosIntegracaoBoletosRequest = () =>
  action(TotalErrosIntegracaoBoletosTypes.LOAD_REQUEST);
export const loadRealtimeTotalErrosIntegracaoBoletosSuccess = (
  data: IRealtimeItem[],
  atualizacao: string,
) => action(TotalErrosIntegracaoBoletosTypes.LOAD_SUCCESS, { data, atualizacao });
export const loadRealtimeTotalErrosIntegracaoBoletosFailure = () =>
  action(TotalErrosIntegracaoBoletosTypes.LOAD_FAILURE);
