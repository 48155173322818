import React, { useEffect, useState } from 'react';
import {
  Grid,
  FormControl,
  Box,
  TextField,
  createStyles,
  Button,
  Paper,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from 'store';
import { CuttOffDayRequest } from 'store/reentryConfigStore/sagas';
import * as ReentryConfigActions from 'store/reentryConfigStore/actions';
import { ICuttOffDay } from 'store/reentryConfigStore/index';
import { AppMessages } from 'common/AppMessages.enum';
import Utils from 'common/Utils';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 120,
    },
    margin: {
      margin: theme.spacing(1),
    },
  }),
);

interface ICuttOffDayParams {
  LoadCuttOffDay: () => void;
  cuttOffDayLoading: boolean;
  cuttOffDayData: ICuttOffDay[];
}

const CutOffDateConfig: React.FC<ICuttOffDayParams> = ({ LoadCuttOffDay, cuttOffDayData }) => {
  const classes = useStyle();
  const [cuttOffState, setCuttOffState] = useState({
    numeroDia: 0,
    inicioVigencia: '',
    fimVigencia: '',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorDay, setErrorDay] = useState(false);

  const handleCuttOffDay = async () => {
    const hasErrors = getErrorMessages();

    if (!hasErrors) {
      setLoading(true);
      await CuttOffDayRequest(cuttOffState);
      return setLoading(false);
    }
  };

  const getErrorMessages = (): boolean => {
    const isDateValid =
      Utils.compareDate(cuttOffState.inicioVigencia, cuttOffState.fimVigencia) &&
      cuttOffState.inicioVigencia !== cuttOffState.fimVigencia;
    const isInputRequiredValid = Utils.hasInputError(
      [cuttOffState.fimVigencia, cuttOffState.inicioVigencia],
      true,
    );

    const isNotValidNumeroDia =
      isNaN(cuttOffState.numeroDia) || cuttOffState.numeroDia <= 0 || cuttOffState.numeroDia > 31;

    setErrorDay(isNotValidNumeroDia);

    setErrorMessage(
      isDateValid
        ? AppMessages.DATE_START_BIGGERTHAN_END
        : isInputRequiredValid
        ? AppMessages.FORM_FIELDS_REQUIRED
        : isNotValidNumeroDia
        ? 'Inclua um dia de corte válido'
        : '',
    );

    return [!isDateValid, !isInputRequiredValid, !isNotValidNumeroDia].some(e => !e);
  };

  useEffect(() => {
    LoadCuttOffDay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!cuttOffDayData.length) return setCuttOffState({ ...cuttOffDayData[0] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!cuttOffDayData.length]);

  return (
    <Paper elevation={2}>
      <Box m="1.5em">
        <Grid container spacing={3} alignItems="flex-end">
          <Grid item xs={12}>
            <Typography variant="h5" component="h2">
              Cadastro Data de Corte dos Reingressos
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth className={classes.formControl}>
              <TextField
                error={errorDay && !!errorMessage}
                required
                value={cuttOffState.numeroDia > 0 ? cuttOffState.numeroDia : ''}
                InputLabelProps={{ shrink: cuttOffState.numeroDia > 0 ?? false }}
                onChange={(e: React.ChangeEvent<{ value: any }>) => {
                  if (e.target.value.length <= 2) {
                    setCuttOffState({
                      ...cuttOffState,
                      numeroDia: parseInt(e.target.value),
                    });
                  }
                }}
                label="Dia de Corte"
                variant="outlined"
                type="number"
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth className={classes.formControl}>
              <TextField
                error={Utils.hasInputError(cuttOffState.inicioVigencia, true) && !!errorMessage}
                required
                type="date"
                id="startDate"
                name="vigenciaDe"
                value={cuttOffState.inicioVigencia.substring(0, 10)}
                onChange={(e: React.ChangeEvent<{ value: any }>) =>
                  setCuttOffState({ ...cuttOffState, inicioVigencia: e.target.value })
                }
                label="Início de Vigência"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth className={classes.formControl}>
              <TextField
                error={Utils.hasInputError(cuttOffState.fimVigencia, true) && !!errorMessage}
                required
                type="date"
                id="endDate"
                name="vigenciaAte"
                value={cuttOffState.fimVigencia.substring(0, 10)}
                onChange={(e: React.ChangeEvent<{ value: any }>) =>
                  setCuttOffState({ ...cuttOffState, fimVigencia: e.target.value })
                }
                label="Fim da Vigência"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              disabled={cuttOffState.numeroDia <= 0 || cuttOffState.numeroDia > 31 || loading}
              onClick={handleCuttOffDay}
              color="secondary"
              size="large"
            >
              {loading ? 'Carregando..' : !!cuttOffDayData.length ? 'Atualizar' : 'Cadastrar'}
            </Button>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={3} className={classes.formControl}>
            <Box fontSize={14} color="red">
              {errorMessage}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  promotionsList: state.reentryConfigStore.promotionsList.data,
  cuttOffDayData: state.reentryConfigStore.cuttOffDay.data,
  cuttOffDayLoading: state.reentryConfigStore.cuttOffDay.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(ReentryConfigActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CutOffDateConfig);
