import { action } from 'typesafe-actions';
import { RegulationTypes, IRegulationItem, IRegulationUpdateRequest } from '.';
import { IDefaultPatternRequest } from 'common/Constants';

export const loadRegulationItems = (id: number) => action(RegulationTypes.LOAD_REQUEST, { id });
export const loadRegulationItemsSuccess = (data: IRegulationItem[]) =>
  action(RegulationTypes.LOAD_SUCCESS, { data });
export const loadRegulationItemsFailure = () => action(RegulationTypes.LOAD_FAILURE);

export const loadRegulationItemContent = (campaignId: number, campanhaRegulamentoId: number) =>
  action(RegulationTypes.LOAD_HTML_REQUEST, { campaignId, campanhaRegulamentoId });
export const loadRegulationItemContentSuccess = (data: IRegulationItem[]) =>
  action(RegulationTypes.LOAD_HTML_SUCCESS, { data });

export const deleteRegulationItemContent = (campaignId: number, regulationId: number) =>
  action(RegulationTypes.DELETE_HTML_REQUEST, { campaignId, regulationId });
export const deleteRegulationItemContentSuccess = data =>
  action(RegulationTypes.DELETE_HTML_SUCCESS, { data });

export const updateRegulationsContent = (campaignId: number, body: IRegulationUpdateRequest) =>
  action(RegulationTypes.UPDATE_REQUEST, { campaignId, body });
export const updateRegulationsContentSuccess = data =>
  action(RegulationTypes.UPDATE_SUCCESS, { data });

export const loadRegulationPattern = (campaignId: number) =>
  action(RegulationTypes.LOAD_PATTERN_REQUEST, { campaignId });
export const loadRegulationPatternSuccess = data =>
  action(RegulationTypes.LOAD_PATTERN_SUCCESS, { data });

export const uploadRegulationPattern = (campaignId: number, data: FormData) =>
  action(RegulationTypes.UPLOAD_PATTERN_REQUEST, { campaignId, data });
export const uploadRegulationPatternSuccess = data =>
  action(RegulationTypes.UPLOAD_PATTERN_SUCCESS, { data });

export const updateRegulationPattern = (campaignId: number, request: IDefaultPatternRequest) =>
  action(RegulationTypes.UPDATE_PATTERN_REQUEST, { campaignId, request });
export const updateRegulationPatternSuccess = data =>
  action(RegulationTypes.UPDATE_PATTERN_SUCCESS, { data });
