import { Reducer } from 'redux';

export enum DistribuitorTypes {
  LOAD_REQUEST = '@distribuitors/LOAD_REQUEST',
  LOAD_BY_REGION_REQUEST = '@distribuitors/LOAD_BY_REGION_REQUEST',
  LOAD_SUCCESS = '@distribuitors/LOAD_SUCCESS',
  LOAD_FAILURE = '@distribuitors/LOAD_FAILURE',
  RESET = '@distribuitors/RESET',
}

export interface IDistribuitor {
  cnpj: string;
  razaoSocial: string;
}

export interface IDistribuitorState {
  readonly data: IDistribuitor[];
  readonly loading: boolean;
  readonly error: boolean;
}

const INITIAL_STATE: IDistribuitorState = {
  data: [],
  loading: false,
  error: false,
};

const reducer: Reducer<IDistribuitorState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DistribuitorTypes.LOAD_BY_REGION_REQUEST:
    case DistribuitorTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case DistribuitorTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data,
      };
    case DistribuitorTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true, data: { ...INITIAL_STATE.data } };
    case DistribuitorTypes.RESET:
      return { ...state, loading: false, error: false, data: [] };
    default:
      return state;
  }
};

export default reducer;
