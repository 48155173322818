import { action } from 'typesafe-actions';
import { TrackImportFileTypes } from '.';

export const loadTrackImportFile = (id: number) =>
  action(TrackImportFileTypes.LOAD_REQUEST, { id });
export const loadUploadFileSuccess = data => action(TrackImportFileTypes.LOAD_SUCCESS, { data });
export const loadUploadFileFailure = () => action(TrackImportFileTypes.LOAD_FAILURE);

export const loadFileTrackImportDetails = (id: number) =>
  action(TrackImportFileTypes.LOAD_DETAILS_REQUEST, { id });
export const loadFileTrackImportDetailsSuccess = data =>
  action(TrackImportFileTypes.LOAD_DETAILS_SUCCESS, { data });
export const loadFileTrackImportDetailsFailure = () =>
  action(TrackImportFileTypes.LOAD_DETAILS_FAILURE);

export const uploadTrackImportCsv = (file: FormData, id: number, transferCampaign: boolean) =>
  action(TrackImportFileTypes.UPLOAD_REQUEST, { file, id, transferCampaign });
export const trackImportCsvSuccess = data => action(TrackImportFileTypes.UPLOAD_SUCCESS, { data });
export const trackImportCsvFailure = () => action(TrackImportFileTypes.UPLOAD_FAILURE);

export const deleteTrackImportCsv = (deleteUrl: string) =>
  action(TrackImportFileTypes.DELETE_REQUEST, { deleteUrl });
export const deleteTrackImportCsvSuccess = data =>
  action(TrackImportFileTypes.DELETE_SUCCESS, { data });
export const deleteTrackImportCsvFailure = () => action(TrackImportFileTypes.DELETE_FAILURE);
