import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import { deepPurple } from '@material-ui/core/colors';
import Api from '../../../common/Api';
import {
  Button,
  Paper,
  Collapse,
  Box,
  Grid,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import SlideImage from './../../SlideImage/SlideImage';

export interface ListEanSku {
  id: number;
  nome: string;
  codigo: string;
  imagemUrl: string;
}

export interface ListEanBonificacaoUfSku {
  id: number;
  numeroSku: string;
  eanSku: ListEanSku;
}

export interface ListUfDados {
  id: number;
  nome: string;
  sigla: string;
}

export interface ListUF {
  id: number;
  uf: string;
  ufId: number;
  _Uf: ListUfDados;
  eanBonificacaoUfSku: Array<ListEanBonificacaoUfSku>;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },

    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },

    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },

    expandOpen: {
      transform: 'rotate(180deg)',
    },

    avatar: {
      backgroundColor: deepPurple[500],
    },

    paper: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(1),
    },

    boxBtnVer: {
      marginTop: '-10px',
    },
  }),
);

const TrackUfList: React.FC<any> = ({ campanhaFaixaAtingimentoId }) => {
  const classes = useStyle();
  const [state, setState] = useState<Array<ListUF>>([]);
  const [checked, setChecked] = React.useState(false);
  const [textVerUfs, setTextVerUfs] = React.useState('Ver mais');

  async function getBonificacaoUfEanSku() {
    var result = await Api.get(
      `/campanhaEan/ListaUfProdutos?CampanhaFaixaAtingimentoId=${campanhaFaixaAtingimentoId}`,
    );
    if (result.status === 200) setState(result.data);
  }

  useEffect(() => {
    getBonificacaoUfEanSku();
  }, []);

  useEffect(() => {
    setTextVerUfs(checked ? 'Ver menos' : 'Ver mais');
  }, [checked]);

  useEffect(() => {
    getBonificacaoUfEanSku();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campanhaFaixaAtingimentoId]);

  const handleChange = () => {
    setChecked(prev => !prev);
  };

  return state.length > 0 ? (
    <>
      <Collapse in={checked} collapsedSize={470}>
        <Paper elevation={4} className={classes.paper}>
          <Grid container spacing={1}>
            {state.map((item: ListUF) => {
              return (
                <Grid item xs={12} sm={12} md={6} lg={3} xl={2}>
                  <Box mb={1}>
                    <Card className={classes.root}>
                      <CardHeader
                        avatar={
                          <Avatar aria-label="UF" className={classes.avatar}>
                            {item._Uf.sigla}
                          </Avatar>
                        }
                        title={item._Uf.nome}
                      />
                      <CardContent>
                        <SlideImage data={item.eanBonificacaoUfSku} autoplay={true} />
                      </CardContent>
                    </Card>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Paper>
      </Collapse>
      <Box textAlign="center" className={classes.boxBtnVer}>
        <Button color="primary" variant="contained" onClick={handleChange}>
          {textVerUfs}
        </Button>
      </Box>
    </>
  ) : null;
};

export default TrackUfList;
