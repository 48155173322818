import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import {
  loadPendingChainingNetSuccess,
  loadPendingChainingNetFailure,
  approveChainingNetFailure,
  approveChainingNetSuccess,
  loadChainingNetDetailSuccess,
  loadChainingNetDetailFailure,
  loadStatusFailure,
  loadStatusSuccess,
  chainingNetReportSuccess,
  chainingNetReportFailure,
} from './actions';

export function* loadStatusRequest() {
  try {
    const { data } = yield call(API.get, 'solicitacao/status');

    yield put(loadStatusSuccess(data));
  } catch (error) {
    yield put(loadStatusFailure());
  }
}

export function* loadPendingChainingNetRequest(action) {
  try {
    const { data } = yield call(API.post, 'solicitacao/list', action.payload.data);

    yield put(loadPendingChainingNetSuccess(data));
  } catch (error) {
    yield put(loadPendingChainingNetFailure());
  }
}

export function* loadChainingNetDetailRequest(action) {
  try {
    const { data } = yield call(
      API.get,
      `solicitacao/list/details/${action.payload.idSolicitacao}`,
    );

    yield put(loadChainingNetDetailSuccess(data));
  } catch (error) {
    yield put(loadChainingNetDetailFailure());
  }
}

export function* approveChainingNetRequest(action) {
  try {
    const { data } = yield call(API.post, 'solicitacao/AprovaReprova', action.payload.data);

    yield put(approveChainingNetSuccess(data));
  } catch (error) {
    yield put(approveChainingNetFailure(false));
  }
}

export function* chainingNetReportRequest(action) {
  try {
    const { data } = yield call(API.post, 'solicitacao/csv', action.payload.data);

    yield put(chainingNetReportSuccess(data));
  } catch (error) {
    yield put(chainingNetReportFailure());
  }
}
