import React from 'react';
import { Button } from '@material-ui/core';

export default function DownloadClientSingleBankSlip() {
  const handleDownloadPdvs = () => {
    const downloadLink = document.createElement('a');
    downloadLink.href = '/modelo/ModeloBoletoAvulso.xlsx';
    downloadLink.download = 'ModeloBoletoAvulso.xlsx';
    document.body.appendChild(downloadLink);
    downloadLink.click();
  };

  return (
    <Button color="primary" onClick={handleDownloadPdvs}>
      Clique para fazer o download do modelo
    </Button>
  );
}
