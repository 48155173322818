import React, { useEffect, useState } from 'react';
import { ApplicationState } from 'store';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import GetApp from '@material-ui/icons/GetApp';
import { IPaymentMethodsItem, IPaymentMethodsParams } from 'store/dashboard';
import * as PaymentMethodsActions from 'store/dashboard/actions';
import {
  Box,
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  Paper,
  Select,
  Theme,
  Button,
  Grid,
} from '@material-ui/core';

interface IProps extends RouteComponentProps {
  paymentReceivablesMethods: IPaymentMethodsItem[];
  loading: boolean;
  loadPaymentReceivablesMethodRequest: () => void;
  generatePaymentReceivablesMethodReportRequest: (data: IPaymentMethodsParams) => void;
  generatePaymentReceivablesMethodReportResetParams: () => void;
  loadingReport: boolean;
  report: string;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    spacing: {
      marginBottom: theme.spacing(2),
    },
  }),
);

const MembershipConectaPay: React.FC<IProps> = ({
  paymentReceivablesMethods,
  loadPaymentReceivablesMethodRequest,
  loading,
  loadingReport,
  generatePaymentReceivablesMethodReportRequest,
  report,
  generatePaymentReceivablesMethodReportResetParams,
}) => {
  const classes = useStyle();
  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  const [paymentMethod, setPaymentMethod] = useState('0');

  useEffect(() => {
    setLabelWidth(inputLabel.current!.offsetWidth);

    loadPaymentReceivablesMethodRequest();
  }, []);

  useEffect(() => {
    if (report !== undefined && report !== '') {
      var buffer = new ArrayBuffer(3);
      var dataView = new DataView(buffer);
      dataView.setUint8(0, 0xfe);
      dataView.setUint8(1, 0xbb);
      dataView.setUint8(2, 0xbf);

      var csvString = report;
      var universalBOM = '\uFEFF';
      var a = window.document.createElement('a');
      a.setAttribute(
        'href',
        'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + csvString),
      );

      let dateString = new Date().toLocaleDateString('pt-BR');
      let dateReplace = dateString.replace('/', '').replace('/', '');
      a.setAttribute('download', `RelatorioDeMetodosDePagamento${dateReplace}_SouzaCruz.csv`);
      window.document.body.appendChild(a);
      a.click();

      generatePaymentReceivablesMethodReportResetParams();
    }
  }, [report]);

  const handleGenerateReport = () => {
    let tipoPagto = parseInt(paymentMethod);

    let data = {
      idTipoPgto: tipoPagto,
      pagination: {
        limit: 0,
        offset: 0,
      },
    };

    generatePaymentReceivablesMethodReportRequest(data);
  };

  return (
    <Paper>
      <Box p="1.5em" className={classes.formControl}>
        <Box fontSize="h5.fontSize" className={classes.spacing}>
          Relatório de métodos de pagamento
        </Box>
        <Box width={4 / 12} className={classes.spacing}>
          <FormControl fullWidth variant="outlined">
            <InputLabel ref={inputLabel} htmlFor="paymentMethod">
              Selecione tipo de pagamento
            </InputLabel>
            <Select
              required
              native
              value={paymentMethod}
              labelWidth={labelWidth}
              onChange={(e: React.ChangeEvent<{ value: any }>) => setPaymentMethod(e.target.value)}
              inputProps={{
                name: 'paymentMethod',
                id: 'paymentMethod',
              }}
            >
              <option value="0">Todos</option>

              {paymentReceivablesMethods.map(method => (
                <option key={method.id} value={method.id}>
                  {method.nome}
                </option>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Grid container>
          <Grid item md={8} className={classes.formControl}>
            <Button
              onClick={() => handleGenerateReport()}
              variant="contained"
              color="default"
              disabled={loading || loadingReport ? true : false}
            >
              Gerar relatório
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  paymentReceivablesMethods: state.paymentReceivablesMethod.data,
  loading: state.paymentReceivablesMethod.loading,
  loadingReport: state.paymentReceivablesMethod.loadingReport,
  report: state.paymentReceivablesMethod.report,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(PaymentMethodsActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MembershipConectaPay);
