import React, { useEffect } from 'react';
import Wrap from 'components/Wrap/Wrap';
import { Breadcrumbs, Typography, Link, Button, Box, IconButton } from '@material-ui/core';
import { ApplicationState } from 'store';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as BenefitsListActions from 'store/benefit/list/actions';
import { IBenefitListItem } from 'store/benefit/list';
import { TABLE_STYLE } from 'common/Constants';
import EditIcon from '@material-ui/icons/Edit';
import { RouteComponentProps } from 'react-router-dom';
import GenericTable from 'components/GenericTable/GenericTable';
import { CloudDownload } from '@material-ui/icons';

interface IProps extends RouteComponentProps {
  benefitList: IBenefitListItem[];
  loading: boolean;
  loadBenefitList: () => void;
}

const BenefitList: React.FC<IProps> = ({ benefitList, loadBenefitList, loading, history }) => {
  useEffect(() => {
    loadBenefitList();
  }, []);

  const handleOnEdit = (id: number) => history.push(`/benefit/edit/${id}`);
  const handleOnCreate = () => history.push(`/benefit/create`);

  return (
    <>
      <Wrap>
        <Breadcrumbs>
          <Link color="inherit" onClick={() => history.push('/benefit')}>
            Home
          </Link>
          <Typography color="textPrimary">Gestão de Benefícios</Typography>
        </Breadcrumbs>
      </Wrap>

      <Box m="0 0 1.5em 0">
        <Button variant="contained" color="primary" onClick={handleOnCreate}>
          Criar Benefício
        </Button>
      </Box>

      <GenericTable
        title="Arquivos"
        isLoading={loading}
        data={benefitList}
        options={{
          paging: true,
          search: true,
          pageSize: 10,
          sorting: false,
        }}
        columns={[
          {
            searchable: true,
            title: 'Ordem Exibição',
            field: 'ordem',
            cellStyle: { ...TABLE_STYLE, width: '10%' },
          },
          {
            searchable: true,
            title: 'Nome',
            field: 'nome',
            cellStyle: { ...TABLE_STYLE, width: '20%' },
          },
          {
            searchable: false,
            title: 'Vigência De',
            field: 'vigenciaDe',
            cellStyle: { ...TABLE_STYLE, width: '15%' },
          },
          {
            searchable: false,
            title: 'Até',
            field: 'vigenciaAte',
            cellStyle: { ...TABLE_STYLE, width: '15%' },
          },
          {
            searchable: false,
            title: 'Status',
            render: rowData => {
              return `${rowData.ativo ? 'Ativo' : 'Inativo'}`;
            },
            cellStyle: { ...TABLE_STYLE, width: '15%' },
          },
          {
            searchable: false,
            title: 'Ações',
            cellStyle: {
              width: '10%',
              ...TABLE_STYLE,
            },
            render: ({ id }) => (
              <>
                <IconButton color="primary" onClick={() => handleOnEdit(id)}>
                  <EditIcon fontSize="small" />
                </IconButton>
              </>
            ),
          },
        ]}
      />
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  benefitList: state.benefitList.data,
  loading: state.benefit.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(BenefitsListActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BenefitList);
