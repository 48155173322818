import { Box, Button, createStyles, Grid, makeStyles, Paper, Theme } from '@material-ui/core';
import Api from 'common/Api';
import {
  ElegiblesType,
  IFilterItemRequest,
  MODULE_TYPE,
  STRUCTURE_PULICTARGET,
} from 'common/Constants';
import Utils from 'common/Utils';
import ModalConfirmation, {
  IModalConfirmationState,
} from 'components/ModalConfirmation/ModalConfirmation';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from 'store';
import * as BenefitActions from 'store/benefit/edit/actions';
import { ICluster } from 'store/clusters';
import * as ClustersActions from 'store/clusters/actions';
import { IMailing } from 'store/mailing';
import * as MailingActions from 'store/mailing/actions';
import { IRegion } from 'store/regions';
import * as RegionsActions from 'store/regions/actions';
import { ISegment } from 'store/segments';
import * as SegmentsActions from 'store/segments/actions';
import ElegiblesFilterItem from './ElegiblesFilterItem';

interface ElegiblesProps {
  loadRegions(): void;
  loadClusters(): void;
  loadSegments(): void;
  loadBenefitFilters(benefitId: number, moduleType: STRUCTURE_PULICTARGET): void;
  applyBenefitFilter({ id: number, filter: IBenefitFilterRequest }): void;
  deleteMailing(deleteUrl: string): void;
  regions: IRegion[];
  clusters: ICluster[];
  segments: ISegment[];
  loadingRegions: boolean;
  loadingClusters: boolean;
  loadingSegments: boolean;
  loading: boolean;
  campaignFilters: any;
  benefitId?: string | undefined;
  publicTarget: STRUCTURE_PULICTARGET;
  isFilterCompleted: boolean;
  resetCampaignFilters(): void;
  setCampaignFilterCompleted(): void;
  filterApplied: number;
  filterParticipantCount: number;
  mailing?: IMailing[] | undefined;
  callback?: any;
}

export interface IElegiblesState {
  selectAllRegion: boolean;
  selectAllCluster: boolean;
  selectAllSegment: boolean;
  regions: IFilterItemRequest[];
  segments: IFilterItemRequest[];
  cluster: IFilterItemRequest[];
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginBottom: theme.spacing(1),
    },
    fullWidth: {
      width: '100%',
    },
    spacing: {
      marginBottom: theme.spacing(2),
    },
    listBox: {
      heigth: '260px',
      overflow: 'auto',
      minHeight: '260px',
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& > button': {
        marginLeft: theme.spacing(2),
        marginRight: '0 !important',
      },
    },
  }),
);

const ElegiblesBenefit: React.FC<ElegiblesProps> = ({
  regions,
  clusters,
  segments,
  loadingRegions,
  loadingClusters,
  loadingSegments,
  loadRegions,
  loadClusters,
  loadSegments,
  campaignFilters,
  benefitId,
  applyBenefitFilter,
  publicTarget,
  resetCampaignFilters,
  filterApplied,
  loading,
}) => {
  const classes = useStyle();

  const [regionsState, setRegionsState] = React.useState<IFilterItemRequest[]>([]);
  const [clustersState, setClustersState] = React.useState<IFilterItemRequest[]>([]);
  const [segmentsState, setSegmentsState] = React.useState<IFilterItemRequest[]>([]);
  const location = useLocation().pathname;
  const [moduleType] = useState(MODULE_TYPE.BENEFIT);
  const [participantCount, setParticipantCount] = useState('');
  const [modalConfirmation, setModalConfirmation] = useState<IModalConfirmationState>({
    open: false,
    onConfirm: null,
  });

  const isApplyDisabled = () => {
    return (
      regionsState.filter(e => e.selected).length === 0 ||
      clustersState.filter(e => e.selected).length === 0 ||
      segmentsState.filter(e => e.selected).length === 0
    );
  };

  const handleClearFilters = () => {
    resetCampaignFilters();

    setRegionsState([
      ...Utils.toFilterItemObject<IRegion>(regions, 'id', 'nome', ElegiblesType.REGION),
    ]);

    setClustersState([
      ...Utils.toFilterItemObject<ICluster>(clusters, 'id', 'nome', ElegiblesType.CLUSTER),
    ]);

    setSegmentsState([
      ...Utils.toFilterItemObject<ISegment>(segments, 'id', 'nome', ElegiblesType.SEGMENT),
    ]);

    setParticipantCount('');

    handleApplyFilter(true);
  };

  const handlePostSegments = async () => {
    try {
      const ufs = regionsState
        .filter(e => {
          return e.selected;
        })
        .map(e => {
          return e.id;
        });

      const clusters = clustersState
        .filter(e => {
          return e.selected;
        })
        .map(e => {
          return e.id;
        });

      const atividades = segmentsState
        .filter(e => {
          return e.selected;
        })
        .map(e => {
          return e.id;
        });

      if (ufs.length > 0 && clusters.length > 0 && atividades.length > 0) {
        const { data } = await Api.post(`/shared/contagem/filtro`, {
          ufs,
          clusters,
          atividades,
        });
        if (data) {
          setParticipantCount(
            `Os filtros aplicados resultaram na seleção de <strong>${data}</strong> participantes`,
          );
        }
      } else {
        setParticipantCount('');
      }
    } catch (error) {
      setParticipantCount('');
    }
  };

  const handleApplyFilter = (clear?: boolean) => {
    const ufs = clear
      ? []
      : regionsState
          .filter(e => {
            return e.selected;
          })
          .map(e => {
            return e.id;
          });

    const clusters = clear
      ? []
      : clustersState
          .filter(e => {
            return e.selected;
          })
          .map(e => {
            return e.id;
          });

    const atividades = clear
      ? []
      : segmentsState
          .filter(e => {
            return e.selected;
          })
          .map(e => {
            return e.id;
          });
    if (moduleType === MODULE_TYPE.BENEFIT) {
      applyBenefitFilter({
        id: benefitId,
        filter: {
          elegivelFiltro: publicTarget,
          ufs,
          clusters,
          atividades,
        },
      });
    }
  };

  useEffect(() => {
    setRegionsState([
      ...Utils.toFilterItemObjectSelecteItems<IRegion>(
        regions,
        'id',
        'nome',
        ElegiblesType.REGION,
        campaignFilters.ufs,
      ),
    ]);
  }, [regions, campaignFilters]);

  useEffect(() => {
    setClustersState([
      ...Utils.toFilterItemObjectSelecteItems<ICluster>(
        clusters,
        'id',
        'nome',
        ElegiblesType.CLUSTER,
        campaignFilters.clusters,
      ),
    ]);
  }, [clusters, campaignFilters]);

  useEffect(() => {
    setSegmentsState([
      ...Utils.toFilterItemObjectSelecteItems<ISegment>(
        segments,
        'id',
        'nome',
        ElegiblesType.SEGMENT,
        campaignFilters.atividades,
      ),
    ]);
  }, [segments, campaignFilters]);

  useEffect(() => {
    loadRegions();
    loadClusters();
    loadSegments();

    return () => {
      resetCampaignFilters();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handlePostSegments();
  }, [segments]);

  return (
    <>
      <Box className={classes.spacing}>
        Defina os participantes elegíveis à campanha utilizando uma das opções abaixo:
      </Box>

      <Paper elevation={3}>
        <Box p="1.5em">
          <Box fontSize="h4.fontSize">
            {publicTarget === STRUCTURE_PULICTARGET.PDV ? 'PDV' : 'Força de Vendas'}
          </Box>
          <Box fontSize={12} className={classes.spacing}>
            Selecione a região, cluster e segmento:
          </Box>

          <Grid container={true} spacing={3} className={classes.spacing}>
            <ElegiblesFilterItem
              isLoading={loadingRegions}
              itemName="regions"
              searchTitle="Pesquisar regiões"
              setState={setRegionsState}
              state={regionsState}
              forKey="nome"
              onPost={() => handlePostSegments()}
            />

            {publicTarget === STRUCTURE_PULICTARGET.PDV && (
              <>
                <ElegiblesFilterItem
                  isLoading={loadingClusters}
                  itemName="clusters"
                  searchTitle="Pesquisar clusters"
                  setState={setClustersState}
                  state={clustersState}
                  forKey="id"
                  onPost={() => handlePostSegments()}
                />
                <ElegiblesFilterItem
                  isLoading={loadingSegments}
                  itemName="segments"
                  searchTitle="Pesquisar segmentos"
                  setState={setSegmentsState}
                  state={segmentsState}
                  forKey="nome"
                  onPost={() => handlePostSegments()}
                />
              </>
            )}
          </Grid>

          <Box alignItems="center" className={classes.actions}>
            <Box fontSize={12}>
              <div
                dangerouslySetInnerHTML={{
                  __html: participantCount,
                }}
              ></div>
              {}
            </Box>
            <Button
              color="secondary"
              variant="contained"
              disabled={loading || isApplyDisabled()}
              onClick={() => handleApplyFilter(false)}
            >
              {loading ? 'Aguarde...' : 'Salvar'}
            </Button>
            <Button variant="outlined" onClick={handleClearFilters} disabled={loading}>
              {loading ? 'Aguarde...' : 'Limpar filtros'}
            </Button>
          </Box>
        </Box>
      </Paper>

      <ModalConfirmation
        title="Aplicar Filtro"
        content={`Filtro já aplicado para ${
          filterApplied === STRUCTURE_PULICTARGET.PDV ? 'Cliente (PDV)' : 'Força de Vendas'
        }. Deseja mesmo aplicar para ${
          filterApplied === STRUCTURE_PULICTARGET.PDV ? 'Força de Vendas' : 'Cliente (PDV)'
        }?`}
        state={modalConfirmation}
        setState={setModalConfirmation}
      />
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  regions: state.regions.data,
  clusters: state.clusters.data,
  segments: state.segments.data,
  loadingRegions: state.regions.loading,
  loadingClusters: state.clusters.loading,
  loadingSegments: state.segments.loading,
  filterApplied: state.campaignEdit.filterApplied,
  loading: state.campaignEdit.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...RegionsActions,
      ...SegmentsActions,
      ...ClustersActions,
      ...BenefitActions,
      ...MailingActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ElegiblesBenefit);
