import { action } from 'typesafe-actions';
import { TotalUltimosCancelamentosTypes, IRealtimeItem } from './';

export const loadRealtimeTotalCancelamentosUltimosDiasRequest = () =>
  action(TotalUltimosCancelamentosTypes.LOAD_REQUEST);
export const loadRealtimeTotalCancelamentosUltimosDiasSuccess = (
  data: IRealtimeItem[],
  atualizacao: string,
) => action(TotalUltimosCancelamentosTypes.LOAD_SUCCESS, { data, atualizacao });
export const loadRealtimeTotalCancelamentosUltimosDiasFailure = () =>
  action(TotalUltimosCancelamentosTypes.LOAD_FAILURE);
