import React, { useState, useEffect } from 'react';
import Wrap from 'components/Wrap/Wrap';
import GeneralData from './GeneralData/GeneralData';
import Structure from './Structure/Structure';
import Regulation from './Regulation/Regulation';
import Kpi from './Kpi/Kpi';
import Banners from './Banners/Banners';
import Tracks from '../List/Tracks/Tracks';
import { RouteComponentProps } from 'react-router-dom';
import { Breadcrumbs, Typography, Link, Box } from '@material-ui/core';
import Tab, { ITab } from 'components/Tab/Tab';
import * as CampaignEditActions from 'store/campaign/edit/actions';
import { ICampaignEdit } from 'store/campaign/edit';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import { Dispatch, bindActionCreators } from 'redux';
import Communication from './Communication/Communication';
import Premiacao from './Kpi/Premiacao';
import Sku from './Kpi/Sku';

interface IParams {
  id?: string | undefined;
}

interface CampaignEditProps {
  loadCampaign: (id: number) => void;
  loadRegions: () => void;
  resetCampaign: () => void;
  campaign: ICampaignEdit;
  loading: boolean;
}

const CampaignEdit: React.FC<CampaignEditProps & RouteComponentProps<IParams>> = ({
  history,
  match,
  loadCampaign,
  resetCampaign,
  campaign,
}) => {
  useEffect(() => {
    if (match.params.id) {
      loadCampaign(+match.params.id);
    }

    return () => resetCampaign();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isTabsDisabled = (): boolean => !match.params.id && !campaign.id;

  const [tabsRegularCampaign] = useState<ITab[]>([
    {
      component: <GeneralData />,
      renderLabel: (tab, index) => `${index + 1}. Dados Gerais`,
      disabled: false,
    },
    {
      component: <Structure />,
      renderLabel: (tab, index) => `${index + 1}. Estruturas`,
      disabled: isTabsDisabled(),
    },
    {
      component: <Banners />,
      renderLabel: (tab, index) => `${index + 1}. Banners`,
      disabled: isTabsDisabled(),
    },
    {
      component: <Communication />,
      renderLabel: (tab, index) => `${index + 1}. Comunicação Mecânica`,
      disabled: isTabsDisabled(),
    },
    {
      component: <Regulation />,
      renderLabel: (tab, index) => `${index + 1}. Regulamento`,
      disabled: isTabsDisabled(),
    },
    {
      component: <Kpi />,
      renderLabel: (tab, index) => `${index + 1}. KPIs`,
      disabled: isTabsDisabled(),
    },
    {
      component: <Premiacao />,
      renderLabel: (tab, index) => `${index + 1}. Premiação`,
      disabled: isTabsDisabled(),
    },
    {
      component: <Tracks idCampanha={match.params.id} />,
      renderLabel: (tab, index) => `${index + 1}. Bonificações por UF`,
      disabled: isTabsDisabled(),
    },
  ]);

  const [tabsBonus] = useState<ITab[]>([
    {
      component: <GeneralData />,
      renderLabel: (tab, index) => `${index + 1}. Dados Gerais`,
      disabled: false,
    },
    {
      component: <Structure />,
      renderLabel: (tab, index) => `${index + 1}. Estruturas`,
      disabled: isTabsDisabled(),
    },
    {
      component: <Banners />,
      renderLabel: (tab, index) => `${index + 1}. Banners`,
      disabled: isTabsDisabled(),
    },
    {
      component: <Communication />,
      renderLabel: (tab, index) => `${index + 1}. Comunicação Mecânica`,
      disabled: isTabsDisabled(),
    },
    {
      component: <Regulation />,
      renderLabel: (tab, index) => `${index + 1}. Regulamento`,
      disabled: isTabsDisabled(),
    },
    {
      component: <Sku campaignId={campaign.id} />,
      renderLabel: (tab, index) => `${index + 1}. SKU`,
      disabled: isTabsDisabled(),
    },
    {
      component: <Kpi />,
      renderLabel: (tab, index) => `${index + 1}. KPIs`,
      disabled: isTabsDisabled(),
    },
    {
      component: <Premiacao />,
      renderLabel: (tab, index) => `${index + 1}. Premiação`,
      disabled: isTabsDisabled(),
    },
  ]);

  return (
    <Box mb="1.5em">
      <Wrap>
        <Breadcrumbs>
          <Link color="inherit" onClick={() => history.push('/')}>
            Home
          </Link>
          <Link color="inherit" onClick={() => history.push('/campanhas/gestaodecampanhas')}>
            Gestão de Campanhas
          </Link>
          <Typography color="textPrimary">{!!campaign.id ? 'Editar' : 'Cadastrar'}</Typography>
        </Breadcrumbs>
      </Wrap>

      <Tab
        tabs={campaign.campanhaRegular ? tabsRegularCampaign : tabsBonus}
        enableNavigationButton={true}
        disableNextButton={!campaign.id}
      />
    </Box>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  campaign: state.campaignEdit.data,
  loading: state.campaignEdit.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...CampaignEditActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CampaignEdit);
