import { action } from 'typesafe-actions';
import { MailingTypes } from '.';
import { MODULE_TYPE } from 'common/Constants';

export const loadMailing = (id: number, moduleType: MODULE_TYPE) =>
  action(MailingTypes.LOAD_REQUEST, { id, moduleType });
export const loadMailingSuccess = data => action(MailingTypes.LOAD_SUCCESS, { data });
export const loadMailingFailure = () => action(MailingTypes.LOAD_FAILURE);

export const uploadMailing = (
  uploadUrl: string,
  data: FormData,
  callbackAction: any,
  id: number,
  type: number,
) => action(MailingTypes.UPLOAD_REQUEST, { uploadUrl, data, callbackAction, id, type });
export const uploadMailingSuccess = data => action(MailingTypes.UPLOAD_SUCCESS, { data });
export const uploadMailingFailure = () => action(MailingTypes.UPLOAD_FAILURE);

export const deleteMailing = (deleteUrl: string) =>
  action(MailingTypes.DELETE_REQUEST, { deleteUrl });
export const deleteMailingSuccess = data => action(MailingTypes.DELETE_SUCCESS, { data });
export const deleteMailingFailure = () => action(MailingTypes.DELETE_FAILURE);
