import React, { useEffect, useState } from 'react';
import { Paper } from '@material-ui/core';
import * as ParticipantExemptionFileActions from 'store/participantExemption/uploadFile/actions';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import { Dispatch, bindActionCreators } from 'redux';
import DownloadClientExemptionExcel from 'components/DownloadClientBase/DownloadClientExemptionExcel';
import GenericImportTable, {
  ImportGenericTableData,
  IUploadVolume,
  IUploadVolumeDetail,
} from 'components/GenericImportTable/GenericImportTable';

interface UploadReportProps {
  loadParticipantExemptionFile(): void;
  loadFileExemptionDetails(id: number): void;
  uploadParticipantExemptionCsv(file: FormData): void;
  uploadVolume: IUploadVolume;
  uploadVolumeDetail: IUploadVolumeDetail;
}

const ParticipantExemption: React.FC<UploadReportProps> = ({
  loadParticipantExemptionFile,
  loadFileExemptionDetails,
  uploadParticipantExemptionCsv,
  uploadVolume,
  uploadVolumeDetail,
}) => {
  const [dataGenericTable, setDataGenericTable] = useState<ImportGenericTableData>({
    title: 'Histórico de Upload de Abonos',
    responseTable: [],
    responseModal: [],
    loadingModal: true,
    loadingTable: true,
    disabledHandleUpload: false,
  });

  useEffect(() => {
    loadParticipantExemptionFile();
  }, [loadParticipantExemptionFile]);

  useEffect(() => {
    // Array da tabela de upload
    if (!uploadVolume.loading) {
      setDataGenericTable({
        ...dataGenericTable,
        responseTable: uploadVolume.data,
        loadingTable: uploadVolume.loading,
      });
    } else if (uploadVolume.loading) {
      setDataGenericTable({ ...dataGenericTable, loadingTable: uploadVolume.loading });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadVolume.data, uploadVolume.loading]);

  useEffect(() => {
    // Array da tabela de detalhamento do modal
    if (!uploadVolumeDetail.loading) {
      setDataGenericTable({
        ...dataGenericTable,
        responseModal: uploadVolumeDetail.data,
        loadingModal: uploadVolumeDetail.loading,
      });
    } else if (uploadVolumeDetail.loading) {
      // Troca de state loadingModal
      setDataGenericTable({ ...dataGenericTable, loadingModal: uploadVolumeDetail.loading });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadVolumeDetail.data, uploadVolumeDetail.loading]);

  const uploadByFile = file => {
    uploadParticipantExemptionCsv(file);
  };

  return (
    <Paper>
      <GenericImportTable
        props={dataGenericTable}
        exampleUploadFile={() => <DownloadClientExemptionExcel />}
        uploadApi={uploadByFile}
        detailModalApi={loadFileExemptionDetails}
      />
    </Paper>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  uploadVolume: state.storeParticipantExemptionFile.uploadVolume,
  uploadVolumeDetail: state.storeParticipantExemptionFile.uploadVolumeDetail,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(ParticipantExemptionFileActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantExemption);
