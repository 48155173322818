import { AppMessages } from 'common/AppMessages.enum';
import { showToastr } from 'store/toastr/actions';
import { call, put, delay } from 'redux-saga/effects';
import API from 'common/Api';
import {
  loadSuccess,
  loadFailure,
  loadConfiguredKpi as loadConfiguredKpiAction,
  loadConfiguredKpiSuccess,
  loadConfiguredKpiFailure,
  genericRequestSuccess,
  genericRequestFailure,
  uploadKpiFileSuccess,
  loadFdvProfilesSuccess,
} from './actions';
import { loadCampaign } from 'store/campaign/edit/actions';

export function* loadKPIs() {
  try {
    const response = yield call(API.get, `shared/KpiTipos`);
    yield put(loadSuccess(response.data));
  } catch (error) {
    yield put(loadFailure());
    yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
  }
}
export function* loadConfiguredKpi(action) {
  try {
    const response = yield call(API.get, `campanha/${action.payload.id}/kpi/pdv/faturamento`);
    yield put(loadConfiguredKpiSuccess(response.data));
  } catch (error) {
    yield put(loadConfiguredKpiFailure());
    yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
  }
}

export function* saveKpi(action) {
  try {
    const { response } = yield call(
      API.post,
      `campanha/${action.payload.data.id}/kpi/pdv/faturamento`,
      action.payload.data,
    );
    yield put(genericRequestSuccess());
    yield put(showToastr({ message: AppMessages.SUCCESS_SAVE_KPI, type: 'success', open: true }));
    yield put(loadCampaign(action.payload.data.id));
  } catch (error) {
    yield put(genericRequestFailure());
    yield put(showToastr({ message: AppMessages.ERROR_SAVE_GENERIC, type: 'error', open: true }));
  }
}

export function* uploadKpiFileCampaignRegular(action) {
  try {
    const response = yield call(
      API.post,
      `campanha/${action.payload.id}/kpi/${action.payload.mes}/${action.payload.ano}/pdv/faturamento/upload/campanha-regular`,
      action.payload.file,
    );
    yield put(uploadKpiFileSuccess(response.data));
    yield put(
      showToastr({ message: AppMessages.SUCCESS_UPLOAD_GENERIC, type: 'success', open: true }),
    );
    yield put(loadConfiguredKpiAction(action.payload.id));
  } catch (error) {
    yield put(genericRequestFailure());
    yield put(showToastr({ message: AppMessages.ERROR_SAVE_GENERIC, type: 'error', open: true }));
  }
}

export function* uploadKpiFile(action) {
  try {
    const response = yield call(
      API.post,
      `campanha/${action.payload.id}/kpi/${action.payload.mes}/${action.payload.ano}/${action.payload.eanReferencia}/pdv/faturamento/upload`,
      action.payload.file,
    );
    yield put(uploadKpiFileSuccess(response.data));
    yield put(
      showToastr({ message: AppMessages.SUCCESS_UPLOAD_GENERIC, type: 'success', open: true }),
    );
    yield put(loadConfiguredKpiAction(action.payload.id));
  } catch (error) {
    yield put(genericRequestFailure());
    yield put(showToastr({ message: AppMessages.ERROR_SAVE_GENERIC, type: 'error', open: true }));
  }
}

export function* uploadKpiFileSellin(action) {
  try {
    const response = yield call(
      API.post,
      `campanha/${action.payload.id}/kpi/${action.payload.mes}/${action.payload.ano}/${action.payload.eanReferencia}/${action.payload.tipoCampanhaId}/pdv/faturamento/upload/sellin`,
      action.payload.file,
    );
    yield put(uploadKpiFileSuccess(response.data));
    yield put(
      showToastr({ message: AppMessages.SUCCESS_UPLOAD_GENERIC, type: 'success', open: true }),
    );
    yield put(loadConfiguredKpiAction(action.payload.id));
  } catch (error) {
    yield put(genericRequestFailure());
    yield put(showToastr({ message: AppMessages.ERROR_SAVE_GENERIC, type: 'error', open: true }));
  }
}

export function* uploadKpiFileSellinVeo(action) {
  try {
    const response = yield call(
      API.post,
      `campanha/${action.payload.id}/kpi/${action.payload.mes}/${action.payload.ano}/${action.payload.eanReferencia}/${action.payload.tipoCampanhaId}/${action.payload.tipoVeoId}/pdv/faturamento/upload/sellin-veo`,
      action.payload.file,
    );
    yield put(uploadKpiFileSuccess(response.data));
    yield put(
      showToastr({ message: AppMessages.SUCCESS_UPLOAD_GENERIC, type: 'success', open: true }),
    );
    yield put(loadConfiguredKpiAction(action.payload.id));
  } catch (error) {
    yield put(genericRequestFailure());
    yield put(showToastr({ message: AppMessages.ERROR_SAVE_GENERIC, type: 'error', open: true }));
  }
}

export function* uploadKpiFileVeo(action) {
  try {
    const response = yield call(
      API.post,
      `campanha/${action.payload.id}/kpi/${action.payload.mes}/${action.payload.ano}/${action.payload.tipoMetaId}/pdv/faturamento/upload/veo`,
      action.payload.file,
    );
    yield put(uploadKpiFileSuccess(response.data));
    yield put(
      showToastr({ message: AppMessages.SUCCESS_UPLOAD_GENERIC, type: 'success', open: true }),
    );
    yield put(loadConfiguredKpiAction(action.payload.id));
  } catch (error) {
    yield put(genericRequestFailure());
    yield put(showToastr({ message: AppMessages.ERROR_SAVE_GENERIC, type: 'error', open: true }));
  }
}

export function* loadFdvProfiles() {
  try {
    const response = yield call(API.get, '/profile/1');
    yield put(loadFdvProfilesSuccess(response.data));
  } catch (error) {
    yield put(genericRequestFailure());
    yield put(showToastr({ message: AppMessages.ERROR_GENERIC_400, type: 'error', open: true }));
  }
}
