import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import {
  loadCuttOffListSuccess,
  loadCuttOffListFailure,
  updateCuttOffDateSuccess,
  updateCuttOffDateFailure,
  createCuttOffDateSuccess,
  createCuttOffDateFailure,
  loadCuttOffList as loadCuttOffListAction,
  loadHistoricByUpload as loadHistoricByUploadAction,
  loadHistoricFilterPDVList as loadHistoricFilterPDVListAction,
  loadHistoricByUploadSuccess,
  loadHistoricByUploadFailure,
  uploadFileSuccess,
  uploadFileFailure,
  loadHistoricFilterPDVListSuccess,
  loadHistoricFilterPDVListFailure,
  uploadFilterPDVSuccess,
  uploadFilterPDVFailure,
  loadUploadPDVDetailListSuccess,
  loadUploadPDVDetailListFailure,
} from './actions';
import { showToastr } from 'store/toastr/actions';

export function* loadCuttOffList() {
  try {
    const { data } = yield call(API.get, `/participante-pagamento-data-corte/list`);
    yield put(loadCuttOffListSuccess(data));
  } catch (error) {
    yield put(loadCuttOffListFailure());
  }
}

export function* createCuttOffDate(action) {
  try {
    const { data } = yield call(
      API.post,
      `/participante-pagamento-data-corte/create`,
      action.payload.data,
    );
    yield put(createCuttOffDateSuccess(data));
    yield put(loadCuttOffListAction());
  } catch (error) {
    yield put(createCuttOffDateFailure());
  }
}

export function* updateCuttOffDate(action) {
  try {
    const { data } = yield call(
      API.patch,
      `/participante-pagamento-data-corte/update`,
      action.payload.data,
    );
    yield put(updateCuttOffDateSuccess(data));
    yield put(loadCuttOffListAction());
  } catch (error) {
    yield put(updateCuttOffDateFailure());
  }
}

export function* loadHistoricByUpload(action) {
  try {
    const { data } = yield call(
      API.get,
      `/participante-pagamento-data-corte-importacao/all?idDataCorte=${action.payload.idDataCorte}`,
    );
    yield put(loadHistoricByUploadSuccess(data));
  } catch (error) {
    yield put(loadHistoricByUploadFailure());
  }
}

export function* uploadFile(action) {
  try {
    const { data } = yield call(
      API.post,
      `/participante-pagamento-data-corte-importacao/upload?idDataCorte=${action.payload.idDataCorte}`,
      action.payload.file,
    );
    yield put(uploadFileSuccess(data));
    yield put(loadHistoricByUploadAction(action.payload.idDataCorte));
  } catch (error) {
    yield put(uploadFileFailure());
  }
}

export function* loadHistoricFilterPDVList(action) {
  try {
    const { data } = yield call(
      API.get,
      `/participante-pagamento-data-corte/listar-filtro-pdv?dataCorteId=${action.payload.idDataCorte}`,
    );
    yield put(loadHistoricFilterPDVListSuccess(data));
  } catch (error) {
    yield put(loadHistoricFilterPDVListFailure());
  }
}

export function* uploadFilterPDV(action) {
  try {
    const { data } = yield call(
      API.post,
      `/participante-pagamento-data-corte/create-filtro-pdv`,
      action.payload.data,
    );

    yield put(uploadFilterPDVSuccess(data));
    yield put(loadHistoricFilterPDVListAction(action.payload.data.dataCorteId));
    yield put(
      showToastr({
        type: 'success',
        message: 'Filtro gerado com sucesso!',
        open: true,
      }),
    );
  } catch (error) {
    yield put(uploadFilterPDVFailure());
  }
}

export function* loadUploadPDVDetailList(action) {
  try {
    const { data } = yield call(
      API.get,
      `/participante-pagamento-data-corte-importacao/${action.payload.id}/linhas`,
    );
    yield put(loadUploadPDVDetailListSuccess(data));
  } catch (error) {
    yield put(loadUploadPDVDetailListFailure());
  }
}
