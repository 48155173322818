import { action } from 'typesafe-actions';
import {
  ChainingNetworkTypes,
  IChainingNetworkItem,
  IChainingNetworkParams,
  IStatusItem,
  IChainingNetDetailItem,
  IChainingNetApproval,
} from './';

export const loadStatusRequest = () => action(ChainingNetworkTypes.LOAD_STATUS_REQUEST);

export const loadStatusSuccess = (data: IStatusItem[]) =>
  action(ChainingNetworkTypes.LOAD_STATUS_SUCCESS, { data });

export const loadStatusFailure = () => action(ChainingNetworkTypes.LOAD_STATUS_FAILURE);

export const loadPendingChainingNetRequest = (data: IChainingNetworkParams) =>
  action(ChainingNetworkTypes.LOAD_PENDING_CHAINING_NET_REQUEST, { data });

export const loadPendingChainingNetSuccess = (data: IChainingNetworkItem[]) =>
  action(ChainingNetworkTypes.LOAD_PENDING_CHAINING_NET_SUCCESS, { data });

export const loadPendingChainingNetFailure = () =>
  action(ChainingNetworkTypes.LOAD_PENDING_CHAINING_NET_FAILURE);

export const loadChainingNetDetailRequest = (idSolicitacao: number) =>
  action(ChainingNetworkTypes.LOAD_CHAINING_NET_DETAIL_REQUEST, { idSolicitacao });

export const loadChainingNetDetailSuccess = (data: IChainingNetDetailItem) =>
  action(ChainingNetworkTypes.LOAD_CHAINING_NET_DETAIL_SUCCESS, { data });

export const loadChainingNetDetailFailure = () =>
  action(ChainingNetworkTypes.LOAD_CHAINING_NET_DETAIL_FAILURE);

export const approveChainingNetRequest = (data: IChainingNetApproval) =>
  action(ChainingNetworkTypes.APPROVE_CHAINING_NET_REQUEST, { data });

export const approveChainingNetSuccess = (data: undefined) =>
  action(ChainingNetworkTypes.APPROVE_CHAINING_NET_SUCCESS, { data });

export const approveChainingNetFailure = (success: boolean) =>
  action(ChainingNetworkTypes.APPROVE_CHAINING_NET_FAILURE, { success });

export const chainingNetReportRequest = (data: IChainingNetworkParams) =>
  action(ChainingNetworkTypes.CHAINING_NET_REPORT_REQUEST, { data });

export const chainingNetReportSuccess = (data: string) =>
  action(ChainingNetworkTypes.CHAINING_NET_REPORT_SUCCESS, { data });

export const chainingNetReportFailure = () =>
  action(ChainingNetworkTypes.CHAINING_NET_REPORT_FAILURE);

export const chainingNetReportReset = () => action(ChainingNetworkTypes.CHAINING_NET_REPORT_RESET);
