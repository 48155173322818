import React, { useEffect } from 'react';
import { ApplicationState } from 'store';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import GenericTable from 'components/GenericTable/GenericTable';
import { IconButton } from '@material-ui/core';
import GetApp from '@material-ui/icons/GetApp';
import { TABLE_STYLE } from 'common/Constants';
import { ICampaignCountingItem, ICampaignReportParams } from 'store/campaign/report';
import * as CampaignCountingActions from 'store/campaign/report/actions';

interface IProps extends RouteComponentProps {
  regularCampaigns: ICampaignCountingItem[];
  loading: boolean;
  loadRequest: () => void;
  generateReportRequest: (data: ICampaignReportParams) => void;
  generateReportResetParams: () => void;
  loadingReport: boolean;
  report: string;
}

const CampaignCounting: React.FC<IProps> = ({
  regularCampaigns,
  loadRequest,
  loading,
  loadingReport,
  generateReportRequest,
  report,
  generateReportResetParams,
}) => {
  useEffect(() => {
    loadRequest();
  }, []);

  useEffect(() => {
    if (report !== undefined && report !== '') {
      var buffer = new ArrayBuffer(3);
      var dataView = new DataView(buffer);
      dataView.setUint8(0, 0xfe);
      dataView.setUint8(1, 0xbb);
      dataView.setUint8(2, 0xbf);

      var csvString = report;
      var universalBOM = '\uFEFF';
      var a = window.document.createElement('a');
      a.setAttribute(
        'href',
        'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + csvString),
      );

      let dateString = new Date().toLocaleDateString('pt-BR');
      let dateReplace = dateString.replace('/', '').replace('/', '');
      a.setAttribute('download', `RelatorioDeApuraçãoCampanhaRegular${dateReplace}_SouzaCruz.csv`);
      window.document.body.appendChild(a);
      a.click();

      generateReportResetParams();
    }
  }, [report]);

  const generateReport = (campaignId: number, refMonth: number, refYear: number) => {
    let data = {
      campanhaId: campaignId,
      mesReferencia: refMonth,
      anoReferencia: refYear,
    };

    generateReportRequest(data);
  };

  return (
    <div>
      <h1>Apuração campanhas regulares</h1>
      <GenericTable
        title="Campanhas"
        isLoading={loading || loadingReport}
        data={regularCampaigns}
        options={{
          paging: true,
          search: true,
          pageSize: 10,
          sorting: false,
        }}
        columns={[
          {
            searchable: true,
            title: 'Id',
            field: 'id',
            cellStyle: { ...TABLE_STYLE, width: '5%' },
          },
          {
            searchable: true,
            title: 'Nome',
            field: 'nome',
            cellStyle: { ...TABLE_STYLE, width: '30%' },
          },
          {
            searchable: false,
            title: 'Vigência De',
            field: 'vigenciaDe',
            cellStyle: { ...TABLE_STYLE, width: '12%' },
          },
          {
            searchable: false,
            title: 'Vigência Até',
            field: 'vigenciaAte',
            cellStyle: { ...TABLE_STYLE, width: '12%' },
          },
          {
            searchable: false,
            title: 'Mês de Referência',
            field: 'mesReferencia',
            cellStyle: { ...TABLE_STYLE, width: '15%' },
          },
          {
            searchable: false,
            title: 'Ano de Referência',
            field: 'anoReferencia',
            cellStyle: { ...TABLE_STYLE, width: '15%' },
          },
          {
            searchable: false,
            title: 'Aceites',
            field: 'aceites',
            cellStyle: { ...TABLE_STYLE, width: '15%' },
          },
          {
            searchable: false,
            title: 'Gerar relatório',
            cellStyle: {
              width: '10%',
              ...TABLE_STYLE,
            },
            render: ({ id, mesReferencia, anoReferencia }) => (
              <IconButton
                color="primary"
                onClick={() => generateReport(id, mesReferencia, anoReferencia)}
              >
                <GetApp fontSize="small" />
              </IconButton>
            ),
          },
        ]}
      />
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  regularCampaigns: state.campaignCouting.data,
  loading: state.campaignCouting.loading,
  loadingReport: state.campaignCouting.loadingReport,
  report: state.campaignCouting.report,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(CampaignCountingActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CampaignCounting);
