import React from 'react';
import Wrap from 'components/Wrap/Wrap';
import { RouteComponentProps } from 'react-router-dom';
import { Breadcrumbs, Link, Box, Typography } from '@material-ui/core';
import CutOffDateConfig from './CutOffDateConfig';

interface IParams {
  id?: string | undefined;
}

const CutOffDateIndex: React.FC<RouteComponentProps<IParams>> = ({ history, match }) => {
  return (
    <Box mb="1.5em">
      <Wrap>
        <Breadcrumbs>
          <Link color="inherit" onClick={() => history.push('/')}>
            Home
          </Link>
          <Link color="inherit" onClick={() => history.push('/participantes/reingresso')}>
            Reingresso
          </Link>
          <Typography color="inherit">{!!match.params.id ? 'Visualizar' : 'Cadastrar'}</Typography>
        </Breadcrumbs>
      </Wrap>

      <CutOffDateConfig />
    </Box>
  );
};

export default CutOffDateIndex;
