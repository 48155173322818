import { action } from 'typesafe-actions';
import { PremiacaoTypes, IPremiacao } from './';

export const loadPremiacao = (id: number) => action(PremiacaoTypes.LOAD_REQUEST, { id });
export const loadPremiacaoSuccess = (data: IPremiacao) =>
  action(PremiacaoTypes.LOAD_SUCCESS, { data });
export const resetPremiacao = () => action(PremiacaoTypes.RESET_PREMIACAO);

export const createPremiacao = data => action(PremiacaoTypes.CREATE_REQUEST, data);
export const createPremiacaoSuccess = data => action(PremiacaoTypes.CREATE_SUCCESS, data);
export const createPremiacaoFailure = () => action(PremiacaoTypes.CREATE_FAILURE);
