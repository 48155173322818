import { Reducer } from 'redux';
import Utils from 'common/Utils';

export enum FdvListTypes {
  LOAD_REQUEST = '@fdvList/LOAD_REQUEST',
  LOAD_SUCCESS = '@fdvList/LOAD_SUCCESS',
  LOAD_FAILURE = '@fdvList/LOAD_FAILURE',
  LOAD_DISTRIBUTORS_REQUEST = '@fdvList/LOAD_DISTRIBUTORS_REQUEST',
  LOAD_DISTRIBUTORS_SUCCESS = '@fdvList/LOAD_DISTRIBUTORS_SUCCESS',
  LOAD_DISTRIBUTORS_FAILURE = '@fdvList/LOAD_DISTRIBUTORS_FAILURE',
}

export interface IFdvListItem {
  username: string;
  nome: string;
  perfilId: number;
  perfilNome: string;
  lojas: number;
  status: string;
  distribuidorNome: string;
  superior: IFdvListItem;
}

export interface IDistributor {
  cnpj: string;
  razaoSocial: string;
}

export interface IFdvListState {
  readonly data: IFdvListItem[];
  readonly loading: boolean;
  readonly error: boolean;
  readonly distributors: IDistributor[];
}

const INITIAL_STATE: IFdvListState = {
  data: [],
  loading: false,
  error: false,
  distributors: [],
};

const reducer: Reducer<IFdvListState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FdvListTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case FdvListTypes.LOAD_SUCCESS:
      const formated = action.payload.data.participantes.map((e: IFdvListItem) => {
        return {
          ...e,
          username: Utils.maskCpf(e.username),
          superior: e.perfilId === 3 ? 'N/A' : e.superior && e.superior.nome,
          lojas: e.perfilId === 7 || e.perfilId === 8 ? e.lojas : 'N/A',
        };
      });
      return { ...state, loading: false, error: false, data: formated };
    case FdvListTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true, data: [] };
    case FdvListTypes.LOAD_DISTRIBUTORS_REQUEST:
      return { ...state, loading: true };
    case FdvListTypes.LOAD_DISTRIBUTORS_SUCCESS:
      return { ...state, loading: false, error: false, distributors: action.payload.data };
    case FdvListTypes.LOAD_DISTRIBUTORS_FAILURE:
      return { ...state, loading: false, error: true, distributors: [] };
    default:
      return state;
  }
};

export default reducer;
