import React from 'react';
import { Box, createStyles, makeStyles, Modal } from '@material-ui/core';
import {
  benefitLogo,
  benefitDetail,
  benefitSuperiorMobile,
  benefitSuperior,
  benefitCard,
} from './index';

interface IModal {
  open: boolean;
  url: string;
}

interface ModalProps {
  modalOpen: IModal;
  setModalOpen: React.Dispatch<React.SetStateAction<IModal>>;
}

export const BannerExample = {
  SUPERIOR: benefitSuperior,
  SUPERIOR_MOBILE: benefitSuperiorMobile,
  CARD: benefitCard,
  LOGO: benefitLogo,
  DETALHE: benefitDetail,
};

const useStyle = makeStyles(() =>
  createStyles({
    box: {
      position: 'absolute',
      top: '50%',
      overflowY: 'auto',
      left: '50%',
      height: 'auto',
      maxHeight: '85%',
      transform: 'translate(-50%, -50%)',
      width: 'auto',
      maxWidth: '80%',
      backgroundColor: '#ffffff',
      border: '2px solid #000',
      padding: '25px',
    },
  }),
);

const ModalBannerExample: React.FC<ModalProps> = ({ modalOpen, setModalOpen }) => {
  const modal = useStyle();

  return (
    <>
      <Modal open={modalOpen.open} onClose={() => setModalOpen({ open: false, url: '' })}>
        <Box className={modal.box}>
          <img style={{ width: '100%' }} src={modalOpen.url} alt="imagem-exemplo" />
        </Box>
      </Modal>
    </>
  );
};

export default ModalBannerExample;
