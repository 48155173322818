import { action } from 'typesafe-actions';
import { IndustryTypes, IIndustry } from './';

export const loadIndustries = () => action(IndustryTypes.LOAD_REQUEST);
export const loadIndustriesSuccess = (data: IIndustry[]) =>
  action(IndustryTypes.LOAD_SUCCESS, { data });
export const loadIndustriesByRegions = (regions: string[]) =>
  action(IndustryTypes.LOAD_BY_REGION_REQUEST, { regions });

export const resetIndustries = () => action(IndustryTypes.RESET);
