import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import {
  loadUploadFileSuccess,
  loadUploadFileFailure,
  loadFileAllowanceDetailsSuccess,
  loadFileAllowanceDetailsFailure,
  participantAllowanceCsvSuccess,
  participantAllowanceCsvFailure,
} from './actions';
import { showToastr } from 'store/toastr/actions';
import { AppMessages } from 'common/AppMessages.enum';

export function* loadParticipantAllowanceFile() {
  try {
    const { data } = yield call(API.get, `/participante-pagamento-abono/StatusUpload`);
    yield put(loadUploadFileSuccess(data));
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
    yield put(loadUploadFileFailure());
  }
}

export function* loadFileAllowanceDetails(action) {
  try {
    const { data } = yield call(
      API.get,
      `/participante-pagamento-abono/StatusProcessamento?idArquivo=${action.payload.id}`,
    );
    yield put(loadFileAllowanceDetailsSuccess(data));
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
    yield put(loadFileAllowanceDetailsFailure());
  }
}

export function* uploadParticipantAllowanceCsv(action) {
  try {
    const { data } = yield call(
      API.post,
      `participante-pagamento-abono/upload`,
      action.payload.file,
    );

    if (!data.erro) {
      yield put(participantAllowanceCsvSuccess(data));
      yield put(
        showToastr({
          type: 'success',
          message: AppMessages.CAMPAIGN_REPORT_UPLOAD_SUCCESS,
          open: true,
        }),
      );
    } else {
      yield put(
        showToastr({ type: 'error', message: AppMessages.ERROR_MAILING_PROCCESS, open: true }),
      );
      yield put(participantAllowanceCsvFailure());
    }
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
    yield put(participantAllowanceCsvFailure());
  }
}
