import { Reducer } from 'redux';

export enum UploadConectaPayReportTypes {
  LOAD_REQUEST = '@uploadConectaPayReport/LOAD_REQUEST',
  LOAD_SUCCESS = '@uploadConectaPayReport/LOAD_SUCCESS',
  LOAD_FAILURE = '@uploadConectaPayReport/LOAD_FAILURE',
  RESET_CONECTAPAY_REPORT = '@ConectaPayEdit/RESET_ConectaPay_REPORT',

  CREATE_REQUEST = '@uploadConectaPayReport/CREATE_REQUEST',
  CREATE_SUCCESS = '@uploadConectaPayReport/CREATE_SUCCESS',
  CREATE_FAILURE = '@uploadConectaPayReport/CREATE_FAILURE',
}

export interface IUploadConectaPayReportData {
  descricao: string;
  tipoConectaPayRelatorio: number;
  data_Processamento: string;
}

export interface IUploadConectaPayReportCreateRequest {
  descricao: string;
  tipoConectaPayRelatorio: number;
  data_Processamento: string;
}

export interface IUploadConectaPayReportEdit extends IUploadConectaPayReportData {
  id: string | undefined;
}

export interface IUploadConectaPayReportEditState {
  readonly data: IUploadConectaPayReportEdit;
  readonly loading: boolean;
  readonly error: boolean;
}

export const INITIAL_STATE: IUploadConectaPayReportEditState = {
  data: {
    id: '',
    descricao: '',
    tipoConectaPayRelatorio: 0,
    data_Processamento: '',
  },
  loading: false,
  error: false,
};

const reducer: Reducer<IUploadConectaPayReportEditState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //Loads
    case UploadConectaPayReportTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case UploadConectaPayReportTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: { ...action.payload.data },
      };
    case UploadConectaPayReportTypes.RESET_CONECTAPAY_REPORT:
      return { ...state, loading: false, error: false, data: INITIAL_STATE.data };
    case UploadConectaPayReportTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true, data: { ...INITIAL_STATE.data } };
    //Creates
    case UploadConectaPayReportTypes.CREATE_REQUEST:
      return { ...state, loading: true };
    case UploadConectaPayReportTypes.CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: { ...state.data, ...action.payload, id: action.payload.id },
      };
    case UploadConectaPayReportTypes.CREATE_FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default reducer;
