import createSagaMiddleware from 'redux-saga';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';
import { loadState, saveState } from '../common/Utils';
import throttle from 'lodash.throttle';
import { IBannerListState } from './banner/list/index';
import { IBannerEditState } from './banner/edit/index';
import { IProfileState } from './profiles/index';
import { IRegionsState } from './regions/index';
import { IToastrState } from './toastr/index';
import { IIndustryState } from './industries/index';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore, Store } from 'redux';
import { createLogger } from 'redux-logger';

import { ICampaignListState } from './campaign/list';
import { ICampaignEditState } from './campaign/edit';
import { ICampaignCoutingState } from './campaign/report';

import { IConectaPayListState } from './conectaPay/list';
import { IConectaPayEditState } from './conectaPay/edit';
import { IConectaPayCoutingState } from './conectaPay/report';

import { IGenerateBankSlipState } from './GenerateBankSlip';

import { IClusterState } from './clusters';
import { ISegmentState } from './segments';
import { IKpiState } from './kpi';
import { IMailingState } from './mailing';
import { ICommunicationState } from './communication';
import { IRegulationState } from './regulation';
import { IDistribuitorState } from './distribuitors';

import { IFdvListState } from './fdvList';

import { IUserListState } from './user/list';
import { IUserEditState } from './user/edit';
import { IPerfilState } from './perfil/index';

import { IPaymentState } from './payments/index';
import { IPaymentMethodState } from './dashboard/index';

import { IChainingNetworkState } from './participant/index';
import { IMigrationOfPointsState } from './migrationOfPoints';

//DASHBOARD-REALTIME
import { ITotalCadastroState } from './realtime/totalCadastros';
import { ITotalAceitesState } from './realtime/totalAceites';
import { ITotalUltimosCadastrosState } from './realtime/totalUltimosCadastros';
import { ITotalUltimosCancelamentosState } from './realtime/totalUltimosCancelamentos';
import { ITotalMeiosPagamentosState } from './realtime/totalMeiosPagamentos';
import { ITotalPrimeirosAcessosClusterState } from './realtime/totalPrimeirosAcessosCluster';
import { ITotalErrosAccesstageState } from './realtime/totalErrosAccesstage';
import { ITotalErrosCadastrosState } from './realtime/totalErrosCadastros';
import { ITotalErrosCancelamentosState } from './realtime/totalErrosCancelamentos';
import { ITotalErrosIntegracaoBoletosState } from './realtime/totalErrosIntegracaoBoletos';
import { ITotalMalhaAtivacoesState } from './realtime/totalMalhaAtivacoes';
import { ITotalMalhaAtualizacoesState } from './realtime/totalMalhaAtualizacoes';
import { ITotalMalhaAtualizacoesVolumeState } from './realtime/totalMalhaAtualizacoesVolume';
import { ITotalMalhaCancelamentosState } from './realtime/totalMalhaCancelamentos';
import { IPopupEditState } from './popup/edit';
import { IPopupListState } from './popup/list';

//Campaign Reports
import { IUploadCampaignReportEditState } from './uploadCampaignReport/index';
import { ICampaignReportState } from './campaignReport/index';
import { IUploadCampaignReportListState } from './uploadCampaignReport/list';
import { IUploadCampaignFileState } from './uploadCampaignReport/uploadFile';
import { IUploadChainingNetworkListState } from './chainingNetwork/list';
import { IUploadDataFileChainingNetworkState } from './chainingNetwork/uploadFile';

//Campaign Reports
import { IUploadConectaPayReportEditState } from './UploadConectaPayReport/index';
import { IConectaPayReportState } from './conectaPayReport/index';
import { IUploadConectaPayReportListState } from './UploadConectaPayReport/list';
import { IUploadConectaPayFileState } from './UploadConectaPayReport/uploadFile';

import { IAutomaticCreditState } from './counting/automaticCredit';
import { IPremiacaoState } from './premiacao';
import { ISkuState } from './sku';
import { IBenefitEditState } from './benefit/edit';
import { IBenefitListState } from './benefit/list';
import { IBenefitMailingState } from './benefitMailing';
import { IParticipantAllowanceFileState } from './participantAllowance/uploadFile';
import { IParticipantExemptionFileState } from './participantExemption/uploadFile';
import { ITrackingBonificationReportState } from './trackingBonification/report';
import { IPlanManagementState } from './planManagement';
import { IParticipantSeparateInvoiceFileState } from './participantSeparateInvoice/uploadFile';
import { IReentryState } from './reentryConfigStore';

// Product
import { IProductListState } from './product/list';
import { IProductEditState } from './product/edit';

// Track Import
import { ITrackImportFileState } from './trackImport';

export const history = createBrowserHistory();

declare var console: any;

export interface ApplicationState {
  bannerList: IBannerListState;
  bannerEdit: IBannerEditState;
  popupList: IPopupListState;
  popupEdit: IPopupEditState;
  campaignList: ICampaignListState;
  campaignEdit: ICampaignEditState;
  campaignCouting: ICampaignCoutingState;
  conectaPayList: IConectaPayListState;
  conectaPayEdit: IConectaPayEditState;
  conectaPayCouting: IConectaPayCoutingState;
  communication: ICommunicationState;
  clusters: IClusterState;
  distribuitors: IDistribuitorState;
  generateBankSlip: IGenerateBankSlipState;
  industries: IIndustryState;
  segments: ISegmentState;
  toastr: IToastrState;
  regions: IRegionsState;
  kpi: IKpiState;
  profiles: IProfileState;
  mailing: IMailingState;
  regulation: IRegulationState;
  fdvList: IFdvListState;
  userList: IUserListState;
  userEdit: IUserEditState;
  perfil: IPerfilState;
  paymentMethod: IPaymentMethodState;
  payments: IPaymentState;
  participant: IChainingNetworkState;
  paymentReceivablesMethod: IPaymentMethodState;
  migrationOfPoints: IMigrationOfPointsState;
  realtimeTotalCadastro: ITotalCadastroState;
  realtimeTotalAceites: ITotalAceitesState;
  realtimeTotalUltimosCadastros: ITotalUltimosCadastrosState;
  realtimeTotalUltimosCancelamentos: ITotalUltimosCancelamentosState;
  realtimeTotalMeiosPagamentos: ITotalMeiosPagamentosState;
  realtimeTotalPrimeirosAcessosCluster: ITotalPrimeirosAcessosClusterState;
  realtimeTotalErrosAccesstage: ITotalErrosAccesstageState;
  realtimeTotalErrosCadastros: ITotalErrosCadastrosState;
  realtimeTotalErrosCancelamentos: ITotalErrosCancelamentosState;
  realtimeTotalErrosIntegracaoBoletos: ITotalErrosIntegracaoBoletosState;
  realtimeTotalMalhaAtivacoes: ITotalMalhaAtivacoesState;
  realtimeTotalMalhaAtualizacoes: ITotalMalhaAtualizacoesState;
  realtimeTotalMalhaAtualizacoesVolume: ITotalMalhaAtualizacoesVolumeState;
  realtimeTotalMalhaCancelamentos: ITotalMalhaCancelamentosState;
  uploadCampaignReport: IUploadCampaignReportEditState;
  campaignReport: ICampaignReportState;
  uploadCampaignReportList: IUploadCampaignReportListState;
  storeUploadCampaignReportFile: IUploadCampaignFileState;
  uploadConectaPayReport: IUploadConectaPayReportEditState;
  conectaPayReport: IConectaPayReportState;
  uploadConectaPayReportList: IUploadConectaPayReportListState;
  storeUploadConectaPayReportFile: IUploadConectaPayFileState;
  uploadChainingNetworkList: IUploadChainingNetworkListState;
  uploadDataFileChainingNetworkList: IUploadDataFileChainingNetworkState;
  automaticCredit: IAutomaticCreditState;
  premiacao: IPremiacaoState;
  sku: ISkuState;
  benefit: IBenefitEditState;
  benefitList: IBenefitListState;
  benefitMailing: IBenefitMailingState;
  storeParticipantAllowanceFile: IParticipantAllowanceFileState;
  storeParticipantExemptionFile: IParticipantExemptionFileState;
  trackingBonificationReport: ITrackingBonificationReportState;
  planManagement: IPlanManagementState;
  storeParticipantSeparateInvoiceFile: IParticipantSeparateInvoiceFileState;
  reentryConfigStore: IReentryState;
  productList: IProductListState;
  productEdit: IProductEditState;
  storeTrackImportFile: ITrackImportFileState;
}

const logger = createLogger();
const middlewares: any = [routerMiddleware(history)];

const sagaMonitor =
  process.env.NODE_ENV === 'development' ? console.tron.createSagaMonitor() : null;

const sagaMiddleware = createSagaMiddleware({ sagaMonitor });

const persistedState = loadState();

middlewares.push(sagaMiddleware);

const composer: any =
  process.env.NODE_ENV === 'development'
    ? compose(applyMiddleware(...middlewares, logger), console.tron.createEnhancer())
    : compose(applyMiddleware(...middlewares));

const store: Store<ApplicationState> =
  process.env.NODE_ENV === 'development'
    ? createStore(rootReducer(history), persistedState, composer)
    : createStore(rootReducer(history), composer);

if (process.env.NODE_ENV === 'development') {
  store.subscribe(
    throttle(() => {
      saveState({
        ...store.getState(),
      });
    }, 1000),
  );
}

sagaMiddleware.run(rootSaga);

export default store;
