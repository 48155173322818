import { Box, Button, createStyles, IconButton, makeStyles, Paper, Theme } from '@material-ui/core';
import { Delete, Edit, Visibility } from '@material-ui/icons';
import Api from 'common/Api';
import { IDefaultPatternRequest, TABLE_STYLE } from 'common/Constants';
import Utils from 'common/Utils';
import CopyToClipboard from 'components/CopyToClipboard/CopyToClipboard';
import GenericTable from 'components/GenericTable/GenericTable';
import ModalCheckboxList from 'components/ModalCheckboxList/ModalCheckboxList';
import ModalConfirmation, {
  IModalConfirmationState,
} from 'components/ModalConfirmation/ModalConfirmation';
import ModalSinglePatternEdit from 'components/ModalSinglePatternEdit/ModalSinglePatternEdit';
import { EditorState } from 'draft-js';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from 'store';
import { ICommunicationItem, ICommunicationUpdateRequest } from 'store/communication';
import * as CommuncationActions from 'store/communication/actions';

declare var screen: any;

interface CommunicationProps {
  loadCommunicationItems(campaignId: number): void;
  deleteCommunicationItemContent(campaignId: number, communicationId: number): void;
  updateCommunicationsContent(campaignId: number, body: ICommunicationUpdateRequest): void;
  loadCommunicationPattern(campaignId: number): void;
  uploadCommunicationPattern(campaignId: number, data: FormData): void;
  updateCommunicationPattern(campaignId: number, request: IDefaultPatternRequest): void;
  communicationItems: ICommunicationItem[];
  pattern: string;
  campaignId: number;
  loading: boolean;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    spacing: {
      padding: theme.spacing(2),
    },
    spacingBottom: {
      marginBottom: theme.spacing(2),
    },
    spacingTop: {
      marginTop: theme.spacing(4),
    },
    icons: {
      display: 'flex',
    },
  }),
);

const Communication: React.FC<CommunicationProps> = ({
  loadCommunicationItems,
  deleteCommunicationItemContent,
  updateCommunicationsContent,
  communicationItems,
  pattern,
  loadCommunicationPattern,
  uploadCommunicationPattern,
  updateCommunicationPattern,
  campaignId,
  loading,
}) => {
  const classes = useStyle();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ownPattern, setOwnPatternState] = useState({
    editorState: EditorState.createEmpty(),
  });
  const [modalConfirmation, setModalConfirmation] = useState<IModalConfirmationState>({
    open: false,
    onConfirm: null,
  });
  const [modalUploadUrl, setModalUploadUrl] = useState<IModalConfirmationState>({
    open: false,
    onConfirm: null,
  });
  const [uploadUrl, setUploadUrl] = useState('');
  const [isLoadingUploadFile, setIsLoadingUploadFile] = useState(false);
  const [modalSinglePatternEdit, setModalSinglePatternEdit] = useState({
    open: false,
    item: {
      clusterId: 0,
      clusterNome: '',
      campanhaComunicacaoId: 0,
      html: '',
    },
  });

  const handleOnDelete = (row: ICommunicationItem) => {
    setModalConfirmation({
      open: true,
      onConfirm: () =>
        deleteCommunicationItemContent(Number(campaignId), row.campanhaComunicacaoId),
    });
  };

  const handleUpdateCommunication = (clusterIds, html) => {
    updateCommunicationsContent(Number(campaignId), { clusterIds, html });
  };

  const handleUploadFile = async (file: any, resetFileUpload) => {
    setIsLoadingUploadFile(true);
    const formData = new FormData();
    const formated = Utils.getFormatedFile(file);
    formData.append('file', formated);
    const { data } = await Api.post(`/campanha/${Number(campaignId)}/comunicacao/upload`, formData);
    setUploadUrl(data.url);
    setModalUploadUrl({
      open: true,
      onConfirm: () => {
        resetFileUpload();
        setModalUploadUrl({ open: false, onConfirm: null });
      },
    });
    setIsLoadingUploadFile(false);
  };

  const handleSavePattern = () => {
    const content = Utils.getEditorData(ownPattern.editorState);
    updateCommunicationPattern(Number(campaignId), { clusterIds: [null], html: content });
  };

  const handleOnSingleEditConfirm = (clusterId: number, html: any) => {
    updateCommunicationsContent(Number(campaignId), { clusterIds: [clusterId], html });
  };

  const handleOnEdit = item => {
    setModalSinglePatternEdit({ open: true, item });
  };

  const handleOnPreview = html => {
    const win = window.open(
      '',
      '',
      `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=980,height=500,top=${100},left=${0}`,
    );
    if (win) win.document.body.innerHTML = html;
  };

  useEffect(() => {
    loadCommunicationItems(Number(campaignId));
    loadCommunicationPattern(Number(campaignId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pattern) {
      setOwnPatternState({ ...ownPattern, editorState: Utils.stringToEditorText(pattern) });
    }
  }, [pattern]);

  return (
    <Paper>
      <Box p="1.5em">
        {/* <DefaultCampaign
          isLoading={loading}
          handleUploadFile={handleUploadFile}
          state={ownPattern}
          setState={setOwnPatternState}
          isUploadLoading={isLoadingUploadFile}
          handleSavePattern={handleSavePattern}
          campaignId={Number(campaignId)}
          uploadUrl={`/campanha/${Number(campaignId)}/comunicacao/upload`}
          title="Comunicação padrão da Comunicação:"
        /> */}

        <Box className={classes.spacingTop}>
          <Box className={classes.spacingBottom}>
            Caso haja comunicações específicas para perfis da campanha, selecione os perfis e
            descreva a comunicação
          </Box>
          <Box>
            <Button variant="contained" color="primary" onClick={() => setIsModalOpen(true)}>
              Atualizar comunicação
            </Button>
          </Box>
        </Box>

        <Box className={classes.spacingTop}>
          <GenericTable
            title="Comunicações"
            isLoading={loading}
            data={communicationItems}
            options={{
              paging: false,
              search: false,
            }}
            columns={[
              {
                title: 'Cluster',
                field: 'clusterNome',
                cellStyle: { ...TABLE_STYLE },
              },
              {
                title: 'Excluir',
                render: (row: ICommunicationItem) => {
                  return (
                    <Box className={classes.icons}>
                      <IconButton onClick={() => handleOnEdit(row)}>
                        <Edit fontSize="small" />
                      </IconButton>
                      <IconButton
                        disabled={!row.campanhaComunicacaoId || !row.html}
                        onClick={() => handleOnPreview(row.html)}
                      >
                        <Visibility fontSize="small" />
                      </IconButton>
                      <IconButton
                        disabled={!row.campanhaComunicacaoId || !row.html}
                        onClick={() => handleOnDelete(row)}
                      >
                        <Delete fontSize="small" />
                      </IconButton>
                    </Box>
                  );
                },
                cellStyle: { width: '5%', ...TABLE_STYLE },
              },
            ]}
          />
        </Box>
      </Box>

      <ModalCheckboxList
        title="Alterar Comunicação"
        subTitle="Comunicado:"
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        items={communicationItems}
        onConfirm={handleUpdateCommunication}
        uploadUrl={`/campanha/${Number(campaignId)}/comunicacao/upload`}
      />

      <ModalSinglePatternEdit
        title={modalSinglePatternEdit.item.clusterNome}
        item={modalSinglePatternEdit.item}
        isModalOpen={modalSinglePatternEdit.open}
        forKey={'campanhaComunicacaoId'}
        uploadUrl={`/campanha/${Number(campaignId)}/comunicacao/upload`}
        onConfirm={handleOnSingleEditConfirm}
        setIsModalOpen={setModalSinglePatternEdit}
      />

      <ModalConfirmation
        title="Excluir Comunicado"
        content="Tem certeza que deseja confirmar a exclusão deste comunicado?"
        state={modalConfirmation}
        setState={setModalConfirmation}
      />

      <ModalConfirmation
        size="lg"
        title="Arquivo de comunicação enviado com sucesso."
        content={<CopyToClipboard content={uploadUrl} />}
        state={modalUploadUrl}
        setState={setModalUploadUrl}
      />
    </Paper>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  campaignId: state.campaignEdit.data.id,
  communicationItems: state.communication.data.items,
  pattern: state.communication.data.pattern,
  loading: state.communication.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...CommuncationActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Communication);
