import React, { useEffect, useState } from 'react';
import {
  Grid,
  FormControl,
  TextField,
  createStyles,
  Select,
  makeStyles,
  Theme,
  IconButton,
  FormHelperText,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from 'store';
import * as ReentryConfigActions from 'store/reentryConfigStore/actions';
import Delete from '@material-ui/icons/Delete';
import { TriggerPromo, CreatePromotion, ErrorsList } from '../IncentivePromoConfig';
import Utils from 'common/Utils';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 120,
    },
    grid: {
      margin: 'auto',
    },
    flex: {
      display: 'flex',
    },
    btn: {
      marginRight: '10px',
      width: '56px',
    },
    error: {
      color: '#ff0000',
    },
  }),
);

interface TargetOptionsTypoPromo {
  descricao: string;
  id: number;
  tipoPromocaoIncentivoId: number;
  codigo: string;
}

interface IAddBonification {
  notTriggerPromos: TriggerPromo[];
  setNotTriggerPromos: React.Dispatch<React.SetStateAction<TriggerPromo[] | []>>;
  target: CreatePromotion;
  setTarget: React.Dispatch<React.SetStateAction<CreatePromotion>>;
  index: number;
  errorsList: ErrorsList[];
  setErrorsList: React.Dispatch<React.SetStateAction<ErrorsList[]>>;
}

const AddBonification: React.FC<IAddBonification> = ({
  notTriggerPromos,
  setNotTriggerPromos,
  target,
  setTarget,
  index,
  errorsList,
  setErrorsList,
}) => {
  const classes = useStyle();
  const [targetOptionsTypoPromo, setTargetOptionsTypoPromo] = useState<TargetOptionsTypoPromo>();

  useEffect(() => {
    // REGRA DE NEGÓCIO - Caso PAGANDO seja TODAS MENSALIDADES, reseta o Reack Hook targetOptionsTypoPromo para caso ABONO.
    if (notTriggerPromos.find(x => x.codigo === targetOptionsTypoPromo?.codigo) === undefined) {
      return setTargetOptionsTypoPromo(undefined);
    }
    // eslint-disable-next-line
  }, [notTriggerPromos]);

  const handlePromoTypeChange = e => {
    let codeTarget = e.target.options[e.target.options.selectedIndex].getAttribute('data-codigo');

    let targetNotTriggerPromos =
      codeTarget === 'SELECIONE'
        ? undefined
        : notTriggerPromos.find(x => {
            return x.codigo === codeTarget;
          });

    let tipoPromocaoIncentivoOpcao = targetNotTriggerPromos?.tipoPromocaoIncentivoOpcoes[0];

    let newArray = target.reingressoPromocaoIncentivoTipo.map((item, i) =>
      i === index
        ? {
            ...item,
            tipoPromocaoIncentivoId: parseInt(e.target.value),
            tipoPromocaoIncentivoOpcaoId:
              parseInt(e.target.value) === 0 ? 0 : tipoPromocaoIncentivoOpcao!.id,
            valor: '',
            codigo: Utils.slugfy(tipoPromocaoIncentivoOpcao!.descricao),
          }
        : item,
    );

    setNotTriggerPromos(
      notTriggerPromos.map(x => {
        if (x.id === parseInt(e.target.value)) return { ...x, selected: true };
        else if (x.codigo === targetOptionsTypoPromo?.codigo && x.selected) {
          return { ...x, selected: false };
        } else {
          return x;
        }
      }),
    );

    setTarget({
      ...target,
      reingressoPromocaoIncentivoTipo: newArray,
    });

    if (parseInt(e.target.value) === 0) {
      return setTargetOptionsTypoPromo(undefined);
    } else {
      return setTargetOptionsTypoPromo({
        ...tipoPromocaoIncentivoOpcao!,
        codigo: targetNotTriggerPromos!.codigo,
      });
    }
  };

  const handlePromoValueChange = e => {
    let newArray = target.reingressoPromocaoIncentivoTipo.map((item, i) =>
      i === index
        ? {
            ...item,
            valor: e.target.value,
          }
        : item,
    );

    setTarget({
      ...target,
      reingressoPromocaoIncentivoTipo: newArray,
    });
  };

  const handleCheckedChange = (e, checked) => {
    let option = checked ? 'todas-mensalidades' : 'mensalidades';

    let incentiveOptions = notTriggerPromos.find(x => x.codigo === targetOptionsTypoPromo!.codigo)!
      .tipoPromocaoIncentivoOpcoes;
    let chooseOption = incentiveOptions.find(x => Utils.slugfy(x.descricao) === option);

    setTargetOptionsTypoPromo({
      ...chooseOption!,
      codigo: targetOptionsTypoPromo!.codigo,
    });

    let newArray = target.reingressoPromocaoIncentivoTipo.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          tipoPromocaoIncentivoOpcaoId: chooseOption!.id,
          valor: checked ? '' : item.valor,
          codigo: option,
        };
      } else {
        return item;
      }
    });

    setTarget({
      ...target,
      reingressoPromocaoIncentivoTipo: newArray,
    });
  };

  const handleDeleteConfig = () => {
    let newArray = target.reingressoPromocaoIncentivoTipo.filter((elem, i) => i !== index);

    setNotTriggerPromos(
      notTriggerPromos.map(x => {
        if (x.codigo === targetOptionsTypoPromo?.codigo) {
          return { ...x, selected: false };
        } else {
          return x;
        }
      }),
    );

    setTarget({
      ...target,
      reingressoPromocaoIncentivoTipo: newArray,
    });

    let newErrorArray = errorsList.filter((elem, i) => i !== index);
    setErrorsList(newErrorArray);
  };

  return (
    <Grid container spacing={3} className={classes.grid}>
      <Grid item xs={4} className={classes.flex}>
        <IconButton className={classes.btn} onClick={handleDeleteConfig} color="primary">
          <Delete />
        </IconButton>
        <FormControl fullWidth className={classes.formControl}>
          <TextField disabled label="Varejo Ganha" variant="outlined" />
        </FormControl>
      </Grid>

      <Grid item xs={4}>
        <FormControl fullWidth variant="outlined" className={classes.formControl}>
          <Select
            inputProps={{
              name: 'tipoPromocaoIncentivoId',
            }}
            required
            native
            onChange={(e: React.ChangeEvent<{ value: any }>) => handlePromoTypeChange(e)}
          >
            <option value={0} data-codigo={'SELECIONE'}>
              Selecione o tipo de bonificação...
            </option>
            {!!notTriggerPromos.length &&
              notTriggerPromos.map(item => {
                if (
                  (item.selected && targetOptionsTypoPromo?.codigo === item.codigo) ||
                  !item.selected
                ) {
                  return (
                    <option key={item.id} value={item.id} data-codigo={item.codigo}>
                      {item.descricao}
                    </option>
                  );
                }
                return false;
              })}
          </Select>
          {!!errorsList[index].errorSelect && (
            <FormHelperText className={classes.error}>
              {errorsList[index].errorSelect}
            </FormHelperText>
          )}
        </FormControl>
      </Grid>

      {targetOptionsTypoPromo && targetOptionsTypoPromo.codigo === 'ABONO' && (
        <>
          <Grid item xs={2}>
            <FormControl fullWidth className={classes.formControl}>
              <TextField
                inputProps={{
                  name: 'tipoPromocaoIncentivoValue',
                }}
                required
                error={
                  target.reingressoPromocaoIncentivoTipo[index].valor === '' &&
                  Utils.slugfy(targetOptionsTypoPromo.descricao) === 'mensalidades'
                }
                disabled={Utils.slugfy(targetOptionsTypoPromo.descricao) === 'todas-mensalidades'}
                value={target.reingressoPromocaoIncentivoTipo[index].valor}
                onChange={(e: React.ChangeEvent<{ value: any }>) => handlePromoValueChange(e)}
                label={targetOptionsTypoPromo.descricao}
                variant="outlined"
                type="number"
              />
            </FormControl>
            {!!errorsList[index].errorInput && (
              <FormHelperText className={classes.error}>
                {errorsList[index].errorInput}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={2} style={{ alignSelf: 'center' }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e, checked) => handleCheckedChange(e, checked)}
                    color="primary"
                  />
                }
                label="Todos os meses"
              />
            </FormControl>
          </Grid>
        </>
      )}

      {targetOptionsTypoPromo && targetOptionsTypoPromo.codigo !== 'ABONO' && (
        <>
          <Grid item xs={4}>
            <FormControl fullWidth className={classes.formControl}>
              <TextField
                inputProps={{
                  name: 'tipoPromocaoIncentivoValue',
                }}
                required
                error={
                  target.reingressoPromocaoIncentivoTipo[index].tipoPromocaoIncentivoOpcaoId !==
                    0 && target.reingressoPromocaoIncentivoTipo[index].valor === ''
                }
                value={target.reingressoPromocaoIncentivoTipo[index].valor}
                onChange={(e: React.ChangeEvent<{ value: any }>) => handlePromoValueChange(e)}
                label={targetOptionsTypoPromo.descricao}
                variant="outlined"
                type="number"
              />
            </FormControl>
            {!!errorsList[index].errorInput && (
              <FormHelperText className={classes.error}>
                {errorsList[index].errorInput}
              </FormHelperText>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};

const mapStateToProps = (state: ApplicationState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(ReentryConfigActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddBonification);
