import React, { useState, useEffect } from 'react';
import { Box, Paper, CircularProgress } from '@material-ui/core';
import Api from '../../../../common/Api';
import StepTracks from '../../../../components/StepTracks/StepTracks';

interface ITrack {
  idFaixa: number;
  idCampanha: number;
  percentual: number;
  descricao: string;
  opcaoPrimerioPremio: string;
  bannerPrimeiroPremio: FormData;
  opcaoSegundoPremio: string;
  bannerSegundoPremio: FormData;
  pacotes: number;
}

const Tracks: React.FC<any> = props => {
  const [loading, setLoading] = useState(false);
  const [faixas, setFaixas] = useState<Array<ITrack>>([]);

  useEffect(() => {
    loadTracks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function loadTracks() {
    setLoading(true);
    await Api.get(`/campanha/faixas/${parseInt(props.idCampanha)}`).then(function(response) {
      if (
        response !== undefined &&
        response.status === 200 &&
        response.data !== null &&
        response.data.data
      ) {
        setFaixas(response.data.data);
      }
    });
    setLoading(false);
  }

  async function SaveTracks(payload: any) {
    if (payload.idFaixa > 0) {
      setLoading(true);
      await Api.put('/campanha/faixas', payload);
      await loadTracks();
      setLoading(false);
    } else {
      setLoading(true);
      await Api.post('/campanha/faixas', payload);
      await loadTracks();
      setLoading(false);
    }
  }

  return loading ? (
    <CircularProgress />
  ) : (
    <Paper>
      <Box p="1.5em">
        <>
          <StepTracks
            tracks={faixas}
            numberOfSteps={4}
            SaveTracks={SaveTracks}
            idCampanha={parseInt(props.idCampanha)}
            refreshTracks={loadTracks}
          />
        </>
      </Box>
    </Paper>
  );
};

export default Tracks;
