import React from 'react';
import { IconButton } from '@material-ui/core';
import { TABLE_STYLE } from 'common/Constants';
import GenericTable from 'components/GenericTable/GenericTable';
import { CloudDownload } from '@material-ui/icons';
import Utils from '../../../common/Utils';

const CampaignReportExcelList: React.FC<any> = ({ campaignReportExcelList, loading }) => {
  return (
    <>
      {campaignReportExcelList && campaignReportExcelList.length > 0 && (
        <GenericTable
          title="Participantes"
          isLoading={loading}
          data={campaignReportExcelList}
          options={{
            paging: true,
            search: true,
            pageSize: 10,
            sorting: false,
          }}
          columns={[
            {
              title: 'Tipo Relatório',
              field: 'nome_Relatorio',
              cellStyle: { ...TABLE_STYLE, width: '20%' },
            },
            {
              searchable: true,
              title: 'Descrição',
              field: 'descricao',
              cellStyle: { ...TABLE_STYLE, width: '15%' },
            },
            {
              title: 'Data Processamento',
              render: rowData => {
                return `${Utils.setData(rowData.data_Processamento)}`;
              },
              cellStyle: { ...TABLE_STYLE, width: '15%' },
            },
            {
              searchable: false,
              title: 'Ações',
              cellStyle: {
                width: '10%',
                ...TABLE_STYLE,
              },
              render: ({ url_Arquivo }) => (
                <>
                  <IconButton onClick={() => window.open(url_Arquivo)}>
                    <CloudDownload />
                  </IconButton>
                </>
              ),
            },
          ]}
        />
      )}
    </>
  );
};

export default CampaignReportExcelList;
