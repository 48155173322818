import React, { useEffect, useState } from 'react';
import { ApplicationState } from 'store';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import cn from 'classnames';
import { ITypeCampaignReportItem, ICampaignReportParams } from 'store/campaignReport';
import * as CampaignReportActions from 'store/campaignReport/actions';

import {
  Box,
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  Paper,
  Select,
  Theme,
  Button,
  Grid,
  TextField,
} from '@material-ui/core';
import Api from 'common/Api';
import CampaignReportExcelList from './list/CampaignReportExcelList';
import Utils from 'common/Utils';
import { AppMessages } from 'common/AppMessages.enum';

interface IProps extends RouteComponentProps {
  typesCampaignReport: ITypeCampaignReportItem[];
  loading: boolean;
  loadTypesCampaignReportRequest: () => void;
  generateCampaignReportRequest: (data: ICampaignReportParams) => void;
  loadingReport: boolean;
  report: any;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    spacing: {
      marginBottom: theme.spacing(1),
    },
    buttonActions: {
      padding: theme.spacing(1),
      minWidth: 120,
      textAlign: 'left',
      '& > button': {
        marginRight: 0,
      },
    },
  }),
);

export const valuewRequestDefault = {
  tipoCampanhaRelatorio: 0,
  dataInicial: '',
  dataFinal: '',
  pagination: {
    Limit: 0,
    offset: 0,
  },
};

const CampaignReportExcel: React.FC<IProps> = ({
  typesCampaignReport,
  loadTypesCampaignReportRequest,
  loading,
  report,
}) => {
  const classes = useStyle();
  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  const [campaignReports, setCampaignReports] = useState([]);
  const [dataRequest, setDataRequest] = useState(valuewRequestDefault);

  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (prop: keyof any) => async (event: React.ChangeEvent<{ value: unknown }>) =>
    setDataRequest({ ...dataRequest, [prop]: event.target.value });

  const setTypeReport = (prop: keyof any) => (event: React.ChangeEvent<{ value: any }>) => {
    setDataRequest({ ...dataRequest, [prop]: parseInt(event.target.value) });
  };

  useEffect(() => {
    setLabelWidth(inputLabel.current!.offsetWidth);
    ListAllCampaignReportD1();
    loadTypesCampaignReportRequest();
  }, []);

  useEffect(() => {
    if (report.data !== null && report.success) {
      window.open(report.data);
    }
  }, [report]);

  const getErrorMessages = (): boolean => {
    const isDateValid =
      Utils.compareDate(dataRequest.dataInicial, dataRequest.dataFinal) &&
      dataRequest.dataInicial !== dataRequest.dataFinal;

    setErrorMessage(isDateValid ? AppMessages.DATE_START_BIGGERTHAN_END : '');

    return [!isDateValid].some(e => !e);
  };

  async function ListAllCampaignReportD1() {
    await Api.get('/campanhaRelatorio/all/campanhaRelatorio').then(function(response) {
      if (response !== undefined && response.status === 200) {
        setCampaignReports(response.data.data);
      } else {
        setCampaignReports([]);
      }
    });
  }

  async function searchParticipants() {
    const hasErrors = getErrorMessages();
    if (!hasErrors) {
      const dataRequestObj = {
        tipoCampanhaRelatorio: dataRequest.tipoCampanhaRelatorio,
        dataInicial: dataRequest.dataInicial === '' ? null : dataRequest.dataInicial,
        dataFinal: dataRequest.dataFinal === '' ? null : dataRequest.dataFinal,
        pagination: {
          Limit: 0,
          offset: 0,
        },
      };

      await Api.post('/campanhaRelatorio/csvFilter', dataRequestObj).then(function(response) {
        if (response !== undefined && response.status === 200) {
          setCampaignReports(response.data.data);
        } else {
          setCampaignReports([]);
        }
      });
    }
  }

  return (
    <>
      <Paper>
        <Box p="1.5em" className={classes.formControl}>
          <Box fontSize="h5.fontSize" className={classes.spacing}>
            Relatórios em Excel
          </Box>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel ref={inputLabel} htmlFor="typeReport">
              Selecione tipo de relatório
            </InputLabel>
            <Select
              required
              native
              value={dataRequest.tipoCampanhaRelatorio}
              labelWidth={labelWidth}
              onChange={setTypeReport('tipoCampanhaRelatorio')}
              inputProps={{
                name: 'tipoCampanhaRelatorio',
                id: 'tipoCampanhaRelatorio',
              }}
            >
              <option value="0">Todos</option>

              {typesCampaignReport.map(method => (
                <option key={method.id} value={method.id}>
                  {method.nome}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              type="date"
              id="dataInicial"
              value={dataRequest.dataInicial}
              onChange={handleChange('dataInicial')}
              label="Data da atualização inicial"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              type="date"
              id="dataInicial"
              value={dataRequest.dataFinal}
              onChange={handleChange('dataFinal')}
              label="Data da atualização final"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>

          <Grid item={true} xs={12} className={cn([classes.buttonActions])}>
            <Grid item={true} xs={3} className={classes.formControl}>
              <Box fontSize={14} color="red">
                {errorMessage}
              </Box>
            </Grid>
            <Button
              onClick={searchParticipants}
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {loading ? 'Pesquisando...' : 'Pesquisar'}
            </Button>
          </Grid>
        </Box>
      </Paper>
      <CampaignReportExcelList campaignReportExcelList={campaignReports} loading={loading} />
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  typesCampaignReport: state.campaignReport.typesReports,
  loading: state.campaignReport.loading,
  report: state.campaignReport.report,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(CampaignReportActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CampaignReportExcel);
