import { AppMessages } from 'common/AppMessages.enum';
import { showToastr } from 'store/toastr/actions';
import { call, put, all } from 'redux-saga/effects';
import API from 'common/Api';
import { loadBannerTypesSuccess, loadBannersByTypesSuccess } from './actions';

export function* loadBannerTypes() {
  try {
    const { data } = yield call(API.get, 'banner/all/tipos');
    yield put(loadBannerTypesSuccess(data.tipos));
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
  }
}

export function* loadBannerByTypes(action) {
  try {
    const typesId: number[] = action.payload.typesId;

    const items = yield all(typesId.map(x => call(API.get, `banner/all/${x}`)));

    const mappedByTypes = items.map(({ data }, index) => {
      if (data.banners.length > 0) {
        return {
          [index + 1]: [...data.banners],
        };
      } else {
        return undefined;
      }
    });

    yield put(loadBannersByTypesSuccess(mappedByTypes));
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
  }
}
