import React, { useState } from 'react';
import Wrap from 'components/Wrap/Wrap';
import { RouteComponentProps } from 'react-router-dom';
import { Breadcrumbs, Link, Box, Typography } from '@material-ui/core';
import Tab, { ITab } from 'components/Tab/Tab';
import IncentivePromoConfig from './IncentivePromoConfig';
import IncentiveDetailVisualize from './IncentiveDetailVisualize/IncentiveDetailVisualize';
import UploadCampaign from './UploadCampaign/UploadCampaign';
import FilterPDV from './FilterPDV/FilterPDV';

interface IParams {
  id?: string | undefined;
}

const IncentivePromotionIndex: React.FC<RouteComponentProps<IParams>> = ({ history, match }) => {
  const [tabs] = useState<ITab[]>([
    {
      component: match.params.id ? <IncentiveDetailVisualize /> : <IncentivePromoConfig />,
      renderLabel: (tab, index) =>
        `${match.params.id ? 'Visualizar Incentivo' : 'Cadastro Incentivo'}`,
      disabled: false,
    },
    {
      component: <UploadCampaign />,
      renderLabel: (tab, index) => `Upload via Arquivo`,
      disabled: false,
    },
    {
      component: <FilterPDV />,
      renderLabel: (tab, index) => `Filtro PDV`,
      disabled: false,
    },
  ]);

  return (
    <Box mb="1.5em">
      <Wrap>
        <Breadcrumbs>
          <Link color="inherit" onClick={() => history.push('/')}>
            Home
          </Link>
          <Link color="inherit" onClick={() => history.push('/pagamentos/promocoesincentivo')}>
            Promoções de Incentivo
          </Link>
          <Typography color="inherit">{!!match.params.id ? 'Visualizar' : 'Cadastrar'}</Typography>
        </Breadcrumbs>
      </Wrap>
      <Tab tabs={tabs} enableNavigationButton={false} />
    </Box>
  );
};

export default IncentivePromotionIndex;
