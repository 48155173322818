import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import {
  loadRegulationItems as loadRegulationItemsActions,
  loadRegulationItemsSuccess,
  loadRegulationItemsFailure,
  loadRegulationItemContent as loadRegulationItemContentAction,
  loadRegulationItemContentSuccess,
  deleteRegulationItemContentSuccess,
  updateRegulationsContentSuccess,
  loadRegulationPatternSuccess,
  uploadRegulationPatternSuccess,
  updateRegulationPatternSuccess,
} from './actions';
import { showToastr } from 'store/toastr/actions';
import { AppMessages } from 'common/AppMessages.enum';

export function* loadRegulationItems(action) {
  try {
    const campaignId = action.payload.id;
    const { data } = yield call(API.get, `campanha/${campaignId}/regulamento`);
    yield put(loadRegulationItemsSuccess(data));

    yield* data.map(function*(e) {
      if (e.campanhaRegulamentoId) {
        yield put(loadRegulationItemContentAction(campaignId, e.campanhaRegulamentoId));
      }
    });
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
    yield put(loadRegulationItemsFailure());
  }
}

export function* loadRegulationItemContent(action) {
  try {
    const campaignId = action.payload.campaignId;
    const campanhaRegulamentoId = action.payload.campanhaRegulamentoId;

    if (campaignId && campanhaRegulamentoId) {
      const { data } = yield call(
        API.get,
        `/campanha/${campaignId}/regulamento/${campanhaRegulamentoId}`,
      );
      yield put(loadRegulationItemContentSuccess(data));
    }
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
  }
}

export function* deleteRegulationItemContent(action) {
  try {
    const campaignId = action.payload.campaignId;
    const regulationId = action.payload.regulationId;
    yield call(API.delete, `/campanha/${campaignId}/regulamento/${regulationId}`);
    yield put(deleteRegulationItemContentSuccess(regulationId));
    yield put(
      showToastr({
        type: 'success',
        message: AppMessages.DELETE_REGULATION_SUCCESS,
        open: true,
      }),
    );
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
  }
}

export function* updateRegulationsContent(action) {
  try {
    const { data } = yield call(
      API.put,
      `/campanha/${action.payload.campaignId}/regulamento`,
      action.payload.body,
    );
    yield put(updateRegulationsContentSuccess(data));
    yield put(loadRegulationItemsActions(action.payload.campaignId));
    yield put(
      showToastr({
        type: 'success',
        message: AppMessages.UPDATE_REGULATION_SUCCESS,
        open: true,
      }),
    );
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
  }
}

export function* loadRegulationPattern(action) {
  try {
    const { data } = yield call(
      API.get,
      `/campanha/${action.payload.campaignId}/regulamento/padrao`,
    );
    yield put(loadRegulationPatternSuccess(data));
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
  }
}

export function* uploadRegulationPattern(action) {
  try {
    const { data } = yield call(
      API.post,
      `/campanha/${action.payload.campaignId}/regulamento/upload`,
      action.payload.data,
    );
    yield put(uploadRegulationPatternSuccess(data));
    yield put(
      showToastr({
        type: 'success',
        message: AppMessages.UPLOAD_REGULATION_SUCCESS,
        open: true,
      }),
    );
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
  }
}

export function* updateRegulationPattern(action) {
  try {
    const { data } = yield call(
      API.put,
      `/campanha/${action.payload.campaignId}/regulamento`,
      action.payload.request,
    );
    yield put(updateRegulationPatternSuccess(data));
    yield put(
      showToastr({
        type: 'success',
        message: AppMessages.UPDATE_REGULATION_SUCCESS,
        open: true,
      }),
    );
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
  }
}
