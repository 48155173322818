import { Reducer } from 'redux';

export enum ProductEditTypes {
  LOAD_REQUEST = '@productEdit/LOAD_REQUEST',
  LOAD_SUCCESS = '@productEdit/LOAD_SUCCESS',
  LOAD_FAILURE = '@productEdit/LOAD_FAILURE',

  CREATE_REQUEST = '@productEdit/CREATE_REQUEST',
  CREATE_SUCCESS = '@productEdit/CREATE_SUCCESS',
  CREATE_FAILURE = '@productEdit/CREATE_FAILURE',

  UPDATE_REQUEST = '@productEdit/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@productEdit/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@productEdit/UPDATE_FAILURE',

  SETFILTER_COUNT_REQUEST = '@productEdit/SETFILTER_COUNT_REQUEST',
}

export interface IProduct {
  id: number | undefined;
  nome: string;
  ativo: boolean;
  codigo: string;
}

export interface IProductCreateRequest {
  nome: string;
  ativo: boolean;
  codigo: string;
}

export interface IProductEdit extends IProduct {
  imagemUrl: string;
}

export interface IProductEditState {
  readonly data: IProductEdit;
  readonly filterApplied: number;
  readonly filterParticipantCount: number;
  readonly loading: boolean;
  readonly error: boolean;
}

export const INITIAL_STATE: IProductEditState = {
  data: {
    id: 0,
    nome: '',
    codigo: '',
    ativo: true,
    imagemUrl: '',
  },
  filterParticipantCount: 0,
  filterApplied: 0,
  loading: false,
  error: false,
};

const reducer: Reducer<IProductEditState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ProductEditTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case ProductEditTypes.LOAD_SUCCESS:
      return { ...state, loading: false, error: false, data: action.payload.data.data };
    case ProductEditTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true, data: { ...INITIAL_STATE.data } };
    case ProductEditTypes.CREATE_REQUEST:
      return { ...state, loading: true };
    case ProductEditTypes.CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: { ...state.data, ...action.payload, id: action.payload.id },
      };
    case ProductEditTypes.CREATE_FAILURE:
      return { ...state, loading: false };
    case ProductEditTypes.UPDATE_REQUEST:
      return { ...state, loading: true };
    case ProductEditTypes.UPDATE_SUCCESS:
      return { ...state, loading: false, data: { ...state.data } };
    case ProductEditTypes.UPDATE_FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default reducer;
