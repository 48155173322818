import Utils from 'common/Utils';
import { Reducer } from 'redux';

export enum AutomaticCreditTypes {
  LOAD_STATUS_REQUEST = '@automaticCreditTypes/LOAD_STATUS_REQUEST',
  LOAD_STATUS_SUCCESS = '@automaticCreditTypes/LOAD_STATUS_SUCCESS',
  LOAD_STATUS_FAILURE = '@automaticCreditTypes/LOAD_STATUS_FAILURE',
  LOAD_PENDING_AUTOMATIC_CREDIT_REQUEST = '@automaticCreditTypes/LOAD_PENDING_AUTOMATIC_CREDIT_REQUEST',
  LOAD_PENDING_AUTOMATIC_CREDIT_SUCCESS = '@automaticCreditTypes/LOAD_PENDING_AUTOMATIC_CREDIT_SUCCESS',
  LOAD_PENDING_AUTOMATIC_CREDIT_FAILURE = '@automaticCreditTypes/LOAD_PENDING_AUTOMATIC_CREDIT_FAILURE',
  LOAD_AUTOMATIC_CREDIT_DETAIL_REQUEST = '@automaticCreditTypes/LOAD_AUTOMATIC_CREDIT_DETAIL_REQUEST',
  LOAD_AUTOMATIC_CREDIT_DETAIL_SUCCESS = '@automaticCreditTypes/LOAD_AUTOMATIC_CREDIT_DETAIL_SUCCESS',
  LOAD_AUTOMATIC_CREDIT_DETAIL_FAILURE = '@automaticCreditTypes/LOAD_AUTOMATIC_CREDIT_DETAIL_FAILURE',
  APPROVE_AUTOMATIC_CREDIT_REQUEST = '@automaticCreditTypes/APROVE_AUTOMATIC_CREDIT_REQUEST',
  APPROVE_AUTOMATIC_CREDIT_SUCCESS = '@automaticCreditTypes/APROVE_AUTOMATIC_CREDIT_SUCCESS',
  APPROVE_AUTOMATIC_CREDIT_FAILURE = '@automaticCreditTypes/APROVE_AUTOMATIC_CREDIT_FAILURE',
  AUTOMATIC_CREDIT_REPORT_REQUEST = '@automaticCreditTypes/AUTOMATIC_CREDIT_REPORT_REQUEST',
  AUTOMATIC_CREDIT_REPORT_SUCCESS = '@automaticCreditTypes/AUTOMATIC_CREDIT_REPORT_SUCCESS',
  AUTOMATIC_CREDIT_REPORT_FAILURE = '@automaticCreditTypes/AUTOMATIC_CREDIT_REPORT_FAILURE',
  AUTOMATIC_CREDIT_REPORT_RESET = '@automaticCreditTypes/AUTOMATIC_CREDIT_REPORT_RESET',
}

export interface IAutomaticCreditParams {
  idStatus: number;
  documento: string;
}

export interface IAutomaticCreditItem {
  id: number;
  nomeCampanha: string;
  tipoApuracao: string;
}

export interface IAutomaticCreditDetailItem {
  nomeCampanha: string;
  tipoApuracao: string;
  aprovado: boolean;
  dataAberturaChamado: string;
  dataModeracao: string;
  valorTotalEmPontos: string;
  valorTotalEmReais: string;
  quantidadeTotalParticipantes: number;
  observacao: string;
  matriz: string;
  matrizNome: string;
  matrizStatus: string;
  solicitadoEm: string;
  associado: string;
  associadoNome: string;
  associadoStatus: string;
  varejoPrime: string;
  conectaPayParaAssociado: string;
  dadosBancarios: {
    banco: string;
    agencia: string;
    conta: string;
    nome: string;
    documento: string;
  };
  statusId: number;
  motivoReprovacaoCancelamento: string;
  moderadoPor: string;
}

export interface IStatusItem {
  id: number;
  nome: string;
}

export interface IAutomaticCreditApproval {
  idApuracaoLote: number;
  observacao: string;
  aprovado: boolean;
}

export interface IAutomaticCreditState {
  readonly data: IAutomaticCreditItem[];
  readonly statusList: IStatusItem[];
  readonly dataDetail: IAutomaticCreditDetailItem;
  readonly loading: boolean;
  readonly loadingReport: boolean;
  readonly error: boolean;
  readonly success: boolean;
  readonly approved: any;
  readonly report: string;
}

export const INITIAL_STATE: IAutomaticCreditState = {
  data: [],
  statusList: [],
  dataDetail: {
    nomeCampanha: '',
    tipoApuracao: '',
    aprovado: false,
    dataAberturaChamado: '',
    valorTotalEmPontos: '',
    valorTotalEmReais: '',
    quantidadeTotalParticipantes: 0,
    observacao: '',
    matriz: '',
    matrizNome: '',
    matrizStatus: '',
    solicitadoEm: '',
    associado: '',
    associadoNome: '',
    associadoStatus: '',
    varejoPrime: '',
    conectaPayParaAssociado: '',
    dadosBancarios: {
      banco: '',
      agencia: '',
      conta: '',
      nome: '',
      documento: '',
    },
    statusId: 0,
    motivoReprovacaoCancelamento: '',
    moderadoPor: '',
    dataModeracao: '',
  },
  loading: false,
  loadingReport: false,
  error: false,
  success: false,
  approved: undefined,
  report: '',
};

const reducer: Reducer<IAutomaticCreditState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AutomaticCreditTypes.LOAD_STATUS_REQUEST:
      return { ...state, loading: true };
    case AutomaticCreditTypes.LOAD_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        statusList: action.payload.data,
      };
    case AutomaticCreditTypes.LOAD_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        statusList: [],
        approved: false,
      };
    case AutomaticCreditTypes.LOAD_PENDING_AUTOMATIC_CREDIT_REQUEST:
      return { ...state, loading: true };
    case AutomaticCreditTypes.LOAD_PENDING_AUTOMATIC_CREDIT_SUCCESS:
      return { ...state, loading: false, error: false, success: true, data: action.payload.data };
    case AutomaticCreditTypes.LOAD_PENDING_AUTOMATIC_CREDIT_FAILURE:
      return { ...state, loading: false, error: true, success: false, data: [] };
    case AutomaticCreditTypes.LOAD_AUTOMATIC_CREDIT_DETAIL_REQUEST:
      return { ...state, loading: true, approved: undefined };
    case AutomaticCreditTypes.LOAD_AUTOMATIC_CREDIT_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        dataDetail: action.payload.data,
      };
    case AutomaticCreditTypes.LOAD_AUTOMATIC_CREDIT_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        dataDetail: INITIAL_STATE.dataDetail,
      };
    case AutomaticCreditTypes.APPROVE_AUTOMATIC_CREDIT_REQUEST:
      return { ...state, loading: true };
    case AutomaticCreditTypes.APPROVE_AUTOMATIC_CREDIT_SUCCESS:
      return { ...state, approved: action.payload.data.success, success: true, loading: false };
    case AutomaticCreditTypes.APPROVE_AUTOMATIC_CREDIT_FAILURE:
      return {
        ...state,
        approved: action.payload.success,
        loading: false,
        error: true,
        success: false,
      };
    case AutomaticCreditTypes.AUTOMATIC_CREDIT_REPORT_REQUEST:
      return { ...state, loadingReport: true };
    case AutomaticCreditTypes.AUTOMATIC_CREDIT_REPORT_SUCCESS:
      return { ...state, report: action.payload.data, success: true, loadingReport: false };
    case AutomaticCreditTypes.AUTOMATIC_CREDIT_REPORT_FAILURE:
      return {
        ...state,
        report: '',
        loadingReport: false,
        error: true,
        success: false,
      };
    case AutomaticCreditTypes.AUTOMATIC_CREDIT_REPORT_RESET:
      return {
        ...state,
        report: '',
        loadingReport: false,
        error: false,
        success: false,
      };
    default:
      return state;
  }
};

export default reducer;
