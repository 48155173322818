import {
  Box,
  Button,
  createStyles,
  FormControl,
  IconButton,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Theme,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
/* eslint-disable react-hooks/exhaustive-deps */
import { CloudDownload } from '@material-ui/icons';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { TABLE_STYLE } from 'common/Constants';
import MaterialTable from 'material-table';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from 'store';
import * as KpiActions from 'store/kpi/actions';
import { showToastr } from 'store/toastr/actions';
import FileUpload from '../FileUpload/FileUpload';
import { AppKpi } from 'common/AppKpi.enum';
import cn from 'classnames';
import Utils from 'common/Utils';
import { IEanItem } from 'store/sku';
import { AnyARecord } from 'dns';
import * as SkuActions from 'store/sku/actions';
import { createTrue } from 'typescript';
import Api from 'common/Api';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(2),
      minWidth: 120,
    },
    spacing: {
      marginBottom: theme.spacing(2),
    },
    buttonActions: {
      padding: theme.spacing(1),
      minWidth: 120,
      textAlign: 'right',
      '& > button': {
        marginRight: 0,
      },
    },
  }),
);

const KpiSellinVeo = ({
  kpi,
  campaign,
  uploadKpiFileSellinVeoRequest,
  loadRequest,
  saveKpiRequest,
  loadConfiguredKpi,
  loading,
  file,
  sku,
  loadSku,
}) => {
  const classes = useStyle();
  const [labelWidth, setLabelWidth] = React.useState(0);

  const [eansRef, setEansRef] = useState([] as any);
  const [eanRefValue, setEanRefValue] = React.useState(0);

  const [refYears, setRefYears] = useState([] as any);
  const [yearReference, setYearReference] = React.useState(0);

  const [refMonths, setRefMonths] = useState([] as any);
  const [monthReference, setMonthReference] = React.useState(0);

  const [kpiTypesCampaign, setKpiTypesCampaign] = useState([] as any);
  const [kpiTypeCampaignValue, setKpiTipoCampaignValue] = React.useState(0);

  const [kpiTypesVeo, setKpiTypesVeo] = useState([] as any);
  const [kpiTypeVeoValue, setKpiTypeVeoValue] = React.useState(0);

  const [errorMessage, setErrorMessage] = useState('');

  const [validMonthActual, setvalidMonthActual] = React.useState(true);

  useEffect(() => {
    if (campaign.id > 0) {
      loadRequest();
      loadConfiguredKpi(campaign.id);
      loadSku(Number(campaign.id));
    }
  }, [campaign.id]);

  useEffect(() => {
    setRefYears(Utils.generateArrayOfYears());
    setRefMonths(Utils.generateArrayOfMonths());
    getAllTypeCampaign();
    getAllTypeVeo();
  }, []);

  useEffect(() => {
    setEansRef(sku.eans);
  }, [sku.eans]);

  const handleUpload = (file: any) => {
    const hasErrors = getErrorMessages();

    if (!hasErrors) {
      const data = new FormData();
      data.append('file', file);
      uploadKpiFileSellinVeoRequest(
        data,
        campaign.id,
        monthReference,
        yearReference,
        eanRefValue,
        kpiTypeCampaignValue,
        kpiTypeVeoValue,
      );
    }
  };

  const returnStatus = file => {
    if (!file.ativo) {
      return 'Inativo';
    }
    if (file.processado && file.sucesso) {
      return 'Sucesso';
    }
    if (!file.processado && file.ativo && !file.erro) {
      return 'Em processamento';
    }
    if (!file.processado && !file.sucesso && file.erro) {
      return file.erro;
    }
    if (!file.processado && !file.sucesso && !file.erro) {
      return 'Erro';
    }
  };

  const returnEanReferencia = file => {
    if (file.eanReferencia !== null && sku.eans && sku.eans.length > 0)
      return sku.eans.find(ean => {
        return ean.id === file.eanReferencia;
      }).nome;
  };

  const returnTypeCampaign = file => {
    if (file.tipoCampanhaId !== null && kpiTypesCampaign && kpiTypesCampaign.length > 0)
      return kpiTypesCampaign.find(tipo => {
        return tipo.id === file.tipoCampanhaId;
      }).nome;
  };

  const returnTypeVeo = file => {
    if (file.tipoVeoId !== null && kpiTypesVeo && kpiTypesVeo.length > 0)
      return kpiTypesVeo.find(tipo => {
        return tipo.id === file.tipoVeoId;
      }).nome;
  };

  const getAllTypeCampaign = async () => {
    const { data } = await Api.get(`/Shared/kpiTipoCampanha`);
    setKpiTypesCampaign(data);
  };

  const getAllTypeVeo = async () => {
    const { data } = await Api.get(`/Shared/kpiTipoVeo`);
    setKpiTypesVeo(data);
  };

  const handleMonthChange = (event: React.ChangeEvent<{ value: any }>) => {
    setMonthReference(+event.target.value);

    let monthActual = Utils.validMonthActual(+event.target.value);
    setvalidMonthActual(monthActual);
  };

  const handleYearChange = (event: React.ChangeEvent<{ value: any }>) => {
    setYearReference(+event.target.value);
  };

  const handleEansReferenceChange = (event: React.ChangeEvent<{ value: any }>) => {
    setEanRefValue(+event.target.value);
  };

  const handleKpiTypeCampaignChange = (event: React.ChangeEvent<{ value: any }>) => {
    setKpiTipoCampaignValue(+event.target.value);
  };

  const handleKpiTypeVeoChange = (event: React.ChangeEvent<{ value: any }>) => {
    setKpiTypeVeoValue(+event.target.value);
  };

  const getErrorMessages = (): boolean => {
    const isMonthValid = monthReference === 0;
    const isYearValid = yearReference === 0;
    const isEanRefValid = eanRefValue === 0;
    const isTypeCampaignValid = kpiTypeCampaignValue === 0;

    const isTyVeoValid = kpiTypeVeoValue === 0;

    setErrorMessage(
      isMonthValid
        ? 'Mês Referência é obrigatorio'
        : isYearValid
        ? 'Ano Referência é obrigatorio'
        : isEanRefValid
        ? 'Ean Referência é obrigatorio'
        : isTypeCampaignValid
        ? 'Tipo de campanha é obrigatorio'
        : isTyVeoValid
        ? 'Tipo de campanha é obrigatorio'
        : '',
    );

    return [!isMonthValid, !isYearValid, !isEanRefValid, !isTypeCampaignValid, !isTyVeoValid].some(
      e => !e,
    );
  };

  return (
    <Paper>
      <Box p="1.5em">
        <form>
          <Box width={1} className={classes.spacing}>
            <Box fontSize="h5.fontSize">Upload de KPI</Box>
            <Box className={classes.spacing}>
              Faça o upload do arquivo de KPI, códigoSAp dos elegíveis para o crédito automático{' '}
              <br /> Ao enviar um arquivo novo, os anteriores serão inativados.
            </Box>
            <FileUpload
              onUpload={handleUpload}
              acceptType={['csv']}
              loading={loading}
              text={'Selecione o arquivo...'}
            />
          </Box>

          <Grid container={true}>
            <Grid item={true} xs={2}>
              <FormControl fullWidth={true} variant="outlined" className={classes.formControl}>
                <Box className={classes.spacing}> Mês Referência </Box>
                <Select
                  required
                  native
                  value={monthReference}
                  labelWidth={labelWidth}
                  onChange={(e: React.ChangeEvent<{ value: any }>) => handleMonthChange(e)}
                  inputProps={{
                    name: 'monthReference',
                    id: 'monthReference',
                  }}
                >
                  <option value="0">Selecione...</option>

                  {refMonths.map(method => (
                    <option key={method} value={method}>
                      {method}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item={true} xs={1}>
              <FormControl
                fullWidth={true}
                variant="outlined"
                className={classes.formControl}
              ></FormControl>
            </Grid>
            <Grid item={true} xs={2}>
              <FormControl fullWidth={true} variant="outlined" className={classes.formControl}>
                <Box className={classes.spacing}> Ano Referência </Box>
                <Select
                  required
                  native
                  value={yearReference}
                  labelWidth={labelWidth}
                  onChange={(e: React.ChangeEvent<{ value: any }>) => handleYearChange(e)}
                  inputProps={{
                    name: 'yearReference',
                    id: 'yearReference',
                  }}
                >
                  <option value="0">Selecione...</option>

                  {refYears.map(method => (
                    <option key={method} value={method}>
                      {method}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item={true} xs={1}>
              <FormControl
                fullWidth={true}
                variant="outlined"
                className={classes.formControl}
              ></FormControl>
            </Grid>

            {eansRef && eansRef.length > 0 && (
              <Grid item={true} xs={2}>
                <FormControl fullWidth={true} variant="outlined" className={classes.formControl}>
                  <Box className={classes.spacing}> EAN Referência </Box>
                  <Select
                    disabled={eansRef && eansRef.length === 0}
                    required
                    native
                    value={eanRefValue}
                    labelWidth={labelWidth}
                    onChange={(e: React.ChangeEvent<{ value: any }>) =>
                      handleEansReferenceChange(e)
                    }
                    inputProps={{
                      nome: 'eanReference',
                      id: 'eanReference',
                    }}
                  >
                    <option value="0">Selecione...</option>

                    {eansRef.map(method => (
                      <option key={method.id} value={method.id}>
                        {method.nome}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>

          {!validMonthActual && (
            <Box fontSize={14} color="red">
              Você está subindo uma campanha fora do mês de vigência
            </Box>
          )}
          <Box fontSize={14} color="red">
            {errorMessage}
          </Box>

          <Grid container={true}>
            {!campaign.campanhaRegular && (
              <Grid item={true} xs={3}>
                <FormControl fullWidth={true} variant="outlined" className={classes.formControl}>
                  <Box className={classes.spacing}>Tipo Campanha</Box>
                  <Select
                    required
                    native
                    value={kpiTypeCampaignValue}
                    labelWidth={labelWidth}
                    onChange={(e: React.ChangeEvent<{ value: any }>) =>
                      handleKpiTypeCampaignChange(e)
                    }
                    inputProps={{
                      name: 'kpiTypeCampaignValue',
                      id: 'kpiTypeCampaignValue',
                    }}
                  >
                    <option value="0">Selecione...</option>

                    {kpiTypesCampaign.map(method => (
                      <option key={method.id} value={method.id}>
                        {method.nome}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            <Grid item={true} xs={1}>
              <FormControl
                fullWidth={true}
                variant="outlined"
                className={classes.formControl}
              ></FormControl>
            </Grid>

            <Grid item={true} xs={4}>
              <FormControl fullWidth={true} variant="outlined" className={classes.formControl}>
                <Box className={classes.spacing}>VEO</Box>
                <Select
                  required
                  native
                  value={kpiTypeVeoValue}
                  labelWidth={labelWidth}
                  onChange={(e: React.ChangeEvent<{ value: any }>) => handleKpiTypeVeoChange(e)}
                  inputProps={{
                    name: 'kpiTipoCampaignValue',
                    id: 'kpiTipoCampaignValue',
                  }}
                >
                  <option value="0">Selecione...</option>

                  {kpiTypesVeo.map(method => (
                    <option key={method.id} value={method.id}>
                      {method.nome}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {campaign.kpiTipo >= AppKpi.SELLIN && file && file.length > 0 && (
            <Box width={1} className={classes.spacing} mt="1em">
              <Box fontSize="h5.fontSize">Arquivos de KPI</Box>
              <Box className={classes.spacing}>Lista de arquivos de KPI vinculados a campanha:</Box>
              <MaterialTable
                options={{
                  search: false,
                  paging: false,
                  showTitle: false,
                  toolbar: false,
                  headerStyle: { border: '1px solid' },
                }}
                columns={[
                  {
                    title: 'Data envio',
                    render: (rowData: any) => {
                      return moment(rowData?.dataInclusao).format('DD/MM/YYYY - HH:mm:SS');
                    },
                    cellStyle: { ...TABLE_STYLE, border: '1px solid', width: '10%' },
                  },
                  {
                    title: 'Usuário',
                    field: 'usuario',
                    cellStyle: { ...TABLE_STYLE, border: '1px solid', width: '10%' },
                  },
                  {
                    title: 'Baixar',
                    cellStyle: {
                      ...TABLE_STYLE,
                      border: '1px solid',
                      width: '5%',
                    },
                    render: ({ downloadUrl, retorno }) => (
                      <>
                        {downloadUrl && downloadUrl !== '' && (
                          <IconButton onClick={() => window.open(downloadUrl)}>
                            <CloudUploadIcon />
                          </IconButton>
                        )}
                        {retorno && retorno !== '' && (
                          <IconButton onClick={() => window.open(retorno)}>
                            <CloudDownload />
                          </IconButton>
                        )}
                      </>
                    ),
                  },
                  {
                    title: 'Status',
                    cellStyle: { ...TABLE_STYLE, border: '1px solid', width: '10%' },
                    render: rowData => returnStatus(rowData),
                  },
                  {
                    title: 'Ean',
                    render: rowData => returnEanReferencia(rowData),
                    cellStyle: { ...TABLE_STYLE, border: '1px solid', width: '10%' },
                  },
                  {
                    title: 'Tipo Campanha',
                    render: rowData => returnTypeCampaign(rowData),
                    cellStyle: { ...TABLE_STYLE, border: '1px solid', width: '10%' },
                  },
                  {
                    title: 'VEO',
                    render: rowData => returnTypeVeo(rowData),
                    cellStyle: { ...TABLE_STYLE, border: '1px solid', width: '10%' },
                  },
                ]}
                isLoading={false}
                data={file}
              />
            </Box>
          )}
        </form>
      </Box>
    </Paper>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  sku: state.sku.data,
  campaign: state.campaignEdit.data,
  kpi: state.kpi.data,
  file: state.kpi.file,
  loading: state.kpi.loading || state.campaignEdit.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...KpiActions, showToastr, ...SkuActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(KpiSellinVeo);
