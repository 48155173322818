import { Reducer } from 'redux';

export enum UserEditTypes {
  LOAD_REQUEST = '@userEdit/LOAD_REQUEST',
  LOAD_SUCCESS = '@userEdit/LOAD_SUCCESS',
  LOAD_FAILURE = '@userEdit/LOAD_FAILURE',

  CREATE_REQUEST = '@userEdit/CREATE_REQUEST',
  CREATE_SUCCESS = '@userEdit/CREATE_SUCCESS',
  CREATE_FAILURE = '@userEdit/CREATE_FAILURE',

  UPDATE_REQUEST = '@userEdit/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@userEdit/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@userEdit/UPDATE_FAILURE',

  SETFILTER_COUNT_REQUEST = '@userEdit/SETFILTER_COUNT_REQUEST',
}

export interface IUser {
  nome: string;
  email: string;
  telefone: string;
}

export interface IUserCreateRequest {
  nome: string;
  email: string;
  telefone: string;
}

export interface IUserEdit extends IUser {
  id: string | undefined;
}

export interface IUserEditState {
  readonly data: IUserEdit;
  readonly filterApplied: number;
  readonly filterParticipantCount: number;
  readonly loading: boolean;
  readonly error: boolean;
}

export const INITIAL_STATE: IUserEditState = {
  data: {
    id: '',
    nome: '',
    email: '',
    telefone: '',
  },
  filterParticipantCount: 0,
  filterApplied: 0,
  loading: false,
  error: false,
};

const reducer: Reducer<IUserEditState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserEditTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case UserEditTypes.LOAD_SUCCESS:
      return { ...state, loading: false, error: false, data: action.payload.data };
    case UserEditTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true, data: { ...INITIAL_STATE.data } };
    case UserEditTypes.CREATE_REQUEST:
      return { ...state, loading: true };
    case UserEditTypes.CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: { ...state.data, ...action.payload, id: action.payload.id },
      };
    case UserEditTypes.CREATE_FAILURE:
      return { ...state, loading: false };
    case UserEditTypes.UPDATE_REQUEST:
      return { ...state, loading: true };
    case UserEditTypes.UPDATE_SUCCESS:
      return { ...state, loading: false, data: { ...state.data } };
    case UserEditTypes.UPDATE_FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default reducer;
