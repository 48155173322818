import { Reducer } from 'redux';

export enum TrackImportFileTypes {
  LOAD_REQUEST = '@TrackImport/LOAD_REQUEST',
  LOAD_SUCCESS = '@TrackImport/LOAD_SUCCESS',
  LOAD_FAILURE = '@TrackImport/LOAD_FAILURE',
  LOAD_DETAILS_REQUEST = '@TrackImport/LOAD_DETAILS_REQUEST',
  LOAD_DETAILS_SUCCESS = '@TrackImport/LOAD_DETAILS_SUCCESS',
  LOAD_DETAILS_FAILURE = '@TrackImport/LOAD_DETAILS_FAILURE',
  UPLOAD_REQUEST = '@TrackImport/UPLOAD_REQUEST',
  UPLOAD_SUCCESS = '@TrackImport/UPLOAD_SUCCESS',
  UPLOAD_FAILURE = '@TrackImport/UPLOAD_FAILURE',
  DELETE_REQUEST = '@TrackImport/DELETE_REQUEST',
  DELETE_SUCCESS = '@TrackImport/DELETE_SUCCESS',
  DELETE_FAILURE = '@TrackImport/DELETE_FAILURE',
}

export interface ITrackImportDataFile {
  id: number;
  dataUpload: string;
  nomeArquivo: string;
}

export interface ITrackImportDetailListDataFile {
  numeroLinha: number;
  sap: number;
  descricaoErro: string;
}

export interface ITrackImportFileState {
  data: ITrackImportDataFile[];
  detailListData: {
    data: ITrackImportDetailListDataFile[];
    loading: boolean;
    error: boolean;
  };
  loading: boolean;
  error: boolean;
}

const INITIAL_STATE: ITrackImportFileState = {
  data: [],
  detailListData: {
    data: [],
    loading: false,
    error: false,
  },
  loading: false,
  error: false,
};

const reducer: Reducer<ITrackImportFileState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TrackImportFileTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case TrackImportFileTypes.LOAD_SUCCESS:
      return { ...state, loading: false, data: [...action.payload.data] };
    case TrackImportFileTypes.LOAD_FAILURE:
      return { ...state, loading: false };
    case TrackImportFileTypes.LOAD_DETAILS_REQUEST:
      return {
        ...state,
        detailListData: {
          ...state.detailListData,
          loading: true,
        },
      };
    case TrackImportFileTypes.LOAD_DETAILS_SUCCESS:
      return {
        ...state,
        detailListData: {
          ...state.detailListData,
          data: [...action.payload.data],
          loading: false,
        },
      };
    case TrackImportFileTypes.LOAD_DETAILS_FAILURE:
      return {
        ...state,
        detailListData: {
          ...state.detailListData,
          loading: false,
        },
      };
    case TrackImportFileTypes.UPLOAD_REQUEST:
      return { ...state, loading: true };
    case TrackImportFileTypes.UPLOAD_SUCCESS:
      return { ...state, loading: false, data: [...state.data, { ...action.payload.data.data }] };
    case TrackImportFileTypes.UPLOAD_FAILURE:
      return { ...state, loading: false };
    case TrackImportFileTypes.DELETE_REQUEST:
      return { ...state, loading: true };
    case TrackImportFileTypes.DELETE_SUCCESS:
      return { ...state, loading: false, data: [...state.data] };
    case TrackImportFileTypes.DELETE_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default reducer;
