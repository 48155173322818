import React, { useEffect } from 'react';
import Wrap from 'components/Wrap/Wrap';
import { Breadcrumbs, Typography, Link, Button, Box, IconButton } from '@material-ui/core';
import { ApplicationState } from 'store';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as ChainingNetworkActions from 'store/chainingNetwork/list/actions';
import { IUploadChainingNetworkListItem } from 'store/chainingNetwork/list';
import { TABLE_STYLE } from 'common/Constants';
import EditIcon from '@material-ui/icons/Edit';
import { RouteComponentProps } from 'react-router-dom';
import GenericTable from 'components/GenericTable/GenericTable';
import { CloudDownload } from '@material-ui/icons';

interface IProps extends RouteComponentProps {
  uploadChainingNetworkList: IUploadChainingNetworkListItem[];
  loading: boolean;
  loadRequest: () => void;
}

const ChainingNetworkList: React.FC<IProps> = ({
  uploadChainingNetworkList,
  loadRequest,
  loading,
  history,
}) => {
  useEffect(() => {
    loadRequest();
  }, []);
  const handleOnEdit = (id: number) => history.push(`/ChainingNetwork/edit/${id}`);
  const handleOnCreate = () => history.push(`/ChainingNetwork/create`);

  return (
    <>
      <Wrap>
        <Breadcrumbs>
          <Link color="inherit" onClick={() => history.push('/')}>
            Home
          </Link>
          <Typography color="textPrimary">Gestão de Uploads</Typography>
        </Breadcrumbs>
      </Wrap>

      <Box m="0 0 1.5em 0">
        <Button variant="contained" color="primary" onClick={handleOnCreate}>
          Criar Upload
        </Button>
      </Box>

      <GenericTable
        title="Arquivos"
        isLoading={loading}
        data={uploadChainingNetworkList}
        options={{
          paging: true,
          search: true,
          pageSize: 10,
          sorting: false,
        }}
        columns={[
          {
            searchable: true,
            title: 'Nome Arquivo',
            field: 'nome_Arquivo_Original',
            cellStyle: { ...TABLE_STYLE, width: '20%' },
          },
          {
            searchable: false,
            title: 'Data Processamento',
            field: 'dt_Processamento_Original',
            cellStyle: { ...TABLE_STYLE, width: '20%' },
          },
          {
            searchable: false,
            title: 'Status',
            render: rowData => {
              return `${rowData.enriquecido ? 'Processado' : 'Aguardando Processamento'}`;
            },
            cellStyle: { ...TABLE_STYLE, width: '15%' },
          },
          {
            searchable: false,
            title: 'Ações',
            cellStyle: {
              width: '10%',
              ...TABLE_STYLE,
            },
            render: ({ id, url_Arquivo_Alterado, enriquecido }) => (
              <>
                <IconButton
                  disabled={!enriquecido}
                  onClick={() => window.open(url_Arquivo_Alterado)}
                >
                  <CloudDownload />
                </IconButton>
                <IconButton disabled={enriquecido} color="primary" onClick={() => handleOnEdit(id)}>
                  <EditIcon fontSize="small" />
                </IconButton>
              </>
            ),
          },
        ]}
      />
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  uploadChainingNetworkList: state.uploadChainingNetworkList.data,
  loading: state.uploadChainingNetworkList.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(ChainingNetworkActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChainingNetworkList);
