import { action } from 'typesafe-actions';
import { TotalPrimeirosAcessosClusterTypes, IRealtimeItem } from './';

export const loadRealtimeTotalPrimeirosAcessosClusterRequest = () =>
  action(TotalPrimeirosAcessosClusterTypes.LOAD_REQUEST);
export const loadRealtimeTotalPrimeirosAcessosClusterSuccess = (
  data: IRealtimeItem[],
  atualizacao: string,
) => action(TotalPrimeirosAcessosClusterTypes.LOAD_SUCCESS, { data, atualizacao });
export const loadRealtimeTotalPrimeirosAcessosClusterFailure = () =>
  action(TotalPrimeirosAcessosClusterTypes.LOAD_FAILURE);
