import { action } from 'typesafe-actions';
import { GenerateBankSlipTypes } from '.';

export const loadAllUploadBankSlip = () => action(GenerateBankSlipTypes.LOAD_REQUEST);
export const loadAllUploadBankSlipSuccess = data =>
  action(GenerateBankSlipTypes.LOAD_SUCCESS, { data });
export const loadAllUploadBankSlipFailure = () => action(GenerateBankSlipTypes.LOAD_FAILURE);

export const loadDetailUploadBankSlip = (id: number) =>
  action(GenerateBankSlipTypes.LOAD_DETAIL_REQUEST, { id });
export const loadDetailUploadBankSlipSuccess = data =>
  action(GenerateBankSlipTypes.LOAD_DETAIL_SUCCESS, { data });
export const loadDetailUploadBankSlipFailure = () =>
  action(GenerateBankSlipTypes.LOAD_DETAIL_FAILURE);

export const uploadSingleBankSlipByFile = (file: FormData) =>
  action(GenerateBankSlipTypes.UPLOAD_REQUEST, { file });
export const uploadSingleBankSlipByFileSuccess = data =>
  action(GenerateBankSlipTypes.UPLOAD_SUCCESS, { data });
export const uploadSingleBankSlipByFileFailure = () => action(GenerateBankSlipTypes.UPLOAD_FAILURE);
