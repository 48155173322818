import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from 'store';
import {
  Box,
  Grid,
  FormControl,
  makeStyles,
  Theme,
  TextField,
  createStyles,
  Typography,
  Paper,
  FormControlLabel,
  Checkbox,
  Select,
  FormLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import * as ReentryConfigActions from 'store/reentryConfigStore/actions';
import { IPromoDetail, TipoInclusaoParticipante } from 'store/reentryConfigStore/index';
import {
  ETipoPromocaoIncentivo,
  ETipoEmissaoBoletoInadimplente,
} from 'pages/Payments/IncentivePromotion/IncentivePromoConfig';

interface IParams {
  id?: string;
}

interface ICampaignDetailVisualize {
  LoadPromotionCampaignById: (id: any) => void;
  promoDetail: IPromoDetail;
  promoDetailLoading: boolean;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 120,
    },
    grid: {
      margin: 'auto',
    },
    paddingLeft: {
      paddingLeft: '12px',
    },
    flex: {
      display: 'flex',
    },
    error: {
      color: '#ff0000',
    },
  }),
);

const CampaignDetailVisualize: React.FC<ICampaignDetailVisualize &
  RouteComponentProps<IParams>> = ({
  history,
  match,
  LoadPromotionCampaignById,
  promoDetail,
  promoDetailLoading,
}) => {
  const classes = useStyle();

  useEffect(() => {
    if (match.params.id) {
      LoadPromotionCampaignById(parseInt(match.params.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match]);

  return (
    <Paper elevation={2}>
      <Box m="1.5em">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" component="h2">
              Visualização da Promoção de Incentivo
            </Typography>
          </Grid>
          {promoDetailLoading ? (
            <Grid item xs={12}>
              <Typography color="inherit">Carregando...</Typography>
            </Grid>
          ) : (
            <>
              <Grid item xs={4}>
                <FormControl fullWidth variant="outlined">
                  <Select
                    required
                    native
                    disabled
                    value={promoDetail.promocaoIncentivo.tipoPromocaoIncentivo}
                  >
                    <option value={ETipoPromocaoIncentivo.INADIMPLENCIA_90}>
                      Inadimplência 90
                    </option>
                    <option value={ETipoPromocaoIncentivo.REINGRESSO}>Reingresso</option>
                  </Select>
                </FormControl>
                {promoDetail.promocaoIncentivo.tipoPromocaoIncentivo === 1 && (
                  <FormControl required disabled fullWidth style={{ padding: '10px' }}>
                    <FormLabel>Modelo de emissão dos boletos</FormLabel>
                    <RadioGroup
                      row
                      aria-label="modelBankSlip"
                      name="modelBankSlip"
                      value={promoDetail.promocaoIncentivo.tipoEmissaoBoletoInadimplente}
                    >
                      <FormControlLabel
                        value={ETipoEmissaoBoletoInadimplente.EMISSAO_SOLICITACAO}
                        disabled
                        control={<Radio />}
                        label="Emissão por Solicitação do Participante"
                      />
                      <FormControlLabel
                        disabled
                        value={ETipoEmissaoBoletoInadimplente.EMISSAO_FORCADA}
                        control={<Radio />}
                        label="Emissão Forçada pelo Administrador"
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth className={classes.formControl}>
                  <TextField
                    disabled
                    value={promoDetail.promocaoIncentivo.descricao}
                    label="Descrição da Campanha"
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth className={classes.formControl}>
                  <TextField
                    disabled
                    value={promoDetail.promocaoIncentivo.quantidadeMesesExpiracao}
                    label="Meses para expiração de pontos"
                    variant="outlined"
                    type="number"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl fullWidth className={classes.formControl}>
                  <TextField
                    required
                    disabled
                    type="date"
                    id="startDate"
                    name="vigenciaDe"
                    value={promoDetail.promocaoIncentivo.vigenciaInicio.substring(0, 10)}
                    label="Início da Vigência"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl fullWidth className={classes.formControl}>
                  <TextField
                    required
                    disabled
                    type="date"
                    id="startDate"
                    name="vigenciaDe"
                    value={promoDetail.promocaoIncentivo.vigenciaFim.substring(0, 10)}
                    label="Fim da Vigência"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl required fullWidth>
                  <FormLabel>Qual será o modelo inclusão de participantes?</FormLabel>
                  <RadioGroup
                    row
                    aria-label="modelInputParticipant"
                    name="modelInputParticipant"
                    value={promoDetail.promocaoIncentivo.tipoInclusaoParticipante}
                  >
                    <FormControlLabel
                      value={TipoInclusaoParticipante.UPLOAD}
                      disabled
                      control={<Radio />}
                      label="Upload via arquivo"
                    />
                    <FormControlLabel
                      value={TipoInclusaoParticipante.PDV}
                      disabled
                      control={<Radio />}
                      label="Filtro PDV"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              {promoDetail.promocaoIncentivoConfiguracoes?.map((promo, index) => {
                if (index === 0) {
                  return (
                    <React.Fragment key={index}>
                      <Grid item xs={4}>
                        <FormControl fullWidth className={classes.formControl}>
                          <TextField disabled value={promo.descricao} variant="outlined" />
                        </FormControl>
                      </Grid>

                      {promo.valor === '' ? (
                        <>
                          <Grid item xs={4}>
                            <FormControl fullWidth className={classes.formControl}>
                              <TextField disabled value={promo.opcao} variant="outlined" />
                            </FormControl>
                          </Grid>
                          <Grid item xs={4} />
                        </>
                      ) : (
                        <>
                          <Grid item xs={4}>
                            <FormControl fullWidth className={classes.formControl}>
                              <TextField
                                disabled
                                value={promo.valor}
                                label="Quantidade Meses"
                                variant="outlined"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={4}>
                            <FormControl fullWidth className={classes.formControl}>
                              <TextField disabled value={promo.opcao} variant="outlined" />
                            </FormControl>
                          </Grid>
                        </>
                      )}
                    </React.Fragment>
                  );
                } else {
                  if (promo.descricao === 'Abono') {
                    return (
                      <React.Fragment key={index}>
                        <Grid item xs={4}>
                          <FormControl fullWidth className={classes.formControl}>
                            <TextField disabled label="Varejo ganha" variant="outlined" />
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl fullWidth className={classes.formControl}>
                            <TextField disabled value={promo.descricao} variant="outlined" />
                          </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                          <FormControl fullWidth className={classes.formControl}>
                            <TextField
                              disabled
                              value={promo.valor === '99' ? '' : promo.valor}
                              label={promo.opcao}
                              variant="outlined"
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={2} style={{ alignSelf: 'center' }}>
                          <FormControl>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled
                                  checked={promo.valor === '99' ?? false}
                                  color="primary"
                                />
                              }
                              label="Todos os meses"
                            />
                          </FormControl>
                        </Grid>
                      </React.Fragment>
                    );
                  } else {
                    return (
                      <React.Fragment key={index}>
                        <Grid item xs={4}>
                          <FormControl fullWidth className={classes.formControl}>
                            <TextField disabled label="Varejo ganha" variant="outlined" />
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl fullWidth className={classes.formControl}>
                            <TextField disabled value={promo.descricao} variant="outlined" />
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl fullWidth className={classes.formControl}>
                            <TextField
                              disabled
                              value={promo.valor}
                              label={promo.opcao}
                              variant="outlined"
                            />
                          </FormControl>
                        </Grid>
                      </React.Fragment>
                    );
                  }
                }
              })}

              <Grid item xs={12} />
            </>
          )}
        </Grid>
      </Box>
    </Paper>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  promoDetail: state.reentryConfigStore.promoDetailById.data,
  promoDetailLoading: state.reentryConfigStore.promoDetailById.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(ReentryConfigActions, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CampaignDetailVisualize));
