import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import {
  loadMailing as loadMalingAction,
  loadMailingSuccess,
  loadMailingFailure,
  uploadBenefitMailingSuccess,
  uploadBenefitMailingFailure,
  deleteMailingSuccess,
  deleteMailingFailure,
} from './actions';
import { showToastr } from 'store/toastr/actions';
import { AppMessages } from 'common/AppMessages.enum';
import { MODULE_TYPE } from 'common/Constants';
import store from 'store';

export function* loadBenefitMailing(action) {
  try {
    const { data } = yield call(API.get, `beneficio/${action.payload.id}/ListaParticipanteArquivo`);
    yield put(loadMailingSuccess(data.arquivos));
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
    yield put(loadMailingFailure());
  }
}

export function* uploadBenefitMailing(action) {
  try {
    const { data } = yield call(API.post, action.payload.uploadUrl, action.payload.data);
    if (data.sucesso) {
      yield put(uploadBenefitMailingSuccess(data));
      yield put(
        showToastr({ type: 'success', message: AppMessages.MAILING_UPLOAD_SUCCESS, open: true }),
      );
    } else {
      yield put(
        showToastr({ type: 'error', message: AppMessages.ERROR_MAILING_PROCCESS, open: true }),
      );
      yield put(uploadBenefitMailingFailure());
    }
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
    yield put(uploadBenefitMailingFailure());
  }
}

export function* deleteBenefitMailing(action) {
  try {
    const { data } = yield call(API.delete, action.payload.deleteUrl);
    yield put(deleteMailingSuccess(data.arquivos));
    const module = {
      type: MODULE_TYPE.BENEFIT,
      id: Number(store.getState().benefit.data.id),
    };
    yield put(loadMalingAction(module.id, module.type));
    yield put(
      showToastr({ type: 'success', message: AppMessages.MAILING_DELETE_SUCCESS, open: true }),
    );
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
    yield put(deleteMailingFailure());
  }
}
