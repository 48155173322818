import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { ApplicationState } from 'store';
import * as PaymentsActions from 'store/payments/actions';
import * as CampaignListActions from 'store/campaign/list/actions';
import {
  Button,
  Box,
  Grid,
  IconButton,
  Tooltip,
  createStyles,
  makeStyles,
  createTheme,
  Checkbox,
  TextField,
  FormControl,
  FormControlLabel,
  Collapse,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Utils from 'common/Utils';
import { ptBR } from 'date-fns/locale';
import GenericTable from 'components/GenericTable/GenericTable';
import { AddCircle, Visibility, CheckBox, CloudUpload, Edit } from '@material-ui/icons';
import { TABLE_STYLE } from 'common/Constants';
import { ICuttOffData } from 'store/payments/index';
import FilterFileModal, { ModalConfigProps } from '../FilterFileModal/FilterFileModal';
import HistoricUploadTable from '../HistoricUploadTable/HistoricUploadTable';
import HistoricPDVTable from '../HistoricPDVTable/HistoricPDVTable';
import { ThemeProvider } from '@material-ui/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';

const datePickerTheme = createTheme({
  overrides: {
    MuiOutlinedInput: {
      input: {
        textTransform: 'capitalize',
      },
    },
  },
});

const useStyle = makeStyles(() =>
  createStyles({
    box: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '1em',
    },
    ml_2: {
      marginLeft: '2em',
      textTransform: 'capitalize',
    },
    includeButton: {
      marginLeft: '2em',
      alignSelf: 'center',
    },
    helpButton: {
      fontWeight: 'normal',
      textTransform: 'initial',
    },
  }),
);

interface ICampaignsListProps {
  nome: string;
  id: number;
}

interface ICuttOffConfig {
  cuttOffDate: Date | null;
  monthYearReference: Date | null;
  campanhas: any[];
  allParticipants: boolean;
}

interface CuttOffDateProps {
  campaignsList: ICampaignsListProps[];
  loadingCampaignsList: boolean;
  loadCuttOffList(): void;
  loadCampaignListRequest(): void;
  createCuttOffDateRequest(data): void;
  updateCuttOffDateRequest(data): void;
  cuttOffDateList: ICuttOffData[];
  loadingCuttOffList: boolean;
}

const CuttOffDate: React.FC<CuttOffDateProps> = ({
  campaignsList,
  loadingCampaignsList,
  loadCuttOffList,
  loadCampaignListRequest,
  createCuttOffDateRequest,
  updateCuttOffDateRequest,
  cuttOffDateList,
  loadingCuttOffList,
}) => {
  const classes = useStyle();
  const tableRef = React.createRef();
  const [openAlert, setOpenAlert] = useState(false);
  const [filteredCampaignList, setFilteredCampaignList] = useState<ICampaignsListProps[] | []>([]);
  const [modalConfig, setModalConfig] = useState<ModalConfigProps>({
    open: false,
    type: '',
    id: 0,
    readOnly: false,
  });
  const [cuttOffConfig, setCuttOffConfig] = useState<ICuttOffConfig>({
    cuttOffDate: null,
    monthYearReference: null,
    campanhas: [],
    allParticipants: false,
  });

  const modalProps = {
    modalConfig,
    setModalConfig,
  };

  const tableIcons = {
    Edit: () => <Edit color="primary" />,
  };

  useEffect(() => {
    loadCuttOffList();
    loadCampaignListRequest();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!loadingCampaignsList && !!campaignsList.length) {
      return setFilteredCampaignList(
        campaignsList.map(item => {
          return { id: item.id, nome: item.nome };
        }),
      );
    }
    // eslint-disable-next-line
  }, [loadingCampaignsList]);

  const disableWeekends = date => {
    return date.getDay() === 0 || date.getDay() === 6;
  };

  const formatMonth = number => {
    return ('0' + number).slice(-2);
  };

  const checkDate = date => {
    if (date) {
      let day = new Date(date);

      if (date.getDay() === 0 || date.getDay() === 6) {
        for (let i = 1; day.getDay() === 0 || day.getDay() === 6; i++) {
          day = new Date(date.setDate(date.getDate() + 1));
        }
        setCuttOffConfig({ ...cuttOffConfig, cuttOffDate: day });
        setOpenAlert(true);

        setTimeout(() => {
          setOpenAlert(false);
        }, 2000);

        return;
      } else {
        return setCuttOffConfig({ ...cuttOffConfig, cuttOffDate: day });
      }
    }
  };

  const handlePost = () => {
    if (
      cuttOffConfig.monthYearReference !== null ||
      cuttOffConfig.cuttOffDate !== null ||
      !!cuttOffConfig.campanhas.length
    ) {
      let data = {
        dataCorte: cuttOffConfig.cuttOffDate,
        mesReferencia:
          cuttOffConfig.monthYearReference && cuttOffConfig.monthYearReference.getMonth() + 1,
        anoReferencia:
          cuttOffConfig.monthYearReference && cuttOffConfig.monthYearReference.getFullYear(),
        campanhaId: cuttOffConfig.campanhas.map(x => {
          return x.id;
        }),
        todosParticipantes: cuttOffConfig.allParticipants,
      };

      return createCuttOffDateRequest(data);
    }
  };

  const handleModal = (type: string, id: number, readOnly: boolean, tableDataId?: boolean) => {
    setModalConfig({ open: true, type: type, id, readOnly, tableDataId });
  };

  return (
    <Box>
      <Box className={classes.box}>
        <Alert severity="warning">
          A Data de Corte somente será permitida para os meses posteriores ao "Mês/Ano referência".
        </Alert>
      </Box>
      <Box className={classes.box}>
        <Grid container>
          <Grid item>
            <ThemeProvider theme={datePickerTheme}>
              <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                <KeyboardDatePicker
                  shouldDisableDate={disableWeekends}
                  variant="inline"
                  inputVariant="outlined"
                  label="Data de Corte"
                  autoOk
                  value={cuttOffConfig.cuttOffDate}
                  placeholder="dd/mm/aaaa"
                  onChange={date => checkDate(date)}
                  invalidDateMessage="Data inválida"
                  format="dd/MM/yyyy"
                />
              </MuiPickersUtilsProvider>
            </ThemeProvider>
            <Collapse in={openAlert}>
              <Alert severity="info">Alteramos a data para próximo dia útil!</Alert>
            </Collapse>
          </Grid>

          <Grid item>
            <ThemeProvider theme={datePickerTheme}>
              <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className={classes.ml_2}
                  views={['year', 'month']}
                  label="Mês Ano Referência"
                  variant="inline"
                  inputVariant="outlined"
                  autoOk
                  value={cuttOffConfig.monthYearReference}
                  format="MM/yyyy"
                  onChange={date =>
                    setCuttOffConfig({ ...cuttOffConfig, monthYearReference: date })
                  }
                />
              </MuiPickersUtilsProvider>
            </ThemeProvider>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container xs={12} style={{ padding: '20px 0' }}>
            <Grid item>
              <Autocomplete
                style={{ width: 610, alignSelf: 'center' }}
                multiple
                limitTags={1}
                size="small"
                disableCloseOnSelect
                noOptionsText="Não há registros encontrados"
                options={filteredCampaignList}
                onChange={(event: any, value: any) => {
                  setCuttOffConfig({ ...cuttOffConfig, campanhas: value });
                }}
                getOptionLabel={option => option.nome}
                renderOption={(option, { selected }) => {
                  return (
                    <Fragment>
                      <Checkbox
                        icon={<CheckBox fontSize="small" />}
                        checkedIcon={<CheckBox fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.nome}
                    </Fragment>
                  );
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Campanhas Ativas"
                    placeholder="Consulte opções digitando aqui..."
                  />
                )}
              />
            </Grid>
            <Grid item>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ marginLeft: '2em' }}
                      checked={cuttOffConfig.allParticipants}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setCuttOffConfig({ ...cuttOffConfig, allParticipants: e.target.checked });
                      }}
                      name="ativo"
                      color="primary"
                    />
                  }
                  label="Todos participantes"
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button
            style={{ marginLeft: '0px' }}
            className={classes.includeButton}
            onClick={handlePost}
            variant="contained"
            color="primary"
            size="large"
            disabled={
              cuttOffConfig.monthYearReference === null ||
              cuttOffConfig.cuttOffDate === null ||
              !cuttOffConfig.campanhas.length
            }
          >
            Incluir
          </Button>
        </Grid>
      </Box>

      <Grid container style={{ marginTop: '2em' }}>
        <Grid item xs={12}>
          {!!cuttOffDateList.length && (
            <GenericTable
              localization={{
                body: {
                  editTooltip: 'Editar',
                  editRow: {
                    saveTooltip: 'Salvar',
                    cancelTooltip: 'Cancelar',
                  },
                  emptyDataSourceMessage: 'Não foi encontrado registros.',
                },
                header: {
                  actions: 'Ações',
                },
              }}
              icons={tableIcons}
              tableRef={tableRef}
              title="Histórico registro de Datas de Corte"
              isLoading={loadingCuttOffList || loadingCampaignsList}
              data={cuttOffDateList}
              options={{
                paging: true,
                search: true,
                pageSize: 20,
                emptyRowsWhenPaging: false,
                actionsColumnIndex: 4,
                detailPanelType: 'single',
              }}
              columns={[
                {
                  title: 'Data de Corte',
                  field: 'dataCorte',
                  type: 'datetime',
                  sorting: true,
                  customFilterAndSearch: (filter, rowData) => {
                    let stringDate = Utils.setData(rowData.dataCorte);
                    return stringDate.includes(filter);
                  },
                  customSort: (a, b) =>
                    new Date(a.dataCorte).getTime() - new Date(b.dataCorte).getTime(),
                  render: rowData => {
                    return `${Utils.setData(rowData.dataCorte)}`;
                  },
                  editComponent: props => {
                    return (
                      <ThemeProvider theme={datePickerTheme}>
                        <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            shouldDisableDate={disableWeekends}
                            label="Data de Corte"
                            autoOk
                            value={props.rowData.dataCorte}
                            placeholder="dd/mm/aaaa"
                            onChange={date => props.onChange(date)}
                            format="dd/MM/yyyy"
                          />
                        </MuiPickersUtilsProvider>
                      </ThemeProvider>
                    );
                  },
                  cellStyle: { ...TABLE_STYLE },
                },
                {
                  title: 'Mês/Ano Referência',
                  field: 'anoReferencia',
                  editable: 'never',
                  align: 'center',
                  sorting: true,
                  defaultSort: 'desc',
                  customSort: (a, b) => {
                    return (
                      new Date().setFullYear(a.anoReferencia, a.mesReferencia - 1, 1) -
                      new Date().setFullYear(b.anoReferencia, b.mesReferencia - 1, 1)
                    );
                  },
                  type: 'numeric',
                  render: rowData => {
                    return (
                      formatMonth(rowData.mesReferencia).slice(-2) + '/' + rowData.anoReferencia
                    );
                  },
                  cellStyle: { ...TABLE_STYLE },
                },
                {
                  title: 'Campanhas Relacionadas',
                  field: 'campanhas',
                  sorting: false,
                  editComponent: props => {
                    return (
                      <Autocomplete
                        style={{ width: 600 }}
                        multiple
                        limitTags={1}
                        size="small"
                        disableCloseOnSelect
                        noOptionsText="Não há registros encontrados"
                        options={filteredCampaignList}
                        onChange={(event: any, value: any, reason, option) => {
                          if (reason === 'remove-option') {
                            props.rowData.campanha = value;
                            return;
                          }

                          let newValue = [...value];
                          props.rowData.campanha = newValue;
                        }}
                        getOptionLabel={option => option.nome}
                        defaultValue={props.value}
                        renderOption={(option, { selected }) => {
                          return (
                            <Fragment>
                              <Checkbox
                                id={option.id}
                                icon={<CheckBox fontSize="small" />}
                                checkedIcon={<CheckBox fontSize="small" />}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.nome}
                            </Fragment>
                          );
                        }}
                        renderInput={params => (
                          <TextField {...params} placeholder="Consulte opções digitando aqui..." />
                        )}
                      />
                    );
                  },
                  render: rowData => {
                    return !!rowData.campanha.length ? (
                      <>
                        <Tooltip
                          title={
                            <Fragment>
                              <ul>
                                {rowData.campanha.map(item => (
                                  <li>{item.nome}</li>
                                ))}
                              </ul>
                            </Fragment>
                          }
                          arrow
                        >
                          <Button
                            disableFocusRipple
                            disableElevation
                            disableRipple
                            className={classes.helpButton}
                            size="small"
                            endIcon={<Visibility />}
                          >
                            Ver campanhas...
                          </Button>
                        </Tooltip>
                      </>
                    ) : (
                      ''
                    );
                  },
                  cellStyle: { ...TABLE_STYLE },
                },
                {
                  title: 'Status',
                  field: 'ativo',
                  sorting: false,
                  editable: 'never',
                  render: rowData => {
                    return `${rowData.ativo ? 'Ativo' : 'Inativo'}`;
                  },
                  cellStyle: { ...TABLE_STYLE },
                },
                {
                  title: '',
                  searchable: false,
                  editable: 'never',
                  sorting: false,
                  cellStyle: {
                    width: '15%',
                    padding: '5px 0px',
                  },
                  render: ({ id }) => (
                    <>
                      <Tooltip title="Incluir ou editar participantes via filtro">
                        <IconButton color="primary" onClick={() => handleModal('pdv', id, false)}>
                          <AddCircle />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Incluir participantes via arquivo">
                        <IconButton color="primary" onClick={() => handleModal('file', id, false)}>
                          <CloudUpload />
                        </IconButton>
                      </Tooltip>
                    </>
                  ),
                },
              ]}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    let data = {
                      id: newData.id,
                      campanhaId: newData.campanha.map(x => {
                        return x.id;
                      }),
                      dataCorte: newData.dataCorte,
                      mesReferencia: new Date(oldData.dataCorte).getMonth(),
                      anoReferencia: new Date(oldData.dataCorte).getFullYear(),
                    };

                    resolve(data);
                  }).then(result => {
                    updateCuttOffDateRequest(result);
                  }),
              }}
              detailPanel={[
                {
                  tooltip: 'Exibir históricos de inclusão de participantes',
                  render: rowData => {
                    return (
                      <>
                        <HistoricPDVTable id={rowData.id} handleModal={handleModal} />
                        <HistoricUploadTable id={rowData.id} />
                      </>
                    );
                  },
                },
              ]}
            />
          )}
        </Grid>
      </Grid>
      <FilterFileModal {...modalProps} />
    </Box>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  campaignsList: state.campaignList.data,
  loadingCampaignsList: state.campaignList.loading,
  cuttOffDateList: state.payments.cuttOffList.data,
  loadingCuttOffList: state.payments.cuttOffList.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...PaymentsActions, ...CampaignListActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CuttOffDate);
