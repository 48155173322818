import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import Utils from 'common/Utils';
import { loadCampaignListSuccess, loadCampaignListFailure } from './actions';

export function* loadCampaignList() {
  try {
    const { data } = yield call(API.get, 'campanha/all');
    yield put(loadCampaignListSuccess(data));
  } catch (error) {
    yield put(loadCampaignListFailure());
    Utils.redirect(error);
  }
}
