import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  makeStyles,
  Theme,
  createStyles,
  Button,
  FormControlLabel,
  Switch,
  IconButton,
} from '@material-ui/core';
import { Delete, Visibility, Edit } from '@material-ui/icons';
import ModalCheckboxList from 'components/ModalCheckboxList/ModalCheckboxList';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import { Dispatch, bindActionCreators } from 'redux';
import * as RegulationActions from 'store/regulation/actions';
import * as CampaignEditActions from 'store/campaign/edit/actions';
import { IRegulationItem, IRegulationUpdateRequest } from 'store/regulation';
import GenericTable from 'components/GenericTable/GenericTable';
import { TABLE_STYLE, IDefaultPatternRequest } from 'common/Constants';
import ModalConfirmation, {
  IModalConfirmationState,
} from 'components/ModalConfirmation/ModalConfirmation';
import DefaultCampaign from 'components/DefaultCampaign/DefaultCampaign';
import { useAfterChange } from 'common/CustomHooks';
import Api from 'common/Api';
import Utils from 'common/Utils';
import { EditorState } from 'draft-js';
import ModalSinglePatternEdit from 'components/ModalSinglePatternEdit/ModalSinglePatternEdit';
import CopyToClipboard from 'components/CopyToClipboard/CopyToClipboard';

interface RegulationProps {
  loadRegulationItems(campaignId: number): void;
  deleteRegulationItemContent(campaignId: number, RegulationId: number): void;
  updateRegulationsContent(campaignId: number, body: IRegulationUpdateRequest): void;
  loadRegulationPattern(campaignId: number): void;
  uploadRegulationPattern(campaignId: number, data: FormData): void;
  updateHasRegulation(campaignId: number, value: boolean): void;
  updateRequiredRegulation(campaignId: number, value: boolean): void;
  updateRegulationPattern(campaignId: number, request: IDefaultPatternRequest): void;
  regulationItems: IRegulationItem[];
  pattern: string;
  campaignId: number;
  loading: boolean;
  campaignHasRegulation: boolean;
  campaignRequiredRegulation: boolean;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    spacing: {
      padding: theme.spacing(2),
    },
    spacingBottom: {
      marginBottom: theme.spacing(2),
    },
    spacingTop: {
      marginTop: theme.spacing(4),
    },
    icons: {
      display: 'flex',
    },
  }),
);

const Regulation: React.FC<RegulationProps> = ({
  loadRegulationItems,
  deleteRegulationItemContent,
  updateRegulationsContent,
  regulationItems,
  pattern,
  loadRegulationPattern,
  uploadRegulationPattern,
  updateHasRegulation,
  updateRequiredRegulation,
  campaignId,
  loading,
  campaignHasRegulation,
  campaignRequiredRegulation,
  updateRegulationPattern,
}) => {
  const classes = useStyle();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ownPattern, setOwnPatternState] = useState({
    editorState: EditorState.createEmpty(),
  });
  const [modalConfirmation, setModalConfirmation] = useState<IModalConfirmationState>({
    open: false,
    onConfirm: null,
  });
  const [hasRegulation, setHasRegulation] = useState(campaignHasRegulation);
  const [requiredRegulation, setRequiredRegulation] = useState(campaignRequiredRegulation);
  const [modalUploadUrl, setModalUploadUrl] = useState<IModalConfirmationState>({
    open: false,
    onConfirm: null,
  });
  const [uploadUrl, setUploadUrl] = useState('');
  const [isLoadingUploadFile, setIsLoadingUploadFile] = useState(false);
  const [modalSinglePatternEdit, setModalSinglePatternEdit] = useState({
    open: false,
    item: {
      clusterId: 0,
      clusterNome: '',
      campanhaRegulamentoId: 0,
      html: '',
    },
  });

  const handleOnDelete = (row: IRegulationItem) => {
    setModalConfirmation({
      open: true,
      onConfirm: () => deleteRegulationItemContent(Number(campaignId), row.campanhaRegulamentoId),
    });
  };

  const handleUploadFile = async (file: any, resetFileUpload) => {
    setIsLoadingUploadFile(true);
    const formData = new FormData();
    const formated = Utils.getFormatedFile(file);
    formData.append('file', formated);

    const { data } = await Api.post(`/campanha/${Number(campaignId)}/regulamento/upload`, formData);
    setUploadUrl(data.url);
    setModalUploadUrl({
      open: true,
      onConfirm: () => {
        resetFileUpload();
        setModalUploadUrl({ open: false, onConfirm: null });
      },
    });
    setIsLoadingUploadFile(false);
  };

  const handleSavePattern = () => {
    const content = Utils.getEditorData(ownPattern.editorState);
    updateRegulationPattern(Number(campaignId), { clusterIds: [null], html: content });
  };

  const handleUpdateRegulation = (clusterIds, html) => {
    updateRegulationsContent(Number(campaignId), { clusterIds, html });
  };

  const handleOnSingleEditConfirm = (clusterId: number, html: any) => {
    updateRegulationsContent(Number(campaignId), { clusterIds: [clusterId], html });
  };

  const handleOnEdit = item => {
    setModalSinglePatternEdit({ open: true, item });
  };

  const handleOnPreview = html => {
    const win = window.open(
      '',
      '',
      `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=980,height=500,top=${100},left=${0}`,
    );
    if (win) win.document.body.innerHTML = html;
  };

  useEffect(() => {
    loadRegulationItems(Number(campaignId));
    loadRegulationPattern(Number(campaignId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useAfterChange(() => {
    updateHasRegulation(Number(campaignId), hasRegulation);
  }, [hasRegulation]);

  useAfterChange(() => {
    updateRequiredRegulation(Number(campaignId), requiredRegulation);
  }, [requiredRegulation]);

  useEffect(() => {
    if (pattern) {
      setOwnPatternState({ ...ownPattern, editorState: Utils.stringToEditorText(pattern) });
    }
  }, [pattern]);

  return (
    <Paper>
      <Box p="1.5em">
        <Box>
          <FormControlLabel
            control={
              <Switch
                checked={hasRegulation}
                onChange={(e, checked) => setHasRegulation(checked)}
                value="hasRegulation"
                color="primary"
              />
            }
            label="Haverá regulamento para esta campanha?"
          />
        </Box>

        {hasRegulation && (
          <>
            <Box className={classes.spacingBottom}>
              <FormControlLabel
                control={
                  <Switch
                    checked={requiredRegulation}
                    onChange={(e, checked) => setRequiredRegulation(checked)}
                    value="requiredRegulation"
                    color="primary"
                  />
                }
                label="Aceite de regulamento obrigatório?"
              />
            </Box>
            {/*
            <DefaultCampaign
              isLoading={loading}
              campaignId={Number(campaignId)}
              handleUploadFile={handleUploadFile}
              state={ownPattern}
              setState={setOwnPatternState}
              isUploadLoading={isLoadingUploadFile}
              handleSavePattern={handleSavePattern}
              uploadUrl={`/campanha/${Number(campaignId)}/regulamento/upload`}
              title="Comunicação padrão do Regulamento:"
            /> */}

            <Box className={classes.spacingTop}>
              <Box className={classes.spacingBottom}>
                Caso haja comunicações específicas para perfis do regulamento, selecione os perfis e
                descreva o regulamento
              </Box>
              <Box>
                <Button variant="contained" color="primary" onClick={() => setIsModalOpen(true)}>
                  Atualizar regulamento
                </Button>
              </Box>
            </Box>

            <Box className={classes.spacingTop}>
              <GenericTable
                title="Regulamentos"
                isLoading={loading}
                data={regulationItems}
                options={{
                  paging: false,
                  search: false,
                }}
                columns={[
                  {
                    title: 'Cluster',
                    field: 'clusterNome',
                    cellStyle: { ...TABLE_STYLE },
                  },
                  {
                    title: 'Excluir',
                    render: (row: IRegulationItem) => {
                      return (
                        <Box className={classes.icons}>
                          <IconButton onClick={() => handleOnEdit(row)}>
                            <Edit fontSize="small" />
                          </IconButton>
                          <IconButton
                            disabled={!row.campanhaRegulamentoId || !row.html}
                            onClick={() => handleOnPreview(row.html)}
                          >
                            <Visibility fontSize="small" />
                          </IconButton>
                          <IconButton
                            disabled={!row.campanhaRegulamentoId || !row.html}
                            onClick={() => handleOnDelete(row)}
                          >
                            <Delete fontSize="small" />
                          </IconButton>
                        </Box>
                      );
                    },
                    cellStyle: { width: '5%', ...TABLE_STYLE },
                  },
                ]}
              />
            </Box>
          </>
        )}
      </Box>

      <ModalCheckboxList
        title="Alterar Regulamento"
        subTitle="Regulamento:"
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        items={regulationItems}
        onConfirm={handleUpdateRegulation}
        uploadUrl={`/campanha/${Number(campaignId)}/regulamento/upload`}
      />

      <ModalSinglePatternEdit
        title={modalSinglePatternEdit.item.clusterNome}
        item={modalSinglePatternEdit.item}
        isModalOpen={modalSinglePatternEdit.open}
        forKey={'campanhaRegulamentoId'}
        uploadUrl={`/campanha/${Number(campaignId)}/regulamento/upload`}
        onConfirm={handleOnSingleEditConfirm}
        setIsModalOpen={setModalSinglePatternEdit}
      />

      <ModalConfirmation
        title="Excluir Regulamento"
        content="Tem certeza que deseja confirmar a exclusão deste regulamento?"
        state={modalConfirmation}
        setState={setModalConfirmation}
      />

      <ModalConfirmation
        size="lg"
        title="Arquivo de regulamento enviado com sucesso."
        content={<CopyToClipboard content={uploadUrl} />}
        state={modalUploadUrl}
        setState={setModalUploadUrl}
      />
    </Paper>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  campaignId: state.campaignEdit.data.id,
  regulationItems: state.regulation.data.items,
  pattern: state.regulation.data.pattern,
  loading: state.regulation.loading,
  campaignHasRegulation: state.campaignEdit.data.temRegulamento,
  campaignRequiredRegulation: state.campaignEdit.data.temRegulamentoAceite,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...RegulationActions,
      ...CampaignEditActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Regulation);
