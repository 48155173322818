import React, { useEffect, useState } from 'react';
import { Paper } from '@material-ui/core';
import * as ParticipantAllowanceFileActions from 'store/participantAllowance/uploadFile/actions';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import { Dispatch, bindActionCreators } from 'redux';
import DownloadClientAllowanceExcel from 'components/DownloadClientBase/DownloadClientAllowanceExcel';

import GenericImportTable, {
  ImportGenericTableData,
  IUploadVolume,
  IUploadVolumeDetail,
} from 'components/GenericImportTable/GenericImportTable';
interface UploadReportProps {
  loadParticipantAllowanceFile(): void;
  loadFileAllowanceDetails(id: number): void;
  uploadParticipantAllowanceCsv(file: FormData): void;
  uploadVolume: IUploadVolume;
  uploadVolumeDetail: IUploadVolumeDetail;
}

const ParticipantAllowance: React.FC<UploadReportProps> = ({
  loadParticipantAllowanceFile,
  loadFileAllowanceDetails,
  uploadVolume,
  uploadVolumeDetail,
  uploadParticipantAllowanceCsv,
}) => {
  const [dataGenericTable, setDataGenericTable] = useState<ImportGenericTableData>({
    title: 'Histórico de Upload de Abonos',
    responseTable: [],
    responseModal: [],
    loadingModal: true,
    loadingTable: true,
    disabledHandleUpload: false,
  });

  useEffect(() => {
    loadParticipantAllowanceFile();
  }, [loadParticipantAllowanceFile]);

  const uploadByFile = file => {
    uploadParticipantAllowanceCsv(file);
  };

  useEffect(() => {
    // Array da tabela de upload
    if (!uploadVolume.loading) {
      setDataGenericTable({
        ...dataGenericTable,
        responseTable: uploadVolume.data,
        loadingTable: uploadVolume.loading,
      });
    } else if (uploadVolume.loading) {
      setDataGenericTable({ ...dataGenericTable, loadingTable: uploadVolume.loading });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadVolume.data, uploadVolume.loading]);

  useEffect(() => {
    // Array da tabela de detalhamento do modal
    if (!uploadVolumeDetail.loading) {
      setDataGenericTable({
        ...dataGenericTable,
        responseModal: uploadVolumeDetail.data,
        loadingModal: uploadVolumeDetail.loading,
      });
    } else if (uploadVolumeDetail.loading) {
      // Troca de state loadingModal
      setDataGenericTable({ ...dataGenericTable, loadingModal: uploadVolumeDetail.loading });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadVolumeDetail.data, uploadVolumeDetail.loading]);

  return (
    <Paper>
      <GenericImportTable
        props={dataGenericTable}
        exampleUploadFile={() => <DownloadClientAllowanceExcel />}
        uploadApi={uploadByFile}
        detailModalApi={loadFileAllowanceDetails}
      />
    </Paper>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  uploadVolume: state.storeParticipantAllowanceFile.uploadVolume,
  uploadVolumeDetail: state.storeParticipantAllowanceFile.uploadVolumeDetail,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(ParticipantAllowanceFileActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantAllowance);
