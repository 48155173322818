import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import { loadPerfilSuccess } from './actions';
import { showToastr } from 'store/toastr/actions';
import { AppMessages } from 'common/AppMessages.enum';

export function* loadPerfil() {
  try {
    const { data } = yield call(API.get, 'perfil');
    yield put(loadPerfilSuccess(data));
  } catch (error) {
    yield put(showToastr({ type: 'error', message: AppMessages.ERROR_GENERIC_400, open: true }));
  }
}
