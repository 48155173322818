/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  createStyles,
  FormControl,
  IconButton,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Theme,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
/* eslint-disable react-hooks/exhaustive-deps */
import { CloudDownload } from '@material-ui/icons';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { TABLE_STYLE } from 'common/Constants';
import MaterialTable from 'material-table';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from 'store';
import * as KpiActions from 'store/kpi/actions';
import { showToastr } from 'store/toastr/actions';
import FileUpload from '../../../../components/FileUpload/FileUpload';
import { AppKpi } from 'common/AppKpi.enum';
import cn from 'classnames';
import Utils from 'common/Utils';
import { IEanItem } from 'store/sku';
import { AnyARecord } from 'dns';
import KpiSellinVeo from '../../../../components/KpiTypes/KpiSellinVeo';
import KpiVeo from '../../../../components/KpiTypes/KpiVeo';
import KpiSellinCampaignBonus from 'components/KpiTypes/KpiSellinCampaignBonus';
import KpiSellinCampaignRegular from 'components/KpiTypes/KpiSellinCampaignRegular';
import DownloadClientBaseBeneficioCSV from 'components/DownloadClientBase/DownloadClientBaseBenefitCSV';
import DownloadClientBaseCampaign from 'components/DownloadClientBase/DownloadClientBaseCampaign';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(2),
      minWidth: 120,
    },
    spacing: {
      marginBottom: theme.spacing(2),
    },
    buttonActions: {
      padding: theme.spacing(1),
      minWidth: 120,
      textAlign: 'right',
      '& > button': {
        marginRight: 0,
      },
    },
  }),
);

const Kpi = ({
  kpi,
  campaign,
  uploadKpiFileRequest,
  loadRequest,
  saveKpiRequest,
  loadConfiguredKpi,
  loading,
  file,
  sku,
}) => {
  const classes = useStyle();
  const [kpiValue, setKpiValue] = useState(campaign.kpiTipo);
  const [firstBuyValue, setFirstBuyValue] = useState(true);

  useEffect(() => {
    if (campaign.id > 0) {
      loadRequest();
      loadConfiguredKpi(campaign.id);
      setKpiValue(campaign.kpiTipo > 0 ? campaign.kpiTipo : 0);
    }
  }, [campaign.id]);

  const handleOnSave = () => {
    saveKpiRequest({
      id: campaign.id,
      kpiTipo: kpiValue,
    });
  };

  const handleKpiChange = (event: React.ChangeEvent<{ value: any }>) => {
    setKpiValue(+event.target.value);
    if (Number(+event.target.value) === AppKpi.VEO_PRIMEIRA_COMPRA) {
      setFirstBuyValue(false);
    }
  };

  useEffect(() => {
    if (campaign.kpiTipo === AppKpi.VEO_PRIMEIRA_COMPRA) {
      setFirstBuyValue(false);
    }
  }, []);

  const kpiSelected = () => {
    if (kpi.length === 0) return false;

    return kpi.find(item => {
      return item.id === kpiValue;
    });
  };

  return (
    <Paper>
      <Box p="1.5em">
        <form>
          <Box width={6 / 12} className={classes.spacing}>
            <FormControl fullWidth variant="outlined" className={classes.formControl}>
              <Box fontSize="h5.fontSize">KPI</Box>
              <Box className={classes.spacing}>
                Selecione a opção de KPI a ser apurado na para o credito automático. <br /> Após
                salvar a seleção não é possível alterar o tipo de KPI da campanha.
              </Box>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                required
                native={false}
                disabled={campaign.kpiTipo > 0 || loading}
                value={kpiValue}
                defaultValue={campaign.kpiTipo}
                onChange={(e: React.ChangeEvent<{ value: any }>) => handleKpiChange(e)}
                inputProps={{
                  name: 'kpi',
                  id: 'kpi',
                }}
              >
                <MenuItem value={0}>
                  <em>Selecione...</em>
                </MenuItem>
                {kpi.length > 0 &&
                  kpi
                    .filter(kpi => {
                      return (
                        (kpi.id === AppKpi.SELLIN && campaign.campanhaRegular === true) ||
                        campaign.campanhaRegular === false
                      );
                    })
                    .map((kpi: any, index) => {
                      return (
                        <MenuItem key={index} value={kpi.id}>
                          {kpi.nome}
                        </MenuItem>
                      );
                    })}
              </Select>
            </FormControl>
          </Box>

          {kpiSelected() && kpiSelected().modeloKpi && firstBuyValue && (
            <Box width={1} className={classes.spacing}>
              <Box fontSize="h5.fontSize">Modelo de arquivo</Box>
              <Box className={classes.spacing}>
                Faça o download do modelo de arquivo de KPI, siga exatamente o modelo de cabeçalho
                do arquivo para a correta importação dos dados.
              </Box>
              <Box mt="1em">
                <DownloadClientBaseCampaign />
              </Box>
            </Box>
          )}

          {campaign.kpiTipo < AppKpi.SELLIN && (
            <Grid container={true}>
              <Grid item={true} xs={6} className={cn([classes.buttonActions])}>
                <Button
                  onClick={handleOnSave}
                  disabled={loading || kpiValue <= 0 || kpiValue === ''}
                  variant="contained"
                  color="secondary"
                >
                  {loading ? 'Aguarde...' : 'Salvar'}
                </Button>
              </Grid>
            </Grid>
          )}

          {campaign.campanhaRegular && campaign.kpiTipo === AppKpi.SELLIN && (
            <KpiSellinCampaignRegular />
          )}

          {!campaign.campanhaRegular && campaign.kpiTipo === AppKpi.SELLIN && (
            <KpiSellinCampaignBonus />
          )}

          {campaign.kpiTipo === AppKpi.SELLIN_VEO_CONECTA_LITE && <KpiSellinVeo />}

          {campaign.kpiTipo === AppKpi.VEO && <KpiVeo />}

          {campaign.kpiTipo === AppKpi.VEO_PRIMEIRA_COMPRA && (
            <Box width={1} className={classes.spacing}>
              <Box className={classes.spacing}>
                Não é necessário o cadastro de objetivos. Os participantes desta campanha receberão
                os crédito de pontos ao realizarem a primeira compra através do VEO, sem quantidade
                minima.
              </Box>
            </Box>
          )}
        </form>
      </Box>
    </Paper>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  sku: state.sku.data,
  campaign: state.campaignEdit.data,
  kpi: state.kpi.data,
  file: state.kpi.file,
  loading: state.kpi.loading || state.campaignEdit.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...KpiActions, showToastr }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Kpi);
