import { call, put } from 'redux-saga/effects';
import API from 'common/Api';
import {
  loadRealtimeTotalMalhaCancelamentosSuccess,
  loadRealtimeTotalMalhaCancelamentosFailure,
} from './actions';

export function* loadRealtimeTotalMalhaCancelamentosRequest() {
  try {
    const { data } = yield call(API.get, 'dashboard/realtime/total/malha/cancelamentos');
    yield put(loadRealtimeTotalMalhaCancelamentosSuccess(data.itens, data.atualizacao));
  } catch (error) {
    yield put(loadRealtimeTotalMalhaCancelamentosFailure());
  }
}
