import React, { useState, SetStateAction } from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Theme,
  createStyles,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormControlLabel,
  Checkbox,
  Chip,
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { showToastr } from 'store/toastr/actions';
import { IToastrState } from 'store/toastr';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Utils from 'common/Utils';
import Api from 'common/Api';
import { EditorState } from 'draft-js';

interface ModalCheckboxListProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<SetStateAction<boolean>>;
  title: string;
  subTitle: string;
  items: any[];
  onConfirm: any;
  showToastr(options: IToastrState): void;
  uploadUrl: string;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: theme.typography.fontFamily,
    },
    label: {
      fontWeight: 'bolder',
      fontSize: '14px',
      marginBottom: theme.spacing(1),
      display: 'block',
    },
    spacingBottom: {
      marginBottom: theme.spacing(2),
    },
    minHeight: {
      minHeight: '450px',
    },
  }),
);

const ModalCheckboxList: React.FC<ModalCheckboxListProps> = ({
  isModalOpen,
  setIsModalOpen,
  title,
  subTitle,
  items,
  onConfirm,
  showToastr,
  uploadUrl,
}) => {
  const classes = useStyle();
  const [state, setState] = useState<{ items: any[]; html: string }>({
    items: [],
    html: EditorState.createEmpty(),
  });
  const [selectAll, setSelectAll] = useState<boolean>(false);

  const handleOnConfirm = () => {
    if (onConfirm) {
      if (state.items.length === 0) {
        showToastr({ type: 'error', message: 'Selecione um cluster para atualizar.', open: true });
        return;
      }

      if (!state.html || state.html === '') {
        showToastr({
          type: 'error',
          message: 'O campo comunicação não pode ficar em branco.',
          open: true,
        });
        return;
      }

      const data = Utils.getEditorData(state.html);
      onConfirm(
        state.items.map(e => e.clusterId),
        data,
      );
    }
    setSelectAll(false);
    onCloseModal();
  };

  const handleSelectAll = (event, checked) => {
    setSelectAll(checked);
    setState({
      ...state,
      items: checked ? [...items] : [],
    });
  };

  const handleOnChangeInput = (e, values) => {
    setState({
      ...state,
      items: [...values],
    });
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
    setState({
      items: [],
      html: EditorState.createEmpty(),
    });
  };

  const uploadImageCallBack = async (file: any, resetFileUpload) => {
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();
      formData.append('file', file);
      const { data } = await Api.post(uploadUrl, formData);
      resolve({
        data: {
          link: data.url,
        },
      });
    });
  };

  React.useEffect(() => {
    setSelectAll(false);
  }, []);

  return (
    <Dialog className={classes.root} maxWidth="md" fullWidth={true} open={isModalOpen}>
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent dividers={true}>
        <Box className={classes.spacingBottom}>
          <Grid container={true} spacing={3} alignItems={'center'}>
            <Grid item={true} xs={10}>
              <Autocomplete
                value={state.items}
                multiple={true}
                id="tags-outlined"
                options={items}
                getOptionLabel={option => option.clusterNome}
                disableCloseOnSelect={true}
                autoHighlight={true}
                noOptionsText={'Sem opções restantes'}
                filterSelectedOptions={true}
                onChange={(e, value) => handleOnChangeInput(e, value)}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Cluster"
                    placeholder="Selecione os clusters"
                    fullWidth={true}
                  />
                )}
              />
            </Grid>
            <Grid item={true} xs={2}>
              <FormControlLabel
                control={
                  <Checkbox checked={selectAll} onChange={handleSelectAll} value="selectAll" />
                }
                label="Todos"
              />
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Box className={classes.label} display="label">
            {subTitle}
          </Box>
          <Box className={classes.minHeight}>
            <Editor
              toolbar={{
                image: {
                  uploadCallback: uploadImageCallBack,
                  urlEnabled: true,
                  uploadEnabled: true,
                  previewImage: true,
                },
              }}
              editorState={state.html}
              onEditorStateChange={editorState => {
                setState({ ...state, html: editorState });
              }}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseModal}>Fechar</Button>
        <Button onClick={() => handleOnConfirm()} variant="contained" color="primary">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ showToastr }, dispatch);

export default connect(null, mapDispatchToProps)(ModalCheckboxList);
