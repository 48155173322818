import { Reducer } from 'redux';
import Utils from 'common/Utils';

export enum ConectaPayListTypes {
  LOAD_REQUEST = '@conectaPayList/LOAD_REQUEST',
  LOAD_SUCCESS = '@conectaPayList/LOAD_SUCCESS',
  LOAD_FAILURE = '@conectaPayList/LOAD_FAILURE',
}

export interface IConectaPayListItem {
  id: number;
  campanhaBanner: [];
  dataInclusao: string;
  industriaCnpj: string;
  industriaNome: string;
  nome: string;
  temRegulamento: boolean;
  temRegulamentoAceite: boolean;
  vigenciaAte: string;
  vigenciaDe: string;
}

export interface IConectaPayListState {
  readonly data: IConectaPayListItem[];
  readonly loading: boolean;
  readonly error: boolean;
}

const INITIAL_STATE: IConectaPayListState = {
  data: [],
  loading: false,
  error: false,
};

const reducer: Reducer<IConectaPayListState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ConectaPayListTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case ConectaPayListTypes.LOAD_SUCCESS:
      const formated = action.payload.data.map((e: IConectaPayListItem) => {
        return {
          ...e,
          vigenciaDe: Utils.setData(e.vigenciaDe),
          vigenciaAte: Utils.setData(e.vigenciaAte),
        };
      });
      return { ...state, loading: false, error: false, data: formated };
    case ConectaPayListTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true, data: [] };
    default:
      return state;
  }
};

export default reducer;
