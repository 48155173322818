import { Reducer } from 'redux';

export enum CommunicationTypes {
  LOAD_REQUEST = '@communication/LOAD_REQUEST',
  LOAD_SUCCESS = '@communication/LOAD_SUCCESS',
  LOAD_FAILURE = '@communication/LOAD_FAILURE',
  LOAD_HTML_REQUEST = '@communication/LOAD_HTML_REQUEST',
  LOAD_HTML_SUCCESS = '@communication/LOAD_HTML_SUCCESS',
  LOAD_HTML_FAILURE = '@communication/LOAD_HTML_FAILURE',
  DELETE_HTML_REQUEST = '@communication/DELETE_HTML_REQUEST',
  DELETE_HTML_SUCCESS = '@communication/DELETE_HTML_SUCCESS',
  DELETE_HTML_FAILURE = '@communication/DELETE_HTML_FAILURE',
  UPDATE_REQUEST = '@communication/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@communication/UPDATE_SUCCESS',
  LOAD_PATTERN_REQUEST = '@communication/LOAD_PATTERN_REQUEST',
  LOAD_PATTERN_SUCCESS = '@communication/LOAD_PATTERN_SUCCESS',
  UPLOAD_PATTERN_REQUEST = '@communication/UPLOAD_PATTERN_REQUEST',
  UPLOAD_PATTERN_SUCCESS = '@communication/UPLOAD_PATTERN_SUCCESS',
  UPDATE_PATTERN_REQUEST = '@communication/UPDATE_PATTERN_REQUEST',
  UPDATE_PATTERN_SUCCESS = '@communication/UPDATE_PATTERN_SUCCESS',
}

export interface ICommunicationUpdateRequest {
  clusterIds: number[];
  html: string;
}

export interface ICommunicationItem {
  perfilId: number;
  perfilNome: string;
  campanhaComunicacaoId: number;
  html?: string;
}

export interface ICommunication {
  items: ICommunicationItem[];
  pattern: string;
}

export interface ICommunicationState {
  readonly data: ICommunication;
  readonly loading: boolean;
  readonly error: boolean;
}

const INITIAL_STATE = {
  data: {
    items: [],
    pattern: '',
  },
  loading: false,
  error: false,
};

const reducer: Reducer<ICommunicationState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CommunicationTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case CommunicationTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          items: [...action.payload.data],
        },
      };
    case CommunicationTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true };
    case CommunicationTypes.LOAD_HTML_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          items: state.data.items.map(e => {
            const data = action.payload.data;
            if (e.campanhaComunicacaoId === data.id) {
              return { ...e, html: data.html };
            } else {
              return { ...e };
            }
          }),
        },
      };
    case CommunicationTypes.DELETE_HTML_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          items: state.data.items.map(e => {
            const campanhaComunicacaoId = action.payload.data;
            if (e.campanhaComunicacaoId === campanhaComunicacaoId) {
              return { ...e, html: undefined };
            } else {
              return { ...e };
            }
          }),
        },
      };
    case CommunicationTypes.LOAD_PATTERN_REQUEST:
      return { ...state, loading: false };
    case CommunicationTypes.LOAD_PATTERN_SUCCESS:
      const content = action.payload.data === '' ? '' : action.payload.data.html;
      return {
        ...state,
        loading: false,
        data: { items: state.data.items, pattern: content },
      };
    case CommunicationTypes.UPDATE_PATTERN_REQUEST:
      return { ...state, loading: true };
    case CommunicationTypes.UPDATE_PATTERN_SUCCESS:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default reducer;
