export const LAST_PAGE = 'last_page';
export const ACCESS_TOKEN = 'accessToken';

export const TABLE_STYLE = {
  paddingTop: '5px',
  paddingBottom: '5px',
  paddingLeft: '15px',
  paddingRight: '15px',
};

export enum STRUCTURE_PULICTARGET {
  PDV = 1,
  SALES_FORCE = 3,
  MAILING = 2,
}

export enum ModuleFilterType {
  Pdv = 1,
  Fdv = 2,
}

export enum MODULE_TYPE {
  CAMPAIGN = 1,
  BANNER = 2,
  BENEFIT = 3,
  CUTTOFFDATE = 4,
}

export enum CampaignKpiTipo {
  PdvFaturamento = 1,
  PdvComprouGanhou = 2,
  PdvMix = 3,
  FdvFaturamento = 4,
  FdvCobertura = 5,
  FdvMix = 6,
}

export enum ElegiblesType {
  REGION = 1,
  INDUSTRY = 2,
  CLUSTER = 3,
  SEGMENT = 4,
  PROFILE = 5,
}

export enum POPUP_TIPO {
  SOMENTE_TEXTO = 1,
  TEXTO_IMAGEM = 2,
  TEXTO_VIDEO = 3,
}

export enum POPUP_TIPO_SEGMENTACAO {
  FILTRO = 1,
  ARQUIVO = 2,
}

export interface IFilterItemRequest {
  id: number;
  selected: boolean;
  nome: string;
  tipo: number;
  value: string;
}

export interface IDefaultPatternRequest {
  clusterIds: [null];
  html: string;
}

export interface IGenericUploadTable {
  id: number;
  nomeArquivo: string;
  descricaoStatus: string;
}
export interface IGenericUploadDetailTable {
  numeroLinha: number;
  sap: number;
  descricaoErro: string;
}

export const PLACEHOLDER_IMAGE = '/assets/images/placeholder-image.png';
