import { Reducer } from 'redux';

export enum ToastrTypes {
  SHOW = '@toastr/SHOW',
  HIDE = '@toastr/HIDE',
}

export interface IToastrState {
  open: boolean;
  message: string;
  type: IToastrType;
}

export type IToastrType = 'success' | 'error' | 'info' | 'warning' | undefined;

const INITIAL_STATE: IToastrState = {
  open: false,
  message: '',
  type: undefined,
};

const reducer: Reducer<IToastrState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ToastrTypes.SHOW:
      return { open: true, message: action.payload.message, type: action.payload.type };
    case ToastrTypes.HIDE:
      return { ...state, open: false };
    default:
      return state;
  }
};

export default reducer;
