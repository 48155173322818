import { action } from 'typesafe-actions';
import { TotalErrosCadastrosTypes, IRealtimeItem } from './';

export const loadRealtimeTotalErrosCadastrosRequest = () =>
  action(TotalErrosCadastrosTypes.LOAD_REQUEST);
export const loadRealtimeTotalErrosCadastrosSuccess = (
  data: IRealtimeItem[],
  atualizacao: string,
) => action(TotalErrosCadastrosTypes.LOAD_SUCCESS, { data, atualizacao });
export const loadRealtimeTotalErrosCadastrosFailure = () =>
  action(TotalErrosCadastrosTypes.LOAD_FAILURE);
