import { STRUCTURE_PULICTARGET } from 'common/Constants';
import { Reducer } from 'redux';
import { ModuleFilterType, IFilterItemRequest } from 'common/Constants';
import { BannerHomeSize } from 'components/BannerHomeUpload/BannerHomeUpload';

export enum CampaignEditTypes {
  LOAD_REQUEST = '@campaignEdit/LOAD_REQUEST',
  LOAD_SUCCESS = '@campaignEdit/LOAD_SUCCESS',
  LOAD_FAILURE = '@campaignEdit/LOAD_FAILURE',
  RESET_CAMPAIGN = '@campaignEdit/RESET_CAMPAIGN',
  CREATE_REQUEST = '@campaignEdit/CREATE_REQUEST',
  CREATE_SUCCESS = '@campaignEdit/CREATE_SUCCESS',
  CREATE_FAILURE = '@campaignEdit/CREATE_FAILURE',
  APPLYFILTER_REQUEST = '@campaignEdit/APPLYFILTER_REQUEST',
  APPLYFILTER_SUCCESS = '@campaignEdit/APPLYFILTER_SUCCESS',
  APPLYFILTER_FAILURE = '@campaignEdit/APPLYFILTER_FAILURE',
  GETFILTER_REQUEST = '@campaignEdit/GETFILTER_REQUEST',
  GETFILTER_SUCCESS = '@campaignEdit/GETFILTER_SUCCESS',
  GETFILTER_FAILURE = '@campaignEdit/GETFILTER_FAILURE',
  GETFILTER_COMPLETED = '@campaignEdit/GETFILTER_COMPLETED',
  GETFILTER_RESET = '@campaignEdit/GETFILTER_RESET',
  GETFILTER_SETCOMPLETED = '@campaignEdit/GETFILTER_SETCOMPLETED',
  UPLOAD_BANNER_REQUEST = '@campaignEdit/UPLOAD_BANNER_REQUEST',
  UPLOAD_BANNER_SUCCESS = '@campaignEdit/UPLOAD_BANNER_SUCCESS',
  UPLOAD_BANNER_FAILURE = '@campaignEdit/UPLOAD_BANNER_FAILURE',
  UPDATE_HAS_REGULATION_REQUEST = '@campaignEdit/UPDATE_HAS_REGULATION_REQUEST',
  UPDATE_HAS_REGULATION_SUCCESS = '@campaignEdit/UPDATE_HAS_REGULATION_SUCCESS',
  UPDATE_REQUIRED_REGULATION_REQUEST = '@campaignEdit/UPDATE_REQUIRED_REGULATION_REQUEST',
  UPDATE_REQUIRED_REGULATION_SUCCESS = '@campaignEdit/UPDATE_REQUIRED_REGULATION_SUCCESS',
  LOAD_CAMPAIGN_FILTER_APPLIED_REQUEST = '@campaignEdit/LOAD_CAMPAIGN_FILTER_APPLIED_REQUEST',
  LOAD_CAMPAIGN_FILTER_APPLIED_SUCCESS = '@campaignEdit/LOAD_CAMPAIGN_FILTER_APPLIED_SUCCESS',
  LOAD_CAMPAIGN_FILTER_APPLIED_FAILURE = '@campaignEdit/LOAD_CAMPAIGN_FILTER_APPLIED_FAILURE',
  SETFILTER_COUNT_REQUEST = '@campaignEdit/SETFILTER_COUNT_REQUEST',
  LOAD_TARGETPUBLIC_REQUEST = '@campaignEdit/LOAD_TARGETPUBLIC_REQUEST',
  LOAD_TARGETPUBLIC_SUCCESS = '@campaignEdit/LOAD_TARGETPUBLIC_SUCCESS',
  LOAD_TARGETPUBLIC_FAILURE = '@campaignEdit/LOAD_TARGETPUBLIC_FAILURE',
}

export interface IGeneralData {
  nome: string;
  industriaCnpj: string;
  industriaNome: string;
  vigenciaDe: string;
  vigenciaAte: string;
  campanhaRegular: boolean;
  temRegulamento: boolean;
  temRegulamentoAceite: boolean;
  dataInclusao: string;
  elegivelFiltroTipo: number;
  campanhaBanner: ICampaignBanner[];
  tipoId: number;
  filtros: any;
  subTitulo: string;
  descricaoResultado: string;
  kpiTipo: number;
  creditoAutomatico: boolean;
  quantidadeComprasVeo: number;
  tipoPeriodoCreditoId: number;
}

export interface ICampaignBanner {
  id: number;
  campanhaId: number;
  tipoId: BannerHomeSize;
  url: string;
}

export interface ICampaignPopup {
  id: number;
  campanhaId: number;
  tipoId: BannerHomeSize;
  url: string;
}

export interface IGeneralDataCreateRequest {
  nome: string;
  industriaCnpj: string;
  vigenciaDe: string;
  vigenciaAte: string;
  temRegulamento: boolean;
  temRegulamentoAceite: boolean;
  tipoId: number;
}

export interface ICampaignFilterRequest {
  elegivelFiltro: ModuleFilterType;
  regioes?: IFilterItemRequest[];
  distribuidores?: IFilterItemRequest[];
  clusters?: IFilterItemRequest[];
  segmentos?: IFilterItemRequest[];
  perfis?: IFilterItemRequest[];
}

export interface ICampaignEdit extends IGeneralData {
  id: number;
}

export interface IElegibleTypeOptions {
  id: number;
  nome: string;
}

export interface ICampaignEditState {
  readonly data: ICampaignEdit;
  readonly elegibleFilter: IElegibleFilter;
  readonly filterApplied: number;
  readonly filterParticipantCount: number;
  readonly loading: boolean;
  readonly error: boolean;
  readonly elegibleTypeBlocked: boolean;
  readonly elegibleTypeOptions: IElegibleTypeOptions[];
}

export interface IElegibleFilter {
  status?: number;
  filtros: IElegibleFilterItems;
  isCompleted: boolean;
  filterParticipantCount: number;
}

export interface IElegibleFilterItem {
  id: number;
  name: string;
  selected: boolean;
  tipo: number;
  tipoNome: string;
  value: string;
}

export interface IElegibleFilterItems {
  elegivelFiltro: STRUCTURE_PULICTARGET;
  regioes: IElegibleFilterItem[];
  distribuidores: IElegibleFilterItem[];
  clusters: IElegibleFilterItem[];
  segmentos: IElegibleFilterItem[];
  perfis: IElegibleFilterItem[];
}

export const INITIAL_STATE: ICampaignEditState = {
  data: {
    id: 0,
    vigenciaAte: '',
    vigenciaDe: '',
    campanhaRegular: false,
    industriaCnpj: '',
    industriaNome: '',
    nome: '',
    subTitulo: '',
    descricaoResultado: '',
    kpiTipo: 0,
    campanhaBanner: [],
    dataInclusao: '',
    elegivelFiltroTipo: 0,
    temRegulamento: false,
    temRegulamentoAceite: false,
    tipoId: 1,
    creditoAutomatico: false,
    quantidadeComprasVeo: 0,
    tipoPeriodoCreditoId: 0,
    filtros: {
      atividades: [],
      clusters: [],
      ufs: [],
    },
  },
  filterParticipantCount: 0,
  filterApplied: 0,
  elegibleFilter: {
    filtros: {
      regioes: [],
      clusters: [],
      distribuidores: [],
      perfis: [],
      segmentos: [],
      elegivelFiltro: 1,
    },
    filterParticipantCount: 0,
    isCompleted: false,
  },
  loading: false,
  error: false,
  elegibleTypeBlocked: false,
  elegibleTypeOptions: [],
};

const reducer: Reducer<ICampaignEditState> = (state = INITIAL_STATE, action) => {
  console.log('action create camp', action);
  switch (action.type) {
    case CampaignEditTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case CampaignEditTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...action.payload.data,
          elegivelFiltroTipo:
            action.payload.data.elegivelFiltroTipo === null
              ? 0
              : action.payload.data.elegivelFiltroTipo,
        },
        elegibleTypeBlocked:
          action.payload.data.elegivelFiltroTipo === 0 ||
          action.payload.data.elegivelFiltroTipo === null
            ? false
            : true,
      };
    case CampaignEditTypes.RESET_CAMPAIGN:
      return { ...state, loading: false, error: false, data: INITIAL_STATE.data };
    case CampaignEditTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true, data: { ...INITIAL_STATE.data } };
    case CampaignEditTypes.CREATE_REQUEST:
      return { ...state, loading: true };
    case CampaignEditTypes.CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: { ...state.data, ...action.payload, id: action.payload.id },
      };
    case CampaignEditTypes.CREATE_FAILURE:
      return { ...state, loading: false };
    case CampaignEditTypes.APPLYFILTER_REQUEST:
      return { ...state, loading: true };
    case CampaignEditTypes.APPLYFILTER_SUCCESS:
      return { ...state, loading: false, data: { ...state.data } };
    case CampaignEditTypes.APPLYFILTER_FAILURE:
      return { ...state, loading: false };
    case CampaignEditTypes.GETFILTER_REQUEST:
      return { ...state, loading: true, filterParticipantCount: 0 };
    case CampaignEditTypes.GETFILTER_RESET:
      return {
        ...state,
        elegibleFilter: { ...INITIAL_STATE.elegibleFilter },
      };
    case CampaignEditTypes.GETFILTER_SETCOMPLETED:
      return { ...state, elegibleFilter: { ...INITIAL_STATE.elegibleFilter, isCompleted: true } };
    case CampaignEditTypes.GETFILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: { ...state.data },
        elegibleFilter: { ...action.payload, isCompleted: false },
      };
    case CampaignEditTypes.GETFILTER_COMPLETED:
      return {
        ...state,
        loading: false,
        data: { ...state.data },
        elegibleFilter: { ...state.elegibleFilter, isCompleted: true },
      };
    case CampaignEditTypes.UPLOAD_BANNER_REQUEST:
      return { ...state, loading: true };
    case CampaignEditTypes.UPLOAD_BANNER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: { ...state.data },
      };
    case CampaignEditTypes.UPLOAD_BANNER_FAILURE:
      return { ...state, loading: false };
    case CampaignEditTypes.UPDATE_HAS_REGULATION_REQUEST:
      return { ...state };
    case CampaignEditTypes.UPDATE_HAS_REGULATION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          temRegulamento: action.payload.value,
        },
      };
    case CampaignEditTypes.UPDATE_REQUIRED_REGULATION_REQUEST:
      return { ...state };
    case CampaignEditTypes.UPDATE_REQUIRED_REGULATION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          temRegulamentoAceite: action.payload.value,
        },
      };
    case CampaignEditTypes.SETFILTER_COUNT_REQUEST:
      return {
        ...state,
        filterParticipantCount: action.payload.count,
      };
    case CampaignEditTypes.LOAD_TARGETPUBLIC_REQUEST:
      return { ...state };
    case CampaignEditTypes.LOAD_TARGETPUBLIC_SUCCESS:
      return {
        ...state,
        elegibleTypeOptions: action.payload.data.elegivelFiltroTipos,
      };
    case CampaignEditTypes.LOAD_TARGETPUBLIC_FAILURE:
      return { ...state };
    default:
      return state;
  }
};

export default reducer;
