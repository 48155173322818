import React from 'react';
import { Button, Grid } from '@material-ui/core';

import cn from 'classnames';

const BtnSteps = props => {
  return (
    <Grid item={true} xs={12} className={cn([props.classes.buttonActions])}>
      <Button
        disabled={props.activeStep == 1 ?? true}
        onClick={props.handleBack}
        className={props.classes.button}
      >
        Voltar
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={props.nextTrack}
        className={props.classes.button}
        disabled={props.activeStep == props.numberOfSteps ?? true}
      >
        Prox Faixa
      </Button>

      <Button
        variant="contained"
        color="secondary"
        onClick={props.saveTrack}
        className={props.classes.button}
      >
        Salvar Faixa {props.activeStep}
      </Button>
    </Grid>
  );
};

export default BtnSteps;
