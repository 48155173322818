import { action } from 'typesafe-actions';
import { BenefitMailingTypes } from '.';
import { MODULE_TYPE } from 'common/Constants';

export const loadMailing = (id: number, moduleType: MODULE_TYPE) =>
  action(BenefitMailingTypes.LOAD_REQUEST, { id, moduleType });
export const loadMailingSuccess = data => action(BenefitMailingTypes.LOAD_SUCCESS, { data });
export const loadMailingFailure = () => action(BenefitMailingTypes.LOAD_FAILURE);

export const uploadBenefitMailing = (
  uploadUrl: string,
  data: FormData,
  callbackAction: any,
  id: number,
  type: number,
) => action(BenefitMailingTypes.UPLOAD_REQUEST, { uploadUrl, data, callbackAction, id, type });
export const uploadBenefitMailingSuccess = data =>
  action(BenefitMailingTypes.UPLOAD_SUCCESS, { data });
export const uploadBenefitMailingFailure = () => action(BenefitMailingTypes.UPLOAD_FAILURE);

export const loadBenefitMailing = (id: number, moduleType: MODULE_TYPE) =>
  action(BenefitMailingTypes.LOAD_REQUEST, { id, moduleType });

export const deleteBenefitMailing = (deleteUrl: string) =>
  action(BenefitMailingTypes.DELETE_REQUEST, { deleteUrl });
export const deleteMailingSuccess = data => action(BenefitMailingTypes.DELETE_SUCCESS, { data });
export const deleteMailingFailure = () => action(BenefitMailingTypes.DELETE_FAILURE);
