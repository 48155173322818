import { action } from 'typesafe-actions';
import { TotalUltimosCadastrosTypes, IRealtimeItem } from './';

export const loadRealtimeTotalCadastrosUltimosDiasRequest = () =>
  action(TotalUltimosCadastrosTypes.LOAD_REQUEST);
export const loadRealtimeTotalCadastrosUltimosDiasSuccess = (
  data: IRealtimeItem[],
  atualizacao: string,
) => action(TotalUltimosCadastrosTypes.LOAD_SUCCESS, { data, atualizacao });
export const loadRealtimeTotalCadastrosUltimosDiasFailure = () =>
  action(TotalUltimosCadastrosTypes.LOAD_FAILURE);
